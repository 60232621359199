import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import { getColor } from 'theme/colors'
import Placeholder from 'assets/images/Placeholder.png'
import classNames from 'classnames'
import Typography from 'components/common/Typography'
import Image from 'components/common/Image'
import Button from 'components/common/Button'
import { useNavigate } from 'react-router-dom'
import { getFlag } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    width: '48.6%',
    display: 'inline-block',
    height: '115px',
    padding: '20px',
    backgroundColor: '#fff',
    border: '1px solid #F0F0F4',
    marginBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },
  mainFlex: {
    display: 'flex',
  },
  cardLeft: {
    width: '50px',
    height: '50px',
    '& img': {
      width: '50px',
      ObjectFit: 'cover',
      height: '100%',
      border: '1px solid #F0F0F4 ',
    },
  },
  cardRight: {
    width: '93%',
  },
  oldPrice: {
    fontWeight: '300',
    fontSize: '1.25rem',
    color: getColor('grey-g65'),
  },
  price: {
    fontWeight: '600',
    fontSize: '20px',
    color: getColor('dark-main'),
  },
  title: {
    color: getColor('dark-main'),
  },
  paragraphColor: {
    color: getColor('grey-g35'),
  },
  stockColor: {
    color: getColor('func-positive'),
  },
  shipColor: {
    color: getColor('func-info'),
  },
  partnumberColor: {
    color: getColor('grey-g65'),
  },
  buynowBg: {
    backgroundColor: getColor('func-positive'),
  },
  viewquoteColor: {
    color: getColor('func-info'),
  },
  pb5: {
    paddingBottom: '5px',
  },
  pb10: {
    paddingBottom: '7px',
    alignItems: 'center',
  },
  progress: {
    fontWeight: '600',
    fontSize: '20px',
    lineheight: ' 30px',
    color: getColor('grey-g35'),
  },
  titleAlign: {
    alignItems: 'center',
  },
  flag: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function CantParticipantCard({ name, city, state, country, img, id, brandName, equipName, partName }) {
  const classes = useStyles({})
  const url = `/suppliers/${name}/${id}`
  const navigate = useNavigate()
  return (
    <>
      <Container className={classes.root}>
        <Container className={classNames(classes.mainFlex, classes.pb10)} gap={'0 10px'}>
          <Container className={classes.cardLeft}>
            <Image src={img || Placeholder} alt="placehodler" />
          </Container>
          <Container className={classes.cardRight}>
            <Container className={classes.mainFlex} justify={'space-between'}>
              <Container className={classes.mainFlex} gap={'0 5px'}>
                <Typography variant="title" className={classes.title}>
                  {/* AAM Parts & Equipment */}
                  {name}
                </Typography>
              </Container>
            </Container>
            <Container className={classNames(classes.mainFlex, classes.pb5)} justify={'space-between'}>
              <Container className={classNames(classes.mainFlex, classes.titleAlign)} gap={'0 5px'}>
                <Typography variant="paragraph1" className={classNames(classes.paragraphColor, classes.flag)}>
                  {city}, {state}
                </Typography>
                {getFlag(country)}
              </Container>
            </Container>
          </Container>
          <Container>
            <Button variant="secondary" width={'110px'} onClick={() => navigate(url)}>
              View Profile
            </Button>
          </Container>
        </Container>
        <Container>
          <Typography variant="paragraph1" color="#4A4D68">
            {brandName} • {equipName} • {partName}
          </Typography>
        </Container>
      </Container>
    </>
  )
}

CantParticipantCard.propTypes = {
  name: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  img: PropTypes.string,
  id: PropTypes.string,
  brandName: PropTypes.string,
  equipName: PropTypes.string,
  country: PropTypes.string,
  partName: PropTypes.string,
}
