import { createContext } from 'react'
import { constants } from 'utils/constants'
import { getFromLocalStorage, setToLocalStorage } from 'utils/Utils'

export const AuthContext = createContext()
export const initialState = {
  isAuthenticated: !!getFromLocalStorage(constants.TOKEN) || false,
  user: getFromLocalStorage(constants.USER),
  token: getFromLocalStorage(constants.TOKEN),
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      setToLocalStorage(constants.USER, action.payload.user)
      setToLocalStorage(constants.TOKEN, action.payload.token)
      setToLocalStorage(constants.ROLE, action.payload.role)
      setToLocalStorage(constants.USER_ID, action.payload.id)
      setToLocalStorage(constants.COMPANY_ID, action.payload.companyId)
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      }
    case 'LOGOUT':
      localStorage.clear()
      sessionStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }
    case 'SINGUP':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
export const SignupDataContext = createContext({
  onSaveSignupData: () => {},
  signupData: {},
})
