import React from 'react'
import { makeStyles } from '@mui/styles'
import { Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import classNames from 'classnames'
import { InfoIcon } from 'assets/icons'
import { getColor } from 'theme/colors'
// import typography from 'theme/typography'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    marginBottom: theme.spacing(props.spacing),
    display: 'flex',
    alignItems: 'center',
    color: getColor('grey-g25'),
    '& svg': {
      width: 15,
      height: 15,
      margin: theme.spacing(0, 1),
      cursor: 'help',
    },
  }),
}))

export default function Label({ children, className, spacing, variant = 'paragraph2', tooltip, divider, ...props }) {
  const classes = useStyles({ spacing, variant, divider })
  return (
    <Typography component="label" className={classNames(classes.root, className)} {...props}>
      {children}
      {tooltip && (
        <Tooltip title={tooltip} placement="top">
          <InfoIcon />
        </Tooltip>
      )}
    </Typography>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  spacing: PropTypes.number,
  tooltip: PropTypes.string,
  variant: PropTypes.string,
  divider: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'light']),
}
