import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { CloseIcon } from 'assets/icons'
import classNames from 'classnames'
import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'
import Link from 'components/common/Link'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    padding: '0.938rem 1.25rem',
    alignItems: 'center',
    backgroundColor: getColor('light'),
    borderBottom: `1px solid ${getColor('grey-g95')}`,
    ...getRootStyle(props),
  }),
  content: {
    flex: 1,
  },
  rightContent: {
    width: 'auto',
  },
  underLine: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

export default function DialogHead({ title, onClose, children, className, divider, closeTo }) {
  const classes = useStyles({ divider })
  return (
    <Container container justify="space-between" alignItems="center" className={classNames(classes.root, className)}>
      <Container container alignItems="center" className={classes.content}>
        {title && (
          <Typography variant="h3" color={getColor('grey-g25')}>
            {title}
          </Typography>
        )}

        {children}
      </Container>
      <Container item container alignItems="center" className={classes.rightContent}>
        {
          // eslint-disable-next-line no-nested-ternary
          onClose ? (
            <IconButton size="md" variant="transparent" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : closeTo ? (
            <Link to={closeTo}>
              <IconButton size="md" variant="transparent" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Link>
          ) : null
        }
      </Container>
    </Container>
  )
}

DialogHead.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  divider: PropTypes.bool,
  closeTo: PropTypes.string,
}

function getRootStyle(props) {
  return {
    ...(props.divider && {
      borderBottomWidth: 0.5,
      borderBottomStyle: 'solid',
      borderBottomColor: getColor('grey-g85'),
    }),
  }
}
