import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import TextField from 'components/common/text/TextField'
import React from 'react'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Typography from 'components/common/Typography'
import Tooltip from 'components/common/Tooltip'
import { InfoSmallIcon } from 'assets/icons'
import Button from 'components/common/Button'

const useStyles = makeStyles(() => ({
  root: {
    padding: '15px 30px',
    background: getColor('light-main'),
    border: `1px solid ${getColor('grey-g95')}`,
  },
  main: {
    position: 'absolute',
    width: '100%',
    zIndex: '999',
    bottom: '0px',
  },
  toolTipInfo: {
    padding: '21px 30px',
    background: '#fff',
  },
  textfield: {
    '& input': {
      borderRadius: 'unset',
    },
  },
  doneBtn: {
    marginTop: '20px',
  },
}))

export default function FormFooter({
  currentStep,
  equipmentTypes,
  brandTypes,
  // setModelNumber,
  // setSerialNumber,
  // modelNumber,
  // serialNumber,
  setEquipmentName,
  equipmentName,
  setBrandName,
  brandName,
  nextBtn,
}) {
  const classes = useStyles()
  let label = 'Equipment'
  if (currentStep === 1) {
    label = 'Brand'
  }

  // const handleOnModelNumber = (e) => {
  //   setModelNumber(e.target.value)
  // }
  // const handleOnSerialNumber = (e) => {
  //   setSerialNumber(e.target.value)
  // }
  const handleOnEqChange = (e) => {
    setEquipmentName(e.target.value)
  }

  const handleBrandName = (e) => {
    setBrandName(e.target.value)
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  return (
    <Container className={classes.main}>
      <Container className={classes.toolTipInfo}>
        <Container width={'100%'} flex justify="space-between">
          <Container flex gap="10px">
            <Typography variant="overline">Images provided for reference only</Typography>
            <Tooltip
              placement="right-end"
              maxWidth={'565px'}
              title={
                'All trademarks, logos and brand names are the exclusive property of their respective owners, are for reference purposes only, and do not imply endorsement or affiliation with PartsClub, Inc.'
              }
            >
              <InfoSmallIcon className={classes.icon} />
            </Tooltip>
          </Container>

          {(currentStep === 0 || currentStep === 1) && (
            <Typography variant="overline">
              *Patent Pending with the United States Patent & Trademark Office (USPTO)
            </Typography>
          )}
        </Container>
      </Container>

      {equipmentTypes?.name === 'Other' && (
        <Container justify="flex-start" gap={'20px'} alignItems="center" className={classes.root}>
          <TextField
            className={classes.textfield}
            width={246}
            label={label}
            placeholder="Type"
            value={equipmentName}
            id={`eq-number-${equipmentTypes}-${currentStep}`}
            onChange={handleOnEqChange}
            onKeyDown={handleKeyDown}
          />
          <Button className={classes.doneBtn} disabled={nextBtn} type={'submit'} form={`hook-form-${currentStep}`}>
            Done
          </Button>
        </Container>
      )}
      {brandTypes && (
        <>
          <form id={`hook-form-${1}`}>
            {brandTypes?.name === 'Other' && (
              <Container flex justify="flex-start" gap={'20px'} className={classes.root}>
                <TextField
                  className={classes.textfield}
                  width={246}
                  value={brandName}
                  label="Brand"
                  placeholder="Type"
                  id={`eq-number-model-${brandTypes}-${currentStep}`}
                  labelVariant="paragraph2"
                  onChange={handleBrandName}
                  onKeyDown={handleKeyDown}
                />
                <Button
                  className={classes.doneBtn}
                  disabled={nextBtn}
                  type={'submit'}
                  form={`hook-form-${currentStep}`}
                >
                  Done
                </Button>
              </Container>
            )}
          </form>
        </>
      )}
    </Container>
  )
}

FormFooter.propTypes = {
  equipmentTypes: PropTypes.object,
  brandTypes: PropTypes.object,
  currentStep: PropTypes.number,
  // setModelNumber: PropTypes.func,
  // setSerialNumber: PropTypes.func,
  // modelNumber: PropTypes.string,
  // serialNumber: PropTypes.string,
  setEquipmentName: PropTypes.func,
  equipmentName: PropTypes.string,
  setBrandName: PropTypes.func,
  brandName: PropTypes.string,
  nextBtn: PropTypes.bool,
}
