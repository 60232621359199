import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getColor } from 'theme/colors'
import { makeStyles } from '@mui/styles'
import { ListItem } from '@mui/material'
import typography from 'theme/typography'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  listItem: {
    padding: '0',
    marginBottom: '20px',
    color: getColor('grey-g25'),
    fontSize: typography.body1.fontSize,
    fontWeight: typography.body1.fontWeight,
    whiteSpace: 'nowrap',
    '& ul': { padding: '0' },
    '&:hover': {
      backgroundColor: 'unset',
    },
  },

  listActive: {
    backgroundColor: getColor('grey-g35'),
  },
}))

export default function ContextMenuItem({ children, active, onClick, className, ...props }) {
  const classes = useStyles()
  return (
    <ListItem
      button
      className={classNames(
        classes.listItem,
        {
          [classes.listActive]: active,
        },
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </ListItem>
  )
}

ContextMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
