import { updateTaxes } from 'common-api/settings/settings'
import useToastify from 'common-hooks/useToastify'

export default function useUpdateTaxes() {
  const { toastMessage } = useToastify()
  const editTax = async (data) => {
    try {
      const response = await updateTaxes(data)
      toastMessage('success', 'Your changes updated successfully')
      return response
    } catch {
      return false
    }
  }
  return {
    editTax,
  }
}
