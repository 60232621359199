/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable camelcase */
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import { makeStyles } from '@mui/styles'
import Typography from 'components/common/Typography'
import Seperator from 'components/common/Seperator'
import Dropzone from 'components/custom/dropzone/Dropzone'
import { useState, useEffect, useContext } from 'react'
import Select from 'components/common/select/Select'
import Checkbox from 'components/common/Checkbox'
import TextField from 'components/common/text/TextField'
import TextArea from 'components/common/text/TextArea'
import IconButton from 'components/common/IconButton'
// import Constructionbrand from 'assets/images/Constructionbrand.png'
import useToggle from 'common-hooks/useToggle'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { filesAddKeys, IMAGE_MIME } from 'utils/Files'
// import DropDownTextField from 'components/common/text/DropDownTextField'
import { getColor } from 'theme/colors'
import FileUpload from 'components/custom/dropzone/FileUpload'
import useSupplierdetails from 'common-hooks/super-admin/useSupplierDetails'
import useCreateSupplier from 'common-hooks/super-admin/useCreateSupplier'
import {
  AddIcon,
  CaretLeftIcon,
  DeletePlain,
  DeletePlainWhite,
  // EarlyBadge,
  RedDeleteIcon,
  Upload,
  Verfied,
} from 'assets/icons'
import useIndustryList from 'common-hooks/useIndustryList'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import { useParams } from 'react-router-dom'
import { getFormError } from 'utils/form/form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import GooglePlaces from 'components/custom/GooglePlaces'
import useToastify from 'common-hooks/useToastify'
import SpecializationInTreeView from 'containers/common-containers/SpecializationInTreeView'
import AddPartsDialog from 'containers/common-containers/AddPartsDialog'
import OemDialog from 'containers/common-containers/OemDialog'
import DistributorDialog from 'containers/common-containers/DistributorDialog'

import AddEquipmentDialog from '../../../common-containers/AddEquipmentDialog'
import { CreateSupplierContext, getEquipAndPartsUpdatedData, parseSupplierDetails, Status } from '../SupplierUtils'
import { getWellKnownAddress } from 'utils/Utils'
import LabelTextField from 'components/common/text/LabelTextField'
import LoadingOverlay from 'components/common/LoadingOverlay'
import useSupplierList from 'common-hooks/super-admin/useSupplierList'
import DeleteConformation from './DeleteConformation'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import ImageFileUpload from 'components/ImageFileUpload'
// import Image from 'components/common/Image'

const useStyles = makeStyles(() => ({
  mainContainer: {
    backgroundColor: '#fff',
    '& .root-main:hover .delete-icon': {
      display: 'block',
      cursor: 'pointer',
    },
    '& .delete-icon': {
      display: 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '#companyInfo-location': {
      marginBottom: 0,
    },
    '& #drop-zone-logo': {
      '& div': {
        textAlign: 'center',
      },
    },
    '& .profile-div:hover .profile-icon': {
      display: 'block',
      // cursor: 'pointer',
      background: '#13141B',
      opacity: 0.7,
      width: '86px',
      height: '86px',
      zIndex: '999999',
      position: 'absolute',
      top: '0',
    },
    '& .profile-div:hover .profile-action-btns': {
      display: 'flex',
      width: '86px',
      height: '86px',
      zIndex: '999999',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      top: '0',
      '& .MuiButtonBase-root': {
        position: 'absolute',
        top: '-5px !important',
        right: '-5px !important',
      },
    },
    // '& .profile-div': {
    //   '&:hover': {},
    // },
    '& .profile-action-btns': {
      display: 'none',
    },
    '& .profile-icon': {
      display: 'none',
    },
    '& .header-div:hover .header-icon': {
      display: 'block',
      // cursor: 'pointer',
      background: '#13141B',
      opacity: 0.7,
      width: '300px',
      height: '86px',
      zIndex: '999999',
      position: 'absolute',
      top: '0',
    },
    '& .header-div:hover .header-action-btns': {
      display: 'flex',
      width: '300px',
      height: '86px',
      zIndex: '999999',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      top: '0',
      '& .MuiButtonBase-root': {
        position: 'absolute',
        top: '-5px !important',
        right: '-5px !important',
      },
    },
    // '& .header-div': {
    //   '&:hover': {},
    // },
    '& .header-action-btns': {
      display: 'none',
    },
    '& .header-icon': {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '12px 30px',
    alignItems: 'center',
    zIndex: '999',
    background: 'white',
  },
  div1: {
    width: '25%',
    // padding: '30px',
    padding: '30px 0 30px 0',
    borderRight: '1px solid #F0F0F4',
  },
  div_to_scroll: {
    width: '50%',
    height: '93vh',
    // padding: '30px 30px 0px 30px',
    padding: '30px 0 30px 0',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  div3: {
    width: '25%',
    padding: '30px 0 30px 0',
    borderLeft: '1px solid #F0F0F4',
  },
  imgBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    width: '84px',
    height: '84px',
    margin: '30px 0px',

    textAlign: 'center',
    position: 'relative',
    '& .MuiButtonBase-root': {
      background: 'unset',
      position: 'absolute',
      top: '-5px',
      right: '-7px',
    },
    '& img': {
      height: '84px',
      // border: '1px solid #F0F0F4',
      width: '84px',
    },
  },
  imgContainer1: {
    width: '100%',
    margin: '30px 0px',
    textAlign: 'center',
    position: 'relative',
    '& .MuiButtonBase-root': {
      background: 'unset',
      position: 'absolute',
      top: '-5px',
      right: '-7px',
    },
    '& div': {
      height: '86px',
    },
  },
  statusBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
  centerMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '20px',
    marginBottom: '30px',
  },
  formText: {
    position: 'relative',
    '& input': {
      paddingLeft: '65px',
    },
  },
  staticText: {
    position: 'absolute',
    left: '10px',
    top: '28px',
  },
  headImgBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
  },
  brands: {
    padding: '12px 0px',
    cursor: 'pointer',
    '& img': {
      border: '1px solid #F0F0F4',
    },
  },
  oemBrands: {
    '& img': {
      border: '1px solid #F0F0F4',
    },
  },
  addBtn: {
    cursor: 'pointer',
  },
  brandeSection: {
    margin: '30px 0px 25px 0px',
  },
  contactNo: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  contactDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
  containerWidth: {
    width: '50%',
  },
  mainHeader: {
    position: 'fixed',
    width: '100%',
    zIndex: '1',
    borderBottom: '1px solid #F0F0F4',
  },
  addBtns: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginLeft: '-15px',
    padding: '0rem 0.75rem',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
    },
  },
  textFieldWidth: {
    width: '60%',
  },
  imageInfo: {
    height: '69px',
    width: '69px',
    backgroundColor: '#F8F9F9',
    borderColor: getColor('grey-g95'),
    border: '1px solid',
  },
  inputImgStyle: {
    display: 'none',
  },
  onHoverBtn: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  deleteTxt: {
    color: getColor('func-alert'),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconbtn: {
    padding: '0',
    minHeight: 'unset',
    backgroundColor: 'unset',
    left: '47px',
    height: '30px',
    position: 'relative',
    top: '-72px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  editButton: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  imgContainer: {
    height: '70px',
    width: '70px',
    margin: '20px 0px 30px 0px',
    textAlign: 'center',
  },
  accesBtn: {
    display: 'none',
  },
  deleteIcone: {
    display: 'none',
  },
  checkBoxContainer: {
    width: '206.76px',
  },
  maincontainedBox: {
    display: 'flex',
    height: '93.5vh',
    marginTop: '61px',
  },
  setScroller: {
    maxHeight: '120px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  logoContainer: {
    borderColor: getColor('grey-g95'),
    border: '1px solid',
  },
  emptyLogoContainer: {
    border: '1px dashed',
    height: '69px',
    width: '69px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: getColor('grey-g80'),
  },
  saveChanges: {
    border: ' 1px solid #9799B5',
    borderRadius: ' 4px',
    color: '#4A4D68',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dropZoneLogo: (props) => ({
    '& div': {
      textAlign: 'center',
    },
    display: props.logoImages?.length > 0 ? 'none' : 'block',
  }),
  dropZoneBanner: (props) => ({
    '& div': {
      textAlign: 'center',
    },
    width: '300px',
    height: '86px',
    display: props.bannerImages?.length > 0 ? 'none' : 'block',
  }),
  cmnPadding: {
    padding: '0 30px',
  },
  btmPadding: {
    paddingBottom: '20px',
  },
  logoTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  uploadBtn: {
    cursor: 'pointer',
  },
  yearFounded: {
    width: '150px',
  },
  addSpecializations: {
    fontWeight: '500',
    fontSize: '14px',
    color: getColor('grey-g35'),
  },
  verfied: {
    // padding: '5px 0',
  },
  verfiedIcon: {
    '& svg': {
      height: '30px',
      width: '30px',
    },
  },
  elaryadopt: {
    padding: '5px 0',
  },
  elaryadoptIcon: {
    '& svg': {
      height: '30px',
      width: '30px',
    },
  },
  premiumnRoot: {
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
    '& span.MuiCheckbox-root': {
      padding: '0',
    },
  },
  addSpeciBtn: {
    background: 'transparent',
    color: '#4A4D68',
    // border: '1px solid #4A4D68',
    padding: '0',
    width: 'unset',
    paddingRight: '26px',
    height: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  labelTextField: {
    width: '100px',
  },
}))
const schema = yup
  .object({
    name: yup.string().required('Company name is required.'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    phone_number: yup
      .string()
      .nullable(false)
      .matches(/^\d{10}$/, 'Please enter the valid mobile number')
      .required(),
    website_url: yup.string().required('Website is required'),
  })
  .required()

export default function CreateSupplier() {
  const params = useParams()
  const navigate = useNavigate()
  const { toastMessage } = useToastify()
  const { createSupplier, companyProfileImagesDelete, companyDelete } = useCreateSupplier()
  const { listMutate } = useSupplierList()
  const { supplierDetail, isLoading, mutate } = useSupplierdetails(params.id)
  const { industryData } = useIndustryList()
  const { specializationTreeViewData } = useContext(CreateSupplierContext)
  const [filterActiveCard, setFilterActiveCard] = useState([])
  const [deleteDialog, toggleDeleteDialog] = useToggle()
  const [charCount, setCharCount] = useState(120)

  const [isHovering] = useState({ display: 'none' })
  const [isProfileHovering] = useState({ display: 'none' })
  const [isHeaderHovering] = useState({ display: 'none' })
  const [logoRef, setLogoRef] = useState(null)
  const [bannerRef, setBannerRef] = useState(null)

  const [openEquipDialog, toggleEquipDialog] = useToggle(false)
  const [openParts, toggleParts] = useToggle(false)
  const [openOemDailog, toggleOemDailog] = useToggle(false)
  const [openDistDialog, toggleDistDialog] = useToggle(false)

  const [bannerImages, setBannerImages] = useState([])
  const [bannerFile, setBannerFile] = useState()
  const [logoImages, setLogoImages] = useState([])
  const classes = useStyles({ logoImages, bannerImages })
  const [logoFile, setLogoFile] = useState()
  const [industries, setIndustries] = useState([])
  const [dealerBrands, setDealerBrands] = useState([])
  const [distBrands, setDistBrands] = useState([])
  const [specializationsData, setSpecializationsData] = useState([])
  const [specializationPartsData, setSpecializationPartsData] = useState([])
  const [equipsObj, setEquips] = useState({ del_equips: [], equips: [] })
  const uniqueIds = []
  const uniqueDistributorIds = []
  const [equipId, setEquipId] = useState()
  const [partId, setPartId] = useState()

  const handleCharCount = (e) => {
    const charLength = e.target.value.length
    setCharCount(120 - charLength)
  }

  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { same_address: true, prefix: '1' },
  })
  const [same_address] = useWatch({
    control,
    name: ['same_address'],
  })
  const constextValue = {
    onSelectNode: setSpecializationsData,
    specializationTreeViewData: specializationsData,
    setPartsData: setSpecializationPartsData,
    partsData: specializationPartsData,
  }

  const handleBannerFileChange = (name, newFiles) => {
    const filesArr = filesAddKeys(newFiles)

    newFiles?.map((obj) => {
      const image = new Image()
      image.addEventListener('load', () => {
        if (image.width === 1060 && image.height === 170) {
          setValue(name, filesArr)
          setBannerFile(newFiles)
          setBannerImages(filesArr)
        } else {
          toastMessage('error', 'Upload image in 1060px x 170px resolution')
        }
      })
      image.src = URL.createObjectURL(obj)
      return null
    })
  }

  const handleLogoFileChange = (name, newFiles) => {
    const filesArr = filesAddKeys(newFiles)
    newFiles?.map((obj) => {
      const image = new Image()
      image.addEventListener('load', () => {
        if (image.width === 512 && image.height === 512) {
          setValue(name, filesArr)
          setLogoFile(newFiles)
          setLogoImages(filesArr)
        } else {
          toastMessage('error', 'Upload image in 512px x 512px resolution')
        }
      })
      image.src = URL.createObjectURL(obj)
      return null
    })
  }

  useEffect(() => {
    let data
    if (supplierDetail) {
      data = parseSupplierDetails(supplierDetail)
      Object.keys(data).map((key) => setValue(key, data[key]))
      setDistBrands(data?.disti_brands)
      setDealerBrands(data?.dlr_brands)
      setIndustries(data?.industries?.map((ind) => ind.id))
      setBannerImages(supplierDetail?.img_header ? [supplierDetail?.img_header] : [])
      setLogoImages(supplierDetail?.logo ? [supplierDetail?.logo] : [])
    }
    if (specializationTreeViewData) {
      setSpecializationsData(specializationTreeViewData)
    }
    if (data?.equips) {
      setSpecializationsData(data?.equips)
      setSpecializationPartsData(data?.parts)
    }
  }, [supplierDetail, setValue, specializationTreeViewData])

  const onSubmit = (data) => {
    if (!data?.prefix) {
      toastMessage('error', 'Please enter prefix')
      return
    }
    if (!logoImages || logoImages.length === 0) {
      toastMessage('error', 'Logo image must required!')
      return
    }
    const { add_parts, equips, del_equips } = getEquipAndPartsUpdatedData(
      params.id,
      equipsObj,
      supplierDetail,
      specializationPartsData
    )

    createSupplier(
      {
        ...(params.id && { id: params.id }),
        ...data,
        dlr_brands: uniqueIds,
        disti_brands: uniqueDistributorIds,
        add_parts,
        equips,
        del_equips,
        industries,
      },
      bannerFile,
      logoFile,
      mutate,
      setEquips
    )
  }

  const fillterBrands = dealerBrands.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.id)
    if (!isDuplicate) {
      uniqueIds.push(element.id)
      return true
    }
    return false
  })
  const fillterDistributor = distBrands.filter((element) => {
    const isDuplicate = uniqueDistributorIds.includes(element.id)
    if (!isDuplicate) {
      uniqueDistributorIds.push(element.id)
      return true
    }
    return false
  })
  const handleDelete = () => {
    companyDelete(params?.id, listMutate)
    navigate('/sa/company')
  }

  return (
    <CreateSupplierContext.Provider value={constextValue}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
        id="company-form"
      >
        <Container className={classes.mainContainer}>
          {params.id && isLoading && <LoadingOverlay />}
          <Container className={classes.mainHeader}>
            {params.id ? (
              <Container className={classes.header}>
                <Container flex gap={'0 10px'}>
                  <Button
                    variant={'secondary'}
                    border
                    width={'36px'}
                    icon={<CaretLeftIcon />}
                    onClick={() => navigate('/sa/company')}
                  />

                  <Typography variant="title" color={getColor('grey-g25')}>
                    {supplierDetail?.name}
                  </Typography>
                </Container>
                <Container gap="10px" display="flex">
                  <Button type="submit">Save Changes</Button>
                  <Button
                    variant={'secondary'}
                    border
                    width={'36px'}
                    icon={<RedDeleteIcon />}
                    onClick={() => toggleDeleteDialog(true)}
                  />
                </Container>
              </Container>
            ) : (
              <Container className={classes.header}>
                <Button variant={'secondary'} border onClick={() => navigate('/sa/company')}>
                  Cancel
                </Button>
                <Typography variant="title" color={getColor('grey-g25')}>
                  Add Suppliers
                </Typography>
                <Button type="submit">Add</Button>
              </Container>
            )}
          </Container>
          <Seperator />
          <Container className={classes.maincontainedBox}>
            <Container className={classes.div1}>
              <Container className={classes.imgBox}>
                <Container className={classes.cmnPadding}>
                  <Container className={classes.logoTitle}>
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Logo*
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      Size should be 512 x 512. Supported files are jpg and png.
                    </Typography>
                  </Container>

                  <Container className={classes.imageContainer}>
                    <Container className={'profile-div'}>
                      {logoImages &&
                        logoImages?.length > 0 &&
                        logoImages?.map((file, index) => {
                          const props = {
                            url: file.file ? URL.createObjectURL(file.file) : file,
                            index,
                            assetId: file.id,
                            indexImage: 0,
                            disableDropdown: true,
                            deleteAddImage: false,
                          }
                          return (
                            <FileUpload
                              key={index}
                              removeImage={() => {
                                setValue('logoImage', [])
                              }}
                              {...props}
                            />
                          )
                        })}

                      <Dropzone
                        id={'drop-zone-logo'}
                        dropzoneClassName={classes.dropZoneLogo}
                        multiple={false}
                        accept={IMAGE_MIME}
                        setRef={setLogoRef}
                        onChange={(e) => handleLogoFileChange('logoImage', e)}
                        actionComponent={<AddIcon />}
                      />

                      {logoImages && logoImages?.length > 0 && (
                        <>
                          {isProfileHovering && (
                            <>
                              <Container className={'profile-icon'}>
                                <input style={{ display: 'none' }} type="file" />
                              </Container>
                              <Container className={'profile-action-btns'}>
                                <IconButton
                                  onClick={() => {
                                    setLogoImages([])
                                    companyProfileImagesDelete({ type: 'logo', companyId: params.id })
                                  }}
                                >
                                  <DeletePlainWhite />
                                </IconButton>
                                <Upload onClick={() => logoRef.click()} className={classes.uploadBtn} />
                              </Container>
                            </>
                          )}
                        </>
                      )}
                    </Container>
                  </Container>
                </Container>
                <Seperator />
              </Container>
              <Container className={classes.cmnPadding}>
                <Container className={classes.headImgBox}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Header Image
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    Size should be 1060 x 170. Supported files are jpg and png.
                  </Typography>
                  <Container className={classes.imgContainer1}>
                    <Container className={'header-div'} height="100%">
                      {bannerImages?.map((file, index) => {
                        const props = {
                          url: file.file ? URL.createObjectURL(file.file) : file,
                          index,
                          assetId: file.id,
                          indexImage: 0,
                          disableDropdown: true,
                          deleteAddImage: false,
                        }
                        return (
                          <FileUpload
                            width={'300px'}
                            height={'86px'}
                            key={index}
                            removeImage={() => {
                              setValue('bannerImage', [])
                            }}
                            {...props}
                          />
                        )
                      })}

                      <Dropzone
                        id={'drop-zone'}
                        multiple={false}
                        dropzoneClassName={classes.dropZoneBanner}
                        accept={IMAGE_MIME}
                        setRef={setBannerRef}
                        onChange={(e) => handleBannerFileChange('bannerImage', e)}
                        actionComponent={<AddIcon />}
                      />
                      {bannerImages && bannerImages.length > 0 && (
                        <>
                          {isHeaderHovering && (
                            <>
                              <Container className={'header-icon'}>
                                <input style={{ display: 'none' }} type="file" />
                              </Container>
                              <Container className={'header-action-btns'}>
                                <IconButton
                                  onClick={() => {
                                    setBannerImages([])
                                    companyProfileImagesDelete({ type: 'cover', companyId: params.id })
                                  }}
                                >
                                  <DeletePlainWhite />
                                </IconButton>
                                <Upload onClick={() => bannerRef.click()} className={classes.uploadBtn} />
                              </Container>
                            </>
                          )}
                        </>
                      )}
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container className={classes.div_to_scroll}>
              <Container className={classes.cmnPadding}>
                <Container justify={'flex-start'} direction={'column'}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Company Details
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    Provide basic details of company.
                  </Typography>
                </Container>

                <Container className={classes.centerMainContainer}>
                  <Container flex gap={'20px'}>
                    <TextField
                      label={'Company Name*'}
                      placeholder={'Type'}
                      type="text"
                      {...register('name', { required: true })}
                      error={getFormError(errors, 'name')}
                    />
                    <Controller
                      name={'year_found'}
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <TextField
                          className={classes.yearFounded}
                          label={'Year Founded'}
                          placeholder={'Type'}
                          type="text"
                          name={name}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Container>
                  <Container>
                    <Container flex justify={'space-between'}>
                      <Typography variant="paragraph2" color={getColor('grey-g25')}>
                        Tagline
                      </Typography>
                      <Typography variant="paragraph2" color={getColor('grey-g25')}>
                        {charCount}
                      </Typography>
                    </Container>
                    <Container className={classes.inputText}>
                      <TextArea
                        id={'tagline'}
                        placeholder={'Write short tagline about company here'}
                        rows={3}
                        {...register('tagline', { required: true })}
                        onChange={handleCharCount}
                        height={'56px'}
                        maxLength="120"
                      />
                    </Container>
                  </Container>
                  <Container>
                    <Typography variant="paragraph2" color={getColor('grey-g25')}>
                      About
                    </Typography>
                    <Container>
                      <TextArea
                        id={'about'}
                        placeholder={'Write description about company here...'}
                        {...register('about', { required: true })}
                        rows={6}
                      />
                    </Container>
                  </Container>
                  <Container className={classes.contactDetails}>
                    <Container className={classes.containerWidth}>
                      <TextField
                        id="email"
                        type="email"
                        name={'email'}
                        placeholder="john.d@partsclub.com"
                        label={'Email*'}
                        {...register('email', { required: true })}
                        error={getFormError(errors, 'email')}
                      />
                    </Container>
                    <Container className={classes.containerWidth}>
                      <Typography variant="paragraph2" color={getColor('grey-g25')}>
                        Phone Number*
                      </Typography>
                      <Container flex gap="2px">
                        <Controller
                          name={'prefix'}
                          control={control}
                          render={({ field: { name, value, onChange } }) => (
                            <LabelTextField
                              id={name}
                              type={'number'}
                              labelText={'+'}
                              className={classes.labelTextField}
                              placeholder={'1'}
                              onChange={onChange}
                              name={name}
                              value={value}
                            />
                          )}
                        />
                        <TextField
                          id={'phone_number'}
                          type={'number'}
                          control={control}
                          {...register('phone_number', { required: true })}
                          error={getFormError(errors, 'phone_number')}
                          placeholder="235 567 2355"
                        />
                      </Container>

                      {/* <DropDownTextField
                        options={options}
                        id={'phone_number'}
                        type={'number'}
                        control={control}
                        {...register('phone_number', { required: true })}
                        error={getFormError(errors, 'phone_number')}
                        placeholder="235 567 2355"
                      /> */}
                    </Container>
                  </Container>
                  <Container>
                    <TextField
                      id="website_url"
                      type="text"
                      name={'website_url'}
                      placeholder="www.partsclub.us"
                      {...register('website_url', { required: true })}
                      error={getFormError(errors, 'website_url')}
                      label={'Website*'}
                      width={435}
                    />
                  </Container>
                </Container>
              </Container>
              <Seperator />
              <Container>
                <Container className={classes.cmnPadding}>
                  <Container className={classes.centerMainContainer}>
                    <Container justify={'flex-start'} direction={'column'}>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        Address*
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        Let buyer know where the company is located.
                      </Typography>
                    </Container>
                    <Container flex gap={'20px'}>
                      <Controller
                        name={'cmp_addr'}
                        control={control}
                        render={({ field: { name } }) => (
                          <GooglePlaces
                            id={name}
                            name={name}
                            label="Location"
                            spacing={3}
                            placeholder={'e.g. 4517 Washington Ave'}
                            onClearInput={() => setValue(name, { id: getValues()?.cmp_addr?.id })}
                            value={
                              getValues()?.cmp_addr?.addr_line_1 &&
                              getWellKnownAddress(
                                getValues()?.cmp_addr?.addr_line_1,
                                getValues()?.cmp_addr?.addr_line_2
                                  ? getValues()?.cmp_addr?.addr_line_2
                                  : (getValues()?.cmp_addr?.city,
                                    getValues()?.cmp_addr?.state,
                                    getValues()?.cmp_addr?.country)
                              )
                            }
                            onSelect={(loc, i) => {
                              if (i === 0)
                                setValue(
                                  'cmp_addr',
                                  formatAddressOfGAPI(
                                    loc.addr,
                                    loc.addr_line_1,
                                    loc.addr_line_2,
                                    loc,
                                    getValues()?.cmp_addr?.id
                                  )
                                )
                              else
                                setValue(
                                  'cmp_billing_addr',
                                  formatAddressOfGAPI(
                                    loc.addr,
                                    loc.addr_line_1,
                                    loc.addr_line_2,
                                    loc,
                                    getValues()?.cmp_billing_addr?.id
                                  )
                                )
                            }}
                          />
                        )}
                      />
                    </Container>
                    <Container justify={'flex-start'} direction={'column'}>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        Billing Address
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        Provide billing address of company
                      </Typography>
                    </Container>
                    <Typography variant="body1">
                      <Controller
                        name={'same_address'}
                        control={control}
                        render={({ field: { name, value } }) => (
                          <Checkbox
                            onChange={(e) => {
                              setValue('same_address', e.target.checked)
                            }}
                            label={'Same as address'}
                            name={name}
                            value={value}
                          />
                        )}
                      />
                    </Typography>
                    {!same_address ? (
                      <>
                        <Container flex gap={'20px'}>
                          <Controller
                            name={'cmp_billing_addr'}
                            control={control}
                            render={({ field: { name } }) => (
                              <GooglePlaces
                                id={name}
                                name={name}
                                onClearInput={() => setValue(name, { id: getValues()?.cmp_billing_addr?.id })}
                                label="Location"
                                value={
                                  getValues()?.cmp_billing_addr?.addr_line_1 &&
                                  getWellKnownAddress(
                                    getValues()?.cmp_billing_addr?.addr_line_1,
                                    getValues()?.cmp_billing_addr?.addr_line_2
                                      ? getValues()?.cmp_billing_addr?.addr_line_2
                                      : (getValues()?.cmp_billing_addr?.city,
                                        getValues()?.cmp_billing_addr?.state,
                                        getValues()?.cmp_billing_addr?.country)
                                  )
                                }
                                // value={getValues()?.cmp_billing_addr?.addr_line_1}
                                spacing={3}
                                placeholder={'e.g. 4517 Washington Ave'}
                                onSelect={(loc, i) => {
                                  if (i === 0)
                                    setValue(
                                      'cmp_addr',
                                      formatAddressOfGAPI(
                                        loc.addr,
                                        loc.addr_line_1,
                                        loc.addr_line_2,
                                        loc,
                                        getValues()?.cmp_addr?.id
                                      )
                                    )
                                  else
                                    setValue(
                                      'cmp_billing_addr',
                                      formatAddressOfGAPI(
                                        loc.addr,
                                        loc.addr_line_1,
                                        loc.addr_line_2,
                                        loc,
                                        getValues()?.cmp_billing_addr?.id
                                      )
                                    )
                                }}
                              />
                            )}
                          />
                        </Container>
                      </>
                    ) : (
                      <>{''}</>
                    )}
                  </Container>
                </Container>
                <Seperator />
                <Container>
                  <Container className={classes.cmnPadding}>
                    <Container className={classes.centerMainContainer}>
                      <Container justify={'flex-start'} direction={'column'}>
                        <Typography variant="title" color={getColor('grey-g25')}>
                          Industry*
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')}>
                          Select industry you are working in. Select at least one option.
                        </Typography>
                      </Container>
                      <Container flex justify={'space-between'}>
                        {industryData?.map((ind, index) => (
                          <Checkbox
                            key={index}
                            value={industries.includes(ind.id)}
                            id={ind.id}
                            label={ind.name}
                            onChange={(e) => {
                              if (e.target.checked) setIndustries([...industries, e.target.id])
                              else setIndustries(industries.filter((id) => id !== e.target.id))
                            }}
                          />
                        ))}
                      </Container>
                    </Container>
                  </Container>
                  <Seperator />
                </Container>
                <Container>
                  <Container className={classes.cmnPadding}>
                    <Container className={classes.centerMainContainer}>
                      <Container justify={'space-between'}>
                        <Container justify={'flex-start'} direction={'column'}>
                          <Typography variant="title" color={getColor('grey-g25')}>
                            Specializations
                          </Typography>
                          <Typography variant="paragraph1" color={getColor('grey-g35')}>
                            Select the equipment and parts that you would like to recieve quote requests for
                          </Typography>
                        </Container>
                        <Container display="flex" alignItems="center">
                          {/* <IconButton
                            variant={'transparent'}
                            className={classes.editButton}
                            onClick={toggleEquipDialog}
                          >
                            <AddIcon />
                          </IconButton> */}
                          <Button icon={<AddIcon />} onClick={toggleEquipDialog} className={classes.addSpeciBtn}>
                            Add
                          </Button>
                          {/* <Typography className={classes.addSpecializations}>Add</Typography> */}
                        </Container>
                      </Container>
                    </Container>
                    <Container>
                      <SpecializationInTreeView
                        openSpecialization={openEquipDialog}
                        toggleSpecialization={toggleEquipDialog}
                        openParts={openParts}
                        toggleParts={toggleParts}
                        setEquipId={setEquipId}
                        setPartId={setPartId}
                        setEquips={setEquips}
                        setFilterActiveCard={setFilterActiveCard}
                      />
                    </Container>
                  </Container>
                  <Seperator />
                </Container>
                <Container>
                  <Container className={classes.cmnPadding}>
                    <Container className={classes.centerMainContainer}>
                      <Container justify={'flex-start'} direction={'column'}>
                        <Typography variant="title" color={getColor('grey-g25')}>
                          Social Account
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')}>
                          Link company social accounts.
                        </Typography>
                      </Container>
                      <Container className={classes.formText}>
                        <TextField
                          id="linkedin_url"
                          type="text"
                          name={'linkedin_url'}
                          placeholder="www.linkedin.com/parts-clubs-1939473br8"
                          label={'LinkedIn'}
                          className={classes.inputText}
                          {...register('linkedin_url', { required: true })}
                        />
                        <Typography className={classes.staticText} variant="paragraph1">
                          https://
                        </Typography>
                      </Container>

                      <Container className={classes.formText}>
                        <TextField
                          id="twitter_url"
                          type="text"
                          name={'twitter_url'}
                          placeholder="www.twitter.com/49fh37bq84750j"
                          label={'Twitter'}
                          {...register('twitter_url', { required: true })}
                        />
                        <Typography className={classes.staticText} variant="paragraph1">
                          https://
                        </Typography>
                      </Container>
                      <Container className={classes.formText}>
                        <TextField
                          id="facebook_url"
                          type="text"
                          name={'facebook_url'}
                          placeholder="www.facebook.com/pArTs.clUb.4hry"
                          label={'Facebook'}
                          {...register('facebook_url', { required: true })}
                        />
                        <Typography className={classes.staticText} variant="paragraph1">
                          https://
                        </Typography>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container className={classes.div3}>
              <Container className={classes.cmnPadding}>
                <Container className={classes.statusBox}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Company Status & Badge
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    Select the status & badge for the company
                  </Typography>
                </Container>
              </Container>
              <Container className={classes.cmnPadding}>
                <Container direction={'column'} justify={'flex-start'} gap={'20px 0'}>
                  <Controller
                    name={'status'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <Select
                        options={Status}
                        id={name}
                        name={name}
                        value={value?.label === undefined ? '' : value}
                        onChange={onChange}
                        label={'Status*'}
                      />
                    )}
                  />
                  {/* <Select options={Status} id={'Status'} name={'Status'} label={'Status*'} /> */}
                  <Container className={classes.premiumnRoot}>
                    <Container display="flex" justify="space-between" alignItems="center" className={classes.verfied}>
                      <Container display="flex" gap={'10px'} alignItems="center" className={classes.verfiedIcon}>
                        <Verfied className={classes.verfiedIcon} />
                        <Typography variant="body1" color={getColor('grey-g25')}>
                          Verified
                        </Typography>
                      </Container>

                      <Controller
                        name={'is_verified'}
                        control={control}
                        render={({ field: { name, value } }) => (
                          <Checkbox
                            id={name}
                            name={name}
                            value={value !== undefined ? value : false}
                            onChange={(e) => setValue(name, e.target.checked)}
                            // {...register('is_verified')}
                          />
                        )}
                      />
                    </Container>
                    {/* <Container
                      display="flex"
                      justify="space-between"
                      alignItems="center"
                      className={classNames(classes.btmPadding, classes.elaryadopt)}
                    >
                      <Container display="flex" gap={'10px'} alignItems="center" className={classes.elaryadoptIcon}>
                        <EarlyBadge />
                        <Typography variant="body1" color={getColor('grey-g25')}>
                          Early Adopt (Innovator)
                        </Typography>
                      </Container>

                      <Controller
                        name={'early_adopt'}
                        control={control}
                        render={({ field: { name, value } }) => (
                          <Checkbox
                            id={name}
                            name={name}
                            value={value !== undefined ? value : false}
                            onChange={(e) => setValue(name, e.target.checked)}
                            // {...register('early_adopt')}
                          />
                        )}
                      />
                    </Container> */}
                    <Container display="flex" justify="space-between" alignItems="center">
                      <Typography variant="body1" color={getColor('grey-g25')}>
                        Premium
                      </Typography>
                      <Controller
                        name={'is_premium'}
                        control={control}
                        render={({ field: { name, value } }) => (
                          <Checkbox
                            id={name}
                            name={name}
                            value={value !== undefined ? value : false}
                            onChange={(e) => setValue(name, e.target.checked)}
                            // {...register('is_premium')}
                          />
                        )}
                      />
                    </Container>
                    <Container flex justify="space-between">
                      <Typography variant="body1" color={getColor('grey-g25')}>
                        Quote Request
                      </Typography>
                      <Controller
                        name={'quote_req'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <SwitchToggle
                            name={name}
                            value={value}
                            onChange={onChange}
                            defaultChecked={value}
                            // error={getFormError(errors, name)}
                          />
                        )}
                      />
                    </Container>
                  </Container>
                </Container>
              </Container>
              <Seperator />
              <Container className={classes.cmnPadding}>
                <Container className={classes.brandeSection}>
                  <Container className={classes.statusBox}>
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Approved OEM Dealer
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      Inputs will be sent to OEM for verification.
                    </Typography>
                  </Container>
                  <Container className={classes.setScroller}>
                    {fillterBrands?.map((item, index) => (
                      <Container
                        className={'root-main'}
                        justify={'space-between'}
                        key={index}
                        item={item}
                        index={index}
                        flex
                      >
                        <Container className={classes.brands}>
                          <Container flex gap={'12px'} className={classes.oemBrands}>
                            <ImageFileUpload
                              src={item?.image || item?.cover_image}
                              alt=""
                              width={'24px'}
                              height={'24px'}
                            />
                            <Container>
                              <Typography variant="body">
                                {item?.label} {item?.name}
                              </Typography>
                            </Container>
                          </Container>
                        </Container>
                        <Container className={'delete-icon'}>
                          {isHovering && (
                            <DeletePlain
                              onClick={() => setDealerBrands(dealerBrands.filter((dlr) => dlr.id !== item.id))}
                            />
                          )}
                        </Container>
                      </Container>
                    ))}
                  </Container>
                  <Container className={classes.addBtns} onClick={toggleOemDailog}>
                    <AddIcon />
                    <Container>
                      <Typography variant="body"> Add</Typography>
                    </Container>
                  </Container>
                </Container>
              </Container>
              <Seperator />
              <Container className={classes.cmnPadding}>
                <Container className={classes.brandeSection}>
                  <Container className={classes.statusBox}>
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Approved Product Line Distributor
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      Inputs will be sent to the manufacturer for verification.
                    </Typography>
                  </Container>
                  <Container className={classes.setScroller}>
                    {fillterDistributor.map((item, index) => (
                      <Container justify={'space-between'} key={index} gap={'12px'} className={'root-main'}>
                        <Container className={classes.brands}>
                          <Container flex gap={'12px'}>
                            <ImageFileUpload
                              src={item?.image || item?.cover_image}
                              alt=""
                              width={'24px'}
                              height={'24px'}
                            />
                            <Container>
                              <Typography variant="body">
                                {' '}
                                {item?.label}
                                {item?.name}
                              </Typography>
                            </Container>
                          </Container>
                        </Container>
                        <Container className={'delete-icon'}>
                          {isHovering && (
                            <DeletePlain
                              onClick={() => setDistBrands(distBrands.filter((dlr) => dlr.id !== item.id))}
                            />
                          )}
                        </Container>
                      </Container>
                    ))}
                  </Container>
                  <Container className={classes.addBtns} onClick={toggleDistDialog}>
                    <AddIcon />
                    <Container>
                      <Typography variant="body"> Add</Typography>
                    </Container>
                  </Container>
                </Container>
              </Container>
              <Seperator />
            </Container>
          </Container>
          <AddEquipmentDialog
            open={openEquipDialog}
            onClose={toggleEquipDialog}
            setEquips={setEquips}
            filterActiveCard={filterActiveCard}
          />
          <AddPartsDialog open={openParts} onClose={toggleParts} equipmentId={equipId} partId={partId} />
          <OemDialog
            open={openOemDailog}
            onclose={toggleOemDailog}
            setDealerBrands={setDealerBrands}
            dealerBrands={dealerBrands}
          />
          <DistributorDialog
            open={openDistDialog}
            onclose={toggleDistDialog}
            setDistBrands={setDistBrands}
            distBrands={distBrands}
          />
          <DeleteConformation open={deleteDialog} onclose={toggleDeleteDialog} handleDelete={handleDelete} />
        </Container>
      </form>
    </CreateSupplierContext.Provider>
  )
}
