import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useState, useEffect, useRef } from 'react'
import typography from 'theme/typography'

// import IconButton from 'components/common/IconButton'
// import { ClearIcon } from 'assets/icons'
import TextField from 'components/common/text/TextField'
import IconButton from 'components/common/IconButton'
import { ClearIcon } from 'assets/icons'
import Label from '../common/Label'

const GOOGLE_PLACE_API_KEY = 'AIzaSyBUnzuK9Dp4dBQ6ijkIWStNyN8vnynli5k'

const useStyles = makeStyles(() => ({
  root: {
    width: (props) => props.width,
    position: 'relative',
  },
  labelTypography: {
    fontSize: typography.paragraph2.fontSize,
    fontWeight: typography.paragraph2.fontWeight,
  },
  clearIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

function GooglePlaces({
  id,
  name,
  size,
  spacing = 0,
  width,
  placeholder,
  label,
  error,
  labelSpacing = 0,
  onBlur,
  readOnly,
  disabled,
  onSelect,
  onClearInput,
  className,
  value,
}) {
  const autoCompleteRef = useRef(null)
  const inputs = document.getElementsByClassName('query')

  const loadScript = (url, callback) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    if (script.readyState) {
      script.onreadystatechange = function loadMap() {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null
          callback()
        }
      }
    } else {
      script.onload = () => callback()
    }
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  const options = {
    // componentRestrictions: { country: 'us' },
    fields: ['address_components', 'formatted_address', 'geometry', 'icon', 'name'],
    strictBounds: true,
    // types: ['establishment'],
  }

  function handleScriptLoad() {
    for (let i = 0; i < inputs.length; i += 1) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputs[i], options)
      autocomplete.inputId = inputs[i].id

      autocomplete.addListener('place_changed', () => {
        // const addressline2 = autocomplete.getPlace().formatted_address.split(',')
        // const deteleLast = addressline2.length === 3 ? 2 : 3
        // // eslint-disable-next-line no-unused-expressions
        // addressline2.length < 3 ? null : addressline2.splice(addressline2.length - deteleLast, deteleLast)
        // const add2 = addressline2.join(', ')

        const addressObj = {
          addr: autocomplete.getPlace().address_components,
          addr_line_1: autocomplete.getPlace().name,
          addr_line_2: autocomplete.getPlace().formatted_address.replace(`${autocomplete.getPlace().name}, `, ''),
          latitude: autocomplete?.getPlace()?.geometry?.location?.lat(),
          longitude: autocomplete?.getPlace()?.geometry?.location?.lng(),
        }
        onSelect(addressObj, i)
      })
    }
  }

  useEffect(() => {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACE_API_KEY}&libraries=places`, () =>
      handleScriptLoad()
    )
  }, [])

  const classes = useStyles({
    spacing,
    size,
    width,
    readOnly,
    disabled,
    error,
  })

  const [allowChange, setAllowChange] = useState(false)
  const [hasText, setHasText] = useState(autoCompleteRef?.current?.value || value)
  useEffect(() => {
    if (hasText === undefined && (autoCompleteRef?.current?.value || value)) {
      setHasText(autoCompleteRef?.current?.value || value)
    }
    if (hasText === null) {
      autoCompleteRef.current.value = ''
    }
  }, [autoCompleteRef?.current?.value, value, setHasText, hasText])

  return (
    <Grid container direction="column" className={classNames(classes.root)}>
      {label && (
        <Label className={classes.labelTypography} spacing={labelSpacing}>
          {label}
        </Label>
      )}
      <TextField
        id={id}
        ref={autoCompleteRef}
        name={name}
        placeholder={placeholder}
        inputClassName={className || 'query'}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) => {
          setAllowChange(true)
          setHasText(e.target.value)
          if (!e.target.value?.length > 0) {
            onClearInput()
          }
        }}
        {...(allowChange ? {} : { value: autoCompleteRef?.current?.value || value })}
        rightIcon={
          hasText ? (
            <ClearButton
              onClick={() => {
                autoCompleteRef.current.value = ''
                onClearInput()
                setHasText(null)
              }}
            />
          ) : (
            ''
          )
        }
        autocomplete={'off'}
      />
    </Grid>
  )
}

export default GooglePlaces

function ClearButton(props) {
  const classes = useStyles()
  return (
    <IconButton size="md" variant="transparent" className={classes.clearIcon} {...props}>
      <ClearIcon />
    </IconButton>
  )
}

GooglePlaces.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  spacing: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  labelSpacing: PropTypes.number,
  width: PropTypes.number,
  onSelect: PropTypes.func,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onClearInput: PropTypes.func,
  className: PropTypes.string,
}
