/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import Container from 'components/common/Container'
import { makeStyles } from '@mui/styles'
import TextField from 'components/common/text/TextField'
import DropDownTextField from 'components/common/text/DropDownTextField'
import Typography from 'components/common/Typography'
import Seperator from 'components/common/Seperator'
import Button from 'components/common/Button'
import { useEffect, useRef, useState } from 'react'
import Link from 'components/common/Link'
import ContextMenuItem from 'components/custom/drop-downs/ContextMenuItem'
import ContextMenu from 'components/custom/drop-downs/ContextMenu'
import { getColor } from 'theme/colors'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { getFormError } from 'utils/form/form'
import useToggle from 'common-hooks/useToggle'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { ProfileInfoFillIcon } from 'assets/icons'
import { formatAddressOfGAPI, locationProfileValue } from 'containers/authentication/sign-up/SignUpUtils'
import Spacing from 'components/common/Spacing'
import useUpdateProfile from 'common-hooks/user-profile/useUpdateProfile'
import useUserProfile from 'common-hooks/user-profile/useUserProfile'
import Image from 'components/common/Image'
import GooglePlace from 'components/custom/GooglePlace'
import GooglePlaces from 'components/custom/GooglePlaces'
import HtmlTitle from 'utils/HtmlTitle'
import UserProfileDailog from './UserProfileDailog'
import { options } from './UserProfileUtils'
import { getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  headBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    height: '93vh',
  },
  mainContainer: {
    border: '1px solid #F0F0F4',
    padding: '30px',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '20px',
  },
  contactInfo: {
    '& input': {
      width: '150px',
    },
  },
  deleteTxt: {
    color: getColor('func-alert'),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconbtn: {
    padding: '0',
    minHeight: 'unset',
    backgroundColor: 'unset',
    left: '47px',
    height: '30px',
    position: 'relative',
    top: '-72px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  imageInfo: {
    height: '70px',
    width: '70px',
    border: '1px solid #F0F0F4',
    backgroundColor: '#F8F9F9',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
  },
  imgContainer: {
    height: '70px',
  },
  inputImgStyle: {
    display: 'none',
  },
  onHoverBtn: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  // addressBox: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   gap: '18px',
  // },
  belongsTo: {
    display: 'flex',
    gap: '16px',
  },
}))

const UserProfile = () => {
  const [toggle, setToggle] = useToggle(false)
  const [disabled, setDisabled] = useState(true)
  const { profileData, mutate } = useUserProfile()
  const { updateProfile } = useUpdateProfile()
  const [locationArray, setLocationArray] = useState()
  const [removeProfile, setremoveProfile] = useState(false)
  const [locationString, setLocationString] = useState('')
  const [fileData, setFileData] = useState()
  const classes = useStyles()
  const imageUploader = useRef(null)
  const handlePassword = () => {
    setToggle(true)
  }
  const [img, setImg] = useState([])
  // var data = new FormData()
  // var imagedata = files[0]

  const handleImageUpload = (e) => {
    const [file] = e.target.files
    setFileData(e.target.files[0])
    setImg([file])
  }

  function genrateUrl(image) {
    let imageObj

    image?.map((obj, index) => {
      if (obj) {
        imageObj = {
          url: typeof obj === 'string' ? obj : URL.createObjectURL(obj),
          // url: obj.obj ? URL.createObjectURL(obj) : obj,
          index,
          assetId: obj.id,
          indexImage: 0,
        }
        return imageObj
      }
      return {}
    })
    return imageObj
  }

  const url = genrateUrl(img)

  function getInitials(firstName, lastName) {
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`
    }
    if (firstName) {
      return firstName[0]
    }
    if (lastName) {
      return lastName[0]
    }
    return ''
  }
  function getInitialsFullName(fullName) {
    try {
      const [firstName, lastName] = fullName.split(' ')
      return getInitials(firstName, lastName)
    } catch (error) {
      return getInitials('')
    }
  }

  const lastName = capitalizeFirstLetter(profileData?.last_name)
  const firstName = capitalizeFirstLetter(profileData?.first_name)
  const fullName = capitalizeFirstLetter(`${firstName} ${lastName}`)
  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1)
  }
  const schema = yup.object({
    email: yup.string().email('Enter the valid email id').required('Email is required').required(),
    phone_number: yup
      .string()
      .matches(/^\d{10}$/, 'Please enter the valid mobile number')
      .required(),
  })
  const {
    register,
    setValue,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      street: locationString,
    },
  })

  // eslint-disable-next-line camelcase
  const [fName, lName, email, phoneNumber, user_address] = useWatch({
    control,
    name: ['first_name', 'last_name', 'email', 'phone_number', 'user_address'],
  })
  useEffect(() => {
    const unknownValues = [null, undefined, '', {}, []]
    if (
      !unknownValues.includes(fName) &&
      !unknownValues.includes(lName) &&
      !unknownValues.includes(email) &&
      !unknownValues.includes(phoneNumber) &&
      !unknownValues.includes(user_address)
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [fName, lName, email, phoneNumber, user_address])

  useEffect(() => {
    if (profileData) {
      Object.keys(profileData).map((key) => setValue(key, profileData[key]))
      if (profileData?.user_address) {
        const string = getWellKnownAddress(
          profileData?.user_address?.addr_line_1,
          profileData?.user_address?.addr_line_2,
          profileData?.user_address?.city,
          profileData?.user_address?.state,
          profileData?.user_address?.country,
          profileData?.user_address?.zipcode
        )
        setLocationString(string)
      }
      if (profileData?.profile_image) {
        setImg([profileData?.profile_image])
      }
    }
  }, [profileData, setValue])

  const handleOnremove = () => {
    setImg([])
    setremoveProfile(true)
  }
  const onSubmit = async () => {
    setDisabled(true)
    const data = {
      first_name: fName,
      last_name: lName,
      phone: phoneNumber,
      email,
      address: { ...user_address, id: profileData?.user_address?.id },
      remove_profile_img: removeProfile,
    }
    const uperProfileData = JSON.stringify(JSON.stringify(data))
    // updateProfile({ document: uperProfileData })
    updateProfile(uperProfileData, fileData)
    mutate()
  }

  return (
    <Container className={classes.headBox}>
      <HtmlTitle title="Settings" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className={classes.mainContainer}>
          <Container className={classes.subContainer}>
            <Container className={classes.imgContainer}>
              <input
                onChange={handleImageUpload}
                ref={imageUploader}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className={classes.inputImgStyle}
              />
              <Container className={classes.imageInfo}>
                {url?.url ? (
                  <Image alt={'user-profile'} src={url?.url} className={classes.imageInfo} />
                ) : (
                  <Typography>{fullName ? getInitialsFullName(fullName) : getInitials(firstName, lastName)}</Typography>
                )}
              </Container>
              <ContextMenu
                closeOnClick
                trigger={
                  <Button
                    icon={<ProfileInfoFillIcon />}
                    className={classes.iconbtn}
                    transformoriginhorizontal={'left'}
                  />
                }
              >
                <Container className={classes.lists}>
                  {img !== null && img.length < 1 ? (
                    <>
                      <ContextMenuItem onClick={() => imageUploader.current.click()}>Upload Image</ContextMenuItem>
                    </>
                  ) : (
                    <>
                      <ContextMenuItem onClick={() => imageUploader.current.click()}>Upload New Image</ContextMenuItem>
                      <ContextMenuItem onClick={handleOnremove}>
                        <span className={classes.deleteTxt}>Delete Image</span>
                      </ContextMenuItem>
                    </>
                  )}
                </Container>
              </ContextMenu>
            </Container>
            <Container flex gap={'10px'}>
              <TextField
                id={'first_name'}
                type="text"
                control={control}
                name={'first_name'}
                placeholder={'John'}
                label={'First Name*'}
                {...register('first_name', { required: true })}
                error={errors.first_name ? 'This Field required' : ''}
              />
              <TextField
                id={'last_name'}
                label={'Last Name*'}
                placeholder={'Doe'}
                type="text"
                name={'last_name'}
                {...register('last_name', { required: true })}
                error={errors.last_Name ? 'This Field required' : ''}
              />
            </Container>
            <Container className={classes.containerWidth}>
              <TextField
                id={'email'}
                type="email"
                name={'email'}
                placeholder="john.d@partsclub.com"
                label={'Email*'}
                control={control}
                {...register('email', { required: true })}
                error={getFormError(errors, 'email')}
              />
            </Container>
            <Spacing y={2} width={'56%'}>
              <Typography variant="paragraph2">Phone Number*</Typography>
              <DropDownTextField
                id={'phone_number'}
                options={options}
                control={control}
                type="number"
                name={'phone_number'}
                placeholder="1234 567 235"
                className={classes.contactInfo}
                {...register('phone_number', { required: true })}
                error={getFormError(errors, 'phone_number')}
              />
            </Spacing>
            <Seperator />
            <Typography variant="title1">Address</Typography>
            <Container className={classes.addressBox}>
              <Container>
                <Controller
                  name={'user_address'}
                  control={control}
                  render={({ field: { name } }) => (
                    <GooglePlaces
                      id={name}
                      name={name}
                      label="Location"
                      spacing={3}
                      placeholder={'e.g. 4517 Washington Ave'}
                      cmp_billing_addr
                      value={
                        getValues()?.user_address?.addr_line_1
                          ? getWellKnownAddress(
                              getValues()?.user_address?.addr_line_1,
                              getValues()?.user_address?.addr_line_2
                            )
                          : ''
                      }
                      onSelect={(loc, i) => {
                        setValue(
                          'user_address',
                          formatAddressOfGAPI(
                            loc.addr,
                            loc.addr_line_1,
                            loc.addr_line_2,
                            null,
                            getValues()?.user_address?.id
                          )
                        )
                      }}
                    />
                  )}
                />
              </Container>
            </Container>
          </Container>
          <Container className={classes.btnBox}>
            <Container className={classes.links}>
              <Link to="#" underline onClick={handlePassword}>
                Change Password
              </Link>
            </Container>
            <Button
              className={classes.button}
              disabled={disabled}
              variant="secondary"
              border
              // onClick={handleSave}
              type={'submit'}
            >
              Save Changes
            </Button>
          </Container>
        </Container>
      </form>

      <UserProfileDailog open={toggle} onclose={setToggle} />
    </Container>
  )
}

export default UserProfile
