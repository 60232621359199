import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import useToggle from 'common-hooks/useToggle'
import { FacebookColorIcon, LinkCopyIcon, LinkedInColorIcon, MailIcon } from 'assets/icons'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import Seperator from 'components/common/Seperator'
import IconButton from 'components/common/IconButton'
import EmailDiaglog from './EmailDiaglog'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  emailField: {
    border: ' 1px solid #F0F0F4',
    borderRadius: ' 4px',
    padding: '15px',
    display: 'flex',
    gap: '0 10px',
    cursor: 'pointer',
  },
  socialMediaBox: {
    width: '197px',
    padding: '15px!important',
    display: 'flex',
    flexDirection: 'column',
    border: ' 1px solid #F0F0F4!important',
    borderRadius: ' 4px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  IconButton: {
    background: 'unset',
    width: 'unset',
    height: 'unset',
    padding: '0',
    '&:hover': {
      background: 'unset',
    },
  },
  linkCopy: {
    border: ' 1px solid #C3C5D5',
    borderRadius: '2px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default function ShareViaDialog({ open, onClose, brandname, partname, parnumber, productid }) {
  const classes = useStyles()

  const [emailDialog, setEmailDialog] = useToggle(false)
  const productName = `${brandname} - ${partname} - ${parnumber}`.replace('null', '').replace('undefined', '')
  const copyData = `https://partsclub-fe-dev.web.app/parts/${productName}/${productid}`

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Share Product '} onClose={onClose} />
          <Container className={classes.selectField}>
            <Container
              className={classes.emailField}
              onClick={() => {
                setEmailDialog()
                onClose()
              }}
            >
              <MailIcon />
              <Container>
                <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                  Email
                </Typography>
                <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                  Add subject line and message.
                </Typography>
              </Container>
            </Container>

            <Container flex gap={'0 10px'} width={'100%'}>
              <Seperator />
              <Typography variant="overline" color={getColor('grey-g65')}>
                Or
              </Typography>
              <Seperator />
            </Container>
            <Container flex gap={'0 15px'}>
              <LinkedinShareButton className={classes.socialMediaBox} url={copyData}>
                <LinkedInColorIcon />
                <Typography variant="caption" color={getColor('grey-g35')}>
                  LinkedIn
                </Typography>
              </LinkedinShareButton>
              {/* <Container className={classes.socialMediaBox}>
                <FacebookColorIcon />
                <Typography>Facebook</Typography>
              </Container> */}
              <FacebookShareButton className={classes.socialMediaBox} url={copyData}>
                {' '}
                <FacebookColorIcon />
                <Typography variant="caption" color={getColor('grey-g35')}>
                  Facebook
                </Typography>
              </FacebookShareButton>
            </Container>
            <Container className={classes.linkCopy}>
              <Typography variant="paragraph1" color={'grey-g25'}>
                {copyData}
              </Typography>
              <IconButton className={classes.IconButton} onClick={() => navigator.clipboard.writeText(copyData)}>
                <LinkCopyIcon />
              </IconButton>
            </Container>
          </Container>
        </Dialog>
      </Container>
      <EmailDiaglog open={emailDialog} onClose={setEmailDialog} productid={productid} />
    </>
  )
}
ShareViaDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  productid: PropTypes.string,
  brandname: PropTypes.string,
  partname: PropTypes.string,
  parnumber: PropTypes.string,
}
