import React from 'react'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  root: {},
  imgResolution: {},
}))

export default function BrandsSlider({ className, speed, maxWidth, height, sliderData }) {
  const classes = useStyles({ maxWidth, height })

  const settings = {
    dots: false,
    infinite: true,
    speed: speed || 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    arrows: false,
    accessibility: true,
    focusOnSelect: true,
    draggable: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Slider {...settings} className={classNames(className, classes.root)}>
      {sliderData?.map((data, index) => (
        <Container key={index} className={classes.imgResolution}>
          <img src={data.src} alt={data.title} />
        </Container>
      ))}
    </Slider>
  )
}

BrandsSlider.propTypes = {
  className: PropTypes.string,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  sliderData: PropTypes.array,
  setActive: PropTypes.func,
  url: PropTypes.string,
  index: PropTypes.number,
  slickGoto: PropTypes.number,
}
