import {
  addShippingAddress,
  deleteShippingAddress,
  editShippingAddress,
  setDefaultShippingAddress,
} from 'common-api/settings/settings'
import useToastify from 'common-hooks/useToastify'
import { parseShippingData } from 'containers/settings/SettingsUtils'
import useShippingAddressList from './useShippingAddressList'

export default function useShippingSettings() {
  const { toastMessage } = useToastify()
  const { mutate } = useShippingAddressList()
  const addNewAddress = async (data, closeDialog) => {
    try {
      const response = await addShippingAddress(parseShippingData(data))
      toastMessage('success', 'Address added successfully')
      mutate()
      closeDialog()
      return response
    } catch {
      return false
    }
  }
  const editAddress = async (data, closeDialog) => {
    try {
      const response = await editShippingAddress(parseShippingData(data))
      toastMessage('success', 'Address updated successfully')
      mutate()
      closeDialog()
      return response
    } catch {
      return false
    }
  }

  const deleteAddress = async (data) => {
    try {
      const response = await deleteShippingAddress(data)
      toastMessage('success', 'Address removed successfully')
      mutate()
      return response
    } catch {
      return false
    }
  }

  const setDefaultAddress = async (data) => {
    try {
      const response = await setDefaultShippingAddress(data)
      toastMessage('success', 'Default shipping address changed successfully')
      mutate()
      return response
    } catch {
      return false
    }
  }

  return {
    addNewAddress,
    editAddress,
    deleteAddress,
    setDefaultAddress,
  }
}
