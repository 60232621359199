/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import Button from 'components/common/Button'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import * as yup from 'yup'
import { useState, React, useEffect } from 'react'
import Dropzone from 'components/custom/dropzone/Dropzone'
import Tooltip from 'components/common/Tooltip'
import inventoryProductEmpty from 'assets/images/inventoryProductEmpty.svg'
import {
  AddIcon,
  CaretLeftIcon,
  DeleteIcon,
  InfoSmallIcon,
  PencilIcon,
  ProfileInfoFillIcon,
  ShareIcon,
} from 'assets/icons'
import ContextMenuItem from 'components/custom/drop-downs/ContextMenuItem'
import ContextMenu from 'components/custom/drop-downs/ContextMenu'
import { filesAddKeys, IMAGE_MIME } from 'utils/Files'
import FileUpload from 'components/custom/dropzone/FileUpload'
import Image from 'components/common/Image'
import TextField from 'components/common/text/TextField'
import { Controller, useForm, useWatch } from 'react-hook-form'
import useToggle from 'common-hooks/useToggle'
import { yupResolver } from '@hookform/resolvers/yup'
import TextArea from 'components/common/text/TextArea'
import Seperator from 'components/common/Seperator'
import Select from 'components/common/select/Select'
import LabelTextField from 'components/common/text/LabelTextField'
import IconButton from 'components/common/IconButton'
import Chip from 'components/common/Chip'
import { getFormError } from 'utils/form/form'
import { useNavigate, useParams } from 'react-router-dom'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import { conditions, parseCrossRefNumbers, parseFitsWith, status } from './InventoryUtils'
import { CrossRefNumberDialog } from './CrossRefNumberDialog'
import RelatedProductDialog from './RelatedProductDialog'
import FitsWithDialog from './FitsWithDialog'
import ShareViaDialog from './ShareViaDialog'
import useProductDetails from 'common-hooks/inventory/useProductDetails'
import { createOption } from 'components/common/text/DropDownTextField'
import useAddProduct from 'common-hooks/inventory/useAddProduct'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import LoadingOverlay from 'components/common/LoadingOverlay'
import useToastify from 'common-hooks/useToastify'
import { formatPrice } from 'utils/Utils'
import PreviewListingDialog from './PreviewListingDialog'
import ConfirmationDialog from './ConfirmatationDialog'
import useOEMbrandList from 'common-hooks/super-admin/useOEMbrandList'

const useStyles = makeStyles(() => ({
  header: {
    padding: '12px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: ' 1px solid #F0F0F4',
  },
  headermain: {
    padding: '25px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: ' 1px solid #F0F0F4',
    '& button': {
      height: '30px',
    },
  },
  leftgrid: {
    padding: '30px',
    width: ' 25%',
    '& p': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  imageContainer: {
    width: '300px',
    // width: '84px',
    // height: '84px',
    // // margin: '30px 0px',
    // textAlign: 'center',

    // position: 'relative',

    display: 'flex',
    gap: '20px',
    flexFlow: 'wrap',
    '& .MuiButtonBase-root': {
      background: 'unset',
      position: 'absolute',
      top: '-5px',
      right: '-7px',
      display: 'none',
      // background: '#F0F0F4',
      '& svg': {
        '& rect': {
          fill: '#F0F0F4',
        },
        '& path': {
          fill: '#000',
        },
      },
    },
    '& img': {
      height: '84px',
      width: '84px',
      border: ' 1px solid #F0F0F4',
    },
  },
  dropZoneLogo: {
    width: '84px',
    display: 'flex',
    alignItems: 'center',
    height: '84px',
    borderColor: '#C3C5D5',
  },
  imgPosition: {
    position: 'relative',
    cursor: 'pointer',
    '& .makeStyles-textCover-45': {
      height: '84px',
      cursor: 'pointer',
    },
    '&:hover .MuiButtonBase-root': {
      display: 'block',
      zIndex: '999',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      background: '#00000096',
      display: 'none',
      zInded: '-1',
    },
    '&:hover::after': {
      display: 'block',
    },
  },
  imgwidth: {
    height: '84px',
  },
  coverImage: {
    width: ' 300px',
    height: '250px',
    border: '1px solid #F0F0F4',
    margin: '20px 0',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  middleGrid: {
    borderLeft: '1px solid #F0F0F4',
    borderRight: '1px solid #F0F0F4',
    width: '62%',
    height: '97vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  productpagegrid: {
    display: 'flex',
  },
  middleGrid1: {
    alignItems: 'unset',
    padding: '30px',
  },
  middleGrid2: {
    padding: '30px',
    alignItems: 'unset',
  },
  middleGrid2Inner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  middleGrid3: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  IconButton: {
    background: 'unset',
    padding: '0',
    width: 'unset',
    height: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  middleGrid4: {
    padding: '30px',
  },
  brandList: {
    marginTop: '20px',
  },
  chipContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '0 5px',
  },
  middleGrid5: {
    padding: '30px',
  },
  brandList1: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
    marginTop: '20px',
  },
  relatedProductCard: {
    position: 'relative',
    cursor: 'pointer',
    width: '206px',
    // height: '244px',
    border: '1px solid #F0F0F4 ',
    // marginBottom: '20px',
    '& img': {
      width: '206px',
      height: '130px',
      borderBottom: '1px solid #F0F0F4',
    },
    '& h5': {
      marginTop: '10px',
    },
    '& .deleteBtn': {
      position: 'absolute',
      top: '0',
      right: '0',
      display: 'none',
    },
    '&:hover .deleteBtn': {
      display: 'block',
    },
  },
  imgContent: {
    padding: '10px',
  },
  relatedProductGrid: {
    paddingTop: '20px',
    display: 'flex',
    flexFlow: 'wrap',
    gap: '0 20px',
  },
  middleGrid6: {
    padding: '30px',
  },
  rightGrid1: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  rightGrid: {
    width: '25.5%',
    height: '93.5vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  rightGrid2: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px 0',
  },
  rightGrid3: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px 0',
    padding: '30px',
  },
  activeChip: {
    color: '#097309',
    background: '#E0FFE0',
    borderRadius: '100px',
  },
  instockChip: {
    color: '#19528C',
    background: '#E0F0FF',
    borderRadius: '100px',
  },
  deleteTxt: {
    color: '#AD1F36',
  },
  blueLinkBtn: {
    background: 'unset',
    height: 'unset',
    minHeight: 'unset',
    padding: 'unset',
    width: 'unset',
    color: '#2680D9',
    lineHeight: '12px',
    borderBottom: '1px solid #2680D9',
    borderRadius: 'unset',
    fontSize: '11px',
    '&:hover': {
      background: 'unset',
    },
    '& span': {
      fontSize: '11px',
      lineHeight: '11px',
    },
  },
  pricingGrid: {
    display: 'flex',
    gap: '0 20px',
  },
  pricingInnerGrid: {
    border: ' 1px solid #F0F0F4',
    borderRadius: '4px',
    background: '#F8F9F9',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
    height: '111px',
    width: '100%',
  },
  pricingInnerGrid2: {
    border: ' 1px solid #F0F0F4',
    borderRadius: '4px',
    background: '#F8F9F9',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
    height: '64px',
    width: '100%',
  },
  pricingGridLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
  },
  msrpPrice: {
    textDecorationLine: 'line-through',
    color: '#9799B5',
  },
  crossRoot: {
    padding: '20px 0',
  },
  bluelink: {
    color: '#2680D9',
    '& a': {
      color: '#2680D9',
    },
  },
  shareBtn: {
    display: 'flex',
    paddingTop: '5px!important',
  },
  saveChnagesBtn: {
    background: '#F0F0F4',
    color: '#9799B5!important',
    '&:hover': {
      background: '#F0F0F4',
      color: '#9799B5!important',
    },
  },
}))

export default function AddProduct() {
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const { toastMessage } = useToastify()
  const { productDetails, mutate, isLoading } = useProductDetails()
  const { addProduct, deleteRelatedProduct, deleteProductImage, coverProductImage } = useAddProduct()
  const { brandList } = useOEMbrandList()
  const brandOption = brandList?.map((obj) => ({ id: obj.id, label: obj.name, img: obj?.cover_image }))
  const [productImages, setProductImages] = useState([])
  const [crossRefNumbers, setCrossRefNumbers] = useState([])
  const [editCrossRefNumbers, setEditCrossRefNumber] = useState()
  const [FitsWithData, setFitsWithData] = useState([])
  const unitFilterRelatedData = []
  const [unitPrice, setUnitPrice] = useState('00.00')
  const [unitPriceFlag, setUnitPriceFlage] = useState(false)
  const [titleFlag, setTitleFlag] = useState(false)
  const [flag, setflag] = useState(true)
  const [productResponse, setProductResponse] = useState(false)

  const [editFitsWithData, setEditFitsWithData] = useState()
  const [relatedProducts, setRelatedProducts] = useState([])
  const [coverImage, setCoverImage] = useState('')
  const [coverActiveImage, setCoverActiveImage] = useState(null)

  const [relatedProductDialog, setRelatedProductDialog] = useToggle(false)
  const [previewListingDialog, setPreviewListingDialog] = useToggle(false)
  const [crossRefDialog, setCrossRefDialog] = useToggle(false)
  const [fitsWithDialog, setFitsWithDialog] = useToggle(false)
  const [shareViaDialog, setShareViaDialog] = useToggle(false)
  const [confirmationDialog, setConfirmationDialog] = useToggle(false)

  const schema = yup.object({
    title: yup.string().required('Title is required.'),
    part_no: yup.string().required('PartNumber is required.'),
    // unit_price: yup.number().required('Unit Price is required.'),
    part_desp: yup.string().required('Part Description is required.'),
    msrp: yup.string().required('MSRP Price is required.'),
  })

  const {
    control,
    handleSubmit,
    setValue,
    // formState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      msrp: '00.00',
      qty: '0',
      enable_buy_now: true,
      enable_make_offer: true,
      is_returnable: true,
      has_warranty: true,
      is_cancellable: true,
    },
  })

  const [msrp, qty, available_in, brandname, partdesp, partno] = useWatch({
    control,
    name: ['msrp', 'qty', 'available_in', 'brand_name', 'part_desp', 'part_no'],
  })

  // eslint-disable-next-line consistent-return
  const handleProductImages = (name, newFiles) => {
    const filesArr = filesAddKeys(newFiles)
    const MAX_FILE_SIZE = 2048 // 2MB
    const fileSizeKiloBytes = filesArr?.map((obj) => obj?.file?.size / 1024)

    if (fileSizeKiloBytes?.[0] > MAX_FILE_SIZE) {
      toastMessage('error', 'Please upload an image smaller than 2 MB')
      return 0
    }

    setProductImages([...productImages, ...filesArr])
    setflag(false)
  }

  const fillterRelatedProduct = relatedProducts?.filter((element) => {
    const isDuplicate = unitFilterRelatedData.includes(element.value || element.related_product_id)

    if (!isDuplicate) {
      unitFilterRelatedData.push(element.value || element.related_product_id)
      return true
    }
    return false
  })

  // eslint-disable-next-line consistent-return
  const onSubmit = async (data) => {
    if (data?.brand_name === undefined || data?.brand_name === '') {
      toastMessage('error', 'Brand is required')
      return 0
    }
    if (data?.condition === undefined || data?.condition === '') {
      toastMessage('error', 'Condition is required')
      return 0
    }
    if (data?.status === undefined || data?.status === '') {
      toastMessage('error', 'Status is required')
      return 0
    }
    if (unitPrice === undefined || unitPrice === '') {
      toastMessage('error', 'Unit Price is required')
      return 0
    }
    if (data?.qty === '0' && (!data?.available_in || data?.available_in === '0')) {
      toastMessage('error', '"Available In" is required and should greater then 0')
      return 0
    }
    if (Number(unitPrice) === 0) {
      toastMessage('error', 'Unit price should be more than zero')
      return 0
    }
    const response = await addProduct(
      data,
      unitPrice,
      crossRefNumbers,
      FitsWithData,
      fillterRelatedProduct,
      productImages,
      mutate
    )
    if (response) {
      setProductResponse(true)
    }
  }

  useEffect(() => {
    if (productDetails) {
      const crossRefData = parseCrossRefNumbers(productDetails)
      const fitsWithData = parseFitsWith(productDetails)
      Object.keys(productDetails).map((key) => {
        if (key === 'cross_refs' && productDetails?.cross_refs !== undefined) {
          setCrossRefNumbers(crossRefData)
        } else if (key === 'fits_with' && productDetails?.fits_with !== undefined) {
          setFitsWithData(fitsWithData)
        } else if (['brand_name'].includes(key)) {
          setValue(key, createOption(productDetails[key], productDetails[key]))
        } else if (['status'].includes(key)) {
          if (productDetails[key] === 'active') {
            setValue(key, createOption('active', 'Active'))
          } else {
            setValue(key, createOption('inactive', 'Inactive'))
          }
        } else if (['condition'].includes(key)) {
          if (productDetails[key] === 'new_aftermarket') {
            setValue(key, createOption('new_aftermarket', 'New Aftermarket'))
          } else if (productDetails[key] === 'new_oem') {
            setValue(key, createOption('new_oem', 'New OEM'))
          } else {
            setValue(key, createOption('used', 'Used'))
          }
        } else if (key === 'related_prods' && productDetails?.related_prods !== undefined) {
          setRelatedProducts(productDetails?.related_prods)
        } else if (key === 'unit_price') {
          setUnitPrice(productDetails?.unit_price)
        } else if (key === 'prod_assets' && productDetails?.prod_assets !== undefined) {
          setProductImages(productDetails?.prod_assets)
        } else {
          setValue(key, productDetails[key])
        }
        return 0
      })
      setCoverImage([productDetails?.cover_image])
      setCoverActiveImage(productDetails?.cover_image)
    }
  }, [productDetails])

  if (productImages?.length > 4) {
    productImages.length = 5
  }

  // let coverURL = ''
  // if (productImages?.length > 0 && !productDetails?.cover_image) {
  //   coverURL = URL.createObjectURL(productImages?.[0]?.file)
  // }

  useEffect(() => {
    if (unitPriceFlag) {
      const msrpCalculation = unitPrice * Number(1 + 0.28)
      if (msrpCalculation !== '') {
        setValue('msrp', msrpCalculation)
        setUnitPriceFlage(false)
      }
    }
  }, [unitPrice, unitPriceFlag])

  useEffect(() => {
    if (titleFlag && brandname && partdesp && partno) {
      setValue('title', `${brandname?.label && `${brandname?.label} -`} ${partdesp && `${partdesp} -`} ${partno}`)
    }
  }, [brandname, partdesp, partno, titleFlag])

  useEffect(() => {
    if (productResponse) {
      setflag(true)
      setProductResponse(false)
    }
  }, [productResponse])

  return (
    <Container>
      {params?.id && isLoading && <LoadingOverlay />}
      <form onSubmit={handleSubmit(onSubmit)} id={'product-form'}>
        <Container className={classes.productPageroot}>
          {params.id ? (
            <Container className={classes.headermain}>
              <Container flex gap={'0 10px'}>
                <Button
                  variant={'secondary'}
                  width={'30px'}
                  border
                  icon={<CaretLeftIcon />}
                  onClick={() => {
                    if (!flag) {
                      setConfirmationDialog()
                    } else {
                      navigate('/inventory')
                    }
                  }}
                />
                <Container>
                  <Typography variant="h1" color={getColor('grey-g25')}>
                    {productDetails?.brand_name} - {productDetails?.part_desp} - {productDetails?.part_no}
                  </Typography>
                  <Container flex gap={'0 10px'} justify="flex-start">
                    {productDetails?.status === 'active' && <Chip label="Active" className={classes.activeChip} />}
                    {productDetails?.status === 'inactive' && <Chip label="Inactive" variant="error" />}
                    {productDetails?.stock === true && <Chip label="In Stock" className={classes.instockChip} />}
                    {productDetails?.stock === false && <Chip label="Out of Stock" />}•
                    <Container flex>
                      <Typography variant="body1" color={getColor('grey-g65')}>
                        Last updated on {formatDate(productDetails?.created_atcreated_at, DATE_FORMAT_PRIMARY)}{' '}
                      </Typography>
                      {/* <Tooltip
                        title={
                          'Your product is expired and hidden from buyers cause you haven’t updated it since last 30 days. To reactivate your product you have to update any details.'
                        }
                      >
                        <InfoSmallIcon />
                      </Tooltip> */}
                    </Container>
                    {/* •
                    <Typography variant="body1" color={getColor('grey-g65')}>
                      {' '}
                      Expired on mm/dd/yyyy
                    </Typography>
                    <Tooltip title="Product is going to expires if there are no updates within 30 days. To reactivate your product you have to update any details.">
                      <InfoSmallIcon />
                    </Tooltip> */}
                  </Container>
                </Container>
              </Container>

              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border onClick={setPreviewListingDialog}>
                  Preview Listing
                </Button>
                <Button className={classes.shareBtn} variant="secondary" border onClick={setShareViaDialog}>
                  <ShareIcon />
                </Button>

                {/* {flag === false ? (
                  <Button disabled={flag} className={flag ? classes.saveChnagesBtn : ''} type="submit">
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    disabled={!formState.isValid}
                    className={!formState.isValid ? classes.saveChnagesBtn : ''}
                    type="submit"
                  >
                    Save Changes
                  </Button>
                )} */}
                <Button disabled={flag} className={flag ? classes.saveChnagesBtn : ''} type="submit">
                  Save Changes
                </Button>
              </Container>
            </Container>
          ) : (
            <Container className={classes.header}>
              <Button variant={'secondary'} border onClick={() => navigate('/inventory')}>
                Cancel
              </Button>
              <Typography variant="title" color={getColor('grey-g25')}>
                New Product
              </Typography>
              <Button type="submit">Add</Button>
            </Container>
          )}

          <Container className={classes.productpagebody}>
            <Container className={classes.productpagegrid}>
              <Container className={classes.leftgrid}>
                <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                  Images
                  <Tooltip title={'Supported image file format are .jpg and .png, max size 2mb.'} maxWidth={'450px'}>
                    <InfoSmallIcon />
                  </Tooltip>
                </Typography>
                <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                  Upload up to 5 images of product
                </Typography>
                <Container className={classes.coverImage}>
                  <Image src={coverImage[0] || inventoryProductEmpty} key={coverImage[0]} alt={coverImage[0]} />
                </Container>
                <Container className={classes.imageContainer}>
                  {productImages?.map((file, index) => {
                    const props = {
                      url: file.file ? URL.createObjectURL(file.file) : file?.path,
                      index,
                      assetId: file.id,
                      indexImage: 0,
                      disableDropdown: true,
                      deleteAddImage: false,
                    }
                    return (
                      <Container className={classes.imgPosition} key={index}>
                        <FileUpload
                          key={index}
                          coverActiveImage={coverActiveImage}
                          height={'84px'}
                          imgHeight={'86px'}
                          imgwidth={'86px'}
                          removeImage={() => {
                            // setValue('logoImage', [])
                          }}
                          {...props}
                        />
                        {productImages && productImages?.length > 0 && (
                          <ContextMenu
                            className={classes.editList}
                            closeOnClick
                            trigger={<Button icon={<ProfileInfoFillIcon />} />}
                          >
                            <Container className={classes.lists}>
                              <ContextMenuItem>
                                <Button
                                  className={classes.onHoverBtn}
                                  variant="secondary"
                                  onClick={() => {
                                    setCoverActiveImage(props?.url)
                                    setCoverImage([props?.url])
                                    if (file.id && file.path) {
                                      coverProductImage(file.id, mutate)
                                    }
                                  }}
                                >
                                  Make Cover
                                </Button>
                              </ContextMenuItem>
                              <ContextMenuItem>
                                <Button
                                  variant="secondary"
                                  className={classes.deleteTxt}
                                  onClick={() => {
                                    setCoverImage(coverImage?.filter((d) => d !== file?.path))
                                    setCoverActiveImage(null)
                                    setProductImages(productImages.filter((d) => d.id !== file.id))
                                    if (file?.id && file?.path) {
                                      deleteProductImage(file.id, mutate)
                                    }
                                  }}
                                >
                                  Delete
                                </Button>
                              </ContextMenuItem>
                            </Container>
                          </ContextMenu>
                        )}
                      </Container>
                    )
                  })}
                  {productImages?.length > 4 ? (
                    ''
                  ) : (
                    <Dropzone
                      id={'drop-zone-logo'}
                      dropzoneClassName={classes.dropZoneLogo}
                      multiple
                      accept={IMAGE_MIME}
                      onChange={(e) => handleProductImages('productImage', e)}
                      actionComponent={<AddIcon />}
                    />
                  )}
                </Container>
              </Container>
              <Container className={classes.middleGrid}>
                <Container className={classes.middleGrid2} flex direction="column" gap={'20px 0'}>
                  <Container>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Product Details
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                      Provide basic details about product
                    </Typography>
                  </Container>
                  <Container className={classes.middleGrid2Inner}>
                    <Container flex justify="space-between">
                      <Controller
                        name={'brand_name'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <Select
                            options={brandOption}
                            id={name}
                            value={value}
                            placeholder={'Type or select'}
                            width={'49%'}
                            name={name}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                              setTitleFlag(true)
                            }}
                            label={'Brand*'}
                          />
                        )}
                      />
                      <Controller
                        name={'part_desp'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <TextField
                            id={name}
                            value={value}
                            placeholder={'e.g. Bearing'}
                            name={name}
                            width={'49%'}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                              setTitleFlag(true)
                            }}
                            label={'Part Description*'}
                            error={getFormError(errors, name)}
                          />
                        )}
                      />
                    </Container>
                    <Container flex justify="space-between">
                      <Controller
                        name={'part_no'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <TextField
                            id={name}
                            value={value}
                            placeholder={'Type'}
                            name={name}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                              setTitleFlag(true)
                            }}
                            width={'49%'}
                            label={'Part Number*'}
                            error={getFormError(errors, name)}
                          />
                        )}
                      />
                      <Controller
                        name={'condition'}
                        control={control}
                        defaultValue={{ label: 'New Aftermarket', value: 'new_aftermarket' }}
                        render={({ field: { name, value, onChange } }) => (
                          <Select
                            options={conditions}
                            id={name}
                            value={value}
                            placeholder={'Type or select'}
                            width={'23%'}
                            name={name}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                            }}
                            label={'Condition*'}
                          />
                        )}
                      />
                      <Container width={'23%'}>
                        <Container flex justify="flex-start" gap={'0 2px'}>
                          <Typography variant="paragraph2" color={getColor('grey-g25')}>
                            Weight
                          </Typography>
                          <Tooltip
                            maxWidth={'500px'}
                            title={
                              'Input either the physical weight or the dimensional weight - whichever is greater. This will be used to calculate shipping rates.'
                            }
                          >
                            <InfoSmallIcon />
                          </Tooltip>
                        </Container>
                        <Controller
                          name={'weight'}
                          control={control}
                          render={({ field: { name, value, onChange } }) => (
                            <LabelTextField
                              labelText={'lbs'}
                              lablePosition={'right'}
                              id={name}
                              value={value}
                              placeholder={'00'}
                              name={name}
                              onChange={(value) => {
                                onChange(value)
                                setflag(false)
                              }}
                            />
                          )}
                        />
                      </Container>
                    </Container>
                  </Container>
                </Container>
                <Seperator />
                <Container className={classes.middleGrid1} flex gap={'20px 0'} direction={'column'}>
                  <Container>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Title & Description
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                      Provide title & description to display to buyers
                    </Typography>
                  </Container>
                  <Controller
                    name={'title'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <TextField
                        id={name}
                        value={value}
                        placeholder={'e.g. John Deere Bearing A320B'}
                        name={name}
                        onChange={(value) => {
                          onChange(value)
                          setflag(false)
                        }}
                        label={'Title*'}
                        error={getFormError(errors, name)}
                      />
                    )}
                  />
                  <Container width={'100%'}>
                    <Typography variant="paragraph2" color={getColor('grey-g25')}>
                      Description
                    </Typography>
                    <Controller
                      name={'desp'}
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <TextArea
                          id={name}
                          value={value}
                          placeholder={'Write description here...'}
                          name={name}
                          onChange={(value) => {
                            onChange(value)
                            setflag(false)
                          }}
                        />
                      )}
                    />
                  </Container>
                </Container>
                <Seperator />
                <Container className={classes.middleGrid3}>
                  <Container>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Price & Quantity
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                      Adjust price and quantity for buyers
                    </Typography>
                  </Container>
                  <Container flex justify="flex-start" gap={'0 20px'}>
                    <Container width={'150px'}>
                      <Typography variant="paragraph2" color={getColor('grey-g25')}>
                        Unit Price*
                      </Typography>
                      {/* <Controller
                        name={'unit_price'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <LabelTextField
                            labelText={'$'}
                            lablePosition={'left'}
                            id={name}
                            value={value}
                            placeholder={'00.00'}
                            name={name}
                            onChange={onChange}
                            error={getFormError(errors, name)}
                          />
                        )}
                      /> */}
                      <LabelTextField
                        labelText={'$'}
                        lablePosition={'left'}
                        value={unitPrice}
                        placeholder={'00.00'}
                        onChange={(e) => {
                          setUnitPrice(e)
                          setUnitPriceFlage(true)
                          setflag(false)
                        }}
                      />
                    </Container>
                    <Container width={'150px'}>
                      <Container flex justify="flex-start" gap={'0 2px'}>
                        <Typography variant="paragraph2" color={getColor('grey-g25')}>
                          MSRP Price
                        </Typography>
                        {/* <Tooltip
                          maxWidth={'500px'}
                          title={
                            'Manufacturer’s suggested retail price is not the price that customer will pay for. It help to generate stikrout price. ex. $100.00 $120.99'
                          }
                        >
                          <InfoSmallIcon />
                        </Tooltip> */}
                      </Container>
                      <Controller
                        name={'msrp'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <LabelTextField
                            labelText={'$'}
                            lablePosition={'left'}
                            id={name}
                            value={value}
                            placeholder={'00.00'}
                            name={name}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                            }}
                            error={getFormError(errors, name)}
                          />
                        )}
                      />
                    </Container>
                    <Controller
                      name={'qty'}
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <TextField
                          id={name}
                          value={value}
                          placeholder={'0'}
                          name={name}
                          width={'150px'}
                          onChange={(value) => {
                            onChange(value)
                            setflag(false)
                          }}
                          label={'Available Qty.'}
                        />
                      )}
                    />
                    {Number(qty) === 0 && (
                      <Container width={'150px'}>
                        <Container flex justify="space-between">
                          <Typography variant="paragraph2" color={getColor('grey-g25')}>
                            Available In
                          </Typography>
                        </Container>
                        <Controller
                          name={'available_in'}
                          control={control}
                          render={({ field: { name, value, onChange } }) => (
                            <LabelTextField
                              labelText={'Days'}
                              lablePosition={'right'}
                              id={name}
                              value={value}
                              disabled={Number(qty) !== 0}
                              placeholder={'0'}
                              name={name}
                              onChange={(value) => {
                                onChange(value)
                                setflag(false)
                              }}
                              error={getFormError(errors, name)}
                            />
                          )}
                        />
                      </Container>
                    )}
                  </Container>
                  {Number(unitPrice) === 0 || Number(msrp) === 0 || Number(qty) === 0 ? (
                    <Container className={classes.pricingGrid}>
                      <Container className={classes.pricingGridLeft} width="50%">
                        <Container flex justify="flex-start" gap={'0 5px'}>
                          <Typography variant="paragraph2" color={getColor('grey-g25')} component={'p'}>
                            PartsClub+ Buyer Preview
                          </Typography>
                          {/* <Tooltip title={''}>
                            <InfoSmallIcon />
                          </Tooltip> */}
                        </Container>
                        <Container className={classes.pricingInnerGrid2}>
                          <Container>
                            <Container flex gap="0 2px" justify="flex-start">
                              <Typography variant="paragraph2" color={'#2680D9'} component={'p'}>
                                Get pricing
                              </Typography>
                              <Typography variant="paragraph2" color={'#9799B5'} component={'p'}>
                                for this product
                              </Typography>
                            </Container>
                            <Typography variant="caption" color={'#AD1F36'} component={'p'}>
                              Available in {available_in} days
                            </Typography>
                          </Container>
                        </Container>
                      </Container>
                      <Container className={classes.pricingGridLeft} width="50%">
                        <Container flex justify="flex-start" gap={'0 5px'}>
                          <Typography variant="paragraph2" color={getColor('grey-g25')} component={'p'}>
                            Free Buyer Preview
                          </Typography>
                          {/* <Tooltip title={''}>
                            <InfoSmallIcon />
                          </Tooltip> */}
                        </Container>
                        <Container className={classes.pricingInnerGrid2}>
                          <Container>
                            <Container flex gap="0 2px" justify="flex-start">
                              <Typography variant="paragraph2" color={'#2680D9'} component={'p'}>
                                Get pricing
                              </Typography>
                              <Typography variant="paragraph2" color={'#9799B5'} component={'p'}>
                                for this product
                              </Typography>
                            </Container>
                            <Typography variant="caption" color={'#AD1F36'} component={'p'}>
                              Available in {available_in} days
                            </Typography>
                          </Container>
                        </Container>
                      </Container>
                    </Container>
                  ) : (
                    <Container className={classes.pricingGrid}>
                      <Container className={classes.pricingGridLeft} width="50%">
                        <Container flex justify="flex-start" gap={'0 5px'}>
                          <Typography variant="paragraph2" color={getColor('grey-g25')} component={'p'}>
                            PartsClub+ Buyer Preview
                          </Typography>
                          {/* <Tooltip title={''}>
                            <InfoSmallIcon />
                          </Tooltip> */}
                        </Container>
                        <Container className={classes.pricingInnerGrid}>
                          <Container>
                            <Typography
                              variant="paragraph2"
                              color={getColor('grey-g65')}
                              component={'p'}
                              className={classes.msrpPrice}
                            >
                              MSRP: {formatPrice(msrp)}
                            </Typography>
                            <Typography variant="h2" color={getColor('grey-g25')} component={'p'}>
                              ${unitPrice}
                            </Typography>
                            <Typography variant="caption" color={'#0DA50D'} component={'p'}>
                              Only {qty} left in stock
                            </Typography>
                          </Container>
                        </Container>
                      </Container>
                      <Container className={classes.pricingGridLeft} width="50%">
                        <Container flex justify="flex-start" gap={'0 5px'}>
                          <Typography variant="paragraph2" color={getColor('grey-g25')} component={'p'}>
                            Free Buyer Preview
                          </Typography>
                          {/* <Tooltip title={''}>
                            <InfoSmallIcon />
                          </Tooltip> */}
                        </Container>
                        <Container className={classes.pricingInnerGrid}>
                          <Container>
                            <Typography
                              variant="paragraph2"
                              color={getColor('grey-g65')}
                              component={'p'}
                              className={classes.msrpPrice}
                            >
                              MSRP: {formatPrice(msrp)}
                            </Typography>
                            <Typography variant="h2" color={getColor('grey-g25')} component={'p'}>
                              {formatPrice(unitPrice * Number(1 + 0.05))}
                            </Typography>
                            <Typography variant="caption" color={'#0DA50D'} component={'p'}>
                              Only {qty} left in stock
                            </Typography>
                          </Container>
                          <Container flex gap="0 3px" justify="flex-start">
                            <Typography variant="caption" color={getColor('grey-g65')} component={'p'}>
                              Save {formatPrice(unitPrice * Number(1 + 0.05) - unitPrice)} instantly by upgrading to{' '}
                            </Typography>
                            <Typography className={classes.blueLinkBtn}>PartsClub+</Typography>
                          </Container>
                        </Container>
                      </Container>
                    </Container>
                  )}
                </Container>
                <Seperator />
                <Container className={classes.middleGrid4}>
                  <Container flex justify="space-between">
                    <Container>
                      <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                        Cross-References
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        Show buyers interchangeable part numbers for this product
                      </Typography>
                    </Container>
                    <IconButton className={classes.IconButton} onClick={() => setCrossRefDialog()}>
                      <AddIcon />
                    </IconButton>
                  </Container>
                  {crossRefNumbers?.length > 0 && (
                    <Container className={classes.brandList}>
                      {crossRefNumbers?.map((data, index) => (
                        <Container key={index}>
                          <Container>
                            <Container flex justify="space-between">
                              <Typography variant="body1" color={getColor('grey-g25')}>
                                {data?.brand_name?.label ? data?.brand_name?.label : data?.brand_name}
                              </Typography>
                              <IconButton className={classes.IconButton}>
                                <PencilIcon
                                  onClick={() => {
                                    setEditCrossRefNumber({ ...data, editIndex: index })
                                    setCrossRefDialog()
                                  }}
                                />
                              </IconButton>
                            </Container>
                            <Container className={classes.chipContainer}>
                              {data?.partnumber?.map((d, idx) => (
                                <>
                                  <Chip label={`${d?.label || d} `} />
                                  {/* {data?.partnumber?.length >= 2 && <> •</>} */}
                                  {idx !== data?.partnumber?.length - 1 && <>•</>}
                                </>
                              ))}
                            </Container>
                          </Container>
                          {index !== crossRefNumbers?.length - 1 && (
                            <Container className={classes.crossRoot}>
                              <Seperator />
                            </Container>
                          )}
                        </Container>
                      ))}
                    </Container>
                  )}
                </Container>
                <Seperator />
                <Container className={classes.middleGrid5}>
                  <Container flex justify="space-between">
                    <Container>
                      <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                        Fits With
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        Show buyers what equipment this part was used on
                      </Typography>
                    </Container>
                    <IconButton className={classes.IconButton} onClick={setFitsWithDialog}>
                      <AddIcon />
                    </IconButton>
                  </Container>
                  {FitsWithData?.map((data, index) => (
                    <Container className={classes.brandList1} key={index}>
                      <Container>
                        <Container flex justify="space-between">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {data?.brand_name?.label ? data?.brand_name?.label : data?.brand_name}
                          </Typography>
                          <IconButton
                            className={classes.IconButton}
                            onClick={() => {
                              setEditFitsWithData({ ...data, editIndex: index })
                              setFitsWithDialog()
                            }}
                          >
                            <PencilIcon />
                          </IconButton>
                        </Container>
                        <Container className={classes.chipContainer}>
                          {data?.modelNumbers?.map((md, index) => (
                            <Container key={index} flex gap={'0 5px'}>
                              <Chip label={`Model : ${md?.model_no}`} />
                              {md?.serial_rang_from && md?.serial_rang_to && (
                                <Chip label={`Serial Range : ${md?.serial_rang_from} - ${md?.serial_rang_to}`} />
                              )}{' '}
                              {index !== data?.modelNumbers?.length - 1 && <>•</>}
                            </Container>
                          ))}
                        </Container>
                      </Container>
                      {index !== FitsWithData?.length - 1 && <Seperator />}
                    </Container>
                  ))}
                </Container>
                <Seperator />
                <Container className={classes.middleGrid6}>
                  <Container flex justify="space-between">
                    <Container>
                      <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                        Related Products
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        Show other products from your inventory on this listing to upsell & cross-sell buyers
                      </Typography>
                    </Container>
                    <IconButton className={classes.IconButton} onClick={setRelatedProductDialog}>
                      <AddIcon />
                    </IconButton>
                  </Container>
                  <Container className={classes.relatedProductGrid}>
                    {fillterRelatedProduct?.map((obj, index) => (
                      <>
                        <Container className={classes.relatedProductCard}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`/inventory/editproduct/${obj?.related_product_id}`}
                          >
                            <Container
                              className={classes.relatedProducinner}
                              // onClick={() => navigate(`/inventory/editproduct/${obj?.id}`)}
                            >
                              <Image src={obj?.cover_image} />
                              <Container className={classes.imgContent}>
                                <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                                  {obj?.product_title || obj?.label}
                                </Typography>
                                <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                                  {/* {obj?.product_condition ? obj?.product_condition : '-' || obj?.condition} */}

                                  {(obj?.product_condition === 'new_aftermarket' && 'New Aftermarket') ||
                                    (obj?.condition === 'new_aftermarket' && 'New Aftermarket')}

                                  {(obj?.product_condition === 'new_oem' && 'New OEM') ||
                                    (obj?.condition === 'new_oem' && 'New OEM')}

                                  {(obj?.product_condition === 'used' && 'Used') ||
                                    (obj?.condition === 'used' && 'Used')}
                                </Typography>
                                <Typography variant="title" color={getColor('grey-g25')} component={'h5'}>
                                  {obj.price ? `$ ${obj?.price} ` : `$ ${obj?.product_unit_price} `}
                                </Typography>
                              </Container>
                            </Container>
                          </a>
                          <IconButton
                            className={'deleteBtn'}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (obj?.id) {
                                deleteRelatedProduct(obj.id, mutate)
                              }
                              setRelatedProducts(fillterRelatedProduct.filter((ob, id) => id !== index))
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Container>
                      </>
                    ))}
                  </Container>
                </Container>
              </Container>
              <Container className={classes.rightGrid}>
                <Container className={classes.rightGrid1}>
                  <Container>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Product Status
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                      Select product status
                    </Typography>
                  </Container>
                  <Controller
                    name={'status'}
                    control={control}
                    defaultValue={{ label: 'Active', value: 'active' }}
                    render={({ field: { name, value, onChange } }) => (
                      <Select
                        options={status}
                        id={name}
                        value={value}
                        placeholder={'select'}
                        width={'100%'}
                        name={name}
                        onChange={(value) => {
                          onChange(value)
                          setflag(false)
                        }}
                        label={'Status*'}
                      />
                    )}
                  />
                </Container>
                <Seperator />
                <Container className={classes.rightGrid2}>
                  <Container>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Marketplace
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                      Choose how buyers interact with this item
                    </Typography>
                  </Container>
                  <Container flex direction="column" gap={'20px 0'} width={'100%'}>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container>
                        <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                          ‘Buy Now’ button
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                          Let buyers self-serve online
                        </Typography>
                      </Container>
                      <Controller
                        name={'enable_buy_now'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <SwitchToggle
                            name={name}
                            defaultChecked={value}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                            }}
                            id={'buynowbtn'}
                          />
                        )}
                      />
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container>
                        <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                          ‘Send Offer’ button
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                          Let buyers send you offers
                        </Typography>
                      </Container>
                      <Controller
                        name={'enable_make_offer'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <SwitchToggle
                            name={name}
                            defaultChecked={value}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                            }}
                            id={'makeofferbtn'}
                          />
                        )}
                      />
                    </Container>
                  </Container>
                </Container>
                <Seperator />
                <Container className={classes.rightGrid3}>
                  <Container>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Special Conditions
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                      Default conditions set in{' '}
                      <a className={classes.bluelink} target="_blank" rel="noreferrer" href="/settings/company">
                        Company Settings
                      </a>
                    </Typography>
                  </Container>
                  <Container flex direction="column" gap={'20px 0'} width={'100%'}>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container>
                        <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                          Returnable
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                          30-day
                        </Typography>
                      </Container>
                      <Controller
                        name={'is_returnable'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <SwitchToggle
                            name={name}
                            defaultChecked={value}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                            }}
                            id={'Returnable'}
                          />
                        )}
                      />
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container>
                        <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                          Warranty
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                          365 days
                        </Typography>
                      </Container>
                      <Controller
                        name={'has_warranty'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <SwitchToggle
                            name={name}
                            defaultChecked={value}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                            }}
                            id={'Warranty'}
                          />
                        )}
                      />
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container>
                        <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                          Cancellable
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                          Prior to shipment
                        </Typography>
                      </Container>
                      <Controller
                        name={'is_cancellable'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <SwitchToggle
                            name={name}
                            defaultChecked={value}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                            }}
                            id={'Cancellable'}
                          />
                        )}
                      />
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container>
                        <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                          LTL Freight Required
                        </Typography>
                      </Container>
                      <Controller
                        name={'ltl_freight_required'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <SwitchToggle
                            name={name}
                            onChange={(value) => {
                              onChange(value)
                              setflag(false)
                            }}
                            defaultChecked={value}
                            id={'LTLFreight'}
                          />
                        )}
                      />
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
        <RelatedProductDialog
          open={relatedProductDialog}
          onClose={setRelatedProductDialog}
          setRelatedProducts={setRelatedProducts}
          relatedProducts={relatedProducts}
          mutate={mutate}
          setflag={setflag}
        />
        <CrossRefNumberDialog
          open={crossRefDialog}
          onClose={setCrossRefDialog}
          setCrossRefNumbers={setCrossRefNumbers}
          crossRefNumbers={crossRefNumbers}
          editCrossRefNumbers={editCrossRefNumbers}
          setEditCrossRefNumber={setEditCrossRefNumber}
          mutate={mutate}
          setflag={setflag}
        />
        <FitsWithDialog
          open={fitsWithDialog}
          onClose={setFitsWithDialog}
          setFitsWithData={setFitsWithData}
          editFitsWithData={editFitsWithData}
          setEditFitsWithData={setEditFitsWithData}
          mutate={mutate}
          setflag={setflag}
        />

        <PreviewListingDialog
          onClose={setPreviewListingDialog}
          open={previewListingDialog}
          searchDetails={productDetails}
        />
        <ConfirmationDialog open={confirmationDialog} onClose={setConfirmationDialog} />
      </form>
      <ShareViaDialog
        open={shareViaDialog}
        onClose={setShareViaDialog}
        productid={params?.id}
        brandname={productDetails?.brand_name}
        partname={productDetails?.part_desp}
        parnumber={productDetails?.part_no}
      />
    </Container>
  )
}

AddProduct.propTypes = {
  url: PropTypes.string,
}
