/* eslint-disable no-nested-ternary */
import Image from 'components/common/Image'
import { createOption } from 'components/common/text/DropDownTextField'
import moment from 'moment'
import { encrypt, decrypt } from 'n-krypta'
import DefalutFlag from 'assets/icons/DefalutFlag.svg'

import { constants } from './constants'
import { DATE_FORMAT_PRIMARY, formatDate } from './dateUtils'
import { isEmpty } from './Files'

export function hideGlobalConsoles() {
  const noop = () => {}
  ;[
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profileEnd',
    'table',
    'time',
    'timeEnd',
    'timeline',
    'timelineEnd',
    'timeStamp',
    'trace',
    'warn',
  ].forEach((method) => {
    window.console[method] = noop
  })
}

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return ''
}

export function timeFromNow(date) {
  return moment(date).isSame(moment(), 'day')
    ? moment(date).fromNow().replace('minutes', 'mins').replace('hours', 'hrs').replace('seconds', 'secs')
    : formatDate(date, DATE_FORMAT_PRIMARY)
}

export function expiredIn48Hours(date) {
  const expiredDateTime = moment(date).add(48, 'hours')
  // const expiredDateTime = moment('2023-02-011T09:36:13.266Z').add(48, 'hours')

  const remainHours = moment(expiredDateTime).diff(new Date(), 'h')
  // const remainMinutes = moment(expiredDateTime).diff(new Date(), 'm')
  const remainSec = moment(expiredDateTime).diff(new Date(), 's')
  return remainSec >= 0 ? `${Math.floor(remainHours / 24)}d ${remainSec < 3600 ? '1' : remainHours % 24}h` : ''
}

export function getOptionsList(data) {
  return data?.map((values) => createOption(values.id, values.name))
}
export function parseCurrencyString(str, allowNegative) {
  const parsedStr = str || str === 0 ? str.toString().replace(/[^\d.-]/g, '') : ''

  if (allowNegative) {
    const isNegative = str?.toString().includes('-')
    return isNegative ? `-${parsedStr}` : parsedStr
  }

  return parsedStr
}
export function allowOnlyAlphaNumric(event) {
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/
  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
    event.preventDefault()
  }
}
export function isNumber(evt) {
  const NUMERIC_REGEX = /^[0-9]+$/
  if (!NUMERIC_REGEX.test(evt.key)) {
    evt.preventDefault()
  }
}
export function formatInputNumber(n, skipZero, allowNegative, symbol = '$') {
  if (skipZero && !n) {
    return ''
  }
  const fixedNumber = parseFloat(n)?.toFixed(2)
  const strNumber = fixedNumber?.toString()
  const isNegative = strNumber?.includes('-')
  const hasDecimal = strNumber?.includes('.')
  const fullNumber = hasDecimal ? strNumber.split('.')[0] : n
  const decimal = hasDecimal ? strNumber.split('.')[1].slice(0, 2) : ''

  const res = parseCurrencyString(fullNumber, allowNegative)
    .replace('-', '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // eslint-disable-next-line no-nested-ternary
  const negativeRes = isNegative ? `-${symbol}${res.toString().replace('-', '')}` : isEmpty(res) ? '' : symbol + res

  return `${negativeRes}${hasDecimal ? `.${decimal}` : ''}`
}
export function formatPrice(n = '', skipZero) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(!n)) {
    return '-'
  }
  return formatInputNumber(n, skipZero, true)
}

export function getFromLocalStorage(key) {
  const val = localStorage.getItem(key)
  return val ? decrypt(val, constants.ENCRYPT_SECRET) : null
}

export function setToLocalStorage(key, value) {
  localStorage.setItem(key, encrypt(value, constants.ENCRYPT_SECRET))
}
export function isUnwantedValue(val) {
  if ([undefined, 'undefined', null, 'null', '', 0, '0', 'N/A', '[]', '{}', 'NaN'].includes(val)) {
    return true
  }
  return false
}
export function getWellKnownAddress(...props) {
  let str = ''
  Object.keys(props)?.map((key) => {
    if (key === (props?.length - 1).toString()) {
      str += props[key]
    } else {
      str += `${props[key]}, `
    }
    return 0
  })
  const val = str
    .replaceAll(', null', '')
    .replaceAll(', undefined', '')
    .replaceAll('null, ', '')
    .replaceAll('undefined, ', '')
    .replaceAll(', , ', '')
  return isUnwantedValue(val) ? '-' : val
}

export function getFlag(country) {
  const url =
    process.env.REACT_APP_ENV === 'prod' ? process.env.REACT_APP_FLAG_URL_PROD : process.env.REACT_APP_FLAG_URL_DEV
  return country ? (
    <Image src={`${url}${country?.replaceAll(' ', '_')?.toLowerCase()}.svg`} />
  ) : (
    <Image src={DefalutFlag} className={'defalutFlag'} />
  )
}

export function getShortAddress(city, state, country, countryAbbrv) {
  if (
    country?.toLowerCase() === 'canada' ||
    country?.toLowerCase() === 'united states' ||
    countryAbbrv?.toLowerCase() === 'us' ||
    countryAbbrv?.toLowerCase() === 'ca'
  ) {
    return getWellKnownAddress(city, state)
  }
  return getWellKnownAddress(country || countryAbbrv)
}
export function generateUUID() {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
