import { useRef, useState } from 'react'

export default function useUncontrolledSearch(onSearch) {
  const ref = useRef()
  const [hasText, setHasText] = useState(false)

  const onType = (e) => {
    const { value } = e.target
    if (value && !hasText) {
      setHasText(true)
    }
    if (!value && hasText) {
      setHasText(false)
    }
    onSearch(value)
  }

  const onClear = () => {
    setHasText(false)
    onSearch('')
    if (ref.current) {
      ref.current.value = ''
    }
  }

  return {
    onType,
    onClear,
    hasText,
    ref,
  }
}
