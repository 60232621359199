/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable camelcase */
import Container from 'components/common/Container'
import { React, useState, useEffect } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import TextField from 'components/common/text/TextField'
import RadioGroup from 'components/common/radio/RadioGroup'
import Radio from 'components/common/radio/Radio'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import GooglePlaces from 'components/custom/GooglePlaces'
import TextArea from 'components/common/text/TextArea'
import { yupResolver } from '@hookform/resolvers/yup'
import { getFormError } from 'utils/form/form'
import { getWellKnownAddress } from 'utils/Utils'
import useToastify from 'common-hooks/useToastify'
import LabelTextField from 'components/common/text/LabelTextField'
import Checkbox from 'components/common/Checkbox'
// import { BuyerQuoteDataContext, parsingAddress } from './BuyerQuoteUtils'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: '690px',
    overflowY: 'scroll',
  },
  group: {
    display: 'flex',
    gap: '0 16px',
  },
  radiogrp: {
    display: 'flex',
    gap: '0 26px',
    height: '20px',
    // flexDirection: 'row',
  },
  pb10: {
    paddingBottom: '10px',
  },
  billing: {
    fontWeight: '700',
    color: getColor('grey-g25'),
    paddingTop: '5px',
  },
  bilingAddrres: {
    height: '20px',
  },
  paragraph: {
    '& textarea': {
      height: '76px!important',
    },
  },
})

const ShipingAddressDialog = ({
  open,
  onClose,
  editShipingAddress,
  setShipingAddress,
  setFlag,
  setEditShipingAddress,
}) => {
  const setAddressValues = (addr, addr_line_1, addr_line_2) => {
    const loc = formatAddressOfGAPI(addr, addr_line_1, addr_line_2, null)
    setValue('addr_line_1', addr_line_1)
    setValue('addr_line_2', addr_line_2)
    setValue('city', loc.city)
    setValue('state', loc.state)
    // setValue('country', loc.country)
    // setValue('zipcode', loc.zipcode)
    setValue('city_abbrv', loc.city_abbrv)
    setValue('state_abbrv', loc.state_abbrv)
    setValue('country_abbrv', loc.country_abbrv)
  }

  const schema = yup.object({
    fullname: yup.string().required('Full name is required.'),
    email: yup.string().required('email is required.'),
    phone: yup
      .string()
      .nullable(false)
      .matches(/^\d{10}$/, 'Please enter the valid mobile number')
      .required(),
  })
  const { toastMessage } = useToastify()
  const [radioValue, setRadioValue] = useState()

  const handleOnradioValue = (e) => {
    setRadioValue(e.target.value)
  }

  const {
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    if (editShipingAddress) {
      Object.keys(editShipingAddress).map((key) => setValue(key, editShipingAddress[key]))
      if (editShipingAddress?.phone) {
        const split = editShipingAddress?.phone?.split('-')
        setValue('prefix', editShipingAddress?.phone?.includes('+') ? split?.[0]?.replace('+', '') : '1')
        setValue('phone', editShipingAddress?.phone?.includes('+') ? split?.[1] : editShipingAddress?.phone)
      }
      if (editShipingAddress?.type) {
        setRadioValue(editShipingAddress?.type)
      } else {
        setRadioValue('business')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editShipingAddress])

  const handleOnClose = () => {
    reset({
      fullname: '',
      company_name: '',
      email: '',
      prefix: '',
      phone: '',
      UnitNumber: '',
      location: '',
      deliveryinst: '',
    })
    setEditShipingAddress(null)
    onClose()
  }

  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    if (radioValue === 'business' && !data?.company_name) {
      toastMessage('error', 'Please enter company name')
      return 0
    }
    if (data?.prefix === '' || data?.prefix === undefined) {
      toastMessage('error', 'Country code is required')
      return 0
    }
    if (data?.addr_line_1 === undefined || data?.addr_line_1 === '') {
      toastMessage('error', 'Location is required')
      return 0
    }
    setShipingAddress({ ...data, phone: `+${data?.prefix}-${data?.phone}`.toString(), type: radioValue })
    setFlag(false)
    handleOnClose()
  }

  const classes = useStyles()

  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead
          title={'Shipping Address'}
          onClose={() => {
            clearErrors()
            handleOnClose()
          }}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        >
          <Container className={classes.dialogbody} direction={'column'} gap={'10px 0'}>
            <RadioGroup className={classes.radiogrp} onChange={handleOnradioValue} value={radioValue}>
              <Container className={classes.radiogrp}>
                <Radio name={'business'} label={'Business'} checked={radioValue === 'business'} value={'business'} />
                <Radio
                  name={'residence'}
                  label={'Residence'}
                  checked={radioValue === 'residence'}
                  value={'residence'}
                />
              </Container>
            </RadioGroup>
            {radioValue === 'business' ? (
              <>
                <Controller
                  name={'fullname'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      id={'name'}
                      name={name}
                      label={'Full Name*'}
                      placeholder={'e.g. John Doe'}
                      value={value}
                      type="text"
                      onChange={onChange}
                      error={getFormError(errors, 'fullname')}
                    />
                  )}
                />
                <Controller
                  name={'company_name'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      id={'company_name'}
                      name={name}
                      value={value}
                      label={'Company Name*'}
                      placeholder={'e.g. PartsClub'}
                      type="text"
                      onChange={onChange}
                      error={getFormError(errors, 'company_name')}
                    />
                  )}
                />
              </>
            ) : (
              <Controller
                name={'fullname'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={'Full-Name'}
                    name={name}
                    label={'Full Name*'}
                    placeholder={'e.g. John Deere'}
                    type="text"
                    onChange={onChange}
                    value={value}
                    error={getFormError(errors, 'fullname')}
                  />
                )}
              />
            )}

            <Controller
              name={'email'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={'Email'}
                  name={name}
                  label={'Email*'}
                  placeholder={'john.d@partsclub.us'}
                  type="email"
                  onChange={onChange}
                  value={value}
                  error={getFormError(errors, 'email')}
                />
              )}
            />
            <Container flex justify="space-between">
              {/* <Controller
                name={'phone'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropDownTextField
                    options={options}
                    disabled
                    value={value}
                    id={'phone'}
                    // type={'number'}
                    className={classes.phoneFlied}
                    label={'Phone Number*'}
                    control={control}
                    onChange={onChange}
                    placeholder="235 567 2355"
                    error={getFormError(errors, 'phone')}
                    width={'240px'}
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  />
                )}
              /> */}
              <Container>
                <Typography variant="paragraph2" color={getColor('grey-g35')}>
                  Phone Number*
                </Typography>
                <Container flex gap="2px">
                  <Controller
                    name={'prefix'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <LabelTextField
                        id={name}
                        type={'number'}
                        labelText={'+'}
                        className={classes.labelTextField}
                        placeholder={'1'}
                        onChange={onChange}
                        name={name}
                        value={value}
                        width={'50px'}
                      />
                    )}
                  />
                  <TextField
                    id={'phone'}
                    type={'number'}
                    control={control}
                    {...register('phone', { required: true })}
                    error={getFormError(errors, 'phone')}
                    placeholder="210-806-6669"
                    width={'340px'}
                  />
                </Container>
              </Container>
            </Container>
            <Controller
              name={'location'}
              control={control}
              render={({ field: { name, value } }) => (
                <GooglePlaces
                  id={name}
                  name={name}
                  label="Location*"
                  spacing={3}
                  onClearInput={() => setValue('addr_line_1', '')}
                  placeholder={'e.g. 4517 Washington Ave'}
                  value={
                    value ||
                    (getValues()?.addr_line_1 &&
                      getValues()?.addr_line_2 &&
                      getWellKnownAddress(getValues()?.addr_line_1, getValues()?.addr_line_2))
                  }
                  onSelect={(loc) => {
                    setAddressValues(loc.addr, loc.addr_line_1, loc.addr_line_2)
                  }}
                />
              )}
            />
            <Controller
              name={'UnitNumber'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={'Unit Number'}
                  name={name}
                  label={'Unit Number'}
                  placeholder={'e.g. Unit 36A'}
                  type="number"
                  onChange={onChange}
                  value={value}
                  width={'219px'}
                />
              )}
            />
            <Container className={classes.paragraph}>
              <Typography variant="paragraph2">Delivery Instructions</Typography>
              <Controller
                name={'deliveryinst'}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <TextArea name={name} onChange={onChange} value={value} placeholder="Paragraph" />
                )}
              />
            </Container>
            {radioValue === 'business' && (
              <>
                <Controller
                  name={'forklift_available'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Checkbox
                      className={classes.checkBox}
                      name={name}
                      id={name}
                      value={value}
                      label={'Forklift available to unload delivery driver'}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name={'dock_available'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Checkbox
                      className={classes.checkBox}
                      name={name}
                      id={name}
                      value={value}
                      label={'Dock available to unload delivery driver'}
                      onChange={onChange}
                    />
                  )}
                />
              </>
            )}
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button
                variant="secondary"
                border
                width={77}
                onClick={() => {
                  clearErrors()
                  handleOnClose()
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Update</Button>
            </Container>
          </DialogFooter>
        </form>
      </Dialog>
      {/* <Button onClick={onclose}>Open Dilaog</Button> */}
    </Container>
  )
}
ShipingAddressDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setShipingAddress: PropTypes.func,
  editShipingAddress: PropTypes.object,
  setFlag: PropTypes.func,
  setEditShipingAddress: PropTypes.func,
}

export default ShipingAddressDialog
