import Sidebar from 'components/custom/Sidebar'
import Container from 'components/common/Container'
import { React, useEffect, useState } from 'react'
import Placeholder from 'assets/images/Placeholder.png'
import { makeStyles } from '@mui/styles'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import classNames from 'classnames'
import Seperator from 'components/common/Seperator'
import Dropzone from 'components/custom/dropzone/Dropzone'
import FileUpload from 'components/custom/dropzone/FileUpload'
import Image from 'components/common/Image'
import { useNavigate } from 'react-router-dom'
import useToggle from 'common-hooks/useToggle'
import ImageSliderContainer from 'components/custom/image-slider/ImageSliderContainer'
import { getWellKnownAddress } from 'utils/Utils'
import { AddIcon, CaretLeftIcon, DeleteIcon, EditIcon, InfoSmallIcon } from 'assets/icons'
import IconButton from 'components/common/IconButton'
import Button from 'components/common/Button'
import TwoWayController from 'components/custom/TwoWayController'
import Tooltip from 'components/common/Tooltip'
import { filesCreateIds, IMAGE_MIME } from 'utils/Files'
import ModelNumberDialog from './ModelNumberDialog'
import SerialNumberDialog from './SerialNumberDialog'
import PartNumberDialog from './PartNumberDialog'
import NotesDialog from './NotesDialog'
import ShipingAddressDialog from './ShipingAddressDialog'
import useCreateQuote from 'common-hooks/create-quote/useCreateQuote'
import { quoteUpdateData } from './BuyerQuoteUtils'
import useBuyerQuoteDetails from 'common-hooks/buyer/quotes-details/useBuyerQuoteDetails'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles(() => ({
  rootinner: {
    display: 'flex',
  },
  eqpimage: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',

    '& div': {
      width: '32.2%',
      height: '120px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',

      '& img': {
        width: '120px',
        height: '120px',
      },
    },
  },
  typographycolor: {
    color: getColor('grey-g25'),
  },
  typographycolortitle: {
    color: getColor('grey-g35'),
  },
  partsimages: {
    '& div': {
      '& div': {
        width: 'unset',
        border: 'unset',
        height: 'unset',
        display: 'unset',
        alignItems: ' center',
        justifyContent: ' center',
      },
    },
  },
  eqpImageBorder: {
    '& div': {
      border: 'unset',
      '& img': {
        border: '1px solid #F0F0F4',
      },
    },
    '& div:first-child': {
      borderRight: 'unset',
    },
  },
  sideBarRoot: {
    width: '400px',
    // padding: '30px',
    '& .supplierForm': {
      // paddingTop: '20px',
      width: '100%',
      height: '77.5vh',
      padding: '0 30px 30px 30px',
      '&::-webkit-scrollbar': {
        width: '6px',
        backgroundColor: '#fff',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        borderRadius: ' 100px',
        background: '#C3C5D5',
      },
    },
  },
  titleContainer: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
    padding: '30px 30px 20px 30px',
  },
  backBtn: {
    '& span': {
      display: 'flex',
    },
  },
  saveChangesBtn: {
    background: '#F0F0F4',
    color: '#9799B5!important',
    '&:hover': {
      background: '#F0F0F4',
    },
    '& span': {
      color: '#9799B5',
    },
  },
  separtor: {
    padding: '0 30px 20px 30px',
    background: '#fff',
  },
  eqpRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
    '& img': {
      width: '28px',
      height: '28px',
    },
    '& .div': {
      display: 'flex',
      width: 'fit-content',
      height: '36px',
      alignItems: 'center',
      minWidth: '140px',
      gap: '0 5px',
      padding: '0 10px',
      borderRadius: '100px',
      border: ' 1px solid #F0F0F4',
    },
  },
  addBtn: {
    background: 'unset',
    color: '#2680D9',
    borderBottom: '1px solid #2680D9',
    borderRadius: 'unset',
    height: 'unset',
    minHeight: '18px',
    padding: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  textCover: {
    border: ' 1px dashed #C3C5D5',
    width: '50px',
    height: '50px',
    padding: '0',
    display: 'flex',
  },
  ImagesRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
  },
  IconBTn: {
    background: 'unset',
    padding: '0',
    '&:hover': {
      background: 'unset',
    },
  },
  notesRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  ImagesDiv: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '0 5px',
    '& img': {
      width: '50px',
      height: '50px',
    },
  },
  ImagesRootFlex: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '0 5px',
  },
  hoverRoot: {
    position: 'relative',
    cursor: 'pointer',
    '& .hover-delete': {
      display: 'none',
      position: 'absolute',
      '& svg': {
        stroke: '#fff',
      },
    },
    '&:hover .hover-delete': {
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000000a3',
      '& svg': {
        stroke: '#fff',
      },
    },
    '& svg > path': {
      stroke: '#fff',
    },
  },
}))

const BuyerQuoteSidebar = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [imageIndex, setImageIndex] = useState(0)
  const [imagesArray, setImagesArray] = useState([])
  const [removeAssets, setRemoveAssets] = useState([])
  const [modelNumber, setModelNumber] = useState()
  const [editModelNumber, setEditMododelNumber] = useState()
  const [serialNumber, setSerialNumber] = useState()
  const [editSerialNumber, setEditSerialNumber] = useState()
  const [partNumber, setPartNumber] = useState()
  const [editPartNumber, setEditPartNumber] = useState()
  const [shipingAddress, setShipingAddress] = useState()
  const [editShipingAddress, setEditShipingAddress] = useState()
  const [notes, setNotes] = useState()
  const [editNotes, setEditNots] = useState()
  const [flag, setFlag] = useState(true)
  const [imgFlag, setImgFlag] = useState(false)
  const [productResponse, setProductResponse] = useState(false)
  const [qty, setQty] = useState()

  const [openImageDialog, toggleImageDialog] = useToggle(false)
  const [modelNumberDialog, setModelNumberDialog] = useToggle(false)
  const [serialNumberDialog, setSerialNumberDialog] = useToggle(false)
  const [partNumberDialog, setPartNumberDialog] = useToggle(false)
  const [notesDialog, setNotesDialog] = useToggle(false)
  const [shipingAddressDialog, setShipingAddressDialog] = useToggle(false)

  const { quoteDetails, mutate } = useBuyerQuoteDetails()
  const { updateQuoteDetail } = useCreateQuote()
  const { toastMessage } = useToastify()

  function handleImage(index) {
    setImageIndex(index)
    if (imageIndex >= 0) {
      toggleImageDialog(true)
    }
  }
  // eslint-disable-next-line consistent-return
  const handleQuoteImages = (name, newFiles) => {
    const filesArr = filesCreateIds(newFiles)
    const MAX_FILE_SIZE = 2048 // 2MB
    const fileSizeKiloBytes = filesArr?.map((obj) => obj?.file?.size / 1024)

    if (fileSizeKiloBytes?.[0] > MAX_FILE_SIZE) {
      toastMessage('error', 'Upload image max size 2mb')
      return 0
    }

    if (filesArr.length > 3) {
      filesArr.length = 3
    }
    setImagesArray([...imagesArray, ...filesArr])
    setFlag(false)
    setImgFlag(true)
  }

  const removeImage = (assetId) => {
    const filesArr = imagesArray?.filter((i) => i.id !== assetId)
    setImagesArray(filesArr)
    if (!assetId?.includes('temp')) {
      setRemoveAssets([...removeAssets, assetId])
    }
    setFlag(false)
  }

  let quoteName = ''
  if (quoteDetails?.brand_name || quoteDetails?.part_name || quoteDetails?.part_number) {
    quoteName = `${quoteDetails?.brand_name} ${quoteDetails?.part_name} ${quoteDetails?.part_number} `
      .replace('null', '')
      .replace('undefined', '')
  }
  if (quoteDetails?.product_title && !quoteDetails?.part_details) {
    quoteName = quoteDetails?.product_title
  }

  const handleOnQty = (e) => {
    setQty(e)
    setFlag(false)
  }

  const handleOnSaveChanges = async () => {
    const parseData = quoteUpdateData(modelNumber, serialNumber, partNumber, qty, shipingAddress, notes, removeAssets)
    const response = await updateQuoteDetail(parseData, imagesArray, mutate)
    if (response) {
      setProductResponse(true)
      setImgFlag(false)
    }
  }

  useEffect(() => {
    if (quoteDetails) {
      Object.keys(quoteDetails).map((key) => {
        if (key === 'brand_model_number') {
          setModelNumber(quoteDetails?.brand_model_number)
        } else if (key === 'brand_serial_number') {
          setSerialNumber(quoteDetails?.brand_serial_number)
        } else if (key === 'part_number') {
          setPartNumber(quoteDetails?.part_number)
        } else if (key === 'note') {
          setNotes(quoteDetails?.note)
        } else if (key === 'cmp_delivery_addr_addr_line_1') {
          setShipingAddress({
            company_name: quoteDetails?.cmp_delivery_addr_company_name,
            fullname: quoteDetails?.buyer_full_name,
            email: quoteDetails?.cmp_delivery_addr_email,
            phone: quoteDetails?.cmp_delivery_addr_phone,
            addr_line_1: quoteDetails?.cmp_delivery_addr_addr_line_1,
            addr_line_2: quoteDetails?.cmp_delivery_addr_addr_line_2,
            id: quoteDetails?.cmp_delivery_addr_id,
            city: quoteDetails?.cmp_delivery_addr_city,
            city_abbrv: quoteDetails?.cmp_delivery_addr_city_abbrv,
            state: quoteDetails?.cmp_delivery_addr_state,
            state_abbrv: quoteDetails?.cmp_delivery_addr_state_abbrv,
            country: quoteDetails?.cmp_delivery_addr_country,
            country_abbrv: quoteDetails?.cmp_delivery_addr_country_abbrv,
            zipcode: quoteDetails?.cmp_delivery_addr_zipcode,
            UnitNumber: quoteDetails?.cmp_delivery_addr_unit_number,
            deliveryinst: quoteDetails?.cmp_delivery_addr_delivery_instructions,
            type: quoteDetails?.cmp_delivery_addr_type,
            forklift_available: quoteDetails?.cmp_delivery_addr_forklift_available,
            dock_available: quoteDetails?.cmp_delivery_addr_dock_available,
          })
        } else if (key === 'quantity') {
          setQty(quoteDetails?.quantity)
        } else if (key === 'quote_assets' && quoteDetails?.quote_assets?.length > 0 && imgFlag === false) {
          setImagesArray(quoteDetails?.quote_assets)
        } else {
          return 0
        }
        return 0
      })
    }
  }, [quoteDetails, imgFlag])

  useEffect(() => {
    if (productResponse) {
      setFlag(true)
      setProductResponse(false)
    }
  }, [productResponse])

  return (
    <Container className={classes.sideBarRoot}>
      <Container className={classes.titleContainer}>
        <Container flex justify="space-between">
          <Button
            className={classes.backBtn}
            variant="secondary"
            width="36px"
            border
            onClick={() => navigate('/quotes')}
          >
            <CaretLeftIcon />
          </Button>
          <Button
            disabled={flag}
            variant={!flag ? 'positive' : ''}
            className={flag ? classes.saveChangesBtn : ''}
            onClick={() => handleOnSaveChanges()}
          >
            Save Changes
          </Button>
        </Container>
        <Typography variant="h2" color={getColor('grey-g25')}>
          {quoteName.replace('null', '').replace('undefined', '')}
        </Typography>
      </Container>
      <Container className={classes.separtor}>
        <Seperator />
      </Container>
      <Sidebar className="supplierForm" display="visible">
        <Container className={classes.rootinner} direction={'column'} gap={'15px 0'}>
          <Container className={classes.rootinner} direction={'column'} gap={'15px 0'}>
            <Container>
              {/* {quotesDetailDataList?.product_title ? (
                <Typography className={classes.typographycolor} variant="title">
                  Machine Info
                </Typography>
              ) : ( */}
              <Typography className={classes.typographycolor} variant="title">
                Equipment
              </Typography>
              {/* )} */}
            </Container>
            <Container className={classes.eqpRoot}>
              {quoteDetails?.equip_name && (
                <Container className="div">
                  <Image src={quoteDetails?.equip_cover_image || Placeholder} alt={quoteDetails?.equip_cover_image} />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    {quoteDetails?.equip_name}
                  </Typography>
                </Container>
              )}
              {quoteDetails?.brand_name && (
                <Container className="div">
                  <Image src={quoteDetails?.brand_cover_image || Placeholder} alt={'Brand Image'} />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    {quoteDetails?.brand_name}
                  </Typography>
                </Container>
              )}
            </Container>
            <Container display="flex" direction="column" gap="10px">
              <Container display="flex" justify="space-between">
                <Typography variant="body1" color={getColor('grey-g25')}>
                  Model Number
                </Typography>

                {modelNumber ? (
                  <Container>
                    <IconButton
                      className={classes.IconBTn}
                      onClick={() => {
                        setModelNumberDialog()
                        setEditMododelNumber(modelNumber)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <Typography variant="body1" color={getColor('grey-g25')}>
                      {modelNumber}
                    </Typography>
                  </Container>
                ) : (
                  <Button onClick={setModelNumberDialog} className={classes.addBtn}>
                    Add
                  </Button>
                )}
              </Container>
              <Container display="flex" justify="space-between">
                <Typography variant="body1" color={getColor('grey-g25')}>
                  Serial Number
                </Typography>
                {serialNumber ? (
                  <Container>
                    <IconButton
                      className={classes.IconBTn}
                      onClick={() => {
                        setSerialNumberDialog()
                        setEditSerialNumber(serialNumber)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <Typography variant="body1" color={getColor('grey-g25')}>
                      {serialNumber}
                    </Typography>
                  </Container>
                ) : (
                  <Button onClick={setSerialNumberDialog} className={classes.addBtn}>
                    Add
                  </Button>
                )}
              </Container>
            </Container>
          </Container>
          <Seperator />
          <Container className={classNames(classes.rootinner, classes.pt15)} direction={'column'} gap={'10px 0'}>
            <Typography className={classes.typographycolor} variant="title">
              Part Details
            </Typography>

            {/* <Typography className={classes.typographycolor} variant="body1">
              {productTitleSplit?.[1] || ''}
            </Typography> */}

            <Container flex justify="space-between">
              <Typography variant="body1" color={getColor('grey-g25')}>
                Category
              </Typography>
              <Typography className={classes.typographycolor} variant="body1">
                {quoteDetails?.part_name}
              </Typography>
            </Container>

            <Container display="flex" justify="space-between">
              <Typography variant="body1" color={getColor('grey-g25')}>
                Part Number
              </Typography>
              {partNumber ? (
                <Container>
                  <IconButton
                    className={classes.IconBTn}
                    onClick={() => {
                      setPartNumberDialog()
                      setEditPartNumber(partNumber)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    {partNumber}
                  </Typography>
                </Container>
              ) : (
                <Button className={classes.addBtn} onClick={setPartNumberDialog}>
                  Add
                </Button>
              )}
            </Container>

            <Container display="flex" justify="space-between">
              <Typography variant="body1" color={getColor('grey-g25')}>
                Quantity Needed
              </Typography>
              <TwoWayController onChange={(e) => handleOnQty(e)} defalutValue={qty} />
            </Container>

            <Container className={classes.ImagesRoot}>
              <Container flex justify="flex-start" gap={'0 5px'}>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  Image
                </Typography>
                <Tooltip
                  placement={'right'}
                  title={'Upload up to 3 images. Supported file formate are .jpg and .png, max size 2mb. '}
                >
                  <InfoSmallIcon />
                </Tooltip>
              </Container>
              <Container className={classes.ImagesRootFlex}>
                <Container className={classes.ImagesDiv}>
                  {imagesArray?.map((file, index) => {
                    const props = {
                      url: file?.part_image || URL.createObjectURL(file.file),
                      index,
                      assetId: file.id,
                      indexImage: 0,
                      disableDropdown: true,

                      onClick: () => handleImage(index),
                    }
                    return (
                      <Container key={index} className={classes.hoverRoot}>
                        <FileUpload width={'50px'} height={'50px'} key={index} {...props} />
                        <Container className="hover-delete" onClick={() => removeImage(file?.id)}>
                          <DeleteIcon />
                        </Container>
                      </Container>
                    )
                  })}
                </Container>
                {imagesArray?.length >= 3 ? (
                  ''
                ) : (
                  <Dropzone
                    multiple
                    accept={IMAGE_MIME}
                    onChange={(e) => handleQuoteImages('quoteImage', e)}
                    dropzoneClassName={classes.textCover}
                    actionComponent={<AddIcon />}
                  />
                )}
              </Container>
            </Container>
          </Container>
          <Seperator />
          <Container className={classes.rootinner} direction={'column'} gap={'10px'}>
            <Container display="flex" direction={'column'} gap={'15px 0'}>
              <Container flex justify="space-between">
                <Typography className={classes.typographycolor} variant="title">
                  Shipping
                </Typography>
                <IconButton
                  className={classes.IconBTn}
                  onClick={() => {
                    setShipingAddressDialog()
                    setEditShipingAddress(shipingAddress)
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Container>
              <Container>
                <Typography variant="body1" color={getColor('grey-g25')} component="p">
                  {shipingAddress?.fullname} at {shipingAddress?.company_name}
                </Typography>

                <Typography className={classes.typographycolor} variant="paragraph1" color={getColor('grey-g35')}>
                  {getWellKnownAddress(shipingAddress?.addr_line_1, shipingAddress?.addr_line_2)}
                </Typography>

                {/* {!buyerQuoteData?.company_billing_addr && (
                  <Typography className={classes.typographycolor} variant="paragraph1" color={getColor('grey-g35')}>
                    {getWellKnownAddress(
                      quotesDetailDataList?.cmp_delivery_addr_addr_line_1,
                      quotesDetailDataList?.cmp_delivery_addr_addr_line_2
                    )}
                  </Typography>
                )} */}
                {shipingAddress?.UnitNumber && (
                  <Typography
                    variant="paragraph1"
                    color={getColor('grey-g35')}
                    component={'p'}
                  >{`Unit Number: ${shipingAddress?.UnitNumber}`}</Typography>
                )}
              </Container>
              {/* {quotesDetailDataList?.cmp_delivery_addr_email && (
                <Typography
                  variant="paragraph1"
                  color={getColor('grey-g35')}
                >{`E: ${quotesDetailDataList?.cmp_delivery_addr_email}`}</Typography>
              )} */}
              {/* {quotesDetailDataList?.cmp_delivery_addr_phone && (
                <Typography
                  variant="paragraph1"
                  color={getColor('grey-g35')}
                >{`P: ${quotesDetailDataList?.cmp_delivery_addr_phone}`}</Typography>
              )} */}
            </Container>
            {/* {quotesDetailDataList?.cmp_delivery_addr_delivery_instructions !== '' && (
              <Container display="flex" direction={'column'}>
                <Typography className={classes.typographycolor} variant="body1">
                  Instructions
                </Typography>
                {quotesDetailDataList?.cmp_delivery_addr_delivery_instructions && (
                  <Typography>{quotesDetailDataList?.cmp_delivery_addr_delivery_instructions}</Typography>
                )}
              </Container>
            )} */}
          </Container>

          <Seperator />
          <Container className={classes.notesRoot}>
            <Container flex justify="space-between">
              <Typography className={classes.typographycolor} variant="title">
                Notes
              </Typography>
              <IconButton
                className={classes.IconBTn}
                onClick={() => {
                  setNotesDialog()
                  setEditNots(notes)
                }}
              >
                <EditIcon />
              </IconButton>
            </Container>

            <Typography className={classes.typographycolortitle} variant="body1">
              {notes}
            </Typography>
          </Container>
        </Container>
        <ImageSliderContainer
          open={openImageDialog}
          onclose={toggleImageDialog}
          imageData={imagesArray}
          index={imageIndex}
        />
        <ModelNumberDialog
          onClose={setModelNumberDialog}
          open={modelNumberDialog}
          setModelNumber={setModelNumber}
          editModelNumber={editModelNumber}
          setFlag={setFlag}
          setEditMododelNumber={setEditMododelNumber}
        />
        <SerialNumberDialog
          onClose={setSerialNumberDialog}
          open={serialNumberDialog}
          setSerialNumber={setSerialNumber}
          editSerialNumber={editSerialNumber}
          setFlag={setFlag}
          setEditSerialNumber={setEditSerialNumber}
        />
        <PartNumberDialog
          onClose={setPartNumberDialog}
          open={partNumberDialog}
          setPartNumber={setPartNumber}
          setEditPartNumber={setEditPartNumber}
          editPartNumber={editPartNumber}
          setFlag={setFlag}
        />
        <NotesDialog
          onClose={setNotesDialog}
          open={notesDialog}
          setNotes={setNotes}
          editNotes={editNotes}
          setFlag={setFlag}
          setEditNots={setEditNots}
        />
        <ShipingAddressDialog
          onClose={setShipingAddressDialog}
          open={shipingAddressDialog}
          setShipingAddress={setShipingAddress}
          shipingAddress={shipingAddress}
          editShipingAddress={editShipingAddress}
          setFlag={setFlag}
          setEditShipingAddress={setEditShipingAddress}
        />
      </Sidebar>
    </Container>
  )
}

export default BuyerQuoteSidebar
