export const City = [
  { value: 'Alexander City', label: 'Alexander City' },
  { value: 'Andalusia', label: 'Andalusia' },
  { value: 'Anniston', label: 'Anniston' },
  { value: 'Athens', label: 'Athens' },
  { value: 'Atmore', label: 'Atmore' },
  { value: 'Auburn', label: 'Auburn' },
  { value: 'Las Vegas', label: 'Las Vegas' },
  { value: 'Bessemer', label: 'Bessemer' },
]

export const Country = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'Florida', label: 'Florida' },
]

export const options = ['+1']
