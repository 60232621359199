/* eslint-disable no-nested-ternary */
import EBackhoe from 'assets/buyer-quotes-assets/equi/Backhoe.png'
import ECrane from 'assets/buyer-quotes-assets/equi/Crane.png'
import ECrusher from 'assets/buyer-quotes-assets/equi/Crusher.png'
import EExcavator from 'assets/buyer-quotes-assets/equi/Excavator.png'
import EGrade from 'assets/buyer-quotes-assets/equi/Grade.png'
import EForestry from 'assets/buyer-quotes-assets/equi/Forestry.png'
import deepmerge from 'deepmerge'
import BArcticCat from 'assets/buyer-quotes-assets/brand/ArcticCat.png'
import BClaas from 'assets/buyer-quotes-assets/brand/Claas.png'
import BDeutzFahr from 'assets/buyer-quotes-assets/brand/DeutzFahr.png'
import BGleaner from 'assets/buyer-quotes-assets/brand/Gleaner.png'
import BNewHolland from 'assets/buyer-quotes-assets/brand/NewHolland.png'
import BVehicle from 'assets/buyer-quotes-assets/brand/Vehicle.png'
import EllipsisVerticleIcon from 'assets/icons/UnionIcon.svg'
import { createContext } from 'react'
import { formatDate, DATE_FORMAT_COMMA } from 'utils/dateUtils'

export const BuyerQuoteContext = createContext({
  onSaveBuyerQuoteInformaton: () => {},
  BuyerQueoteInformation: {},
  visibleTextBoxData: [],
  setTextBoxVisibilityData: () => {},
  setPartsTreeViewData: () => {},
  partsTreeViewData: [],
})

// Old backup
// export const BuyerQuoteContext = createContext({
//   onSaveBuyerQuoteInformaton: () => {},
//   BuyerQueoteInformation: {},
//   visibleTextBoxData: [],
//   setTextBoxVisibilityData: () => {},
// })
export const createUuid = () => {
  // eslint-disable-next-line no-var
  var dt = new Date().getTime()
  // eslint-disable-next-line func-names, no-var, prefer-arrow-callback
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    // eslint-disable-next-line no-bitwise, eqeqeq
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj
  let i = null
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind]?.toLowerCase() === valToFind?.toLowerCase()) {
      foundObj = { ...nestedValue, parent_id: i }
    } else if (nestedValue && typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
      i = nestedValue?.id ? nestedValue.id : null
    }

    return nestedValue
  })
  return foundObj
}

export const equipmentData = {
  Popular: [
    {
      id: 1,
      name: 'Backhoe',
      img: EBackhoe,
    },
    {
      id: 2,
      name: 'Crane',
      img: ECrane,
    },
    {
      id: 3,
      name: 'Crusher',
      img: ECrusher,
    },

    {
      id: 5,
      name: 'Excavator',
      img: EExcavator,
    },
    {
      id: 6,
      name: 'Grade',
      img: EGrade,
    },
    {
      id: 7,
      name: 'Forestry',
      img: EForestry,
    },
  ],
  Others: [
    {
      id: 11,
      name: 'Backhoe1',
      img: EBackhoe,
    },
    {
      id: 21,
      name: 'Crane1',
      img: ECrane,
    },
    {
      id: 31,
      name: 'Crusher1',
      img: ECrusher,
    },

    {
      id: 51,
      name: 'Excavator1',
      img: EExcavator,
    },
    {
      id: 61,
      name: 'Grade1',
      img: EGrade,
    },
    {
      id: 71,
      name: 'Forestry1',
      img: EForestry,
    },
    {
      id: 81,
      name: 'Other',
      img: EllipsisVerticleIcon,
    },
  ],
}

export const equipmentData1 = [
  {
    id: 2,
    name: 'Crane',
    img: ECrane,
  },
  {
    id: 1,
    name: 'Backhoe',
    img: EBackhoe,
  },

  {
    id: 5,
    name: 'Excavator',
    img: EExcavator,
  },
  {
    id: 3,
    name: 'Crusher',
    img: ECrusher,
  },

  {
    id: 6,
    name: 'Grade',
    img: EGrade,
  },
  {
    id: 7,
    name: 'Forestry',
    img: EForestry,
  },
]
export const brandData = {
  Popular: [
    {
      id: 1,
      name: 'ArcticCat',
      img: BArcticCat,
    },
    {
      id: 2,
      name: 'Claas',
      img: BClaas,
    },
    {
      id: 3,
      name: 'DeutzFahr',
      img: BDeutzFahr,
    },
    {
      id: 4,
      name: 'Gleaner',
      img: BGleaner,
    },
    {
      id: 5,
      name: 'NewHolland',
      img: BNewHolland,
    },
    {
      id: 6,
      name: 'Vehicle',
      img: BVehicle,
    },
  ],
  Others: [
    {
      id: 6,
      name: 'Vehicle1',
      img: BVehicle,
    },

    {
      id: 5,
      name: 'NewHolland1',
      img: BNewHolland,
    },
    {
      id: 4,
      name: 'Gleaner1',
      img: BGleaner,
    },
    {
      id: 81,
      name: 'Other',
      img: EllipsisVerticleIcon,
    },
  ],
}
export const parts = [
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
]

export const activeData = [
  {
    id: '01',
    img: '',
    brand: 'John Deere',
    part_number: 'AR89564-A',
    part_description: 'Pencil Fuel Injector',
    qty: '10',
    equipment: 'Tractor',
    model: '450B',
    serial: 'AR88239',
    supplier: '2 Supplier',
    quotes: '2 Quotes',
    quotesCap: '2',
    date: '07/30/2022',
    modes: 'Private',
    message: '1 Message',
    images: '1 Images',
  },
  {
    id: '02',
    img: '',
    brand: 'John Deere',
    part_number: 'AT163468',
    part_description: 'Solenoid',
    qty: '22',
    equipment: 'Backhoe Loader',
    model: '300D',
    serial: '',
    supplier: '3 Supplier',
    quotes: '',
    date: '07/22/2022',
    modes: 'Public',
    message: '6 Message',
    images: '',
  },
  {
    id: '03',
    img: '',
    brand: 'John Deere',
    part_number: '',
    part_description: 'Fuel Transfer Pump Fits',
    qty: '2',
    equipment: 'Loader',
    model: '2550',
    serial: 'AR52159',
    supplier: '5 Supplier',
    quotes: '2 Quotes',
    quotesCap: '5',
    date: '07/30/2022',
    modes: 'Private',
    message: '4 Message',
    images: '6 Images',
  },
  {
    id: '04',
    img: '',
    brand: 'John Deere',
    part_number: 'JD-450B',
    part_description: 'CLUTCHKITMET Clutch Kit Fits',
    qty: '17',
    equipment: 'Loader',
    model: '2550',
    serial: 'AR52159',
    supplier: '5 Supplier',
    quotes: '1 Quotes',
    date: '5 Supplier',
    modes: 'Public',
    message: '2 Message',
    images: '',
  },
  {
    id: '05',
    img: '',
    brand: 'John Deere',
    part_number: 'AT124357',
    part_description: 'Axle Rear Axles',
    qty: '10',
    equipment: 'Bull Dozer',
    model: '350',
    serial: '',
    supplier: '5 Supplier',
    quotes: '1 Quotes',
    date: '07/28/2022',
    modes: 'Public',
    message: '2 Message',
    images: '',
  },

  {
    id: '06',
    img: '',
    brand: 'John Deere',
    part_number: 'AR89564',
    part_description: 'A Pencil Fuel Injector',
    qty: '10',
    equipment: 'Tractor',
    model: '450B',
    serial: 'AR88239',
    supplier: '2 Supplier',
    quotes: '2 Quotes',
    date: '07/30/2022',
    modes: 'Private',
    message: '1 Message',
    images: '1 Images',
  },
  {
    id: '07',
    img: '',
    brand: 'John Deere',
    part_number: 'AT163468',
    part_description: 'Solenoid',
    qty: '22',
    equipment: 'Backhoe Loader',
    model: '300D',
    serial: '',
    supplier: '3 Supplier',
    quotes: '2 Quotes',
    date: '07/22/2022',
    modes: 'Public',
    message: '6 Message',
    images: '',
  },
  {
    id: '08',
    img: '',
    brand: 'John Deere',
    part_number: '',
    part_description: 'Fuel Transfer Pump Fits',
    qty: '2',
    equipment: 'Loader',
    model: '2550',
    serial: 'AR52159',
    supplier: '5 Supplier',
    quotes: '2 Quotes',
    date: '07/30/2022',
    modes: 'Private',
    message: '4 Message',
    images: '6 Images',
  },
  {
    id: '09',
    img: '',
    brand: 'John Deere',
    part_number: 'JD-450B',
    part_description: 'CLUTCHKITMET Clutch Kit Fits',
    qty: '17',
    equipment: 'Loader',
    model: '2550',
    serial: 'AR52159',
    supplier: '5 Supplier',
    quotes: '1 Quotes',
    date: '5 Supplier',
    modes: 'Public',
    message: '2 Message',
    images: '',
  },
  {
    id: '10',
    img: '',
    brand: 'John Deere',
    part_number: 'AT124357',
    part_description: 'Axle Rear Axles',
    qty: '10',
    equipment: 'Bull Dozer',
    model: '350',
    serial: '',
    supplier: '5 Supplier',
    quotes: '1 Quotes',
    date: '07/28/2022',
    modes: 'Public',
    message: '2 Message',
    images: '',
  },
]
export const closedData = [
  {
    id: '01',
    img: '',
    brand: 'John Deere',
    part_number: 'AR89564',
    part_description: 'A Pencil Fuel Injector',
    qty: '10',
    equipment: 'Tractor',
    model: '450B',
    serial: 'AR88239',
    supplier: '2 Supplier',
    quotes: '2 Quotes',
    date: '07/30/2022',
    modes: 'Private',
    message: '1 Message',
    images: '1 Images',
  },
  {
    id: '02',
    img: '',
    brand: 'John Deere',
    part_number: 'AT163468',
    part_description: 'Solenoid',
    qty: '22',
    equipment: 'Backhoe Loader',
    model: '300D',
    serial: '',
    supplier: '3 Supplier',
    quotes: '',
    date: '07/22/2022',
    modes: 'Public',
    message: '6 Message',
    images: '',
  },
]
export const tabObject = [
  {
    id: '1',
    tabLabel: 'Active',
    tabContent: 'Content 1',
  },
  {
    id: '2',
    tabLabel: 'Closed',
    tabContent: 'Content 2',
  },
]

export const shippingDetail = [
  {
    id: '1',
    flatNo: '2972',
    location: 'Westheimer Rd.',
    town: 'SantaAna',
    country: 'Illinois',
    pin: '85486',

    shippingCompany: 'John’s Equipment Repair Co.',
    street: 'S 5th Ave, Merrll St',
    countryAndCode: 'Castlewood, SD 57078, US',

    partsDetail: 'John Deere - LW10450034 - Seal Kit',
    instock: 'In-Stock',
    newArravial: 'New - Aftermarket • 1.1 lbs',
    quantity: '1',
    prize: '$125.00',
    afterDiscount: '$80.50',
  },
]

export const shipingOptions = {
  value: [
    { label: 'Next Day Delivery', value: '$80.00' },
    { label: '2nd Day', value: '$30.00' },
    { label: 'Ground', value: '$31.00' },
  ],
}
export const IndustryInfoData = [
  {
    id: 1,
    name: 'Backhoe',
    img: EBackhoe,
  },
  {
    id: 2,
    name: 'Crane',
    img: ECrane,
  },
  {
    id: 3,
    name: 'Crusher',
    img: ECrusher,
  },
  {
    id: 5,
    name: 'Excavator',
    img: EExcavator,
  },
]

export function partsDataParse(data) {
  const allNodeIds = []
  const splitBy = (sep) => (str) => str.split(sep).map((x) => x.trim())
  const splitLine = splitBy('<#>')
  const splitCategories = splitBy('->')

  const nest = (xs) =>
    xs.length === 2
      ? typeof xs[1] === 'string'
        ? { [xs[0]]: { id: xs[1] } }
        : { [xs[0]]: nest(xs[1]) }
      : nest([xs[0], xs.slice(1)])

  const load = (lines) =>
    [lines]
      .map((lines) => lines.map(splitLine))
      .map((lines) => lines.map(([id, cats]) => splitCategories(cats).concat(id)))
      .map((lines) => lines.map(nest))
      .map((lines) => deepmerge.all(lines))
      .pop()

  const lineData = []

  // eslint-disable-next-line no-restricted-syntax
  for (const e of data) {
    lineData.push(`${e.id} <#> ${e.path}`)
  }
  const splitByDeep = (sep) => (str) => str.split(sep).map((x) => x.trim())

  const splitId = splitByDeep('-#-')
  const resultObj = []
  const goDeep = (subElem) => {
    if (subElem) {
      const children = []

      // eslint-disable-next-line no-restricted-syntax
      for (const elm of Object.keys(subElem)) {
        const [subElemId, subElemName] = splitId(elm)
        if (subElemName) {
          allNodeIds.push(subElemId)
          children.push({
            id: subElemId,
            name: subElemName,
            children: goDeep(subElem[elm]),
          })
        }
      }
      return children?.length > 0 ? children : null
    }
    return null
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const elem of Object.keys(data)) {
    const [elemId, elemName] = splitId(elem)
    allNodeIds.push(elemId)
    resultObj.push({
      id: elemId,
      name: elemName,
      children: goDeep(data[elem]),
    })
  }

  const partsList = goDeep(load(lineData))

  allNodeIds.splice(0, 1)

  return { partsList, allNodeIds }
}

export function parseOrderList(data, type) {
  try {
    const obj = {}
    data?.map((row) => {
      const date = formatDate(row[type], DATE_FORMAT_COMMA)

      if (obj[date]) {
        obj[date].push(row)
      } else {
        obj[date] = [row]
      }
      return null
    })
    return obj
  } catch (e) {
    console.error(e)
    return []
  }
}
