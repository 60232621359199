import { Tab, Tabs as MuiTab } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import { PropTypes } from 'prop-types'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'

const useStyles = makeStyles({
  root: {
    '& .MuiTabs-indicator': { backgroundColor: getColor('func-info') },
    '& .MuiTab-root': { color: getColor('grey-g35') },
    '& .Mui-selected': { color: getColor('func-info') },
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '36px',
    minHeight: '25px',
    '& button': {
      padding: '0',
      marginRight: '20px',
      marginBottom: '9px',
      height: 'unset',
      minHeight: 'unset',
      minWidth: 'auto',
    },
  },
})
// TabLabel
export const Tabs = ({ value, array, onchange, className }) => {
  const classes = useStyles()
  return (
    <>
      <MuiTab value={value} onChange={onchange} className={className || classes.root}>
        {array?.map((item, index) => (
          <Tab key={index} id={item.id} label={<Typography variant="body1">{item.name || item.tabLabel}</Typography>} />
        ))}
      </MuiTab>
    </>
  )
}

Tabs.propTypes = {
  value: PropTypes.node,
  array: PropTypes.array,
  onchange: PropTypes.func,
  className: PropTypes.string,
}

// TabPanel

export function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Container role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Typography component={'div'}>{children}</Typography>}
    </Container>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number.isRequired,
}
