import Api from 'services/Api'

export async function getInventoryProductList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getFitsModelList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getPartDescList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getProductDetails(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function createProduct(data) {
  const response = await Api.post('/product', data)
  return response.data.data
}
export async function updateProduct(data) {
  const response = await Api.put('/product', data)
  return response.data.data
}
export async function crossRefNumberDelete(data) {
  const response = await Api.delete(
    data.brand_id === null
      ? `/prod-cross-ref?product_id=${data.product_id}&id=${data.id}`
      : `/prod-cross-ref?product_id=${data.product_id}&id=${data.id}&brand_id=${data.brand_id}`
  )
  return response.data.data
}

export async function fitsWithDataDelete(data) {
  const response = await Api.delete(
    data?.brand_id === null
      ? `/prod-fit?product_id=${data.product_id}&id=${data.id}`
      : `/prod-fit?product_id=${data.product_id}&id=${data.id}&brand_id=${data.brand_id}`
  )
  return response.data.data
}
export async function relatedProductDelete(data) {
  const response = await Api.delete(`/related-prod/${data}`)
  return response.data.data
}
export async function productImageDelete(id) {
  const response = await Api.delete(`/product-asset/${id}`)
  return response.data.data
}
export async function productCoverImage(id) {
  const response = await Api.patch(`/product-asset/cover/${id}`)
  return response.data.data
}
export async function shareViaEmailProduct(id, data) {
  const response = await Api.post(`/product/share/via-email/${id}`, data)
  return response.data.data
}
