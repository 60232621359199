import {
  getSubscriptionPaymentHistory,
  subscriptionCancel,
  subscriptionInvoiceDownload,
} from 'common-api/settings/settings'
import useToastify from 'common-hooks/useToastify'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import useProfileSettingDetail from '../profile/useProfileSettingDetail'

export default function useSubscriptionPaymentHistory() {
  const { toastMessage } = useToastify()
  const { profileData } = useProfileSettingDetail()
  const [loading, setLoading] = useState(false)
  const { data, mutate } = useSWR(() => {
    if (profileData?.user_plan?.plan_name === 'free') {
      return null
    }
    return '/subscription/payment-history'
  }, getSubscriptionPaymentHistory)
  useEffect(() => {
    if (profileData?.user_plan?.plan_name === 'free') {
      setLoading(false)
    }
  }, [data])
  const [invoiceUrl, setInvoiceUrl] = useState()

  const cancleSubscription = async (id, mutate) => {
    try {
      const response = await subscriptionCancel(id)
      toastMessage('success', 'Subscription Cancel Successfully')
      mutate()
      return response
    } catch {
      return false
    }
  }
  const downloadInvoice = async (id, mutate) => {
    try {
      const response = await subscriptionInvoiceDownload({ invoice_id: id })
      setInvoiceUrl(response)
      mutate()
      return response
    } catch {
      return false
    }
  }
  return {
    paymentHistory: data || [],
    isLoadingHistory: loading,
    mutate,
    cancleSubscription,
    downloadInvoice,
    invoiceUrl,
    setInvoiceUrl,
  }
}
