import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { getBadgeColor, getColor } from 'theme/colors'
import { Avatar as MuiAvatar } from '@mui/material'
import Typography from 'components/common/Typography'
import Container from 'components/common/Container'
import typography from 'theme/typography'
import { UserIcon } from 'assets/icons'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: getSize(props.avatarSize),
    height: getSize(props.avatarSize),
    backgroundColor: getColor('grey-g80'),
    borderRadius: '75%',
  }),
  info: {
    flex: 1,
    padding: (props) => getPadding(props.avatarSize, theme),
  },
  initials: (props) => ({
    color: getColor('dark-d25'),
    ...getRootStyle(props),
  }),
  name: {
    '& span[data-role="email"]': {
      color: getColor('grey-g50'),
    },
  },
  avatarContainer: (props) => ({
    ...(!!props.onClick && {
      cursor: 'pointer',
    }),
  }),
}))
function getInitials(firstName, lastName) {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`
  }
  if (firstName) {
    return firstName[0]
  }
  if (lastName) {
    return lastName[0]
  }
  return ''
}
const namesCache = {}

export default function Avatar({
  onlyAvatar,
  firstName,
  lastName,
  fullName,
  email,
  phone,
  randomColor,
  img,
  userRole,
  subText,
  avatarSize = 'md',
  light,
  reverse,
  className,
  onClick,
  nameClassName,
  subtitleClassName,
  avatarIcon,
  customColor,
  date,
  ...props
}) {
  const [color] = useState(getBgColor(randomColor))
  const name = fullName || `${firstName} ${lastName}`
  const [badgeColor, setBadgeColor] = useState('')
  const classes = useStyles({ avatarSize, onClick, randomColor, color: badgeColor || color })
  const image = firstName || lastName || fullName
  useEffect(() => {
    if (!namesCache[name]) {
      const color = getBadgeColor()
      namesCache[name] = color
      setBadgeColor(color)
    } else {
      setBadgeColor(namesCache[name])
    }
  }, [fullName, name])

  return (
    <Container
      container
      direction={reverse ? 'row-reverse' : 'row'}
      className={classNames(classes.root, className)}
      {...props}
    >
      <Container onClick={onClick} className={classes.avatarContainer}>
        <MuiAvatar src={image ? img : <UserIcon />} className={classNames(classes.root, className)} {...props}>
          {avatarIcon || (
            <Typography variant={getInitialsVariant(avatarSize)} className={classes.initials}>
              {fullName ? getInitialsFullName(fullName) : getInitials(firstName, lastName)}
            </Typography>
          )}
        </MuiAvatar>
      </Container>
    </Container>
  )
}

Avatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fullName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  img: PropTypes.string,
  userRole: PropTypes.string,
  subText: PropTypes.string,
  avatarSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
  textSize: PropTypes.oneOf(['sm', 'md']),
  light: PropTypes.bool,
  reverse: PropTypes.bool,
  onlyAvatar: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  nameClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  avatarIcon: PropTypes.node,
  randomColor: PropTypes.bool,
  date: PropTypes.string,
  customColor: PropTypes.string,
}

function getPadding(size, theme) {
  switch (size) {
    case 'sm':
      return theme.spacing(0, 1.5)
    case 'md':
      return theme.spacing(0, 2.5)
    default:
      return theme.spacing(0, 2.5)
  }
}

function getInitialsFullName(fullName) {
  try {
    const [firstName, lastName] = fullName.split(' ')
    return getInitials(firstName, lastName)
  } catch (error) {
    return getInitials('')
  }
}

function getInitialsVariant(size) {
  switch (size) {
    case 'sm':
      return 'subtitle1'
    case 'md':
      return 'h3'
    case '2xl':
      return 'h1'
    default:
      return 'h3'
  }
}
function getBgColor(randomColor, customColor) {
  return customColor || (randomColor ? getBadgeColor() : getColor('grey-g90'))
}
function getSize(size) {
  switch (size) {
    case 'md':
      return 30
    case 'lg':
      return 40
    default:
      return 30
  }
}
function getRootStyle(props) {
  return {
    ...(props.avatarSize === 'md' && {
      fontSize: '14px',
      fontWeight: 400,
    }),
    ...(props.avatarSize === 'sm' && {
      fontSize: typography.body1.fontSize,
      fontWeight: typography.body1.fontWeight,
    }),
  }
}
