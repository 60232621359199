import TextField from 'components/common/text/TextField'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { useContext, useEffect } from 'react'
import { SignupDataContext } from 'utils/provider/AuthContext'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import GooglePlaces from 'components/custom/GooglePlaces'
import { formatAddressOfGAPI } from './SignUpUtils'
import { getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  customeToolTipInfo: {
    padding: '5px, 10px, 5px, 10px',
    '& ul': {
      margin: 0,
      paddingLeft: '18px',
    },
  },
}))

export default function SignUpAccountInfo({ onStepChange, step, setBtnDisable, emailId }) {
  const classes = useStyles()
  const { onSaveSignupData, signupData } = useContext(SignupDataContext)
  const methods = useForm({
    defaultValues: {
      firstName: signupData?.data?.firstName,
      lastName: signupData?.data?.lastName,
      password: signupData?.data?.password,
      confirmPassword: signupData?.data?.confirmPassword,
      location: signupData?.data?.locationString,
    },
  })
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  const { handleSubmit, control, setValue } = methods
  const [firstName, lastName, password, confirmPassword, location] = useWatch({
    control,
    name: ['firstName', 'lastName', 'password', 'confirmPassword', 'location'],
  })
  const onSaveSignupContextValue = () => {
    const data = {
      firstName,
      lastName,
      location,
      password,
      confirmPassword,
      emailId,
      locationString:
        getWellKnownAddress(location?.addr_line_1, location?.addr_line_2) === '-'
          ? signupData?.data?.locationString
          : getWellKnownAddress(location?.addr_line_1, location?.addr_line_2),
    }
    onSaveSignupData({ ...signupData, data })
  }

  useEffect(() => {
    if (firstName && lastName && password && location && password === confirmPassword) {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }, [firstName, lastName, password, location, confirmPassword, setBtnDisable])

  function onSubmit() {
    onSaveSignupContextValue()
    onStepChange(step + 1)
  }

  return (
    <FormProvider {...methods} autocomplete="off">
      <Container width={'100%'} direction={'column'} flex gap="30px">
        <form onSubmit={handleSubmit(onSubmit)} id={'signup-account-from'}>
          <Container flex justify="flex-start" direction={'column'} width={'320px'} height={'380px'} gap={'10px'}>
            <Container flex justify="space-between" gap="20px">
              <Controller
                name={'firstName'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={name}
                    placeholder={'First Name'}
                    name={name}
                    onChange={onChange}
                    value={value}
                    label={'First Name'}
                    onKeyDown={handleKeyDown}
                    autocomplete="off"
                  />
                )}
              />

              <Controller
                name={'lastName'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={name}
                    name={name}
                    onChange={onChange}
                    value={value}
                    placeholder={'Last Name'}
                    label={'Last Name'}
                    onKeyDown={handleKeyDown}
                    autocomplete="off"
                  />
                )}
              />
            </Container>
            <Controller
              name={'location'}
              control={control}
              render={({ field: { name, value } }) => (
                <GooglePlaces
                  id={name}
                  name={name}
                  label="Address"
                  spacing={3}
                  placeholder={'e.g. 4517 Washington Ave, New York, US'}
                  value={value}
                  onClearInput={() => setValue(name, '')}
                  onSelect={(loc) => {
                    setValue(name, formatAddressOfGAPI(loc.addr, loc.addr_line_1, loc.addr_line_2, true))
                  }}
                />
              )}
            />
            <Controller
              name={'password'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={name}
                  name={name}
                  type={'password'}
                  placeholder={'Password'}
                  onChange={onChange}
                  value={value}
                  label={'Set Password'}
                  toolTipinfo={passwordSuggestion(classes.customeToolTipInfo)}
                  autocomplete="new-password"
                />
              )}
            />
            <Controller
              name={'confirmPassword'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  type={'password'}
                  placeholder={'Confirm Password'}
                  value={value}
                  label={'Confirm Password'}
                  autocomplete="off"
                />
              )}
            />
          </Container>
        </form>
      </Container>
    </FormProvider>
  )
}
function passwordSuggestion(cssClass) {
  return (
    <Typography variant="paragraph1" className={cssClass}>
      Password contains
      <ul>
        <li>At least 8 characters</li>
        <li>Upper case letter (A-Z)</li> <li>Lower case letter (a-z)</li> <li>Number (0-9)</li> <li>Symbol (#$@)</li>
      </ul>
    </Typography>
  )
}
SignUpAccountInfo.propTypes = {
  onStepChange: PropTypes.func,
  step: PropTypes.number,
  setBtnDisable: PropTypes.func,
  emailId: PropTypes.string,
}
