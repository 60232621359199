import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'

import { Controller, useForm } from 'react-hook-form'

import TextField from 'components/common/text/TextField'
import useOrders from 'common-hooks/orders/useOrders'
import { useEffect } from 'react'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
})

export default function PurchaseOrderNumberDialog({ open, onClose, editPonNumber, mutate }) {
  const classes = useStyles()
  const { control, handleSubmit, setValue } = useForm()
  //   const options = countryOption?.map((item) => createOption(item, item))
  const { toastMessage } = useToastify()
  const { updatePoNumber } = useOrders()

  const onSubmit = (data) => {
    if (data.ponumber === '' || data.ponumber === undefined) {
      toastMessage('error', 'Purchase Order Number is Required')
    } else {
      updatePoNumber(data.ponumber)
      mutate()
      onClose()
    }
  }

  useEffect(() => {
    if (editPonNumber) {
      setValue('ponumber', editPonNumber)
    }
  }, [editPonNumber, setValue])

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Purchase Order Number'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.selectField}>
              <Controller
                name={'ponumber'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField id={name} name={name} value={value} onChange={onChange} placeholder="Type" />
                )}
              />
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">{editPonNumber ? 'Update' : 'Add'}</Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
PurchaseOrderNumberDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editPonNumber: PropTypes.string,
  mutate: PropTypes.func,
}
