import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Typography from 'components/common/Typography'
import TitleList from 'components/custom/list/TitleList'
import { getColor } from 'theme/colors'
import ShippoIcon from 'assets/images/ShippoIcon.svg'
import { useEffect, useState } from 'react'
import { ResetIcon } from 'assets/icons'

import Button from 'components/common/Button'
import useGetCompanyCarriers from 'common-hooks/settings/company/useGetCompanyCarriers'
import useSupplierDetails from 'common-hooks/super-admin/useSupplierDetails'
import LoadingOverlay from 'components/common/LoadingOverlay'
import useCompanySettings from 'common-hooks/settings/company/useCompanySettings'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import CourierImageCard from './courier/CourierImageCard'
import CourierStep from './courier/CourierStep'
import CourierConfirmationDialog from './courier/CourierConfirmationDialog'
import useToggle from 'common-hooks/useToggle'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    // paddingTop: '',
    // marginTop: '30px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '20px',
    border: '1px solid #F0F0F4',
  },
  headerDiv: {
    paddingTop: '30px',
  },
  detailDiv: {
    // paddingTop: '20px',
    padding: '0 20px 20px 20px',
  },
  shipoIconLine: {
    display: 'flex',
    color: ' #C3C5D5',
    gap: '0 5px',
  },
  courierImagecardGrid: {
    display: 'flex',
    gap: '19px',
    marginBottom: '20px',
    flexFlow: 'wrap',
  },
  switchRoot: {
    display: 'flex',
    gap: '0 20px',
    alignItems: 'center',
  },
  iconBtn: {
    width: '36px',
    height: '36px',
    border: '1px solid #9799B5',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: 'transparent' },
  },
  editList: {
    backgroundColor: 'transparent!important',
  },
  unLinkText: {
    color: '#AD1F36',
  },
  courinerInner: {
    padding: '20px',
  },
  btns: {
    display: 'flex',
    gap: '0 20px',
    paddingLeft: '20px',
    // borderLeft: '1px solid #F0F0F4',
  },
  linkcolor: {
    color: '#7D809B',
    textDecoration: 'underline',
  },
  margin: {
    // marginBottom: '10px',
  },
}))
export default function CompanyCourier() {
  const classes = useStyles()
  // const [toogleSwitch, seToggleSwitch] = useState(true)
  const [isShipo, setShipo] = useState(false)
  const [confirmationDialog, setConfirmationDialog] = useToggle(false)

  const company = getFromLocalStorage(constants.COMPANY_ID)
  let id
  if (![null, undefined, 'null', 'undefined'].includes(company)) {
    id = company
  }
  const { supplierDetail } = useSupplierDetails(id)
  // const shipo = supplierDetail?.shippo_enabled

  const { companyCarriersList, isLoading, mutate } = useGetCompanyCarriers(isShipo)
  const { unlinkShippo } = useCompanySettings()

  const handleUnlinkAccount = () => {
    setShipo(false)
    unlinkShippo(mutate)
  }

  useEffect(() => {
    if (supplierDetail?.shippo_enabled) {
      setShipo(supplierDetail?.shippo_enabled)
    }
  }, [supplierDetail?.shippo_enabled])

  return (
    <Container>
      <Container className={classes.root}>
        <Container className={classes.courinerInner} flex justify="space-between" width="100%">
          <Container>
            <TitleList
              title={'Automate Freight Rates'}
              subtitle={'Connect your courier accounts to automate courier rates.'}
            />
          </Container>
          <Container className={classes.switchRoot}>
            {isShipo ? (
              <>
                {/* <SwitchToggle defaultChecked={toogleSwitch} onChange={() => seToggleSwitch(!toogleSwitch)} /> */}
                <Container className={classes.btns}>
                  <Button className={classes.iconBtn} onClick={() => mutate()} icon={<ResetIcon />} />
                  <Button variant="secondary" border className={classes.unLinkText} onClick={setConfirmationDialog}>
                    Unlink Account
                  </Button>
                </Container>
                {/* <ContextMenu
                className={classes.editList}
                closeOnClick
                trigger={<Button className={classes.iconBtn} icon={<EllipsisHorizontalIcon />} />}
              >
                <Container className={classes.lists}>
                  <ContextMenuItem>
                    <Button className={classes.onHoverBtn} variant="secondary" onClick={() => mutate()}>
                      Sync Account
                    </Button>
                  </ContextMenuItem>
                  <ContextMenuItem>
                    <Button variant="secondary" className={classes.unLinkText} onClick={handleUnlinkAccount}>
                      Unlink Account
                    </Button>
                  </ContextMenuItem>
                </Container>
              </ContextMenu> */}
              </>
            ) : (
              ''
            )}
          </Container>
        </Container>

        <Container className={classes.detailDiv}>
          {isShipo && (
            <>
              {isLoading && <LoadingOverlay />}
              <Container className={classes.margin}>
                <Container className={classes.courierImagecardGrid}>
                  {companyCarriersList?.map((data, index) => (
                    <CourierImageCard
                      key={index}
                      // toogleSwitch={toogleSwitch}
                      active={data.active}
                      carriername={data.carrier_name}
                      image={data.image}
                    />
                  ))}
                </Container>
                <Container flex direction="column">
                  <Typography variant="overline" color={getColor('grey-g80')}>
                    All courier accounts are securely handled by Shippo.
                  </Typography>
                  <Typography
                    variant="overline"
                    color={getColor('grey-g80')}
                    className={classes.shipoIconLine}
                    component="p"
                  >
                    Powered by <Image src={ShippoIcon} width={'49px'} />
                  </Typography>
                </Container>
              </Container>
            </>
          )}
          {isShipo !== undefined && !isShipo && <CourierStep setShipo={setShipo} />}
        </Container>
        <CourierConfirmationDialog
          onClose={setConfirmationDialog}
          open={confirmationDialog}
          handleUnlinkAccount={handleUnlinkAccount}
          setShipo={setShipo}
          mutate={mutate}
        />
      </Container>
      <Typography variant="paragraph1" color={'#7D809B'}>
        Require any assistance with Shippo? We’re happy to help. Contact us at{' '}
        <a className={classes.linkcolor} href="mailto:support@partsclub.us">
          support@partsclub.us
        </a>{' '}
      </Typography>
    </Container>
  )
}
