import { getBrandsData } from 'common-api/create-quote/createQuote'
import { useState } from 'react'
import useSWR from 'swr'

export default function useBrandList(id) {
  const [search, onSearch] = useState('')
  const { data } = useSWR(() => {
    if (!id || id === 81) {
      return `/services/equip-brands/brand-list`
    }
    if (search === '') {
      return `/services/equip-brands/brand-list?equip_ids=${id}`
    }
    if (search !== '') {
      return `/services/equip-brands/brand-list?equip_ids=${id}&search=${search}`
    }
    return null
  }, getBrandsData)
  return {
    brandList: data,
    onSearch,
    search,
    onBrandSearch: onSearch,
    searchedBrand: search,
    isLoading: !data,
  }
}
