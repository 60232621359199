import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import FedExCourier from 'assets/images/FedEx_Courier.svg'
import USPSCourier from 'assets/images/USPS_Courier.svg'
import UPSCourier from 'assets/images/UPS_Courier.svg'
import DHLCourier from 'assets/images/DHL_Courier.svg'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Select from 'components/common/select/Select'
import { Controller, useForm, useWatch } from 'react-hook-form'
import TextField from 'components/common/text/TextField'
import useOrders from 'common-hooks/orders/useOrders'
import { useEffect } from 'react'
import { createOption } from 'components/common/text/DropDownTextField'
import useToastify from 'common-hooks/useToastify'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  iconWithLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 10px',
  },
})

export default function ShipOrderDialog({ open, onClose, mutate, editTrackingNumber, activeId }) {
  const classes = useStyles()
  const { control, handleSubmit, setValue } = useForm()
  //   const options = countryOption?.map((item) => createOption(item, item))

  const options = [
    {
      label: (
        <div className={classes.iconWithLabel}>
          <img src={USPSCourier} alt="usps" />
          <p>USPS</p>
        </div>
      ),
      value: 'USPS',
    },
    {
      label: (
        <div className={classes.iconWithLabel}>
          <img src={UPSCourier} alt="usps" />
          <p>UPS</p>
        </div>
      ),
      value: 'UPS',
    },
    {
      label: (
        <div className={classes.iconWithLabel}>
          <img src={FedExCourier} alt="usps" />
          <p>FedEx</p>
        </div>
      ),
      value: 'FedEx',
    },
    {
      label: (
        <div className={classes.iconWithLabel}>
          <img src={DHLCourier} alt="usps" />
          <p>DHL</p>
        </div>
      ),
      value: 'DHL',
    },
    { label: 'Other', value: 'Other' },
  ]
  const [Courier] = useWatch({
    control,
    name: ['Courier'],
  })
  const { orderShip, updateTrackingNumber } = useOrders()
  const { toastMessage } = useToastify()
  const navigate = useNavigate()

  const onSubmit = (data) => {
    const courierNme = data.couriername ? data.couriername : data.Courier.value

    if (editTrackingNumber) {
      updateTrackingNumber(data.trackingnumber, courierNme)
      mutate()
    } else if (!data.Courier) {
      toastMessage('error', 'Please select Courier')
    } else if (!data.trackingnumber) {
      toastMessage('error', 'Please enter tracking number')
    } else if (data.Courier.value === 'Other' && !data.couriername) {
      toastMessage('error', 'Please enter courier name ')
    } else {
      orderShip(data.trackingnumber, courierNme, activeId)
      navigate('/orders', { state: { status: 'shipped' } })
      onClose()
    }

    // navigate('/orders')
  }

  useEffect(() => {
    if (editTrackingNumber) {
      Object.keys(editTrackingNumber).map((key) => {
        if (['Courier'].includes(key)) {
          setValue('Courier', createOption(editTrackingNumber?.Courier, editTrackingNumber?.Courier))
        } else {
          setValue(key, editTrackingNumber[key])
        }
        return 0
      })
    }
  }, [editTrackingNumber, setValue])

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Ship Order'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.selectField}>
              <Controller
                name={'Courier'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <Select
                    options={options}
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    label={'Courier*'}
                    menuPosition={'fixed'}
                  />
                )}
              />
              {Courier?.value === 'Other' && (
                <Controller
                  name={'couriername'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={'Courier Name'}
                      placeholder="ex. FedEx"
                    />
                  )}
                />
              )}
              <Controller
                name={'trackingnumber'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    label={'Tracking Number'}
                    placeholder="ex. 1Z5977460390043291"
                  />
                )}
              />
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!Courier}>
                  {editTrackingNumber ? 'Update' : 'Ship Now'}
                </Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
ShipOrderDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  activeId: PropTypes.string,
  mutate: PropTypes.func,
  editTrackingNumber: PropTypes.array,
}
