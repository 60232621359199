/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { getPartsData } from 'common-api/create-quote/createQuote'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import { useState } from 'react'

export default function useQuotePartsTree(equpId) {
  const [searchedParts, onPartsSearch] = useState('')
  const params = {
    ...(equpId && equpId !== 81 && { equipment_id: equpId }),
  }
  const { data, mutate } = useSwrInfinite('/services/part', params, getPartsData)
  const partsTree = data
    ? data.map((obj) => ({
        id: obj.id,
        parent_id: obj.parent_id,
        name: obj.name,
        user_created: obj?.user_created,
        children: [{ parent_id: obj.id, name: 'Add Part' }],
      }))
    : []

  return {
    partsTree,
    onPartsSearch,
    searchedParts,
    isLoading: !data,
    mutate,
  }
}
