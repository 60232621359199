import { getEquipmentsData } from 'common-api/create-quote/createQuote'
import { useState } from 'react'
import useSWR from 'swr'

export default function useEquipmentsList(id) {
  const [search, onSearch] = useState('')
  const { data } = useSWR(() => {
    if (search === '' && !id) {
      return `/services/equip-list`
    }
    if (id.length > 0 && search === '') {
      return `/services/equip-inds/equip-list?industry_id=${id}`
    }
    if (id.length > 0 && search !== '') {
      return `/services/equip-inds/equip-list?industry_id=${id}&search=${search}`
    }

    return null
  }, getEquipmentsData)

  return {
    equipmentsList: data,
    onSearch,
    search,
    searchEqup: search,
    onSearchEqup: onSearch,
    isLoading: !data,
  }
}
