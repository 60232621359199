/* eslint-disable camelcase */
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
// import Image from 'components/common/Image'
import { Controller, useForm } from 'react-hook-form'
import Typography from 'components/common/Typography'
import companyHeaderPlaceholder from 'assets/images/companyHeaderPlaceholder.png'
import Avatar from 'assets/images/Avatar.svg'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'

import useSupplierdetails from 'common-hooks/super-admin/useSupplierDetails'
import Button from 'components/common/Button'
import { AddIcon, CaretDownIcon, DeleteIcon, DeletePlainWhite, Upload } from 'assets/icons'

import TextField from 'components/common/text/TextField'
import Select from 'components/common/select/Select'
import {
  CreateSupplierContext,
  getEquipAndPartsUpdatedData,
  oprationHoursDefaultObj,
  parseSupplierDetails,
} from 'containers/super-admin/supplier-mgt/SupplierUtils'
import useIndustryList from 'common-hooks/useIndustryList'
import { getFromLocalStorage, getOptionsList, getWellKnownAddress } from 'utils/Utils'
import TextArea from 'components/common/text/TextArea'
import EmailField from 'components/common/text/EmailField'
import Seperator from 'components/common/Seperator'
import GooglePlaces from 'components/custom/GooglePlaces'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import Checkbox from 'components/common/Checkbox'
import useToggle from 'common-hooks/useToggle'
// import SpecializationInTreeView from 'containers/common-containers/SpecializationInTreeView'
import TitleList from 'components/custom/list/TitleList'
import { getColor } from 'theme/colors'
import List from 'components/custom/list/List'
import OemDialog from 'containers/common-containers/OemDialog'
import DistributorDialog from 'containers/common-containers/DistributorDialog'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import { getTimes } from 'containers/supplier-quote-details/SupplierQuoteDetailsUtils'
import { createOption } from 'components/common/MonthYearPicker'
import SpecializationInTreeView from 'containers/common-containers/SpecializationInTreeView'
import AddEquipmentDialog from 'containers/common-containers/AddEquipmentDialog'
import AddPartsDialog from 'containers/common-containers/AddPartsDialog'
import useCompanySettings from 'common-hooks/settings/company/useCompanySettings'
import classNames from 'classnames'
import { updateProfileEmail, verifyProfileEmail } from 'common-api/settings/settings'
import { yupResolver } from '@hookform/resolvers/yup'
import { getFormError } from 'utils/form/form'
import useToastify from 'common-hooks/useToastify'
import { constants } from 'utils/constants'
import IconButton from 'components/common/IconButton'
import LabelTextField from 'components/common/text/LabelTextField'
import ImageFileUpload from 'components/ImageFileUpload'
// import { companyOptions } from '../SettingsUtils'

const useStyles = makeStyles(() => ({
  root: {
    '& .root-main': {
      border: `1px solid ${getColor('grey-g95')}`,
      width: '31.5%',
      height: '50px',
      padding: '10px 10px',
    },
    '& .root-main:hover .delete-icon': {
      display: 'block',
      cursor: 'pointer',
      marginRight: '-8px',
    },
    '& .delete-icon': {
      display: 'none',
    },

    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '#companyInfo-location': {
      marginBottom: 0,
    },
    '& #drop-zone-logo': {
      '& div': {
        textAlign: 'center',
      },
    },
    '& #company-profile': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px 0',
    },
  },
  headerDiv: {
    padding: '30px',
  },
  headImgBox: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  imgContainer1: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    // marginTop: '30px',
  },
  staticText: {
    position: 'absolute',
    left: '10px',
    top: '28px',
  },
  imageInfo: {
    height: '170px',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  profileImgMain: {
    height: '120px',
    width: '120px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    '& img': {
      width: '120px',
      height: '120px',
      borderRadius: '4px',
    },
  },
  imageInfoPlace: {
    height: '170px',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    height: '170px',
    position: 'relative',
    // marginTop: '30px',
    '&:hover .logo-hover': {
      display: 'flex',
    },
    '& .logo-hover': {
      display: 'none',
      position: 'absolute',
      backgroundColor: '#000c',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      cursor: 'pointer',

      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .delete-btn': {
      background: 'unset',
      position: 'absolute',
      top: '0',
      right: '0',
      '&:hover': {
        background: 'unset',
      },
    },
  },
  profileImg: {
    height: '120px',
    width: '120px',
    position: 'relative',
    top: '-60px',
    left: '20px',
    '&:hover .logo-hover': {
      display: 'flex',
    },
    '& .logo-hover': {
      height: '120px',
      width: '120px',

      display: 'none',
      position: 'absolute',
      backgroundColor: '#000c',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      cursor: 'pointer',

      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .delete-btn': {
      background: 'unset',
      position: 'absolute',
      top: '0',
      right: '0',
      '&:hover': {
        background: 'unset',
      },
    },
  },
  profileInfo: {
    height: '120px',
    width: '120px',
    border: '3px solid #fff',
    justifyContent: 'center',
    borderRadius: '4px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    alignItems: 'center',
  },
  formText: {
    position: 'relative',
    '& input': {
      paddingLeft: '65px',
    },
  },
  inputImgStyle: {
    display: 'none',
  },
  iconbtn: {
    padding: '0',
    minHeight: 'unset',
    backgroundColor: 'unset',
    right: '5px',
    position: 'absolute',
    top: '5px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  profileIconBtn: {
    padding: '0',
    minHeight: 'unset',
    backgroundColor: 'unset',
    right: '6px',
    position: 'absolute',
    top: '5px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  profilelists: {
    position: 'relative',
  },
  companyDetails: {
    paddingTop: '20px',
  },
  contactDetails: {
    paddingTop: '20px',
    display: 'flex',
    gap: '20px',
  },
  phoneContactDetails: {
    paddingTop: '20px',
    display: 'flex',
    gap: '5px',
  },
  emailFiled: {
    width: '385px',
  },
  phoneFlied: {
    width: '249px',
  },
  companyDetailsMain: {
    // marginBottom: '20px',
  },
  treeView: {
    paddingTop: '20px',
    '& ul': {
      paddingBottom: '0',
    },
    '& .css-9mgopn-MuiDivider-root': {
      marginLeft: 0,
    },
  },
  exapanBtn: (props) => ({
    '& svg': {
      rotate: props.delivery ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  exapanTermsBtn: (props) => ({
    '& svg': {
      rotate: props.product ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  ophours: (props) => ({
    height: '30px',
    minHeight: '30px',
    '& svg': {
      rotate: props.ophours ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  social: (props) => ({
    '& svg': {
      rotate: props.social ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),

  brandBtn: {
    borderColor: getColor('grey-g95'),
    color: '#36384C',
    border: '1px solid',
    width: '31.5%',
    '&:hover': {
      cursor: 'pointer',
    },
    height: '50px',
  },
  img: {
    height: '30px',
    width: '30px',
    border: '1px solid #F0F0F4',
  },
  brandDiv: {
    marginTop: '30px',
    flexFlow: 'wrap',
    display: 'flex',
    gap: '20px',
  },
  dayDiv: {
    width: '152px',
    color: '#34364c',
  },
  hrsRoot: {
    // paddingBottom: '20px',
  },
  cmnPadding: {
    padding: '20px 0',
  },
  centerMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  tagLine: {
    height: '56px',
  },
  personalDetailRoot: {
    background: '#fff',
    border: ' 1px solid #F0F0F4',
    borderRadius: ' 4px',
    // marginTop: '30px',
    // padding: '0 20px 30px 20px',
  },
  companyDetailInner: {
    padding: '0 20px 20px 20px',
  },
  shipingDetailInner: {
    padding: '20px 20px 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  bilingAddressText: {
    paddingBottom: '10px',
  },
  shipingDetailCommon: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  specoalizationsInner: {
    padding: '20px 20px 20px 20px',
  },
  oemDealerRoot: {
    padding: '20px 20px 20px 20px',
  },
  imgtitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  labelTextField: {
    width: '80px',
  },
}))
export default function CompanyProfile({
  specializationPartsData,
  setSpecializationsData,
  setSpecializationPartsData,
  // setEnableBtn,
}) {
  const schema = yup.object({
    name: yup.string().required('Company name is required.'),
    website_url: yup.string().required('website url is required.'),
    phone_number: yup
      .string()
      .nullable(false)
      .matches(/^\d{10}$/, 'Please enter the valid mobile number')
      .required(),
  })
  const userRole = getFromLocalStorage(constants.ROLE)
  const { toastMessage } = useToastify()

  const { industryData } = useIndustryList()
  const { updateCompany, companyProfileImagesDelete } = useCompanySettings()
  const industryOptions = getOptionsList(industryData)
  const [oprationHours, setOprationHours] = useState(oprationHoursDefaultObj)
  const [, refreshOprationHours] = useState(false)
  const [dealerBrands, setDealerBrands] = useState([])
  const [delivery, toggleDelivery] = useToggle(true)
  const [product, toggleProduct] = useToggle(true)
  const [ophours, toggleOphours] = useToggle(true)
  const [social, toggleSocial] = useToggle(true)

  const ophoursOptios = getTimes()
  const classes = useStyles({ delivery, product, ophours, social })
  const [headerImage, setHeaderImage] = useState()

  const [openDistDialog, toggleDistDialog] = useToggle(false)
  const [distBrands, setDistBrands] = useState([])
  const [charCount, setCharCount] = useState(120)
  const [tagline, setTagline] = useState()
  const [profileImage, setProfileImage] = useState()
  const [openOemDailog, toggleOemDailog] = useToggle(false)
  const [isHovering] = useState({ display: 'none' })
  const company = getFromLocalStorage(constants.COMPANY_ID)
  let id
  if (![null, undefined, 'null', 'undefined'].includes(company)) {
    id = company
  }
  const { supplierDetail } = useSupplierdetails(id)
  const [equipsObj, setEquips] = useState({ del_equips: [], equips: [] })
  const [openEquipDialog, toggleEquipDialog] = useToggle(false)
  const [openParts, toggleParts] = useToggle(false)
  const [equipId, setEquipId] = useState()
  const [partId, setPartId] = useState()
  const imageUploader = useRef()
  const profileUploader = useRef()
  const [sameAsAddress, setSameAsAddress] = useState(true)
  const { specializationTreeViewData } = useContext(CreateSupplierContext)
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const handleOprationHours = (key, value) => {
    setOprationHours((pre) => {
      pre[key] = value

      return pre
    })
    refreshOprationHours((pre) => !pre)
  }
  const getDisableStatus = (key) => !oprationHours[key]
  const getTimeValue = (key) => createOption(oprationHours[key], oprationHours[key])

  useEffect(() => {
    let data
    if (supplierDetail) {
      data = parseSupplierDetails(supplierDetail)
      Object.keys(data).map((key) => {
        if (key === 'industries') {
          setValue(
            key,
            data[key].map((values) => createOption(values.id, values.name))
          )
        } else {
          setValue(key, data[key])
        }
        return 0
      })
      setDistBrands(data?.disti_brands)
      setTagline(data?.tagline)
      setDealerBrands(data?.dlr_brands)
      setHeaderImage(supplierDetail?.img_header ? supplierDetail?.img_header : '')
      setProfileImage(supplierDetail?.logo ? supplierDetail?.logo : '')
      if (data?.cmp_ops_hrs) {
        setOprationHours(data?.cmp_ops_hrs)
      }
    }
    if (specializationTreeViewData) {
      setSpecializationsData(specializationTreeViewData)
    }
    if (data?.equips) {
      setSpecializationsData(data?.equips)
      setSpecializationPartsData(data?.parts)
    }
  }, [supplierDetail, setValue])
  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    const { add_parts, equips, del_equips } = getEquipAndPartsUpdatedData(
      id,
      equipsObj,
      supplierDetail,
      specializationPartsData
    )
    const profile = true
    delete oprationHours?.id

    if (data.industries.length <= 0) {
      toastMessage('error', 'Industry is Required')
      return 0
    }
    if (!data.cmp_addr?.addr_line_1) {
      toastMessage('error', 'Company address is Required')
      return 0
    }
    if (!data.cmp_billing_addr?.addr_line_1 && !data.same_address) {
      toastMessage('error', 'Billing address is Required')
      return 0
    }

    updateCompany(
      {
        id,
        ...data,
        dlr_brands: dealerBrands,
        disti_brands: distBrands,
        tagline,
        add_parts,
        equips,
        del_equips,
        industries: getValues().industries.map((values) => values.value),
        // company_type: getValues().industries.map((values) => values.value),
        cmp_ops_hrs: oprationHours,
      },
      headerImage,
      profileImage,
      profile
    )
  }
  const handleBannerFileChange = (name, newFiles) => {
    const image = new Image()
    image.addEventListener('load', () => {
      if (image.width === 1060 && image.height === 170) {
        setHeaderImage(newFiles)
      } else {
        toastMessage('error', 'Upload image in 1060px x 170px resolution')
      }
    })
    image.src = URL.createObjectURL(newFiles)
  }
  const handleProfileFileChange = (name, newFiles) => {
    const image = new Image()
    image.addEventListener('load', () => {
      if (image.width === 512 && image.height === 512) {
        setProfileImage(newFiles)
      } else {
        toastMessage('error', 'Upload image in 512px x 512px resolution')
      }
    })
    image.src = URL.createObjectURL(newFiles)
  }
  return (
    <Container className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)} id="company-profile">
        <Container className={classes.personalDetailRoot}>
          <Container height="247px">
            <Container className={classes.headImgBox}>
              <Container className={classes.imgContainer}>
                <input
                  onChange={(e) => handleBannerFileChange('bannerImage', e.target.files[0])}
                  // onChange={(e) => setHeaderImage(e.target.files[0])}
                  ref={imageUploader}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  className={classes.inputImgStyle}
                />
                <Container className={classes.imageInfo}>
                  {headerImage ? (
                    <ImageFileUpload
                      alt={'user-header'}
                      src={typeof headerImage === 'string' ? headerImage : URL.createObjectURL(headerImage)}
                      className={classes.imageInfo}
                    />
                  ) : (
                    <ImageFileUpload alt={'user-header'} src={companyHeaderPlaceholder} className={classes.imageInfo} />
                  )}
                </Container>
                {/* <ContextMenu
                  closeOnClick
                  trigger={
                    <Button
                      icon={<ProfileInfoFillIcon />}
                      className={classes.iconbtn}
                      transformOriginHorizontal={'left'}
                    />
                  }
                >
                  <Container className={classes.profilelists}>
                    {!headerImage ? (
                      <>
                        <ContextMenuItem
                          onClick={() => {
                            imageUploader.current.click()
                            // setEnableBtn(true)
                          }}
                        >
                          Upload Image
                        </ContextMenuItem>
                      </>
                    ) : (
                      <>
                        <ContextMenuItem
                          onClick={() => {
                            imageUploader.current.click()
                            // setEnableBtn(true)
                          }}
                        >
                          Upload New Image
                        </ContextMenuItem>
                        <ContextMenuItem
                          onClick={() => {
                            setHeaderImage(null)
                            profileImagesDelete({ type: 'cover' })
                          }}
                        >
                          <span className={classes.deleteTxt}>Delete Image </span>
                        </ContextMenuItem>
                      </>
                    )}
                  </Container>
                </ContextMenu> */}

                <Container className="logo-hover">
                  {!headerImage ? (
                    <Container>
                      <Upload onClick={() => imageUploader.current.click()} className={classes.uploadBtn} />
                    </Container>
                  ) : (
                    <>
                      {' '}
                      <IconButton
                        className={'delete-btn'}
                        onClick={() => {
                          setHeaderImage(null)
                          companyProfileImagesDelete({ type: 'cover' })
                        }}
                      >
                        <DeletePlainWhite />
                      </IconButton>
                      <Container>
                        <Upload onClick={() => imageUploader.current.click()} className={classes.uploadBtn} />
                      </Container>
                    </>
                  )}
                </Container>
                <Container className={classes.imgtitle}>
                  <Typography variant="overline" color="#C3C5D5">
                    Logo Size should be 512 x 512, Header image size should be 1060 x 170. Supported files are .png and
                    .jpg
                  </Typography>
                </Container>
              </Container>

              <Container className={classes.profileImg}>
                <input
                  // onChange={(e) => setProfileImage(e.target.files[0])}
                  onChange={(e) => handleProfileFileChange('profileImage', e.target.files[0])}
                  ref={profileUploader}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  className={classes.inputImgStyle}
                />
                <Container className={classes.profileImgMain}>
                  {profileImage ? (
                    <ImageFileUpload
                      alt={'user-profile'}
                      src={typeof profileImage === 'string' ? profileImage : URL.createObjectURL(profileImage)}
                      className={classes.profileInfo}
                    />
                  ) : (
                    <ImageFileUpload alt={'user-profile'} src={Avatar} className={classes.profileInfoPlace} />
                  )}
                </Container>
                {/* <ContextMenu
                  closeOnClick
                  trigger={
                    <Button
                      icon={<ProfileInfoFillIcon />}
                      className={classes.profileIconBtn}
                      transformOriginHorizontal={'left'}
                    />
                  }
                >
                  <Container className={classes.lists}>
                    {!profileImage ? (
                      <>
                        <ContextMenuItem onClick={() => profileUploader.current.click()}>Upload Image</ContextMenuItem>
                      </>
                    ) : (
                      <>
                        <ContextMenuItem onClick={() => profileUploader.current.click()}>
                          Upload New Image
                        </ContextMenuItem>
                        <ContextMenuItem
                          onClick={() => {
                            setProfileImage(null)
                            profileImagesDelete({ type: 'logo' })
                          }}
                        >
                          <span className={classes.deleteTxt}>Delete Image</span>
                        </ContextMenuItem>
                      </>
                    )}
                  </Container>
                </ContextMenu> */}

                <Container className="logo-hover">
                  {!profileImage ? (
                    <Container>
                      <Upload onClick={() => profileUploader.current.click()} className={classes.uploadBtn} />
                    </Container>
                  ) : (
                    <>
                      <IconButton
                        className={'delete-btn'}
                        onClick={() => {
                          setProfileImage(null)
                          companyProfileImagesDelete({ type: 'logo' })
                        }}
                      >
                        <DeletePlainWhite />
                      </IconButton>
                      <Container>
                        <Upload onClick={() => profileUploader.current.click()} className={classes.uploadBtn} />
                      </Container>
                    </>
                  )}
                </Container>
              </Container>
            </Container>
          </Container>

          <Container className={classes.companyDetailInner}>
            <Container justify={'flex-start'} direction={'column'}>
              <Typography variant="title" color={getColor('grey-g25')}>
                Company Details
              </Typography>
              <Typography variant="paragraph1">Provide basic details of company.</Typography>
            </Container>
            <Container className={classes.companyDetailsMain}>
              <Container flex gap={'20px'} className={classes.companyDetails}>
                <Controller
                  name={'name'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={'Company Name*'}
                      placeholder={'Type'}
                      type="text"
                      // {...register('name', { required: true })}
                      error={getFormError(errors, 'name')}
                    />
                  )}
                />
                {/* <Controller
                name={'year_found'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <Select
                    options={yearFounded}
                    id={name}
                    value={value}
                    placeholder={'Select'}
                    width={'30%'}
                    name={name}
                    onChange={onChange}
                    label={'Year Founded'}
                  />
                )}
              /> */}

                <Controller
                  name={'year_found'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      // options={yearFounded}
                      id={name}
                      value={value}
                      placeholder={'Type'}
                      width={'30%'}
                      name={name}
                      onChange={onChange}
                      label={'Year Founded'}
                    />
                  )}
                />
              </Container>
              <Container flex gap={'20px'} justify="flex-start" className={classes.companyDetails}>
                <Controller
                  name={'industries'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      id={'industries'}
                      name={name}
                      multi
                      value={value}
                      label={'Industry*'}
                      options={industryOptions}
                      onChange={onChange}
                      width={'50%'}
                      // error={getFormError(errors, 'industries')}
                    />
                  )}
                />
                {/* <Controller
                name={'company_type'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <Select
                    options={companyOptions}
                    id={name}
                    value={value}
                    placeholder={'Select'}
                    width={'30%'}
                    name={name}
                    onChange={onChange}
                    label={'Company Type*'}
                    // error={getFormError(errors, 'company_type')}
                  />
                )}
              /> */}
              </Container>
              <Container className={classes.companyDetails}>
                <Container flex justify={'space-between'}>
                  <Typography variant="paragraph2" color="#36384C">
                    Tagline
                  </Typography>
                  <Typography variant="paragraph2" color="#36384C">
                    {charCount}
                  </Typography>
                </Container>
                <Container className={classes.tagLine}>
                  <Controller
                    name={'tagline'}
                    control={control}
                    render={({ field: { name } }) => (
                      <TextArea
                        id={name}
                        name={name}
                        value={tagline}
                        placeholder={'Write short tagline about company here'}
                        height={'56px'}
                        rows={3}
                        onChange={(e) => {
                          const charLength = e.target.value.length
                          setCharCount(120 - charLength)
                          setTagline(e.target.value)
                        }}
                        labelVariant="paragraph2"
                        // error={getFormError(errors, 'tagline')}
                        maxlength="120"
                      />
                    )}
                  />
                </Container>
              </Container>
              <Container className={classes.contactDetails}>
                <Controller
                  name={'website_url'}
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <TextField
                      id={name}
                      name={name}
                      onChange={onChange}
                      type="text"
                      value={value}
                      placeholder="www.partsclub.us"
                      // {...register('website_url', { required: true })}
                      error={getFormError(errors, 'website_url')}
                      label={'Website*'}
                      width={385}
                    />
                  )}
                />
                <Controller
                  name={'email'}
                  control={control}
                  render={({ field: { name, value } }) => (
                    <EmailField
                      name={name}
                      value={value}
                      label={'Email*'}
                      placeholder={'john.d@partsclub.com'}
                      disabled
                      className={classes.emailFiled}
                      verifyEmail={verifyProfileEmail}
                      updateEmail={updateProfileEmail}
                    />
                  )}
                />
              </Container>
              <Container className={classes.phoneContactDetails}>
                {/* <Controller
                  name={'phone_number'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropDownTextField
                      options={options}
                      // disabled
                      value={value}
                      // textBoxDisabled
                      id={'phone_number'}
                      type={'number'}
                      className={classes.phoneFlied}
                      label={'Office Phone Number*'}
                      control={control}
                      onChange={onChange}
                      placeholder="235 567 2355"
                    />
                  )}
                /> */}
                <Controller
                  name={'prefix'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <LabelTextField
                      id={name}
                      type={'number'}
                      labelText={'+'}
                      className={classes.labelTextField}
                      placeholder={'1'}
                      onChange={onChange}
                      name={name}
                      value={value}
                    />
                  )}
                />
                <TextField
                  id={'phone_number'}
                  type={'number'}
                  className={classes.phoneFlied}
                  control={control}
                  {...register('phone_number', { required: true })}
                  error={getFormError(errors, 'phone_number')}
                  placeholder="235 567 2355"
                />
              </Container>
              <Container className={classes.companyDetails} width={'100%'}>
                <Typography variant="paragraph2" color="#36384C">
                  About
                </Typography>
                <Controller
                  name={'about'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextArea
                      value={value}
                      name={name}
                      // height={'56px'}
                      id={name}
                      placeholder={'Write description about company here...'}
                      rows={6}
                      onChange={onChange}
                    />
                  )}
                />
              </Container>
            </Container>
          </Container>
        </Container>

        <Container className={classes.personalDetailRoot}>
          <Container className={classes.shipingDetailInner}>
            <Container className={classes.shipingDetailCommon}>
              <Container justify={'flex-start'} direction={'column'}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  Address*
                </Typography>
                <Typography variant="paragraph1">Let buyer know where the company is located.</Typography>
              </Container>
              <Container flex gap={'20px'}>
                <Controller
                  name={'cmp_addr'}
                  control={control}
                  render={({ field: { name } }) => (
                    <GooglePlaces
                      id={name}
                      name={name}
                      label="Location"
                      spacing={3}
                      onClearInput={() => setValue(name, { id: getValues()?.cmp_addr?.id })}
                      placeholder={'e.g. 4517 Washington Ave'}
                      value={
                        getValues()?.cmp_addr?.addr_line_1
                          ? getWellKnownAddress(getValues()?.cmp_addr?.addr_line_1, getValues()?.cmp_addr?.addr_line_2)
                          : ''
                      }
                      onSelect={(loc, i) => {
                        if (i === 0)
                          setValue(
                            'cmp_addr',
                            formatAddressOfGAPI(
                              loc.addr,
                              loc.addr_line_1,
                              loc.addr_line_2,
                              loc,
                              getValues()?.cmp_addr?.id
                            )
                          )
                        else
                          setValue(
                            'cmp_billing_addr',
                            formatAddressOfGAPI(
                              loc.addr,
                              loc.addr_line_1,
                              loc.addr_line_2,
                              loc,
                              getValues()?.cmp_billing_addr?.id
                            )
                          )
                      }}
                    />
                  )}
                />
              </Container>
            </Container>
            <Seperator />
            <Container className={classes.shipingDetailCommon}>
              <Container>
                <Container className={classes.bilingAddressText}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Billing Address
                  </Typography>
                </Container>
                <Typography variant="body1">
                  <Checkbox
                    value={sameAsAddress}
                    onChange={(e) => {
                      setValue('same_address', e.target.checked)
                      setSameAsAddress(e.target.checked)
                    }}
                  />
                  Same as address above
                </Typography>
              </Container>
              {!sameAsAddress ? (
                <>
                  <Container flex gap={'20px'}>
                    <Controller
                      name={'cmp_billing_addr'}
                      control={control}
                      render={({ field: { name } }) => (
                        <GooglePlaces
                          id={name}
                          name={name}
                          label="Location"
                          onClearInput={() => setValue(name, { id: getValues()?.cmp_billing_addr?.id })}
                          value={
                            getValues()?.cmp_billing_addr?.addr_line_1
                              ? getWellKnownAddress(
                                  getValues()?.cmp_billing_addr?.addr_line_1,
                                  getValues()?.cmp_billing_addr?.addr_line_2
                                )
                              : ''
                          }
                          spacing={3}
                          placeholder={'e.g. 4517 Washington Ave'}
                          onSelect={(loc, i) => {
                            if (i === 0)
                              setValue(
                                'cmp_addr',
                                formatAddressOfGAPI(
                                  loc.addr,
                                  loc.addr_line_1,
                                  loc.addr_line_2,
                                  loc,
                                  getValues()?.cmp_addr?.id
                                )
                              )
                            else
                              setValue(
                                'cmp_billing_addr',
                                formatAddressOfGAPI(
                                  loc.addr,
                                  loc.addr_line_1,
                                  loc.addr_line_2,
                                  loc,
                                  getValues()?.cmp_billing_addr?.id
                                )
                              )
                          }}
                        />
                      )}
                    />
                  </Container>
                </>
              ) : (
                <>{''}</>
              )}
            </Container>
          </Container>
        </Container>

        {userRole !== 'customer' && (
          <>
            {/* Company Specializations */}
            <Container className={classes.personalDetailRoot}>
              <Container className={classes.specoalizationsInner}>
                <Container justify={'space-between'}>
                  <Container justify={'flex-start'} direction={'column'}>
                    {/* <Typography variant="title" color={getColor('grey-g25')}>Specializations In</Typography> */}
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Specializations
                    </Typography>
                    <Typography variant="paragraph1">
                      Select the equipment and parts that you would like to recieve quote requests for
                    </Typography>
                  </Container>
                  <Button variant={'secondary'} onClick={toggleEquipDialog} border width="137px">
                    Add Equipment
                  </Button>
                </Container>
                <Container className={classNames(classes.companyDetails, classes.treeView)}>
                  <SpecializationInTreeView
                    openSpecialization={openEquipDialog}
                    toggleSpecialization={toggleEquipDialog}
                    openParts={openParts}
                    toggleParts={toggleParts}
                    setEquipId={setEquipId}
                    setPartId={setPartId}
                    setEquips={setEquips}
                  />
                </Container>
              </Container>
              <Seperator />
            </Container>

            {/* OEM Brands */}
            <Container className={classes.personalDetailRoot}>
              <Container className={classes.oemDealerRoot}>
                <Container flex justify="space-between">
                  <TitleList
                    title={'OEM Brands'}
                    subtitle={'Dealer designations will be sent to the OEM for verification.'}
                  />
                  <Button
                    variant="secondary"
                    className={classes.exapanBtn}
                    onClick={() => toggleDelivery(true)}
                    icon={<CaretDownIcon />}
                  />
                </Container>
                {delivery && (
                  <Container className={classes.brandDiv} display={'flex'} gap={'20px'}>
                    <List title="Add Brand" icon={<AddIcon />} className={classes.brandBtn} onClick={toggleOemDailog} />
                    {dealerBrands?.map((item, index) => (
                      <Container
                        className={'root-main'}
                        justify={'space-between'}
                        key={index}
                        item={item}
                        index={index}
                        flex
                      >
                        <Container className={classes.brands}>
                          <Container flex gap={'12px'} className={classes.oemBrands}>
                            <ImageFileUpload src={item?.img} alt="brandImg" className={classes.img} />
                            <Container>
                              <Typography variant="body" color={getColor('grey-g25')}>
                                {item?.label} {item?.name}
                              </Typography>
                            </Container>
                          </Container>
                        </Container>
                        <Container className={'delete-icon'}>
                          {isHovering && (
                            <DeleteIcon
                              onClick={() => setDealerBrands(dealerBrands.filter((dlr) => dlr.id !== item.id))}
                            />
                          )}
                        </Container>
                      </Container>
                    ))}
                  </Container>
                )}
              </Container>
            </Container>
            {/* Product Lines Brands */}
            <Container className={classes.personalDetailRoot}>
              <Container className={classes.oemDealerRoot}>
                <Container flex justify="space-between">
                  <TitleList
                    title={'Product Lines'}
                    subtitle={'Distributor designations will be sent to the manufacturer for verification.'}
                  />
                  <Button
                    variant="secondary"
                    className={classes.exapanTermsBtn}
                    onClick={() => toggleProduct(true)}
                    icon={<CaretDownIcon />}
                  />
                </Container>
                {product && (
                  <Container className={classes.brandDiv}>
                    <List
                      title="Add Distributor"
                      icon={<AddIcon />}
                      className={classes.brandBtn}
                      onClick={toggleDistDialog}
                    />
                    {distBrands?.map((item, index) => (
                      <Container
                        className={'root-main'}
                        justify={'space-between'}
                        key={index}
                        item={item}
                        index={index}
                        flex
                      >
                        <Container className={classes.brands}>
                          <Container flex gap={'12px'} className={classes.oemBrands}>
                            <ImageFileUpload src={item?.img} alt="brandImg" className={classes.img} />
                            <Container>
                              <Typography variant="body" color={getColor('grey-g25')}>
                                {item?.label} {item?.name}
                              </Typography>
                            </Container>
                          </Container>
                        </Container>
                        <Container className={'delete-icon'}>
                          {isHovering && (
                            <DeleteIcon onClick={() => setDistBrands(distBrands.filter((dlr) => dlr.id !== item.id))} />
                          )}
                        </Container>
                      </Container>
                    ))}
                  </Container>
                )}
              </Container>
            </Container>
          </>
        )}
        {userRole !== 'customer' && (
          <Container className={classes.personalDetailRoot}>
            <>
              <Container className={classes.oemDealerRoot}>
                <Container className={classes.companyDetailsMain}>
                  <Container flex justify="space-between">
                    {/* <TitleList title={'Hours of Operation'} subtitle={'Set operating hours'} /> */}
                    <TitleList title={'Hours of Operation'} height="unset" />
                    <Button
                      variant="secondary"
                      className={classes.ophours}
                      onClick={() => toggleOphours(true)}
                      icon={<CaretDownIcon />}
                    />
                  </Container>
                </Container>

                <Container className={classes.hrsRoot}>
                  {ophours && (
                    <Container display="flex" gap="10px" direction="column" className={classes.hourDiv}>
                      <Container display="flex" alignItems="center" gap="20px" className={classes.hourDiv}>
                        <Container display="flex" alignItems="center">
                          <Typography variant="body1" component={'div'} className={classes.dayDiv}>
                            Monday
                          </Typography>
                          <Container display="flex" alignItems="center" gap="10px">
                            <Controller
                              name={'mon_open_status'}
                              control={control}
                              render={({ field: { name } }) => (
                                <SwitchToggle
                                  name={name}
                                  defaultChecked={!getDisableStatus(name)}
                                  onChange={(e) => {
                                    handleOprationHours(name, e)
                                  }}
                                  id={'in-stock'}
                                />
                              )}
                            />
                            <Typography variant="paragraph1" color={getColor('grey-g25')}>
                              Open
                            </Typography>
                          </Container>
                        </Container>
                        <Container display="flex" alignItems="center" gap="10px">
                          <Controller
                            name={'mon_open_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={'order-time'}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('mon_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                          <Typography variant="paragraph1">To</Typography>
                          <Controller
                            name={'mon_close_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={'order-time'}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('mon_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                        </Container>
                      </Container>
                      <Container display="flex" alignItems="center" gap="20px" className={classes.hourDiv}>
                        <Container display="flex" alignItems="center">
                          <Typography variant="body1" component={'div'} className={classes.dayDiv}>
                            Tuesday
                          </Typography>
                          <Container display="flex" alignItems="center" gap="10px">
                            <Controller
                              name={'tue_open_status'}
                              control={control}
                              render={({ field: { name } }) => (
                                <SwitchToggle
                                  name={name}
                                  defaultChecked={!getDisableStatus(name)}
                                  onChange={(e) => {
                                    handleOprationHours(name, e)
                                  }}
                                  id={'in-stock'}
                                />
                              )}
                            />
                            <Typography variant="paragraph1">Open</Typography>
                          </Container>
                        </Container>
                        <Container display="flex" alignItems="center" gap="10px">
                          <Controller
                            name={'tue_open_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('tue_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                          <Typography variant="paragraph1">To</Typography>
                          <Controller
                            name={'tue_close_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('tue_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                        </Container>
                      </Container>
                      <Container display="flex" alignItems="center" gap="20px" className={classes.hourDiv}>
                        <Container display="flex" alignItems="center">
                          <Typography variant="body1" component={'div'} className={classes.dayDiv}>
                            Wednesday
                          </Typography>
                          <Container display="flex" alignItems="center" gap="10px">
                            <Controller
                              name={'wed_open_status'}
                              control={control}
                              render={({ field: { name } }) => (
                                <SwitchToggle
                                  name={name}
                                  defaultChecked={!getDisableStatus(name)}
                                  onChange={(e) => {
                                    handleOprationHours(name, e)
                                  }}
                                  id={'in-stock'}
                                />
                              )}
                            />
                            <Typography variant="paragraph1">Open</Typography>
                          </Container>
                        </Container>
                        <Container display="flex" alignItems="center" gap="10px">
                          <Controller
                            name={'wed_open_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('wed_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                          <Typography variant="paragraph1">To</Typography>
                          <Controller
                            name={'wed_close_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('wed_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                        </Container>
                      </Container>
                      <Container display="flex" alignItems="center" gap="20px" className={classes.hourDiv}>
                        <Container display="flex" alignItems="center">
                          <Typography variant="body1" component={'div'} className={classes.dayDiv}>
                            Thursday
                          </Typography>
                          <Container display="flex" alignItems="center" gap="10px">
                            <Controller
                              name={'thu_open_status'}
                              control={control}
                              render={({ field: { name } }) => (
                                <SwitchToggle
                                  name={name}
                                  defaultChecked={!getDisableStatus(name)}
                                  onChange={(e) => {
                                    handleOprationHours(name, e)
                                  }}
                                  id={'in-stock'}
                                />
                              )}
                            />
                            <Typography variant="paragraph1">Open</Typography>
                          </Container>
                        </Container>
                        <Container display="flex" alignItems="center" gap="10px">
                          <Controller
                            name={'thu_open_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('thu_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                          <Typography variant="paragraph1">To</Typography>
                          <Controller
                            name={'thu_close_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('thu_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                        </Container>
                      </Container>
                      <Container display="flex" alignItems="center" gap="20px" className={classes.hourDiv}>
                        <Container display="flex" alignItems="center">
                          <Typography variant="body1" component={'div'} className={classes.dayDiv}>
                            Friday
                          </Typography>
                          <Container display="flex" alignItems="center" gap="10px">
                            <Controller
                              name={'fri_open_status'}
                              control={control}
                              render={({ field: { name } }) => (
                                <SwitchToggle
                                  name={name}
                                  defaultChecked={!getDisableStatus(name)}
                                  onChange={(e) => {
                                    handleOprationHours(name, e)
                                  }}
                                  id={'in-stock'}
                                />
                              )}
                            />
                            <Typography variant="paragraph1">Open</Typography>
                          </Container>
                        </Container>
                        <Container display="flex" alignItems="center" gap="10px">
                          <Controller
                            name={'fri_open_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('fri_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                          <Typography variant="paragraph1">To</Typography>
                          <Controller
                            name={'fri_close_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('fri_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                        </Container>
                      </Container>
                      <Container display="flex" alignItems="center" gap="20px" className={classes.hourDiv}>
                        <Container display="flex" alignItems="center">
                          <Typography variant="body1" component={'div'} className={classes.dayDiv}>
                            Saturday
                          </Typography>
                          <Container display="flex" alignItems="center" gap="10px">
                            <Controller
                              name={'sat_open_status'}
                              control={control}
                              render={({ field: { name } }) => (
                                <SwitchToggle
                                  name={name}
                                  defaultChecked={!getDisableStatus(name)}
                                  onChange={(e) => {
                                    handleOprationHours(name, e)
                                  }}
                                  id={'in-stock'}
                                />
                              )}
                            />
                            <Typography variant="paragraph1">Open</Typography>
                          </Container>
                        </Container>
                        <Container display="flex" alignItems="center" gap="10px">
                          <Controller
                            name={'sat_open_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('sat_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                          <Typography variant="paragraph1">To</Typography>
                          <Controller
                            name={'sat_close_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('sat_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                        </Container>
                      </Container>
                      <Container display="flex" alignItems="center" gap="20px" className={classes.hourDiv}>
                        <Container display="flex" alignItems="center">
                          <Typography variant="body1" component={'div'} className={classes.dayDiv}>
                            Sunday
                          </Typography>
                          <Container display="flex" alignItems="center" gap="10px">
                            <Controller
                              name={'sun_open_status'}
                              control={control}
                              render={({ field: { name } }) => (
                                <SwitchToggle
                                  name={name}
                                  defaultChecked={!getDisableStatus(name)}
                                  onChange={(e) => {
                                    handleOprationHours(name, e)
                                  }}
                                  id={'in-stock'}
                                />
                              )}
                            />
                            <Typography variant="paragraph1">Open</Typography>
                          </Container>
                        </Container>
                        <Container display="flex" alignItems="center" gap="10px">
                          <Controller
                            name={'sun_close_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('sun_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                          <Typography variant="paragraph1">To</Typography>
                          <Controller
                            name={'sun_close_time'}
                            control={control}
                            render={({ field: { name, value, ref } }) => (
                              <Select
                                id={name}
                                className={classes.container}
                                options={ophoursOptios}
                                placeholder="Select"
                                value={value || getTimeValue(name)}
                                disabled={getDisableStatus('sun_open_status')}
                                ref={ref}
                                name={name}
                                closeMenuOnScroll={false}
                                searchable={false}
                                onChange={(e) => handleOprationHours(name, e.value)}
                                width={120}
                              />
                            )}
                          />
                        </Container>
                      </Container>
                    </Container>
                  )}
                </Container>
              </Container>
            </>
          </Container>
        )}
        <Container className={classes.personalDetailRoot}>
          <Container className={classes.oemDealerRoot}>
            <Container>
              <Container className={classes.centerMainContainer}>
                <Container className={classes.companyDetailsMain}>
                  <Container flex justify="space-between">
                    <Container justify={'flex-start'} direction={'column'}>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        Social Account
                      </Typography>
                      <Typography variant="paragraph1">Link company social accounts.</Typography>
                    </Container>
                    <Button
                      variant="secondary"
                      className={classes.social}
                      onClick={() => toggleSocial(true)}
                      icon={<CaretDownIcon />}
                    />
                  </Container>
                </Container>
                {social && (
                  <Container flex gap={'20px'}>
                    <Container className={classes.formText} width={'50%'}>
                      <TextField
                        id="linkedin_url"
                        type="text"
                        name={'linkedin_url'}
                        placeholder="www.linkedin.com/parts-clubs-1939473br8"
                        label={'LinkedIn'}
                        className={classes.inputText}
                        {...register('linkedin_url')}
                      />
                      <Typography className={classes.staticText} variant="paragraph1">
                        https://
                      </Typography>
                    </Container>

                    <Container className={classes.formText} width={'50%'}>
                      <TextField
                        id="twitter_url"
                        type="text"
                        name={'twitter_url'}
                        placeholder="www.twitter.com/49fh37bq84750j"
                        label={'Twitter'}
                        {...register('twitter_url')}
                      />
                      <Typography className={classes.staticText} variant="paragraph1">
                        https://
                      </Typography>
                    </Container>
                    <Container className={classes.formText} width={'50%'}>
                      <TextField
                        id="facebook_url"
                        type="text"
                        name={'facebook_url'}
                        placeholder="www.facebook.com/pArTs.clUb.4hry"
                        label={'Facebook'}
                        {...register('facebook_url')}
                      />
                      <Typography className={classes.staticText} variant="paragraph1">
                        https://
                      </Typography>
                    </Container>
                  </Container>
                )}
              </Container>
            </Container>
          </Container>
        </Container>
      </form>
      <OemDialog
        open={openOemDailog}
        onclose={toggleOemDailog}
        setDealerBrands={setDealerBrands}
        dealerBrands={dealerBrands}
      />
      <DistributorDialog
        open={openDistDialog}
        onclose={toggleDistDialog}
        setDistBrands={setDistBrands}
        distBrands={distBrands}
      />
      <AddEquipmentDialog open={openEquipDialog} onClose={toggleEquipDialog} setEquips={setEquips} />
      <AddPartsDialog open={openParts} onClose={toggleParts} equipmentId={equipId} partId={partId} />
    </Container>
  )
}
CompanyProfile.propTypes = {
  specializationPartsData: PropTypes.array,
  setSpecializationsData: PropTypes.func,
  setSpecializationPartsData: PropTypes.func,
}
