import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Container from 'components/common/Container'
import { AuthContext, SignupDataContext } from 'utils/provider/AuthContext'
import VerifyEmail from 'containers/authentication/sign-up/VerifyEmail'
import VerifyCode from 'containers/authentication/sign-up/VerifyCode'
import Seperator from 'components/common/Seperator'
import SignUpAccountSteps from 'containers/authentication/sign-up/SignUpAccountSteps'
import CompanyInfo from 'containers/authentication/sign-up/SignUpCompanyInfo'
import IndustryInfo from 'containers/authentication/sign-up/SignUpIndustryInfo'
import Button from 'components/common/Button'
import Typography from 'components/common/Typography'
import SignUpAccountInfo from 'containers/authentication/sign-up/SignUpAccountInfo'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import SignIn from './sign-in/SignIn'
import SignInHeaderLogo from './sign-in/SignInHeaderLogo'
import SingUpAccoutType from './sign-up/SingUpAccoutType'

const useStyles = makeStyles(() => ({
  bottomLine: {
    color: getColor('grey-g65'),
    textAlign: 'center',
    maxWidth: '372px',
  },
  bottomLineLink: {
    color: getColor('grey-g65'),
    textDecoration: 'underline',
  },
}))

export default function Login() {
  const classes = useStyles()
  const navigation = useNavigate()
  const [authenticationStep, setAuthenticationStep] = useState(1)
  const { authState } = useContext(AuthContext)
  const [btnDisable, setBtnDisable] = useState(true)
  const [signup, setSign] = useState()
  const [userId, setUserId] = useState('')
  const [emailId, setEmail] = useState('')
  const contextValue = {
    onSaveSignupData: setSign,
    signupData: signup,
  }

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const userRole = getFromLocalStorage(constants.ROLE)
      if (userRole === 'super_admin') navigation('/sa')
      else navigation('/quotes')
    }
  }, [navigation, authState?.isAuthenticated])

  return (
    <Container flex width={'100vw'} height={'100vh'}>
      {authenticationStep < 4 && (
        <Container flex direction={'column'} gap={'30px'} height={'auto'}>
          <Container flex direction={'column'}>
            <SignInHeaderLogo />
          </Container>
          <Container width={'300px'}>
            {authenticationStep === 1 && (
              <VerifyEmail
                onStepChange={(step) => {
                  setAuthenticationStep(step)
                }}
                setUserId={setUserId}
                setEmail={setEmail}
              />
            )}
            {authenticationStep === 2 && <SignIn userId={userId} id={emailId} />}
            {authenticationStep === 3 && (
              <VerifyCode
                onStepChange={(step) => {
                  setAuthenticationStep(step)
                }}
              />
            )}
          </Container>

          <Container className={classes.bottomLine}>
            <Typography variant="overline" component="div">
              By clicking above, you acknowledge that you are agree to PartsClub's{' '}
              <Link to="#">
                <Typography variant="overline" className={classes.bottomLineLink}>
                  Terms & Conditions
                </Typography>
              </Link>{' '}
              and{' '}
              <Link to={'#'}>
                <Typography variant="overline" className={classes.bottomLineLink}>
                  Privacy Policy.
                </Typography>
              </Link>
            </Typography>
          </Container>
        </Container>
      )}
      <SignupDataContext.Provider value={contextValue}>
        {authenticationStep > 3 && (
          <Container flex direction={'column'} width={'460px'} height={'700px'} gap={'30px'}>
            <Container flex direction={'column'}>
              <SignUpAccountSteps authenticationStep={authenticationStep} />
            </Container>
            <Seperator />
            <Container width="100%">
              {authenticationStep === 4 && (
                <SignUpAccountInfo
                  onStepChange={(step) => {
                    setAuthenticationStep(step)
                  }}
                  step={authenticationStep}
                  setBtnDisable={setBtnDisable}
                  emailId={emailId}
                />
              )}
              {authenticationStep === 5 && (
                <SingUpAccoutType
                  onStepChange={(step) => {
                    setAuthenticationStep(step)
                  }}
                  step={authenticationStep}
                  setBtnDisable={setBtnDisable}
                  emailId={emailId}
                />
              )}
              {authenticationStep === 6 && (
                <CompanyInfo
                  onStepChange={(step) => {
                    setAuthenticationStep(step)
                  }}
                  step={authenticationStep}
                  setBtnDisable={setBtnDisable}
                  btnDisable={btnDisable}
                />
              )}
              {authenticationStep === 7 && (
                <IndustryInfo
                  onStepChange={(step) => {
                    setAuthenticationStep(step)
                  }}
                  step={authenticationStep}
                  setBtnDisable={setBtnDisable}
                />
              )}
            </Container>
            <Seperator />

            {authenticationStep === 4 && (
              <Container>
                <Button
                  variant={btnDisable ? 'disabled' : 'primary'}
                  type="submit"
                  width="319px"
                  form={'signup-account-from'}
                  disabled={btnDisable}
                >
                  Continue
                </Button>
              </Container>
            )}

            <Container flex width="100%" height="70px" justify="space-between" padding="4.5">
              {authenticationStep === 5 && (
                <>
                  <Button variant="secondary" border onClick={() => setAuthenticationStep(authenticationStep - 1)}>
                    Previous
                  </Button>
                  <Button
                    variant={btnDisable ? 'disabled' : 'primary'}
                    type="submit"
                    form={'singup-account-type'}
                    disabled={btnDisable}
                  >
                    Continue
                  </Button>
                </>
              )}
            </Container>

            <Container flex width="100%" justify="space-evenly">
              {authenticationStep === 6 && (
                <>
                  <Button variant="secondary" border onClick={() => setAuthenticationStep(authenticationStep - 1)}>
                    Previous
                  </Button>
                  <Button
                    variant={btnDisable ? 'disabled' : 'primary'}
                    type="submit"
                    form={'companyInfo-form'}
                    disabled={btnDisable}
                  >
                    Continue
                  </Button>
                </>
              )}

              {authenticationStep === 7 && (
                <>
                  <Button variant="secondary" border onClick={() => setAuthenticationStep(authenticationStep - 1)}>
                    Previous
                  </Button>
                  <Button
                    variant={btnDisable ? 'disabled' : 'primary'}
                    disabled={btnDisable}
                    type="submit"
                    form={'industry-form'}
                  >
                    Get Started
                  </Button>
                </>
              )}
            </Container>
          </Container>
        )}
      </SignupDataContext.Provider>
    </Container>
  )
}
