import Container from 'components/common/Container'
import React from 'react'
import './css/landingpage.css'
import './css/responsive.css'
import Logo from 'assets/icons/Logo50x50.svg'
import Typography from 'components/common/Typography'
import Link from 'components/common/Link'
import { getColor } from 'theme/colors'
import FacebookDark from 'assets/icons/FacebookDark.svg'
import LinkedInDark from 'assets/icons/LinkedInDark.svg'

import CanadaFlage from 'assets/icons/CanadaFlage.svg'
import { LandingPageLogoBig, UsFlag } from 'assets/icons'

const LandingPageFooter = () => (
  <footer className="footer-main">
    <Container className="container">
      <Container className="footer-inner">
        <Container className="footer-grid">
          <Container className="footer-left">
            <img src={Logo} alt="Logo" className="mobile-img" />
            <LandingPageLogoBig className="desktop-img" />
            <Container>
              <Typography variant="title" component={'h3'} color={getColor('light-main')}>
                PartsClub, Inc.
              </Typography>
              <Typography variant="paragraph1" component={'p'} color={getColor('white-main')} className="adddresLine">
                240-1104 2028 E Ben White Blvd. Austin, Texas 78741
              </Typography>
              <Container>
                <Typography variant="paragraph1" component={'p'} color={getColor('white-main')}>
                  sellmoreparts@partsclub.us
                </Typography>
                <Typography variant="paragraph1" component={'p'} color={getColor('white-main')}>
                  737-234-6311
                </Typography>
              </Container>
            </Container>
          </Container>
          <Container className="footer-right">
            <Container className="footer-ul">
              <Link to={'/home'}>
                <Typography color={getColor('light-main')} variant="body1">
                  Home
                </Typography>
              </Link>
              <Link to={'/parts'}>
                <Typography color={getColor('light-main')} variant="body1">
                  Parts
                </Typography>
              </Link>
              <Link to={'/quoterequest'}>
                <Typography color={getColor('light-main')} variant="body1">
                  Quote Request
                </Typography>
              </Link>
              <Link to={'/supplier'}>
                <Typography color={getColor('light-main')} variant="body1">
                  Suppliers
                </Typography>
              </Link>
              <Link to={'/buying'}>
                <Typography color={getColor('light-main')} variant="body1">
                  Buying
                </Typography>
              </Link>
              <Link to={'/selling'}>
                <Typography color={getColor('light-main')} variant="body1">
                  Selling
                </Typography>
              </Link>
              <Link to={'/about'}>
                <Typography color={getColor('light-main')} variant="body1">
                  About Us
                </Typography>
              </Link>
            </Container>
            <Container className="social-media-flex">
              <a href="https://www.linkedin.com/company/partsclub/" target={'_blank'} rel="noreferrer">
                <img src={LinkedInDark} alt={LinkedInDark} />
              </a>
              <a href="https://www.facebook.com/sellmoreparts/" target={'_blank'} rel="noreferrer">
                <img src={FacebookDark} alt={FacebookDark} />
              </a>
            </Container>
          </Container>
        </Container>
        <Container className="copyright-sec" flex justify="space-between">
          <Container>
            <Typography variant="caption" color={getColor('grey-g80')}>
              © 2022 PartsClub, Inc. All rights reserved.
            </Typography>
          </Container>
          <Container flex>
            <Container flex gap="0 4px" className="austinflag">
              <UsFlag />
              <Typography variant="caption" color={getColor('grey-g80')}>
                Austin, TX
              </Typography>
            </Container>
            <Container flex gap="0 4px" className="torontoFlag">
              <img src={CanadaFlage} alt={CanadaFlage} />
              <Typography variant="caption" color={getColor('grey-g80')}>
                Toronto, ON
              </Typography>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  </footer>
)
export default LandingPageFooter
