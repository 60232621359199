import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import React, { useEffect, useState } from 'react'
import { getColor } from 'theme/colors'
import listingStar from 'assets/images/listingStar.svg'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
// import { useNavigate } from 'react-router-dom'
// import { formatPrice } from 'utils/Utils'

const useStyles = makeStyles({
  cardGrid: {
    display: 'flex',
    gap: '20px',
    flexFlow: 'wrap',
  },
  card: {
    width: '340px',
    height: ' auto',
    background: '#FFFFFF',
    border: '1px solid #F0F0F4',
    borderRadius: ' 4px',
    padding: '20px',
    '&:hover': {
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },
  cardInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  priceText: {
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '42px',
    color: '#13141B',
  },
  priceTextSmall: {
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#9799B5',
  },
  pricingFlex: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px 0',
    padding: '20px 0',
  },
  currrentPlanBtn: {
    background: '#F0F0F4!important',
    color: '#9799B5!important',
    width: '100%',
  },
  upgradeBtn: {
    background: '#fff!important',
    color: '#4A4D68!important',
    width: '100%',
    border: '1px solid #9799B5',
  },
  upgradeGreenBtn: {
    background: '#0DA50D!important',
    color: '#fff!important',
    width: '100%',
  },
  listFlex: {
    display: 'flex',
    gap: '0 5px',
    alignItems: 'center',
    color: getColor('grey-g35'),
  },
  listFlexRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
    marginTop: '15px',
  },
  btn: {
    // cursor: 'not-allowed',
    // pointerEvents: 'none',
  },
})

const PlanCard = ({ index, name, price, request, benefit, benefitTitle }) => {
  const classes = useStyles()
  // const navigate = useNavigate()
  const [benefitTitlesArray, setBanefitTitleArray] = useState([])
  const numbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']

  useEffect(() => {
    setBanefitTitleArray(() => {
      const arr = []
      for (let i = 0; i < Object.keys(benefitTitle).length; i += 1) {
        arr.push(
          <Typography key={i} variant="body1" className={classes.listFlex}>
            <Image src={listingStar} />
            {benefitTitle[numbers[i]]}
          </Typography>
        )
      }
      return arr
    })
  }, [benefitTitle])

  return (
    <Container className={classes.card}>
      <Container className={classes.cardInner}>
        <Container className={classes.cardgrp}>
          <Typography variant="title" color={getColor('grey-g25')}>
            {name}
          </Typography>
          <Container className={classes.pricingFlex}>
            <Typography className={classes.priceText}>
              {/* {price} */}${price} <span className={classes.priceTextSmall}>/month</span>
            </Typography>
            <Typography variant="body1" component="p" color={getColor('grey-g35')}>
              {request}
            </Typography>
          </Container>
          {index === 0 && (
            <Button disabled className={classes.currrentPlanBtn}>
              Current Plan
            </Button>
          )}
          {index === 1 && (
            <Button
              className={classes.btn}
              variant="secondary"
              border
              width={'100%'}
              // onClick={() => navigate('/upgradeaccount', { state: { id, type } })}
            >
              Upgrade
            </Button>
          )}
          {index === 2 && (
            <Button
              className={classes.btn}
              variant="positive"
              width={'100%'}
              // onClick={() => navigate('/upgradeaccount', { state: { id, type } })}
            >
              Upgrade
            </Button>
          )}
        </Container>
        <Seperator />
        <Container className={classes.cardgrp}>
          <Typography variant="body1" color={getColor('grey-g35')}>
            {benefit}
          </Typography>
          <Container className={classes.listFlexRoot}>{benefitTitlesArray.map((obj) => obj)}</Container>
        </Container>
      </Container>
    </Container>
  )
}

export default PlanCard

PlanCard.propTypes = {
  // id: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.string,
  request: PropTypes.string,
  benefit: PropTypes.string,
  benefitTitle: PropTypes.object,
  // type: PropTypes.string,
}
