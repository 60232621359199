import React, { Fragment } from 'react'
import partsclublogo from 'assets/images/partsclub-logo.png'
import Typography from 'components/common/Typography'
import Container from 'components/common/Container'
import { getColor } from 'theme/colors'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  loginTitle: {
    paddingTop: '11px',
  },
  loginLogo: {
    marginTop: '5px',
  },
}))

export default function SignInHeaderLogo() {
  const classes = useStyles()
  return (
    <Fragment key="auth-header">
      <img src={partsclublogo} alt="logo" className={classes.loginLogo} />
      <Container flex direction={'column'} className={classes.loginTitle}>
        <Typography variant="h1" color={getColor('grey-g25')}>
          PartsClub
        </Typography>
        <Typography variant="body1" color={getColor('grey-g35')}>
          Heavy-Duty Parts Marketplace
        </Typography>
      </Container>
    </Fragment>
  )
}
