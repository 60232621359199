import { makeStyles } from '@mui/styles'
import { CaretLeftIcon, CaretRightIcon } from 'assets/icons'
import useSupplierList from 'common-hooks/super-admin/useSupplierList'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import LoadingOverlay from 'components/common/LoadingOverlay'
import SearchField from 'components/common/search/SearchField'

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getColor } from 'theme/colors'
// import { supplierListLimit } from 'utils/constants'
import HtmlTitle from 'utils/HtmlTitle'
import SupplierCard from './SupplierCard'
// import { BottomScrollListener } from 'react-bottom-scroll-listener'
// import { debounce } from '@mui/material'

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'space-between',
    padding: '20px',
    paddingBottom: '0',
    background: getColor('light-main'),
  },
  grid: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '20px',
    height: '82%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingBottom: '30px',
  },
  header: {
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '30px',
    position: 'sticky',
    top: '60px',
    zIndex: '1',
    background: getColor('light-main'),
  },
  marginHead: {
    background: getColor('light-main'),
  },
  paginationFooter: {
    position: 'sticky',
    bottom: '0px',
    height: '76px',
    marginLeft: '-20px',
    marginRight: '-20px',
    padding: '0 20px',
    zIndex: '1',
    borderTop: `1px solid ${getColor('grey-g95')}`,
    background: getColor('light-main'),
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
}))

export default function SuppliersList() {
  const classes = useStyles()
  const [suppliers, setSupplirs] = useState([])

  const { supplierList, search, onSearch, page, setPage, isLoading } = useSupplierList()
  const navigate = useNavigate()
  const handleNextClick = () => {
    setPage((pre) => pre + 1)
    sessionStorage.setItem('pageNumber', page)
  }
  const handlePrevClick = () => {
    setPage((pre) => (pre > 1 ? pre - 1 : 1))
    sessionStorage.setItem('pageNumber', page)
  }
  // const handleContainerOnBottom = debounce(() => {
  //   if (hasMore) {
  //     loadMore()
  //   }
  // }, 400)

  useEffect(() => {
    if (supplierList) setSupplirs(supplierList)
  }, [supplierList])

  return (
    <Container className={classes.root} height={'92vh'}>
      <HtmlTitle title={'Suppliers'} />
      {isLoading && <LoadingOverlay />}
      {/* {isLoading ||
        (1 && (
          <Container className={classes.grid}>
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
            <Skeleton count={70} />
          </Container>
        ))} */}
      <Container className={classes.header}>
        <Container flex justify={'flex-end'} className={classes.marginHead}>
          <Container flex gap={'20px'}>
            <Container>
              <SearchField
                id="search-field"
                placeholder={'Search...'}
                width={250}
                onSearch={(e) => {
                  onSearch(e)
                }}
                value={search}
              />
            </Container>
            <Container>
              <Button onClick={() => navigate('/sa/addsupplier')}>Add Supplier</Button>
            </Container>
          </Container>
        </Container>
      </Container>
      {/* <BottomScrollListener onBottom={handleContainerOnBottom}>
        {(scrollRef) => ( */}
      {/* <Container className={classes.grid} containerRef={scrollRef}> */}
      <Container className={classes.grid}>
        {suppliers &&
          suppliers?.map((data, index) => (
            <SupplierCard
              key={index}
              id={data?.id}
              name={data?.name}
              imgheader={data?.imgheader}
              status={data?.status}
              isPremium={data?.is_premium}
              logo={data?.logo}
              countryAbbrv={data?.country_abbrv}
              country={data?.country}
              isVerified={data?.is_verified}
              earlyAdopt={data?.early_adopt}
              city={data?.city}
              stateAbbrv={data?.state_abbrv}
              state={data?.state}
              tagline={data?.tagline}
              email={data?.email}
              phoneNumber={data?.phone_number}
              industries={data?.industries}
              websiteUrl={data?.website_url}
            />
          ))}
      </Container>
      {/*   )}
       </BottomScrollListener> */}

      <Container flex justify={'space-between'} className={classes.paginationFooter}>
        <Button
          icon={<CaretLeftIcon />}
          variant={'transparent'}
          className={classes.prevBtn}
          onClick={handlePrevClick}
          disabled={page === 1}
        >
          Prev.
        </Button>
        <Button
          icon={<CaretRightIcon />}
          variant={'transparent'}
          className={classes.nextBtn}
          onClick={handleNextClick}
          // disabled={supplierList?.length < supplierListLimit}
        >
          Next
        </Button>
      </Container>
    </Container>
  )
}
