import Container from 'components/common/Container'
import { makeStyles } from '@mui/styles'
import Link from 'components/common/Link'
import useToggle from 'common-hooks/useToggle'
import SearchEmpty from 'assets/images/SearchEmpty.png'
// import supplierplaceholder from 'assets/images/supplierplaceholder.png'
import { getColor } from 'theme/colors'
import PreferenceDrawer from 'containers/supplier-quotes/PreferenceDrawer'
import QuoteRequestCard from 'containers/supplier-quotes/QuoteRequestCard'
import useSuppliersReqList from 'common-hooks/buyer-supplier-list/useSuppliersReqList'
import LoadingOverlay from 'components/common/LoadingOverlay'
import HtmlTitle from 'utils/HtmlTitle'
import Button from 'components/common/Button'
import Typography from 'components/common/Typography'
import Image from 'components/common/Image'

// import FiltersDrawer from './FiltersDrawer'
// import PreferenceDrawer from './PreferenceDrawer'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    padding: '30px',
  },
  marginHead: {
    justifyContent: 'flex-end',
    position: 'sticky',
    top: '60px',
    background: getColor('system-bg'),
    zIndex: '1',
  },
  placeholderPosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  list: {
    height: '83%',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  links: {
    '& a': {
      fontWeight: '400',
      color: '#2680D9',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  placeholder: {
    textAlign: 'center',
    '& img': {
      marginBottom: '20px',
    },
  },
}))

const BuyerQuotesRequestList = () => {
  const classes = useStyles()

  const [Preference, setPreference] = useToggle(false)
  const { reqQuoteData, setPreferenceData, isLoadingReq } = useSuppliersReqList()
  return (
    <Container height={'92.9vh'}>
      <HtmlTitle title={'Request Board'} />
      <Container className={classes.mainContainer} width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            <Container flex gap={'20px'}>
              <Container className={classes.links}>
                <Link
                  to="#"
                  underline
                  onClick={() => {
                    setPreference(true)
                  }}
                >
                  Quote Preferences
                </Link>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={classes.list}>
        {reqQuoteData?.length === 0 && (
          <Container flex>
            {/* <img src={supplierplaceholder} alt="LET'S TURBOCHARGE YOUR PARTS SALES" width={'314px'} height={'275px'} /> */}
            <Container className={classes.placeholder}>
              <Container>
                <Image src={SearchEmpty} />
              </Container>
              <Container flex direction="column" gap="20px 0">
                <Container flex direction="column">
                  <Typography variant="title" color={getColor('grey-g25')} component="p">
                    No quote requests
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                    Change your preferences to receive quote requests.
                  </Typography>
                </Container>
                <Button
                  onClick={() => {
                    setPreference(true)
                  }}
                >
                  Set preferences
                </Button>
              </Container>
            </Container>
          </Container>
        )}
        <Container height={'100%'}>
          {isLoadingReq && <LoadingOverlay />}
          {reqQuoteData?.map((obj, index) => (
            <QuoteRequestCard
              key={index}
              partName={obj?.part_name}
              createdAt={obj?.created_at}
              quoteId={obj?.quote_id}
              brandName={obj?.brand_name}
              partNumber={obj?.quote_part_number}
              equipName={obj?.equip_name}
              city={obj?.cust_addr_city}
              state={obj?.cust_addr_state}
              country={obj?.cust_addr_country}
              supplierName={obj?.buyer_full_name}
              brandImg={obj?.brand_cover_image}
              equpImg={obj?.equip_cover_image}
              brandModel={obj?.quote_brand_model_number}
              brandSerial={obj?.quote_brand_serial_number}
              inviteId={obj?.id}
              companyName={obj?.company_name}
              type={obj?.type}
              isCompany={obj?.company_is_company}
            />
          ))}
        </Container>
      </Container>

      <PreferenceDrawer open={Preference} onclose={setPreference} setPreferenceData={setPreferenceData} />
    </Container>
  )
}

export default BuyerQuotesRequestList
