import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import SearchField from 'components/common/search/SearchField'
import SearchCard from './SearchCard'
import { filterOption } from './SearchUtils'
import { Typography } from '@mui/material'
import Select from 'components/common/select/Select'
import SearchEmpty from 'assets/images/SearchEmpty.png'
import { getColor } from 'theme/colors'
import Image from 'components/common/Image'
import Button from 'components/common/Button'
import useProductSearchList from 'common-hooks/search-product/useProductSearchList'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import { CaretLeftIcon, CaretRightIcon } from 'assets/icons'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { useState } from 'react'
import CreateQuoteDialog from 'containers/buyer-quotes/CreateQuoteDialog'
import { BuyerQuoteContext } from 'containers/buyer-quotes/BuyerQuotesUtils'
import useToggle from 'common-hooks/useToggle'

const useStyles = makeStyles({
  SearchField: {
    width: '908px',
    margin: '0 auto',
    padding: '25px 0',
    '& input': {
      height: '50px',
      backgroundColor: '#fff',
    },
  },
  searchResult: {
    maxWidth: '908px',
    margin: '0 auto',
    width: '100%',
    '& p': {
      width: '70px',
    },
    marginBottom: '25px',
  },
  productLisitinGrid: {
    // height: '77vh',
    height: '77vh',
    // padding: '0 5px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // overflowX: 'hidden',
    // margin: '0 5px',
    // '&::-webkit-scrollbar': {
    //   width: '6px',
    //   paddingtop: '50px',
    //   backgroundColor: '#fff',
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   width: '4px',
    //   borderRadius: ' 100px',
    //   background: '#C3C5D5',
    // },
  },
  placeholder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center ',
    gap: '20px 0',
    height: '77vh',
    position: 'absolute',
    top: '200px',
    bottom: '0',
    left: '0',
    right: '0',
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  btnDiv: {
    padding: '0 0px 30px 0px',
  },
})

export default function SearchListing() {
  // const { search, onSearch } = useSearch()
  const userType = getFromLocalStorage(constants.ROLE)

  const [filterValue, setFilterValue] = useState('')
  const { searchList, search, onSearch, page, setPage, isLoading } = useProductSearchList(userType, filterValue)
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useToggle(false)

  const [selectedParts, setSelectedParts] = useState([])
  const [quoteData, setQuoteData] = useState([])
  const [formStep, setFormStep] = useState(0)

  const contextValue = {
    onSaveBuyerQuoteInformaton: setQuoteData,
    BuyerQueoteInformation: quoteData,
    visibleTextBoxData: selectedParts,
    setTextBoxVisibilityData: setSelectedParts,
  }

  const handleChange = (e) => {
    setFilterValue(e?.value)
  }

  // const partId = JSON.parse(getFromLocalStorage(constants.UNAUTHORIZEDPARTID))

  // useEffect(() => {
  //   if (partId) {
  //     setFilterObject(searchList?.filter((obj) => obj.id === partId))
  //   }
  // }, [partId])

  const activeProduct = searchList && searchList?.filter((obj) => obj?.status === 'active')

  return (
    <Container flex>
      <Container width={'908px'} direction="column" display="flex">
        <Container className={classes.SearchField}>
          <SearchField
            placeholder="Search by part number, brand, description"
            onSearch={(e) => {
              onSearch(e)
            }}
            value={search}
          />
        </Container>
        {isLoading && <LoadingOverlay />}
        {search && (
          <Container className={classes.searchResult}>
            <Container flex justify="space-between">
              <Typography variant="title" color={getColor('grey-g35')}>
                Search Results for “{search}”
              </Typography>
              <Container flex gap={'0 10px'} width="241px">
                <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                  Sort by:
                </Typography>
                <Select
                  options={filterOption}
                  defaultValue={{ value: 'Relevance', label: 'Relevance' }}
                  id={'Options'}
                  name={'Options'}
                  onChange={handleChange}
                />
              </Container>
            </Container>
          </Container>
        )}

        <Container className={classes.productLisitinGrid}>
          {activeProduct?.map((obj, index) => (
            <SearchCard
              key={index}
              id={obj.id}
              brandName={obj.brand_name}
              partName={obj.part_desp}
              partNumber={obj.part_no}
              condition={obj.condition}
              companyName={obj.company_name}
              city={obj.company_city}
              state={obj.company_state}
              amout={obj.unit_price}
              qty={obj.qty}
              stock={obj.stock}
              brandImage={obj.cover_image}
              obj={obj}
              country={obj?.company_country}
              weight={obj?.weight}
            />
          ))}
          {activeProduct?.length === 0 && (
            <>
              {' '}
              <Container className={classes.placeholder}>
                <Container>
                  <Image src={SearchEmpty} />
                </Container>
                <Container flex direction="column" gap="20px 0">
                  <Container flex direction="column">
                    <Typography variant="title" color={getColor('grey-g25')} component="p">
                      No products found
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                      Start a quote request to get quotes from suppliers
                    </Typography>
                  </Container>
                  <Button onClick={setOpenDialog}>Request Quote</Button>
                </Container>
              </Container>
            </>
          )}
        </Container>
        <Container display="flex" justify="space-between" width={'100%'} className={classes.btnDiv}>
          <Button
            icon={<CaretLeftIcon />}
            variant={'transparent'}
            className={classes.prevBtn}
            onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
            disabled={page === 1}
          >
            Prev.
          </Button>
          <Button
            icon={<CaretRightIcon />}
            variant={'transparent'}
            className={classes.nextBtn}
            onClick={() => setPage((pre) => pre + 1)}
            disabled={searchList?.length !== 14}
          >
            Next
          </Button>
        </Container>
      </Container>
      <BuyerQuoteContext.Provider value={contextValue}>
        <CreateQuoteDialog open={openDialog} onClose={setOpenDialog} setFormStep={setFormStep} formStep={formStep} />
      </BuyerQuoteContext.Provider>
    </Container>
  )
}
