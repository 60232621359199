import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import { getColor } from 'theme/colors'
import Chip from 'components/common/Chip'
import Button from 'components/common/Button'
import UserProfileFillIcon from 'assets/icons/UserIcon.svg'
import Placeholder from 'assets/images/Placeholder.png'
import classNames from 'classnames'
import Typography from 'components/common/Typography'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import { useNavigate } from 'react-router-dom'
import { getFlag } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '166px',
    padding: '20px',
    backgroundColor: '#fff',
    border: '1px solid #F0F0F4',
    marginBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },
  mainFlex: {
    display: 'flex',
  },
  cardLeft: {
    width: '80px',
    height: '80px',
    '& img': {
      width: '80px',
      ObjectFit: 'cover',
      height: '100%',
      border: '1px solid #F0F0F4 ',
    },
  },
  cardRight: {
    width: '93%',
  },
  oldPrice: {
    fontWeight: '300',
    fontSize: '1.25rem',
    color: getColor('grey-g65'),
  },
  price: {
    fontWeight: '600',
    fontSize: '20px',
    color: getColor('dark-main'),
  },
  title: {
    color: getColor('dark-main'),
  },
  paragraphColor: {
    color: getColor('grey-g35'),
  },
  stockColor: {
    color: getColor('func-positive'),
  },
  shipColor: {
    color: getColor('func-info'),
  },
  partnumberColor: {
    color: getColor('grey-g65'),
  },
  buynowBg: {
    backgroundColor: getColor('func-positive'),
  },

  progress: {
    fontWeight: '600',
    fontSize: '20px',
    lineheight: ' 30px',
    color: getColor('grey-g35'),
  },
  nudgeBtn: {
    color: getColor('grey-g35'),
  },
  pb5: {
    paddingTop: '5px',
    paddingBottom: '8px',
  },
  pb10: {
    paddingBottom: '10px',
    alignItems: 'center',
  },
  titleAlign: {
    alignItems: 'center',
  },
  flag: {
    display: 'flex',
    alignItems: 'center',
  },
  adChip: {
    width: '28px',
    height: '18px',
    borderRadius: '2px',
    backgroundColor: '#F0F0F4',
    '& span': {
      padding: '0',
      fontWeight: '500',
      fontSize: '12px',
      height: '100%',
      lineHeight: ' 14px',
      color: getColor('grey-g65'),
    },
  },
  premimumChip: {
    height: '20px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    coolor: '#13141B',
    borderRadius: '100px',
  },
  companyNameTitle: {
    fontWeight: ' 500',
    fontSize: ' 16px',
    lineheight: ' 24px',
    color: ' #13141B',
  },
}))

export default function InvitedCard({
  img,
  brandName,
  companyName,
  status,
  city,
  state,
  equipName,
  type,
  partName,
  nudgeMail,
  id,
  date,
  companyid,
  premium,
  country,
}) {
  const classes = useStyles()
  // const location = useLocation()
  // const headLinks = location.pathname.split('/')
  const navigate = useNavigate()
  // const { isLoading, invitedDataList } = useInvitedSuppliers(headLinks[4])
  const handleClick = () => {
    nudgeMail(id)
  }
  return (
    // <Container>
    //   {!invitedDataList ? (
    //     <>{isLoading && <LoadingOverlay />}</>
    //   ) : (
    //     <>
    <Container className={classes.root}>
      <Container className={classNames(classes.mainFlex, classes.pb10)} gap={'0 10px'}>
        <Container className={classes.cardLeft}>
          <img src={img !== null ? img : Placeholder} alt="placehodler" />
        </Container>
        <Container className={classes.cardRight}>
          <Container className={classNames(classes.mainFlex, classes.titleAlign)} justify={'space-between'}>
            <Container className={classNames(classes.mainFlex, classes.titleAlign)} gap={'0 5px'}>
              <Container
                variant="title"
                color={'#13141B'}
                className={classes.companyNameTitle}
                onClick={() => navigate(`/suppliers/${companyName}/${companyid}`)}
              >
                {/* AAM Parts & Equipment */}
                {companyName}
              </Container>
              {premium && <Chip className={classes.premimumChip} label={'premium'} />}
            </Container>
            <Container className={classes.mainFlex} gap={'0 5px'}>
              <Typography className={classes.progress}>{status === 'in_progress' && 'In-Progress'}</Typography>
            </Container>
          </Container>
          <Container className={classNames(classes.mainFlex, classes.pb5)} justify={'space-between'}>
            <Container className={classNames(classes.mainFlex, classes.titleAlign)} gap={'0 5px'}>
              <Container display="flex" alignItems="center" gap="5px">
                <Typography variant="paragraph1" className={classes.paragraphColor}>
                  {city}
                  {state && `, ${state}`}
                </Typography>
                {getFlag(country)}
              </Container>
            </Container>
          </Container>
          <Container className={classes.mainFlex} justify={'space-between'}>
            <Typography variant="paragraph1" className={classes.paragraphColor}>
              {/* {partName && (
                <>
                  {partName} <span>•</span>
                </>
              )}{' '}
              {brandName} <span>•</span> {equipName} */}
              {brandName} • {equipName} • {partName}
            </Typography>
          </Container>
        </Container>
      </Container>
      <Container className={classes.mainFlex} justify={'space-between'}>
        <Container flex>
          <img src={UserProfileFillIcon} alt="usericon" />
          <Typography variant="body2" className={classes.partnumberColor}>
            {type === 'direct' ? (
              <>Matched by PartsClub Admin - {formatDate(date, DATE_FORMAT_PRIMARY)}</>
            ) : (
              <>Auto-Matched - {formatDate(date, DATE_FORMAT_PRIMARY)}</>
            )}
          </Typography>
        </Container>
        <Container className={classes.mainFlex} gap={'0 10px'}>
          <Button variant="secondary" className={classes.nudgeBtn} border width={85} onClick={handleClick}>
            Nudge
          </Button>
        </Container>
      </Container>
    </Container>
    //     </>
    //   )}
    // </Container>
  )
}

InvitedCard.propTypes = {
  img: PropTypes.string,
  brandName: PropTypes.string,
  status: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  equipName: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.string,
  partName: PropTypes.string,
  nudgeMail: PropTypes.func,
  id: PropTypes.string,
  premium: PropTypes.bool,
  companyName: PropTypes.string,
  companyid: PropTypes.string,
  country: PropTypes.string,
}
