import { makeStyles } from '@mui/styles'
import { AddIcon, Done } from 'assets/icons'
import useProfileSettingDetail from 'common-hooks/settings/profile/useProfileSettingDetail'
import useShippingAddressList from 'common-hooks/settings/shipping/useShippingAddressList'
import useShippingSettings from 'common-hooks/settings/shipping/useShippingSettings'
import useToggle from 'common-hooks/useToggle'
// import Button from 'components/common/Button'
import Container from 'components/common/Container'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Typography from 'components/common/Typography'
import List from 'components/custom/list/List'
import TitleList from 'components/custom/list/TitleList'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getColor } from 'theme/colors'
import { getWellKnownAddress } from 'utils/Utils'
import ShippingAddressDialog from './ShippingAdressDialog'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  headerDiv: {
    // padding: '30px',
    padding: '30px 0',
    width: '830px',
    margin: '0 auto',
  },
  iconButton: {
    padding: '0',
    minHeight: 'unset',
    height: '30px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  title: {
    marginTop: '20px',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    flexWrap: 'wrap',
  },
  shippingDiv: {
    border: `1px solid ${getColor('grey-g95')}`,
    padding: '20px',
    width: '263.33px',
    background: '#fff',
    marginTop: '30px',
    color: getColor('grey-g35'),
  },
  linkText: {
    textDecoration: 'underline',
    color: getColor('func-info'),
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleColor: {
    color: '#36384C !important',
  },
  removeLink: {
    textDecoration: 'underline',
    color: getColor('func-alert'),
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  default: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    color: getColor('func-positive'),
  },
  actions: {
    paddingTop: '5px',
  },
  space: {
    padding: '5px 0px',
  },
  addressSpacing: {
    padding: '10px 0 5px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    height: '130px',
  },
  profileroot: {
    width: '100%',
    display: 'flex',
    background: '#fff',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabs: {
    width: '260px',
    padding: '20px 10px 20px 10px',
    borderRight: `1px solid ${getColor('grey-g95')}`,
  },
  tabList: {
    padding: '10px 20px 10px 20px',
    color: '#36384c',
  },
  containers: {
    background: '#F8F9F9',
    // width: '830px',
    // margin: '0 auto',
    height: '94vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  active: {
    color: getColor('func-info'),
    padding: '10px 20px 10px 20px',
  },
  addCard: {
    width: '263.33px',
    height: '240px',
    border: ' 1px dashed #C3C5D5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: ' 30px',
  },
  noLink: {
    padding: '10px 20px',
  },
}))
export default function ShippingSettings() {
  const classes = useStyles()
  const { deleteAddress, setDefaultAddress } = useShippingSettings()
  const { profileData } = useProfileSettingDetail()
  const { addressList, isLoading } = useShippingAddressList()

  const navigate = useNavigate()
  const [openDialog, toggleDialog] = useToggle(false)
  const [editAddressData, setEditAddress] = useState()

  const getAddress = (add) => getWellKnownAddress(add?.addr_line_1, add?.addr_line_2)

  return (
    <>
      <Container className={classes.profileroot}>
        <Container className={classes.tabs}>
          <List
            title={'My Profile'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/profile')
            }}
          />
          {profileData?.user_company ? (
            <List
              title={'Company Profile'}
              className={classes.tabList}
              type={'link'}
              onClick={() => navigate('/settings/company')}
            />
          ) : (
            <Container className={classes.noLink}>
              <Typography color="#9799B5">Company Profile</Typography>
            </Container>
          )}
          <List
            title={'Payment'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/payment')
            }}
          />
          <List
            title={'Shipping'}
            className={classes.active}
            type={'link'}
            onClick={() => {
              navigate('/settings/shipping')
            }}
          />
          <List
            title={'Membership'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/membership')
            }}
          />
          <List
            title={'Password'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/security')
            }}
          />
        </Container>
        <Container width="100%" className={classes.containers}>
          <Container className={classes.root}>
            <Container flex justify="space-between" width="100%" className={classes.headerDiv}>
              <Container width="100%">
                <Typography variant="h1" color={getColor('grey-g25')}>
                  Shipping
                </Typography>
                <Container className={classes.title} display={'flex'} direction={'column'} gap={'20px'}>
                  <Container flex justify="space-between">
                    <Container>
                      <TitleList title={'Shipping Address'} subtitle={'Set your shipping address'} height={'40px'} />
                    </Container>

                    {/* <Button
                      variant="secondary"
                      icon={<AddIcon />}
                      className={classes.iconButton}
                      onClick={() => {
                        setEditAddress(undefined)
                        toggleDialog()
                      }}
                    /> */}
                  </Container>
                </Container>
                {isLoading && (
                  <Container flex height="520px">
                    <LoadingOverlay />
                  </Container>
                )}
                <Container className={classes.body}>
                  <Container className={classes.addCard}>
                    <Container
                      flex
                      width={'100%'}
                      height={'100%'}
                      direction="column"
                      onClick={() => {
                        setEditAddress(undefined)
                        toggleDialog()
                      }}
                    >
                      <AddIcon />
                      <Typography variant="body1" color={getColor('grey-g35')}>
                        Add Address
                      </Typography>
                    </Container>
                  </Container>
                  {addressList?.length > 0 &&
                    addressList?.map((address) => (
                      <Container display="flex" direction="column" className={classes.shippingDiv} key={address?.id}>
                        <Typography variant="title" className={classes.titleColor}>
                          {address?.full_name}
                        </Typography>
                        <Typography variant="paragraph1">
                          {address?.type === 'residence' ? 'Home' : 'Business'}
                        </Typography>
                        <Container className={classes.addressSpacing}>
                          <Typography variant="paragraph1">{getAddress(address)}</Typography>
                          <Typography variant="paragraph1">{`E: ${address?.email}`}</Typography>
                          <Typography
                            variant="paragraph1"
                            // className={classes.space}
                          >{`P:${address?.phone}`}</Typography>
                        </Container>

                        <Container display="flex" justify="space-between" className={classes.actions}>
                          <Container display="flex" gap="5px" alignItems="center">
                            <Container
                              className={classes.linkText}
                              onClick={() => {
                                setEditAddress(address)
                                toggleDialog()
                              }}
                            >
                              Edit
                            </Container>
                            {addressList?.length > 1 && (
                              <>
                                •
                                <Container
                                  className={classes.removeLink}
                                  onClick={() => {
                                    deleteAddress({ data: { id: address?.id } })
                                  }}
                                >
                                  Remove
                                </Container>
                              </>
                            )}
                          </Container>
                          <Container>
                            {address?.is_default ? (
                              <Typography variant="paragraph1" className={classes.default}>
                                <Done /> Default
                              </Typography>
                            ) : (
                              <Container
                                className={classes.linkText}
                                onClick={() => setDefaultAddress({ id: address?.id })}
                              >
                                Set as default
                              </Container>
                            )}
                          </Container>
                        </Container>
                      </Container>
                    ))}
                </Container>
              </Container>
            </Container>
            <ShippingAddressDialog
              open={openDialog}
              onClose={toggleDialog}
              editAddressData={editAddressData}
              setEditAddress={setEditAddress}
            />
          </Container>
        </Container>
      </Container>
    </>
  )
}
