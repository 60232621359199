import { AppBar as MuiAppBar, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useLocation, useParams } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  toolbar: {
    paddingLeft: (props) => getPaddingLeft(props.drawerOpen, props?.padding),
    paddingRight: 0,
    minHeight: 'unset',
    height: '60px',
  },
  root: {
    padding: '0 30px',
    borderBottom: ' 1px solid #F0F0F4',
    boxShadow: 'unset',
    backgroundColor: '#fff',
  },
}))
export default function Bar({ children, className, toolbarClassName, drawerOpen }) {
  const params = useParams()
  const location = useLocation()
  let padding = false
  if (params?.reqId) {
    padding = true
  }
  if (location?.pathname.includes('offers/active')) {
    padding = true
  }
  const classes = useStyles({ drawerOpen, padding })
  return (
    <MuiAppBar color="secondary" className={classNames(classes.root, className)}>
      <Toolbar justify="space-between" className={classNames(classes.toolbar, toolbarClassName)}>
        {children}
      </Toolbar>
    </MuiAppBar>
  )
}

Bar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  toolbarClassName: PropTypes.string,
  drawerOpen: PropTypes.bool,
}
function getPaddingLeft(drawerOpen, padding) {
  if (padding) {
    return 0
  }
  return `calc(${drawerOpen ? 228 : 60}px)`
}
