import Container from 'components/common/Container'
import React from 'react'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Typography from 'components/common/Typography'
import useToggle from 'common-hooks/useToggle'
import Button from 'components/common/Button'
import useDetailQuoteList from 'common-hooks/buyer/quotes-details/useDetailQuoteList'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import SearchEmpty from 'assets/images/SearchEmpty.png'
import useInvitedSuppliers from 'common-hooks/buyer/quotes-details/useInvitedSuppliers'
import LoadingOverlay from 'components/common/LoadingOverlay'
import useCantParticipateList from 'common-hooks/buyer/quotes-details/useCantParticipateList'
import BuyerQuoteCard from './BuyerQuoteCard'
import InvitedCard from './InvitedCard'
import PropTypes from 'prop-types'
import CantParticipantCard from './CantParticipantCard'
import InviteMoreSuppliersDialog from './InviteMoreSuppliersDialog'
import useInviteSuppliers from 'common-hooks/buyer/quotes-details/useInviteSuppliers'
import useOtherSuppliers from 'common-hooks/buyer/quotes-details/useOtherSuppliers'
import OtherSupplierCard from './OtherSupplierCard'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '1200px',

    margin: '0 auto',
    padding: '0 50px',
    paddingTop: '20px',
    // paddingBottom: '20px',
    // height: '92.9vh',
    // overflowY: 'scroll',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  mainRoot: {
    position: 'relative',
    width: '79%',
    height: '93.2vh',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: '600',
    lineHeight: '30px',
    color: getColor('grey-g25'),
  },
  ParticipantColor: {
    color: getColor('grey-g25'),
  },
  divFlex: {
    display: 'flex',
  },
  tabDiv: {
    position: 'absolute',
    maxWidth: '1100px',
    width: '100%',
    height: '80px',
    zIndex: '999',
    top: '140px',
    backgroundColor: getColor('system-bg'),
  },
  invitedButton: {
    height: '36px',
    backgroundColor: 'transparent',
  },
  pb10: {
    paddingBottom: '10px',
  },
  cantParticipant: {
    flexFlow: 'wrap',
  },
  color2: {
    color: getColor('grey-g35'),
  },
  alignItem: {
    textAlign: 'center',
  },
  noSuppliers: {
    height: '372px',
    backgroundColor: '#fff',
    border: '1px solid #F0F0F4',
    borderRadius: '4px',
    marginBottom: '20px',
    marginTop: '-10px',
  },

  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '47%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
  invitedCard: {
    // marginTop: '28px',
    // height: '92.9vh',
    // overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
      // paddingtop: '50px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
  },
  div: {
    marginBottom: '20px',
  },
  rootMainscroll: {
    height: '83vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  quoteList: {
    // paddingTop: '58px',
    marginBottom: '28px',
  },
  iconWithLabel: {
    display: 'flex',
    gap: '0 5px',
    alignItems: 'center',
    '& p': {
      margin: '0',
    },
  },
  number: {
    width: '20px',
    height: '16px',
    borderRadius: '100px',
    background: '#2680D9',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
  otherSupplier: {
    marginBottom: '20px',
    marginTop: '10px',
  },
  messageRoot: {
    position: 'absolute',
    bottom: '0',
    right: '0',

    '& #panel2a-header': {
      padding: '15px',
    },
    '& div': {
      margin: '0',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
    },
  },
  chatsRoot: {
    display: 'flex',
    borderTop: '1px solid #F0F0F4',
    borderBottom: '1px solid #F0F0F4',
    cursor: 'pointer',
    gap: '0 10px',
    alignItems: 'center',
    padding: '15px',
    '& img': {
      width: '40px',
      height: '40px',
      border: '1px solid #F0F0F4',
      borderRadius: '100px',
    },
  },
  MessageBox: { width: '260px' },
  count: {
    width: '18px',
    height: '18px',
    background: ' #2680D9',
    borderRadius: '100px ',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
  },
  chatsName: {
    display: ' inline-block',
    width: '120px',
    whiteSpace: ' nowrap',
    overflow: ' hidden !important',
    textOverflow: ' ellipsis',
    color: '#36384C',
  },
  chatsNameActive: {
    display: ' inline-block',
    width: '120px',
    whiteSpace: ' nowrap',
    overflow: ' hidden !important',
    textOverflow: ' ellipsis',
    color: '#2680D9',
  },
  chatsMessage: {
    display: ' inline-block',
    width: '140px',
    whiteSpace: ' nowrap',
    overflow: ' hidden !important',
    textOverflow: ' ellipsis',
    color: '#36384C',
  },
  chatTitleRoot: {
    display: 'flex',
    gap: '0 5px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  messageFlex: {
    display: 'flex',
    gap: '0 20px',
    alignItems: 'flex-end',
    height: 'unset',
  },
  MessageInnerChat: {
    width: '400px',
    boxShadow: ' 0px 20px 20px rgba(21, 22, 30, 0.08), 0px 8px 8px -4px rgba(21, 22, 30, 0.02)',
    '& .chatTitleRoot': {
      display: 'none',
    },
    '& #panel2a-header1[aria-expanded="true"] .chatTitleRoot': {
      display: 'block',
    },
    '& #panel2a-header1': {
      padding: '10px',
    },
    '& .chatsName': {
      display: ' inline-block',
      width: '140px',
      whiteSpace: ' nowrap',
      overflow: ' hidden !important',
      textOverflow: ' ellipsis',
      color: '#36384C',
    },
    '& #panel2a-header1[aria-expanded="false"] .chatsName': {
      width: 'unset',
    },
  },
  chatsInnerChatRoot: {
    display: 'flex',
    // borderBottom: '1px solid #F0F0F4',
    alignItem: 'center',
    cursor: 'pointer',
    gap: '0 10px',
    alignItems: 'center',
    // padding: '15px',
    '& img': {
      width: '40px',
      height: '40px',
      border: '1px solid #F0F0F4',
      borderRadius: '100px',
    },
  },
  iconBtn: {
    padding: '0',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  userChatsSentDesign: {
    background: ' #FADABD',
    borderRadius: ' 4px 0px 4px 4px',
    width: '230px',
    padding: '10px',
    marginLeft: 'auto!important',
    marginRight: '10px!important',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      // backgroundImage: `url(${senderTail})`,
      width: '4px',
      height: '5px',
      backgroundSize: 'cover',
      right: '-4px',
      top: '0',
      zIndex: '99',
    },
  },
  userChats: {
    paddingTop: '10px',
    borderTop: '1px solid #F0F0F4',
  },
  time: {
    fontSize: '9px',
    color: ' #7D809B',
    float: 'right',
    paddingRight: '10px',
  },
  userChatsReciveDesign: {
    background: ' #F0F0F4',
    borderRadius: '0px 4px 4px 4px',
    width: '230px',
    padding: '10px',
    marginLeft: '10px!important',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      // backgroundImage: `url(${reciverTail})`,
      width: '4px',
      height: '5px',
      backgroundSize: 'cover',
      left: '-4px',
      top: '0',
      zIndex: '99',
    },
  },
  time2: {
    fontSize: '9px',
    color: ' #7D809B',

    paddingLeft: '10px',
  },
  userChatsInner: {
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
  },
  sendBtn: {
    '& span': {
      display: 'flex',
    },
  },
  chatBtn: {
    padding: '10px',
    display: 'flex',
    gap: '0 10px',
    borderTop: '1px solid #F0F0F4',
  },
}))

const BuyerQuoteList = ({ status }) => {
  const classes = useStyles()
  const params = useParams()
  // const [tab, setTab] = useState(0)

  const { invitedDataList } = useInvitedSuppliers(params.id)
  const { otherSuppliersList } = useOtherSuppliers(params?.id)
  const { detailQuoteList, isLoading, mutate } = useDetailQuoteList(params.id)
  const { cantparticipateList, nudgeMail } = useCantParticipateList(params.id)
  const [openInviteMoreDialog, toggleInviteMoreDialog] = useToggle(false)
  const { data } = useInviteSuppliers()
  const otherSupplier = []
  data?.map((obj) => {
    if (!obj?.quote_req) {
      otherSupplier.push(obj)
    }
    return 0
  })

  return (
    <>
      <Container className={classes.mainRoot}>
        <Container className={classes.root}>
          {isLoading && <LoadingOverlay />}
          <Container className={classes.rootMainscroll}>
            {detailQuoteList?.length > 0 && (
              <Container className={classes.quoteList}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  Quotes
                </Typography>

                <>
                  {detailQuoteList?.map((obj, index) => (
                    <BuyerQuoteCard
                      key={index}
                      logo={obj?.company_logo}
                      name={obj?.company_name}
                      amount={obj?.amount}
                      city={obj?.supplier_addr_city}
                      state={obj?.supplier_addr_state_abbrv}
                      country={obj?.supplier_addr_country}
                      instock={obj?.in_stock}
                      eqpName={obj?.equip_name}
                      brandName={obj?.brand_name}
                      partName={obj?.part_name}
                      shipping={obj?.ships_in}
                      email={obj?.company_email}
                      condition={obj?.condition}
                      premium={obj?.is_premium}
                      partNumber={obj?.part_number}
                      weight={obj?.weight}
                      quntity={obj?.available_qty}
                      img={obj?.company_logo}
                      quoteReqId={obj?.id}
                      addrline1={obj?.supplier_addr_line_1}
                      addrline2={obj?.supplier_addr_line_2}
                      companyname={obj?.company_name}
                      supplierId={obj?.company_id}
                      listMutate={mutate}
                    />
                  ))}
                </>
              </Container>
            )}

            <Container className={classes.invitedCard}>
              <Container flex justify="space-between" className={classes.div}>
                <Typography variant="title" color={getColor('grey-g25')} component="p">
                  Invited Suppliers
                </Typography>
                <Button variant="secondary" border className={classes.invitedButton} onClick={toggleInviteMoreDialog}>
                  Invite Suppliers
                </Button>
              </Container>
              {invitedDataList?.length > 0 && (
                <>
                  {invitedDataList?.map((obj, index) => (
                    <InvitedCard
                      key={index}
                      img={obj?.company_logo}
                      id={obj?.id}
                      brandName={obj?.brand_name}
                      status={obj?.status}
                      city={obj?.supplier_addr_city}
                      state={obj?.supplier_addr_state}
                      equipName={obj?.equip_name}
                      type={obj?.type}
                      companyName={obj?.company_name}
                      date={obj?.created_at}
                      partName={obj?.part_name}
                      premium={obj?.is_premium}
                      nudgeMail={nudgeMail}
                      companyid={obj?.company_id}
                      country={obj?.supplier_addr_country}
                    />
                  ))}
                </>
              )}
            </Container>

            {invitedDataList?.length === 0 && (
              <Container>
                <Container gap={'20px 0'} flex direction={'column'} className={classes.noSuppliers}>
                  <img src={SearchEmpty} alt="noSupplier" />
                  <Container className={classes.alignItem}>
                    <Typography component="h3" variant="title" className={classes.ParticipantColor}>
                      No Active Suppliers
                    </Typography>
                    <Typography component="p" variant="paragraph1" className={classes.color2}>
                      You haven’t invited any suppliers yet
                    </Typography>
                  </Container>
                  <Button onClick={toggleInviteMoreDialog}>Invite More Suppliers</Button>
                </Container>
              </Container>
            )}

            {cantparticipateList?.length > 0 && (
              <>
                <Typography component="p" variant="h3" className={classNames(classes.pb10, classes.ParticipantColor)}>
                  Can’t Participate
                </Typography>
                <Container className={classNames(classes.divFlex, classes.cantParticipant)} gap={'0 20px'}>
                  {cantparticipateList?.map((obj, index) => (
                    <CantParticipantCard
                      key={index}
                      name={obj?.company_name}
                      city={obj?.supplier_addr_city}
                      state={obj?.supplier_addr_state_abbrv}
                      country={obj?.supplier_addr_country}
                      img={obj?.company_logo}
                      quoteReqId={obj?.id}
                      id={obj?.id}
                      brandName={obj?.brand_name}
                      equipName={obj?.equip_name}
                    />
                  ))}
                </Container>
              </>
            )}
            {cantparticipateList?.length > 0 && (
              <>
                <Typography component="p" variant="h3" className={classNames(classes.pb10, classes.ParticipantColor)}>
                  Can’t Participate
                </Typography>
                <Container className={classNames(classes.divFlex, classes.cantParticipant)} gap={'0 20px'}>
                  {cantparticipateList?.map((obj, index) => (
                    <CantParticipantCard
                      key={index}
                      name={obj?.company_name}
                      city={obj?.supplier_addr_city}
                      state={obj?.supplier_addr_state_abbrv}
                      country={obj?.supplier_addr_country}
                      img={obj?.company_logo}
                      quoteReqId={obj?.id}
                      id={obj?.id}
                      brandName={obj?.brand_name}
                      equipName={obj?.equip_name}
                      partName={obj?.part_name}
                    />
                  ))}
                </Container>
              </>
            )}

            {otherSuppliersList?.length > 0 && status === false && (
              <>
                <Container className={classes.otherSupplier}>
                  <Typography variant="title" color={getColor('grey-g25')} component="p">
                    Other Suppliers
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    These suppliers are not currently receiving quote requests and must be contacted directly
                  </Typography>
                </Container>
                <Container className={classNames(classes.divFlex, classes.cantParticipant)} gap={'0 20px'}>
                  {otherSuppliersList?.map((obj, index) => (
                    <OtherSupplierCard
                      key={index}
                      name={obj?.company_name}
                      city={obj?.supplier_addr_city}
                      state={obj?.supplier_addr_state}
                      country={obj?.supplier_addr_country}
                      img={obj?.company_logo}
                      id={obj?.company_id}
                      equipName={obj?.equip_name}
                      brandName={obj?.brand_name}
                      partName={obj?.part_name}
                    />
                  ))}
                </Container>
              </>
            )}
          </Container>
        </Container>
      </Container>
      <InviteMoreSuppliersDialog open={openInviteMoreDialog} onclose={toggleInviteMoreDialog} />
    </>
  )
}

export default BuyerQuoteList

BuyerQuoteList.propTypes = {
  status: PropTypes.bool,
}
