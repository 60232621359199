import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import SampleImage1 from 'assets/images/PartsSampleImage-1.png'
import SampleImage2 from 'assets/images/PartsSampleImage-2.png'
import SampleImage3 from 'assets/images/PartsSampleImage-3.png'
import Image from 'components/common/Image'
import classNames from 'classnames'
import { getColor } from 'theme/colors'
import { useNavigate, useParams } from 'react-router-dom'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import Button from 'components/common/Button'
import { formatPrice, getFromLocalStorage } from 'utils/Utils'
import { CaretLeftIcon, EditIcon, Verfied } from 'assets/icons'
import Link from 'components/common/Link'
import Chip from 'components/common/Chip'
import TitleList from 'components/custom/list/TitleList'
import ImageSlider from 'components/custom/image-slider/ImageSlider'
import { useState } from 'react'
import HowOfferWorkBuyerDialog from './dialogs/HowOfferWorkBuyerDialog'
import useToggle from 'common-hooks/useToggle'
import CounterOfferCardSeller from './CounterOfferCardSeller'
import { constants } from 'utils/constants'
import AddShipRatesDialog from './dialogs/AddShipRatesDialog'
import CounterOfferCardBuyer from './CounterOfferCardBuyer'
import EndNegotiationDialog from './dialogs/EndNegotiationDialog'

const useStyles = makeStyles(() => ({
  title: {},
  root: {
    width: '21%',
    padding: '30px ',
    borderRight: '1px solid #F0F0F4',
    height: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  activepageRightInner: {
    height: '72vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  mainFlex: {
    display: 'flex',
  },
  eqpimage: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
    width: '60px',
    height: '60px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),

    '& div': {
      width: '32.2%',
      height: '120px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',
    },
  },
  eqpimage2: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
    width: '60px',
    height: '60px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),
    borderLeft: '0',

    '& div': {
      width: '32.2%',
      height: '60px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',
    },
  },
  //   partsimages: {
  //     width: '32.2%',
  //     cursor: 'pointer',
  //     height: '120px',
  //     display: 'flex',
  //     justifyContent: ' center',
  //     alignItems: 'center',
  //     '& img': {
  //       width: '120px',
  //       height: '120px',
  //       border: '1px solid #F0F0F4',
  //     },
  //   },
  p8: {
    padding: '8px 0',
    color: '#36384C',
  },
  p10: {
    padding: '10px 0',
    color: '#36384C',
  },
  p2: {
    padding: '2px 0',
  },
  partImageGrid: {
    display: 'flex',
    flexFlow: 'wrap',
    marginBottom: '15px',
  },
  imageOverlay: (props) => ({
    position: 'relative',
    cursor: 'pointer',
    '&:before': {
      position: 'absolute',
      content: `"+${props?.partsImage?.length - 3}"`,
      top: '0',
      bottom: '0',
      fontWeight: ' 400',
      fontFamily: 'Lexend Deca',
      fontSize: ' 14px',
      left: '0',
      right: '0',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#13141bb3',
    },
  }),
  activePageBody: {
    background: '#fff',
  },
  roottitle: {
    // height: '86px',
    borderBottom: ' 1px solid #F0F0F4',
    padding: '28px 30px',

    position: 'fixed',
    width: '100%',
    background: '#fff',
    zIndex: '999',
  },
  eqpImages: {
    marginTop: '10px',
    marginBottom: '13px',
  },
  eqpDetails: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
    paddingBottom: '10px',
  },
  partDetail: {
    paddingTop: '10px',
    display: 'flex',
  },
  activepageGrid: {
    display: 'flex',
    height: '98vh',
    // paddingTop: '93px',
  },
  number: {
    width: '20px',
    height: '16px',
    borderRadius: '100px',
    background: '#2680D9',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
  iconWithLabel: {
    display: 'flex',
    gap: '0 5px',
    alignItems: 'center',
    '& p': {
      margin: '0',
    },
  },
  link: {
    fontWeight: 600,
    fontSize: '1rem',
    color: '#2680D9',
    textDecoration: 'underline',
  },
  link2: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#2680D9',
    textDecoration: 'underline',
  },
  blueLink: {
    background: 'unset',
    padding: 'unset',
    color: getColor('grey-g35'),
    hight: 'unset',
    minHeight: 'unset',
    borderRadius: 'unset',
    '&:hover': {
      background: 'unset',
    },
    '& span': {
      fontSize: '14px',
      lineHeight: '20px',
      // fontWeight: '300',
    },
  },
  activepageRight: {
    width: '79%',
    paddingLeft: '50px',
    paddingRight: '30px',
    paddingTop: '30px',
    paddingBottom: '30px',
    background: '#F8F9F9',
  },
  img: {
    width: '60px',
    height: '60px',
    borderRadius: '4px',
    border: '1px solid #F0F0F4 ',
  },
  Tabs: {
    marginBottom: '25px',
  },
  offerSlider: {
    '& ul': {
      bottom: '25px',
      background: 'linear-gradient(180deg, rgba(19, 20, 27, 0.1) 0%, #13141B 100%)',
      height: '40px',
    },
    '& div div div': {
      height: 'auto',
      width: 'auto',
    },
  },
  redBtn: {
    background: 'transparent',
    color: '#AD1F36',
    border: '1px solid #AD1F36',
    '&:hover': {
      background: '#c15768',
      color: '#fff',
    },
  },
}))

export default function OfferDetails() {
  const params = useParams()
  const navigate = useNavigate()
  const [, setCurrentIndex] = useState(0)
  const userRole = getFromLocalStorage(constants.ROLE)
  const [shipDialog, toggleShipOptDialog] = useToggle(false)
  const [howdoWorkDialog, setHowDoWorkDialog] = useToggle(false)
  const { supplierReqDetails } = useReqSupplierDetails(params?.id, params?.reqId)
  const [deleteDialog, toggleDeleteDialog] = useToggle()
  const partsImage = supplierReqDetails?.quote_assets
  const classes = useStyles({ partsImage })
  const tempProductArray = [
    { part_image: SampleImage1 },
    { part_image: SampleImage2 },
    { part_image: SampleImage3 },
    { part_image: SampleImage3 },
  ]
  const sampleImages = tempProductArray
  return (
    <Container className={classes.activePageBody}>
      {/* <Container className={classes.roottitle}>
        <Container flex gap={'0 10px'} justify="flex-start">
          <Button
            variant={'secondary'}
            border
            width={'36px'}
            icon={<CaretLeftIcon />}
            onClick={() => navigate('/quotes')}
          />

          <Typography variant="h1" color={getColor('grey-g25')}>
            {supplierReqDetails?.brand_name} {supplierReqDetails?.part_name} {supplierReqDetails?.part_number}
          </Typography>
        </Container>
      </Container> */}
      <Container className={classes.activepageGrid}>
        <Container className={classNames(classes.mainFlex, classes.root)} direction={'column'} gap={'10px 0'}>
          <Container className={classNames(classes.mainFlex, classes.title)} direction={'column'}>
            <Container>
              <Button
                variant={'secondary'}
                border
                width={'36px'}
                icon={<CaretLeftIcon />}
                onClick={() => navigate('/offers')}
              />
            </Container>
            <Container>
              <Typography component="p" variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                {supplierReqDetails?.buyer_full_name}
              </Typography>
            </Container>
          </Container>

          <Container display="flex" direction="column" gap="20px">
            <ImageSlider
              sliderData={sampleImages}
              slidesToShow={1}
              slickGoto={0}
              setCurrentIndex={setCurrentIndex}
              className={classes.offerSlider}
            />
            <Container display="flex" direction="column" gap="10px">
              <Link to={''} linkClassName={classes.link}>
                John Deere - Bucket Cylinder - LW8924656
              </Link>
              <Container display="flex" gap="5px">
                <Chip label="In Stock" variant="success" />
                <Typography>{formatPrice(910)}</Typography>
              </Container>
            </Container>
            <Seperator />
          </Container>

          <Container direction={'column'}>
            <TitleList title="Seller" />
            <Container className={classes.partImageGrid}>
              <Container display="flex" gap={'10px'}>
                <Image src={tempProductArray[0]} className={classes.img} />
                <Container>
                  <Container display="flex" gap="5px" alignItems="center">
                    <Link to={''} linkClassName={classes.link2}>
                      J.R. Smith Construction Company
                    </Link>{' '}
                    <Verfied />
                  </Container>
                  <Typography variant="paragraph1">Lynden, NJ </Typography>
                </Container>
              </Container>
            </Container>
            <Seperator />
            {userRole === 'customer' ? (
              <Container className={classes.partDetail} direction={'column'} gap={'10px 0'}>
                <TitleList title="Shipping Options" />
                <Container display="flex" justify="space-between">
                  <Typography variant="body1" color="#36384C">
                    Next Day*
                  </Typography>
                  <Typography color="#36384C" variant="body1">
                    Free
                  </Typography>
                </Container>
                <Container display="flex" justify="space-between">
                  <Typography variant="body1" color="#36384C">
                    2nd Day*
                  </Typography>
                  <Typography color="#36384C" variant="body1">
                    $30.00
                  </Typography>
                </Container>
                <Container display="flex" justify="space-between">
                  <Typography variant="body1" color="#36384C">
                    Ground
                  </Typography>
                  <Typography color="#36384C" variant="body1">
                    $20.00
                  </Typography>
                </Container>
                <Container display="flex" justify="space-between">
                  <Typography variant="body1" color="#36384C">
                    Local Delivery
                  </Typography>
                  <Typography color="#36384C" variant="body1">
                    $10.00
                  </Typography>
                </Container>
              </Container>
            ) : (
              <Container className={classes.partDetail} direction={'column'} gap={'10px 0'}>
                <Container display="flex" justify="space-between">
                  <TitleList title="Shipping Options" />
                  <EditIcon onClick={toggleShipOptDialog} />
                </Container>

                <Container display="flex" justify="space-between">
                  <Typography variant="body1" color="#36384C">
                    Next Day*
                  </Typography>
                  <Typography color="#36384C" variant="body1">
                    Free
                  </Typography>
                </Container>
                <Container display="flex" justify="space-between">
                  <Typography variant="body1" color="#36384C">
                    Local Delivery
                  </Typography>
                  <Typography color="#36384C" variant="body1">
                    $10.00
                  </Typography>
                </Container>
              </Container>
            )}
          </Container>
          <Seperator />
        </Container>
        <Container className={classes.activepageRight}>
          <Container flex justify="space-between" className={classes.Tabs}>
            <Container>
              <Typography variant="h1">Offers</Typography>
            </Container>
            <Container display="flex" gap="20px">
              <Button className={classes.blueLink} onClick={() => setHowDoWorkDialog(true)}>
                How do offers work?
              </Button>
              <Button className={classes.redBtn} onClick={() => toggleDeleteDialog(true)}>
                End Negotiation
              </Button>
            </Container>
          </Container>
          <Container className={classes.activepageRightInner}>
            <Container>{userRole === 'customer' ? <CounterOfferCardBuyer /> : <CounterOfferCardSeller />}</Container>
          </Container>
        </Container>
      </Container>
      <HowOfferWorkBuyerDialog onClose={setHowDoWorkDialog} open={howdoWorkDialog} />
      <AddShipRatesDialog open={shipDialog} onClose={toggleShipOptDialog} />
      <EndNegotiationDialog open={deleteDialog} onclose={toggleDeleteDialog} />
    </Container>
  )
}
