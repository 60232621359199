import { toast } from 'react-toastify'
import Toast from '../components/common/Toast'
import 'react-toastify/dist/ReactToastify.css'

export default function handleError(error, { errorToast = true } = {}) {
  const code = (error && error.response && error.response.status) || 404
  const message = error?.response?.data?.error || error?.message || ERROR_PLACEHOLDER
  const err = {
    message: typeof message === 'object' ? JSON.stringify(message) : message,
    code,
  }
  const isUnauthorized = error?.response?.status === 401 && !error?.config?.url?.includes('login')
  if (err.message === 'User not found.') {
    return 0
  }
  if (err.message === 'Code not found') {
    return toast(<Toast variant="error" message="Provided Code is Invalid" />)
  }
  if (err.message === '"website_url" must be a valid uri') {
    return toast(<Toast variant="error" message="Enter the valid website url" />)
  }
  if (err.message === '"linkedin_url" must be a valid uri') {
    return toast(<Toast variant="error" message="Enter the valid LinkedIn url" />)
  }
  if (err.message === '"twitter_url" must be a valid uri') {
    return toast(<Toast variant="error" message="Enter the valid Twitter url" />)
  }
  if (err.message === '"facebook_url" must be a valid uri') {
    return toast(<Toast variant="error" message="Enter the valid Facebook url" />)
  }
  if (err.message === 'Network Error') {
    localStorage.clear()
    window.location.reload()
    return toast(<Toast variant="error" message="Network Error" />)
  }
  if (err?.message?.includes('shippo_api_key')) {
    return toast(<Toast variant="error" message="Please enter valid shippo key" />)
  }
  if (errorToast && !isUnauthorized) toast(<Toast variant="error" message={err.message} />)

  throw err
}
export const ERROR_PLACEHOLDER = 'Oops! Something went wrong...'
