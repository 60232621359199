import React from 'react'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import classNames from 'classnames'
import { InfoSmallIcon } from 'assets/icons'
import Container from 'components/common/Container'
import Tooltip from 'components/common/Tooltip'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    height: props.height || '40px',
  }),
}))

export default function TitleList({ className, title, tooltip, subtitle, tooltipMaxWidth, height }) {
  const classes = useStyles({ height })
  return (
    <Container direction="column" className={classNames(classes.root, className)}>
      <Container flex justify="flex-start" width="100%" gap={'5px'}>
        <Typography variant="title" color={getColor('grey-g25')}>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip placement="right" maxWidth={tooltipMaxWidth} title={tooltip}>
            <InfoSmallIcon className={classes.icon} />
          </Tooltip>
        )}
      </Container>
      {subtitle && (
        <Typography variant="paragraph1" color={getColor('grey-g25')}>
          {subtitle}
        </Typography>
      )}
    </Container>
  )
}

TitleList.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipMaxWidth: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.string,
}
