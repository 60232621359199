import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import classNames from 'classnames'
import { TextareaAutosize } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import { ErrorRedIcon } from 'assets/icons'
import Label from '../Label'
import Tooltip from '../Tooltip'

import Container from '../Container'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: (props) => theme.spacing(props.spacing),
    width: (props) => props.width,
    position: 'relative',
    '& textarea': {
      // height: (props) => `${props.height} !important` || '120px !important',
      height: (props) => (props?.height ? `${props.height} !important` : '120px !important'),
      overflowY: 'scroll!important',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  textArea: {
    width: '100%',
    resize: 'none',
    outline: 'none',
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: (props) => (props.error ? getColor('func-negative') : getColor('grey-g80')),
    fontWeight: '300',
    fontSize: '14px',
    color: getColor('grey-g25'),
    lineHeight: ' 20px',
    boxSizing: 'border-box',
    minHeight: 30,
    fontFamily: 'Lexend Deca',
    padding: theme.spacing(1.5, 2.5),
    borderRadius: 2,
    backgroundColor: getColor('system-bg'),
    '& div': {
      '& textarea': {
        height: 'unset',
      },
    },
    '&:disabled': {
      color: 'rgba(0,0,0,0.25)',
    },
    '&::placeholder': {
      color: getColor('grey-g65'),
      fontFamily: 'Lexend Deca',
    },

    '&:active ': {
      borderColor: (props) => {
        if (props.readOnly) {
          return getColor('grey-g85')
        }
        if (props.error) {
          return getColor('func-negative')
        }
        return getColor('func-info')
      },
    },
    '&:focus': {
      borderColor: (props) => {
        if (props.readOnly) {
          return getColor('grey-g85')
        }
        if (props.error) {
          return getColor('func-negative')
        }
        return getColor('func-info')
      },
    },
    '&:hover': {
      borderColor: getColor('grey-g35'),
    },
  },
  errorIcon: {
    position: 'absolute',
    right: '0',
    zIndex: '111',
    top: '-17px',
  },
}))

const TextArea = forwardRef(
  (
    {
      id,
      placeholder,
      value,
      onChange,
      label,
      labelSpacing,
      labelTooltip,
      labelVariant,
      LabelProps,
      spacing,
      height,
      error,
      textareaClassName,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({ spacing, error, height })
    return (
      <Container container direction="column" className={classes.root}>
        {label && (
          <Label spacing={labelSpacing} variant={labelVariant} tooltip={labelTooltip} {...LabelProps}>
            {label}
          </Label>
        )}
        {error && (
          <div className={classes.errorIcon}>
            <Tooltip title={error} placement={'right-start'}>
              <ErrorRedIcon />
            </Tooltip>
          </div>
        )}

        <TextareaAutosize
          id={id}
          className={classNames(classes.textArea, textareaClassName)}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          {...props}
        />
      </Container>
    )
  }
)

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  labelSpacing: PropTypes.number,
  labelTooltip: PropTypes.string,
  labelVariant: PropTypes.string,
  spacing: PropTypes.number,
  textareaClassName: PropTypes.string,
  LabelProps: PropTypes.object,
  height: PropTypes.string,
}

TextArea.displayName = 'TextArea'

export default TextArea
