import { createContext } from 'react'

export const SupplierQuoteContext = createContext({
  onSaveSupplierQuoteInformaton: () => {},
  supplierQueoteInformation: {},
})

export const eqpImageContent = {
  brand: 'John Deere',
  name: 'Loader',
  model: '244k',
  serialno: '1LU244KXlZBO4O616',
}
export const partsDetails = {
  brand: 'Hydraulics',
  name: 'Bucket Cylinder',
  model: 'Seal Kit',
}
export const exceptThisSymbols = ['e', 'E', '+', '-']

function freightDataParsing(data) {
  const freightObj = {}

  data?.map((obj) => {
    if (obj?.frieghtstate?.value === true) {
      if (obj?.frieghtstate?.label === 'Ground') {
        freightObj.freight_ground = obj?.freightvalue === '' ? '0' : obj?.freightvalue
      }
      if (obj?.frieghtstate?.label === '2nd Day') {
        freightObj.freight_second_day = obj?.freightvalue === '' ? '0' : obj?.freightvalue
      }
      if (obj?.frieghtstate?.label === 'Next Day') {
        freightObj.freight_next_day = obj?.freightvalue === '' ? '0' : obj?.freightvalue
      }
      if (obj?.frieghtstate?.label === 'LTL') {
        freightObj.mdo_ltl = obj?.freightvalue
      }
      if (obj?.frieghtstate?.label === 'LocalDelivery') {
        freightObj.mdo_local_delivery = obj?.freightvalue === '' ? '0' : obj?.freightvalue
      }
      if (obj?.frieghtstate?.label === 'Pick Up') {
        freightObj.mdo_pick_up = obj?.freightvalue === '' ? '0' : obj?.freightvalue
      }
    }
    return null
  })
  return freightObj
}
export function supplierParsing(data, id, reqId) {
  const freightObj = freightDataParsing(data?.Information?.freight)

  const parsingData = {
    ...{ quote_id: id },
    ...(reqId && { id: reqId }),
    ...(data?.partNumber && { part_number: data.partNumber }),
    ...(data.partDescription && { part_description: data.partDescription }),
    ...(data?.ships && { ships_in: data?.ships }),
    ...(data.unitPrice && { amount: data.unitPrice }),
    ...(data?.inStock === true ? { in_stock: true } : { in_stock: false }),
    ...(data?.condition?.value && { condition: data?.condition?.value }),
    ...(data?.availableQty && { available_qty: data?.availableQty }),
    ...(data?.weight && { weight: data?.weight }),
    ...(data?.notes && { note: data?.notes }),
    ...(data?.Information?.negotiateOffer === true ? { can_negotiate: true } : { can_negotiate: false }),
    ...(data?.Information?.returnProduct === true ? { can_return: true } : { can_return: false }),
    ...(data?.Information?.Warranty === true ? { is_warranty: true } : { is_warranty: false }),
    ...(data?.Information?.cancelProduct === true ? { can_cancel_product: true } : { can_cancel_product: false }),
    delivery_options: {
      ...(freightObj?.freight_ground && { freight_ground: freightObj?.freight_ground }),
      ...(freightObj?.freight_ground && { freight_ground: freightObj?.freight_ground }),
      ...(freightObj?.freight_second_day && { freight_second_day: freightObj?.freight_second_day }),
      ...(freightObj?.freight_next_day && { freight_next_day: freightObj?.freight_next_day }),
      ...(freightObj?.mdo_ltl && { mdo_ltl: freightObj?.mdo_ltl }),
      ...(freightObj?.mdo_local_delivery && { mdo_local_delivery: freightObj?.mdo_local_delivery }),
      ...(freightObj?.mdo_pick_up && { mdo_pick_up: freightObj?.mdo_pick_up }),
    },
    ...(data?.removeAssetIds?.length !== 0 && { remove_assets: data?.removeAssetIds }),
    ...(data?.Information?.orderTime?.value && { order_by: data?.Information?.orderTime?.value }),
    ...(data?.company_billing && {
      company_delivery_addr: {
        id: data?.company_billing?.id,
        addr_line_1: data?.company_billing?.addr_line_1,
        addr_line_2: data?.company_billing?.addr_line_2,
        ...(data?.company_billing?.city !== undefined && { city: data?.company_billing?.city }),
        ...(data?.company_billing?.city_abbrv !== undefined && { city_abbrv: data?.company_billing?.city_abbrv }),
        state: data?.company_billing?.state,
        state_abbrv: data?.company_billing?.state_abbrv,
        country_abbrv: data?.company_billing?.country_abbrv || data?.company_billing?.country,
        zipcode: data?.company_billing?.zipcode || data?.company_billing?.zipCode,
      },
    }),
  }
  const formData = new FormData()
  const newString = JSON.stringify(JSON.stringify(parsingData))
  if (data?.imagesArray?.length > 0) {
    data?.imagesArray.forEach((file) => {
      formData.append('assets', file?.file)
    })
  }
  formData.append('document', newString)

  return formData
}

export function getSupplierData(data) {
  const parsedData = {
    ...(data?.quote_request_part_number && { partNumber: data.quote_request_part_number }),
    ...(data?.quote_request_part_description && { partDescription: data.quote_request_part_description }),
    ...(data?.quote_request_amount && { unitPrice: data.quote_request_amount }),
    ...(data?.quote_request_in_stock === false ? { inStock: false } : { inStock: true }),
    ...(data?.quote_request_condition === 'new_aftermarket' && {
      condition: { value: data?.quote_request_condition, label: 'New Aftermarket' },
    }),
    ...(data?.quote_request_condition === 'new_oem' && {
      condition: { value: data?.quote_request_condition, label: 'New OEM' },
    }),
    ...(data?.quantity && { availableQty: data?.quantity }),
    ...(data?.part_number && { partNumber: data?.part_number }),
    ...(data?.part_name && { partDescription: data?.part_name }),

    ...(data?.quote_request_condition === 'used' && {
      condition: { value: data?.quote_request_condition, label: 'Used' },
    }),
    ...(data?.quote_request_available_qty && { availableQty: data?.quote_request_available_qty }),
    ...(data?.quote_request_weight && { weight: data.quote_request_weight }),

    ...(data?.quote_request_ships_in && { ships: data.quote_request_ships_in }),
    // ...(images?.length !== 0 && { partsImages: images }),
    ...(data?.quote_assets.length > 0 && { partsImages: data?.quote_assets }),
    ...(data?.quote_request_delivery_options?.freight_ground && {
      ground: { value: true, label: 'Ground' },
      groundRate: data?.quote_request_delivery_options?.freight_ground,
    }),
    ...(data?.quote_request_delivery_options?.freight_second_day && {
      secondDayState: { value: true, label: '2nd Day' },
      secondDay: data?.quote_request_delivery_options?.freight_second_day,
    }),
    ...(data?.quote_request_delivery_options?.freight_next_day && {
      nextDayState: { value: true, label: 'Next Day' },
      nextDay: data?.quote_request_delivery_options?.freight_next_day,
    }),
    ...(data?.quote_request_delivery_options?.mdo_ltl && {
      ltlState: { value: true, label: 'LTL' },
      ltl: data?.quote_request_delivery_options?.mdo_ltl,
    }),
    ...(data?.quote_request_delivery_options?.mdo_local_delivery && {
      localDeliveryState: { value: true, label: 'LocalDelivery' },
      localdelivery: data?.quote_request_delivery_options?.mdo_local_delivery,
    }),
    ...(data?.quote_request_delivery_options?.mdo_pick_up && {
      pickUpState: { value: true, label: 'Pick Up' },
      pickup: data?.quote_request_delivery_options?.mdo_pick_up,
    }),
    ...(data?.quote_request_order_by && { orderBy: data?.quote_request_order_by }),
    ...(data?.quote_request_note !== 0 && { notes: data?.quote_request_note }),
    ...(data?.cmp_billing_addr_addr_line_1 && {
      company_billing: {
        addr_line_1: data?.cmp_billing_addr_addr_line_1,
        addr_line_2: data?.cmp_billing_addr_addr_line_2,
        city: data?.cmp_billing_addr_city,
        city_abbrv: data?.cmp_billing_addr_city,
        state: data?.cmp_billing_addr_state,
        state_abbrv: data?.cmp_billing_addr_state_abbrv,
        country: data?.cmp_billing_addr_country_abbrv,
        zipCode: data?.cmp_billing_addr_zipcode,
        id: data?.cmp_billing_addr_id,
      },
    }),
  }

  return parsedData
}

// get time with 15min diffrence //

function twoChar(num) {
  return num.toString().length < 2 ? `0${num.toString()}` : num.toString()
}

export function getTimes() {
  const ans = []
  let ref = ' AM'
  // eslint-disable-next-line no-plusplus
  for (let x = 0; x < 24; x++) {
    if (x === 12) ref = ' PM'
    const i = x % 12
    const tmp = twoChar(i === 0 ? 12 : i)
    for (let j = 0; j < 60; j += 15) {
      const time = `${tmp}:${twoChar(j)}${ref}`
      ans.push({ value: time, label: time })
    }
  }
  return ans
}
