import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { ClearIcon, SearchIcon } from 'assets/icons'
import useUncontrolledSearch from 'components/common/search/useUncontrolledSearch'
import { IconButton } from '@mui/material'
import TextField from 'components/common/text/TextField'
import Container from 'components/common/Container'

const useStyles = makeStyles(() => ({
  rightIcon: {
    padding: '0.125rem',
  },
  searchRoot: {
    position: 'relative',
    '& input': {
      padding: '0 15px',
    },
  },
  searchBtn: {
    position: 'absolute',
    top: '0',
    right: '15px',
    zIndex: '999',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  iconBtn: {
    padding: '0',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
}))

export default function ProductSearch({ id, onSearchClick, onSearch = () => {}, ...props }) {
  const classes = useStyles()
  const { onType, onClear, ref, hasText } = useUncontrolledSearch(onSearch)

  const clearIcon = (props?.value && props?.value !== '') || hasText

  const clearAll = () => {
    onClear()
    if (props.clearPreserveState) props.clearPreserveState()
  }

  return (
    <>
      <Container className={classes.searchRoot}>
        <TextField
          id={id}
          ref={ref}
          placeholder="Search"
          inputClassName={classes.root}
          // rightIcon={
          //   clearIcon ? (
          //     <ClearButton onClick={clearAll} className={classes.rightIcon} />
          //   ) : (
          //     <SearchIcon className={classes.rightIcon} />
          //   )
          // }
          onChange={onType}
          {...props}
        />
        <Container className={classes.searchBtn}>
          <IconButton className={classes.iconBtn}>
            {clearIcon && <ClearButton onClick={clearAll} className={classes.rightIcon} />}
          </IconButton>
          <IconButton className={classes.iconBtn} onClick={onSearchClick}>
            <SearchIcon className={classes.rightIcon} />
          </IconButton>
        </Container>
      </Container>
    </>
  )
}

ProductSearch.propTypes = {
  id: PropTypes.string,
  onSearch: PropTypes.func,
  value: PropTypes.string,
  clearPreserveState: PropTypes.func,
  onSearchClick: PropTypes.func,
}

function ClearButton(props) {
  return (
    <IconButton size="sm" variant="transparent" {...props}>
      <ClearIcon />
    </IconButton>
  )
}
