import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import useToastify from 'common-hooks/useToastify'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { getColor, getTagColor } from 'theme/colors'
import { checkImageDimensions, convertHeic, rejectMaxHeight } from 'utils/Files'
import Container from 'components/common/Container'
import Label from 'components/common/Label'
import Typography from 'components/common/Typography'
import Link from 'components/common/Link'
import { CsvUploadIcon } from 'assets/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: (props) => theme.spacing(props.spacing),
    position: 'relative',
  },
  dropzone: {
    borderWidth: 0.5,
    borderStyle: 'dashed',
    borderColor: getTagColor('grey-g35'),
    padding: theme.spacing(4, 0),
    width: 'inherit',
    height: 'inherit',
    cursor: 'pointer',
  },
  browse: {
    color: theme.palette.domain.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  actionContainer: {
    margin: theme.spacing(2.5, 0),
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: ' 18px',
    marginBottom: '0',
    color: getColor('grey-g65'),
  },
  text: {
    position: 'absolute',
    top: '0',
    cursor: 'pointer',
    width: '380px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '0',
    flexDirection: 'column',
  },
  csvUpload: {
    position: 'absolute',
    top: '0',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '0',
    // flexDirection: 'column',
  },
  browseLink: {
    color: '#2680D9',
    paddingLeft: '0px',
    '& a': {
      color: '#2680D9',
    },
  },
  textRoot: {
    // position: 'relative',
    width: '380px',
    height: '80px',
  },
}))

export default function Dropzone({
  accept,
  onChange,
  spacing,
  label,
  labelVariant,
  labelSpacing = 2,
  labelTooltip,
  LabelProps,
  dropzoneClassName,
  actionComponent,
  maxSize,
  maxFiles,
  multiple,
  maxHeight,
  previewComponent,
  PdfUpload,
  ref,
  csvUpload,
  setRef = () => {},
}) {
  const { toastMessage } = useToastify()
  const classes = useStyles({ spacing })

  const onDrop = useCallback(
    async (files, rejectedFiles) => {
      const handleRejected = (files) => {
        files.forEach((file) => {
          const errCode = file.errors?.[0]?.code
          if (errCode === 'file-too-large') {
            toastMessage('error', `File ${file.file.name} too large`)
          }
          if (errCode === 'max-height') {
            toastMessage('error', `Height of ${file.file.name} more than ${maxHeight} pixel`)
          }
        })
      }
      if (rejectedFiles && rejectedFiles.length) {
        handleRejected(rejectedFiles)
      }

      const acceptFiles = []
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (file.type.includes('heic') || file.type.includes('heif')) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const convertedHeic = await convertHeic(file)
            acceptFiles.push(convertedHeic)
          } catch (e) {
            console.error(e)
          }
        } else {
          acceptFiles.push(file)
        }
      }
      if (maxHeight) {
        const acceptFiles = []
        const rejectedFiles = []
        files.map(async (file) => {
          const { height } = await checkImageDimensions(file)
          if (height > maxHeight) {
            rejectedFiles.push(rejectMaxHeight(file))
          } else {
            acceptFiles.push(file)
          }
        })
        if (rejectedFiles.length) {
          handleRejected(rejectedFiles)
        }
        if (onChange) {
          onChange(acceptFiles)
        }
        return
      }
      if (onChange) {
        onChange(acceptFiles)
      }
    },
    [maxHeight, onChange, toastMessage]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxSize,
    maxFiles,
    multiple,
    inputRef: ref,
    rootRef: ref,
  })

  return (
    <Container container direction="column" className={classes.root}>
      <div {...getRootProps()}>
        {setRef && (
          <input
            {...getInputProps()}
            ref={(r) => {
              setRef(r)
            }}
          />
        )}
        <input {...getInputProps()} />
        <Container
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classNames(classes.dropzone, dropzoneClassName)}
        >
          {previewComponent || (
            <>
              {actionComponent && (
                <Container item className={classes.actionContainer}>
                  {actionComponent}
                </Container>
              )}
            </>
          )}
        </Container>
        {PdfUpload && (
          <Container className={classes.text}>
            <Typography variant="body1" color={'#36384C'}>
              Drag & Drop File or{' '}
              <Link to="" underline className={classes.browseLink}>
                Browse
              </Link>
            </Typography>
            <Typography variant="caption" color={'#9799B5'}>
              Supported file .pdf upto 5mb
            </Typography>
          </Container>
        )}
        {csvUpload && (
          <Container className={classes.csvUpload}>
            <Container>
              <CsvUploadIcon />
            </Container>
            <Container flex direction="column">
              <Typography variant="body1" color={'#36384C'} component="p">
                Drag & Drop or{' '}
                <Link to="" underline className={classes.browseLink}>
                  Upload .csv file
                </Link>
              </Typography>
              <Typography variant="caption" color={'#9799B5'} component="p">
                Importing inventory can take 12-24 hours
              </Typography>
            </Container>
          </Container>
        )}
      </div>
      {label && (
        <Label
          className={classes.label}
          spacing={labelSpacing}
          variant={labelVariant}
          tooltip={labelTooltip}
          {...LabelProps}
        >
          {label}
        </Label>
      )}
    </Container>
  )
}

Dropzone.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  spacing: PropTypes.number,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['primary', 'secondary']),
  labelSpacing: PropTypes.number,
  labelTooltip: PropTypes.string,
  LabelProps: PropTypes.object,
  dropzoneClassName: PropTypes.string,
  actionComponent: PropTypes.node,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  maxHeight: PropTypes.number,
  previewComponent: PropTypes.node,
  ref: PropTypes.node,
  setRef: PropTypes.func,
  PdfUpload: PropTypes.string,
  csvUpload: PropTypes.bool,
}
