import { getOrderList, updateOrder } from 'common-api/super-admin/superAdmin'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import useToastify from 'common-hooks/useToastify'
import { useState } from 'react'

export default function useSuperAdminOrderList(status, orderBy) {
  const [search, onSearch] = useState('')
  const [page, setPage] = useState(1)
  const { toastMessage } = useToastify()
  const limit = 35

  const params = {
    ...(search && { search }),
    ...(status && { order_status: status }),
    ...(orderBy && orderBy),
    limit,
    page,
  }

  const { data, mutate } = useSwrInfinite('/super-admin/orders', params, getOrderList)
  if (data && data.length === 0 && page > 1) {
    toastMessage('error', 'There are no more orders')
    setPage((pre) => pre - 1)
  }

  const orderUpdate = async (data) => {
    function toastMessages(data) {
      if (data?.status) {
        return 'Status changed successfully'
      }
      if (data?.notes === '') {
        return 'Notes deleted successfully'
      }
      if (data?.update === true) {
        return 'Notes updated successfully'
      }
      return 'Notes added successfully'
    }
    const message = toastMessages(data)
    delete data?.update
    try {
      const response = await updateOrder(data)
      toastMessage('success', message)
      mutate()

      return response
    } catch {
      return false
    }
  }

  return {
    orderList: data,
    mutate,
    isLoading: !data,
    onSearch,
    search,
    setPage,
    page,
    orderUpdate,
  }
}
