import { makeStyles } from '@mui/styles'
import { AddIcon } from 'assets/icons'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import StripeWordImg from 'assets/images/StripeWordImg.svg'
import useToggle from 'common-hooks/useToggle'
import List from 'components/custom/list/List'
import { useNavigate } from 'react-router-dom'
import usePaymentMethods from 'common-hooks/settings/payment/usePaymentMethods'
import { getSetupIntent } from 'common-api/settings/settings'
import { setToLocalStorage } from 'utils/Utils'
import { constants } from 'utils/constants'
import { useState } from 'react'
import LoadingOverlay from 'components/common/LoadingOverlay'
import PaymentMethodCard from './PaymentMethodCard'
import PaymentMethodDialog from '../../common-containers/add-payment-method/PaymentMethodDialog'
import useProfileSettingDetail from 'common-hooks/settings/profile/useProfileSettingDetail'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '830px',
    margin: '0 auto',
  },
  headerDiv: {
    padding: '33px 0',
    color: getColor('grey-g25'),
  },
  titleDiv: {
    // padding: '0px 30px',
  },
  cardDiv: {
    padding: '20px 0 10px 0',
    display: 'flex',
    flexFlow: 'wrap',
  },
  titleSubTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  paragraph: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: getColor('grey-g80'),
  },
  add: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  profileroot: {
    width: '100%',
    display: 'flex',
    background: '#fff',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabs: {
    width: '260px',
    padding: '20px 10px 20px 10px',
    borderRight: `1px solid ${getColor('grey-g95')}`,
  },
  tabList: {
    padding: '10px 20px 10px 20px',
    color: '#36384c',
  },
  containers: {
    height: '94vh',
    background: '#F8F9F9',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  active: {
    color: getColor('func-info'),
    padding: '10px 20px 10px 20px',
  },
  addCard: {
    width: '263px',
    height: '150px',
    border: ' 1px dashed #C3C5D5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  noLink: {
    padding: '10px 20px',
  },
}))
export default function PaymentSettings() {
  const classes = useStyles()
  const [openDialog, toggleDialog] = useToggle(false)
  const { profileData } = useProfileSettingDetail()
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { paymentMethods } = usePaymentMethods()

  const addCard = async () => {
    setLoading(true)
    const setupIntent = await getSetupIntent()
    setToLocalStorage(constants.CLIENT_SECRET, setupIntent.client_secret)
    setLoading(false)
    toggleDialog()
  }

  return (
    <>
      <Container className={classes.profileroot}>
        <Container className={classes.tabs}>
          <List
            title={'My Profile'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/profile')
            }}
          />
          {profileData?.user_company ? (
            <List
              title={'Company Profile'}
              className={classes.tabList}
              type={'link'}
              onClick={() => navigate('/settings/company')}
            />
          ) : (
            <Container className={classes.noLink}>
              <Typography color="#9799B5">Company Profile</Typography>
            </Container>
          )}
          <List
            title={'Payment'}
            className={classes.active}
            type={'link'}
            onClick={() => {
              navigate('/settings/payment')
            }}
          />
          <List
            title={'Shipping'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/shipping')
            }}
          />
          <List
            title={'Membership'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/membership')
            }}
          />
          <List
            title={'Password'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/security')
            }}
          />
        </Container>
        <Container width="100%" className={classes.containers}>
          {isLoading && <LoadingOverlay />}
          <Container className={classes.root}>
            <Container justify="space-between" width="100%" className={classes.headerDiv}>
              <Typography variant="h1">Payment</Typography>
            </Container>
            <Container justify="space-between" width="100%" className={classes.titleDiv}>
              <Container className={classes.titleSubTitle}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  Payment Method
                </Typography>
                <Typography variant="paragraph" color={getColor('grey-g35')}>
                  Set credit card for fast and secure payments
                </Typography>
              </Container>
              {/* <Container className={classes.add}>
                <AddIcon onClick={() => addCard()} />
              </Container> */}
            </Container>
            <Container justify="flex-start" width="100%" gap={'20px'} className={classes.cardDiv}>
              <Container className={classes.addCard}>
                <Container flex width={'100%'} height={'100%'} direction="column" onClick={() => addCard()}>
                  <AddIcon />
                  <Typography variant="body1" color={getColor('grey-g35')}>
                    Add Card
                  </Typography>
                </Container>
              </Container>
              {paymentMethods?.map((card, index) => (
                <PaymentMethodCard key={index} card={card} />
              ))}
            </Container>
            <Container justify="flex-start" width="100%" gap="5px" className={classes.titleDiv}>
              <Typography variant="overline" component="p" className={classes.paragraph}>
                *Credit cards are stored and secured by
              </Typography>
              <img src={StripeWordImg} alt="Stripe" />
            </Container>
            <PaymentMethodDialog open={openDialog} onClose={toggleDialog} successURL={'settings/payment'} />
          </Container>
        </Container>
      </Container>
    </>
  )
}
