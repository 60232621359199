import { makeStyles } from '@mui/styles'
import useProfileSettingDetail from 'common-hooks/settings/profile/useProfileSettingDetail'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import { Tabs } from 'components/common/Tabs'
import Typography from 'components/common/Typography'
import List from 'components/custom/list/List'
import { CreateSupplierContext } from 'containers/super-admin/supplier-mgt/SupplierUtils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getColor } from 'theme/colors'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import CompanyCourier from './CompanyCourier'
import CompanyPolicies from './CompanyPolicies'
import CompanyProfile from './CompanyProfile'
import CompanyTaxes from './CompanyTaxes'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',

    padding: '30px 0',
    maxWidth: '830px',
    margin: '0 auto',
  },
  headerDiv: {
    paddingTop: '30px',
    paddingBottom: '30px',
  },
  profileroot: {
    width: '100%',
    display: 'flex',
    background: '#fff',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabs: {
    width: '260px',
    padding: '20px 10px 20px 10px',
    borderRight: `1px solid ${getColor('grey-g95')}`,
  },
  tabList: {
    padding: '10px 20px 10px 20px',
    color: '#36384c',
  },
  containers: {
    height: '94vh',
    backgroundColor: '#F8F9F9',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  active: {
    color: getColor('func-info'),
    padding: '10px 20px 10px 20px',
  },
  bodyScroll: {
    height: '77.4vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  noLink: {
    padding: '10px 20px',
  },
}))
export default function CompanySettings() {
  const classes = useStyles()
  const [tab, setTab] = useState(0)
  const navigate = useNavigate()
  const userRole = getFromLocalStorage(constants.ROLE)
  const { profileData } = useProfileSettingDetail()
  const [specializationsData, setSpecializationsData] = useState([])
  const [specializationPartsData, setSpecializationPartsData] = useState([])
  const [taxbtnDisable, setTaxBtnDisable] = useState(false)
  // const [enableBtn, setEnableBtn] = useState(false)

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }
  const TabsList = [
    { tabIndex: 0, name: 'Basic Details' },
    { tabIndex: 1, name: 'Couriers' },
    { tabIndex: 2, name: 'Policies' },
    { tabIndex: 3, name: 'Taxes' },
  ]
  if (userRole === 'customer') {
    TabsList.splice(1, 2)
  }
  const constextValue = {
    onSelectNode: setSpecializationsData,
    specializationTreeViewData: specializationsData,
    setPartsData: setSpecializationPartsData,
    partsData: specializationPartsData,
  }
  return (
    <>
      <Container className={classes.profileroot}>
        <Container className={classes.tabs}>
          <List
            title={'My Profile'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/profile')
            }}
          />
          {profileData?.user_company ? (
            <List
              title={'Company Profile'}
              className={classes.active}
              type={'link'}
              onClick={() => navigate('/settings/company')}
            />
          ) : (
            <Container className={classes.noLink}>
              <Typography color="#9799B5">Company Profile</Typography>
            </Container>
          )}
          <List
            title={'Payment'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/payment')
            }}
          />
          <List
            title={'Shipping'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/shipping')
            }}
          />
          <List
            title={'Membership'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/membership')
            }}
          />
          <List
            title={'Password'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/security')
            }}
          />
        </Container>
        <Container width="100%" className={classes.containers}>
          <Container className={classes.root}>
            <Container className={classes.TitleFixed}>
              <Container>
                <Typography variant="h1" color={getColor('grey-g25')}>
                  Company Profile
                </Typography>
              </Container>
              <Container flex justify="space-between" width="100%" className={classes.headerDiv}>
                <Tabs value={tab} onchange={handleChange} array={TabsList} />
                {tab === 0 && (
                  <Button type="submit" form="company-profile">
                    Save Changes
                  </Button>
                )}
                {tab === 2 && (
                  <Button type="submit" form={'policy-form'}>
                    Save Changes
                  </Button>
                )}
                {tab === 3 && (
                  <Button type="submit" form={'taxes-form'} disabled={taxbtnDisable}>
                    Save Changes
                  </Button>
                )}
                {tab === 1 && userRole === 'customer' && (
                  <Button type="submit" form={'taxes-form'} disabled={taxbtnDisable}>
                    Save Changes
                  </Button>
                )}
              </Container>
            </Container>
            <Container className={classes.bodyScroll}>
              {userRole === 'customer' ? (
                <>
                  <CreateSupplierContext.Provider value={constextValue}>
                    <Container>
                      {tab === 0 && (
                        <CompanyProfile
                          setSpecializationPartsData={setSpecializationPartsData}
                          setSpecializationsData={setSpecializationsData}
                          specializationPartsData={specializationPartsData}
                          // setEnableBtn={setEnableBtn}
                        />
                      )}
                    </Container>
                  </CreateSupplierContext.Provider>
                  <Container>{tab === 1 && <CompanyTaxes setTaxBtnDisable={setTaxBtnDisable} />}</Container>
                </>
              ) : (
                <>
                  <CreateSupplierContext.Provider value={constextValue}>
                    <Container>
                      {tab === 0 && (
                        <CompanyProfile
                          setSpecializationPartsData={setSpecializationPartsData}
                          setSpecializationsData={setSpecializationsData}
                          specializationPartsData={specializationPartsData}
                          // setEnableBtn={setEnableBtn}
                        />
                      )}
                    </Container>
                  </CreateSupplierContext.Provider>
                  <Container>{tab === 1 && <CompanyCourier />}</Container>
                  <Container>{tab === 2 && <CompanyPolicies />}</Container>
                  <Container>{tab === 3 && <CompanyTaxes setTaxBtnDisable={setTaxBtnDisable} />}</Container>
                </>
              )}
            </Container>
          </Container>
        </Container>
      </Container>
    </>
  )
}
