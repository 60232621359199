import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import { AuthContext, SignupDataContext } from 'utils/provider/AuthContext'
import VerifyEmail from 'containers/authentication/sign-up/VerifyEmail'
import VerifyCode from 'containers/authentication/sign-up/VerifyCode'
import Seperator from 'components/common/Seperator'
import SignUpAccountSteps from 'containers/authentication/sign-up/SignUpAccountSteps'
import CompanyInfo from 'containers/authentication/sign-up/SignUpCompanyInfo'
import IndustryInfo from 'containers/authentication/sign-up/SignUpIndustryInfo'
import Button from 'components/common/Button'
import SignUpAccountInfo from 'containers/authentication/sign-up/SignUpAccountInfo'
import Dialog from 'components/custom/dialog/Dialog'
import { makeStyles } from '@mui/styles'
import Typography from 'components/common/Typography'
import Link from 'components/common/Link'
import { getColor } from 'theme/colors'
import { CloseIcon } from 'assets/icons'
import IconButton from 'components/common/IconButton'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import SignIn from './sign-in/SignIn'
import SignInHeaderLogo from './sign-in/SignInHeaderLogo'
import SingUpAccoutType from './sign-up/SingUpAccoutType'

const useStyle = makeStyles(() => ({
  root: (props) => ({
    width: props.authenticationStep >= 4 ? '460px' : '400px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: props.authenticationStep >= 4 ? '30px 0 0 0' : '0 0 0 0',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .demo-class': {
      '& #radio-group': {
        width: '170px',
        height: '180px',
        textAlign: 'center',
      },
    },
  }),
  bottomLine: {
    color: getColor('grey-g65'),
    textAlign: 'center',
    maxWidth: '372px',
  },
  bottomLineLink: {
    color: getColor('grey-g65'),
    textDecoration: 'underline',
  },
  closeButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    '& button': {
      backgroundColor: 'unset',
    },
    zIndex: '99999',
  },
}))

export default function LoginDialog({ open, onClose }) {
  const navigation = useNavigate()
  const [authenticationStep, setAuthenticationStep] = useState(1)
  const { authState } = useContext(AuthContext)
  const [btnDisable, setBtnDisable] = useState(true)
  const [signup, setSign] = useState()
  const [userId, setUserId] = useState('')
  const [emailId, setEmail] = useState('')
  const classes = useStyle({ authenticationStep })
  const contextValue = {
    onSaveSignupData: setSign,
    signupData: signup,
  }

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const userRole = getFromLocalStorage(constants.ROLE)
      if (userRole === 'super_admin') navigation('/sa')
      else if (userRole === 'supplier') {
        navigation('/requestboard')
      } else {
        navigation('/quotes')
      }
    }
  }, [navigation, authState?.isAuthenticated])

  // useEffect(() => {
  //   window.addEventListener('keyup', (e) => {
  //     if (e.key === 'Escape') {
  //       onClose()
  //     }
  //   })
  // }, [onClose])

  return (
    <Dialog open={open} className={classes.root}>
      {/* <DialogHead onClose={() => onClose()} /> */}
      <Container className={classes.closeButton}>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Container>
      {authenticationStep < 4 && (
        <Container
          flex
          direction={'column'}
          gap={'20px'}
          // width={'434px'}
          height={authenticationStep >= 4 ? '700px' : '523px'}
        >
          <Container flex direction={'column'}>
            <SignInHeaderLogo />
          </Container>
          <Container width={'300px'}>
            {authenticationStep === 1 && (
              <VerifyEmail
                onStepChange={(step) => {
                  setAuthenticationStep(step)
                }}
                setUserId={setUserId}
                setEmail={setEmail}
              />
            )}
            {authenticationStep === 2 && <SignIn userId={userId} id={emailId} />}
            {authenticationStep === 3 && (
              <VerifyCode
                onStepChange={(step) => {
                  setAuthenticationStep(step)
                }}
              />
            )}
          </Container>
          <Container className={classes.bottomLine}>
            <Typography variant="overline" component="div">
              By clicking above, you acknowledge that you are agree to PartsClub's{' '}
              <Link to="#">
                <Typography variant="overline" className={classes.bottomLineLink}>
                  Terms & Conditions
                </Typography>
              </Link>{' '}
              and{' '}
              <Link to={'#'}>
                <Typography variant="overline" className={classes.bottomLineLink}>
                  Privacy Policy.
                </Typography>
              </Link>
            </Typography>
          </Container>
        </Container>
      )}
      <SignupDataContext.Provider value={contextValue}>
        {authenticationStep > 3 && (
          <Container flex direction={'column'} width={'460px'} gap={'20px'}>
            <Container flex direction={'column'}>
              <SignUpAccountSteps authenticationStep={authenticationStep} />
            </Container>
            <Seperator />
            <Container width="100%">
              {authenticationStep === 4 && (
                <SignUpAccountInfo
                  onStepChange={(step) => {
                    setAuthenticationStep(step)
                  }}
                  step={authenticationStep}
                  setBtnDisable={setBtnDisable}
                  emailId={emailId}
                />
              )}
              {authenticationStep === 5 && (
                <SingUpAccoutType
                  onStepChange={(step) => {
                    setAuthenticationStep(step)
                  }}
                  step={authenticationStep}
                  setBtnDisable={setBtnDisable}
                  emailId={emailId}
                />
              )}
              {authenticationStep === 6 && (
                <CompanyInfo
                  onStepChange={(step) => {
                    setAuthenticationStep(step)
                  }}
                  step={authenticationStep}
                  setBtnDisable={setBtnDisable}
                  btnDisable={btnDisable}
                />
              )}
              {authenticationStep === 7 && (
                <Container className="demo-class">
                  <IndustryInfo
                    onStepChange={(step) => {
                      setAuthenticationStep(step)
                    }}
                    step={authenticationStep}
                    setBtnDisable={setBtnDisable}
                  />
                </Container>
              )}
            </Container>
            <Seperator />
          </Container>
        )}
        {authenticationStep === 4 && (
          <Container flex justify="flex-end" height="70px" width="100%" padding="4.5">
            <Button
              variant={btnDisable ? 'disabled' : 'primary'}
              type="submit"
              width="92px"
              form={'signup-account-from'}
              disabled={btnDisable}
            >
              Continue
            </Button>
          </Container>
        )}

        {authenticationStep === 5 && (
          <Container flex width="100%" height="70px" justify="space-between" padding="4.5">
            <>
              <Button variant="secondary" border onClick={() => setAuthenticationStep(authenticationStep - 1)}>
                Previous
              </Button>
              <Button
                variant={btnDisable ? 'disabled' : 'primary'}
                type="submit"
                form={'singup-account-type'}
                disabled={btnDisable}
              >
                Continue
              </Button>
            </>
          </Container>
        )}
        {authenticationStep === 6 && (
          <Container flex width="100%" height="70px" justify="space-between" padding="4.5">
            <>
              <Button variant="secondary" border onClick={() => setAuthenticationStep(authenticationStep - 1)}>
                Previous
              </Button>
              <Button
                variant={btnDisable ? 'disabled' : 'primary'}
                type="submit"
                form={'companyInfo-form'}
                disabled={btnDisable}
              >
                Continue
              </Button>
            </>
          </Container>
        )}

        {authenticationStep === 7 && (
          <Container flex width="100%" height="70px" justify="space-between" padding="4.5">
            <>
              <Button variant="secondary" border onClick={() => setAuthenticationStep(authenticationStep - 1)}>
                Previous
              </Button>
              <Button
                variant={btnDisable ? 'disabled' : 'primary'}
                disabled={btnDisable}
                type="submit"
                form={'industry-form'}
              >
                Get Started
              </Button>
            </>
          </Container>
        )}
      </SignupDataContext.Provider>
    </Dialog>
  )
}
LoginDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
