import { Typography as MuiTypography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    color: props.color,
    width: props.width || 'auto',
  }),
}))
function Typography({ variant, component = 'span', children, color, width, className }) {
  const classes = useStyles({ color, width })
  return (
    <MuiTypography variant={variant} component={component} className={classNames(className || classes.root)}>
      {children}
    </MuiTypography>
  )
}

Typography.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  component: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
}

export default Typography
