import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import { BuyerZoneIcon, DropDownIcon, SellerZoneIcon } from 'assets/icons'
import { useLocation, useNavigate } from 'react-router-dom'
// import Button from 'components/common/Button'
import usePopover from 'components/common/popover/usePopover'
import NotificationsDialog from 'containers/notification/NotificationsDialog'
import Avatar from 'components/custom/Avatar'
import Bar from 'containers/app-bar/Bar'
import BreadCrumbs from 'components/common/BreadCrumbs'
import ContextMenu from 'components/custom/drop-downs/ContextMenu'
import ContextMenuItem from 'components/custom/drop-downs/ContextMenuItem'
import { useContext, useState } from 'react'
import { AuthContext } from 'utils/provider/AuthContext'
import useUserProfile from 'common-hooks/user-profile/useUserProfile'
// import buyerZoneIcon from 'assets/images/buyerZoneIcon.svg'
// import SellerZoneIcon from 'assets/images/SellerZoneIcon.svg'
import correctionIcon from 'assets/images/correctionIcon.svg'
import Typography from 'components/common/Typography'
import IconButton from 'components/common/IconButton'
import Image from 'components/common/Image'
import { constants } from 'utils/constants'
import { getFromLocalStorage, setToLocalStorage } from 'utils/Utils'

const useStyles = makeStyles((theme) => ({
  separator: {
    margin: theme.spacing(0, 3),
  },
  root: {
    alignItems: 'center',
    padding: 0,
  },
  button: {
    padding: 'unset',
    '&:hover': { backgroundColor: 'unset' },
  },
  badgeicon: {
    '&>:nth-child(2)': {
      position: 'absolute',
      fontSize: '12px',
      top: '10px',
      left: '7px',
      minWidth: 'unset',
      width: '18px',
      height: '18px',
      color: getColor('light-main'),
      backgroundColor: getColor('orange-main'),
      border: '1.08333px solid #FFFFFF;',
    },
  },
  deleteTxt: {
    color: getColor('func-alert'),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  zoneText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Lexend Deca',
    fontStyle: ' normal',
    fontWeight: ' 400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#36384C',
  },
  zoneRoot: {
    padding: '0 30px',
    borderLeft: '1px solid #F0F0F4',
    borderRight: '1px solid #F0F0F4',
    height: '60px',
    display: 'flex',
    cursor: 'pointer',
  },
  dropdownIcon: {
    marginLeft: '10px',
  },
  btn: {
    width: 'unset',
    height: 'unset',
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  switchText: {
    color: '#9799B5',
    marginBottom: '10px',
  },
  innerText: {},
  selerIcon: {
    marginRight: '5px',
  },
  iconSpace: {
    marginRight: '10px',
  },
  inactive: {
    margin: '0',
    display: 'flex',
    alignItems: 'center',
  },
  activeColor: {
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    color: '#2680D9',
    '& svg': {
      '& path': {
        fill: '#2680D9',
      },
    },
  },
}))

export default function AppBar({ drawerOpen }) {
  const location = useLocation()
  const navigate = useNavigate()

  const data =
    JSON.parse(getFromLocalStorage(constants.USER_ZONE)) === null
      ? 'sellerZone'
      : JSON.parse(getFromLocalStorage(constants.USER_ZONE))

  const [zone, setZone] = useState(data)

  const { dispatch } = useContext(AuthContext)
  const userRole = getFromLocalStorage(constants.ROLE)
  const headLinks = location.pathname.split('/')
  if (headLinks[1] === 'sa') headLinks.splice(0, 2)
  else headLinks.splice(0, 1)
  const { profileData } = useUserProfile()

  const lastName = capitalizeFirstLetter(profileData?.last_name)
  const firstName = capitalizeFirstLetter(profileData?.first_name)
  const fullName = capitalizeFirstLetter(`${firstName} ${lastName}`)
  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1)
  }

  const [open, anchor, onClose] = usePopover()

  const classes = useStyles({ drawerOpen })
  return (
    <>
      <Bar drawerOpen={drawerOpen}>
        <Container className={classes.root} justify={'space-between'} width={'100%'}>
          <BreadCrumbs list={headLinks} />
          <Container flex gap={'0 20px'}>
            {/* <Button className={classes.button} icon={<ChatIcon />} variant="secondary" />  */}
            {(userRole === 'customer' || userRole === 'super_admin') &&
            JSON.parse(getFromLocalStorage(constants.USER_ZONE) === null) ? (
              ''
            ) : (
              <Container className={classes.zoneRoot}>
                <ContextMenu
                  width={'205px'}
                  closeOnClick
                  trigger={
                    <IconButton className={classes.btn}>
                      {zone === 'sellerZone' && (
                        <Typography className={classes.zoneText} component={'p'}>
                          {/* <Image src={SellerZoneIcon} className={classes.selerIcon} />  */}
                          <SellerZoneIcon className={classes.selerIcon} />
                          Seller’s Zone <DropDownIcon className={classes.dropdownIcon} />
                        </Typography>
                      )}
                      {zone === 'buyerZone' && (
                        <Typography className={classes.zoneText} component={'p'}>
                          <BuyerZoneIcon className={classes.selerIcon} />
                          Buyer’s Zone <DropDownIcon className={classes.dropdownIcon} />
                        </Typography>
                      )}
                    </IconButton>
                  }
                >
                  <Typography
                    variant="body1"
                    color={getColor('grey-g65')}
                    component={'p'}
                    className={classes.switchText}
                  >
                    Switch to
                  </Typography>
                  <ContextMenuItem
                    onClick={() => {
                      setToLocalStorage(constants.USER_ZONE, JSON.stringify('sellerZone'))
                      setToLocalStorage(constants.ROLE, 'supplier')
                      setZone(JSON.parse(getFromLocalStorage(constants.USER_ZONE)))
                      navigate('/requestboard')
                      setTimeout(() => {
                        window.location.reload()
                      }, 500)
                    }}
                  >
                    <p className={zone === 'sellerZone' ? classes.activeColor : classes.inactive}>
                      <SellerZoneIcon className={classes.iconSpace} />
                      Seller’s Zone
                      {zone === 'sellerZone' && <Image src={correctionIcon} />}
                    </p>
                  </ContextMenuItem>
                  <ContextMenuItem
                    onClick={() => {
                      setToLocalStorage(constants.USER_ZONE, JSON.stringify('buyerZone'))
                      setToLocalStorage(constants.ROLE, 'customer')
                      setZone(JSON.parse(getFromLocalStorage(constants.USER_ZONE)))
                      navigate('/search')
                      setTimeout(() => {
                        window.location.reload()
                      }, 500)
                    }}
                  >
                    <p className={zone === 'buyerZone' ? classes.activeColor : classes.inactive}>
                      <BuyerZoneIcon className={classes.iconSpace} /> Buyer’s Zone
                      {zone === 'buyerZone' && <Image src={correctionIcon} />}
                    </p>
                  </ContextMenuItem>
                </ContextMenu>
              </Container>
            )}

            {/* <Button className={classes.button} onClick={onOpen} icon={<NotificationIcon />} variant="secondary" />
            <Button className={classes.button} icon={<HelpIcon />} variant="secondary" /> */}
            <ContextMenu
              width={'112px'}
              closeOnClick
              trigger={<Avatar customColor={'#fff'} fullName={fullName} avatarSize={'md'} />}
            >
              <ContextMenuItem
                onClick={() => {
                  if (userRole === 'super_admin') {
                    navigate('/profile')
                  } else {
                    navigate('/settings/profile')
                  }
                }}
              >
                Profile
              </ContextMenuItem>
              <ContextMenuItem
                className={classes.deleteTxt}
                onClick={() => {
                  dispatch({
                    type: 'LOGOUT',
                  })
                  navigate('/home')
                }}
              >
                Logout
              </ContextMenuItem>
            </ContextMenu>
          </Container>
        </Container>
      </Bar>
      <NotificationsDialog open={open} onClose={onClose} anchorEl={anchor} />
    </>
  )
}

AppBar.propTypes = {
  drawerOpen: PropTypes.bool,
}
