import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
// import johndearBrand from 'assets/images/JohndearBrandImage.svg'
// import johndearImage from 'assets/images/JohndearImage.svg'
import Placeholder from 'assets/images/Placeholder.png'
import React, { useEffect, useState } from 'react'
import useToggle from 'common-hooks/useToggle'
import ImageSliderContainer from 'components/custom/image-slider/ImageSliderContainer'
import Image from 'components/common/Image'
import classNames from 'classnames'
import { getColor } from 'theme/colors'
import { useNavigate, useParams } from 'react-router-dom'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import Button from 'components/common/Button'
import { CaretLeftIcon } from 'assets/icons'
import { Tabs } from 'components/common/Tabs'
import OfferCard from './CounterOfferCard'
import HowDoOfferWorkDialog from './HowDoOfferWorkDialog'
import SupplierActiveQuotePreview from './SupplierActiveQuotePreview'
import { getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  title: {},
  root: {
    width: '21%',
    padding: '30px ',
    borderRight: '1px solid #F0F0F4',
    height: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  activepageRightInner: {
    height: '72vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  mainFlex: {
    display: 'flex',
  },
  eqpimage: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
    width: '60px',
    height: '60px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),

    '& div': {
      width: '32.2%',
      height: '120px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',
    },
  },
  eqpimage2: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
    width: '60px',
    height: '60px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),
    borderLeft: '0',

    '& div': {
      width: '32.2%',
      height: '60px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',
    },
  },
  partsimages: {
    width: '32.2%',
    cursor: 'pointer',
    height: '120px',
    display: 'flex',
    justifyContent: ' center',
    alignItems: 'center',
    '& img': {
      width: '120px',
      height: '120px',
      border: '1px solid #F0F0F4',
    },
  },
  p8: {
    padding: '8px 0',
    color: '#36384C',
  },
  p10: {
    padding: '10px 0',
    color: '#36384C',
  },
  p2: {
    padding: '2px 0',
  },
  partImageGrid: {
    display: 'flex',
    flexFlow: 'wrap',
    marginBottom: '15px',
  },
  imageOverlay: (props) => ({
    position: 'relative',
    cursor: 'pointer',
    '&:before': {
      position: 'absolute',
      content: `"+${props?.partsImage?.length - 3}"`,
      top: '0',
      bottom: '0',
      fontWeight: ' 400',
      fontFamily: 'Lexend Deca',
      fontSize: ' 14px',
      left: '0',
      right: '0',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#13141bb3',
    },
  }),
  activePageBody: {
    background: '#fff',
  },
  roottitle: {
    // height: '86px',
    borderBottom: ' 1px solid #F0F0F4',
    padding: '28px 30px',

    position: 'fixed',
    width: '100%',
    background: '#fff',
    zIndex: '999',
  },
  eqpImages: {
    marginTop: '10px',
    marginBottom: '13px',
  },
  eqpDetails: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
    paddingBottom: '10px',
  },
  partDetail: {
    paddingBottom: '10px',
    display: 'flex',
  },
  activepageGrid: {
    display: 'flex',
    height: '98vh',
    paddingTop: '93px',
  },
  number: {
    width: '20px',
    height: '16px',
    borderRadius: '100px',
    background: '#2680D9',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
  iconWithLabel: {
    display: 'flex',
    gap: '0 5px',
    alignItems: 'center',
    '& p': {
      margin: '0',
    },
  },
  blueLink: {
    background: 'unset',
    padding: 'unset',
    color: '#2680D9',
    textDecoration: 'underline',
    hight: 'unset',
    minHeight: 'unset',
    borderRadius: 'unset',
    '&:hover': {
      background: 'unset',
    },
    '& span': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '300',
    },
  },
  activepageRight: {
    width: '79%',
    paddingLeft: '50px',
    paddingRight: '30px',
    paddingTop: '30px',
    paddingBottom: '30px',
    background: '#F8F9F9',
  },
  Tabs: {
    marginBottom: '25px',
  },
}))

export default function SupplierActiveQuoteDetails() {
  const params = useParams()
  const { supplierReqDetails } = useReqSupplierDetails(params?.id, params?.reqId)
  const partsImage = supplierReqDetails?.quote_assets
  const classes = useStyles({ partsImage })

  const [openImageDialog, toggleImageDialog] = useToggle(false)
  const [value, setValue] = useState(0)
  const [howdoWorkDialog, setHowDoWorkDialog] = useToggle(false)
  const navigate = useNavigate()
  const [imageIndex, setImageIndex] = useState()
  const [offerCount, setOfferCount] = useState(0)
  const offerTabs = [
    {
      id: '0',
      tabContent: 'Content 0',
      tabLabel: (
        <div className={classes.iconWithLabel}>
          <p>Offers</p>
          {offerCount === '1' && <p className={classes.number}>{offerCount}</p>}
        </div>
      ),
    },
    {
      id: '1',
      tabLabel: 'Quote',
      tabContent: 'Content 1',
    },
  ]
  if (offerCount === null) {
    offerTabs.shift()
    offerTabs.length = 1
  }
  const handleTabs = (event, newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    if (offerCount === null) {
      setValue(1)
    }
  }, [supplierReqDetails])
  function handleImage(index) {
    setImageIndex(index)
    if (imageIndex >= 0) {
      toggleImageDialog(true)
    }
  }

  return (
    <Container className={classes.activePageBody}>
      <Container className={classes.roottitle}>
        <Container flex gap={'0 10px'} justify="flex-start">
          <Button
            variant={'secondary'}
            border
            width={'36px'}
            icon={<CaretLeftIcon />}
            onClick={() => navigate('/quotes', { state: { status: 'active' } })}
          />

          <Typography variant="h1" color={getColor('grey-g25')}>
            {supplierReqDetails?.brand_name} {supplierReqDetails?.part_name} {supplierReqDetails?.part_number}
          </Typography>
        </Container>
      </Container>
      <Container className={classes.activepageGrid}>
        <Container className={classNames(classes.mainFlex, classes.root)} direction={'column'} gap={'10px 0'}>
          <Container className={classNames(classes.mainFlex, classes.title)} direction={'column'}>
            <Container>
              <Typography component="h1" variant="title" color={getColor('grey-g25')}>
                Buyer
              </Typography>
            </Container>
            <Container>
              <Typography component="p" variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                {supplierReqDetails?.buyer_full_name}
              </Typography>
            </Container>
          </Container>
          <Seperator />
          <Container>
            <Container className={classes.eqpImages}>
              <Typography component={'h1'} color={getColor('grey-g25')} variant={'title'}>
                Equipment Details
              </Typography>
            </Container>
            <Container flex justify="flex-start">
              <Image
                src={supplierReqDetails?.brand_cover_image || Placeholder}
                alt={'brand Image'}
                className={classes.eqpimage}
              />
              <Image
                src={supplierReqDetails?.equip_cover_image || Placeholder}
                alt={'brand Image'}
                className={classes.eqpimage2}
              />
            </Container>
            <Container className={classes.eqpDetails}>
              <Typography component={'p'} variant="body1" color={getColor('grey-g25')}>
                {supplierReqDetails?.brand_name && `${supplierReqDetails?.brand_name} `}
                {supplierReqDetails?.equip_name && `> ${supplierReqDetails?.equip_name}`}
              </Typography>
              <Container flex justify="space-between">
                <Typography component={'p'} variant="body1" color={getColor('grey-g25')}>
                  Model Number:
                </Typography>
                <Typography component={'p'} variant="body1" color={getColor('grey-g25')}>
                  {supplierReqDetails?.brand_model_number ? supplierReqDetails?.brand_model_number : '-'}
                </Typography>
              </Container>
              <Container flex justify="space-between">
                <Typography component={'p'} variant="body1" color={getColor('grey-g25')}>
                  Serial Number:
                </Typography>
                <Typography component={'p'} variant="body1" color={getColor('grey-g25')}>
                  {supplierReqDetails?.brand_serial_number ? supplierReqDetails?.brand_serial_number : '-'}
                </Typography>
              </Container>
            </Container>
          </Container>
          <Seperator />
          <Container className={classes.mainFlex} direction={'column'}>
            <Container>
              <Typography color={getColor('grey-g25')} className={classes.p8} component="h1" variant="title">
                Part Details
              </Typography>
            </Container>
            {supplierReqDetails?.quote_assets.length > 0 && (
              <Container className={classes.partImageGrid}>
                {supplierReqDetails?.quote_assets?.length !== 0 &&
                  supplierReqDetails?.quote_assets
                    ?.map((obj, index) => (
                      <Container
                        key={index}
                        className={classNames(
                          classes.partsimages,
                          index === 2 && supplierReqDetails?.quote_assets.length > 3 ? classes.imageOverlay : ''
                        )}
                        onClick={() => handleImage(index)}
                      >
                        <Image key={index} src={obj?.part_image} alt={'partImage'} />
                      </Container>
                    ))
                    .splice(0, 3)}
              </Container>
            )}

            <Container className={classes.partDetail} direction={'column'} gap={'10px 0'}>
              <Typography component={'p'} variant="body1" color={getColor('grey-g25')}>
                {supplierReqDetails?.part_details?.name_path}
              </Typography>
              <Container className={classes.mainFlex} justify={'space-between'}>
                <Typography component={'p'} variant="body1" color={getColor('grey-g25')}>
                  Part Number:
                </Typography>
                <Typography component={'p'} variant="body1" color={getColor('grey-g35')}>
                  {supplierReqDetails?.part_number ? supplierReqDetails?.part_number : '-'}
                </Typography>
              </Container>
              <Container className={classes.mainFlex} justify={'space-between'}>
                <Typography variant="body1" component={'p'} color={getColor('grey-g25')}>
                  Quantity Needed:
                </Typography>
                <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                  {supplierReqDetails?.quantity}
                </Typography>
              </Container>
            </Container>
          </Container>
          <Seperator />
          <Container className={classes.mainFlex} direction={'column'}>
            <Container display="flex" direction={'column'}>
              <Typography className={classes.typographycolor} variant="title">
                Shipping Address
              </Typography>
              {supplierReqDetails?.buyer_full_name && (
                <Typography className={classes.typographycolor} variant="body1">
                  {supplierReqDetails?.buyer_full_name}
                </Typography>
              )}
              {supplierReqDetails?.cmp_delivery_addr_company_name && (
                <Typography className={classes.typographycolor} variant="body1">
                  {supplierReqDetails?.cmp_delivery_addr_company_name}
                </Typography>
              )}
              {supplierReqDetails?.company_billing_addr && (
                <Typography className={classes.typographycolor} variant="body1">
                  {getWellKnownAddress(
                    supplierReqDetails?.company_billing_addr?.addr_line_1,
                    supplierReqDetails?.company_billing_addr?.addr_line_2
                  )}
                </Typography>
              )}
              {!supplierReqDetails?.company_billing_addr && (
                <Typography className={classes.typographycolor} variant="body1">
                  {getWellKnownAddress(
                    supplierReqDetails?.cmp_delivery_addr_addr_line_1,
                    supplierReqDetails?.cmp_delivery_addr_addr_line_2
                  )}
                </Typography>
              )}
              {supplierReqDetails?.cmp_delivery_addr_unit_number && (
                <Typography
                  variant="paragraph1"
                  color={getColor('grey-g25')}
                >{`Unit Number: ${supplierReqDetails?.cmp_delivery_addr_unit_number}`}</Typography>
              )}
              {supplierReqDetails?.cmp_delivery_addr_email && (
                <Typography
                  variant="paragraph1"
                  color={getColor('grey-g25')}
                >{`E: ${supplierReqDetails?.cmp_delivery_addr_email}`}</Typography>
              )}
              {supplierReqDetails?.cmp_delivery_addr_phone && (
                <Typography
                  variant="paragraph1"
                  color={getColor('grey-g25')}
                >{`P: ${supplierReqDetails?.cmp_delivery_addr_phone}`}</Typography>
              )}
            </Container>
            <Container display="flex" direction={'column'}>
              <Typography className={classes.typographycolor} variant="body1">
                Instructions
              </Typography>
              {supplierReqDetails?.cmp_delivery_addr_delivery_instructions && (
                <Typography>{supplierReqDetails?.cmp_delivery_addr_delivery_instructions}</Typography>
              )}
            </Container>
          </Container>
          <ImageSliderContainer
            open={openImageDialog}
            onclose={toggleImageDialog}
            imageData={supplierReqDetails?.quote_assets}
            index={imageIndex}
          />
        </Container>
        <Container className={classes.activepageRight}>
          <Container flex justify="space-between" className={classes.Tabs}>
            <Container>
              {offerCount === null ? (
                <Typography variant="body1" color="#4A4D68">
                  Quote
                </Typography>
              ) : (
                <Tabs value={value} onchange={handleTabs} array={offerTabs} />
              )}
            </Container>
            {offerCount === null ? (
              ''
            ) : (
              <Button className={classes.blueLink} onClick={setHowDoWorkDialog}>
                How do offers work?
              </Button>
            )}
          </Container>
          <Container className={classes.activepageRightInner}>
            <Container>{value === 0 && <OfferCard setOfferCount={setOfferCount} />}</Container>
            <Container>{value === 1 && <SupplierActiveQuotePreview />}</Container>
          </Container>
        </Container>
      </Container>
      <HowDoOfferWorkDialog onClose={setHowDoWorkDialog} open={howdoWorkDialog} />
    </Container>
  )
}
