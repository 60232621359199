export const tabs = [
  {
    id: '1',
    tabLabel: 'Buyers',
    tabContent: 'Content 1',
  },
  {
    id: '2',
    tabLabel: 'Sellers',
    tabContent: 'Content 2',
  },
]

export const tabsReport = [
  // {
  //   id: '1',
  //   tabLabel: 'Offers (Sellers)',
  //   tabContent: 'Content 1',
  // },
  // {
  //   id: '2',
  //   tabLabel: 'Offers (Buyer)',
  //   tabContent: 'Content 2',
  // },
  {
    id: '3',
    tabLabel: 'Products',
    tabContent: 'Content 3',
  },
]

export const tabsOrder = [
  {
    id: '1',
    tabLabel: 'Awaiting Shipment',
    tabContent: 'Content 1',
  },
  {
    id: '2',
    tabLabel: 'Completed',
    tabContent: 'Content 2',
  },
  {
    id: '3',
    tabLabel: 'Cancelled',
    tabContent: 'Content 3',
  },
]
export const userTabs = [
  {
    id: '1',
    tabLabel: 'Buyers',
    tabContent: 'Content 1',
  },
  {
    id: '2',
    tabLabel: 'Sellers',
    tabContent: 'Content 2',
  },
]

export const plans = [
  {
    value: 'free',
    label: 'Free',
  },
  { value: 'starter', label: 'Starter' },
  { value: 'Professional', label: 'Professional' },
  { value: 'extra', label: 'Extra' },
  { value: 'partsClub+', label: 'PartsClub+' },
]
