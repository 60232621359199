import { makeStyles } from '@mui/styles'
import useProfileSettingDetail from 'common-hooks/settings/profile/useProfileSettingDetail'
import useSubscriptionPaymentHistory from 'common-hooks/settings/subscription/useSubscriptionPaymentHistory'
import useSubscriptionPlans from 'common-hooks/settings/subscription/useSubscriptionPlans'
import Container from 'components/common/Container'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { Tabs } from 'components/common/Tabs'
import Typography from 'components/common/Typography'
import List from 'components/custom/list/List'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getColor } from 'theme/colors'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import PlanCard from './PlanCard'
import SubscriptionPlanHistory from './SubscriptionPlanHistory'

const useStyles = makeStyles(() => ({
  root: {
    // display: 'flex',
    // maxWidth: '1659px',
    width: '86%',
    background: '#F8F9F9',
    height: '94vh',
    // background: '#F8F9F9',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  headerDiv: {
    padding: '30px 0',
    width: '830px',
    margin: '0 auto',
  },
  plansTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '33px',
    paddingBottom: '25px',
  },
  tab: {
    marginTop: '23px',
  },
  planGrid: {
    display: 'flex',
    gap: '0 20px',
  },
  profileroot: {
    width: '100%',
    display: 'flex',
    background: '#fff',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  innerDiv: {
    width: '830px',
    margin: '0 auto',
  },
  tabs: {
    width: '260px',
    padding: '20px 10px 20px 10px',
    borderRight: `1px solid ${getColor('grey-g95')}`,
  },
  tabList: {
    padding: '10px 20px 10px 20px',
    color: '#36384c',
  },
  containers: {
    height: '94vh',
    background: '#F8F9F9',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  active: {
    color: getColor('func-info'),
    padding: '10px 20px 10px 20px',
  },
  questionLink: {
    color: ' #7D809B',
    fontWeight: ' 300',
    fontSize: ' 14px',
    marginTop: '20px',
    lineHeight: '20px',
    '& a': {
      color: ' #7D809B',
      textDecoration: 'underline',
    },
  },
  noLink: {
    padding: '10px 20px',
  },
}))
export default function SubscriptionSettings() {
  const classes = useStyles()

  const userRole = getFromLocalStorage(constants.ROLE)
  const { profileData } = useProfileSettingDetail()
  const [tab, setTab] = useState(userRole === 'customer' ? 1 : 0)
  const { planData, isLoading } = useSubscriptionPlans(
    userRole === 'customer' || tab === 1 ? 'buyer_plans' : 'seller_plans'
  )
  const { paymentHistory, isLoadingHistory } = useSubscriptionPaymentHistory()
  const navigate = useNavigate()
  const TabsList = [
    { tabIndex: 0, name: 'Seller Plans' },
    { tabIndex: 1, name: 'Buyer Plans' },
  ]
  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <Container className={classes.profileroot}>
      <Container className={classes.tabs}>
        <List
          title={'My Profile'}
          className={classes.tabList}
          type={'link'}
          onClick={() => {
            navigate('/settings/profile')
          }}
        />
        {profileData?.user_company ? (
          <List
            title={'Company Profile'}
            className={classes.tabList}
            type={'link'}
            onClick={() => navigate('/settings/company')}
          />
        ) : (
          <Container className={classes.noLink}>
            <Typography color="#9799B5">Company Profile</Typography>
          </Container>
        )}
        <List
          title={'Payment'}
          className={classes.tabList}
          type={'link'}
          onClick={() => {
            navigate('/settings/payment')
          }}
        />
        <List
          title={'Shipping'}
          className={classes.tabList}
          type={'link'}
          onClick={() => {
            navigate('/settings/shipping')
          }}
        />
        <List
          title={'Membership'}
          className={classes.active}
          type={'link'}
          onClick={() => {
            navigate('/settings/membership')
          }}
        />
        <List
          title={'Password'}
          className={classes.tabList}
          type={'link'}
          onClick={() => {
            navigate('/settings/security')
          }}
        />
      </Container>

      <Container className={classes.root}>
        <Container flex justify="space-between" className={classes.headerDiv}>
          <Container width="100%">
            <Typography variant="h1" color={getColor('grey-g25')}>
              Membership
            </Typography>

            {userRole === 'customer' ? (
              ''
            ) : (
              <Container className={classes.tab}>
                <Tabs value={tab} onchange={handleChange} array={TabsList} />
              </Container>
            )}
            <>
              {isLoadingHistory && <LoadingOverlay />}
              {paymentHistory?.invoice_history?.length > 0 && (
                <SubscriptionPlanHistory paymentHistory={paymentHistory} />
              )}
            </>
            {(!paymentHistory?.invoice_history || paymentHistory?.invoice_history?.length === 0) && (
              <Container className={classes.innerDiv}>
                <Container className={classes.plansTitle}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Plan
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g25')}>
                    Simple pricing. No hidden fees. Advanced features for your business.
                  </Typography>
                </Container>
                {isLoading && <LoadingOverlay />}
                {tab === 0 && (
                  <>
                    <Container className={classes.planGrid}>
                      {planData?.map((plan, index) => (
                        <PlanCard
                          key={index}
                          index={index}
                          id={plan?.plan_type}
                          name={plan?.plan_type}
                          price={plan?.price?.monthly}
                          request={plan?.plan_description}
                          benefit={plan?.benefit}
                          benefitTitle={plan?.benefit_title}
                          type={plan?.type}
                        />
                      ))}
                    </Container>
                  </>
                )}

                {planData && tab === 1 && (
                  <>
                    <Container className={classes.planGrid}>
                      {planData?.map((plan, index) => (
                        <PlanCard
                          key={index}
                          index={index}
                          id={plan?.plan_type}
                          name={plan?.plan_type}
                          price={plan?.price?.monthly}
                          request={plan?.plan_description}
                          benefit={plan?.benefit}
                          benefitTitle={plan?.benefit_title}
                          type={plan?.type}
                        />
                      ))}
                    </Container>
                  </>
                )}
                <Typography className={classes.questionLink} component="p">
                  Have questions about a plan? We’re happy to help. Email us{' '}
                  <a href="mailto:ellmoreparts@partsclub.us">sellmoreparts@partsclub.us</a>
                </Typography>
              </Container>
            )}

            {/* <SubscriptionPlanHistory paymentHistory={paymentHistory} /> */}
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
