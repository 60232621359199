/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import TreeView from '@mui/lab/TreeView'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { CaretRightIcon, CaretDownBlueIcon, BlackDot } from 'assets/icons'
import { Typography } from '@mui/material'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'
import typography from 'theme/typography'
import Seperator from 'components/common/Seperator'
import Checkbox from 'components/common/Checkbox'
import { useEffect, useState } from 'react'
import usePartsDetails from 'common-hooks/usePartsDetails'
import LoadingOverlay from 'components/common/LoadingOverlay'

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF;',
    border: `1px solid ${getColor('grey-g95')}`,
    borderTop: 'unset',
    overflowX: 'hidden',
  },
  treeViewTextfiled: {
    border: 0,
    borderLeft: `1px solid ${getColor('grey-g95')}`,
    borderRight: `1px solid ${getColor('grey-g95')}`,
    backgroundColor: 'transparent',
    margin: '2px 0',
    borderRadius: 0,
    '&:hover': {
      borderColor: getColor('grey-g95'),
    },
    '&:focus': {
      borderColor: getColor('grey-g95'),
    },
    fontWeight: typography.paragraph1.fontWeight,
    fontSize: typography.paragraph1.fontSize,
  },
}))
export default function AddPartsTreeView({ equipmentId, partsData, setPartsData, partId }) {
  const classes = useStyle()
  const [parts, setParts] = useState(partId ? [partId] : [])
  const { partsTree, isLoading, setSelectedNode, selectedNode, childs } = usePartsDetails(equipmentId, partId)

  // handle and collect data -- checkbox visibility -- in array
  let arr = []
  const handleCheck = (checked, id, name, parentId = null, parentName, children) => {
    const node = {
      id,
      name,
      parent_id: parentId,
      equip_id: equipmentId,
      count: 0,
    }
    arr.push(node)
    selectNestedParts(id, children, id)

    // step2
    cleanAfterSelectingNestedParts(checked, parentId)
  }

  const cleanAfterSelectingNestedParts = (checked, parentId) => {
    let results = []
    if (!checked) {
      partsData?.map((obj) => {
        if (obj.id === parentId) {
          obj.count += 1
        }
        return 0
      })
      results = partsData.filter(({ id: id1 }) => !arr.some(({ id: id2 }) => id2 === id1))
    } else {
      partsData?.map((obj) => {
        if (obj.id === parentId) {
          obj.count -= 1
        }
        return 0
      })
      results = [...partsData, ...arr]
    }
    results = results.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
    setPartsData(results)
    arr = []
  }

  const selectNestedParts = (id, items, pId = null) => {
    if (Array.isArray(items)) {
      items.map((child) => {
        const node = {
          id: child.id,
          name: child.name,
          parent_id: pId,
          equip_id: equipmentId,
          count: 0,
        }
        arr.push(node)
        if (child.children && child.children.length > 0) {
          selectNestedParts(child.id, child.children, child.id)
        }
        return 0
      })
    }
  }

  useEffect(() => {
    if (partsTree.length > 0) {
      setParts(partsTree)
    }
    if (selectedNode?.isSelected && childs?.length > 0) {
      selectNestedParts(null, childs, selectedNode?.nodeId)
      cleanAfterSelectingNestedParts(true)
    }
  }, [partsTree.length, isLoading])

  // const isSelectedNodeChecked = (id) => {
  //   let val = false
  //   partsData?.forEach((obj) => {
  //     if (obj.id === id) val = true
  //   })
  //   return val
  // }

  const getTreeItemsFromData = (treeItems, handleCheck, pId = null, pName) => {
    const isSelected = (id) => {
      let val = false
      partsData?.forEach((obj) => {
        if (obj.id === id) val = true
      })
      return val
    }
    const isCountNotMached = (id) => {
      let val = false
      partsData?.forEach((obj) => {
        if (obj.id === id && obj.count !== 0) val = true
      })
      return val
    }

    return treeItems?.map((treeItemData) => {
      let children
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children, handleCheck, treeItemData.id, treeItemData.name)
      } else if (treeItemData.children && treeItemData.children.length === 0) {
        children = [[]]
      }

      return (
        <>
          <StyledTreeItem
            disabled={!treeItemData?.children}
            onClick={() => {
              // if (!isSelected(pId)) {
              //   setPartsData((pre)=>[...pre,{id:pId,name:pName,count:0,}])
              // }
              handleCheck(true, treeItemData.id, treeItemData.name, pId, pName, treeItemData.children)
            }}
            sx={{ border: '1px solide' }}
            key={treeItemData.id}
            nodeId={treeItemData.id}
            labelText={treeItemData.name}
            labelInfo={
              Array.isArray(children) ? (
                <Checkbox
                  value={isSelected(treeItemData.id)}
                  indeterminate={isCountNotMached(treeItemData.id)}
                  onClick={(e) => {
                    handleCheck(e.target.checked, treeItemData.id, treeItemData.name, pId, pName, treeItemData.children)
                    e.stopPropagation()
                  }}
                />
              ) : (
                <Container flex gap="10px">
                  <Checkbox
                    value={isSelected(treeItemData.id)}
                    onClick={(e) => {
                      handleCheck(
                        e.target.checked,
                        treeItemData.id,
                        treeItemData.name,
                        pId,
                        pName,
                        treeItemData.children
                      )
                      e.stopPropagation()
                    }}
                  />
                </Container>
              )
            }
            children={children}
          />
        </>
      )
    })
  }

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <TreeView
        className={classes.root}
        defaultCollapseIcon={
          <span style={{ width: 30, marginTop: 6 }}>
            <CaretDownBlueIcon />
          </span>
        }
        defaultExpandIcon={
          <span style={{ width: 30, marginTop: 6 }}>
            <CaretRightIcon />
          </span>
        }
        defaultEndIcon={
          <span style={{ width: 30, marginTop: 6 }}>
            <BlackDot />
          </span>
        }
        sx={{ flexGrow: 1, overflowY: 'auto' }}
        onNodeSelect={(e, nodeId) =>
          setSelectedNode({
            nodeId,
            // isSelected: isSelectedNodeChecked(nodeId),
            isSelected: true,
          })
        }
      >
        {parts.length > 0 && getTreeItemsFromData(parts, handleCheck)}
      </TreeView>
    </>
  )
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: getColor('dark-d25'),
    backgroundColor: getColor('light-main'),
    padding: '0 20px',
    fontWeight: theme.typography.fontWeightMedium,
    height: '42px',
    border: `0px`,
    // borderBottom: 'unset',
    '&:hover': {
      backgroundColor: '#F0F0F4',
    },
  },
  [`& .${treeItemClasses.expanded}`]: {
    color: getColor('func-info'),
    backgroundColor: getColor('light-main'),
    fontWeight: typography.body1.fontWeight,
    fontSize: typography.body1.fontSize,
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
  },
  [`& .${treeItemClasses.disabled}`]: {
    opacity: '1 !important',
  },
}))

function StyledTreeItem(props) {
  const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, ...other } = props
  return (
    <>
      <Seperator key={1} width="200%" margin={'0px 0px 0px -200px'} />
      <StyledTreeItemRoot
        label={
          <Container flex>
            <Typography variant="body1" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </Container>
        }
        {...other}
      />
    </>
  )
}
AddPartsTreeView.propTypes = {
  equipmentId: PropTypes.string,
  partId: PropTypes.string,
  partsData: PropTypes.array,
  setPartsData: PropTypes.func,
}
StyledTreeItem.propTypes = {
  bgColor: PropTypes.func,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.any,
  labelText: PropTypes.string.isRequired,
}
