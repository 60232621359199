import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import { capitalizeFirstLetter } from 'utils/Utils'
import { getColor } from 'theme/colors'
import { CaretRightIcon } from 'assets/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import { constants } from 'utils/constants'
import useQuoteDetails from 'common-hooks/buyer/quotes-details/useQuoteDetails'
import Link from './Link'

const useStyle = makeStyles(() => ({
  linkItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& span:first-child': {
      paddingLeft: '0',
    },
  },
  link: {
    padding: '5px 10px',
    backgroundColor: 'transparent',

    color: getColor('grey-g35'),
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}))

export default function BreadCrumbs({ list }) {
  const classes = useStyle()
  const params = useParams()
  const navigate = useNavigate()

  // For active quote details breadcrub
  const location = useLocation()
  const url = location.pathname
  const { quotesDetailDataList } = useQuoteDetails(params.id, url)
  if (url.includes('/quotes/active/')) {
    const quoteName =
      quotesDetailDataList?.product_title && !quotesDetailDataList?.part_details
        ? `${quotesDetailDataList?.product_title}`.replace('null', '').replace('undefined', '')
        : `${quotesDetailDataList?.brand_name}  ${quotesDetailDataList?.user_part} ${quotesDetailDataList?.part_number}`
            .replace('null', '')
            .replace('undefined', '')
    list.length = 2
    if (!quoteName?.includes('undefined')) {
      list.push(`${quoteName.replace('null', '').replace('undefined', '').substring(0, 20)}...`)
      list.length = 3
    }
  }
  // ====================End================
  if (list && list.includes('requestboard')) {
    return 'Requests'
  }
  if (params.id && params.suppliername) {
    list.splice(1, 2, params.suppliername.replace(/%20/g, ' '))
  }
  if (params.id && params.ordertype) {
    list.splice(2, 2, `Order #: ${params.orderid.substring(0, 13)}...`)
    list.length = 3
  }
  if (params.id && params.ordertype === 'AwaitingShipment') {
    list.splice(1, 1, `Awaiting Shipment`)
    list.length = 3
  }
  return (
    <Container flex direction="row" justify="flex-start">
      {Object.values(list).map((link, index) => (
        <Container className={classes.linkItem} key={index}>
          <Link
            className={classes.link}
            to={'#'}
            onClick={() => {
              if (location.pathname?.includes('/sa/company')) {
                sessionStorage.clear()
                window.location.reload()
              }
              if (list.length > 1 && index === 0) {
                navigate(-1)
              }
            }}
          >
            <Typography variant="title">{capitalizeFirstLetter(link)}</Typography>
          </Link>
          {list.length !== index + 1 && <CaretRightIcon />}
        </Container>
      ))}
    </Container>
  )
}
BreadCrumbs.propTypes = {
  list: PropTypes.array,
}
