import { setUpdateProfile } from 'common-api/user-profile/getUserProfile'
import useToastify from 'common-hooks/useToastify'

export default function useUpdateProfile() {
  const { toastMessage } = useToastify()
  const updateProfile = async (data, imgdata) => {
    const formdata = new FormData()
    formdata.append('assets', imgdata)
    formdata.append('document', data)
    try {
      const response = await setUpdateProfile(formdata)
      toastMessage('success', 'Profile updated successfully')
      return response
    } catch {
      return false
    }
  }
  return { updateProfile }
}
