import propTypes from 'prop-types'
// import React, { useContext, useEffect } from 'react'
import Button from 'components/common/Button'
// import Checkbox from 'components/common/Checkbox'
import Container from 'components/common/Container'
// import Tooltip from 'components/common/Tooltip'
// import { InfoSmallIcon } from 'assets/icons'
import DialogFooter from 'components/custom/dialog/DialogFooter'
// import { useForm, useWatch } from 'react-hook-form'
// import { BuyerQuoteContext } from './BuyerQuotesUtils'

export default function CreateQuoteDialogFooter({ onClose, currentStep, nextBtn, prevFormStep }) {
  // const { BuyerQueoteInformation, onSaveBuyerQuoteInformaton } = useContext(BuyerQuoteContext)
  // const { control } = useForm({
  //   defaultValues: { ...BuyerQueoteInformation, autoInvite: true },
  // })

  // const [autoInvite] = useWatch({
  //   control,
  //   name: ['autoInvite'],
  // })

  // useEffect(() => {
  //   onSaveBuyerQuoteInformaton({ ...BuyerQueoteInformation, autoInvite })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [autoInvite])

  return (
    <DialogFooter>
      <Container flex direction="row" justify="space-between" alignItems="center">
        {currentStep === 3 && (
          <Button onClick={onClose} variant="secondary" border>
            Cancel
          </Button>
        )}
        {currentStep === 2 && (
          <Button onClick={prevFormStep} type="button" variant="secondary" border>
            Previous
          </Button>
        )}

        <Container flex justify={'flex-end'} gap={'0 10px'}>
          {/* <span>Step {currentStep + 1} of 3</span> */}
          {/* {currentStep === 2 && (
            <Container flex justify="center" gap={'0 5px'}>
              <Controller
                name={'autoInvite'}
                control={control}
                render={({ field: { name, value } }) => (
                  <Checkbox
                    name={name}
                    value={value}
                    label="Auto-Invite Suppliers"
                    onChange={(e) => {
                      setValue(name, e.target.checked)
                    }}
                    id={'autoInvite'}
                  />
                )}
              />

              <Tooltip
                placement="top"
                title={
                  'PartsClub will share your request with suppliers and invite suppliers to quote you based on the details of your parts request'
                }
                maxWidth={'451px'}
              >
                <InfoSmallIcon />
              </Tooltip>
            </Container>
          )} */}
          <Container flex gap={'0 10px'}>
            {currentStep === 3 && (
              <Button onClick={prevFormStep} type="button" variant="secondary" border>
                Previous
              </Button>
            )}
            {currentStep === 3 && (
              <Button type="submit" form={'summary-form'} disabled={nextBtn} variant="positive" width={128}>
                Get Quotes
              </Button>
            )}
            {currentStep === 2 && (
              <Button disabled={nextBtn} type="submit" form={`hook-form-${currentStep}`}>
                Review
              </Button>
            )}
          </Container>
        </Container>
      </Container>
    </DialogFooter>
  )
}
CreateQuoteDialogFooter.propTypes = {
  currentStep: propTypes.number,
  onClose: propTypes.func,
  nextBtn: propTypes.bool,
  prevFormStep: propTypes.func,
}

/* <Button type="submit" form={'hook-form'} onClick={nextFormStep}> */
