import { useCallback, useState } from 'react'

export default function usePopover() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorWidth, setAnchorWidth] = useState(null)

  const onOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget)
    setAnchorWidth(event.currentTarget && event.currentTarget.offsetWidth)
  }, [])

  const onClose = useCallback(() => {
    setAnchorEl(null)
    setTimeout(() => {
      document.body.removeAttribute('style')
    }, 1)
  }, [])

  const open = !!anchorEl
  return [open, anchorEl, onOpen, onClose, anchorWidth]
}
