/* eslint-disable camelcase */
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import { Controller, useForm } from 'react-hook-form'
import GooglePlaces from 'components/custom/GooglePlaces'
import RadioGroup from 'components/common/radio/RadioGroup'
import Radio from 'components/common/radio/Radio'
import { useEffect, useState } from 'react'
import TextField from 'components/common/text/TextField'
import Typography from 'components/common/Typography'
import TextArea from 'components/common/text/TextArea'
import Checkbox from 'components/common/Checkbox'
import useShippingSettings from 'common-hooks/settings/shipping/useShippingSettings'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getFormError } from 'utils/form/form'
import useToastify from 'common-hooks/useToastify'
import { getWellKnownAddress } from 'utils/Utils'
import LabelTextField from 'components/common/text/LabelTextField'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: '690px',
    overflowY: 'scroll',
  },
  labelTextField: {
    width: '100px',
  },
  group: {
    display: 'flex',
    gap: '0 16px',
  },
  radiogrp: {
    display: 'flex',
    gap: '0 26px',
    height: '20px',
    // flexDirection: 'row',
  },
  pb10: {
    paddingBottom: '10px',
  },
  billing: {
    fontWeight: '700',
    color: getColor('grey-g25'),
    paddingTop: '5px',
  },
  checkBox: {
    height: '20px',
  },
})

const defaultValues = {
  type: 'business',
  full_name: undefined,
  company_name: undefined,
  email: undefined,
  phone: undefined,
  location: undefined,
  unit_number: undefined,
  delivery_instructions: undefined,
  forklift_available: true,
  dock_available: true,
  addr_line_1: undefined,
  addr_line_2: undefined,
  city: undefined,
  state: undefined,
  country: undefined,
  zipcode: undefined,
  prefix: '1',
}
const schema = yup
  .object({
    full_name: yup.string().required('Full name is required.'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    phone: yup
      .string()
      .nullable(false)
      .matches(/^\d{10}$/, 'Please enter the valid mobile number')
      .required(),
  })
  .required()

const ShippingAddressDialog = ({ open, onClose, editAddressData, setEditAddress }) => {
  const classes = useStyles()
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
    register,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { addNewAddress, editAddress } = useShippingSettings()
  const [radioValue, setRadioValue] = useState('business')
  const editId = editAddressData?.id
  const { toastMessage } = useToastify()

  const onSubmit = (data) => {
    if (!getValues()?.addr_line_1) {
      toastMessage('error', 'Location is required')
      return
    }
    if (getValues()?.type === 'business' && !getValues()?.company_name) {
      toastMessage('error', 'Company name is required')
      return
    }
    if (data?.prefix === '' || data?.prefix === undefined) {
      toastMessage('error', 'Country code is required')
      // eslint-disable-next-line consistent-return
      return 0
    }
    if (editId) editAddress(data, handleOnClose)
    else addNewAddress(data, handleOnClose)
  }

  const handleOnClose = () => {
    setEditAddress(undefined)
    reset()
    onClose()
  }

  const setAddressValues = (addr, addr_line_1, addr_line_2) => {
    const loc = formatAddressOfGAPI(addr, addr_line_1, addr_line_2, null)
    setValue('addr_line_1', addr_line_1)
    setValue('addr_line_2', addr_line_2)
    setValue('city', loc?.city)
    setValue('state', loc?.state)
    setValue('country', loc?.country)
    setValue('zipcode', loc?.zipcode)
    setValue('city_abbrv', loc?.city_abbrv)
    setValue('state_abbrv', loc?.state_abbrv)
    setValue('country_abbrv', loc?.country_abbrv)
  }

  useEffect(() => {
    if (editId) {
      Object.keys(editAddressData).map((key) => {
        // if (!['is_default', 'city_abbrv', 'state_abbrv', 'country_abbrv', 'business_name'].includes(key))
        // if (!['is_default'].includes(key)) setValue(key, editAddressData[key])
        if (key === 'phone' && editAddressData[key]) {
          const split = editAddressData[key]?.split('-')
          setValue('prefix', editAddressData[key]?.includes('+') ? split?.[0]?.replace('+', '') : '1')
          setValue('phone', editAddressData[key]?.includes('+') ? split?.[1] : editAddressData[key])
          return
        }
        setValue(key, editAddressData[key])
        // eslint-disable-next-line consistent-return
        return 0
      })
      setRadioValue(editAddressData?.type)
    } else {
      reset()
      setRadioValue('business')
      setValue('type', 'business')
    }
  }, [editAddressData, editId, reset, setValue])

  useEffect(() => {
    if (radioValue === 'residence') setValue('company_name', undefined)
  }, [radioValue, setValue])

  return (
    <Container>
      <Dialog open={open} size={'sm'} maxHeight="100%">
        <DialogHead title={'Shipping Address'} onClose={handleOnClose} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.dialogbody} direction={'column'} gap={'10px 0'}>
            <RadioGroup
              className={classes.radiogrp}
              onChange={(e) => {
                setRadioValue(e.target.value)
                setValue('type', e.target.value)
              }}
              value={radioValue}
            >
              <Container className={classes.radiogrp}>
                <Radio name={'business'} label={'Business'} value={'business'} />
                <Radio name={'residence'} label={'Residence'} value={'residence'} />
              </Container>
            </RadioGroup>

            <>
              <Controller
                name={'full_name'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={name}
                    name={name}
                    label={'Full Name*'}
                    placeholder={'e.g. John Doe'}
                    value={value}
                    type="text"
                    onChange={onChange}
                    error={getFormError(errors, name)}
                  />
                )}
              />
              {radioValue === 'business' && (
                <Controller
                  name={'company_name'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      id={name}
                      name={name}
                      value={value}
                      label={'Company Name*'}
                      placeholder={'e.g. PartsClub'}
                      type="text"
                      onChange={onChange}
                      error={getFormError(errors, name)}
                    />
                  )}
                />
              )}
            </>
            <Controller
              name={'email'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={name}
                  name={name}
                  value={value}
                  label={'Email*'}
                  placeholder={'john.d@partsclub.us'}
                  type="email"
                  onChange={onChange}
                  error={getFormError(errors, name)}
                />
              )}
            />
            <Container>
              <Typography variant="paragraph2" color={getColor('grey-g35')}>
                Phone Number*
              </Typography>
              <Container display="flex" gap="5px">
                <Controller
                  name={'prefix'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <LabelTextField
                      id={name}
                      type={'number'}
                      labelText={'+'}
                      className={classes.labelTextField}
                      placeholder={'1'}
                      onChange={onChange}
                      name={name}
                      value={value}
                    />
                  )}
                />
                <TextField
                  id={'phone'}
                  type={'number'}
                  control={control}
                  {...register('phone', { required: true })}
                  error={getFormError(errors, 'phone')}
                  placeholder="235 567 2355"
                />
              </Container>
            </Container>

            <Controller
              name={'location'}
              control={control}
              render={({ field: { name } }) => (
                <GooglePlaces
                  id={name}
                  name={name}
                  label="Location*"
                  spacing={3}
                  placeholder={'e.g. 4517 Washington Ave'}
                  onClearInput={() => setAddressValues(null, null, null)}
                  // value={value || getWellKnownAddress(getValues()?.addr_line_1, getValues()?.addr_line_2)}
                  value={
                    getValues()?.addr_line_1
                      ? getWellKnownAddress(getValues()?.addr_line_1, getValues()?.addr_line_2)
                      : ''
                  }
                  onSelect={(loc) => {
                    setAddressValues(loc.addr, loc.addr_line_1, loc.addr_line_2)
                  }}
                />
              )}
            />
            <Controller
              name={'unit_number'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={name}
                  name={name}
                  value={value}
                  label={'Unit Number'}
                  placeholder={'e.g. Unit 36A'}
                  // type="number"
                  onChange={onChange}
                />
              )}
            />
            <Typography variant="paragraph2">Delivery Instructions</Typography>
            <Controller
              name={'delivery_instructions'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextArea rows={3} id={name} name={name} value={value} onChange={onChange} placeholder="Paragraph" />
              )}
            />
            {radioValue === 'business' && (
              <Container display="flex" gap={'20px'} direction="column">
                <Controller
                  name={'forklift_available'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Checkbox
                      className={classes.checkBox}
                      name={name}
                      id={name}
                      value={value}
                      label={'Forklift available to unload delivery driver'}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name={'dock_available'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Checkbox
                      className={classes.checkBox}
                      name={name}
                      id={name}
                      value={value}
                      label={'Dock available to unload delivery driver'}
                      onChange={onChange}
                    />
                  )}
                />
              </Container>
            )}
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button width={70} type="submit">
                Apply
              </Button>
            </Container>
          </DialogFooter>
        </form>
      </Dialog>
    </Container>
  )
}
ShippingAddressDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editAddressData: PropTypes.object || undefined,
  setEditAddress: PropTypes.func,
}

export default ShippingAddressDialog
