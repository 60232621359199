import { makeStyles } from '@mui/styles'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
// import ContextMenu from 'components/custom/drop-downs/ContextMenu'
// import ContextMenuItem from 'components/custom/drop-downs/ContextMenuItem'
import Avatar from 'assets/images/Avatar.svg'
import { AddIcon, DeletePlainWhite, EditIcon, RedDeleteIcon, Upload } from 'assets/icons'
// import HeaderPlaceHolder from 'assets/images/HeaderPlaceHolder.png'
import companyHeaderPlaceholder from 'assets/images/companyHeaderPlaceholder.png'
import ProfileExpPlaceholder from 'assets/images/ProfileExpPlaceholder.png'
import TextField from 'components/common/text/TextField'
import { getFormError } from 'utils/form/form'
import TextArea from 'components/common/text/TextArea'
import Seperator from 'components/common/Seperator'
import useToggle from 'common-hooks/useToggle'
import EmailField from 'components/common/text/EmailField'
import useProfileSettings from 'common-hooks/settings/profile/useProfileSettings'
import useProfileSettingDetail from 'common-hooks/settings/profile/useProfileSettingDetail'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { DATE_MONTH_YEAR, DATE_YEAR, formatDate } from 'utils/dateUtils'
import List from 'components/custom/list/List'
import { getColor } from 'theme/colors'
import { useNavigate } from 'react-router-dom'
import LabelTextField from 'components/common/text/LabelTextField'
import ExperinceDialog from './ExperinceDialog'
import EducationDialog from './EducationDialog'
import IconButton from 'components/common/IconButton'
import useToastify from 'common-hooks/useToastify'
import ImageFileUpload from 'components/ImageFileUpload'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '30px 0',
    maxWidth: '830px',
    margin: '0 auto',
  },
  profileroot: {
    width: '100%',
    display: 'flex',
    background: '#fff',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabs: {
    width: '260px',
    padding: '20px 10px 20px 10px',
    borderRight: `1px solid ${getColor('grey-g95')}`,
  },
  tabList: {
    padding: '10px 20px 10px 20px',
    color: '#36384c',
  },
  containers: {
    height: '94vh',
    backgroundColor: '#F8F9F9',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  active: {
    color: getColor('func-info'),
    padding: '10px 20px 10px 20px',
  },
  headerDiv: {
    padding: '30px',
  },
  headImgBox: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '247px',
  },
  imgContainer1: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    // marginTop: '30px',
  },
  imageInfo: {
    height: '170px',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  logoimageInfo: {},
  imgContainer: {
    height: '170px',
    position: 'relative',
    // marginTop: '30px',
    '&:hover .logo-hover': {
      display: 'flex',
    },
    '& .logo-hover': {
      display: 'none',
      position: 'absolute',
      backgroundColor: '#000c',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      cursor: 'pointer',

      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .delete-btn': {
      background: 'unset',
      position: 'absolute',
      top: '0',
      right: '0',
      '&:hover': {
        background: 'unset',
      },
    },
  },
  profileImg: {
    height: '120px',
    width: '120px',
    position: 'relative',
    // border: '3px solid #fff',
    // borderRadius: ' 4px',
    top: '-60px',
    left: '20px',
    '&:hover .logo-hover': {
      display: 'flex',
    },
    '& .logo-hover': {
      height: '120px',
      width: '120px',

      display: 'none',
      position: 'absolute',
      backgroundColor: '#000c',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      cursor: 'pointer',

      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .delete-btn': {
      background: 'unset',
      position: 'absolute',
      top: '0',
      right: '0',
      '&:hover': {
        background: 'unset',
      },
    },
  },
  profileInfo: {
    height: '120px',
    width: '120px',
    // border: '3px solid #fff',
    justifyContent: 'center',
    // borderRadius: '4px',
    // boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    alignItems: 'center',
  },
  inputImgStyle: {
    display: 'none',
  },
  iconbtn: {
    padding: '0',
    minHeight: 'unset',
    backgroundColor: 'unset',
    right: '20px',
    position: 'absolute',
    top: '37px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  profileIconBtn: {
    padding: '0',
    minHeight: 'unset',
    backgroundColor: 'unset',
    right: '6px',
    position: 'absolute',
    top: '35px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  profilelists: {
    position: 'relative',
  },
  toggle: {
    top: '220px',
    position: 'absolute',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  profileDetails: { display: 'flex', flexDirection: 'column', gap: '20px', padding: '0 20px 20px 20px' },
  nameFiled: { width: '250px' },
  emailFiled: {
    width: '385px',
  },
  phoneFlied: {
    width: '250px',
  },
  title: {
    marginTop: '20px',
  },
  iconButton: {
    padding: '0',
    minHeight: 'unset',
    height: '30px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  imgDiv: {
    height: '90px',
    width: '90px',
    border: '1px solid #F0F0F4',
  },
  linkdIn: {
    width: '520px',
  },
  profileBody: {
    height: '85vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  personalDetailRoot: {
    background: '#fff',
    border: ' 1px solid #F0F0F4',
    borderRadius: ' 4px',
    // marginTop: '30px',
    // padding: '0 20px 30px 20px',
  },
  workexp: {
    background: '#fff',
    border: ' 1px solid #F0F0F4',
    borderRadius: ' 4px',
    padding: '20px',
  },
  separtor: {
    margin: '20px 0',
  },
  iceBreakerSub: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '10px 20px',
  },
  titleRoot: {
    marginBottom: '20px',
  },
  // inputText: {
  //   '& textarea': {
  //     height: '125px!important',
  //   },
  // },
  imgtitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  expRoot: {
    '& .hover-delete': {
      display: 'none',
    },
    '&:hover .hover-delete': {
      display: 'block',
    },
  },
  eduRoot: {
    '& .hover-delete': {
      display: 'none',
    },
    '&:hover .hover-delete': {
      display: 'block',
    },
  },
  delteBtn: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
  },
  labelTextField: {
    width: '100px',
  },
  noLink: {
    padding: '10px 20px',
  },
}))

export default function ProfileSettings() {
  const classes = useStyles()
  const imageUploader = useRef()
  const profileUploader = useRef()
  const { toastMessage } = useToastify()

  const [headerImage, setHeaderImage] = useState()
  const [profileImage, setProfileImage] = useState()
  const [openEducationDialog, toggleEducationDialog] = useToggle(false)
  const [educations, setEducations] = useState([])
  const [editEducationsData, setEditEducationsData] = useState()
  const [openExprinceDialog, toggleExprinceDialog] = useToggle(false)
  const [experiences, setExperiences] = useState([])
  const [editExperienceData, setEditExperienceData] = useState()
  const navigate = useNavigate()
  const [hobbies, setHobbies] = useState({ sport: '' })
  const [, refreshHobbies] = useState(false)
  const { profileData, isLoading, mutate } = useProfileSettingDetail()
  const { editProfileData, verifyProfileEmail, updateProfileEmail, profileImagesDelete } = useProfileSettings()

  const schema = yup.object({
    first_name: yup.string().required('First name is required.'),
    last_name: yup.string().required('Last name is required.'),
    phone_number: yup
      .string()
      .nullable(false)
      .matches(/^\d{10}$/, 'Please enter the valid mobile number')
      .required(),
  })
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { prefix: '1' },
  })

  const setHobbieValue = (key, value) => {
    setHobbies((pre) => {
      pre[key] = value
      return pre
    })
    refreshHobbies((pre) => !pre)
  }

  const onSubmit = (data) => {
    data.hobbies = hobbies
    data.expc = experiences
    data.eds = educations
    editProfileData(data, headerImage, profileImage, mutate)
  }
  useEffect(() => {
    if (profileData) {
      Object.keys(profileData).map((key) => {
        if (key === 'phone_number' && profileData[key]) {
          const split = profileData[key]?.split('-')
          setValue('prefix', profileData[key]?.includes('+') ? split?.[0]?.replace('+', '') : '1')
          setValue('phone_number', profileData[key]?.includes('+') ? split?.[1] : profileData[key])
          return
        }
        if (key === 'user_hobbies' && profileData?.user_hobbies?.length > 0) {
          setHobbies({
            id: profileData?.user_hobbies?.[0]?.id,
            ...(profileData?.user_hobbies?.[0]?.sport !== null && { sport: profileData?.user_hobbies?.[0]?.sport }),
            ...(profileData?.user_hobbies?.[0]?.famous_person !== null && {
              famous_person: profileData?.user_hobbies?.[0]?.famous_person,
            }),
            ...(profileData?.user_hobbies?.[0]?.team !== null && { team: profileData?.user_hobbies?.[0]?.team }),
            ...(profileData?.user_hobbies?.[0]?.ideal_vacation !== null && {
              ideal_vacation: profileData?.user_hobbies?.[0]?.ideal_vacation,
            }),
          })
        } else if (key === 'user_eds' && profileData?.user_eds?.length > 0) {
          setEducations(profileData?.user_eds)
        } else if (key === 'user_expc' && profileData?.user_expc?.length > 0) {
          setExperiences(profileData?.user_expc)
        } else if (key === 'linkedin_url') {
          setValue('linkedin_url', profileData[key]?.replace('https://null', '')?.replace('https://', ''))
        } else {
          setValue(key, profileData[key])
        }
        // eslint-disable-next-line consistent-return
        return 0
      })
      setHeaderImage(profileData?.img_header ? profileData?.img_header : '')
      setProfileImage(profileData?.logo ? profileData?.logo : '')
    }
  }, [profileData])

  const handleBannerFileChange = (name, newFiles) => {
    const image = new Image()
    image.addEventListener('load', () => {
      if (image.width === 1060 && image.height === 170) {
        setHeaderImage(newFiles)
      } else {
        toastMessage('error', 'Upload image in 1060px x 170px resolution')
      }
    })
    image.src = URL.createObjectURL(newFiles)
  }
  const handleProfileFileChange = (name, newFiles) => {
    const image = new Image()
    image.addEventListener('load', () => {
      if (image.width === 512 && image.height === 512) {
        setProfileImage(newFiles)
      } else {
        toastMessage('error', 'Upload image in 512px x 512px resolution')
      }
    })
    image.src = URL.createObjectURL(newFiles)
  }

  return (
    <>
      <Container className={classes.profileroot}>
        <Container className={classes.tabs}>
          <List
            title={'My Profile'}
            className={classes.active}
            type={'link'}
            onClick={() => {
              navigate('/settings/profile')
            }}
          />
          {profileData?.user_company ? (
            <List
              title={'Company Profile'}
              className={classes.tabList}
              type={'link'}
              onClick={() => navigate('/settings/company')}
            />
          ) : (
            <Container className={classes.noLink}>
              <Typography color="#9799B5">Company Profile</Typography>
            </Container>
          )}
          <List
            title={'Payment'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/payment')
            }}
          />
          <List
            title={'Shipping'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/shipping')
            }}
          />
          <List
            title={'Membership'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/membership')
            }}
          />
          <List
            title={'Password'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/security')
            }}
          />
        </Container>
        <Container width="100%" className={classes.containers}>
          {isLoading && <LoadingOverlay />}
          <Container className={classes.root}>
            {/* Profile Image Section */}
            <form onSubmit={handleSubmit(onSubmit)} id={'profile-form'}>
              <Container className={classes.titleRoot} flex justify="space-between" width="100%">
                <Container>
                  <Typography variant="h1" color={getColor('grey-g25')}>
                    My Profile
                  </Typography>
                </Container>
                <Container>
                  <Button type="submit" form={'profile-form'}>
                    Save Changes
                  </Button>
                </Container>
              </Container>
              <Container className={classes.profileBody}>
                <Container className={classes.personalDetailRoot}>
                  <Container>
                    <Container className={classes.headImgBox}>
                      <Container className={classes.imgContainer}>
                        <input
                          onChange={(e) => handleBannerFileChange('bannerImage', e.target.files[0])}
                          // onChange={(e) => setHeaderImage(e.target.files[0])}
                          ref={imageUploader}
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          className={classes.inputImgStyle}
                        />
                        <Container className={classes.imageInfo}>
                          {headerImage ? (
                            <ImageFileUpload
                              alt={'user-header'}
                              src={typeof headerImage === 'string' ? headerImage : URL.createObjectURL(headerImage)}
                              className={classes.imageInfo}
                            />
                          ) : (
                            <ImageFileUpload
                              alt={'user-header'}
                              src={companyHeaderPlaceholder}
                              className={classes.imageInfo}
                            />
                          )}
                        </Container>

                        <Container className="logo-hover">
                          {!headerImage ? (
                            <Container>
                              <Upload onClick={() => imageUploader.current.click()} className={classes.uploadBtn} />
                            </Container>
                          ) : (
                            <>
                              {' '}
                              <IconButton
                                className={'delete-btn'}
                                onClick={() => {
                                  setHeaderImage(null)
                                  profileImagesDelete({ type: 'cover' })
                                }}
                              >
                                <DeletePlainWhite />
                              </IconButton>
                              <Container>
                                <Upload onClick={() => imageUploader.current.click()} className={classes.uploadBtn} />
                              </Container>
                            </>
                          )}
                        </Container>
                        <Container className={classes.imgtitle}>
                          <Typography variant="overline" color="#C3C5D5">
                            Logo Size should be 512 x 512, Header image size should be 1060 x 170. Supported files are
                            .png and .jpg
                          </Typography>
                        </Container>
                      </Container>

                      <Container className={classes.profileImg}>
                        <input
                          // onChange={(e) => setProfileImage(e.target.files[0])}
                          onChange={(e) => handleProfileFileChange('profile', e.target.files[0])}
                          ref={profileUploader}
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          className={classes.inputImgStyle}
                        />
                        <Container className={classes.logoimageInfo}>
                          {profileImage ? (
                            <ImageFileUpload
                              alt={'user-profile'}
                              src={typeof profileImage === 'string' ? profileImage : URL.createObjectURL(profileImage)}
                              className={classes.profileInfo}
                            />
                          ) : (
                            <ImageFileUpload alt={'user-profile'} src={Avatar} className={classes.profileInfo} />
                          )}
                        </Container>

                        <Container className="logo-hover">
                          {!profileImage ? (
                            <Container>
                              <Upload onClick={() => profileUploader.current.click()} className={classes.uploadBtn} />
                            </Container>
                          ) : (
                            <>
                              <IconButton
                                className={'delete-btn'}
                                onClick={() => {
                                  setProfileImage(null)
                                  profileImagesDelete({ type: 'logo' })
                                }}
                              >
                                <DeletePlainWhite />
                              </IconButton>
                              <Container>
                                <Upload onClick={() => profileUploader.current.click()} className={classes.uploadBtn} />
                              </Container>
                            </>
                          )}
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                  <Container className={classes.profileDetails}>
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Contact Info
                    </Typography>
                    <Container flex justify="space-between">
                      <Controller
                        name={'first_name'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <TextField
                            label={'First Name*'}
                            name={name}
                            value={value}
                            placeholder={'e.g. John'}
                            type="text"
                            width="182px"
                            onChange={onChange}
                            error={getFormError(errors, name)}
                            // className={classes.nameFiled}
                          />
                        )}
                      />
                      <Controller
                        name={'last_name'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <TextField
                            label={'Last Name*'}
                            name={name}
                            value={value}
                            placeholder={'e.g. Doe'}
                            width="182px"
                            type="text"
                            onChange={onChange}
                            error={getFormError(errors, name)}
                            // className={classes.nameFiled}
                          />
                        )}
                      />
                      <Controller
                        name={'email'}
                        control={control}
                        render={({ field: { name, value } }) => (
                          <EmailField
                            name={name}
                            value={value}
                            label={'Email*'}
                            placeholder={'john.d@partsclub.com'}
                            disabled
                            width="385px"
                            className={classes.emailFiled}
                            verifyEmail={verifyProfileEmail}
                            updateEmail={updateProfileEmail}
                          />
                        )}
                      />
                    </Container>
                    <Container flex justify="space-between" width="654px">
                      <Container flex gap="2px">
                        <Controller
                          name={'prefix'}
                          control={control}
                          render={({ field: { name, value, onChange } }) => (
                            <LabelTextField
                              id={name}
                              type={'number'}
                              labelText={'+'}
                              className={classes.labelTextField}
                              placeholder={'1'}
                              onChange={onChange}
                              name={name}
                              value={value}
                            />
                          )}
                        />
                        <TextField
                          id={'phone_number'}
                          type={'number'}
                          control={control}
                          {...register('phone_number', { required: true })}
                          error={getFormError(errors, 'phone_number')}
                          placeholder="235 567 2355"
                        />
                      </Container>
                    </Container>
                    <Container className={classes.inputText}>
                      <Typography variant="paragraph2" color={getColor('grey-g25')}>
                        About
                      </Typography>
                      <Controller
                        name={'about'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <TextArea
                            id={'tagline'}
                            value={value}
                            name={name}
                            onChange={onChange}
                            placeholder={'Tell members a little about yourself'}
                            rows={3}
                          />
                        )}
                      />
                    </Container>
                  </Container>
                </Container>

                <Container className={classes.workexp} display={'flex'} direction={'column'} gap={'10px 0'}>
                  <Container flex justify="space-between">
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Work Experience
                    </Typography>
                    <Button
                      variant="secondary"
                      icon={<AddIcon />}
                      className={classes.iconButton}
                      onClick={() => {
                        setEditExperienceData(null)
                        toggleExprinceDialog()
                      }}
                    />
                  </Container>

                  {experiences?.map((data, index) => (
                    <>
                      <Container
                        className={classes.expRoot}
                        key={index}
                        display={'flex'}
                        alignItems="normal"
                        justify="space-between"
                      >
                        <Container display={'flex'} justify="flex-start" alignItems="normal" gap={'15px'}>
                          <Container>
                            <ImageFileUpload
                              src={data?.company_profile || ProfileExpPlaceholder}
                              className={classes.imgDiv}
                            />
                          </Container>
                          <Container display={'flex'} direction={'column'} gap={'6px'}>
                            <Container display={'flex'} direction={'column'}>
                              <Typography variant="title" color={getColor('grey-g25')}>
                                {data?.title}
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                {data?.company_name?.label || data?.company_name}
                              </Typography>
                            </Container>
                            <Container display={'flex'} direction={'column'}>
                              <Typography variant="paragraph1" color={getColor('grey-g25')}>
                                {formatDate(data.start_date, DATE_MONTH_YEAR)} -{' '}
                                {data.currently_working ? 'Present' : formatDate(data.end_date, DATE_MONTH_YEAR)}
                              </Typography>
                              <Typography variant="paragraph1" color={getColor('grey-g25')}>
                                {data?.city}, {data?.state}
                              </Typography>
                            </Container>
                          </Container>
                        </Container>
                        <Container className="hover-delete">
                          <Button
                            variant="secondary"
                            icon={<EditIcon />}
                            className={classes.iconButton}
                            onClick={() => {
                              setEditExperienceData(data)
                              toggleExprinceDialog()
                            }}
                          />
                          <Button
                            className={classes.delteBtn}
                            onClick={() => setExperiences(experiences?.filter((obj, id) => id !== index))}
                            width={'36px'}
                            icon={<RedDeleteIcon />}
                          />
                        </Container>
                      </Container>
                      {index !== experiences?.length - 1 && (
                        <Container className={classes.separtor}>
                          <Seperator />
                        </Container>
                      )}
                    </>
                  ))}
                </Container>

                <Container className={classes.workexp} display={'flex'} direction={'column'} gap={'10px 0'}>
                  <Container flex justify="space-between">
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Education
                    </Typography>
                    <Button
                      variant="secondary"
                      icon={<AddIcon />}
                      className={classes.iconButton}
                      onClick={() => {
                        setEditEducationsData(null)
                        toggleEducationDialog()
                      }}
                    />
                  </Container>

                  {educations?.map((data, index) => (
                    <>
                      <Container
                        className={classes.eduRoot}
                        key={index}
                        display={'flex'}
                        alignItems="normal"
                        justify="space-between"
                      >
                        <Container display={'flex'} justify="flex-start" alignItems="normal" gap={'15px'}>
                          <Container display={'flex'} direction={'column'} gap={'6px'}>
                            <Container display={'flex'} direction={'column'}>
                              <Typography variant="title" color={getColor('grey-g25')}>
                                {data.school}
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                {data.degree} - {data.field_study}
                              </Typography>
                            </Container>
                            <Container display={'flex'} direction={'column'}>
                              <Typography variant="paragraph1" color={getColor('grey-g25')}>
                                {formatDate(data.start_date, DATE_YEAR)} - {formatDate(data.end_date, DATE_YEAR)}
                              </Typography>
                              <Typography variant="paragraph1" color={getColor('grey-g25')}>
                                {data.city}, {data.state_abbrv}
                              </Typography>
                            </Container>
                          </Container>
                        </Container>

                        <Container className="hover-delete">
                          <Button
                            variant="secondary"
                            icon={<EditIcon />}
                            className={classes.iconButton}
                            onClick={() => {
                              setEditEducationsData(data)
                              toggleEducationDialog()
                            }}
                          />
                          <Button
                            className={classes.delteBtn}
                            width={'36px'}
                            icon={<RedDeleteIcon />}
                            onClick={() => setEducations(educations?.filter((obj, id) => id !== index))}
                          />
                        </Container>
                      </Container>
                      {index !== educations?.length - 1 && (
                        <Container className={classes.separtor}>
                          <Seperator />
                        </Container>
                      )}
                    </>
                  ))}
                </Container>

                <Container className={classes.workexp} display={'flex'} direction={'column'} gap={'10px 0'}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Ice Breakers
                  </Typography>
                  <Container className={classes.iceBreakerSub}>
                    <TextField
                      label={'Favorite Sport '}
                      name={'sport'}
                      value={hobbies?.sport}
                      placeholder={'Sport'}
                      type="text"
                      width="48%"
                      onChange={(e) => setHobbieValue('sport', e.target.value)}
                      className={classes.hobbies}
                    />

                    <TextField
                      label={'Favorite Team '}
                      name={'team'}
                      value={hobbies?.team}
                      placeholder={'Team'}
                      type="text"
                      width="48%"
                      onChange={(e) => setHobbieValue('team', e.target.value)}
                      className={classes.hobbies}
                    />

                    <TextField
                      label={'Most Famous Person I’ve Met '}
                      name={'famous_person'}
                      value={hobbies?.famous_person}
                      placeholder={'Famous Person'}
                      type="text"
                      width="48%"
                      onChange={(e) => setHobbieValue('famous_person', e.target.value)}
                      className={classes.hobbies}
                    />

                    <TextField
                      label={'Ideal Vacation Spot '}
                      name={'ideal_vacation'}
                      value={hobbies?.ideal_vacation}
                      placeholder={'Ideal Vacation'}
                      type="text"
                      width="48%"
                      onChange={(e) => setHobbieValue('ideal_vacation', e.target.value)}
                      className={classes.hobbies}
                    />
                  </Container>
                </Container>

                <Container className={classes.workexp} display={'flex'} direction={'column'} gap={'10px 0'}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Social
                  </Typography>
                  <Container flex justify="space-between">
                    <Container>
                      <Typography variant="paragraph2" color={getColor('grey-g25')}>
                        LinkedIn
                      </Typography>
                      <Controller
                        name={'linkedin_url'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <LabelTextField
                            name={name}
                            value={value}
                            // type={'number'}
                            labelText={'https://'}
                            lablePosition={'left'}
                            width={'380px'}
                            placeholder={'www.linkedin.com/parts-clubs-1939473br8'}
                            onChange={onChange}
                            error={getFormError(errors, name)}
                            // className={classes.nameFiled}
                          />
                        )}
                      />
                    </Container>
                    {/* <Controller
                    name={'linkedin_url'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <TextField
                        label={'LinkedIn'}
                        name={name}
                        value={value}
                        placeholder={'Type'}
                        type="text"
                        onChange={onChange}
                        className={classes.linkdIn}
                      />
                    )}
                  /> */}
                  </Container>
                </Container>
              </Container>
            </form>
            <ExperinceDialog
              onclose={toggleExprinceDialog}
              open={openExprinceDialog}
              setExperiences={setExperiences}
              editExperienceData={editExperienceData}
              setEditExperienceData={setEditExperienceData}
            />
            <EducationDialog
              open={openEducationDialog}
              onclose={toggleEducationDialog}
              setEducations={setEducations}
              editEducationsData={editEducationsData}
              setEditEducationsData={setEditEducationsData}
            />
          </Container>
        </Container>
      </Container>
    </>
  )
}
