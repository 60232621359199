import Button from 'components/common/Button'
import Link from 'components/common/Link'
import TextField from 'components/common/text/TextField'
import useAuth from 'common-hooks/useAuth'
import { getFormError } from 'utils/form/form'
import React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  divider: {
    // marginBottom: theme.spacing(7.5),
    marginBottom: '20px',
    backgroundColor: getColor('grey-g95'),
  },
  link: {
    color: getColor('grey-g35'),
  },
}))

const schema = yup
  .object({
    emailcode: yup.number().nullable(false).typeError('Code must be a number').required('Code is Required.'),
  })
  .required()
export default function VerifyCode({ onStepChange }) {
  const classes = useStyles()
  const { isLoading, verifyCode } = useAuth()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const onSubmit = async (data) => {
    const isValidCode = await verifyCode(data.emailcode.toString())
    if (isValidCode) {
      onStepChange(4)
    }
    setError('emailcode', { type: 'custom', message: 'Provided Code is Invalid' })
  }
  const handleClick = () => {
    onStepChange(1)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Divider light className={classes.divider} />

        <Container flex alignItems="center" direction={'column'} width={'100%'} gap={'20px'}>
          <Container flex direction="column">
            <Typography variant="title" color={getColor('grey-g25')}>
              Account Verification
            </Typography>
            <Typography variant="paragraph1" color={getColor('grey-g35')}>
              Check your inbox for a verification code
            </Typography>
          </Container>

          <TextField
            id="login-verifyCode"
            name={'code'}
            label={'Code'}
            placeholder={'000000'}
            {...register('emailcode', { required: true })}
            error={getFormError(errors, 'emailcode')}
          />
          <Container width="100%" flex direction={'column'} gap={'10px 0'}>
            <Button loading={isLoading} variant="secondary" border type="submit" width={'100%'}>
              Create New Account
            </Button>
            <Container flex direction="column" gap={'10px 0'}>
              <Typography variant="body2" color={getColor('grey-g65')}>
                Didn’t receive code? &nbsp;
                <Link className={classes.link} variant="body2" underline to="">
                  Resend
                </Link>
              </Typography>
              <Link onClick={handleClick} className={classes.link} variant="body2" underline to="">
                Back to Login
              </Link>
            </Container>
          </Container>
        </Container>
      </Container>
    </form>
  )
}
VerifyCode.propTypes = {
  onStepChange: PropTypes.func,
}
