import PropTypes from 'prop-types'
import { IconButton as MuiIconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { getColor } from 'theme/colors'
import { forwardRef } from 'react'
import Link from './Link'

const useStyles = makeStyles({
  root: {
    padding: 0,
    backgroundColor: (props) => getBgColor(props.variant),
    '&:hover': {
      backgroundColor: getColor('grey-g85'),
    },
    width: (props) => getSize(props.size),
    height: (props) => getSize(props.size),
  },
})

const IconButton = forwardRef(
  ({ size = 'md', variant = 'contained', children, className, to, tooltip, ...props }, ref) => {
    const classes = useStyles({ size, variant })

    const button = (
      <MuiIconButton ref={ref} className={classNames(classes.root, className)} {...props}>
        {children}
      </MuiIconButton>
    )

    const wrappedComponent = tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button

    if (to) {
      return (
        <Link to={to} asWrapper>
          {wrappedComponent}
        </Link>
      )
    }

    return wrappedComponent
  }
)

IconButton.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['contained', 'transparent']),
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  tooltip: PropTypes.string,
}

IconButton.displayName = 'IconButton'

export default IconButton

function getSize(size) {
  switch (size) {
    case 'xs':
      return 15
    case 'sm':
      return 20
    case 'md':
      return 30
    case 'lg':
      return 40
    default:
      return 30
  }
}

function getBgColor(variant) {
  switch (variant) {
    case 'contained':
      return getColor('grey-g15')
    case 'transparent':
      return 'transparent'
    default:
      return ''
  }
}
