import Api from 'services/Api'

export const fetchLoginUser = async (email, password) => ({ email, password })

export async function checkEmail(email) {
  const response = await Api.get(`/services/verify-email/${email}`)
  return response.data.data
}
export async function checkCode(code) {
  const response = await Api.post(`/services/verify-create-acct-code`, code)
  return response.data.data
}
export async function login(data) {
  const response = await Api.post(`/services/verify-password`, data)
  return response.data.data
}
export async function getCompanyList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function forgotPasswordEmail(email) {
  const response = await Api.post(`/services/forgot-password`, email)
  return response.data.data
}
export async function userSignUp(data) {
  const response = await Api.post(`/services/create-user-acct`, data)
  return response.data.data
}
export async function getIndustryData(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function userResetPassword(data) {
  const response = await Api.post('/services/reset-password', data)
  return response.data.data
}
