// import Button from 'components/common/Button'
import Container from 'components/common/Container'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import { useContext, useEffect } from 'react'

import { BuyerQuoteContext } from '../BuyerQuotesUtils'
import { getFlag, getWellKnownAddress } from 'utils/Utils'
import PropTypes from 'prop-types'
import useUserProfile from 'common-hooks/user-profile/useUserProfile'
import IconButton from 'components/common/IconButton'
import { EditIcon } from 'assets/icons'
// import useToggle from 'common-hooks/useToggle'

const useStyle = makeStyles(() => ({
  mailEditBtn: {
    backgroundColor: 'unset',
    padding: '0',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  flagClass: {
    padding: '3px 5px',
    position: 'absolute',
  },
  addressDiv: {
    width: '480px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '15px',
    position: 'relative',
  },
}))
export default function QuoteShipping({ setUserAdress, setProfileData, setShippinAddressDialog }) {
  const classes = useStyle()
  const { BuyerQueoteInformation } = useContext(BuyerQuoteContext)
  const { profileData } = useUserProfile()
  useEffect(() => {
    if (profileData) {
      setUserAdress(profileData)
    }
    setProfileData(profileData)
  }, [profileData])
  return (
    <Container>
      <Container flex justify="space-between">
        <Container flex>
          <Typography variant="title" color={getColor('grey-g25')}>
            Shipping
          </Typography>
        </Container>
        <Container className={classes.addressDiv}>
          {BuyerQueoteInformation?.company_billing_addr ? (
            <>
              <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                {getWellKnownAddress(
                  BuyerQueoteInformation?.company_billing_addr?.addr_line_1,
                  BuyerQueoteInformation?.company_billing_addr?.addr_line_2
                )}
                <span className={classes.flagClass}>
                  {getFlag(BuyerQueoteInformation?.company_billing_addr?.country || profileData?.user_address?.country)}
                </span>
              </Typography>
            </>
          ) : (
            <>
              <Container className={classes?.addressDiv}>
                <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                  {getWellKnownAddress(profileData?.user_address?.addr_line_1, profileData?.user_address?.addr_line_2)}
                  <span className={classes.flagClass}>{getFlag(profileData?.user_address?.country)}</span>
                </Typography>
              </Container>
            </>
          )}
          <IconButton className={classes.mailEditBtn} onClick={() => setShippinAddressDialog()}>
            <EditIcon />
          </IconButton>
        </Container>
      </Container>
    </Container>
  )
}
QuoteShipping.propTypes = {
  setUserAdress: PropTypes.func,
  setProfileData: PropTypes.func,
  setShippinAddressDialog: PropTypes.func,
}
