import { makeStyles } from '@mui/styles'
import Button from 'components/common/Button'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import classNames from 'classnames'
import Container from 'components/common/Container'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    width: props.width || '100%',
    borderTop: `1px solid ${getColor('grey-g95')}`,
    background: '#ffffff',
    alignItems: 'center',
    padding: '0.938rem 1.25rem',
    backgroundColor: getColor('light'),
    ...getRootStyle(props),
  }),
}))
export default function DrawerFooter({ children, variant, className, width, onClick }) {
  const classes = useStyles()
  return (
    <Container className={classNames(classes.root, className)} onClick={onClick}>
      <Button variant={variant} width={width}>
        {children}
      </Button>
    </Container>
  )
}

DrawerFooter.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

function getRootStyle(props) {
  return {
    ...(props.justify === 'space-between' && {
      justifyContent: 'space-between',
      display: 'flex',
    }),
    ...(props.justify === 'center' && {
      justifyContent: 'center',
      display: 'flex',
    }),
    ...(props.justify === 'flex-end' && {
      justifyContent: 'flex-end',
      display: 'flex',
    }),
  }
}
