import Api from 'services/Api'

export async function createSupplierQuotePost(data) {
  const response = await Api.post(`/quote-request`, data)
  return response.data.data
}

export async function rejectQuote(data) {
  const response = await Api.patch(`/quote`, data)
  return response.data.data
}
