import { getSubscriptionPlans } from 'common-api/settings/settings'
import useSWR from 'swr'

export default function useSubscriptionPlans(type) {
  const { data, mutate } = useSWR(`/subscription/plan/${type}`, getSubscriptionPlans)
  const paidPlans = []
  data?.map((obj, index) => {
    if (index !== 0) {
      paidPlans.push(obj)
    }
    return 0
  })

  return {
    planData: data || [],
    paidPlans,
    isLoading: !data,
    mutate,
  }
}
