import Container from 'components/common/Container'
import { React, useEffect, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import SearchField from 'components/common/search/SearchField'
import classNames from 'classnames'
import { getColor } from 'theme/colors'
import ProgressBar from 'components/common/ProgressBar'
import useInviteSuppliers from 'common-hooks/buyer/quotes-details/useInviteSuppliers'
import { useParams } from 'react-router-dom'
import useInvitedSuppliers from 'common-hooks/buyer/quotes-details/useInvitedSuppliers'
import InvitedSupplierCard from './InvitedSupplierCard'

const useStyles = makeStyles({
  dialogbody: {
    display: 'flex',
    width: '100%',
    padding: '20px',
    backgroundColor: getColor('system-bg'),
  },
  flex: {
    display: 'flex',
    '& #searchField': {
      backgroundColor: '#fff!important',
    },
  },
  rating: {
    marginRight: '10px',
  },
  captionColor: {
    color: getColor('grey-g35'),
  },
  color: {
    color: getColor('grey-g25'),
  },
  upgradeColor: {
    fontWeight: '300',
    '& a': {
      color: getColor('func-info'),
    },
  },
  alignItems: {
    alignItems: 'center',
  },
  supplierWidth: {
    width: '59%',
  },
  cardGrid: {
    display: 'flex',
    gap: '18px',
    flexFlow: 'wrap',
  },
  supplierGrid: {
    height: '500px',
    display: 'flex',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  premiumSupplier: {
    color: getColor('func-positive'),
    paddingBottom: '10px',
  },
  matchedSuppiler: {
    color: getColor('grey-g25'),
    paddingBottom: '10px',
  },
  curcularProgress: {
    position: 'unset',
    backgroundColor: 'unset',
    '& span': {
      width: '36px',
      height: '36px',
      // border: '4px solid #AD1F36',

      // borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
      '& .CircularProgressbar-trail': {
        color: 'light',
      },
      '& .MuiCircularProgress-svg': {
        color: '#AD1F36',
      },
    },
  },
  upgradeSuppiler: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    '& input': {
      backgroundColor: '#fff!important',
    },
  },
})

const InviteMoreSuppliersDialog = ({ open, onclose }) => {
  const classes = useStyles()
  const params = useParams()
  const [activeSuppliers, setActiveSuppliers] = useState([])
  const { data, inviteSuppliers, onSearch, search } = useInviteSuppliers()
  const { mutate } = useInvitedSuppliers(params.id)
  const premiumFlag = false
  const matched = []
  const premium = []

  const addActiveSuppliers = (name, id) => {
    const data = activeSuppliers?.find((item) => item?.id === id)
    const active = typeof data === 'object'

    if (active) {
      const exisit = activeSuppliers.filter((item) => item.id !== id)
      setActiveSuppliers(exisit)
    } else if (!active) {
      if (premiumFlag) {
        setActiveSuppliers([...activeSuppliers, { name, id }])
      } else if (!premiumFlag && activeSuppliers?.length < 3) {
        setActiveSuppliers([...activeSuppliers, { name, id }])
      }
    }
  }
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (activeSuppliers.length >= 0) setProgress(Math.round((activeSuppliers.length / 3) * 100 || 0))
  }, [activeSuppliers])

  const handleClick = () => {
    inviteSuppliers(activeSuppliers, params.id, mutate)
    onclose()
  }
  data?.map((obj) => {
    if (obj?.is_premium && obj?.quote_req) {
      premium.push(obj)
    }
    if (!obj?.is_premium && obj?.quote_req) {
      matched.push(obj)
    }
    // else {
    //   matched.push(obj)
    // }
    return 0
  })

  return (
    <Container>
      <Dialog open={open} size={'4.5xl'}>
        <DialogHead title={'Invite More Suppliers'} onClose={onclose} />
        <Container className={classes.dialogbody} direction={'column'} gap={'20px 0'}>
          <Container className={classNames(classes.flex, classes.alignItems)}>
            {premiumFlag ? (
              <Container className={classes.upgradeSuppiler}>
                <SearchField
                  id={'searchField'}
                  placeholder="Search Suppliers "
                  width={260}
                  onSearch={(e) => {
                    onSearch(e)
                  }}
                  value={search}
                />
              </Container>
            ) : (
              <>
                <Container className={classes.rating}>
                  <ProgressBar
                    variant="determinate"
                    value={progress}
                    thickness={4}
                    valueLabel={activeSuppliers.length}
                  />
                </Container>
                <Container className={classNames(classes.flex, classes.supplierWidth)} direction={'column'}>
                  <Typography variant="title" className={classes.color}>
                    Suppliers
                  </Typography>
                  {/* <Typography variant="paragraph1" className={classes.color}>
                    Want to add even more suppliers?{' '}
                    <Link to="#" className={classes.upgradeColor}>
                      Upgrade Account
                    </Link>
                  </Typography> */}
                </Container>
                <Container>
                  <SearchField
                    id={'searchField'}
                    placeholder="Search Suppliers "
                    width={260}
                    onSearch={(e) => {
                      onSearch(e)
                    }}
                    value={search}
                  />
                </Container>
              </>
            )}
          </Container>
          <Container className={classes.supplierGrid} direction={'column'} gap={'20px 0'}>
            <Container>
              {premium?.length !== 0 && (
                <>
                  <Typography variant="title" component="p" className={classes.premiumSupplier}>
                    Premium Supplier
                  </Typography>
                  <Container className={classes.cardGrid}>
                    {premium.map((obj, index) => (
                      <InvitedSupplierCard
                        key={index}
                        suppilerData={obj}
                        actives={activeSuppliers}
                        addActiveSuppliers={addActiveSuppliers}
                      />
                    ))}
                  </Container>
                </>
              )}
            </Container>
            <Container>
              {matched?.length !== 0 && (
                <>
                  <Typography className={classes.matchedSuppiler} component="p" variant="title">
                    Matched Suppliers
                  </Typography>
                  <Container className={classes.cardGrid}>
                    <Container className={classes.cardGrid}>
                      {matched.map((data, index) => (
                        <InvitedSupplierCard
                          key={index}
                          suppilerData={{ ...data }}
                          actives={activeSuppliers}
                          addActiveSuppliers={addActiveSuppliers}
                        />
                      ))}
                    </Container>
                  </Container>
                </>
              )}
            </Container>
          </Container>
        </Container>

        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={onclose}>
              Cancel
            </Button>
            <Button width={72} onClick={handleClick}>
              Invite
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </Container>
  )
}
InviteMoreSuppliersDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
}

export default InviteMoreSuppliersDialog
