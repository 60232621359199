import { Card, CardContent, CardMedia } from '@mui/material'
import Typography from 'components/common/Typography'
import Tooltip from 'components/common/Tooltip'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'
import HeaderPlaceHolder from 'assets/images/CardHeaderPlaceHolder.png'
import propTypes from 'prop-types'
import Chip from 'components/common/Chip'
import { useNavigate } from 'react-router-dom'
import { EarlyBadge, Verfied } from 'assets/icons'
import { getFlag, getShortAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '276px',
    width: '270px',

    borderRadius: '0px',
    transition: 'boxShadow 2s ease-out 100ms',
    border: `1px solid ${getColor('grey-g95')}`,
    '&:hover': {
      boxShadow: '0px 16px 14px rgba(0, 0, 0, 0.06),0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
    position: 'relative',
  },
  banner: {
    backgroundColor: getColor('grey-g95'),
    width: '100%',
    height: '80px',
    // position: 'relative',
  },
  logo: {
    backgroundColor: getColor('light-main'),
    width: '80px',
    height: '80px',
    top: '40px',
    left: '20px',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
    position: 'absolute',
  },
  info: {
    padding: '20px',
    paddingTop: '16px',
  },
  linkButton: {
    height: '40px',
    width: '40px',
  },
  chipContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '13px',
    right: '10px',
  },
  premimumChip: {
    height: '20px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    color: '#13141B',
    borderRadius: '100px',
    '& div': {
      color: '#13141B',
      fontSize: '11px',
    },
  },
  specializeChip: {
    color: getColor('grey-g25'),
    background: '#F0F0F4',
    borderRadius: ' 100px',
    fontWeight: ' 400',
    fontSize: ' 11px',
    display: 'flex',
    alignItems: 'center',
  },
  chipMain: {
    paddingTop: '4px',
    '& p': {
      margin: '0',
    },
  },
  verifyMain: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '20px',
    paddingTop: '10px',
    height: '34px',
  },
  paragraph: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: getColor('grey-g65'),
    height: '35px',
  },
  flagDiv: {
    display: 'inline-block',
    height: '15px',
  },
  addDiv: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    flexFlow: 'wrap',
    '& p': {
      display: 'inline-block',
    },
  },
  headingName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
export default function BuyerSupplierCard({ supplierList }) {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <>
      {supplierList?.map((data, index) => {
        const supplierName = `${data?.name}`
        return (
          <Card
            key={index}
            className={classes.root}
            elevation={0}
            // onClick={() => navigate('/suppliers/suppliersdetails')}
            onClick={() => navigate(`/suppliers/${supplierName.replace(/%20/g, ' ')}/${data?.id}`)}
          >
            <CardMedia
              id="banner"
              className={classes.banner}
              component="img"
              image={data?.imgheader ? data?.imgheader : HeaderPlaceHolder}
              onError={(e) => {
                e.target.src = 'https://via.placeholder.com/150'
              }}
            />
            <Container className={classes.chipContainer}>
              {data?.is_premium && <Chip label={'Premium'} className={classes.premimumChip} />}
            </Container>
            <CardMedia
              id="logo"
              className={classes.logo}
              component="img"
              image={data?.logo}
              onError={(e) => {
                e.target.src = 'https://via.placeholder.com/150'
              }}
            />
            <Container className={classes.verifyMain}>
              {data?.is_verified && <Verfied />}
              {data?.early_adopt && (
                <Tooltip title={'Early Adopt'} placement={'right-start'}>
                  <EarlyBadge />
                  {/* <Image src={earlyBadge} /> */}
                </Tooltip>
              )}
            </Container>
            <CardContent className={classes.info}>
              <Container direction="column" gap="10px" justify="flex-start">
                <Container>
                  <Typography
                    variant="title"
                    color={getColor('grey-g25')}
                    component="h5"
                    className={classes.headingName}
                  >
                    {data?.name}
                  </Typography>
                  <Container className={classes.addDiv}>
                    <Typography
                      variant="paragraph1"
                      color={getColor('grey-g35')}
                      component="p"
                      className={classes.headingName}
                    >
                      {getShortAddress(data?.city, data?.state, data?.country, data?.country_abbrv)}
                      {/* {`${data?.city}, `}
                      {data?.state} */}
                    </Typography>
                    <Container className={classes.flagDiv}>{getFlag(data?.country)}</Container>
                  </Container>
                </Container>
                <Typography className={classes.paragraph} variant="body2" component="p">
                  {data?.tagline}
                </Typography>

                <Container flex direction="row" gap="10px" justify="flex-start" className={classes.chipMain}>
                  {/* <Chip label={data.specialization} className={classes.specializeChip} />
                <Chip label="+1" className={classes.specializeChip} /> */}
                  {data?.industries?.length && (
                    <Container flex direction="row" gap="10px" justify="flex-start">
                      {data?.industries.map((ind) => ind?.name && <Chip label={ind?.name} />)[0]}
                      {/* <Chip label={`+ ${data.industries.length - 1}`} /> */}
                      {data?.industries?.length - 1 === 0 ? (
                        ''
                      ) : (
                        <Tooltip placement={'right-start'} title={data?.industries?.map((ind) => ind?.name)}>
                          <p>
                            {' '}
                            <Chip label={`+ ${data?.industries?.length - 1}`} />
                          </p>
                        </Tooltip>
                      )}
                    </Container>
                  )}
                </Container>
              </Container>
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}
BuyerSupplierCard.propTypes = {
  supplierList: propTypes.array,
}
