import { makeStyles } from '@mui/styles'
import { Done } from 'assets/icons'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Seperator from 'components/common/Seperator'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import React, { Fragment, useEffect, useState } from 'react'
import { getColor } from 'theme/colors'
import { expiredIn48Hours, formatPrice } from 'utils/Utils'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import { useNavigate, useParams } from 'react-router-dom'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import useOrderDetails from 'common-hooks/orders/useOrderDetails'
import useGetCounterListBuyer from 'common-hooks/buyer/counter/useGetCounterListBuyer'
import Chip from 'components/common/Chip'

const useStyles = makeStyles({
  cardRoot: {
    border: ' 1px solid #F0F0F4',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
    marginBottom: '20px',
  },
  smallgrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginBottom: '10px',
  },
  doneIcon: {
    height: '24px',
    width: '24px',
  },
})

export default function CounterOfferCard({
  obj,
  setOrderId,
  orderId,
  setCounterToggle,
  quoteReqId,
  setCurrectPrice,
  setApproveDialog,
  setOfferPrice,
}) {
  const classes = useStyles()
  const offerexpired = false
  const params = useParams()
  const [date, setDate] = useState()
  const navigate = useNavigate()
  const { orderDetailsData } = useOrderDetails(orderId)
  const { supplierReqDetails } = useReqSupplierDetails(params?.id, quoteReqId)
  const { setCounterOfferExpire, counterNudgeBuyer } = useGetCounterListBuyer()
  useEffect(() => {
    obj?.map((data) => {
      setDate(data?.created_at)
      setOfferPrice(data?.offer_price)
      return 0
    })
    if (expiredIn48Hours(date) === '') {
      setCounterOfferExpire({ quote_id: params?.id, quote_request_id: quoteReqId })
    }
  }, [obj])
  const handleOrderClick = (orderId) => {
    setOrderId(orderId)
    if (orderDetailsData) {
      navigate(`/orders/AwaitingShipment/${orderDetailsData?.order_id}/${orderId}`)
    }
  }
  return (
    <>
      <Container className={classes.cardRoot}>
        {obj?.map((data, index) => (
          <Fragment key={index}>
            {data?.type === 'customer' && data?.pricing_count === '1' && (
              <Container className={classes.buyerOffer}>
                <Container flex justify="space-between">
                  <Typography variant="body1" color={'#13141B'}>
                    Offer #{data?.pricing_count === null ? '1' : data?.pricing_count}
                  </Typography>
                  <Typography variant="title" color={getColor('grey-g65')}>
                    {formatPrice(data?.offer_price || data?.current_price)}
                  </Typography>
                </Container>
                <Container flex justify="space-between">
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    {data?.note}
                  </Typography>
                  <Container className={classes.smallgrid}>
                    <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                      {data?.shipping_options?.freight_ground &&
                        `Ground: ${formatPrice(data?.shipping_options?.freight_ground)}`}
                      {data?.shipping_options?.freight_next_day &&
                        `Next Day Delivery: ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                      {data?.shipping_options?.freight_second_day &&
                        `2nd Day: ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                      {data?.shipping_options?.mdo_pick_up &&
                        `Pick Up: ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                      {data?.shipping_options?.mdo_ltl && `LTL: ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                      {data?.shipping_options?.mdo_local_delivery &&
                        `Local Delivery: ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                    </Typography>
                    {/* {obj?.length === 1 && !data?.is_approved && (
                      <Container flex gap="5px">
                        <Typography variant="paragraph1" color="#AD1F36" component={'p'}>
                          {expiredIn48Hours(data?.created_at) === ''
                            ? 'Offer Expired'
                            : `Expires : ${expiredIn48Hours(data?.created_at)}`}
                        </Typography>
                      </Container>
                    )} */}
                  </Container>
                </Container>
                <Container flex justify="space-between">
                  {obj?.length === 1 && !data?.is_approved && (
                    <Container flex gap="10px">
                      {expiredIn48Hours(data?.created_at) !== '' && (
                        <>
                          <Chip label="Waiting for supplier" /> •
                        </>
                      )}
                      <Container flex gap="5px">
                        <Typography variant="paragraph1" color="#AD1F36" component={'p'}>
                          {expiredIn48Hours(data?.created_at) === ''
                            ? 'Offer Expired'
                            : `Expires : ${expiredIn48Hours(data?.created_at)}`}
                        </Typography>
                        {/* <ShowCounter days={days} hours={hours} /> */}
                      </Container>
                      •
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                    </Container>
                  )}
                  {obj?.length !== 1 && (
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                    </Typography>
                  )}

                  {obj?.length === 1 && !data?.is_approved && expiredIn48Hours(data?.created_at) !== '' && (
                    <Button
                      variant="secondary"
                      border
                      onClick={() => counterNudgeBuyer({ quote_id: params?.id, quote_request_id: quoteReqId })}
                    >
                      Nudge
                    </Button>
                  )}
                  {offerexpired && (
                    <Button variant="secondary" border>
                      Send New Offer
                    </Button>
                  )}
                </Container>
              </Container>
            )}
            {data?.type === 'supplier' && data?.pricing_count === '1' && (
              <>
                <Seperator />
                <Container className={classes.supplierOffer}>
                  <Container flex justify="space-between">
                    <Typography variant="body1" color={'#13141B'}>
                      Supplier responded with counter offer!
                    </Typography>
                    <Typography variant="title" color={getColor('grey-g25')}>
                      {setCurrectPrice(data?.offer_price)}
                      {formatPrice(data?.offer_price)}
                    </Typography>
                  </Container>
                  <Container flex justify="space-between">
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      {data?.note}
                    </Typography>
                    <Container className={classes.smallgrid}>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        {data?.shipping_options?.freight_ground &&
                          `Ground: ${formatPrice(data?.shipping_options?.freight_ground)}`}
                        {data?.shipping_options?.freight_next_day &&
                          `Next Day Delivery: ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                        {data?.shipping_options?.freight_second_day &&
                          `2nd Day: ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                        {data?.shipping_options?.mdo_pick_up &&
                          `Pick Up: ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                        {data?.shipping_options?.mdo_ltl && `LTL: ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                        {data?.shipping_options?.mdo_local_delivery &&
                          `Local Delivery: ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                      </Typography>
                      {obj?.length === 2 && supplierReqDetails?.status !== 'closed' && (
                        <Container flex gap="5px">
                          <Typography variant="paragraph1" color="#AD1F36" component={'p'}>
                            {expiredIn48Hours(data?.created_at) === ''
                              ? 'Offer Expired'
                              : `Expires : ${expiredIn48Hours(data?.created_at)}`}
                          </Typography>
                          {/* <ShowCounter days={days} hours={hours} /> */}
                        </Container>
                      )}
                    </Container>
                  </Container>
                  <Container flex justify="space-between">
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                    </Typography>
                    {offerexpired && (
                      <Button variant="secondary" border>
                        Send New Offer
                      </Button>
                    )}
                    {obj?.length === 2 &&
                      supplierReqDetails?.status !== 'closed' &&
                      expiredIn48Hours(data?.created_at) !== '' && (
                        <>
                          <Container flex gap={'0 10px'}>
                            <Button variant="secondary" border onClick={setCounterToggle}>
                              Counter
                            </Button>
                            <Button variant="positive" onClick={() => setApproveDialog(true)}>
                              {' '}
                              Accept & Approve
                            </Button>
                          </Container>
                        </>
                      )}
                  </Container>
                </Container>
              </>
            )}
            {data?.is_approved && data?.pricing_count === '1' && (
              <>
                <Seperator />
                <Container flex justify="space-between" gap={'10px 0'} direction={'column'}>
                  <Container flex justify="space-between" width={'100%'}>
                    <Container flex gap={'0 5px'}>
                      <Done className={classes.doneIcon} />
                      <Typography variant="body1" color={'#13141B'}>
                        Congratulations! Supplier has accepted your offer.
                      </Typography>
                    </Container>
                    <Typography variant="title1" color={getColor('grey-g25')}>
                      {formatPrice(data?.offer_price)}
                    </Typography>
                  </Container>
                  <Container flex justify="space-between" width={'100%'}>
                    <Typography variant="paragraph1" color={'#4A4D68'}>
                      {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                    </Typography>
                    {supplierReqDetails?.status !== 'closed' && (
                      <Button variant="positive" onClick={() => setApproveDialog(true)}>
                        Approve
                      </Button>
                    )}
                  </Container>
                </Container>
              </>
            )}

            {supplierReqDetails?.status === 'closed' &&
              (obj?.length === 2 || obj?.length === 1) &&
              data?.pricing_count === '1' && (
                <>
                  <Seperator />
                  <Container flex justify="space-between" gap={'10px 0'} direction={'column'}>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container flex gap={'0 5px'}>
                        <Done />
                        <Typography variant="body1" color={'#13141B'}>
                          Congratulations - you’ve locked the order!
                        </Typography>
                      </Container>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        {formatPrice(data?.offer_price)}
                      </Typography>
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Typography variant="paragraph1" color={'#4A4D68'}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                      <Button
                        variant="secondary"
                        className={classes.counterBtn}
                        border
                        onClick={() => handleOrderClick(data?.orders_id)}
                      >
                        View Order
                      </Button>
                    </Container>
                  </Container>
                </>
              )}
          </Fragment>
        ))}
      </Container>

      {/* Offer 2 complete */}
      {(obj?.length === 3 || obj?.length === 4 || obj?.length === 5 || obj?.length === 6) && (
        <Container className={classes.cardRoot}>
          {obj?.map((data, index) => (
            <Fragment key={index}>
              {data?.type === 'customer' && data?.pricing_count === '2' && (
                <Container className={classes.buyerOffer}>
                  <Container flex justify="space-between">
                    <Typography variant="body1" color={'#13141B'}>
                      Offer #{data?.pricing_count === '2' && data?.pricing_count}
                    </Typography>
                    <Typography variant="title" color={getColor('grey-g65')}>
                      {formatPrice(data?.offer_price || data?.current_price)}
                    </Typography>
                  </Container>
                  <Container flex justify="space-between">
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      {data?.note}
                    </Typography>
                    <Container className={classes.smallgrid}>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        {data?.shipping_options?.freight_ground &&
                          `Ground: ${formatPrice(data?.shipping_options?.freight_ground)}`}
                        {data?.shipping_options?.freight_next_day &&
                          `Next Day Delivery: ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                        {data?.shipping_options?.freight_second_day &&
                          `2nd Day: ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                        {data?.shipping_options?.mdo_pick_up &&
                          `Pick Up: ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                        {data?.shipping_options?.mdo_ltl && `LTL: ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                        {data?.shipping_options?.mdo_local_delivery &&
                          `Local Delivery: ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                      </Typography>
                      {/* {obj?.length === 3 && !data?.is_approved && (
                        <Container flex gap="5px">
                          <Typography variant="paragraph1" color="#AD1F36" component={'p'}>
                            {expiredIn48Hours(data?.created_at) === ''
                              ? 'Offer Expired'
                              : `Expires : ${expiredIn48Hours(data?.created_at)}`}
                          </Typography>
                        </Container>
                      )} */}
                    </Container>
                  </Container>
                  <Container flex justify="space-between">
                    {obj?.length === 3 && !data?.is_approved && (
                      <Container flex gap="10px">
                        {expiredIn48Hours(data?.created_at) !== '' && (
                          <>
                            <Chip label="Waiting for supplier" /> •
                          </>
                        )}
                        <Container flex gap="5px">
                          <Typography variant="paragraph1" color="#AD1F36" component={'p'}>
                            {expiredIn48Hours(data?.created_at) === ''
                              ? 'Offer Expired'
                              : `Expires : ${expiredIn48Hours(data?.created_at)}`}
                          </Typography>
                          {/* <ShowCounter days={days} hours={hours} /> */}
                        </Container>
                        •
                        <Typography variant="paragraph1" color={getColor('grey-g35')}>
                          {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                        </Typography>
                      </Container>
                    )}
                    {obj?.length !== 3 && (
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                    )}
                    {obj?.length === 3 && !data?.is_approved && expiredIn48Hours(data?.created_at) !== '' && (
                      <Button
                        variant="secondary"
                        border
                        onClick={() => counterNudgeBuyer({ quote_id: params?.id, quote_request_id: quoteReqId })}
                      >
                        Nudge
                      </Button>
                    )}
                    {offerexpired && (
                      <Button variant="secondary" border>
                        Send New Offer
                      </Button>
                    )}
                  </Container>
                </Container>
              )}
              {data?.type === 'supplier' && data?.pricing_count === '2' && (
                <>
                  <Seperator />
                  <Container className={classes.supplierOffer}>
                    <Container flex justify="space-between">
                      <Typography variant="body1" color={'#13141B'}>
                        Supplier responded with counter offer!
                      </Typography>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        {setCurrectPrice(data?.offer_price)}
                        {formatPrice(data?.offer_price)}
                      </Typography>
                    </Container>
                    <Container flex justify="space-between">
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {data?.note}
                      </Typography>
                      <Container className={classes.smallgrid}>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                          {data?.shipping_options?.freight_ground &&
                            `Ground: ${formatPrice(data?.shipping_options?.freight_ground)}`}
                          {data?.shipping_options?.freight_next_day &&
                            `Next Day Delivery: ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                          {data?.shipping_options?.freight_second_day &&
                            `2nd Day: ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                          {data?.shipping_options?.mdo_pick_up &&
                            `Pick Up: ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                          {data?.shipping_options?.mdo_ltl && `LTL: ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                          {data?.shipping_options?.mdo_local_delivery &&
                            `Local Delivery: ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                        </Typography>
                        {obj?.length === 4 && supplierReqDetails?.status !== 'closed' && (
                          <Container flex gap="5px">
                            <Typography variant="paragraph1" color="#AD1F36" component={'p'}>
                              {expiredIn48Hours(data?.created_at) === ''
                                ? 'Offer Expired'
                                : `Expires : ${expiredIn48Hours(data?.created_at)}`}
                            </Typography>
                            {/* <ShowCounter days={days} hours={hours} /> */}
                          </Container>
                        )}
                      </Container>
                    </Container>
                    <Container flex justify="space-between">
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                      {offerexpired && (
                        <Button variant="secondary" border>
                          Send New Offer
                        </Button>
                      )}
                      {obj?.length === 4 &&
                        supplierReqDetails?.status !== 'closed' &&
                        expiredIn48Hours(data?.created_at) !== '' && (
                          <>
                            <Container flex gap={'0 10px'}>
                              <Button variant="secondary" border onClick={setCounterToggle}>
                                Counter
                              </Button>
                              <Button variant="positive" onClick={() => setApproveDialog(true)}>
                                {' '}
                                Accept & Approve
                              </Button>
                            </Container>
                          </>
                        )}
                    </Container>
                  </Container>
                </>
              )}
              {data?.type === 'customer' && data?.is_approved && data?.pricing_count === '2' && (
                <>
                  <Seperator />
                  <Container flex justify="space-between" gap={'10px 0'} direction={'column'}>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container flex gap={'0 5px'}>
                        <Done />
                        <Typography variant="body1" color={'#13141B'}>
                          Congratulations! Supplier has accepted your offer.
                        </Typography>
                      </Container>
                      <Typography variant="title1" color={getColor('grey-g25')}>
                        {formatPrice(data?.offer_price)}
                      </Typography>
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Typography variant="paragraph1" color={'#4A4D68'}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                      {supplierReqDetails?.status !== 'closed' && (
                        <Button variant="positive" onClick={() => setApproveDialog(true)}>
                          Approve
                        </Button>
                      )}
                    </Container>
                  </Container>
                </>
              )}

              {supplierReqDetails?.status === 'closed' && obj?.length === 3 && data?.pricing_count === '2' && (
                <>
                  <Seperator />
                  <Container flex justify="space-between" gap={'10px 0'} direction={'column'}>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container flex gap={'0 5px'}>
                        <Done />
                        <Typography variant="body1" color={'#13141B'}>
                          Congratulations - you’ve locked the order!
                        </Typography>
                      </Container>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        {formatPrice(data?.offer_price)}
                      </Typography>
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Typography variant="paragraph1" color={'#4A4D68'}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                      <Button
                        variant="secondary"
                        className={classes.counterBtn}
                        border
                        onClick={() => handleOrderClick(data?.orders_id)}
                      >
                        View Order
                      </Button>
                    </Container>
                  </Container>
                </>
              )}
            </Fragment>
          ))}
        </Container>
      )}

      {/* Offer 3 complete */}
      {(obj?.length === 5 || obj?.length === 6) && (
        <Container className={classes.cardRoot}>
          {obj?.map((data, index) => (
            <Fragment key={index}>
              {data?.type === 'customer' && data?.pricing_count === '3' && (
                <Container className={classes.buyerOffer}>
                  <Container flex justify="space-between">
                    <Typography variant="body1" color={'#13141B'}>
                      Offer #{data?.pricing_count === '3' && data?.pricing_count}
                    </Typography>
                    <Typography variant="title" color={getColor('grey-g65')}>
                      {formatPrice(data?.offer_price || data?.current_price)}
                    </Typography>
                  </Container>
                  <Container flex justify="space-between">
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      {data?.note}
                    </Typography>
                    <Container className={classes.smallgrid}>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        {data?.shipping_options?.freight_ground &&
                          `Ground: ${formatPrice(data?.shipping_options?.freight_ground)}`}
                        {data?.shipping_options?.freight_next_day &&
                          `Next Day Delivery: ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                        {data?.shipping_options?.freight_second_day &&
                          `2nd Day: ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                        {data?.shipping_options?.mdo_pick_up &&
                          `Pick Up: ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                        {data?.shipping_options?.mdo_ltl && `LTL: ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                        {data?.shipping_options?.mdo_local_delivery &&
                          `Local Delivery: ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                      </Typography>
                    </Container>
                  </Container>
                  <Container flex justify="space-between">
                    {obj?.length === 5 && !data?.is_approved && (
                      <Container flex gap="10px">
                        {expiredIn48Hours(data?.created_at) !== '' && (
                          <>
                            <Chip label="Waiting for supplier" /> •
                          </>
                        )}
                        <Typography variant="paragraph1" color={getColor('grey-g35')}>
                          {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                        </Typography>
                        •
                        <Container flex gap="5px">
                          <Typography variant="paragraph1" color="#AD1F36" component={'p'}>
                            {expiredIn48Hours(data?.created_at) === ''
                              ? 'Offer Expired'
                              : `Expires : ${expiredIn48Hours(data?.created_at)}`}
                          </Typography>
                          {/* <ShowCounter days={days} hours={hours} /> */}
                        </Container>
                      </Container>
                    )}
                    {obj?.length !== 5 && (
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                    )}
                    {obj?.length === 5 && !data?.is_approved && expiredIn48Hours(data?.created_at) !== '' && (
                      <Button
                        variant="secondary"
                        border
                        onClick={() => counterNudgeBuyer({ quote_id: params?.id, quote_request_id: quoteReqId })}
                      >
                        Nudge
                      </Button>
                    )}
                    {offerexpired && (
                      <Button variant="secondary" border>
                        Send New Offer
                      </Button>
                    )}
                  </Container>
                </Container>
              )}
              {data?.type === 'supplier' && data?.pricing_count === '3' && (
                <>
                  <Seperator />
                  <Container className={classes.supplierOffer}>
                    <Container flex justify="space-between">
                      <Typography variant="body1" color={'#13141B'}>
                        Supplier responded with counter offer!
                      </Typography>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        {formatPrice(data?.offer_price)}
                      </Typography>
                    </Container>
                    <Container flex justify="space-between">
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {data?.note}
                      </Typography>
                      <Container className={classes.smallgrid}>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                          {data?.shipping_options?.freight_ground &&
                            `Ground: ${formatPrice(data?.shipping_options?.freight_ground)}`}
                          {data?.shipping_options?.freight_next_day &&
                            `Next Day Delivery: ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                          {data?.shipping_options?.freight_second_day &&
                            `2nd Day: ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                          {data?.shipping_options?.mdo_pick_up &&
                            `Pick Up: ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                          {data?.shipping_options?.mdo_ltl && `LTL: ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                          {data?.shipping_options?.mdo_local_delivery &&
                            `Local Delivery: ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                        </Typography>
                        {obj?.length === 6 && supplierReqDetails?.status !== 'closed' && (
                          <Container flex gap="5px">
                            <Typography variant="paragraph1" color="#AD1F36" component={'p'}>
                              {expiredIn48Hours(data?.created_at) === ''
                                ? 'Offer Expired'
                                : `Expires : ${expiredIn48Hours(data?.created_at)}`}
                            </Typography>
                            {/* <ShowCounter days={days} hours={hours} /> */}
                          </Container>
                        )}
                      </Container>
                    </Container>
                    <Container flex justify="space-between">
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                      {offerexpired && (
                        <Button variant="secondary" border>
                          Send New Offer
                        </Button>
                      )}
                      {obj?.length === 6 &&
                        supplierReqDetails?.status !== 'closed' &&
                        expiredIn48Hours(data?.created_at) !== '' && (
                          <>
                            <Container flex gap={'0 10px'}>
                              <Button variant="positive" onClick={() => setApproveDialog(true)}>
                                Approve
                              </Button>
                            </Container>
                          </>
                        )}
                    </Container>
                  </Container>
                </>
              )}
              {data?.type === 'customer' && data?.is_approved && data?.pricing_count === '3' && (
                <>
                  <Seperator />
                  <Container flex justify="space-between" gap={'10px 0'} direction={'column'}>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container flex gap={'0 5px'}>
                        <Done />
                        <Typography variant="body1" color={'#13141B'}>
                          Congratulations! Supplier has accepted your offer.
                        </Typography>
                      </Container>
                      <Typography variant="title1" color={getColor('grey-g25')}>
                        {formatPrice(data?.offer_price)}
                      </Typography>
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Typography variant="paragraph1" color={'#4A4D68'}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                      {supplierReqDetails?.status !== 'closed' && (
                        <Button variant="positive" onClick={() => setApproveDialog(true)}>
                          Approve
                        </Button>
                      )}
                    </Container>
                  </Container>
                </>
              )}
              {supplierReqDetails?.status === 'closed' && data?.pricing_count === '3' && data?.orders_id && (
                <>
                  <Seperator />
                  <Container flex justify="space-between" gap={'10px 0'} direction={'column'}>
                    <Container flex justify="space-between" width={'100%'}>
                      <Container flex gap={'0 5px'}>
                        <Done />
                        <Typography variant="body1" color={'#13141B'}>
                          Congratulations - you’ve locked the order!
                        </Typography>
                      </Container>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        {formatPrice(data?.offer_price)}
                      </Typography>
                    </Container>
                    <Container flex justify="space-between" width={'100%'}>
                      <Typography variant="paragraph1" color={'#4A4D68'}>
                        {formatDate(obj?.created_at, DATE_FORMAT_PRIMARY)}
                      </Typography>
                      <Button
                        variant="secondary"
                        className={classes.counterBtn}
                        border
                        onClick={() => handleOrderClick(data?.orders_id)}
                      >
                        View Order
                      </Button>
                    </Container>
                  </Container>
                </>
              )}
            </Fragment>
          ))}
        </Container>
      )}
    </>
  )
}
CounterOfferCard.propTypes = {
  quoteReqId: PropTypes.string,
  setCounterToggle: PropTypes.func,
  obj: PropTypes.object,
  setCurrectPrice: PropTypes.func,
  setApproveDialog: PropTypes.func,
  setOrderId: PropTypes.func,
  orderId: PropTypes.string,
  setOfferPrice: PropTypes.func,
}
