/* eslint-disable camelcase */
import CaterpillarCon_Brands from 'assets/images/CaterpillarCon_Brands.svg'
import Construction_Brand from 'assets/images/Construction_Brand.svg'
import Hitachi_Brands from 'assets/images/Hitachi_Brands.svg'
import Hyster_Brands from 'assets/images/Hyster_Brands.svg'
import Jcb_Brands from 'assets/images/Jcb_Brands.svg'
import JlgRgb_Brands from 'assets/images/JlgRgb_Brands.svg'
import JohnDeere_Brands from 'assets/images/JohnDeere_Brands.svg'
import NewHollandCon_Brands from 'assets/images/NewHollandCon_Brands.svg'
import JohndearBrandImage from 'assets/images/JohndearBrandImage.svg'

import Sany_Brands from 'assets/images/Sany_Brands.svg'
import Toyoto_Brands from 'assets/images/Toyoto_Brands.svg'
import ChristopherRobinson from 'assets/images/ChristopherRobinson.svg'
import ChrisTempleton from 'assets/images/ChrisTempleton.svg'
import LionelSmith from 'assets/images/LionelSmith.svg'
import BobChartwell from 'assets/images/BobChartwell.svg'
import AlbertLu from 'assets/images/AlbertLu.svg'
import jamesSchneider from 'assets/images/jamesSchneider.svg'
import supplierBanner from 'assets/images/supplierBanner.jpg'
import QuoteImage from 'assets/images/QuoteImage.png'
import Verfied from 'assets/icons/Verfied.svg'
import earlyBadge from 'assets/icons/earlyBadge.svg'
import EBackhoe from 'assets/buyer-quotes-assets/equi/Backhoe.png'
import ECrane from 'assets/buyer-quotes-assets/equi/Crane.png'
import ECrusher from 'assets/buyer-quotes-assets/equi/Crusher.png'
import EExcavator from 'assets/buyer-quotes-assets/equi/Excavator.png'
import EGrade from 'assets/buyer-quotes-assets/equi/Grade.png'
import EForestry from 'assets/buyer-quotes-assets/equi/Forestry.png'
import BArcticCat from 'assets/buyer-quotes-assets/brand/ArcticCat.png'
import BClaas from 'assets/buyer-quotes-assets/brand/Claas.png'
import BDeutzFahr from 'assets/buyer-quotes-assets/brand/DeutzFahr.png'
import BGleaner from 'assets/buyer-quotes-assets/brand/Gleaner.png'
import BNewHolland from 'assets/buyer-quotes-assets/brand/NewHolland.png'
import BVehicle from 'assets/buyer-quotes-assets/brand/Vehicle.png'
import EllipsisVerticleIcon from 'assets/icons/UnionIcon.svg'
// import { createContext } from 'react'

// export const BuyerQuoteContextLandingPage = createContext({
//   onSaveBuyerQuoteInformaton: () => {},
//   BuyerQueoteInformation: {},
//   visibleTextBoxData: [],
//   setTextBoxVisibilityData: () => {},
// })
export function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj
  let i = null
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind]?.toLowerCase() === valToFind?.toLowerCase()) {
      foundObj = { ...nestedValue, parent_id: i }
    } else if (nestedValue && typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
      i = nestedValue?.id ? nestedValue.id : null
    }

    return nestedValue
  })
  return foundObj
}

export const LandingPageData = [
  {
    id: '01',
    img1: '',
    img2: '',
    brand: 'John Deere',
    part_type: 'Bucket Cylinder',
    part_number: 'LW8924656',
    equipment: 'Loader',
    model: 'ED010',
    serial: 'Unlock serial number',
    buyer_name: 'Unlock Buyer Details',
    city_state: 'El Paso, TX',
    country_flag: '',
    /** Include Brad and quipement in different font */
    part_description: 'Hydraulics',
    quantity: '+2',
    time_slap: '48 mins ago',
  },
  {
    id: '02',
    img1: '',
    img2: '',
    brand: 'JLG',
    part_type: 'Seal Kit',
    part_number: '8034690',
    equipment: 'Skid Steer',
    model: 'AT18416',
    serial: 'Unlock Buyer Details',
    buyer_name: 'Unlock Buyer Details',
    city_state: 'Mauldin, SC',
    country_flag: '',
    /** Include Brad and quipement in different font */
    part_description: 'Hydraulics',
    quantity: '+2',
    time_slap: '48 mins ago',
  },
  {
    id: '03',
    img1: '',
    img2: '',
    brand: 'JCB',
    part_type: 'Steering Valve',
    part_number: '29/803790',
    equipment: 'Skid Steer',
    model: 'AT18416',
    serial: 'Unlock Buyer Details',
    buyer_name: 'Unlock Buyer Details',
    city_state: 'Austin, TX',
    country_flag: '',
    /** Include Brad and quipement in different font */
    part_description: '',
    quantity: '+2',
    time_slap: '12 hrs ago',
  },
  {
    id: '04',
    img1: '',
    img2: '',
    brand: 'Caterpillar',
    part_type: 'Fuel Pump',
    part_number: '240-8381',
    equipment: 'Excavator',
    model: '350',
    serial: 'AT124357',
    buyer_name: 'Brightspot Equipment Rental',
    city_state: 'El Paso, TX',
    country_flag: '',
    /** Include Brand and quipement in different font */
    part_description: 'Hydraulics',
    quantity: '+2',
    time_slap: '07/30/2022',
  },
  {
    id: '05',
    img1: '',
    img2: '',
    brand: 'John Deere',
    part_type: 'Bucket Cylinder',
    part_number: 'LW8924656',
    equipment: 'Loader',
    model: 'ED010',
    serial: 'Unlock serial number',
    buyer_name: 'Unlock Buyer Details',
    city_state: 'El Paso, TX',
    country_flag: '',
    /** Include Brad and quipement in different font */
    part_description: 'Hydraulics',
    quantity: '+2',
    time_slap: '48 mins ago',
  },
  {
    id: '06',
    img1: '',
    img2: '',
    brand: 'JLG',
    part_type: 'Seal Kit',
    part_number: '8034690',
    equipment: 'Skid Steer',
    model: 'AT18416',
    serial: 'Unlock Buyer Details',
    buyer_name: 'Unlock Buyer Details',
    city_state: 'Mauldin, SC',
    country_flag: '',
    /** Include Brad and quipement in different font */
    part_description: 'Hydraulics',
    quantity: '+2',
    time_slap: '48 mins ago',
  },
  {
    id: '07',
    img1: '',
    img2: '',
    brand: 'JCB',
    part_type: 'Steering Valve',
    part_number: '29/803790',
    equipment: 'Skid Steer',
    model: 'AT18416',
    serial: 'Unlock Buyer Details',
    buyer_name: 'Unlock Buyer Details',
    city_state: 'Austin, TX',
    country_flag: '',
    /** Include Brad and quipement in different font */
    part_description: '',
    quantity: '+2',
    time_slap: '12 hrs ago',
  },
  {
    id: '08',
    img1: '',
    img2: '',
    brand: 'Caterpillar',
    part_type: 'Fuel Pump',
    part_number: '240-8381',
    equipment: 'Excavator',
    model: '350',
    serial: 'AT124357',
    buyer_name: 'Brightspot Equipment Rental',
    city_state: 'El Paso, TX',
    country_flag: '',
    /** Include Brand and quipement in different font */
    part_description: 'Hydraulics',
    quantity: '+2',
    time_slap: '07/30/2022',
  },
]

export const Brands = [
  {
    src: JohnDeere_Brands,
  },
  {
    src: CaterpillarCon_Brands,
  },
  {
    src: Construction_Brand,
  },
  {
    src: JlgRgb_Brands,
  },
  {
    src: Jcb_Brands,
  },
  {
    src: Toyoto_Brands,
  },
  {
    src: NewHollandCon_Brands,
  },
  {
    src: Hyster_Brands,
  },
  {
    src: Sany_Brands,
  },
  {
    src: Hitachi_Brands,
  },

  {
    src: JohnDeere_Brands,
  },
  {
    src: CaterpillarCon_Brands,
  },
  {
    src: Construction_Brand,
  },
  {
    src: JlgRgb_Brands,
  },
  {
    src: Jcb_Brands,
  },
  {
    src: Toyoto_Brands,
  },
  {
    src: NewHollandCon_Brands,
  },
  {
    src: Hyster_Brands,
  },
  {
    src: Sany_Brands,
  },
  {
    src: Hitachi_Brands,
  },
]

export const Testinomial = [
  {
    feedBack: '“It’s Expedia for parts!”',
    img: jamesSchneider,
    name: 'James Schneider',
    profile: 'Maintenance Supervisor at Blue Bin Storage Co.',
  },
  {
    feedBack: '“PartsClub saves us a ton of phone calls and emails. It’s way less work.”',
    img: ChristopherRobinson,
    name: 'Christopher Robinson',
    profile: 'Parts Representative at Noble Equipment',
  },
  {
    feedBack: '“I wish this existed a long time ago.”',
    img: ChrisTempleton,
    name: 'Chris Templeton',
    profile: 'Mobile Technician at South City Lift & Repair Inc.',
  },
  {
    feedBack: '“Clean. Simple. Easy to use.”',
    img: LionelSmith,
    name: 'Lionel Smith',
    profile: 'Purchasing Manager at UTL Metalworks',
  },
  {
    feedBack: '“We can easily get our pricing and availability from multiple suppliers.” ',
    img: BobChartwell,
    name: 'Bob Chartwell',
    profile: 'Service Representative at CE Cylinder Repair',
  },
  {
    feedBack: '“We were tired of suppliers never calling us back. This is the solution to that.” ',
    img: AlbertLu,
    name: 'Albert Lu',
    profile: 'General Manager at ATS Equipment Service Corp.',
  },
]

export const supplierList = [
  {
    bannerImage: supplierBanner,
    profileImage: QuoteImage,
    quipmentName: 'AAM Parts & Equipment',
    city: 'Mauldin, ',
    state: 'SC',
    about: 'This is a tag line section for the comapny. Here supplier/company ca...',
    specialization: 'Industrial & Aerial',
    supplierType: 'Premium',
    verify: Verfied,
    badge: earlyBadge,
  },
  {
    bannerImage: supplierBanner,
    profileImage: QuoteImage,
    quipmentName: 'AGA Parts',
    city: 'El Paso, ',
    state: 'TX',
    about: 'This is a tag line section for the comapny. Here supplier/company ca...',
    specialization: 'Construction & Mining',
    supplierType: 'Premium',
    verify: Verfied,
    badge: earlyBadge,
  },
  {
    bannerImage: supplierBanner,
    profileImage: QuoteImage,
    quipmentName: 'Advantage',
    city: 'Castlewood, ',
    state: ' SD',
    about: 'This is a tag line section for the comapny. Here supplier/company ca...',
    specialization: 'Industrial & Aerial',
    supplierType: 'Premium',
    verify: Verfied,
    badge: earlyBadge,
  },
  {
    bannerImage: supplierBanner,
    profileImage: QuoteImage,
    quipmentName: 'Cooper Tires & Tracks',
    city: 'El Paso,',
    state: ' TX',
    about: 'This is a tag line section for the comapny. Here supplier/company ca...',
    specialization: 'Industrial & Aerial',
    supplierType: 'Premium',
    verify: Verfied,
    badge: earlyBadge,
  },
  {
    bannerImage: supplierBanner,
    profileImage: QuoteImage,
    quipmentName: 'Great Plains',
    city: 'Castlewood, ',
    state: ' SD',
    about: 'This is a tag line section for the comapny. Here supplier/company ca...',
    specialization: 'Agricultural & Turf',
    supplierType: 'Premium',
    verify: Verfied,
    badge: earlyBadge,
  },
  {
    bannerImage: supplierBanner,
    profileImage: QuoteImage,
    quipmentName: 'Great Plains',
    city: 'Castlewood, ',
    state: ' SD',
    about: 'This is a tag line section for the comapny. Here supplier/company ca...',
    specialization: 'Agricultural & Turf',
    supplierType: 'Premium',
    verify: Verfied,
    badge: earlyBadge,
  },
  {
    bannerImage: supplierBanner,
    profileImage: QuoteImage,
    quipmentName: 'Great Plains',
    city: 'Castlewood, ',
    state: ' SD',
    about: 'This is a tag line section for the comapny. Here supplier/company ca...',
    specialization: 'Agricultural & Turf',
    supplierType: 'Premium',
    verify: Verfied,
    badge: earlyBadge,
  },
  {
    bannerImage: supplierBanner,
    profileImage: QuoteImage,
    quipmentName: 'Great Plains',
    city: 'Castlewood, ',
    state: ' SD',
    about: 'This is a tag line section for the comapny. Here supplier/company ca...',
    specialization: 'Agricultural & Turf',
    supplierType: 'Premium',
    verify: Verfied,
    badge: earlyBadge,
  },
]

export const equipmentData = {
  Popular: [
    {
      id: 1,
      name: 'Backhoe',
      img: EBackhoe,
    },
    {
      id: 2,
      name: 'Crane',
      img: ECrane,
    },
    {
      id: 3,
      name: 'Crusher',
      img: ECrusher,
    },

    {
      id: 5,
      name: 'Excavator',
      img: EExcavator,
    },
    {
      id: 6,
      name: 'Grade',
      img: EGrade,
    },
    {
      id: 7,
      name: 'Forestry',
      img: EForestry,
    },
  ],
  Others: [
    {
      id: 11,
      name: 'Backhoe1',
      img: EBackhoe,
    },
    {
      id: 21,
      name: 'Crane1',
      img: ECrane,
    },
    {
      id: 31,
      name: 'Crusher1',
      img: ECrusher,
    },

    {
      id: 51,
      name: 'Excavator1',
      img: EExcavator,
    },
    {
      id: 61,
      name: 'Grade1',
      img: EGrade,
    },
    {
      id: 71,
      name: 'Forestry1',
      img: EForestry,
    },
    {
      id: 81,
      name: 'Other',
      img: EllipsisVerticleIcon,
    },
  ],
}

export const equipmentData1 = [
  {
    id: 2,
    name: 'Crane',
    img: ECrane,
  },
  {
    id: 1,
    name: 'Backhoe',
    img: EBackhoe,
  },

  {
    id: 5,
    name: 'Excavator',
    img: EExcavator,
  },
  {
    id: 3,
    name: 'Crusher',
    img: ECrusher,
  },

  {
    id: 6,
    name: 'Grade',
    img: EGrade,
  },
  {
    id: 7,
    name: 'Forestry',
    img: EForestry,
  },
]
export const brandData = {
  Popular: [
    {
      id: 1,
      name: 'ArcticCat',
      img: BArcticCat,
    },
    {
      id: 2,
      name: 'Claas',
      img: BClaas,
    },
    {
      id: 3,
      name: 'DeutzFahr',
      img: BDeutzFahr,
    },
    {
      id: 4,
      name: 'Gleaner',
      img: BGleaner,
    },
    {
      id: 5,
      name: 'NewHolland',
      img: BNewHolland,
    },
    {
      id: 6,
      name: 'Vehicle',
      img: BVehicle,
    },
  ],
  Others: [
    {
      id: 6,
      name: 'Vehicle1',
      img: BVehicle,
    },

    {
      id: 5,
      name: 'NewHolland1',
      img: BNewHolland,
    },
    {
      id: 4,
      name: 'Gleaner1',
      img: BGleaner,
    },
    {
      id: 81,
      name: 'Other',
      img: EllipsisVerticleIcon,
    },
  ],
}
export const createUuid = () => {
  // eslint-disable-next-line no-var
  var dt = new Date().getTime()
  // eslint-disable-next-line func-names, no-var, prefer-arrow-callback
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    // eslint-disable-next-line no-bitwise, eqeqeq
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
export const parts = [
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Parts',
    children: [
      {
        id: createUuid(),
        name: 'Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
  {
    id: createUuid(),
    name: 'Attachmnets',
    children: [
      {
        id: createUuid(),
        name: 'New Attachments',
        children: [
          {
            id: createUuid(),
            name: 'JunLift Cylindere',
            children: [
              {
                id: createUuid(),
                name: 'THead Cylindere',
                children: [
                  {
                    id: createUuid(),
                    name: 'Abc Cylindere',
                    children: [
                      {
                        id: createUuid(),
                        name: 'BBC Cylindere',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
            children: [
              {
                id: createUuid(),
                name: 'Top Cylindere',
              },
              {
                id: createUuid(),
                name: 'Bottom Cylindere',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: ' Ignition valve',
        children: [
          {
            id: createUuid(),
            name: 'AAA valve',
            children: [
              {
                id: createUuid(),
                name: 'THead valve',
                children: [
                  {
                    id: createUuid(),
                    name: 'CCD valve',
                    children: [
                      {
                        id: createUuid(),
                        name: 'DDD valve',
                      },
                    ],
                  },
                ],
              },
              {
                id: createUuid(),
                name: 'Topper Head',
              },
              {
                id: createUuid(),
                name: 'Assemble',
              },
            ],
          },
          {
            id: createUuid(),
            name: 'Leveling Cylinde',
          },
          {
            id: createUuid(),
            name: 'Cylinde Boler',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Cab & Controls',
        children: [
          {
            id: createUuid(),
            name: 'December',
          },
          {
            id: createUuid(),
            name: 'January',
          },
          {
            id: createUuid(),
            name: 'February',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Boom Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Bucket Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Main Control Valve',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Leveling Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
      {
        id: createUuid(),
        name: 'Lift Cylinder',
        children: [
          {
            id: createUuid(),
            name: 'March',
          },
          {
            id: createUuid(),
            name: 'April',
          },
          {
            id: createUuid(),
            name: 'May',
          },
        ],
      },
    ],
  },
]

export const productList = [
  {
    id: '0',
    brandName: 'JCB',
    partName: 'Seal Kit',
    partNumber: '8034690',
    condition: 'New OEM',
    companyName: 'J.R. Smith Construction Company',
    city: 'Lynden',
    state: 'NJ',
    amout: '45.99',
    qty: '2',
    stock: 'In Stock',
    brandImage: JohndearBrandImage,
  },
  {
    id: '1',
    brandName: 'JCB',
    partName: 'Seal Kit',
    partNumber: '8034690',
    condition: 'New OEM',
    companyName: 'J.R. Smith Construction Company',
    city: 'Lynden',
    state: 'NJ',
    amout: '45.99',
    qty: '2',
    stock: 'In Stock',
    brandImage: JohndearBrandImage,
  },
  {
    id: '2',
    brandName: 'JCB',
    partName: 'Seal Kit',
    partNumber: '8034690',
    condition: 'New OEM',
    companyName: 'J.R. Smith Construction Company',
    city: 'Lynden',
    state: 'NJ',
    amout: '45.99',
    qty: '2',
    stock: 'Out Of Stock',
    brandImage: JohndearBrandImage,
  },
]
export const filterOption = [
  {
    label: 'Relevance',
    value: 'Relevance',
  },
  {
    label: 'A - Z',
    value: 'A - Z',
  },
  {
    label: 'Z - A',
    value: 'Z - A',
  },
  {
    label: 'Price: Low to High ',
    value: 'Price: Low to High ',
  },
  {
    label: 'Price: High to Low ',
    value: 'Price: High to Low ',
  },
]
