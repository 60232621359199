import { makeStyles } from '@mui/styles'
import Website from 'assets/icons/Website.svg'
import Chip from 'components/common/Chip'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import './css/landingpage.css'
import './css/responsive.css'
import HeaderPlaceHolder from 'assets/images/HeaderPlaceHolder.png'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import React from 'react'
import { getColor } from 'theme/colors'
// import supplierBanner from 'assets/images/supplierBanner.jpg'
import Verfied from 'assets/icons/Verfied.svg'
import earlyBadge from 'assets/icons/earlyBadge.svg'
import LinkedIn from 'assets/icons/linkedin-color.svg'
import Twitter from 'assets/icons/twitter-color.svg'
import Placeholder from 'assets/images/Placeholder.png'
import ReadMoreContentToggle from 'components/custom/ReadMoreContentToggle'
import useSupplierdetails from 'common-hooks/super-admin/useSupplierDetails'
import { useNavigate, useParams } from 'react-router-dom'
import LandingPageHeader from './LandingPageHeader'
import LandingPageFooter from './LandingPageFooter'
import { getFlag, getShortAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F8F9F9',
  },
  previousBtn: {
    border: '1px solid',
    borderColor: getColor('grey-g65'),
    width: '36px',
    height: '36px',
  },
  profileImage: {
    border: '1px solid #F0F0F4',
    borderRadius: ' 4px',
    marginTop: ' -90px',
  },
  profileInner: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',

    '& img': {
      backgroundColor: '#fff',
    },
  },
  premimumChip: {
    height: '22px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    color: '#13141B',
    borderRadius: '100px',
    '& div': {
      color: '#13141B',
      fontSize: '11px',
    },
  },
  eqpName: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 7px',
  },
  visitWebsite: {
    color: getColor('grey-g35'),
    border: '1px solid #9799B5',
    width: '145px',
    '& div': {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  commonFlex: {
    display: 'flex',
  },

  dealerItem: {
    padding: ' 10px 15px 10px 10px',
    width: 'auto',
    height: '60px',
    border: '1px solid #F0F0F4',
    '& img': {
      border: '1px solid #F0F0F4',
      width: '40px',
      height: '40px',
    },
  },
  speciliationgrid: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  activeSpecilization: {
    background: '#E0F0FF',
    borderRadius: '100px',
    '& div': {
      color: '#19528C',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  speciliationFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0 7px',
  },
}))

const SupplierDetails = () => {
  const classes = useStyles()
  const param = useParams()
  const navigate = useNavigate()
  const { supplierDetail } = useSupplierdetails(param.id)
  const equipsArray = []
  if (supplierDetail?.equips?.length > 0) {
    supplierDetail?.equips?.map((obj) =>
      equipsArray.push({ name: obj.name, parts: obj?.parts?.[0]?.id === null ? [] : obj?.parts })
    )
  }
  const address = supplierDetail?.cmp_addr?.map((obj) => obj)?.[0]
  return (
    <Container className={classes.root}>
      <Container className="landing-header">
        <Container className="container">
          <LandingPageHeader />
        </Container>
      </Container>
      <Container className="detailpage-body">
        <Container className="container">
          <Container className={'detailPageHeader'}>
            <Typography variant="caption" color={getColor('grey-g35')} component="p">
              <Container onClick={() => navigate('/supplier')}>Suppliers</Container> / {supplierDetail?.name}
            </Typography>
            <Typography component="h1">{supplierDetail?.name}</Typography>
          </Container>
          <Container className={'profileMain'}>
            <Container className="profile-banner">
              <Image src={supplierDetail?.img_header || HeaderPlaceHolder} />
            </Container>
            <Container className={classes.profileInner} gap={'30px 0'}>
              <Image src={supplierDetail?.logo} width={120} height={120} className={classes.profileImage} />
              <Container className={'user-personal-detail'} gap="10px 0">
                <Container className="username">
                  <Container>
                    <Typography variant="h2" component="h2" className={classes.eqpName} color={getColor('grey-g25')}>
                      {supplierDetail?.name}
                      {supplierDetail?.is_verified && <Image src={Verfied} />}
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g25')}>
                      {supplierDetail?.tagline}
                    </Typography>
                  </Container>
                  <Container flex>
                    {supplierDetail?.is_premium && <Chip className={classes.premimumChip} label="Premium" />}
                    {supplierDetail?.early_adopt && <Image src={earlyBadge} />}
                  </Container>
                </Container>
                <Container flex justify="flex-start" gap={'0 5px'}>
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    {supplierDetail?.year_found && `Est. ${supplierDetail?.year_found} •`}{' '}
                    {/* {supplierDetail?.cmp_billing_addr.map((adr) => `${adr.city}, ${adr.country_abbrv}`)[0]} */}
                    {getShortAddress(address?.city, address?.state, address?.country)}
                  </Typography>
                  {getFlag(address?.country)}
                </Container>
                <Container className="parts-chip-and-social">
                  <Container flex justify="flex-start" gap={'0 10px'}>
                    {supplierDetail?.industries &&
                      supplierDetail.industries.map((ind, index) => <Chip key={index} label={ind.name} />)}
                  </Container>
                  <Container flex gap={'0 10px'} justify="flex-end">
                    {supplierDetail?.linkedin_url && !supplierDetail?.linkedin_url?.includes('null') && (
                      <a href={supplierDetail?.linkedin_url} target="_blank" rel="noopener noreferrer">
                        {' '}
                        <Image src={LinkedIn} />
                      </a>
                    )}
                    {supplierDetail?.twitter_url && !supplierDetail?.twitter_url?.includes('null') && (
                      <a href={supplierDetail?.twitter_url} target="_blank" rel="noopener noreferrer">
                        <Image src={Twitter} />
                      </a>
                    )}
                  </Container>
                </Container>
                <Container flex justify="space-between" className={'visitWebsiteRoot'}>
                  {/* <Button icon={<WebsiteIcon />} className={classes.visitWebsite} variant="transparent">
                    Visit Website
                  </Button> */}
                  <a
                    className={'visitWebsite'}
                    href={supplierDetail?.website_url ? supplierDetail?.website_url : ''}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Website} alt="website" /> Visit Website
                  </a>

                  <Container>
                    <a
                      href={supplierDetail?.email ? `mailto:${supplierDetail?.email}` : ''}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {`${supplierDetail?.email} •`}
                      </Typography>
                    </a>
                    &nbsp;
                    <a
                      href={supplierDetail?.phone_number ? `tel:${supplierDetail?.phone_number}` : ''}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {supplierDetail?.phone_number?.replace('-', ' ')}
                      </Typography>
                    </a>
                  </Container>
                </Container>
              </Container>
              {supplierDetail?.about && (
                <>
                  <Seperator />
                  <Container className={classes.commonFlex} direction="column" gap="8px 0">
                    <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
                      About
                    </Typography>
                    <Container>
                      <ReadMoreContentToggle wordSlice="350">{supplierDetail?.about}</ReadMoreContentToggle>
                    </Container>
                  </Container>
                </>
              )}

              <Seperator />
              {supplierDetail?.dlr_brands?.length > 0 && (
                <>
                  <Container className={classes.commonFlex} gap={'18px 0'} direction={'column'}>
                    <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
                      Authorized OEM Dealer
                    </Typography>
                    <Container className="authroize-dealer-grid">
                      {supplierDetail?.dlr_brands.map((db, index) => (
                        <Container className={classes.dealerItem} key={index} flex justify="space-between" gap="0 10px">
                          <Image src={db?.cover_image || Placeholder} />
                          <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                            {db.name}
                          </Typography>
                        </Container>
                      ))}
                    </Container>
                  </Container>
                  <Seperator />
                </>
              )}
              {supplierDetail?.disti_brands?.length > 0 && (
                <>
                  <Container className={classes.commonFlex} gap={'18px 0'} direction={'column'}>
                    <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
                      Authorized Product Line Distributor
                    </Typography>
                    <Container className="authroize-dealer-grid">
                      {supplierDetail?.disti_brands.map((db, index) => (
                        <Container className={classes.dealerItem} key={index} flex justify="space-between" gap="0 10px">
                          <Image src={db?.cover_image || Placeholder} />
                          <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                            {db.name}
                          </Typography>
                        </Container>
                      ))}
                    </Container>
                  </Container>
                  {equipsArray?.length > 0 && <Seperator />}
                </>
              )}
              {equipsArray?.length > 0 && (
                <Container className={classes.commonFlex} direction={'column'} gap={'18px 0'}>
                  <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
                    Specialization In
                  </Typography>
                  {equipsArray?.map((obj, index) => (
                    <>
                      <Container className={classes.speciliationFlex} justify="flex-start" gap={'0 7px'} key={index}>
                        <Chip className={classes.activeSpecilization} label={obj?.name} />
                        <Container className={classes.speciliationgrid} gap={'10px 7px'} wrap>
                          {obj?.parts?.map((obj2, index) => (
                            <Chip label={obj2?.name} key={index} />
                          ))}
                        </Container>
                      </Container>
                    </>
                  ))}
                </Container>
              )}
            </Container>
          </Container>
        </Container>
      </Container>
      <LandingPageFooter />
    </Container>
  )
}

export default SupplierDetails
