import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import rightArrow from 'assets/images/rihgt_white_arrow.svg'
// import leftArrow from 'assets/images/leftArrow.png'
import leftArrow from 'assets/images/left_white_arrow.svg'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    '& .slick-slide': {
      margin: ' 0 10px',
      width: '78px!important',
    },
    '& .slick-list': {
      margin: '0 -10px',
    },
    '& .slick-current': {
      '& img': {
        border: '1px solid #FF8C1A',
      },
    },

    '& button:first-child': {
      left: '0',
      backgroundColor: '#13141bbd',
      zIndex: '99',
      width: '30px',
      height: '78px',
      borderRadius: '4px 0 0 4px',
      display: 'flex!important',
      alignItems: 'center',
      justifyContent: 'center',
      //   position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        backgroundImage: `url(${leftArrow})`,
        backgroundRepeat: 'no-repeat',
        width: '9px',
        height: '14px',
      },
      '& .slick-active': {
        backgroundColor: 'red',
      },
    },
    '& > button:nth-of-type(2)': {
      right: '0',
      zIndex: '99',
      backgroundColor: '#13141bbd',
      width: '30px',
      height: '78px',
      borderRadius: '0 4px 4px 0px',
      display: 'flex!important',
      alignItems: 'center',
      justifyContent: 'center',
      //   position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        backgroundImage: `url(${rightArrow})`,
        backgroundRepeat: 'no-repeat',
        width: '9px',
        height: '14px',
      },
    },
    '& ul': {
      bottom: '-45px',
    },
    '& li ': {
      width: 'unset!important',
      height: 'unset!important',
      margin: '0 7px!important',
      borderRadius: '100%',
      '& button': {
        width: '6px!important',
        height: '6px!important',
        fontSize: 'unset!important',

        padding: 'unset!important',
        '&:before': {
          content: 'unset!important',
        },
      },
    },
    textAlign: 'center',
  },
  imgResolution: {
    maxWidth: (props) => props.maxWidth || '78px!important',
    height: (props) => props.height || '78px',
    // margin: '0 auto',
    borderRadius: '4px',
    objectFit: 'cover',
    '& img': {
      width: '78px',
      height: '78px',
      objectFit: 'contain',
      borderRadius: '7px',
      border: '1px solid #F0F0F4',
    },
  },
}))

export default function ProductSlider({
  className,
  infinite,
  speed,
  maxWidth,
  height,
  sliderData,
  slickGoto,
  setCurrentIndex,
}) {
  const classes = useStyles({ maxWidth, height })
  const slickRef = useRef(null)

  useEffect(() => {
    if (slickRef.current?.slickGoTo) slickRef.current.slickGoTo(slickGoto)
  }, [slickGoto])

  // sliderData?.filter((data) => data.id === active + 1).map((value) => value.src)

  const settings = {
    dots: false,
    infinite: infinite || true,
    speed: speed || 500,
    slidesToShow: 4,
    arrows: true,
    slidesToScroll: 1,
    accessibility: true,
    focusOnSelect: true,
    draggable: true,
    swipeToSlide: true,
    beforeChange: (current, next) => setCurrentIndex(next),
    // afterChange: (current) => setCurrentIndex(current + 1),
  }

  return (
    <Slider {...settings} className={classNames(className, classes.root)} ref={slickRef}>
      {sliderData?.map((data, index) => (
        <Container className={classes.imgResolution} key={index}>
          <img src={data} alt={data} />
        </Container>
      ))}
    </Slider>
  )
}

ProductSlider.propTypes = {
  className: PropTypes.string,
  dot: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  sliderData: PropTypes.array,
  setActive: PropTypes.func,
  url: PropTypes.string,
  index: PropTypes.number,
  slickGoto: PropTypes.number,
  setCurrentIndex: PropTypes.func,
}
