import { makeStyles } from '@mui/styles'
import { CaretLeftIcon, EarlyBadge } from 'assets/icons'
import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Website from 'assets/icons/Website.svg'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import HeaderPlaceHolder from 'assets/images/HeaderPlaceHolder.png'
import Verfied from 'assets/icons/Verfied.svg'
import LinkedIn from 'assets/icons/linkedin-color.svg'
import Twitter from 'assets/icons/twitter-color.svg'
import Placeholder from 'assets/images/Placeholder.png'
import { useNavigate, useParams } from 'react-router-dom'
import ReadMoreContentToggle from 'components/custom/ReadMoreContentToggle'
import useSupplierdetails from 'common-hooks/super-admin/useSupplierDetails'
import Tooltip from 'components/common/Tooltip'
import { getFlag, getShortAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: '#fff',
    // height: '93vh',
    paddingBottom: '20px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  previousBtn: {
    border: '1px solid',
    borderColor: getColor('grey-g65'),
    width: '36px',
    height: '36px',
  },
  detailPageHeader: {
    display: 'flex',
    padding: '30px',
    gap: '0 10px',
    alignItems: 'center',
    borderBottom: ' 1px solid #F0F0F4',
    position: ' sticky',
    top: '61px',
    zIndex: ' 99',
    background: ' #fff',
  },
  profileMain: {
    maxWidth: '1000px',
    margin: '30px auto',
    border: '1px solid #F0F0F4',
    background: '#fff',
    // marginBottom: '20px',
  },
  profileImage: {
    border: '1px solid #F0F0F4',
    borderRadius: ' 4px',
    marginTop: ' -90px',
    background: '#fff',
  },
  profileInner: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  premimumChip: {
    height: '22px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    color: '#13141B',
    borderRadius: '100px',
    '& div': {
      color: '#13141B',
      fontSize: '11px',
    },
  },
  eqpName: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 7px',
  },
  visitWebsite: {
    color: getColor('grey-g35'),
    border: '1px solid #9799B5',
    width: '145px',
    '& div': {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  commonFlex: {
    display: 'flex',
  },
  visitWebsiteRoot: {
    paddingTop: '5px',
  },
  viewMore: {
    fontWeight: '400',
    height: 'unset',
    padding: '0',
    minHeight: 'unset',
    '&:hover': {
      color: getColor('func-info'),
      backgroundColor: '#fff',
    },
    '& span': {
      color: getColor('func-info'),
      textDecoration: 'underline',
      padding: '0',
    },
  },
  dealerItem: {
    padding: ' 10px 15px 10px 10px',
    width: 'auto',
    height: '60px',
    marginTop: '5px',
    border: '1px solid #F0F0F4',
    '& img': {
      border: '1px solid #F0F0F4',
      width: '40px',
      height: '40px',
    },
  },
  activeSpecilization: {
    background: '#E0F0FF',
    borderRadius: '100px',
    '& div': {
      color: '#19528C',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  speciliationFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0 7px',
  },
  speciliationgrid: {
    display: 'flex',
    flexFlow: 'wrap',
  },

  paraViewless: {
    display: '-webkit-box',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: getColor('grey-g35'),
  },
  paraViewmore: {
    color: getColor('grey-g35'),
  },
  dealerBrands: {
    flexWrap: 'wrap',
  },
}))

const BuyerSupplierDetails = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const { supplierDetail } = useSupplierdetails(params.id)
  const equipsArray = []
  if (supplierDetail?.equips?.length > 0) {
    supplierDetail?.equips?.map((obj) =>
      equipsArray.push({ name: obj.name, parts: obj?.parts?.[0]?.id === null ? [] : obj?.parts })
    )
  }
  const address = supplierDetail?.cmp_addr?.map((obj) => obj)?.[0]

  return (
    <Container className={classes.root}>
      <Container className={classes.detailPageHeader}>
        <Button
          variant="transparent"
          className={classes.previousBtn}
          icon={<CaretLeftIcon />}
          onClick={() => navigate('/suppliers')}
        />
        <Typography variant="h1" component="h1" color={getColor('grey-g25')}>
          {supplierDetail?.name}
        </Typography>
      </Container>
      <Container className={classes.profileMain}>
        <Container>
          <Image
            src={supplierDetail?.img_header ? supplierDetail?.img_header : HeaderPlaceHolder}
            height={'196px'}
            width={'1000px'}
          />
        </Container>
        <Container className={classes.profileInner} gap={'30px 0'}>
          <Image src={supplierDetail?.logo} width={120} height={120} className={classes.profileImage} />
          <Container className={classes.commonFlex} direction={'column'} gap="10px 0">
            <Container flex justify="space-between">
              <Container>
                <Typography variant="h2" component="h2" className={classes.eqpName} color={getColor('grey-g25')}>
                  {supplierDetail?.name}
                  {supplierDetail?.is_verified && <Image src={Verfied} />}
                </Typography>
                <Typography variant="body1" component={'p'} color={getColor('grey-g25')}>
                  {supplierDetail?.tagline}
                </Typography>
              </Container>
              <Container flex>
                {supplierDetail?.is_premium && <Chip className={classes.premimumChip} label="Premium" />}
                {supplierDetail?.early_adopt && (
                  <Tooltip placement={'right-start'} title={'Early Adopt'}>
                    <EarlyBadge />
                  </Tooltip>
                )}
              </Container>
            </Container>
            <Container flex justify="flex-start" gap={'0 5px'}>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {supplierDetail?.year_found && `Est. ${supplierDetail?.year_found} •`}{' '}
                {/* {supplierDetail?.cmp_billing_addr.map((adr) => `${adr.city}, ${adr.country_abbrv}`)[0]} */}
                {getShortAddress(address?.city, address?.state, address?.country)}
              </Typography>
              {getFlag(address?.country)}
            </Container>
            <Container flex justify="space-between">
              <Container flex justify="flex-start" gap={'0 10px'}>
                {supplierDetail?.industries &&
                  supplierDetail.industries.map((ind, index) => <Chip key={index} label={ind.name} />)}
              </Container>
              <Container flex gap={'0 10px'} justify="flex-end">
                {supplierDetail?.linkedin_url && !supplierDetail?.linkedin_url?.includes('null') && (
                  <a href={supplierDetail?.linkedin_url} target="_blank" rel="noopener noreferrer">
                    {' '}
                    <Image src={LinkedIn} />
                  </a>
                )}
                {supplierDetail?.twitter_url && !supplierDetail?.twitter_url?.includes('null') && (
                  <a href={supplierDetail?.twitter_url} target="_blank" rel="noopener noreferrer">
                    <Image src={Twitter} />
                  </a>
                )}
              </Container>
            </Container>
            <Container flex justify="space-between" className={classes.visitWebsiteRoot}>
              {supplierDetail?.website_url && (
                <a
                  className={'visitWebsite'}
                  href={supplierDetail?.website_url ? supplierDetail?.website_url : ''}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Website} alt="website" /> Visit Website
                </a>
              )}

              <Container>
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  {`${supplierDetail?.email} •`}
                </Typography>
                &nbsp;
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  {`${supplierDetail?.phone_number}`}
                </Typography>
              </Container>
            </Container>
          </Container>

          {supplierDetail?.about && (
            <>
              <Seperator />
              <Container className={classes.commonFlex} direction="column" gap="8px 0">
                <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
                  About
                </Typography>
                <Container>
                  <ReadMoreContentToggle wordSlice="350">{supplierDetail?.about}</ReadMoreContentToggle>
                </Container>
              </Container>
            </>
          )}
          {supplierDetail?.dlr_brands?.length > 0 && (
            <>
              <Seperator />
              <Container className={classes.commonFlex} gap={'18px 0'} direction={'column'}>
                <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
                  Authorized OEM Dealer
                </Typography>
                <Container flex justify="flex-start" gap={'0 20px'} className={classes.dealerBrands}>
                  {supplierDetail?.dlr_brands.map((db, index) => (
                    <Container className={classes.dealerItem} key={index} flex justify="space-between" gap="0 10px">
                      <Image src={db?.cover_image || Placeholder} />
                      <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                        {db.name}
                      </Typography>
                    </Container>
                  ))}
                </Container>
              </Container>
            </>
          )}
          {supplierDetail?.disti_brands?.length > 0 && (
            <>
              <Seperator />
              <Container className={classes.commonFlex} gap={'18px 0'} direction={'column'}>
                <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
                  Authorized Product Line Distributor
                </Typography>
                <Container flex justify="flex-start" gap={'0 20px'} className={classes.dealerBrands}>
                  {supplierDetail?.disti_brands &&
                    supplierDetail?.disti_brands.map((db, index) => (
                      <Container className={classes.dealerItem} key={index} flex justify="space-between" gap="0 10px">
                        <Image src={db?.cover_image || Placeholder} />
                        <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                          {db.name}
                        </Typography>
                      </Container>
                    ))}
                </Container>
              </Container>
            </>
          )}

          {equipsArray?.length > 0 && (
            <>
              <Seperator />
              <Container className={classes.commonFlex} direction={'column'} gap={'18px 0'}>
                <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
                  Specialization In
                </Typography>
                {equipsArray?.map((obj, index) => (
                  <>
                    <Container className={classes.speciliationFlex} justify="flex-start" gap={'0 7px'} key={index}>
                      <Chip className={classes.activeSpecilization} label={obj?.name} />
                      <Container className={classes.speciliationgrid} gap={'10px 7px'} wrap>
                        {obj?.parts?.map((obj2, index) => (
                          <Chip label={obj2?.name} key={index} />
                        ))}
                      </Container>
                    </Container>
                  </>
                ))}
              </Container>
            </>
          )}
        </Container>
      </Container>
    </Container>
  )
}

export default BuyerSupplierDetails
