import { setUserPassword } from 'common-api/user-profile/getUserProfile'
import useToastify from 'common-hooks/useToastify'

export default function useUserPassword() {
  const { toastMessage } = useToastify()
  const updatePassword = async (data) => {
    try {
      const response = await setUserPassword(data)
      toastMessage('success', 'Password changed successfully')
      return response
    } catch {
      return false
    }
  }

  return { updatePassword }
}
