import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import AuthCode from 'react-auth-code-input'

export default function VerificationCode({ setCodeValue }) {
  const AuthInputRef = useRef(null)

  const handleOnChange = (res) => {
    setCodeValue(res)
  }
  return (
    <>
      <AuthCode autoFocus={false} onChange={handleOnChange} ref={AuthInputRef} allowedCharacters="numeric" />
    </>
  )
}
VerificationCode.propTypes = {
  setCodeValue: PropTypes.func,
}
