import { makeStyles } from '@mui/styles'
import Chip from 'components/common/Chip'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Typography from 'components/common/Typography'
import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  imageCardRoot: {
    border: ' 1px solid #F0F0F4',
    width: '250px',
    height: 'auto',
  },
  imageCardContent: {
    padding: '15px 20px',
  },
  cardImage: {
    display: 'flex',
    height: '110px',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: ' 1px solid #F0F0F4',
    padding: '15px 0',
    '& img': {
      width: '50px',
      height: '50px',
      objectFit: 'contain',
    },
  },
  activeChip: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    borderRadius: ' 100px',
  },
  chip: {
    background: '#E0F0FF',
    color: '#19528C',
    borderRadius: ' 100px',
  },
  desablechip: {
    background: '#F0F0F4',
    color: '#36384C',
    borderRadius: ' 100px',
  },
  imageCardInner: {
    position: 'relative',
  },
  inactiveChip: {
    background: '#FFEBEE',
    color: '#7A1626 !important',
    borderRadius: ' 100px',
    '& div': {
      color: '#7A1626 !important',
    },
  },
}))

const CourierImageCard = ({ toogleSwitch = true, active, carriername, image }) => {
  const classes = useStyles({ toogleSwitch })

  return (
    <>
      <Container className={classes.imageCardRoot}>
        <Container className={classes.imageCardInner}>
          <Container className={classes.cardImage}>
            <Image src={image} />
          </Container>
          <Container className={classes.activeChip}>
            {active === true ? (
              <Chip label="Active" className={!toogleSwitch ? classes.desablechip : classes.chip} />
            ) : (
              <Chip label="Inactive" className={!toogleSwitch ? classes.desablechip : classes.inactiveChip} />
            )}
          </Container>
          <Container className={classes.imageCardContent}>
            {active === true && (
              <Typography
                Typography="body1"
                color={toogleSwitch ? getColor('grey-g25') : getColor('grey-g65')}
                component="p"
              >
                {carriername}
              </Typography>
            )}
            {active === false && (
              <Typography Typography="body1" color={getColor('grey-g65')} component="p">
                {carriername}
              </Typography>
            )}

            {/* <Typography
              Typography="paragraph1"
              color={toogleSwitch ? getColor('grey-g35') : getColor('grey-g65')}
              component="p"
            >
              219718909
            </Typography> */}
          </Container>
        </Container>
      </Container>
    </>
  )
}

export default CourierImageCard
CourierImageCard.propTypes = {
  toogleSwitch: PropTypes.bool,
  active: PropTypes.bool,
  carriername: PropTypes.string,
  image: PropTypes.string,
}
