import React from 'react'
import { makeStyles } from '@mui/styles'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  imgResolution: {},
}))

export default function TestinomialSlider({ className, speed, maxWidth, height, children }) {
  const classes = useStyles({ maxWidth, height })

  const settings = {
    dots: false,
    infinite: true,
    speed: speed || 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    accessibility: true,
    focusOnSelect: true,
    draggable: true,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Slider {...settings} className={classNames(className, classes.root)}>
      {children}
    </Slider>
  )
}

TestinomialSlider.propTypes = {
  className: PropTypes.string,
  dot: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  sliderData: PropTypes.array,
  setActive: PropTypes.func,
  url: PropTypes.string,
  index: PropTypes.number,
  slickGoto: PropTypes.number,
  children: PropTypes.node,
}
