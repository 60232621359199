import { makeStyles } from '@mui/styles'
import { EllipsisHorizontalIcon, PdfIcon } from 'assets/icons'
import Button from 'components/common/Button'
import { saveAs } from 'file-saver'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import React from 'react'
import { getColor } from 'theme/colors'
import PropTypes from 'prop-types'
import ContextMenu from '../drop-downs/ContextMenu'
import ContextMenuItem from '../drop-downs/ContextMenuItem'
import List from '../list/List'

const useStyles = makeStyles(() => ({
  pdfCardRoot: {
    width: ' 383px',
    height: ' 80px',
    border: ' 1px solid #F0F0F4',
    borderRadius: ' 4px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    marginBottom: 0,
  },
  listItemDelete: {
    color: getColor('func-alert'),
    marginBottom: 0,
  },
  iconBtn: {
    backgroundColor: 'transparent!important',
    padding: '0',
  },
  pdfCardinner: {
    display: 'flex',
    width: '100%',
    gap: '0 10px',
  },
  editList: {
    padding: 0,
  },
}))

const PdfCard = ({ fileName, date, removeFile, url }) => {
  const classes = useStyles()
  const downloadImage = () => {
    saveAs(url, `${fileName}.pdf`)
  }
  return (
    <Container>
      <Container className={classes.pdfCardRoot}>
        <Container className={classes.pdfCardinner}>
          <Container>
            <PdfIcon />
          </Container>
          <Container width="68%">
            <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
              {fileName}
            </Typography>
            <Typography variant="paragraph2" color={getColor('grey-g65')} component={'p'}>
              Last Uploaded: {date}
            </Typography>
          </Container>
        </Container>
        <ContextMenu
          className={classes.editList}
          closeOnClick
          trigger={<Button className={classes.iconBtn} icon={<EllipsisHorizontalIcon />} />}
        >
          <Container className={classes.lists}>
            <ContextMenuItem className={classes.listItem}>
              <List title={'Download'} type={'link'} onClick={downloadImage} />
            </ContextMenuItem>
            <ContextMenuItem className={classes.listItemDelete}>
              <List title={'Delete'} type={'link'} onClick={removeFile} txtColor={getColor('func-alert')} />
            </ContextMenuItem>
          </Container>
        </ContextMenu>
      </Container>
    </Container>
  )
}
export default PdfCard

PdfCard.propTypes = {
  fileName: PropTypes.string,
  date: PropTypes.string,
  removeFile: PropTypes.bool,
  url: PropTypes.string,
}
