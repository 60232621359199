import { createContext } from 'react'
import { createOption } from 'components/common/text/DropDownTextField'
import { capitalizeFirstLetter } from 'utils/Utils'

export const CreateSupplierContext = createContext({
  onSelectNode: () => {},
  specializationTreeViewData: [],
})

// =================== Treeview Counts =======================
let nodeCountArray = []
let equipId
export function getNodeCounts(items, equipmentId) {
  nodeCountArray = []
  equipId = equipmentId

  function getAllNodes(items, pId = null) {
    if (Array.isArray(items)) {
      items.map((child) => {
        let parentId = pId
        const node = {
          id: child.id,
          name: child.name,
          parent_id: pId,
          equip_id: equipId,
          count: 0,
        }
        nodeCountArray.push(node)
        if (child.children && child.children.length > 0) {
          parentId = child.id
          getAllNodes(child.children, parentId)
        }
        return 0
      })
    } else {
      const node = {
        id: items.id,
        name: items.name,
        parent_id: pId,
        equip_id: equipId,
        count: 0,
      }
      nodeCountArray.push(node)
    }
    return nodeCountArray
    // return calculateCounts(nodeCountArray)
  }

  // function calculateCounts(list) {
  //   list?.forEach((obj1) => {
  //     const arr = []
  //     list?.forEach((obj2) => {
  //       if (
  //         obj1.id === obj2.parent_id ||
  //         obj1.id === obj2.id ||
  //         arr.includes(obj2.id) ||
  //         arr.includes(obj2.parent_id)
  //       ) {
  //         arr.push(obj2.id)
  //       }
  //       return 0
  //     })
  //     obj1.count = arr.length
  //     return 0
  //   })
  //   return list
  // }

  return getAllNodes(items)
}
export function getAllNodes(items, pId = null) {
  if (Array.isArray(items)) {
    items.map((child) => {
      let parentId = pId
      const node = {
        id: child.id,
        name: child.name,
        parent_id: pId,
        equip_id: equipId,
        count: 0,
      }
      nodeCountArray.push(node)
      if (child.children && child.children.length > 0) {
        parentId = child.id
        getAllNodes(child.children, parentId)
      }
      return 0
    })
  } else {
    const node = {
      id: items.id,
      name: items.name,
      parent_id: pId,
      equip_id: equipId,
      count: 0,
    }
    nodeCountArray.push(node)
  }
  return calculateCounts(nodeCountArray)
}
export function calculateCounts(list) {
  list?.forEach((obj1) => {
    const arr = []
    list?.forEach((obj2) => {
      if (obj1.id === obj2.parent_id || obj1.id === obj2.id || arr.includes(obj2.id) || arr.includes(obj2.parent_id)) {
        arr.push(obj2.id)
      }
      return 0
    })
    obj1.count = arr.length
    return 0
  })
  return list
}
// ================== End ======================

// ================== supplier data parsing ==================

export function addSupplierParseData(data) {
  const phoneNumber = `+${data?.prefix}-${data?.phone_number}`.toString()
  const cmpAddr = {
    ...data?.cmp_addr,
    latitude: data?.cmp_addr?.latitude?.toString(),
    longitude: data?.cmp_addr?.longitude?.toString(),
  }
  const cmpBillingAddr =
    data?.same_address === true
      ? {
          ...data?.cmp_addr,
          latitude: data?.cmp_addr?.latitude?.toString(),
          longitude: data?.cmp_addr?.longitude?.toString(),
        }
      : {
          ...data?.cmp_billing_addr,
          latitude: data?.cmp_billing_addr?.latitude?.toString(),
          longitude: data?.cmp_billing_addr?.longitude?.toString(),
        }
  const parsedData = {
    ...(data?.id && { id: data?.id }),

    ...{ is_verified: data?.is_verified === true },
    ...{ is_premium: data?.is_premium === true },
    // ...{ early_adopt: data?.early_adopt === true },

    // ...(data?.year_found?.value && { year_found: data?.year_found?.value }),
    year_found: data?.year_found === '' ? null : data?.year_found,
    // ...(data?.status?.value === 'Active' && { status: 'active' }),
    // ...(data?.status?.value === 'Inactive' && { status: 'inactive' }),
    ...(data?.status?.value && { status: data?.status?.value }),

    ...(data?.name && { name: data?.name }),
    ...(data?.tagline === '' ? { tagline: null } : { tagline: data?.tagline }),
    ...(data?.about === '' ? { about: null } : { about: data?.about }),
    ...(data?.email && { email: data?.email }),
    ...(phoneNumber && { phone_number: phoneNumber }),
    ...(data?.website_url && { website_url: `https://${data?.website_url}` }),
    ...(data?.linkedin_url === '' ? { linkedin_url: null } : { linkedin_url: `https://${data?.linkedin_url}` }),
    ...(data?.twitter_url === '' ? { twitter_url: null } : { twitter_url: `https://${data?.twitter_url}` }),
    ...(data?.facebook_url === '' ? { facebook_url: null } : { facebook_url: `https://${data?.facebook_url}` }),
    ...{ dlr_brands: data?.dlr_brands },
    ...{ disti_brands: data?.disti_brands },
    ...(data?.add_parts && { add_parts: data?.add_parts }),
    ...(data?.equips?.length > 0 ? { equips: data?.equips } : { equips: [] }),
    ...(data?.del_equips?.length > 0 ? { del_equips: data?.del_equips } : { del_equips: [] }),
    ...(data?.industries?.length > 0 && { industries: data?.industries }),
    ...(data?.cmp_ops_hrs && { cmp_ops_hrs: data?.cmp_ops_hrs }),
    ...(data?.cmp_addr && { cmp_addr: cmpAddr }),
    cmp_ops_hrs: oprationHoursDefaultObj,
    ...(data?.quote_req === true ? { quote_req: true } : { quote_req: false }),
    ...((data?.cmp_billing_addr || data?.same_address) && { cmp_billing_addr: cmpBillingAddr }),
  }
  return JSON.stringify(JSON.stringify(parsedData))
  // return 0
}

export const parsedBrandOptions = (id, name, image) => ({ id, name, image })
export const parseSupplierDetails = (data) => {
  const res = {}
  Object.keys(data).map((key) => {
    if (key.includes('url')) {
      res[key] = data[key] ? data[key].replace('https://null', '').replace('https://', '') : null
    } else if (['status'].includes(key)) {
      res[key] = createOption((data[key] || '').toLowerCase(), capitalizeFirstLetter(data[key]))
    } else if (key === 'equips') {
      const treeview = []
      const parts = []
      data[key]?.map((obj) => {
        const children = []
        obj.parts.map((part) => {
          if (part?.count !== undefined && !part?.parent_id) {
            children.push({
              id: part.id,
              name: part.name,
              equip_id: obj.id,
              parent_id: null,
              count: part.count,
            })
          }
          parts.push({
            id: part.id,
            name: part.name,
            equip_id: obj.id,
            parent_id: part.parent_id,
            count: part.count,
          })
          return 0
        })
        treeview.push({
          id: obj.id,
          name: obj.name,
          children:
            children?.length === 0 ? [{}] : children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i),
        })
        return 0
      })
      res[key] = treeview
      res.parts = parts.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
    } else if (key.includes('addr')) {
      const obj = data[key][0]
      delete obj?.phone
      delete obj?.type
      delete obj?.business_name
      delete obj?.company_name
      res[key] = obj
      res[`${key}_latitude`] = data[key]?.[0]?.latitude
      res[`${key}_longitude`] = data[key]?.[0]?.longitude
    } else {
      res[key] = data[key]
    }
    if (key === 'phone_number' && data[key]) {
      const split = data[key]?.split('-')
      res.prefix = data[key]?.includes('+') ? split?.[0]?.replace('+', '') : '1'
      res.phone_number = data[key]?.includes('+') ? split?.[1] : data[key]
    }
    return 0
  })
  return res
}
// ================== End ======================

export function getEquipAndPartsUpdatedData(id, equipsObj, supplierDetail, specializationPartsData) {
  const addParts = []
  let equips = []
  if (id) {
    equipsObj?.equips?.map((val) => {
      let isNotAvailable = true
      supplierDetail?.equips?.map((obj) => {
        if (obj.id === val) isNotAvailable = false
        return 0
      })
      if (isNotAvailable) equips.push(val)
      return 0
    })
  } else {
    equips = equipsObj?.equips
  }

  let delEquips = []
  if (id) {
    equipsObj?.del_equips?.map((val) => {
      let isNotAvailable = false
      supplierDetail?.equips?.map((obj) => {
        if (obj.id === val) isNotAvailable = true
        return 0
      })
      if (isNotAvailable) delEquips.push(val)
      return 0
    })
  } else {
    delEquips = equipsObj?.del_equips
  }

  specializationPartsData?.map((obj) => {
    if (obj.id) {
      addParts.push({
        id: obj.id,
        parent_id: obj.parent_id,
        equip_id: obj.equip_id,
        count: obj.count,
      })
    }
    return 0
  })

  return {
    add_parts: addParts,
    equips,
    del_equips: delEquips,
  }
}

export const Status = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
]

export const yearFounded = [
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
  { value: '2020', label: '2020' },
  { value: '2019', label: '2019' },
  { value: '2018', label: '2018' },
  { value: '2017', label: '2017' },
  { value: '2016', label: '2016' },
  { value: '2015', label: '2015' },
  { value: '1998', label: '1998' },
]

// Opration Hours default object

export const oprationHoursDefaultObj = {
  mon_open_status: false,
  mon_open_time: '10:00 AM',
  mon_close_time: '10:00 PM',
  tue_open_status: false,
  tue_open_time: '10:00 AM',
  tue_close_time: '10:00 PM',
  wed_open_status: false,
  wed_open_time: '10:00 AM',
  wed_close_time: '10:00 PM',
  thu_open_status: false,
  thu_open_time: '10:00 AM',
  thu_close_time: '10:00 PM',
  fri_open_status: false,
  fri_open_time: '10:00 AM',
  fri_close_time: '10:00 PM',
  sat_open_status: false,
  sat_open_time: '10:00 AM',
  sat_close_time: '10:00 PM',
  sun_open_status: false,
  sun_open_time: '10:00 AM',
  sun_close_time: '10:00 PM',
}
