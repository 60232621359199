import { makeStyles } from '@mui/styles'
import { Chip as MuiChip } from '@mui/material'
import { getColor } from 'theme/colors'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CloseIcon } from 'assets/icons'
import Container from 'components/common/Container'
import IconButton from './IconButton'

const useStyles = makeStyles({
  root: (props) => ({
    alignItems: 'center',
    // padding: '4px 12px',
    height: props.height || '22px',
    width: props.width || 'auto',
    backgroundColor: getBgColor(props.variant),

    borderRadius: '100px',
    lineHeight: '16px',
    verticalAlign: 'top',
    color: getTextColor(props.variant),
    // marginBottom: props.margin || '5px',
    fontWeight: 400,
    maxWidth: ' max-content',
    /* padding: 0 10px; */
    display: 'inline-block',
    paddingLeft: ' 10px',
    paddingRight: props.removable ? '20px' : ' 10px',
    '& span': {
      padding: '0',
      fontSize: '11px',
    },
    '& svg *': {
      position: 'absolute',
      left: '45px',
      top: '1px',
    },
  }),
  icon: {
    position: 'absolute',
    right: '0',
    height: '100%',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  actions: {
    position: 'relative',
  },
  chip: (props) => ({
    width: '100%',
    backgroundColor: 'unset',
    color: getTextColor(props.variant),
    height: '100%',
  }),
})
const Chip = ({ label, width, className, variant, removable, onRemove }) => {
  const classes = useStyles({ variant, width, removable })
  return (
    <Container className={classNames(className || classes.root, classes.actions)}>
      <MuiChip label={label} className={classes.chip} variant={variant} />
      {removable && (
        <IconButton variant="transparent" size="sm" className={classes.icon} onClick={onRemove}>
          <CloseIcon />
        </IconButton>
      )}
    </Container>
  )
}
Chip.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string,
  removable: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'warning']),
  className: PropTypes.string,
  onRemove: PropTypes.func,
}

function getBgColor(variant) {
  switch (variant) {
    case 'primary':
      return getColor('grey-g95')
    case 'secondary':
      return getColor('blue-b25')
    case 'success':
      return getColor('green-g25')
    case 'error':
      return getColor('red-r25')
    case 'warning':
      return getColor('orange-o25')
    default:
      return getColor('grey-g95')
  }
}
function getTextColor(variant) {
  switch (variant) {
    case 'primary':
      return getColor('dark-d25')
    case 'secondary':
      return getColor('blue-b900')
    case 'success':
      return getColor('green-g900')
    case 'error':
      return getColor('red-r900')
    case 'warning':
      return getColor('orange-o900')
    default:
      return getColor('dark-d25')
  }
}

export default Chip
