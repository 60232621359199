import PropTypes from 'prop-types'
import { Radio as MuiRadio, FormControlLabel, Tooltip } from '@mui/material'
import Typography from 'components/common/Typography'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { getColor } from 'theme/colors'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    margin: theme.spacing(0, -2),
    ...(props.row && {
      marginRight: theme.spacing(4),
    }),
  }),
  label: {
    color: getColor('grey-g35'),
    // fontWeight: '700',
    '&.Mui-disabled': {
      color: 'inherit',
    },
    whiteSpace: 'nowrap',
  },
  radio: {
    padding: theme.spacing(1.5),
    color: getColor('grey-g65'),
    ' &.Mui-checked': {
      color: getColor('orange-main'),
    },
    '& svg:first-child': { transform: 'scale(1)' },
    '& svg': {
      fontSize: 'inherit',
      width: 16,
      height: 16,
      border: 1,
      '& path': {
        // fill: getColor('orange-main'),
      },
    },
  },
  disabled: {
    color: getColor('grey-g35'),
    opacity: 0.5,
  },
  txtLabel: {
    width: '136px',
    display: 'block',
  },
}))

export default function Radio({
  value,
  label,
  row,
  className,
  disabled,
  onChange,
  tooltip,
  checked,
  labelWidth,
  ...props
}) {
  const classes = useStyles({ row })
  const content = (
    <FormControlLabel
      classes={{
        root: classNames(classes.root, className),
        label: classes.label,
        disabled: classes.disabled,
      }}
      value={value}
      label={
        <>
          {labelWidth ? (
            <Typography color={getColor('grey-g35')} variant="body1" className={classes.txtLabel}>
              {label}
            </Typography>
          ) : (
            <Typography color={getColor('grey-g35')} variant="body1">
              {label}
            </Typography>
          )}
        </>
      }
      control={
        <MuiRadio
          className={classNames(classes.radio, className)}
          onChange={onChange}
          value={value}
          checked={checked}
        />
      }
      disabled={disabled}
      {...props}
    />
  )
  if (tooltip) {
    return <Tooltip title={tooltip}>{content}</Tooltip>
  }
  return content
}

Radio.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  row: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  checked: PropTypes.bool,
  labelWidth: PropTypes.string,
}
