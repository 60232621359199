import { getSupplierList, inviteSuppliersList } from 'common-api/create-quote/createQuote'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import useSearch from 'common-hooks/useSearch'
import useToastify from 'common-hooks/useToastify'

export default function useInviteSuppliers() {
  const { toastMessage } = useToastify()
  const { search, onSearch } = useSearch()
  const params = {
    page: 1,
    limit: 15,
    ...(search && { search }),
  }
  const { data } = useSwrInfinite('/services/supplier-list', params, getSupplierList)

  const inviteSuppliers = async (data, id, mutateInvited) => {
    const arrId = []
    data?.map((val) => arrId.push(val.id))
    const parsingData = { quote_id: id, company_ids: arrId }
    try {
      const response = await inviteSuppliersList(parsingData)
      toastMessage('success', 'Selected suppliers invited successfully')
      mutateInvited()
      return response
    } catch {
      return false
    }
  }
  return {
    data,
    inviteSuppliers,
    onSearch,
    search,
  }
}
