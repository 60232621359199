import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { ClearIcon, SearchFiledIcon } from 'assets/icons'
import useUncontrolledSearch from 'components/common/search/useUncontrolledSearch'
import { IconButton } from '@mui/material'
import TextField from '../text/TextField'

const useStyles = makeStyles(() => ({
  rightIcon: {
    padding: '0.125rem',
  },
}))

export default function SearchField({ id, onSearch = () => {}, className, ...props }) {
  const classes = useStyles()
  const { onType, onClear, ref, hasText } = useUncontrolledSearch(onSearch)

  const clearIcon = (props?.value && props?.value !== '') || hasText

  const clearAll = () => {
    onClear()
    if (props.clearPreserveState) props.clearPreserveState()
  }

  return (
    <TextField
      id={id}
      ref={ref}
      placeholder="Search"
      inputClassName={className}
      rightIcon={
        clearIcon ? (
          <ClearButton onClick={clearAll} className={classes.rightIcon} />
        ) : (
          <SearchFiledIcon className={classes.rightIcon} />
        )
      }
      onChange={onType}
      {...props}
    />
  )
}

SearchField.propTypes = {
  id: PropTypes.string,
  onSearch: PropTypes.func,
  value: PropTypes.string,
  clearPreserveState: PropTypes.func,
  className: PropTypes.string,
}

function ClearButton(props) {
  return (
    <IconButton size="sm" variant="transparent" {...props}>
      <ClearIcon />
    </IconButton>
  )
}
