import Button from 'components/common/Button'
import Link from 'components/common/Link'
import React from 'react'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router'
import { getColor } from 'theme/colors'
import SignInHeaderLogo from '../sign-in/SignInHeaderLogo'

const useStyles = makeStyles(() => ({
  divider: {
    width: '300px',
    backgroundColor: getColor('grey-g95'),
  },
  emailText: {
    marginBottom: '30px',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '434px',
  },
  placeholderPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  bottomLine: {
    color: getColor('grey-g65'),
    textAlign: 'center',
    maxWidth: '372px',
  },
  bottomLineLink: {
    color: getColor('grey-g65'),
    textDecoration: 'underline',
  },
}))

export default function ForgotLink() {
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <Container className={classes.placeholderPosition}>
      <Container className={classes.mainContainer} gap={'30px 0'}>
        <Container className={classes.headerLogo} gap={'8px'}>
          <SignInHeaderLogo />
        </Container>
        <Divider light className={classes.divider} />
        <Container flex alignItems="center" direction={'column'} width={'100%'} gap={'10px'}>
          <Container flex direction="column" className={classes.emailText}>
            <Typography variant="title" color={getColor('grey-g25')}>
              Check Your Email Inbox
            </Typography>
            <Typography variant="paragraph1" color={getColor('grey-g35')}>
              We have emailed you a link to reset you password.
            </Typography>
          </Container>

          <Button variant="secondary" border type="submit" width={'300px'} onClick={() => navigate(-2)}>
            Back to Login
          </Button>
          <Container>
            <Typography variant="body2" color={getColor('grey-g65')}>
              Didn’t receive email? &nbsp;
              <Link className={classes.link} variant="body2" underline to="">
                Resend Email
              </Link>
            </Typography>
          </Container>
        </Container>
        <Container className={classes.bottomLine}>
          <Typography variant="overline" component="div">
            By clicking above, you acknowledge that you are agree to PartsClub's{' '}
            <Link to="#">
              <Typography variant="overline" className={classes.bottomLineLink}>
                Terms & Conditions
              </Typography>
            </Link>{' '}
            and{' '}
            <Link to={'#'}>
              <Typography variant="overline" className={classes.bottomLineLink}>
                Privacy Policy.
              </Typography>
            </Link>
          </Typography>
        </Container>
      </Container>
    </Container>
  )
}
