import { getSupplierDetails } from 'common-api/super-admin/superAdmin'
import useSWR from 'swr'

export default function useSupplierDetails(id) {
  const { data, mutate } = useSWR(id ? `/services/supplier-details/${id}` : null, getSupplierDetails)

  return {
    supplierDetail: data,
    isLoading: !data,
    mutate,
  }
}
