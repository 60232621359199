import Button from 'components/common/Button'
import Container from 'components/common/Container'
import { makeStyles } from '@mui/styles'
import Typography from 'components/common/Typography'
import React, { useEffect, useState } from 'react'
import { getColor } from 'theme/colors'
import Link from 'components/common/Link'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import useDummyQuoteDetails from 'common-hooks/landing-page/useDummyQuoteDetails'
import LoginDialog from 'containers/authentication/LoginDialog'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import LandingPageFooter from './LandingPageFooter'
import LandingPageHeader from './LandingPageHeader'
import './css/landingpage.css'
import './css/responsive.css'

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '47%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
  tradeFont: {
    fontSize: '9px',
    lineHeight: '14px',
    color: '#C3C5D5',
  },
}))

const QuoteRequestDetail = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { quoteRequestDetail, isLoading } = useDummyQuoteDetails(params.id)
  const [filedVisibility, setFiledVisibility] = useState(true)
  const classes = useStyles({ filedVisibility })
  const isAuthorised = !!getFromLocalStorage(constants.TOKEN)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setFiledVisibility(true)
    } else {
      setFiledVisibility(false)
    }
  }, [isLoading])

  const title = `${quoteRequestDetail?.brand_name || quoteRequestDetail?.quote_brand_name_ref || ''} ${
    quoteRequestDetail?.part_name || ''
  } ${quoteRequestDetail?.quote_part_number || ''}`.trim()

  const getSerialNumber = () =>
    isAuthorised ? quoteRequestDetail?.quote_brand_serial_number || '-' : 'Unlock Serial Number'

  const getBuyerDetails = () => (isAuthorised ? quoteRequestDetail?.buyer_full_name || '-' : 'Unlock Buyer Details')

  return (
    <>
      <Container>
        <Container className="container">
          <LandingPageHeader />
        </Container>
        <Container className="request-detail-body">
          <Container className="request-detail-message">
            <Container className="container">
              <Container className="request-detail-message-inner">
                <Typography component="p">
                  PartsClub is a global marketplace for heavy-duty parts. Find multiple suppliers for the parts you're
                  looking for with a single request.
                </Typography>
              </Container>
            </Container>
          </Container>
          {isLoading && <CircularProgress color="secondary" size={40} className={classes.progress} />}
          <Container className="container">
            <Container className="request-detail-body-inner">
              <Typography variant="caption" color={getColor('grey-g35')} component="p">
                <Link to={'/quoterequest'}> Quote Request </Link>/ {title}
              </Typography>
              <Container className="request-detail-grid">
                <Container width={'42.2%'}>
                  <Container className="request-detail-left">
                    <img src={quoteRequestDetail?.brand_cover_image} alt="Constructionbrand" />
                  </Container>
                  <Typography color="#C3C5D5" className={classes.tradeFont}>
                    All trademarks, logos and brand names are the property of their respective owners. All company,
                    product and service names used in this website are for identification purposes only. Use of these
                    names, trademarks, and brands does not imply affiliation or endorsement.
                  </Typography>
                </Container>

                <Container className="request-detail-right">
                  <Container className="request-deail-right-inner">
                    <Typography component="h1">{title}</Typography>
                    <Typography component="span" variant="body1" color={getColor('grey-g65')}>
                      {quoteRequestDetail?.part_detail.name_path ? quoteRequestDetail?.part_detail.name_path : ''}
                    </Typography>
                    <Container className="product-detail">
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Industry:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.industry_name || '-'}
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Brand:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.brand_name || quoteRequestDetail?.quote_brand_name_ref || '-'}
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Equipment:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.equip_name || '-'}
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Model Number:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.quote_brand_model_number || '-'}
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Serial Number:
                        </Typography>
                        <Typography variant="body1" color="#2680D9" component="p">
                          <Link to="" underline color="#2680D9" onClick={!isAuthorised ? setOpenDialog : null}>
                            {getSerialNumber()}
                          </Link>
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Part Category:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.part_name || '-'}
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Part Number:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.quote_part_number || '-'}
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Buyer Details:
                        </Typography>
                        <Typography variant="body1" color="#2680D9" component="p">
                          <Link to="" underline color="#2680D9" onClick={!isAuthorised ? setOpenDialog : null}>
                            {getBuyerDetails()}
                          </Link>
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Quantity:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.quote_quantity || 1}
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Location:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.cust_addr_city ? quoteRequestDetail?.cust_addr_city : <>{''}</>}
                          {quoteRequestDetail?.cust_addr_state_abbrv ? (
                            <>, {quoteRequestDetail?.cust_addr_state_abbrv} </>
                          ) : (
                            <>{''}</>
                          )}
                        </Typography>
                      </Container>
                      <Container className="product-detail-grid">
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          Notes:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                          {quoteRequestDetail?.quote_note || '-'}
                        </Typography>
                      </Container>
                    </Container>
                    <Container>
                      <Container className="start-quote-btn">
                        <Button width={'100%'} onClick={() => navigate('/createquote')}>
                          Start Quote
                        </Button>
                      </Container>
                    </Container>
                    <Typography className="textunderBtn">
                      This is just one example from a buyer looking for parts. Your request will go directly to
                      suppliers that can potentially supply the parts and they will contact you directly.
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
        <LandingPageFooter />
        <LoginDialog open={openDialog} onClose={setOpenDialog} />
      </Container>
    </>
  )
}

export default QuoteRequestDetail
