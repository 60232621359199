// import useSWR from 'swr'
// import { getActiveQuoteList } from 'common-api/buyer-supplier/buyerSupplier'

// export default function useActiveQuotes(status) {
//   const { data, mutate } = useSWR(() => {
//     if (status) {
//       return `/quote?status=${status}`
//     }
//     return null
//   }, getActiveQuoteList)
//   return {
//     activeQuotesData: data,
//     mutate,
//     isLoading: !data,
//   }
// }
import { getActiveQuoteList } from 'common-api/buyer-supplier/buyerSupplier'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import { useState } from 'react'

export default function useActiveQuotes(status) {
  const [search, onSearch] = useState('')
  const PAGE_SIZE = 20
  const [page, setPage] = useState(1)
  const params = {
    ...(status && { status }),
    limit: PAGE_SIZE,
    page,
  }

  const { data, mutate } = useSwrInfinite('/quote', params, getActiveQuoteList)
  if (data && data.length === 0 && page > 1) setPage((pre) => pre - 1)
  return {
    activeQuotesData: data,
    mutate,
    isLoading: !data,
    search,
    onSearch,
    setPage,
    page,
  }
}
