import Api from 'services/Api'

export async function getSearchList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getSearchProductDetails(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function productQuoteRequest(data) {
  const response = await Api.post('/quote/product', data)
  return response.data.data
}
export async function productReport(data) {
  const response = await Api.post('/report/product', data)
  return response.data.data
}
