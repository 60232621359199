import Container from 'components/common/Container'
import { React, useEffect, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import TextField from 'components/common/text/TextField'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
})

export default function PartNumberDialog({ open, onClose, setPartNumber, editPartNumber, setEditPartNumber, setFlag }) {
  const classes = useStyles()
  const [partNumberData, setPartNumberData] = useState()
  const { toastMessage } = useToastify()

  const handleOnClose = () => {
    setPartNumberData('')
    setEditPartNumber(null)
    onClose()
  }

  // eslint-disable-next-line consistent-return
  const savePartNumber = () => {
    if (editPartNumber) {
      setPartNumber(partNumberData)
      setFlag(false)
      handleOnClose()
      return 0
    }
    if ((!editPartNumber && partNumberData === '') || partNumberData === undefined) {
      toastMessage('error', 'Part number is required')
      return 0
    }
    setPartNumber(partNumberData)
    setFlag(false)
    handleOnClose()
  }

  useEffect(() => {
    if (editPartNumber) {
      setPartNumberData(editPartNumber)
    }
  }, [editPartNumber])

  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Part Number'} onClose={handleOnClose} />
        <Container className={classes.dialogbody}>
          <TextField
            id={'Part Number'}
            placeholder="Type"
            value={partNumberData}
            onChange={(e) => setPartNumberData(e.target.value)}
          />
        </Container>
        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button onClick={() => savePartNumber()}>{editPartNumber ? 'Update' : 'Add'}</Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </Container>
  )
}
PartNumberDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setPartNumber: PropTypes.func,
  editPartNumber: PropTypes.string,
  setFlag: PropTypes.func,
  setEditPartNumber: PropTypes.func,
}
