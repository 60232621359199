import { makeStyles } from '@mui/styles'
import TextField from 'components/common/text/TextField'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Link from 'components/common/Link'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getFormError } from 'utils/form/form'
import useAuth from 'common-hooks/useAuth'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import { useEffect } from 'react'
import SignInHeaderLogo from '../sign-in/SignInHeaderLogo'

const useStyles = makeStyles(() => ({
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '30px',
  },
  placeholderPosition: {
    width: '300px',
  },
  mainRoot: {
    width: '436px',
    height: '100vh',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '30px 0',
  },
  headerLogo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
  },
  link: {
    paddingTop: '10px',
    textAlign: 'center',
  },
  bottomLine: {
    color: getColor('grey-g65'),
    textAlign: 'center',
    maxWidth: '372px',
  },
  bottomLineLink: {
    color: getColor('grey-g65'),
    textDecoration: 'underline',
  },
}))
const schema = yup.object({
  email: yup.string().email().required('Email is Required.'),
})
export default function ForgotPassword() {
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const { isLoading, forgotPassword } = useAuth()
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const onSubmit = async (data) => {
    const verify = await forgotPassword(data.email)
    if (verify) {
      navigate('/forgotlink')
    }
  }

  useEffect(() => {
    setValue('email', params?.email)
  }, [params?.email, setValue])

  return (
    <Container className={classes.mainRoot}>
      <form onSubmit={handleSubmit(onSubmit)} id="forgotPassword-form">
        <Container className={classes.placeholderPosition}>
          <Container className={classes.headerLogo} gap={'8px'}>
            <SignInHeaderLogo />
          </Container>
          <Container className={classes.subContainer}>
            <Container>
              <TextField
                id="login-email"
                type={'email'}
                name={'email'}
                placeholder="john.d@partsclub.com"
                label={'Email'}
                {...register('email')}
                error={getFormError(errors, 'email')}
              />
            </Container>

            <Container>
              <Button loading={isLoading} variant="secondary" border type="submit" width={'100%'}>
                Send Reset Link
              </Button>
            </Container>
          </Container>
          <Link variant="body2" underline to="/login" component={'p'} className={classes.link}>
            Back to Sign In
          </Link>
        </Container>
      </form>
      <Container className={classes.bottomLine}>
        <Typography variant="overline" component="div">
          By clicking above, you acknowledge that you are agree to PartsClub's{' '}
          <Link to="#">
            <Typography variant="overline" className={classes.bottomLineLink}>
              Terms & Conditions
            </Typography>
          </Link>{' '}
          and{' '}
          <Link to={'#'}>
            <Typography variant="overline" className={classes.bottomLineLink}>
              Privacy Policy.
            </Typography>
          </Link>
        </Typography>
      </Container>
    </Container>
  )
}
