import {
  checkEmail,
  checkCode,
  login,
  forgotPasswordEmail,
  userSignUp,
  userResetPassword,
} from 'common-api/authantication/auth'
import { signUpDataParsing } from 'containers/authentication/sign-up/SignUpUtils'
import { useContext, useState } from 'react'
import { AuthContext } from 'utils/provider/AuthContext'
import useToastify from './useToastify'

export default function useAuth() {
  const [isLoading, setLoading] = useState(false)
  const { toastMessage } = useToastify()
  const { dispatch } = useContext(AuthContext)
  const setLoginData = async (userId, userPassword, toastFalse) => {
    try {
      setLoading(true)
      const loginData = await login({ id: userId, password: userPassword })

      dispatch({
        type: 'LOGIN',
        payload: {
          id: loginData?.id,
          user: loginData?.name,
          token: loginData?.token,
          role: loginData?.type,
          companyId: loginData?.company_id,
        },
      })
      setLoading(false)
      if (!toastFalse) {
        toastMessage('success', 'You are logged in successfully')
      }
      return loginData
    } catch (e) {
      if (e.message === 'Invalid Password') {
        toastMessage('error', 'Please enter correct password.')
      }
      setLoading(false)
      return false
    }
  }
  const verifyEmail = async (email) => {
    try {
      setLoading(true)
      const isExisit = await checkEmail(email)
      setLoading(false)
      return isExisit
    } catch (e) {
      setLoading(false)
      return { error: e, type: false }
    }
  }
  const verifyCode = async (emailcode) => {
    try {
      setLoading(true)
      const isExisit = await checkCode({ code: emailcode })
      setLoading(false)
      return isExisit
    } catch {
      setLoading(false)
      return false
    }
  }
  const forgotPassword = async (emailId) => {
    try {
      setLoading(true)
      const isExisit = await forgotPasswordEmail({ email: emailId })
      setLoading(false)
      return isExisit
    } catch {
      setLoading(false)
      return false
    }
  }
  const signUp = async (data) => {
    const parsingData = signUpDataParsing(data)
    try {
      const response = await userSignUp(parsingData)
      if (response) {
        const id = await verifyEmail(parsingData?.email)
        await setLoginData(id, parsingData?.password, true)
      }
      toastMessage('success', 'Account created successfully')
      return response
    } catch {
      return false
    }
  }
  const resetPassword = async (data) => {
    try {
      const response = await userResetPassword(data)
      toastMessage('success', 'Password reset successfully')
      return response
    } catch {
      return false
    }
  }
  return { isLoading, setLoginData, verifyEmail, verifyCode, forgotPassword, signUp, resetPassword }
}
