import { getCompanyPolicies } from 'common-api/settings/settings'
import useSWR from 'swr'

export default function useGetCompanyPolicies() {
  const { data, mutate } = useSWR(`/company/policy`, getCompanyPolicies)

  return {
    companyPolicy: data,
    isLoading: !data,
    mutate,
  }
}
