import { Typography } from '@mui/material'
import Container from 'components/common/Container'
import React from 'react'
import { getColor } from 'theme/colors'
import stopImage from '../assets/images/stop-hand-blue-img.jpg'

export default function NotFound() {
  return (
    <Container flex height="98vh">
      <Container flex width="30%" direction="column">
        <img height={'100px'} width={'100px'} src={stopImage} alt="Stop" />
        <Typography variant="h1" color={getColor('blue-b500')}>
          OOOH! 403 ACCESS DENIDED
        </Typography>
        <Typography variant="title" color={getColor('grey-g35')} align="center">
          Sorry about that, but you don't have permission to access this page.
        </Typography>
      </Container>
    </Container>
  )
}
