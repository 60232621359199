import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Typography from 'components/common/Typography'
import React, { useContext, useState } from 'react'
import Placeholder from 'assets/images/Placeholder.png'
import { getColor } from 'theme/colors'
import Seperator from 'components/common/Seperator'
import { InfoSmallIcon } from 'assets/icons'
import Tooltip from 'components/common/Tooltip'
import { Controller, useForm } from 'react-hook-form'
import TextField from 'components/common/text/TextField'
import { BuyerQuoteContext } from 'containers/buyer-quotes/BuyerQuotesUtils'
import { allowOnlyAlphaNumric, getWellKnownAddress } from 'utils/Utils'
import LoginDialog from 'containers/authentication/LoginDialog'
import MultiplePartsHomePage from 'containers/landing-page/create-quote/forms/MultiplePartsHomePage'
import useToggle from 'common-hooks/useToggle'
import ShipingAddressDialog from 'containers/landing-page/ShipingAddressDialog'
import useToastify from 'common-hooks/useToastify'
import GooglePlaces from 'components/custom/GooglePlaces'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'

const useStyles = makeStyles(() => ({
  summaryBody: {
    backgroundColor: '#fff',
    padding: '30px',
    minHeight: '670px',
  },
  oemBrands: { border: `1px solid ${getColor('grey-g95')}`, width: '178px', height: '49px', padding: '10px 20px' },
  imagesRoot: {
    alignItems: 'center',
    display: 'flex',
    width: '165px',
  },
  titile: {
    display: 'flex',
    gap: '0 10px',
  },
  img: {
    height: '30px',
    width: '30px',
    border: '1px solid #F0F0F4',
  },

  partRoot1: {
    paddingTop: '8px',
    paddingBottom: '25px',
  },
  pb10: {
    marginTop: '10px',
  },
  summaryInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  mailEditBtn: {
    backgroundColor: 'unset',
    padding: '0',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  brandDetail: {
    alignItems: 'unset',
  },
  addressDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '15px',
  },
}))

const SummaryForm = () => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const { toastMessage } = useToastify()
  const { BuyerQueoteInformation } = useContext(BuyerQuoteContext)
  const [shippinAddressDialog, setShippinAddressDialog] = useToggle(false)
  function coverImage(val) {
    if (val === '/static/media/UnionIcon.e8ec7324b81cc0c0dd56b9cfff1a8858.svg') {
      return Placeholder
    }
    return val
  }
  const methods = useForm()
  const { handleSubmit, control, setValue, getValues } = methods

  const onSubmit = async (data) => {
    if (!data?.company_delivery_addr) {
      toastMessage('error', 'Shipping address is required')
      return
    }
    const newData = {
      ...BuyerQueoteInformation,
      brand_model_number: data?.modalNumber,
      brand_serial_number: data?.serialNumber,
      address: data?.company_delivery_addr,
    }
    setOpenDialog(true)
    localStorage.setItem('unAuthQuoteRequest', JSON.stringify(newData))
  }
  return (
    <Container>
      <form id={`summary-form`} onSubmit={handleSubmit(onSubmit)}>
        <Container className={classes.summaryBody}>
          <Container className={classes.summaryInner}>
            <Container className={classes.titile}>
              <Container className={classes.imagesRoot}>
                <Typography variant="title1">Machine Info</Typography>
              </Container>
              <Container flex gap={'15px'} justify={'flex-start'} className={classes.brandDetail}>
                <Container display="flex" gap={'12px'} alignItems="center" className={classes.oemBrands}>
                  <Image
                    src={coverImage(BuyerQueoteInformation?.Brands?.brand?.coverImg)}
                    alt="brandImg"
                    className={classes.img}
                  />
                  <Container>
                    <Typography variant="body">{BuyerQueoteInformation?.Brands?.brand?.name}</Typography>
                  </Container>
                </Container>
                <Container display="flex" gap={'12px'} alignItems="center" className={classes.oemBrands}>
                  <Image
                    src={coverImage(BuyerQueoteInformation?.Equipments?.equipment?.coverImg)}
                    alt="brandImg"
                    className={classes.img}
                  />
                  <Container>
                    <Typography variant="body">{BuyerQueoteInformation?.Equipments?.equipment?.name}</Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container className={classes.titile}>
              <Container className={classes.imagesRoot}>
                <Typography variant="body1">Model & Serial Number</Typography>
              </Container>
              <Container flex gap={'15px'} justify={'flex-start'} className={classes.brandDetail}>
                <Controller
                  name={'modalNumber'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TextField
                      placeholder={'Model Number'}
                      onChange={onChange}
                      onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                      value={value}
                      name={name}
                      id={name}
                    />
                  )}
                />
                <Controller
                  name={'serialNumber'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TextField
                      placeholder={'Serial Number'}
                      onChange={onChange}
                      onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                      value={value}
                      name={name}
                      id={name}
                    />
                  )}
                />
              </Container>
            </Container>
            <Seperator />
            <Container className={classes.partsDiv}>
              <Typography variant="title" color={getColor('grey-g25')} className={classes.partsTitle}>
                Parts
              </Typography>
              <MultiplePartsHomePage partsData={BuyerQueoteInformation.visibleTextBoxData} />
            </Container>
            <Seperator />
            <Container>
              <Container display="flex" gap="90px">
                <Container flex>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Shipping
                  </Typography>
                  <Tooltip
                    placement="right"
                    maxWidth={'565px'}
                    title={
                      'Once you create a quote you will not able to change the address, Provide proper shipping address.'
                    }
                  >
                    <InfoSmallIcon className={classes.icon} />
                  </Tooltip>
                </Container>
                <Container className={classes.addressDiv}>
                  <Controller
                    name={'company_delivery_addr'}
                    control={control}
                    render={({ field: { name } }) => (
                      <GooglePlaces
                        id={name}
                        name={name}
                        onClearInput={() => setValue(name, { id: getValues()?.company_billing_addr?.id })}
                        value={
                          getValues()?.company_delivery_addr?.addr_line_1
                            ? getWellKnownAddress(
                                getValues()?.company_delivery_addr?.addr_line_1,
                                getValues()?.company_delivery_addr?.addr_line_2
                              )
                            : ''
                        }
                        spacing={3}
                        placeholder={'S 5th Ave, Merrll St, Castlewood, SD 57078, US'}
                        onSelect={(loc, i) => {
                          if (i === 0)
                            setValue(
                              'company_delivery_addr',
                              formatAddressOfGAPI(
                                loc.addr,
                                loc.addr_line_1,
                                loc.addr_line_2,
                                null,
                                getValues()?.company_delivery_addr?.id
                              )
                            )
                          else
                            setValue(
                              'company_billing_addr',
                              formatAddressOfGAPI(
                                loc.addr,
                                loc.addr_line_1,
                                loc.addr_line_2,
                                null,
                                getValues()?.company_billing_addr?.id
                              )
                            )
                        }}
                      />
                    )}
                  />
                </Container>
              </Container>
            </Container>
            <Seperator />
          </Container>
        </Container>
      </form>
      <LoginDialog open={openDialog} onClose={setOpenDialog} />
      <ShipingAddressDialog open={shippinAddressDialog} onclose={setShippinAddressDialog} />
    </Container>
  )
}
export default SummaryForm
