import Container from 'components/common/Container'
import React from 'react'
import Placeholder from 'assets/images/Placeholder.png'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { ButtonBase, Typography } from '@mui/material'
import { getColor } from 'theme/colors'
import classNames from 'classnames'
import Image from 'components/common/Image'
import { getFlag } from 'utils/Utils'

const useStyles = makeStyles({
  root: (active) => ({
    width: '238px',
    height: '196px',
    backgroundColor: '#fff',
    border: '1px solid #F0F0F4',
    cursor: 'pointer',
    padding: '16px',
    '&:hover': {
      boxShadow: 'rgb(0 0 0 / 6%) 0px 16px 14px, rgb(0 0 0 / 4%) 0px 2px 6px, rgb(0 0 0 / 4%) 0px 0px 1px',
    },
    ...(active && {
      border: '1px solid #FF8C1A',
    }),
  }),
  cardImage: {
    width: '88px',
    height: '88px',
    display: 'inline-block',

    '& img': {
      width: '88px',
      height: '88px',
      border: '1px solid #F0F0F4',
      objectFit: 'scale-down',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  color1: {
    color: getColor('dark-main'),
  },
  color2: {
    color: getColor('grey-g35'),
  },
  color3: {
    color: getColor('grey-g25'),
  },
  cardBtn: {
    textAlign: 'start',
  },
  flag: {
    objectFit: 'unset',
  },
})

const InvitedSupplierCard = ({ suppilerData, addActiveSuppliers, actives }) => {
  const data = actives?.find((item) => item?.id === suppilerData.id)
  const active = typeof data === 'object'
  const classes = useStyles(active)
  const onActiveSelect = (name, id) => {
    addActiveSuppliers(name, id)
  }
  return (
    <Container>
      <ButtonBase onClick={() => onActiveSelect(suppilerData.name, suppilerData.id)} className={classes.cardBtn}>
        <Container className={classNames(classes.root, classes.flex)} direction={'column'} gap={'8px 0'}>
          <Container className={classes.cardImage}>
            <Image src={suppilerData?.logo || Placeholder} alt="imagebrand" />
          </Container>
          <Container>
            <Typography variant="body1" className={classes.color1}>
              {suppilerData.name}
            </Typography>
            <Container className={classes.flex} gap={'0 5px'}>
              <Typography variant="paragraph1" className={classes.color2}>
                {suppilerData.city}, {suppilerData.state}
              </Typography>
              {getFlag(suppilerData?.country)}
            </Container>
          </Container>
          {/* <Container>
            <Typography variant="body1" className={classes.color3}>
              Brakes • Engine
            </Typography>
          </Container> */}
          <Container>
            <Typography variant="paragraph1" className={classes.color2}>
              {suppilerData.supilerinfo}
            </Typography>
          </Container>
        </Container>
      </ButtonBase>
    </Container>
  )
}

InvitedSupplierCard.propTypes = {
  suppilerData: PropTypes.object,
  addActiveSuppliers: PropTypes.func,
  actives: PropTypes.array,
}

export default InvitedSupplierCard
