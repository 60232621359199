import React, { useContext } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import RadioGroup from 'components/common/radio/RadioGroup'
import IndustryRadioCard from 'containers/authentication/sign-up/IndustryRadioCard'
import { SignupDataContext } from 'utils/provider/AuthContext'
import useAuth from 'common-hooks/useAuth'
import useIndustryList from 'common-hooks/useIndustryList'
import { useNavigate } from 'react-router-dom'
import LoadingOverlay from 'components/common/LoadingOverlay'

const useStyles = makeStyles(() => ({
  radioGroup: {
    width: '100%',
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px ',
      justifyContent: 'center',
    },
  },
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '54%',
    left: '48%',
    right: '0',
    bottom: '0',
  },
}))
const schema = yup.object({
  industry: yup.string().required('Industry is Required.'),
})

export default function SignUpIndustryInfo({ setBtnDisable }) {
  const classes = useStyles()
  const { signupData, onSaveSignupData } = useContext(SignupDataContext)
  const { signUp } = useAuth()
  const { industryData, isLoading } = useIndustryList()
  const navigate = useNavigate()
  const { handleSubmit, setValue, control, getValues } = useForm({
    resolver: yupResolver(schema),
  })
  const industry = useWatch({
    control,
    name: 'industry',
  })
  if (industry || signupData?.industry) {
    setBtnDisable(false)
  } else {
    setBtnDisable(true)
  }

  const onSubmit = async () => {
    const response = await signUp({ ...signupData, industry })
    if (!response) {
      navigate('/home')
    }
    const signupDatajason = JSON.parse(signupData)
    if (response && signupDatajason?.type === 'supplier') {
      navigate('/requestboard')
    }
    if (response && signupDatajason?.type === 'customer') {
      navigate('/quotes')
    }
  }

  return (
    <Container height="380px">
      <form onSubmit={handleSubmit(onSubmit)} id={'industry-form'}>
        {isLoading && <LoadingOverlay color="secondary" size={40} className={classes.progress} />}
        <Container width={'100%'} direction={'column'} flex>
          <Controller
            control={control}
            name="industry"
            render={({ field: { name } }) => (
              <RadioGroup name={name} className={classes.radioGroup}>
                {industryData?.map((eItem) => (
                  <IndustryRadioCard
                    name={eItem?.name}
                    id={eItem?.id}
                    img={eItem?.cover_image}
                    checked={eItem.id === signupData?.industry || eItem?.id === getValues()?.industry}
                    key={eItem?.id}
                    onChange={() => {
                      setValue(name, eItem?.id)
                      onSaveSignupData({ ...signupData, industry: eItem?.id })
                    }}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </Container>
      </form>
    </Container>
  )
}

SignUpIndustryInfo.propTypes = {
  setBtnDisable: PropTypes.func,
}
