import Container from 'components/common/Container'
import { React, useEffect, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import TextField from 'components/common/text/TextField'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm, useWatch } from 'react-hook-form'
import useUserPassword from 'common-hooks/user-profile/useUserPassword'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  passwordContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px',
  },
})

const UserProfileDailog = ({ open, onclose }) => {
  const { updatePassword } = useUserPassword()
  const classes = useStyles()
  const { toastMessage } = useToastify()
  const [updateDisabled, setUpdateDisabled] = useState(true)

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('old Password is required').min(6, 'Password must be at least 6 characters'),
    password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  })
  // const formOptions = { resolver: yupResolver(validationSchema) }
  const {
    register,
    control,
    formState: { errors },
    resetField,
  } = useForm({ resolver: yupResolver(validationSchema) })
  const [currentPassword, password, confirmPassword] = useWatch({
    control,
    name: ['currentPassword', 'password', 'confirmPassword'],
  })

  const handlepassword = () => {
    if (password === confirmPassword) {
      const data = {
        old_password: currentPassword,
        new_password: password,
      }
      updatePassword(data)
      resetField('currentPassword')
      resetField('password')
      resetField('confirmPassword')
      onclose()
    } else {
      toastMessage('error', 'Both the new password and confirm password fields value must be matched')
    }

    // reset({ currentPassword: '', password: '', confirmPassword: '' })
    return false
  }
  const handeleCloseDilaog = () => {
    resetField('currentPassword')
    resetField('password')
    resetField('confirmPassword')
    onclose()
  }
  useEffect(() => {
    if (currentPassword && password && confirmPassword) {
      setUpdateDisabled(false)
    }
  }, [currentPassword, password, confirmPassword])

  return (
    <>
      <Dialog open={open} width={'450px'}>
        <DialogHead title={'Password'} onClose={onclose} />
        <Container className={classes.passwordContainer}>
          <Container>
            <TextField
              id={'currentPassword'}
              type="password"
              control={control}
              name={'currentPassword'}
              placeholder={'Current Password'}
              label={'Current Password'}
              {...register('currentPassword', { required: true })}
              error={errors.currentPassword ? 'This Field required' : ''}
            />
          </Container>
          <Container>
            <TextField
              id={'password'}
              type="password"
              name={'password'}
              placeholder={'New Password'}
              label={'New Password'}
              control={control}
              {...register('password', { required: true })}
              error={errors.password ? <>{errors.password?.message}</> : ''}
            />
          </Container>
          <Container>
            <TextField
              id={'confirmPassword'}
              type="password"
              control={control}
              name={'confirmPassword'}
              placeholder={'Confirm Password'}
              label={'Confirm Password'}
              {...register('confirmPassword', { required: true })}
              error={errors.confirmPassword ? <>{errors.confirmPassword?.message}</> : ''}
            />
          </Container>
        </Container>
        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={handeleCloseDilaog}>
              Cancel
            </Button>
            <Button width={81} onClick={handlepassword} disabled={updateDisabled}>
              Update
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </>
  )
}
UserProfileDailog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  setpassword: PropTypes.func,
}

export default UserProfileDailog
