import { getUserList, updateUser } from 'common-api/super-admin/superAdmin'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import useToastify from 'common-hooks/useToastify'
import { useState } from 'react'

export default function useSuperAdminUserList(user, orderBy, filterData) {
  const [search, onSearch] = useState('')
  const [page, setPage] = useState(1)
  const { toastMessage } = useToastify()
  const limit = 35

  const params = {
    ...(search && { search }),
    limit,
    page,
    ...(user && { type: user }),
    ...(orderBy && orderBy),
    ...(filterData && filterData),
  }

  const { data, mutate } = useSwrInfinite('/super-admin/users', params, getUserList)
  if (data && data.length === 0 && page > 1) {
    toastMessage('error', 'There are no more user')
    setPage((pre) => pre - 1)
  }

  const userUpdate = async (data) => {
    function toastMessages(data) {
      if (data?.status) {
        return 'Status changed successfully'
      }
      if (data?.notes === '') {
        return 'Notes deleted successfully'
      }
      if (data?.update === true) {
        return 'Notes updated successfully'
      }
      return 'Notes added successfully'
    }
    const message = toastMessages(data)
    delete data?.update
    try {
      const response = await updateUser(data)
      toastMessage('success', message)

      mutate()
      // navigate('/inventory')
      return response
    } catch {
      return false
    }
  }

  return {
    userList: data,
    mutate,
    isLoading: !data,
    onSearch,
    search,
    setPage,
    page,
    userUpdate,
  }
}
