import Container from 'components/common/Container'
import { React, useEffect, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import Checkbox from 'components/common/Checkbox'
import PropTypes from 'prop-types'
import TextArea from 'components/common/text/TextArea'
import Typography from 'components/common/Typography'
import { Controller, useForm, useWatch } from 'react-hook-form'
import LabelTextField from 'components/common/text/LabelTextField'
import Seperator from 'components/common/Seperator'
import useCounterDetails from 'common-hooks/buyer/counter/useCounterDetails'
import useToastify from 'common-hooks/useToastify'
import Chip from 'components/common/Chip'
import { CaretDownIcon } from 'assets/icons'
import TitleList from 'components/custom/list/TitleList'
import useToggle from 'common-hooks/useToggle'
import { useParams } from 'react-router-dom'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import Radio from 'components/common/radio/Radio'
import { formatPrice } from 'utils/Utils'
import RadioGroup from 'components/common/radio/RadioGroup'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    height: '582px',
    overflow: 'scroll',
  },
  equpDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  partsDetailContainer: {
    padding: '18px 0px 0px 0px',
  },
  partsDetailSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '22px 0px 12px 0px',
  },
  fieldWidth: {
    width: '150px',
  },
  shpping: {
    marginTop: '20px',
  },
  shppingOptions: {
    paddingTop: '20px',
  },
  shppingBtn: (props) => ({
    '& svg': {
      rotate: props.shipping ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  conditionBtn: (props) => ({
    '& svg': {
      rotate: props.conditions ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  radioDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '393px',
    height: '35px',
  },
  shippingRadio: {
    paddingTop: '10px',
  },
})

const CounterDialog = ({ open, onclose, quoteReqId, amount, obj, mutate, returnAble }) => {
  const { handleSubmit, register, resetField, clearErrors, control } = useForm()
  const [disableBtn, setDisableBtn] = useState(true)
  const [option, setOption] = useState()
  const { counterDailog } = useCounterDetails()
  const [shipping, toggleShipping] = useToggle(true)
  const [conditions, toggleCondition] = useToggle(true)
  const params = useParams()
  const { supplierReqDetails } = useReqSupplierDetails(params?.id, quoteReqId)
  const { toastMessage } = useToastify()
  const [deliveryOption, setDeliveryOption] = useState()
  const [nonerefundable, offerbecomeorder] = useWatch({
    control,
    name: ['nonerefundable', 'offerbecomeorder'],
  })
  const onSubmit = (data) => {
    if (!data.counter_price) {
      toastMessage('error', 'Counter price required')
    }
    if (data?.counter_price > amount) {
      toastMessage('error', 'Counter price should not higher then current price')
      return
    }
    if (data.counter_price) {
      const counterFormData = {
        current_price: amount,
        quote_id: params?.id,
        quote_request_id: quoteReqId,
        offer_price: data.counter_price,
        shipping_options: option,
        ...(data.notes && { note: data.notes }),
      }

      onclose()
      resetField()
      counterDailog(counterFormData, mutate)
    }
    resetField()
  }

  const handeleCloseDilaog = () => {
    clearErrors()
    onclose()
    resetField()
  }
  const classes = useStyles({ shipping, conditions })
  useEffect(() => {
    if (nonerefundable && offerbecomeorder && deliveryOption && Object.keys(deliveryOption)?.length > 0) {
      setDisableBtn(false)
    } else if (returnAble === false && !nonerefundable && offerbecomeorder) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }, [supplierReqDetails?.quote_request_delivery_options, offerbecomeorder, nonerefundable])
  useEffect(() => {
    if (supplierReqDetails) {
      const key = Object.keys(supplierReqDetails?.quote_request_delivery_options)[0]
      const values = Object.values(supplierReqDetails?.quote_request_delivery_options)[0]
      setDeliveryOption(Object.values(supplierReqDetails?.quote_request_delivery_options)[0])
      setOption({
        [key]: values,
      })
    }
  }, [supplierReqDetails])
  return (
    <Container className={classes.root}>
      <Dialog open={open} size={'sm'} height={'712px'}>
        <DialogHead title={'Counter'} onClose={handeleCloseDilaog} />
        <Container className={classes.dialogbody}>
          <Container>
            <Typography variant="title1">Pricing • </Typography>
            {!obj || (obj?.[0]?.pricing_count === null && <Chip label={`3 offers remaining`} variant={'secondary'} />)}
            {obj?.length === 2 && <Chip label={`2 offers remaining`} variant={'warning'} />}
            {obj?.length === 4 && <Chip label={`Final offer!`} variant={'error'} />}
            <Container className={classes.partsDetailContainer}>
              <Container className={classes.equpDetail}>
                <Typography variant="body1">Current Price</Typography>
                <Typography variant="body1">${amount}</Typography>
              </Container>
            </Container>
            <Container className={classes.equpDetail}>
              <Typography variant="body1">Counter Offer*</Typography>
              <LabelTextField
                id={'counterPrice'}
                type={'number'}
                labelText={'$'}
                textAlign={'right'}
                width={'150px'}
                className={classes.labelTextField}
                placeholder={'00.00'}
                name={'counter_price'}
                {...register('counter_price')}
              />
            </Container>
            <TextArea id={'textAreaid'} placeholder={'Write your message here...'} rows={4} {...register('notes')} />
          </Container>
          <Container className={classes.shpping}>
            <Seperator />
            <Container className={classes.shppingOptions}>
              <Container flex justify="space-between">
                <TitleList
                  title={'Shipping*'}
                  subtitle={'Select freight option to be used if supplier accepts offer'}
                />
                <Button
                  variant="secondary"
                  className={classes.shppingBtn}
                  onClick={() => toggleShipping(true)}
                  icon={<CaretDownIcon />}
                />
              </Container>
              {shipping && (
                <>
                  <Controller
                    name={'options'}
                    control={control}
                    // defaultValue={supplierReqDetails?.quote_request_delivery_options?.freight_next_day}
                    render={() => (
                      <RadioGroup
                        value={deliveryOption}
                        onChange={(e) => {
                          setDeliveryOption(e.target.value)
                        }}
                      >
                        <Container className={classes.shippingRadio}>
                          {supplierReqDetails?.quote_request_delivery_options?.freight_ground && (
                            <Container className={classes.radioDiv}>
                              <Radio
                                label={'Ground'}
                                name={'Ground'}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOption({
                                      freight_ground:
                                        supplierReqDetails?.quote_request_delivery_options?.freight_ground,
                                    })
                                  }
                                }}
                                value={supplierReqDetails?.quote_request_delivery_options?.freight_ground}
                                className="shipingRadio"
                              />
                              <Typography variant="paragraph1" className={classes.radioValue}>
                                {formatPrice(supplierReqDetails?.quote_request_delivery_options?.freight_ground)}
                              </Typography>
                            </Container>
                          )}
                          {supplierReqDetails?.quote_request_delivery_options?.freight_next_day && (
                            <Container className={classes.radioDiv}>
                              <Radio
                                label={'Next Day Delivery'}
                                name={'Next Day Delivery'}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOption({
                                      freight_next_day:
                                        supplierReqDetails?.quote_request_delivery_options?.freight_next_day,
                                    })
                                  }
                                }}
                                value={supplierReqDetails?.quote_request_delivery_options?.freight_next_day}
                                className="shipingRadio"
                              />
                              <Typography variant="paragraph1" className={classes.radioValue}>
                                {formatPrice(supplierReqDetails?.quote_request_delivery_options?.freight_next_day)}
                              </Typography>
                            </Container>
                          )}
                          {supplierReqDetails?.quote_request_delivery_options?.freight_second_day && (
                            <Container className={classes.radioDiv}>
                              <Radio
                                label={'2nd Day'}
                                name={'2nd Day'}
                                value={supplierReqDetails?.quote_request_delivery_options?.freight_second_day}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOption({
                                      freight_second_day:
                                        supplierReqDetails?.quote_request_delivery_options?.freight_second_day,
                                    })
                                  }
                                }}
                                className="shipingRadio"
                              />
                              <Typography variant="paragraph1" className={classes.radioValue}>
                                {formatPrice(supplierReqDetails?.quote_request_delivery_options?.freight_second_day)}
                              </Typography>
                            </Container>
                          )}
                          {supplierReqDetails?.quote_request_delivery_options?.mdo_ltl && (
                            <Container className={classes.radioDiv}>
                              <Radio
                                label={'LTL'}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOption({
                                      mdo_ltl: supplierReqDetails?.quote_request_delivery_options?.mdo_ltl,
                                    })
                                  }
                                }}
                                value={supplierReqDetails?.quote_request_delivery_options?.mdo_ltl}
                                className="shipingRadio"
                              />
                              <Typography variant="paragraph1" className={classes.radioValue}>
                                {formatPrice(supplierReqDetails?.quote_request_delivery_options?.mdo_ltl)}
                              </Typography>
                            </Container>
                          )}

                          {supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery && (
                            <Container className={classes.radioDiv}>
                              <Radio
                                label={'Local Delivery'}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOption({
                                      mdo_local_delivery:
                                        supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery,
                                    })
                                  }
                                }}
                                value={supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery}
                                className="shipingRadio"
                              />
                              <Typography variant="paragraph1" className={classes.radioValue}>
                                {formatPrice(supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery)}
                              </Typography>
                            </Container>
                          )}
                          {supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up && (
                            <Container className={classes.radioDiv}>
                              <Radio
                                label={'Pick Up'}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOption({
                                      mdo_pick_up: supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up,
                                    })
                                  }
                                }}
                                value={supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up}
                                className="shipingRadio"
                              />
                              <Typography variant="paragraph1" className={classes.radioValue}>
                                {formatPrice(supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up)}
                              </Typography>
                            </Container>
                          )}
                        </Container>
                      </RadioGroup>
                    )}
                  />
                </>
              )}
            </Container>
          </Container>
          <Container className={classes.shpping}>
            <Seperator />
            <Container className={classes.shppingOptions}>
              <Container flex justify="space-between">
                <TitleList title={'Conditions*'} subtitle={'Check all the conditions to send an offer to supplier'} />
                <Button
                  variant="secondary"
                  className={classes.conditionBtn}
                  onClick={() => toggleCondition(true)}
                  icon={<CaretDownIcon />}
                />
              </Container>
              {conditions && (
                <Container>
                  <Controller
                    name={'offerbecomeorder'}
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <Checkbox
                        name={name}
                        label={'Offer will become an order if approved by supplier'}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {returnAble && (
                    <Controller
                      name={'nonerefundable'}
                      control={control}
                      render={({ field: { name, onChange, value } }) => (
                        <Checkbox name={name} label={'Item is non-returnable'} value={value} onChange={onChange} />
                      )}
                    />
                  )}
                </Container>
              )}
            </Container>
          </Container>
        </Container>
        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={handeleCloseDilaog}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={disableBtn}
              variant={disableBtn ? 'disabled' : 'primary'}
            >
              Send Offer
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </Container>
  )
}
CounterDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  quoteReqId: PropTypes.string,
  amount: PropTypes.string,
  obj: PropTypes.array,
  mutate: PropTypes.func,
  returnAble: PropTypes.bool,
}

export default CounterDialog
