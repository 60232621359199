/* eslint-disable no-nested-ternary */
import React from 'react'
import Container from 'components/common/Container'
import Placeholder from 'assets/images/Placeholder.png'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Button from 'components/common/Button'
import { useNavigate } from 'react-router-dom'
// import Chip from 'components/common/Chip'
import Image from 'components/common/Image'
import { capitalizeFirstLetter, formatPrice, getFlag, getShortAddress, timeFromNow } from 'utils/Utils'
import { getColor } from 'theme/colors'
import useToggle from '../../common-hooks/useToggle'
import ReasonDailog from './ReasonDailog'
import QuickInfoDialog from './QuickInfoDialog'
import Chip from 'components/common/Chip'

const useStyles = makeStyles(() => ({
  mainContainer: {
    marginBottom: '20px',
    width: '800px',
    height: '175px',
    background: '#fff',
    padding: '20px',
    gap: '10px',
    border: '1px solid #F0F0F4',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },
  name: {
    // width: '280px',
  },
  subContainer: {
    gap: '3px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // margin: '6px 0px',
    marginTop: '4px',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },

  img: {
    width: '90px',
    height: '67.5px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),
  },
  img2: {
    width: '90px',
    height: '67.5px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),
    borderTop: '0',
  },
  dot: {
    color: '#4A4D68',
    padding: '0px 5px',
  },
  flag: {
    paddingLeft: '5.36px',
  },
  chipContainer: {
    paddingLeft: '0px',
  },
  premimumChip: {
    height: '20px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    color: '#13141B',
    borderRadius: '100px',
  },
  partName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '276px',
  },
  supplierNameRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const QuoteRequestCard = ({
  partName,
  createdAt,
  quoteId,
  brandModel,
  brandSerial,
  brandName,
  brandImg,
  equpImg,
  partNumber,
  equipName,
  city,
  state,
  amount,
  quantity,
  supplierName,
  inviteId,
  companyName,
  reqId,
  country,
  isCompany,
  type,
}) => {
  const [toggle, setToggle] = useToggle(false)
  const [infoToggle, setInfoToggle] = useToggle(false)
  const classes = useStyles()
  const date = createdAt
  const navigate = useNavigate()
  const handleBtnClick = () => {
    navigate(amount ? `/quotes/supplierdetail/${quoteId}/active/${reqId}` : `/quotes/supplierdetail/request/${quoteId}`)
  }
  const handleClick = (e) => {
    setToggle(true)
    e.stopPropagation()
  }
  return (
    <Container flex direction={'column'}>
      <Container flex className={classes.mainContainer} onClick={() => setInfoToggle(true)}>
        <Container flex direction={'column'}>
          <Image src={brandImg || Placeholder} alt="LET'S SPARK UP LOGO" className={classes.img} />
          <Image src={equpImg || Placeholder} alt="Machine parts" className={classes.img2} />
        </Container>
        <Container width={'100%'}>
          <Container flex justifyContent={'space-between'} className={classes.subContainer}>
            <Typography variant="title" color={getColor('grey-g25')}>
              {brandName && `${brandName} `} {partName !== null ? `- ${partName}` : ''}
              {partNumber !== null ? `- ${partNumber}` : ''}
            </Typography>
            <Typography variant="title" color={getColor('grey-g25')}>
              {quantity && `Qty: ${quantity}  • ${formatPrice(amount)}`}
            </Typography>
            <Chip label={capitalizeFirstLetter(type)} />
          </Container>
          <Container flex className={classes.subContainer} direction={'column'}>
            <Container>
              <Typography variant="paragraph1" className={classes.otherText}>
                {equipName ? <>{equipName}</> : <>{''}</>}
                {brandModel ? (
                  <>
                    <span className={classes.dot}>•</span>
                    {brandModel}
                  </>
                ) : (
                  <>{''}</>
                )}

                {brandSerial ? (
                  <>
                    <span className={classes.dot}>•</span>
                    {brandSerial}
                  </>
                ) : (
                  <>{''}</>
                )}
              </Typography>
            </Container>

            <Container className={classes.supplierNameRoot}>
              {isCompany ? (
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {supplierName} at {companyName}
                </Typography>
              ) : (
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {supplierName}
                </Typography>
              )}

              {/* <Container className={classes.chipContainer}>{companyName}</Container> */}
              <Container display="flex" alignItems="center" gap="5px">
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  {getShortAddress(city, state, country)}
                </Typography>
                {getFlag(country)}
              </Container>
            </Container>

            <Container flex width={'100%'} justify={'space-between'}>
              <Typography variant="paragraph1" className={classes.partName}>
                {brandName}
                {brandName && equipName ? ` • ` : ''}
                {equipName}
                {equipName && partName ? ` • ` : ''}
                {partName}
              </Typography>

              <Container className={classes.btn}>
                <Typography variant="paragraph1">{timeFromNow(date)}</Typography>
                <span>•</span>
                <Button variant="secondary" border onClick={handleClick}>
                  Can’t Participate
                </Button>
                <Button variant="positive" className={classes.startbtn} onClick={handleBtnClick}>
                  {amount ? 'Edit' : 'Start Quote'}
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <ReasonDailog open={toggle} onclose={setToggle} inviteId={inviteId} />
      <QuickInfoDialog
        open={infoToggle}
        onclose={setInfoToggle}
        quoteId={quoteId}
        inviteId={inviteId}
        amount={amount}
        reqId={reqId}
        companyName={companyName}
        city={city}
        state={state}
        country={country}
        isCompany={isCompany}
      />
    </Container>
  )
}
QuoteRequestCard.propTypes = {
  partName: PropTypes.string,
  reqId: PropTypes.string,
  quoteId: PropTypes.string,
  brandName: PropTypes.string,
  partNumber: PropTypes.string,
  equipName: PropTypes.string,
  city: PropTypes.string,
  brandImg: PropTypes.string,
  equpImg: PropTypes.string,
  state: PropTypes.string,
  brandModel: PropTypes.string,
  brandSerial: PropTypes.string,
  supplierName: PropTypes.string,
  createdAt: PropTypes.string,
  amount: PropTypes.string,
  quantity: PropTypes.string,
  inviteId: PropTypes.string,
  companyName: PropTypes.string,
  country: PropTypes.string,
  isCompany: PropTypes.bool,
  type: PropTypes.string,
}

export default QuoteRequestCard
