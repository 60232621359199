import Container from 'components/common/Container'
import { React } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import Placeholder from 'assets/images/Placeholder.png'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import Seperator from 'components/common/Seperator'
import useToggle from 'common-hooks/useToggle'
import Image from 'components/common/Image'
import { useNavigate } from 'react-router-dom'
import ReasonDailog from './ReasonDailog'
import { getFlag, getWellKnownAddress } from 'utils/Utils'
import { getColor } from 'theme/colors'

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    // height: '750px',
    width: '450px',
  },
  dialogbody: {
    overflowY: 'scroll',
    padding: '0px 20px 0px 20px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  buyerDetail: {
    padding: '10px 0px 15px 0px',
  },
  EquipDetail: {
    padding: '15px 0px 10px 0px',
  },
  partDetail: {
    padding: '10px 0px',
  },
  reference: {
    padding: '15px 0px 0px 0px',
  },
  CategoryDetail: {
    paddingBottom: '10px',
  },
  notes: {
    padding: '15px 0px',
  },
  referenceDetail: {
    alignItems: 'flex-start',
    padding: '10px 0px 15px 0px',
  },
  billDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
    alignItems: 'flex-start',
  },
  billDetailss: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
    alignItems: 'flex-end',
  },
  imgContainer: {
    '& img': {
      height: '120px',
      width: '120px',
    },
  },
  eqpimage: {
    border: '1px solid #F0F0F4',
    width: '120px',
    height: '120px',
  },
  partDetailTypo: {
    fontWeight: ' 700',
    fontSize: ' 14px',
    lineHeight: '18px',
    color: '#36384C',
  },
  partImageGrid: {
    display: 'flex',
    flexFlow: 'wrap',
  },
})

const QuickInfoDialog = ({
  open,
  onclose,
  quoteId,
  inviteId,
  amount,
  reqId,
  companyName,
  city,
  state,
  country,
  isCompany,
}) => {
  const [toggle, setToggle] = useToggle(false)
  const { supplierReqDetails } = useReqSupplierDetails(quoteId)
  const navigate = useNavigate()
  function handleToggle() {
    onclose()
    setToggle(true)
  }
  const handleBtnClick = () => {
    navigate(amount ? `/quotes/supplierdetail/${quoteId}/active/${reqId}` : `/quotes/supplierdetail/request/${quoteId}`)
  }
  const classes = useStyles()
  return (
    <Container>
      <Dialog open={open} className={classes.mainContainer}>
        <DialogHead title={'Quote Details'} onClose={onclose} />
        <Container className={classes.dialogbody}>
          <Container direction={'column'} justify={'flex-start'} className={classes.buyerDetail}>
            <Typography variant="title" color={getColor('grey-g25')}>
              Buyer
            </Typography>
            {isCompany ? (
              <Typography variant="body1" color={getColor('grey-g25')}>
                {supplierReqDetails?.buyer_full_name} at {companyName}
              </Typography>
            ) : (
              <Typography variant="body1" color={getColor('grey-g25')}>
                {supplierReqDetails?.buyer_full_name}
              </Typography>
            )}
          </Container>
          <Seperator />
          <Container className={classes.EquipDetail}>
            <Typography variant="title" color={getColor('grey-g25')}>
              Equipment
            </Typography>
          </Container>

          <Container className={classes.imgContainer}>
            <Image
              src={supplierReqDetails?.brand_cover_image || Placeholder}
              alt={'brand Image'}
              className={classes.eqpimage}
            />
            <Image
              src={supplierReqDetails?.equip_cover_image || Placeholder}
              alt={'brand Image'}
              className={classes.eqpimage}
            />
          </Container>
          <Container className={classes.buyerDetail}>
            <Typography variant="body1" color={getColor('grey-g25')}>
              <span>{supplierReqDetails?.brand_name}</span> <span>{supplierReqDetails?.equip_name}</span>
              {supplierReqDetails?.brand_model_number ? `> ${supplierReqDetails?.brand_model_number}` : ''}
              {supplierReqDetails?.brand_serial_number ? `> ${supplierReqDetails?.brand_serial_number}` : ''}
            </Typography>
          </Container>

          <Seperator />
          <Container>
            <Container className={classes.EquipDetail}>
              <Typography variant="title" color={getColor('grey-g25')}>
                Part
              </Typography>
            </Container>
            <Container className={classes.partImageGrid}>
              {supplierReqDetails?.quote_assets?.length !== 0 &&
                supplierReqDetails?.quote_assets?.map((obj, index) => (
                  <Container key={index} className={classes.eqpimage}>
                    <Image key={index} src={obj.part_image} alt={'partImage'} className={classes.eqpimage} />
                  </Container>
                ))}
            </Container>
          </Container>
          <Container className={classes.partDetail}>
            <Typography variant="body1 " className={classes.partDetailTypo}>
              {supplierReqDetails?.part_details?.name_path}
            </Typography>
          </Container>
          <Container flex justify={'space-between'} className={classes.CategoryDetail}>
            <Container className={classes.billDetails}>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                Category
              </Typography>
              {/* <Typography variant="paragraph1" color={getColor('grey-g35')}>
                Sub Category
              </Typography> */}
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                Quantity
              </Typography>
            </Container>
            <Container className={classes.billDetailss}>
              <Typography variant="paragraph1" color={getColor('grey-g25')}>
                {supplierReqDetails?.part_details?.name}
              </Typography>
              {/* <Typography variant="paragraph1" color={getColor('grey-g25')}>
                {supplierReqDetails?.part_name}
              </Typography> */}
              <Typography variant="paragraph1" color={getColor('grey-g25')}>
                {supplierReqDetails?.quantity}
              </Typography>
            </Container>
          </Container>
          {supplierReqDetails?.quote_ref_numbers?.length > 0 && <Seperator />}

          <Container className={classes.reference}>
            {supplierReqDetails?.quote_ref_numbers?.length > 0 && (
              <Typography variant="title" color={getColor('grey-g25')}>
                Cross-References
              </Typography>
            )}
          </Container>
          {supplierReqDetails?.quote_ref_numbers?.map((obj, index) => (
            <Container flex direction={'column'} gap={'5px'} className={classes.referenceDetail} key={index}>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {obj?.cross_ref_no}
              </Typography>
            </Container>
          ))}
          <Seperator />
          {supplierReqDetails?.note && (
            <Container className={classes.notes}>
              <Typography variant="title" color={getColor('grey-g25')}>
                Notes
              </Typography>
              <Container>
                <Typography variant="paragraph1" color={getColor('grey-g25')}>
                  {supplierReqDetails?.note}
                </Typography>
              </Container>
            </Container>
          )}

          <Seperator />
          <Container className={classes.notes}>
            <Typography variant="title" color={getColor('grey-g35')}>
              Ship-To:
            </Typography>
            <Container display="flex" alignItems="center" gap="5px">
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {getWellKnownAddress(city, state, country)}
              </Typography>
              {getFlag(country)}
            </Container>
          </Container>
        </Container>

        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border onClick={() => handleToggle()}>
              Can’t Participate
            </Button>
            <Button variant="positive" className={classes.startbtn} onClick={handleBtnClick}>
              {amount ? 'Edit' : 'Start Quote'}
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
      <ReasonDailog open={toggle} onclose={setToggle} inviteId={inviteId} />
    </Container>
  )
}
QuickInfoDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  quoteId: PropTypes.string,
  inviteId: PropTypes.string,
  amount: PropTypes.string,
  reqId: PropTypes.string,
  companyName: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  isCompany: PropTypes.bool,
}

export default QuickInfoDialog
