import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import Button from 'components/common/Button'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import LabelTextField from 'components/common/text/LabelTextField'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px 0',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  bodyInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  subTextLine: {
    color: getColor('grey-g65'),
  },
})

export default function AddShipRatesDialog({ open, onClose }) {
  const classes = useStyles()
  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Shipping Options'} onClose={onClose} />
          <Container className={classes.dialogbody}>
            <Typography variant="body1" color={getColor('grey-g65')}>
              Input Freight rates for buyer to checkout
            </Typography>
            <Container display="flex" justify="space-between" alignItems="center">
              <Container display="flex" alignItems="center" gap="10px">
                <SwitchToggle />
                <Typography variant="body1" color={getColor('grey-g25')}>
                  Ground
                </Typography>
              </Container>
              <LabelTextField labelText={'$'} width={'90px'} placeHolder="80.00" />
            </Container>
            <Container display="flex" justify="space-between" alignItems="center">
              <Container display="flex" alignItems="center" gap="10px">
                <SwitchToggle />
                <Typography variant="body1" color={getColor('grey-g25')}>
                  2nd Day
                </Typography>
              </Container>
              <LabelTextField labelText={'$'} width={'90px'} placeHolder="80.00" />
            </Container>
            <Container display="flex" justify="space-between" alignItems="center">
              <Container display="flex" alignItems="center" gap="10px">
                <SwitchToggle />
                <Typography variant="body1" color={getColor('grey-g25')}>
                  Next Day
                </Typography>
              </Container>
              <LabelTextField labelText={'$'} width={'90px'} placeHolder="80.00" />
            </Container>
            <Container display="flex" justify="space-between" alignItems="center">
              <Container display="flex" alignItems="center" gap="10px">
                <SwitchToggle />
                <Typography variant="body1" color={getColor('grey-g25')}>
                  Local Delivery
                </Typography>
              </Container>
              <LabelTextField labelText={'$'} width={'90px'} placeHolder="80.00" />
            </Container>
            <Container display="flex" justify="space-between" alignItems="center">
              <Container display="flex" alignItems="center" gap="10px">
                <SwitchToggle />
                <Typography variant="body1" color={getColor('grey-g25')}>
                  LTL
                </Typography>
              </Container>
              <LabelTextField labelText={'$'} width={'90px'} placeHolder="80.00" />
            </Container>
            <Container display="flex" justify="space-between" alignItems="center">
              <Container display="flex" alignItems="center" gap="10px">
                <SwitchToggle />
                <Typography variant="body1" color={getColor('grey-g25')}>
                  Pickup (Free)
                </Typography>
              </Container>
            </Container>
            <Typography variant="caption" color={getColor('grey-g65')}>
              *If you put “$0” in price it will considered as “Free Shipping”.
            </Typography>
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button width={72}>Add</Button>
            </Container>
          </DialogFooter>
        </Dialog>
      </Container>
    </>
  )
}
AddShipRatesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
