import Container from 'components/common/Container'
import './css/landingpage.css'
import './css/responsive.css'
import React, { useState } from 'react'
import Snippets from 'assets/images/Snippets.svg'
import industryTractorImg from 'assets/images/industryTractorImg.svg'
import performance from 'assets/images/performance.svg'
import integrity from 'assets/images/integrity.svg'
import neutrality from 'assets/images/neutrality.svg'
import storyImg from 'assets/images/storyImge.png'
import EveryOrder from 'assets/images/EveryOrder.jpg'
import Button from 'components/common/Button'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import LoginDialog from 'containers/authentication/LoginDialog'
import HtmlTitle from 'utils/HtmlTitle'
import LandingPageFooter from './LandingPageFooter'
import LandingPageHeader from './LandingPageHeader'

const AboutUsPage = () => {
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <Container className="about-main">
      <HtmlTitle title={'About Us'} />
      <Container className="container">
        <LandingPageHeader />
      </Container>
      <Container className="about-body">
        <section className="section-about-us p90">
          <div className="container">
            <div className="about-hero">
              <div className="about-text-box">
                <h1 className="heading-about-us">The #1 marketplace for heavy-duty parts</h1>
                <p className="hero-heading-description">
                  PartsClub makes it easy for buyers and suppliers to connect and work together on quotes and orders
                </p>
                <p className="hero-description">
                  We want to dramatically reduce the number of phone calls and emails it takes to order parts. Our suite
                  of digital procurement and sales tools help buyers and sellers communicate effectively and in
                  real-time to get more done than ever before.
                </p>
              </div>
              <div className="about-img-box">
                <img src={Snippets} className="about-img" alt="search and save like a pro" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-industry p90">
          <div className="container">
            <div className="industry">
              <div className="industry-text-box">
                <ul className="industry-list">
                  <li className="industry-list-item">Agricultural & Turf</li>
                  <li>Construction & Mining</li>
                  <li>Industrial & Aerial</li>
                </ul>
                <h1 className="heading-industry">Find great savings from hundreds of verified suppliers</h1>
                <p className="hero-description">
                  We're connecting buyers and sellers in the Agricultural, Construction and Industrial equipment parts
                  industries. Build your network of customers and suppliers to turbocharge your parts business.
                </p>
                <div className="sign-up-free">
                  <Button className="button-sign-up" onClick={setOpenDialog}>
                    Sign up free
                  </Button>
                </div>
              </div>
              <div className="industry-img-box">
                <img src={industryTractorImg} className="industry-img" alt="search and save like a pro" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-performance p90">
          <div className="container">
            <div className="performance">
              <div>
                <h2 className="heading-performance">Our Values</h2>
                <p className="performance-text">
                  Our values of performance, integrity and neutrality mean that we provide a marketplace that is fair
                  and opportunistic for everyone.
                </p>
              </div>
              <div>
                <ul className="performance-list">
                  <li className="performance-list-item">
                    <img src={performance} alt="performance icon" />
                    <p className="integrity-heading">Performance</p>
                    <p className="hero-description">
                      Parts procurement can be thorny and frustrating. We make the lives of both buyers and sellers
                      easier and simpler with functionality specifically designed for the parts industry.
                    </p>
                  </li>
                  <li className="performance-list-item">
                    <img src={integrity} alt="performance icon" />
                    <p className="integrity-heading">Integrity</p>
                    <p className="hero-description">
                      We have a responsibility to keep all parties safe online. We demand integrity of ourselves, of
                      those we work with, and of those who use PartsClub, to ensure a safe platform for all.
                    </p>
                  </li>
                  <li className="performance-list-item">
                    <img src={neutrality} alt="performance icon" />
                    <p className="integrity-heading">Neutrality</p>
                    <p className="hero-description">
                      We are committed to financial independence from all current and future platform participants. Our
                      neutrality allows us to protect the interests and data of all parties.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="section-team p90">
          <div className="container">
            <div className="team">
              <div className="team-img-box">
                <img src={storyImg} className="team-img-box" alt="search and save like a pro" />
              </div>
              <div className="team-text-box">
                <h1 className="heading-industry margin">Our Story</h1>
                <p className="hero-heading-description">Unlocking eCommerce for thousands of parts businesses.</p>
                <p className="hero-description">
                  Our founder Zac Jones created PartsClub after building an online parts business and realizing how
                  difficult and expensive it was to sell parts online. He learned that beyond the upfront costs, the
                  main challenge for sellers is keeping their vast inventory data organized and up-to-date enough to
                  participate online.
                </p>
                <p className="hero-description">
                  PartsClub allows parts suppliers to start receiving buyer quote requests in minutes without a single
                  part uploaded and has fees up to 70% lower than competitor platforms. This unlocks eCommerce for
                  thousands of suppliers whose inventories previously prohibited them from participating online and
                  presents buyers with greater pricing and a wider array of verified purchasing options than ever
                  before.{' '}
                </p>
                <p className="hero-description">
                  PartsClub Marketplace provides an affordable, accessible, and industry-specific alternative to Amazon
                  & eBay for equipment parts buyers and suppliers.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Container className="every-order-sec">
          <Container className="container">
            <Container className="every-order-inner p90">
              <Container className="every-order-grid">
                <Container className="every-order-left">
                  <Container className="every-order-left-inner">
                    <Typography className="title" component="h1">
                      Every order supports military veterans & their families
                    </Typography>
                    <Typography variant="h3" component="h3" color={getColor('grey-g25')}>
                      Supporting those who serve and sacrifice to keep us safe.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      We are committed to donating a portion of each sale on PartsClub to the Wounded Warrior Project in
                      support of Veterans and their families.
                    </Typography>
                    <a
                      // variant="transparent"
                      className={'everyOrderBtn'}
                      href={'https://www.woundedwarriorproject.org'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Wounded Warrior Project
                    </a>
                  </Container>
                </Container>
                <Container className="every-order-right">
                  <img src={EveryOrder} alt="EveryOrder" />
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <LandingPageFooter />
      <LoginDialog open={openDialog} onClose={setOpenDialog} />
    </Container>
  )
}

export default AboutUsPage
