import { makeStyles } from '@mui/styles'
import { CloseIcon } from 'assets/icons'
import classNames from 'classnames'
import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'
import Typography from 'components/common/Typography'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    padding: '0.938rem 1.25rem',
    alignItems: 'center',
    backgroundColor: getColor('light'),
    borderBottom: `1px solid ${getColor('grey-g95')}`,
    ...getRootStyle(props),
  }),
  content: {
    flex: 1,
  },
  rightContent: {
    width: 'auto',
  },
  underLine: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))
export default function DrawerHeader({ children, title, className, onClose }) {
  const classes = useStyles()
  return (
    <Container container justify="space-between" alignItems="center" className={classNames(classes.root, className)}>
      <Container container alignItems="center" className={classes.content}>
        {title && <Typography variant="h3">{title}</Typography>}

        {children}
      </Container>
      <Container item container alignItems="center" className={classes.rightContent}>
        <IconButton size="md" variant="transparent" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Container>
    </Container>
  )
}

DrawerHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}
function getRootStyle(props) {
  return {
    ...(props.divider && {
      borderTopWidth: 0.5,
      borderTopStyle: 'solid',
      borderTopColor: getColor('grey-g85'),
    }),
  }
}
