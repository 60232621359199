import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
})

export default function EquipmentConformationDialog({ open, onClose, equipDeleteId, handleAction }) {
  const classes = useStyles()
  const { handleSubmit } = useForm()
  //   const options = countryOption?.map((item) => createOption(item, item))

  const onSubmit = () => {
    // navigate('/inventory')
    onClose()
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Confirmation'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.selectField}>
              <Typography variant="body1" color={getColor('grey-g35')}>
                Are you sure you want to delete equipment? Once you delete it you won’t be able to get it back.
              </Typography>
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="error"
                  onClick={() => {
                    handleAction('perent-delete', equipDeleteId)
                    onClose()
                  }}
                >
                  Delete
                </Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
EquipmentConformationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  equipDeleteId: PropTypes.string,
  handleAction: PropTypes.func,
}
