import { getColor } from './colors'

const overrides = {
  MuiInput: {
    input: {
      '&::placeholder': {
        color: getColor('grey-g50'),
      },
    },
  },
  MuiButton: {
    root: {
      minWidth: 'auto',
    },
  },
  MuiBadge: {
    badge: {
      height: 12,
      paddingLeft: 2,
      paddingRight: 2,
      fontSize: 8,
    },
  },
  MuiTypography: {
    colorPrimary: {
      color: getColor('dark'),
    },
    colorSecondary: {
      color: getColor('grey-g35'),
    },
    colorTextPrimary: {
      color: getColor('grey-g50'),
    },
    button: {
      color: getColor('light'),
    },
  },
  MuiDivider: {
    root: {
      height: 0.5,
      backgroundColor: getColor('grey-g85'),
    },
  },
}

export default overrides
