import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import React from 'react'
import { getColor } from 'theme/colors'
import PropTypes from 'prop-types'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import Button from 'components/common/Button'
import useToggle from 'common-hooks/useToggle'
import ReasonDailog from './ReasonDialog'

const useStyles = makeStyles({
  dialogBody: {
    padding: '20px',
    display: 'flex',
    gap: '20px 0',
    flexDirection: 'column',
  },
  DialogWidth: {
    width: '450px',
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0 10px',
  },
  cancelBtn: {
    width: '77px',
    border: '1px solid #9799B5',
    backgroundColor: 'transparent',
    color: '#4A4D68',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

const ConfirmationDialog = ({ open, onClose }) => {
  const classes = useStyles()

  const [reasonDialog, setReasonDialog] = useToggle(false)

  return (
    <Container>
      <Dialog open={open} className={classes.DialogWidth}>
        <DialogHead title={'Confirmation'} onClose={onClose} />
        <Container className={classes.dialogBody}>
          <Typography component="p" variant="body1" color={getColor('grey-g25')}>
            Are you sure you want to cancel plan?
          </Typography>
          <Typography component="p" variant="body1" color={getColor('grey-g25')}>
            Your plan will change at the end of your currently billing period.
          </Typography>
        </Container>
        <DialogFooter>
          <Container className={classes.dialogFooter}>
            <Button className={classes.cancelBtn} onClick={onClose}>
              Cancel
            </Button>
            <Button width={'92px'} onClick={() => setReasonDialog(true)}>
              Continue
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
      <ReasonDailog open={reasonDialog} onclose={setReasonDialog} />
    </Container>
  )
}
export default ConfirmationDialog
ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
