import Container from 'components/common/Container'
import { React, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { Controller, useForm } from 'react-hook-form'
import RadioGroup from 'components/common/radio/RadioGroup'
import Radio from 'components/common/radio/Radio'
import TextArea from 'components/common/text/TextArea'
import { getColor } from 'theme/colors'
import { useParams } from 'react-router-dom'
import useRequestQuote from 'common-hooks/search-product/useRequestQuote'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  feedback: {
    padding: '10px 0px 20px 0px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    paddingBottom: '10px',
  },
  cancelOrder: {
    background: '#AD1F36',
    width: '121px',
  },
})

export default function ReportDialog({ open, onClose }) {
  const { handleSubmit, register, setValue, control, reset } = useForm({
    defaultValues: {
      noteValue: '',
    },
  })
  const [radioValue, setRadioValue] = useState('Other')
  const { toastMessage } = useToastify()
  const params = useParams()

  const { reportProduct } = useRequestQuote()

  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    if (radioValue === 'Other' && data?.noteValue === '') {
      toastMessage('error', 'Notes is required')
      return 0
    }

    reportProduct(
      radioValue === 'Other' && data?.noteValue !== '' ? { reason: data?.noteValue } : { reason: radioValue },
      params.id
    )
    reset({
      Inappropriate: '',
      Fraudulent: '',
      Misleading: '',
      Duplicate: '',
      DataError: '',
      noteValue: '',
    })
    setRadioValue('Other')
    onClose()
  }

  const handleOnradio = (e) => {
    setRadioValue(e.target.value)
  }

  const classes = useStyles()
  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <form id={'resonForm'} onSubmit={handleSubmit(onSubmit)}>
            <DialogHead title={'Reason For Report'} onClose={onClose} />
            <Container className={classes.dialogbody}>
              <Container className={classes.feedback}>
                <Typography variant="paragraph1" color={getColor('grey-g25')}>
                  Your response will be privately reviewed by PartsClub against our policies and Terms of Service within
                  48-72 hours:
                </Typography>
              </Container>
              <Container>
                <RadioGroup defaultValue={'Other'} onChange={handleOnradio}>
                  <Container className={classes.radioContainer}>
                    <Controller
                      name={'Inappropriate'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Inappropriate'}
                          name={name}
                          value={'Inappropriate'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Inappropriate')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'Fraudulent'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Fraudulent'}
                          name={name}
                          value={'Fraudulent'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Fraudulent')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'Misleading'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Misleading'}
                          value={'Misleading'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Misleading')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'Duplicate'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Duplicate'}
                          value={'Duplicate'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Duplicate')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'DataError'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Data Error'}
                          value={'Data Error'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Data Error')
                            }
                          }}
                        />
                      )}
                    />
                    <Radio name={'Other'} label={'Other'} value={'Other'} />
                  </Container>
                </RadioGroup>
              </Container>
              {radioValue === 'Other' && (
                <Container>
                  <TextArea
                    id={'noteArea'}
                    placeholder={'Paragraph'}
                    name={'noteValue'}
                    rows={4}
                    {...register('noteValue')}
                  />
                </Container>
              )}
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">Submit</Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
ReportDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  inviteId: PropTypes.string,
}
