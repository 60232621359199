import { makeStyles } from '@mui/styles'
import { Done } from 'assets/icons'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import useGetCounterListBuyer from 'common-hooks/buyer/counter/useGetCounterListBuyer'
import useSupplierCounter from 'common-hooks/buyer/counter/useSupplierCounter'
import useToggle from 'common-hooks/useToggle'
import PropTypes from 'prop-types'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Seperator from 'components/common/Seperator'
import LabelTextField from 'components/common/text/LabelTextField'
import TextArea from 'components/common/text/TextArea'
import Typography from 'components/common/Typography'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getColor, getTagColor } from 'theme/colors'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import { allowOnlyAlphaNumric, expiredIn48Hours, formatPrice } from 'utils/Utils'
import ConfirmationDialog from './ConfirmationDialog'
import useSuppliersActiveList from 'common-hooks/buyer-supplier-list/useSuppliersActiveList'
import useOrderDetails from 'common-hooks/orders/useOrderDetails'

const useStyles = makeStyles({
  cardRoot: {
    background: '#FFFFFF',
    border: ' 1px solid #F0F0F4',
    padding: '20px',
    marginBottom: '20px',
  },
  acptBtn: {
    display: 'flex',
    gap: '0 10px',
    paddingLeft: '140px',
  },
  offerPricing: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
    '& p': {
      width: '140px',
    },
  },
  cardBuyerSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  offerSend: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '15px 0',
    '& p': {
      width: '140px',
    },
  },
  cardInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  Notes: {
    width: '100%',
    '& div': {
      width: '800px',
    },
  },
  offerSendWaingResponse: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  textWidth: {
    width: '140px',
  },
  couterPrice: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
  },
  finalPrice: {
    display: 'flex',

    gap: '10px 0',
    '& p': {
      width: '140px',
    },
  },
  supplierAccpeptOffer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  expirdOfferPrice: {
    display: 'flex',
    '& p': {
      width: '140px',
    },
  },
  NudgeBtn: {
    marginLeft: '140px',
  },
  expirdOffer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
})
export default function CounterOfferCard({ setOfferCount }) {
  const classes = useStyles()

  const [counterOffer, setCounterOffer] = useState(false)
  const [confirmationDialog, setConfirmatioDialog] = useToggle(false)
  const { nudgeMailSupplier } = useSuppliersActiveList()
  const params = useParams()
  const { supplierReqDetails } = useReqSupplierDetails(params?.id)
  const navigate = useNavigate()
  const [orderId, setOrderId] = useState()
  const { counterData, mutate, setCounterOfferExpire } = useGetCounterListBuyer(params?.reqId)
  const [currectPrice, setCurrectPrice] = useState()
  const [offerPrice, setOfferPrice] = useState()
  const [counterId, setCounterId] = useState('')
  const { setSupplierCounter } = useSupplierCounter()
  const [date, setDate] = useState()
  const [offerExpired, setOfferExpired] = useState(false)
  const [note, setNotes] = useState('')
  const [counterPrice, setCounterPrice] = useState('')
  const { orderDetailsData } = useOrderDetails(orderId)
  useEffect(() => {
    counterData?.map((data) => {
      setOrderId(orderId)
      setCurrectPrice(data?.current_price)
      setOfferPrice(data?.offer_price)
      setOfferCount(data?.pricing_count)
      setDate(data?.created_at)
      if (expiredIn48Hours(data?.created_at) === '') {
        setOfferExpired(true)
        setCounterOfferExpire({ quote_id: params?.id, quote_request_id: params?.reqId })
      } else {
        setOfferExpired(false)
      }
      return 0
    })
  }, [counterData])

  useEffect(() => {
    counterData?.map((data) => {
      if (data?.orders_id) {
        setOrderId(data?.orders_id)
      }
      return 0
    })
  }, [counterData?.map((data) => data?.orders_id)])

  const handleClick = () => {
    setSupplierCounter(
      {
        quote_id: params?.id,
        quote_request_id: params?.reqId,
        current_price: currectPrice,
        offer_price: counterPrice,
        ...(note !== '' && { note }),
      },
      mutate
    )
    setCounterOffer(false)
  }
  const handleOrderClick = (orderId) => {
    setOrderId(orderId)
    if (orderDetailsData) {
      navigate(`/orders/AwaitingShipment/${orderDetailsData?.order_id}/${orderId}`)
    }
  }
  function handleApproveClick(id) {
    setCounterId(id)
    setConfirmatioDialog(true)
  }
  const handleNudgeClick = () => {
    nudgeMailSupplier({ quote_id: params?.id, quote_request_id: params?.reqId })
  }
  return (
    <>
      {counterData?.[0]?.type && (
        <>
          {/* Offer 3 complete */}
          {(counterData?.length === 6 || counterData?.length === 5) && (
            <Container className={classes.cardRoot}>
              <Container className={classes.cardInner}>
                {counterData?.map((data, index) => (
                  <>
                    {data?.type === 'customer' && data?.pricing_count === '3' && (
                      <Container className={classes.cardBuyerSide} key={index}>
                        <Container flex justify="space-between">
                          <Typography variant="title" color="#13141B">
                            {data?.pricing_count === null ? '1' : data?.pricing_count}rd offer from buyer
                          </Typography>
                          <Container flex gap="10px">
                            {counterData?.length === 5 && supplierReqDetails?.status !== 'closed' && (
                              <>
                                <Typography color="#AD1F36" variant="paragraph1">
                                  {expiredIn48Hours(date) === ''
                                    ? 'Offer Expired'
                                    : `Expires : ${expiredIn48Hours(date)}`}{' '}
                                </Typography>
                                •
                              </>
                            )}

                            <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                              {formatDate(date, DATE_FORMAT_PRIMARY)}
                            </Typography>
                          </Container>
                        </Container>
                        <Container className={classes.offerPricing}>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Buyer Offer Price:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {formatPrice(data?.offer_price)}
                            </Typography>
                          </Container>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Shipping Option:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {data?.shipping_options?.freight_ground &&
                                `Ground • ${formatPrice(data?.shipping_options?.freight_ground)}`}
                              {data?.shipping_options?.freight_next_day &&
                                `Next Day Delivery • ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                              {data?.shipping_options?.freight_second_day &&
                                `2nd Day • ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                              {data?.shipping_options?.mdo_pick_up &&
                                `Pick Up • ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                              {data?.shipping_options?.mdo_ltl &&
                                `LTL • ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                              {data?.shipping_options?.mdo_local_delivery &&
                                `Local Delivery • ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                            </Typography>
                          </Container>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Notes:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {data?.note}
                            </Typography>
                          </Container>
                        </Container>
                        {counterData?.length === 5 && expiredIn48Hours(date) !== '' && (
                          <>
                            {!counterOffer && !data?.is_approved && (
                              <Container className={classes.acptBtn}>
                                <Button onClick={() => handleApproveClick(data?.id)}> Accept Offer</Button>
                                <Button variant="secondary" border onClick={() => setCounterOffer(!counterOffer)}>
                                  Counter
                                </Button>
                              </Container>
                            )}
                          </>
                        )}
                        {counterData?.length === 5 && expiredIn48Hours(date) === '' && (
                          <>
                            {!counterOffer && !data?.is_approved && (
                              <Container className={classes.acptBtn}>
                                <Button variant="secondary" border onClick={() => handleNudgeClick()}>
                                  Request New Offer
                                </Button>
                              </Container>
                            )}
                          </>
                        )}
                      </Container>
                    )}
                  </>
                ))}

                {counterOffer && (
                  <>
                    <Seperator />
                    <Container className={classes.offerSend}>
                      <Container flex justify="flex-start">
                        <Typography variant="body1" color={getColor('grey-g35')} component="p">
                          Counter Price*:
                        </Typography>
                        <LabelTextField
                          labelText={'$'}
                          width={'150px'}
                          onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                          onChange={(e) => setCounterPrice(e)}
                        />
                      </Container>
                      <Container flex justify="flex-start" width={'100%'}>
                        <Typography variant="body1" color={getColor('grey-g35')} component="p">
                          Notes:
                        </Typography>
                        <Container width={'86%'}>
                          <TextArea onChange={(e) => setNotes(e?.target?.value)} />
                        </Container>
                      </Container>
                      <Container flex gap={'0 10px'} justify="flex-start">
                        <Button onClick={handleClick}>Send Offer</Button>
                        <Button
                          variant="secondary"
                          border
                          onClick={() => {
                            setCounterOffer(!counterOffer)
                          }}
                        >
                          Cancel
                        </Button>
                      </Container>
                    </Container>
                  </>
                )}

                {counterData?.map((data) => (
                  <>
                    {supplierReqDetails?.status === 'closed' && data?.orders_id && (
                      <>
                        <Seperator />
                        <Container className={classes.supplierAccpeptOffer}>
                          <Container flex justify="space-between">
                            <Container flex gap="0 5px">
                              <Done />
                              <Typography variant="title" color="#13141B">
                                Congratulations - Order created {orderDetailsData?.order_id}
                              </Typography>
                            </Container>
                            <Container flex gap={'0 5px'}>
                              <Typography variant="paragraph1" color="#0DA50D">
                                Order Placed
                              </Typography>
                              <Typography color="#4A4D68">•</Typography>
                              <Typography variant="paragraph1" color="#4A4D68">
                                {formatDate(date, DATE_FORMAT_PRIMARY)}
                              </Typography>
                            </Container>
                          </Container>
                          <Container className={classes.finalPrice}>
                            <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                              Final Price:
                            </Typography>
                            <Container>
                              <Typography variant="body1" color={getColor('grey-g35')} component={'h5'}>
                                {formatPrice(data?.offer_price)}
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                The buyer has paid for this order and you are now ready to ship this product.
                              </Typography>
                            </Container>
                          </Container>
                          <Container flex justify="flex-start" gap={'0 10px'}>
                            <Button
                              onClick={() => {
                                navigate(`/orders/AwaitingShipment/${orderDetailsData?.order_id}/${data?.orders_id}`, {
                                  state: { upatetraking: true },
                                })
                              }}
                            >
                              Update Tracking
                            </Button>
                            <Button variant="secondary" border onClick={() => handleOrderClick(data?.orders_id)}>
                              View Order
                            </Button>
                          </Container>
                        </Container>
                      </>
                    )}
                  </>
                ))}
                {counterData?.map((data) => (
                  <>
                    {data?.is_approved &&
                      supplierReqDetails?.status !== 'closed' &&
                      (data?.type === 'customer' || data?.type === 'supplier') && (
                        <>
                          <Seperator />
                          <Container className={classes.supplierAccpeptOffer}>
                            <Container flex justify="space-between">
                              <Container flex gap="0 5px">
                                <Done />
                                <Typography variant="title" color="#13141B">
                                  Congratulations - you've locked in the order!
                                </Typography>
                              </Container>
                              <Container flex gap="10px">
                                <Typography variant="paragraph1" color="#0DA50D">
                                  Offer Approved
                                </Typography>
                                <Typography color="#AD1F36" variant="paragraph1">
                                  {expiredIn48Hours(date) === ''
                                    ? 'Offer Expired'
                                    : `Expires : ${expiredIn48Hours(date)}`}{' '}
                                </Typography>
                                •
                                <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                                  {formatDate(date, DATE_FORMAT_PRIMARY)}
                                </Typography>
                              </Container>
                            </Container>
                            <Container className={classes.finalPrice}>
                              <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                                Final Price:
                              </Typography>
                              <Container>
                                <Typography variant="body1" color={getColor('grey-g35')} component={'h5'}>
                                  {formatPrice(data?.offer_price)}
                                </Typography>
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  You’ll receive an order confirmation email when the buyer has paid{' '}
                                </Typography>
                              </Container>
                            </Container>
                          </Container>
                        </>
                      )}
                  </>
                ))}
                {counterData?.map((data) => (
                  <>
                    {data?.type === 'supplier' &&
                      data?.pricing_count === '3' &&
                      supplierReqDetails?.status !== 'closed' && (
                        <>
                          <Seperator />
                          <Container className={classes.offerSendWaingResponse}>
                            <Container flex justify="space-between">
                              <Typography variant="title" color="#13141B">
                                You responded with counter offer!
                              </Typography>
                              <Container flex gap="10px">
                                {counterData?.length === 6 && (
                                  <>
                                    <Typography color="#AD1F36" variant="paragraph1">
                                      {expiredIn48Hours(date) === ''
                                        ? 'Offer Expired'
                                        : `Expires : ${expiredIn48Hours(date)}`}{' '}
                                    </Typography>
                                    •
                                  </>
                                )}

                                <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                                  {formatDate(date, DATE_FORMAT_PRIMARY)}
                                </Typography>
                              </Container>
                            </Container>
                            <Container className={classes.couterPrice}>
                              <Container flex justify="flex-start">
                                <Typography
                                  variant="body1"
                                  color={getColor('grey-g35')}
                                  component="p"
                                  className={classes.textWidth}
                                >
                                  Counteroffer Price:
                                </Typography>
                                <Typography variant="body1" color={getColor('grey-g35')} component="p">
                                  {formatPrice(data?.offer_price)}
                                </Typography>
                              </Container>
                              <Container flex justify="flex-start">
                                <Typography
                                  variant="body1"
                                  color={getColor('grey-g35')}
                                  component="p"
                                  className={classes.textWidth}
                                >
                                  Notes:
                                </Typography>
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  {data?.note}
                                </Typography>
                              </Container>
                              {counterData?.length === 6 && expiredIn48Hours(date) === '' && (
                                <>
                                  {!counterOffer && !data?.is_approved && (
                                    <Container className={classes.acptBtn}>
                                      <Button variant="secondary" border onClick={() => handleNudgeClick()}>
                                        Nudge
                                      </Button>
                                    </Container>
                                  )}
                                </>
                              )}
                            </Container>
                          </Container>
                        </>
                      )}
                  </>
                ))}
              </Container>
            </Container>
          )}

          {/* Offer 2 complete */}
          {(counterData?.length === 3 ||
            counterData?.length === 4 ||
            counterData?.length === 6 ||
            counterData?.length === 5) && (
            <Container className={classes.cardRoot}>
              <Container className={classes.cardInner}>
                {counterData?.map((data, index) => (
                  <>
                    {data?.type === 'customer' && data?.pricing_count === '2' && (
                      <Container className={classes.cardBuyerSide} key={index}>
                        <Container flex justify="space-between">
                          <Typography variant="title" color="#13141B">
                            {data?.pricing_count === null ? '1' : data?.pricing_count}nd offer from buyer
                          </Typography>
                          <Container flex gap="10px">
                            {counterData?.length === 3 &&
                              !data?.is_approved &&
                              supplierReqDetails?.status !== 'closed' && (
                                <>
                                  <Typography color="#AD1F36" variant="paragraph1">
                                    {expiredIn48Hours(date) === ''
                                      ? 'Offer Expired'
                                      : `Expires : ${expiredIn48Hours(date)}`}{' '}
                                  </Typography>
                                  •
                                </>
                              )}

                            <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                              {formatDate(date, DATE_FORMAT_PRIMARY)}
                            </Typography>
                          </Container>
                        </Container>
                        <Container className={classes.offerPricing}>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Buyer Offer Price:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {formatPrice(data?.offer_price)}
                            </Typography>
                          </Container>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Shipping Option:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {data?.shipping_options?.freight_ground &&
                                `Ground • ${formatPrice(data?.shipping_options?.freight_ground)}`}
                              {data?.shipping_options?.freight_next_day &&
                                `Next Day Delivery • ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                              {data?.shipping_options?.freight_second_day &&
                                `2nd Day • ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                              {data?.shipping_options?.mdo_pick_up &&
                                `Pick Up • ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                              {data?.shipping_options?.mdo_ltl &&
                                `LTL • ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                              {data?.shipping_options?.mdo_local_delivery &&
                                `Local Delivery • ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                            </Typography>
                          </Container>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Notes:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {data?.note}
                            </Typography>
                          </Container>
                        </Container>
                        {counterData?.length === 3 && expiredIn48Hours(date) !== '' && (
                          <>
                            {!counterOffer && !data?.is_approved && (
                              <Container className={classes.acptBtn}>
                                <Button onClick={() => handleApproveClick(data?.id)}> Accept Offer</Button>
                                <Button variant="secondary" border onClick={() => setCounterOffer(!counterOffer)}>
                                  Counter
                                </Button>
                              </Container>
                            )}
                          </>
                        )}
                        {counterData?.length === 3 && expiredIn48Hours(date) === '' && (
                          <>
                            {!counterOffer && !data?.is_approved && (
                              <Container className={classes.acptBtn}>
                                <Button variant="secondary" border onClick={() => handleNudgeClick()}>
                                  Request New Offer
                                </Button>
                              </Container>
                            )}
                          </>
                        )}
                      </Container>
                    )}
                  </>
                ))}

                {counterOffer && counterData?.length === 3 && (
                  <>
                    <Seperator />
                    <Container className={classes.offerSend}>
                      <Container flex justify="flex-start">
                        <Typography variant="body1" color={getColor('grey-g35')} component="p">
                          Counter Price*:
                        </Typography>
                        <LabelTextField
                          labelText={'$'}
                          width={'150px'}
                          onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                          onChange={(e) => setCounterPrice(e)}
                        />
                      </Container>
                      <Container flex justify="flex-start" width={'100%'}>
                        <Typography variant="body1" color={getColor('grey-g35')} component="p">
                          Notes:
                        </Typography>
                        <Container width={'86%'}>
                          <TextArea onChange={(e) => setNotes(e?.target?.value)} />
                        </Container>
                      </Container>
                      <Container flex gap={'0 10px'} justify="flex-start">
                        <Button onClick={handleClick}>Send Offer</Button>
                        <Button
                          variant="secondary"
                          border
                          onClick={() => {
                            setCounterOffer(!counterOffer)
                          }}
                        >
                          Cancel
                        </Button>
                      </Container>
                    </Container>
                  </>
                )}
                {counterData?.map((data) => (
                  <>
                    {data?.type === 'supplier' && data?.pricing_count === '2' && !data?.orders_id && (
                      <>
                        <Seperator />
                        <Container className={classes.offerSendWaingResponse}>
                          <Container flex justify="space-between">
                            <Typography variant="title" color="#13141B">
                              You responded with counter offer!
                            </Typography>
                            <Container flex gap="10px">
                              {counterData?.length === 4 && (
                                <>
                                  <Typography color="#AD1F36" variant="paragraph1">
                                    {expiredIn48Hours(date) === ''
                                      ? 'Offer Expired'
                                      : `Expires : ${expiredIn48Hours(date)}`}{' '}
                                  </Typography>
                                  •
                                </>
                              )}

                              <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                                {formatDate(date, DATE_FORMAT_PRIMARY)}
                              </Typography>
                            </Container>
                          </Container>
                          <Container className={classes.couterPrice}>
                            <Container flex justify="flex-start">
                              <Typography
                                variant="body1"
                                color={getColor('grey-g35')}
                                component="p"
                                className={classes.textWidth}
                              >
                                Counteroffer Price:
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')} component="p">
                                {formatPrice(data?.offer_price)}
                              </Typography>
                            </Container>
                            <Container flex justify="flex-start">
                              <Typography
                                variant="body1"
                                color={getColor('grey-g35')}
                                component="p"
                                className={classes.textWidth}
                              >
                                Notes:
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                {data?.note}
                              </Typography>
                            </Container>
                            {counterData?.length === 4 && expiredIn48Hours(date) === '' && (
                              <>
                                {!counterOffer && !data?.is_approved && (
                                  <Container className={classes.acptBtn}>
                                    <Button variant="secondary" border onClick={() => handleNudgeClick()}>
                                      Nudge
                                    </Button>
                                  </Container>
                                )}
                              </>
                            )}
                          </Container>
                        </Container>
                      </>
                    )}
                  </>
                ))}
                {counterData?.map((data) => (
                  <>
                    {data?.pricing_count === '2' && data?.orders_id && supplierReqDetails?.status === 'closed' && (
                      <>
                        <Seperator />
                        <Container className={classes.supplierAccpeptOffer}>
                          <Container flex justify="space-between">
                            <Container flex gap="0 5px">
                              <Done />
                              <Typography variant="title" color="#13141B">
                                Congratulations - Order created {orderDetailsData?.order_id}
                              </Typography>
                            </Container>
                            <Container flex gap={'0 5px'}>
                              <Typography variant="paragraph1" color="#0DA50D">
                                Order Placed
                              </Typography>
                              <Typography color="#4A4D68">•</Typography>
                              <Typography variant="paragraph1" color="#4A4D68">
                                {formatDate(date, DATE_FORMAT_PRIMARY)}
                              </Typography>
                            </Container>
                          </Container>
                          <Container className={classes.finalPrice}>
                            <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                              Final Price:
                            </Typography>
                            <Container>
                              <Typography variant="body1" color={getColor('grey-g35')} component={'h5'}>
                                {formatPrice(data?.offer_price)}
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                The buyer has paid for this order and you are now ready to ship this product.
                              </Typography>
                            </Container>
                          </Container>
                          <Container flex justify="flex-start" gap={'0 10px'}>
                            <Button
                              onClick={() => {
                                navigate(`/orders/AwaitingShipment/${orderDetailsData?.order_id}/${data?.orders_id}`, {
                                  state: { upatetraking: true },
                                })
                              }}
                            >
                              Update Tracking
                            </Button>
                            <Button variant="secondary" border onClick={() => handleOrderClick(data?.orders_id)}>
                              View Order
                            </Button>
                          </Container>
                        </Container>
                      </>
                    )}
                  </>
                ))}
                {counterData?.map((data) => (
                  <>
                    {data?.is_approved &&
                      data?.pricing_count === '2' &&
                      supplierReqDetails?.status !== 'closed' &&
                      (data?.type === 'customer' || data?.type === 'supplier') && (
                        <>
                          <Seperator />
                          <Container className={classes.supplierAccpeptOffer}>
                            <Container flex justify="space-between">
                              <Container flex gap="0 5px">
                                <Done />
                                <Typography variant="title" color="#13141B">
                                  Congratulations - you've locked in the order!
                                </Typography>
                              </Container>
                              <Container flex gap="10px">
                                <Typography variant="paragraph1" color="#0DA50D">
                                  Offer Approved
                                </Typography>
                                <Typography color="#AD1F36" variant="paragraph1">
                                  {expiredIn48Hours(date) === ''
                                    ? 'Offer Expired'
                                    : `Expires : ${expiredIn48Hours(date)}`}{' '}
                                </Typography>
                                •
                                <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                                  {formatDate(date, DATE_FORMAT_PRIMARY)}
                                </Typography>
                              </Container>
                            </Container>
                            <Container className={classes.finalPrice}>
                              <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                                Final Price:
                              </Typography>
                              <Container>
                                <Typography variant="body1" color={getColor('grey-g35')} component={'h5'}>
                                  {formatPrice(data?.offer_price)}
                                </Typography>
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  You’ll receive an order confirmation email when the buyer has paid{' '}
                                </Typography>
                              </Container>
                            </Container>
                          </Container>
                        </>
                      )}
                  </>
                ))}
              </Container>
            </Container>
          )}

          {/* Offer 1 complete */}
          {counterData && (
            <Container className={classes.cardRoot}>
              <Container className={classes.cardInner}>
                {counterData?.map((data, index) => (
                  <>
                    {data?.type === 'customer' && data?.pricing_count === '1' && (
                      <Container className={classes.cardBuyerSide} key={index}>
                        <Container flex justify="space-between">
                          <Typography variant="title" color="#13141B">
                            {data?.pricing_count === null ? '1' : data?.pricing_count}st offer from buyer
                          </Typography>
                          <Container flex gap={'0 5px'}>
                            {counterData?.length === 1 &&
                              !data?.is_approved &&
                              supplierReqDetails?.status !== 'closed' && (
                                <Container flex gap="5px">
                                  <Typography color="#AD1F36" variant="paragraph1">
                                    {expiredIn48Hours(date) === ''
                                      ? 'Offer Expired'
                                      : `Expires : ${expiredIn48Hours(date)}`}{' '}
                                  </Typography>
                                  •
                                </Container>
                              )}

                            <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                              {formatDate(date, DATE_FORMAT_PRIMARY)}
                            </Typography>
                          </Container>
                        </Container>
                        <Container className={classes.offerPricing}>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Buy Now Price:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {formatPrice(data?.current_price)}
                            </Typography>
                          </Container>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Offer Price:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {formatPrice(data?.offer_price)}
                            </Typography>
                          </Container>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Shipping Option:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {data?.shipping_options?.freight_ground &&
                                `Ground • ${formatPrice(data?.shipping_options?.freight_ground)}`}
                              {data?.shipping_options?.freight_next_day &&
                                `Next Day Delivery • ${formatPrice(data?.shipping_options?.freight_next_day)}`}
                              {data?.shipping_options?.freight_second_day &&
                                `2nd Day • ${formatPrice(data?.shipping_options?.freight_second_day)}`}
                              {data?.shipping_options?.mdo_pick_up &&
                                `Pick Up • ${formatPrice(data?.shipping_options?.mdo_pick_up)}`}
                              {data?.shipping_options?.mdo_ltl &&
                                `LTL • ${formatPrice(data?.shipping_options?.mdo_ltl)}`}
                              {data?.shipping_options?.mdo_local_delivery &&
                                `Local Delivery • ${formatPrice(data?.shipping_options?.mdo_local_delivery)}`}
                            </Typography>
                          </Container>
                          <Container flex justify="flex-start">
                            <Typography variant="body1" color={getColor('grey-g35')} component="p">
                              Notes:
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {data?.note}
                            </Typography>
                          </Container>
                        </Container>
                        {counterData?.length === 1 && !data?.is_approved && expiredIn48Hours(date) !== '' && (
                          <>
                            {!counterOffer && (
                              <Container className={classes.acptBtn}>
                                <Button onClick={() => handleApproveClick(data?.id)}> Accept Offer</Button>
                                <Button variant="secondary" border onClick={() => setCounterOffer(!counterOffer)}>
                                  Counter
                                </Button>
                              </Container>
                            )}
                          </>
                        )}
                        {counterData?.length === 1 && expiredIn48Hours(date) === '' && (
                          <>
                            {!counterOffer && !data?.is_approved && (
                              <Container className={classes.acptBtn}>
                                <Button variant="secondary" border onClick={() => handleNudgeClick()}>
                                  Request New Offer
                                </Button>
                              </Container>
                            )}
                          </>
                        )}
                      </Container>
                    )}
                  </>
                ))}

                {counterOffer && counterData?.length === 1 && (
                  <>
                    <Seperator />
                    <Container className={classes.offerSend}>
                      <Container flex justify="flex-start">
                        <Typography variant="body1" color={getColor('grey-g35')} component="p">
                          Counter Price*:
                        </Typography>
                        <LabelTextField
                          labelText={'$'}
                          width={'150px'}
                          onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                          onChange={(e) => setCounterPrice(e)}
                        />
                      </Container>
                      <Container flex justify="flex-start" width={'100%'}>
                        <Typography variant="body1" color={getColor('grey-g35')} component="p">
                          Notes:
                        </Typography>
                        <Container width={'86%'}>
                          <TextArea onChange={(e) => setNotes(e?.target?.value)} />
                        </Container>
                      </Container>
                      <Container flex gap={'0 10px'} justify="flex-start">
                        <Button onClick={handleClick}>Send Offer</Button>
                        <Button
                          variant="secondary"
                          border
                          onClick={() => {
                            setCounterOffer(!counterOffer)
                          }}
                        >
                          Cancel
                        </Button>
                      </Container>
                    </Container>
                  </>
                )}
                {counterData?.map((data) => (
                  <>
                    {data?.type === 'supplier' && data?.pricing_count === '1' && !data?.orders_id && (
                      <>
                        <Seperator />
                        <Container className={classes.offerSendWaingResponse}>
                          <Container flex justify="space-between">
                            <Typography variant="title" color="#13141B">
                              {counterData?.length === 2 && !offerExpired
                                ? 'Counteroffer sent - Waiting for response'
                                : 'You responded with counter offer!'}
                            </Typography>

                            <Container flex gap="10px">
                              {counterData?.length === 2 && (
                                <>
                                  <Typography color="#AD1F36" variant="paragraph1">
                                    {expiredIn48Hours(date) === ''
                                      ? 'Offer Expired'
                                      : `Expires : ${expiredIn48Hours(date)}`}{' '}
                                  </Typography>
                                  •
                                </>
                              )}

                              <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                                {formatDate(date, DATE_FORMAT_PRIMARY)}
                              </Typography>
                            </Container>
                          </Container>
                          <Container className={classes.couterPrice}>
                            <Container flex justify="flex-start">
                              <Typography
                                variant="body1"
                                color={getColor('grey-g35')}
                                component="p"
                                className={classes.textWidth}
                              >
                                Counteroffer Price:
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')} component="p">
                                {formatPrice(data?.offer_price)}
                              </Typography>
                            </Container>
                            <Container flex justify="flex-start">
                              <Typography
                                variant="body1"
                                color={getColor('grey-g35')}
                                component="p"
                                className={classes.textWidth}
                              >
                                Notes:
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                {data?.note}
                              </Typography>
                            </Container>
                            {counterData?.length === 2 && expiredIn48Hours(date) === '' && (
                              <>
                                {!counterOffer && !data?.is_approved && (
                                  <Container className={classes.acptBtn}>
                                    <Button variant="secondary" border onClick={() => handleNudgeClick()}>
                                      Nudge
                                    </Button>
                                  </Container>
                                )}
                              </>
                            )}
                          </Container>
                        </Container>
                      </>
                    )}
                  </>
                ))}

                {counterData?.map((data) => (
                  <>
                    {data?.pricing_count === '1' && data?.orders_id && supplierReqDetails?.status === 'closed' && (
                      <>
                        <Seperator />
                        <Container className={classes.supplierAccpeptOffer}>
                          <Container flex justify="space-between">
                            <Container flex gap="0 5px">
                              <Done />
                              <Typography variant="title" color="#13141B">
                                Congratulations - Order created {orderDetailsData?.order_id}
                              </Typography>
                            </Container>
                            <Container flex gap={'0 5px'}>
                              <Typography variant="paragraph1" color="#0DA50D">
                                Order Placed
                              </Typography>
                              <Typography color="#4A4D68">•</Typography>
                              <Typography variant="paragraph1" color="#4A4D68">
                                {formatDate(date, DATE_FORMAT_PRIMARY)}
                              </Typography>
                            </Container>
                          </Container>
                          <Container className={classes.finalPrice}>
                            <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                              Final Price:
                            </Typography>
                            <Container>
                              <Typography variant="body1" color={getColor('grey-g35')} component={'h5'}>
                                {formatPrice(data?.offer_price)}
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                The buyer has paid for this order and you are now ready to ship this product.
                              </Typography>
                            </Container>
                          </Container>
                          <Container flex justify="flex-start" gap={'0 10px'}>
                            <Button
                              onClick={() => {
                                navigate(`/orders/AwaitingShipment/${orderDetailsData?.order_id}/${data?.orders_id}`, {
                                  state: { upatetraking: true },
                                })
                              }}
                            >
                              Update Tracking
                            </Button>
                            <Button variant="secondary" border onClick={() => handleOrderClick(data?.orders_id)}>
                              View Order
                            </Button>
                          </Container>
                        </Container>
                      </>
                    )}
                  </>
                ))}
                {counterData?.map((data) => (
                  <>
                    {data?.is_approved && data?.pricing_count === '1' && supplierReqDetails?.status !== 'closed' && (
                      <>
                        <Seperator />
                        <Container className={classes.supplierAccpeptOffer}>
                          <Container flex justify="space-between">
                            <Container flex gap="0 5px">
                              <Done />
                              <Typography variant="title" color="#13141B">
                                Congratulations - you've locked in the order!
                              </Typography>
                            </Container>
                            <Container flex gap="10px">
                              <Typography variant="paragraph1" color="#0DA50D">
                                Offer Approved
                              </Typography>
                              <Typography color="#AD1F36" variant="paragraph1">
                                {expiredIn48Hours(date) === ''
                                  ? 'Offer Expired'
                                  : `Expires : ${expiredIn48Hours(date)}`}{' '}
                              </Typography>
                              •
                              <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                                {formatDate(date, DATE_FORMAT_PRIMARY)}
                              </Typography>
                            </Container>
                          </Container>
                          <Container className={classes.finalPrice}>
                            <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                              Final Price:
                            </Typography>
                            <Container>
                              <Typography variant="body1" color={getColor('grey-g35')} component={'h5'}>
                                {formatPrice(data?.offer_price)}
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                You’ll receive an order confirmation email when the buyer has paid{' '}
                              </Typography>
                            </Container>
                          </Container>
                        </Container>
                      </>
                    )}
                  </>
                ))}
              </Container>
            </Container>
          )}
        </>
      )}
      <ConfirmationDialog
        onClose={setConfirmatioDialog}
        open={confirmationDialog}
        currectPrice={currectPrice}
        counterPrice={offerPrice}
        counterId={counterId}
        mutate={mutate}
      />
    </>
  )
}
CounterOfferCard.propTypes = {
  setOfferCount: PropTypes.func,
}
