import { AuthContext } from 'utils/provider/AuthContext'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import AppPage from 'containers/AppPage'

export default function CommonAuthRoute({ children }) {
  const { authState } = useContext(AuthContext)
  const isAuthorized = authState?.isAuthenticated || false
  const location = useLocation()

  if (!isAuthorized) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/home" />
  }

  if (
    location?.pathname.includes('quotes/supplierdetail') ||
    location?.pathname.includes('sa/addsupplier') ||
    location?.pathname.includes('sa/editsupplier') ||
    location?.pathname.includes('upgradeaccount')
  ) {
    return children
  }

  // authorized so return child components
  return (
    <AppPage appBar appDrawer>
      {children}
    </AppPage>
  )
}
CommonAuthRoute.propTypes = {
  children: PropTypes.node,
}
