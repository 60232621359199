import Container from 'components/common/Container'
import { React, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { Controller, useForm } from 'react-hook-form'
import RadioGroup from 'components/common/radio/RadioGroup'
import Radio from 'components/common/radio/Radio'
import TextArea from 'components/common/text/TextArea'
import useOrders from 'common-hooks/orders/useOrders'
import { getColor } from 'theme/colors'
import { useNavigate, useParams } from 'react-router-dom'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  feedback: {
    padding: '10px 0px 20px 0px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    paddingBottom: '10px',
  },
  cancelOrder: {
    background: '#AD1F36',
    width: '121px',
  },
})

export default function OrderCancelDialog({ open, onClose }) {
  const { handleSubmit, register, setValue, reset, control } = useForm({
    defaultValues: {
      noteValue: '',
    },
  })

  const params = useParams()

  const navigate = useNavigate()
  const { toastMessage } = useToastify()
  const { cancelOrder } = useOrders()
  const [radioValue, setRadioValue] = useState('Other')

  const handleOnradio = (e) => {
    setRadioValue(e.target.value)
  }
  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    if (radioValue === 'Other' && data?.noteValue === '') {
      toastMessage('error', 'Notes is required')
      return 0
    }
    cancelOrder(
      radioValue === 'Other' && data?.noteValue !== '' ? { reason: data?.noteValue } : { reason: radioValue },
      params.id
    )
    navigate('/orders')
    reset({
      Invalidshippingaddress: '',
      outOfStock: '',
      pricingerror: '',
      noteValue: '',
    })
    setRadioValue('Other')
    onClose()
  }
  const classes = useStyles()
  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <form id={'resonForm'} onSubmit={handleSubmit(onSubmit)}>
            <DialogHead title={'Reason For Order Cancellation'} onClose={onClose} />
            <Container className={classes.dialogbody}>
              <Container className={classes.feedback}>
                <Typography variant="paragraph1" color={getColor('grey-g25')}>
                  Let the buyer know why you are cancelling the order:{' '}
                </Typography>
              </Container>
              <Container>
                <RadioGroup defaultValue={'Other'} onChange={handleOnradio}>
                  <Container className={classes.radioContainer}>
                    <Controller
                      name={'outOfStock'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Out of stock'}
                          name={name}
                          value={'Out of stock'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Out of stock')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'pricingerror'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Pricing error'}
                          name={name}
                          value={'Pricing error'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Pricing error')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'Invalidshippingaddress'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Invalid shipping address'}
                          value={'Invalid shipping address'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Invalid shipping address')
                            }
                          }}
                        />
                      )}
                    />

                    <Radio name={'Other'} label={'Other'} value={'Other'} />
                  </Container>
                </RadioGroup>
              </Container>
              {radioValue === 'Other' && (
                <Container>
                  <TextArea
                    id={'noteArea'}
                    placeholder={'Paragraph'}
                    name={'noteValue'}
                    rows={4}
                    {...register('noteValue')}
                  />
                </Container>
              )}
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button width={80} type="submit" className={classes.cancelOrder}>
                  Cancel Order
                </Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
OrderCancelDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  inviteId: PropTypes.string,
}
