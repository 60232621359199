import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    width: props.width || '100%',
    height: props.height || 'auto',
    // display: 'flex',
    borderTop: `1px solid ${getColor('grey-g95')}`,
    background: '#ffffff',
    padding: '17px 20px',
    alignItems: 'center',
    ...getRootStyle(props),
  }),
}))

const DialogFooter = forwardRef(({ children, justify }, ref) => {
  const classes = useStyles({ justify })

  return (
    <>
      <Container className={classes.root} ref={ref}>
        {children}
      </Container>
    </>
  )
})

export default DialogFooter

DialogFooter.propTypes = {
  children: PropTypes.node,
  justify: PropTypes.string,
}

function getRootStyle(props) {
  return {
    ...(props.justify === 'space-between' && {
      justifyContent: 'space-between',
      display: 'flex',
    }),
    ...(props.justify === 'center' && {
      justifyContent: 'center',
      display: 'flex',
    }),
    ...(props.justify === 'flex-end' && {
      justifyContent: 'flex-end',
      display: 'flex',
    }),
  }
}
