export function getSize() {
  return window.innerWidth > 1920 ? 42 : 42
}

export const supplierListLimit = 14
export const inventoryProductLimit = 18

// local storage keys

export const constants = {
  ENCRYPT_SECRET: 'PartsClub11112222',
  TOKEN: 'F=zP:LEw',
  ACTIVE_QUOTE_NAME: 'h$gQaF',
  USER: 'hVD$',
  ROLE: 'Dk%h',
  USER_ID: 'Oh=|',
  COMPANY_ID: '%eILJ(',
  CLIENT_SECRET: '|%%erG=',
  USER_ZONE: 'k#@1!',
  UNAUTHORIZEDPARTID: 'p#c1!d',
  UNAUTHORIZEDPARTNAME: 'n%6m3',
}
