import { counterFormData } from 'common-api/buyer-supplier/buyerSupplier'
import useToastify from 'common-hooks/useToastify'

export default function useCounterDetails() {
  const { toastMessage } = useToastify()
  const counterDailog = async (data, mutate) => {
    try {
      const response = await counterFormData(data)
      mutate()
      toastMessage('success', 'Counter Data Submitted')
      return response
    } catch {
      return false
    }
  }
  return { counterDailog }
}
