/* eslint-disable camelcase */
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import * as yup from 'yup'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import GooglePlaces from 'components/custom/GooglePlaces'
import { yupResolver } from '@hookform/resolvers/yup'
import { getFormError } from 'utils/form/form'
import Select from 'components/common/select/Select'
import MonthYearPicker from 'components/common/MonthYearPicker'
import TextField from 'components/common/text/TextField'
import typography from 'theme/typography'
import { useEffect } from 'react'
import { createOption } from 'components/common/text/DropDownTextField'
import { getWellKnownAddress } from 'utils/Utils'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: '690px',
    overflowY: 'scroll',
  },
  group: {
    display: 'flex',
    gap: '0 16px',
  },
  radiogrp: {
    display: 'flex',
    gap: '0 26px',
    height: '20px',
    // flexDirection: 'row',
  },
  pb10: {
    paddingBottom: '10px',
  },
  billing: {
    fontWeight: '700',
    color: getColor('grey-g25'),
    paddingTop: '5px',
  },
  bilingAddrres: {
    height: '20px',
  },
  labelTypography: {
    fontSize: typography.paragraph2.fontSize,
    fontWeight: typography.paragraph2.fontWeight,
  },
})
const schema = yup.object({
  school: yup.string().required('school name is required.'),
  field_study: yup.string().required('Study  is required.'),
})
export default function EducationDialog({ open, onclose, setEducations, editEducationsData, setEditEducationsData }) {
  const classes = useStyles()
  const { toastMessage } = useToastify()
  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      start_date: new Date(),
      end_date: new Date(),
    },
  })
  const [startDate] = useWatch({
    control,
    name: ['start_date'],
  })

  const options = [
    { value: 'High School Diploma (GED)', label: 'High School Diploma (GED)' },
    { value: 'Associate Degree', label: 'Associate Degree' },
    { value: 'Bachelor’s Degree', label: 'Bachelor’s Degree' },
    { value: 'Master’s Degree', label: 'Master’s Degree' },
    { value: 'Doctorate Degree', label: 'Doctorate Degree' },
  ]

  const setAddressValues = (addr, addr_line_1, addr_line_2) => {
    const loc = formatAddressOfGAPI(addr, addr_line_1, addr_line_2, null)
    setValue('addr_line_1', addr_line_1)
    setValue('addr_line_2', addr_line_2)
    setValue('city', loc.city)
    setValue('state', loc.state)
    // setValue('country', loc.country)
    // setValue('zipcode', loc.zipcode)
    setValue('city_abbrv', loc.city_abbrv)
    setValue('state_abbrv', loc.state_abbrv)
    setValue('country_abbrv', loc.country_abbrv)
  }

  const handleOnClose = () => {
    reset({
      school: '',
      degree: '',
      field_study: '',
      location: '',
      start_date: new Date(),
      end_date: new Date(),
    })
    setEditEducationsData(null)
    onclose()
  }

  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    if (data?.degree === undefined || data?.degree === '' || data?.degree?.label === '') {
      toastMessage('error', 'Degree is required')
      return 0
    }
    if (data?.addr_line_1 === undefined || data?.addr_line_1 === '') {
      toastMessage('error', 'Location is required')
      return 0
    }
    delete data?.location
    delete data?.currently_working
    data.degree = data.degree?.label
    setEducations((pre) => {
      const newArray = pre.filter((obj) => obj.id !== data.id)
      return [data, ...newArray]
    })

    handleOnClose()
  }

  useEffect(() => {
    if (editEducationsData) {
      Object.keys(editEducationsData).map((key) => {
        if (key === 'degree') {
          setValue(key, createOption(editEducationsData[key], editEducationsData[key]))
        } else {
          setValue(key, editEducationsData[key])
        }
        return 0
      })
    } else {
      reset()
    }
  }, [editEducationsData, setValue, reset])

  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Education'} onClose={handleOnClose} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.dialogbody} direction={'column'} gap={'10px 0'}>
            <Controller
              name={'school'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  label={'School*'}
                  name={name}
                  placeholder={'School'}
                  type="text"
                  value={value}
                  onChange={onChange}
                  error={getFormError(errors, name)}
                  className={classes.nameFiled}
                />
              )}
            />
            <Controller
              name={'degree'}
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <Select
                  id="degree"
                  label="Degree"
                  options={options}
                  name={name}
                  value={value}
                  placeholder={'Select Degree'}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
            <Controller
              name={'field_study'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  label={'Field of study*'}
                  name={name}
                  placeholder={'Field of study'}
                  type="text"
                  value={value}
                  onChange={onChange}
                  error={getFormError(errors, name)}
                  className={classes.nameFiled}
                />
              )}
            />
            {/* <Controller
              name={'location'}
              control={control}
              render={({ field: { name, value } }) => (
                <GooglePlaces
                  id={name}
                  name={name}
                  label="Location*"
                  spacing={3}
                  placeholder={'e.g. 4517 Washington Ave'}
                  value={value || getWellKnownAddress(getValues()?.addr_line_1, getValues()?.addr_line_2)}
                  onSelect={(loc) => {
                    setAddressValues(loc.addr, loc.addr_line_1, loc.addr_line_2)
                  }}
                />
              )}
            /> */}

            <Controller
              name={'location'}
              control={control}
              render={({ field: { name, value } }) => (
                <GooglePlaces
                  id={name}
                  name={name}
                  label="Location"
                  spacing={3}
                  onClearInput={() => setValue('addr_line_1', '')}
                  placeholder={'e.g. 4517 Washington Ave'}
                  value={
                    value ||
                    (getValues()?.addr_line_1 &&
                      getValues()?.addr_line_2 &&
                      getWellKnownAddress(getValues()?.addr_line_1, getValues()?.addr_line_2))
                  }
                  onSelect={(loc) => {
                    setAddressValues(loc.addr, loc.addr_line_1, loc.addr_line_2)
                  }}
                />
              )}
            />

            <Container flex gap={'20px'}>
              <Controller
                name="start_date"
                control={control}
                render={({ field: { name, value } }) => (
                  <MonthYearPicker
                    views={['year', 'month']}
                    label="Start Date*"
                    // minDate={new Date('2012-03-01')}
                    maxDate={new Date()}
                    value={value}
                    onChange={(newValue) => {
                      setValue(name, newValue)
                    }}
                  />
                )}
              />
              <Controller
                name="end_date"
                control={control}
                render={({ field: { name, value } }) => (
                  <MonthYearPicker
                    views={['year', 'month']}
                    label="End Date (or expected)*"
                    minDate={getValues().start_date || startDate}
                    maxDate={new Date()}
                    value={value}
                    onChange={(newValue) => {
                      setValue(name, newValue)
                    }}
                  />
                )}
              />
            </Container>
          </Container>

          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button type="submit" width={70}>
                Add
              </Button>
            </Container>
          </DialogFooter>
        </form>
      </Dialog>
    </Container>
  )
}
EducationDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  setEducations: PropTypes.func,
  editEducationsData: PropTypes.object,
  setEditEducationsData: PropTypes.func,
}
