/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import Container from 'components/common/Container'
import SearchField from 'components/common/search/SearchField'
import Button from 'components/common/Button'
import { makeStyles } from '@mui/styles'
import {
  AddIcon,
  CaretLeftIcon,
  CaretRightIcon,
  DropdownBigIcon,
  EditIcon,
  RedDeleteIcon,
  SortArrowIcon,
} from 'assets/icons'
import { Table } from 'components/common/Table'
import { getColor } from 'theme/colors'
import IconButton from 'components/common/IconButton'
import inventoryEmptyState from 'assets/images/inventoryEmptyState.svg'
import correctionIcon from 'assets/images/correctionIcon.svg'
import Chip from 'components/common/Chip'
import ContextMenu from 'components/custom/drop-downs/ContextMenu'
import ContextMenuItem from 'components/custom/drop-downs/ContextMenuItem'
import Image from 'components/common/Image'
import useToggle from 'common-hooks/useToggle'
import NotesDialog from './NotesDialog'
import Typography from 'components/common/Typography'
import Tooltip from 'components/common/Tooltip'
import useSuperAdminSupportList from 'common-hooks/super-admin/useSuperAdminSupportList'
import { DATE_FORMAT_COMMA_SUPER, formatDate } from 'utils/dateUtils'
import LoadingOverlay from 'components/common/LoadingOverlay'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    // padding: '30px',
  },
  marginHead: {
    position: 'sticky',
    justifyContent: 'flex-end',
    top: '60px',
    // background: getColor('system-bg'),
    zIndex: '1',
  },
  mainContainer: {
    background: '#fff',
    padding: '30px',
    // '& svg': {
    //   '& path': {
    //     fill: '#4A4D68',
    //   },
    // },
  },
  SearchField: {
    '& input': {
      background: '#F8F9F9',
    },
  },
  list: {
    marginTop: '20px',
    maxHeight: '75vh',
    overflowY: 'scroll',

    whiteSpace: ' nowrap',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      paddingtop: '50px',
      backgroundColor: '#F0F0F4',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
    '& table': {
      overflowX: 'hidden',
      // width: '1920px',
      // overflowX: 'scroll',
      '& tr': {
        cursor: 'pointer',
      },
    },
  },
  pagination: {
    borderTop: '1px solid #F0F0F4 ',
    padding: '30px',
    display: 'flex',
    background: '#fff',
    justifyContent: 'space-between',
  },
  paginationFooter: {
    position: 'sticky',
    bottom: '0px',
    height: 'unset',
    paddingTop: '30px',
    // marginLeft: '-20px',
    // marginRight: '-20px',
    padding: '0 0',
    zIndex: '1',
    borderTop: `1px solid ${getColor('grey-g95')}`,
    background: getColor('light-main'),
    '& svg path': {
      fill: 'unset',
    },
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  customTableContainer: {
    overflowX: 'initial',
  },
  placeholder: {
    // position: 'absolute',
    display: 'flex',
    height: '72vh',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px 0',
  },
  iconBtn: {
    background: 'unset',
    border: ' 1px solid #9799B5',
    borderRadius: ' 4px',
    width: ' 36px',
    height: '36px',
    '&:hover': {
      background: 'unset',
    },
  },
  btn: {
    width: '100%',
    justifyContent: ' space-between',
    padding: 'unset',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  addBtn: {
    background: 'unset',
    color: '#2680D9',
    padding: 'unset',
    height: 'unset',
    minHeight: 'unset',
    '& svg path': {
      fill: '#2680D9',
      stroke: '#2680D9',
    },
    '&:hover': {
      background: 'unset',
    },
  },
  noteRoot: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    '& svg path': {
      fill: 'unset',
    },
    '& .hoverIcon': {
      display: 'none',
      gap: '0 10px',
      position: 'absolute',
      background: '#F8F9F9',
      right: '5px',
      '& .btn': {
        background: 'unset',
        padding: 'unset',
        '&:hover': {
          background: 'unset',
        },
      },
    },
    '&:hover .hoverIcon': {
      display: 'flex',
    },
  },
  resonRoot: {
    '& div': {
      display: 'flex',
    },
    '& p': {
      margin: '0',
      display: 'inline-block',
    },
  },
  sortBtn: {
    padding: '0',
    width: 'unset',
    height: 'unset',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  rootBg: {
    background: '#fff',
  },
}))

export default function Support() {
  const classes = useStyles()
  const [activeRows, setActiveRows] = useState([])
  const [notesDialog, setNotesDialog] = useToggle(false)
  const [orderBy, setOrderBy] = useState()

  const { supportList, isLoading, setPage, page, supportUpdate, search, onSearch } = useSuperAdminSupportList(orderBy)
  const [editnoteData, setEditNoteData] = useState()
  const [noteId, setNoteId] = useState()

  function createData(status, Date, Account, Section, User, Subject, Message, Notes) {
    return { status, Date, Account, Section, User, Subject, Message, Notes }
  }

  function statusProduct(status) {
    if (status === 'not set') return 'Not Set'
    if (status === 'In Progress') return 'In Progress'
    if (status === 'Completed') return 'Completed'
    return 'Not Set'
  }

  useEffect(() => {
    if (supportList !== undefined) {
      setActiveRows(
        supportList?.map((obj) =>
          createData(
            <ContextMenu
              width={'205px'}
              closeOnClick
              trigger={
                <IconButton className={classes.btn}>
                  <Chip
                    label={statusProduct(obj?.status)}
                    // eslint-disable-next-line no-nested-ternary
                    variant={
                      // eslint-disable-next-line no-nested-ternary
                      statusProduct(obj?.status) === 'In Progress'
                        ? 'secondary'
                        : statusProduct(obj?.status) === 'Completed'
                        ? 'success'
                        : ''
                    }
                  />
                  <DropdownBigIcon />
                </IconButton>
              }
            >
              <ContextMenuItem onClick={() => supportUpdate({ id: obj?.id, status: 'Not Set' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Not Set'} />
                  {statusProduct(obj?.status) === 'Not Set' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => supportUpdate({ id: obj?.id, status: 'In Progress' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'In Progress'} variant={'secondary'} />
                  {statusProduct(obj?.status) === 'In Progress' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => supportUpdate({ id: obj?.id, status: 'Completed' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Completed'} variant={'success'} />
                  {statusProduct(obj?.status) === 'Completed' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
            </ContextMenu>,

            obj?.created_at ? formatDate(obj?.created_at, DATE_FORMAT_COMMA_SUPER) : '-',
            obj?.user_type === 'customer' ? 'Buyer' : 'Seller',
            obj?.section ? <Chip label={obj?.section} /> : '-',
            <div>
              {obj?.user_name ? obj?.user_name : '-'}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.user_email ? obj?.user_email : '-'}
              </Typography>
            </div>,
            obj?.subject ? obj?.subject : '-',

            <div className={classes.resonRoot}>
              {obj?.message?.length > 40 ? (
                <div>
                  {obj?.message.slice(0, 40)}...
                  <Tooltip title={obj?.message}>
                    <p> View reason</p>
                  </Tooltip>
                </div>
              ) : (
                obj?.message
              )}
            </div>,
            obj?.notes ? (
              <Container className={classes.noteRoot}>
                <div className={classes.resonRoot}>
                  {obj?.notes?.length > 40 ? <div>{obj?.notes?.slice(0, 40)}...</div> : obj?.notes}
                </div>
                <Container className={'hoverIcon'}>
                  <IconButton
                    className={'btn'}
                    onClick={() => {
                      setNotesDialog()
                      setEditNoteData(obj?.notes)
                      setNoteId(obj?.id)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton className={'btn'} onClick={() => supportUpdate({ id: obj?.id, notes: '' })}>
                    <RedDeleteIcon />
                  </IconButton>
                </Container>
              </Container>
            ) : (
              <Button
                onClick={() => {
                  setNotesDialog()
                  setEditNoteData('')
                  setNoteId(obj?.id)
                }}
                className={classes.addBtn}
                icon={<AddIcon />}
              >
                Add
              </Button>
            )
          )
        )
      )
    }
  }, [supportList])

  const allProductcols = [
    <Container width={'100%'} flex justify="space-between">
      Status{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Date{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'created_at', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'created_at', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Account{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'user_type', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'user_type', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Section{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'section', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'section', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      User{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'user_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'user_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Subject{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'subject', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'subject', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Message{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'message', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'message', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Notes{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
  ]

  return (
    <Container height={'93vh'} className={classes.rootBg}>
      <Container className={classes.mainContainer} width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            {/* <Tabs value={tabValue} onchange={handleChange} array={tabs} /> */}
            <Container flex gap={'20px'}>
              <SearchField
                id={'searchField'}
                width={'334px'}
                className={classes.SearchField}
                onSearch={(e) => {
                  onSearch(e)
                }}
                value={search}
              />
            </Container>
          </Container>
        </Container>

        <Container className={classes.list}>
          <Container>
            {isLoading && <LoadingOverlay />}
            {supportList?.length > 0 ? (
              <Table stickyHeader cols={allProductcols} rows={activeRows} />
            ) : (
              <Container className={classes.placeholder}>
                <Container>
                  <Image src={inventoryEmptyState} />
                </Container>
                <Container flex direction="column" gap="20px 0">
                  {search === '' ? (
                    <Container flex direction="column">
                      <Typography variant="title" color={getColor('grey-g25')} component="p">
                        No support request
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                        Looks like everything working fine for all users
                      </Typography>
                    </Container>
                  ) : (
                    <Container flex direction="column">
                      <Typography variant="title" color={getColor('grey-g25')} component="p">
                        No result found
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                        Looks like there are no support requestr with this value
                      </Typography>
                    </Container>
                  )}
                </Container>
              </Container>
            )}
          </Container>
        </Container>
        <Container flex justify={'space-between'} className={classes.paginationFooter}>
          <Button
            icon={<CaretLeftIcon />}
            variant={'transparent'}
            className={classes.prevBtn}
            onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
            disabled={page === 1}
          >
            Prev.
          </Button>
          <Button
            icon={<CaretRightIcon />}
            variant={'transparent'}
            className={classes.nextBtn}
            onClick={() => setPage((pre) => pre + 1)}
            disabled={supportList?.length < 35}
          >
            Next
          </Button>
        </Container>
      </Container>
      <NotesDialog
        onClose={setNotesDialog}
        open={notesDialog}
        productUpdate={supportUpdate}
        editnoteData={editnoteData}
        noteId={noteId}
        setEditNoteData={setEditNoteData}
      />
    </Container>
  )
}
