/* eslint-disable camelcase */
import { createContext } from 'react'

export const SettingsContext = createContext({
  setSettingsData: () => {},
  settingsData: {},
})

export function addProfileParseData(data) {
  console.log(data)
  const phoneNumber = `+${data?.prefix}-${data?.phone_number}`.toString()
  const linkdlnUrl = data?.linkedin_url ? `https://${data?.linkedin_url}` : ''
  try {
    const res = {}

    Object.keys(data).map((key) => {
      // data[key]
      if (
        data[key] &&
        ![
          'email',
          'is_company',
          'logo',
          'img_header',
          'user_eds',
          'user_expc',
          'user_hobbies',
          'prefix',
          'user_address',
          'user_plan',
          'user_company',
        ].includes(key)
      ) {
        if (key === 'phone_number') {
          res.phone_number = phoneNumber
          return res
        }
        if (key === 'linkedin_url') {
          res.linkedin_url = linkdlnUrl
          return res
        }
        if (key === 'eds') {
          const eds = data[key]?.map((obj) => {
            delete obj.id
            return obj
          })
          res[key] = eds
        } else if (key === 'expc') {
          const expc = data[key]?.map((obj) => {
            delete obj.id
            delete obj?.location
            delete obj?.company_profile

            obj.company_id = obj?.company_name?.value || obj?.company_id
            obj.company_name = obj?.company_name?.label || obj.company_name
            obj.currently_working = obj.currently_working ? obj.currently_working.toString() : 'false'
            if (obj?.company_name === obj?.company_id || obj?.company_id === null) {
              delete obj?.company_id
            }
            return obj
          })
          res[key] = expc
        } else res[key] = data[key]
      }
      return 0
    })

    return res?.linkedin_url
      ? JSON.stringify(JSON.stringify(res))
      : JSON.stringify(JSON.stringify({ ...res, linkedin_url: '' }))
  } catch (e) {
    console.log(e)
    return 0
  }
  // const filterexp = data?.expc.map((object) => {
  //   if (object.currently_working === true) {
  //     return { ...object, currently_working: 'true' }
  //   }
  //   if (object.currently_working === false) {
  //     return { ...object, currently_working: 'false' }
  //   }
  //   return object
  // })
  // const filtereds = data?.expc.map((object) => {
  //   if (object.currently_working === true) {
  //     return { ...object, currently_working: 'true' }
  //   }
  //   if (object.currently_working === false) {
  //     return { ...object, currently_working: 'false' }
  //   }
  //   return object
  // })

  // const parsedData = {
  //   ...(data?.id && { id: data?.id }),
  //   ...(data?.about && { about: data?.id }),
  //   ...(data?.eds && { eds: filtereds }),
  //   ...(data?.expc && { expc: filterexp }),
  //   ...(data?.hobbies && { hobbies: data?.hobbies }),
  //   ...(data?.linkedin_url && { linkedin_url: `https://${data?.linkedin_url}` }),
  //   ...(data?.first_name && { first_name: data?.first_name }),
  //   ...(data?.last_name && { last_name: data?.last_name }),
  // }

  // return JSON.stringify(JSON.stringify(res))
  // return 0
}
