import React, { useEffect, useState } from 'react'
import Container from 'components/common/Container'
import './css/landingpage.css'
import './css/responsive.css'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import { makeStyles } from '@mui/styles'
import CreateRequest from 'assets/images/CreateRequest.svg'
import QuoteList from 'assets/images/QuoteList.svg'
import BuyNow from 'assets/images/BuyNow.svg'
import MatchingRequest from 'assets/images/MatchingRequest.jpg'
import QuoteBuyer from 'assets/images/QuoteBuyer.jpg'
import stillQuestion from 'assets/images/stillQuestion.svg'
import Receiveorder from 'assets/images/Receiveorder.jpg'
import RecieveOfferMobile from 'assets/images/RecieveOfferMobile.png'
import CheckoutMobile from 'assets/images/CheckoutMobile.png'
import CreaterqMobile from 'assets/images/CreaterqMobile.png'
import { useNavigate } from 'react-router-dom'
import verfiedBigIcon from 'assets/images/verfiedBigIcon.svg'
// import AisReplacement from 'assets/images/AisReplacement.svg'
import ReliableAftermarket from 'assets/images/ReliableAftermarket.svg'
import AmsConstruction from 'assets/images/AmsConstruction.svg'
import BedrockMachinery from 'assets/images/BedrockMachinery.svg'
import ConEquip from 'assets/images/ConEquip.svg'
import EasternLiftTruck from 'assets/images/EasternLiftTruck.svg'
import EquipmentShare from 'assets/images/EquipmentShare.svg'
// import LandingPageBanner from 'assets/images/LandingPageBanner.png'
import LandingpageNewBanner from 'assets/images/LandingpageNewBanner.jpg'
// import LandingBannerMobile from 'assets/images/LandingBannerMobile.png'
import NashLift from 'assets/images/NashLift.svg'
import RomacParts from 'assets/images/RomacParts.svg'
import ContstructionMining from 'assets/images/ContstructionMining.svg'
import ConstuctionMobile from 'assets/images/ConstuctionMobile.png'
import Industrial from 'assets/images/Industrial.svg'
import IndustrialMobile from 'assets/images/IndustrialMobile.png'
import Agriculture from 'assets/images/Agriculture.svg'
import AgricultureMobile from 'assets/images/AgricultureMobile.png'
import Button from 'components/common/Button'
// import Constructionbrand from 'assets/images/Constructionbrand.png'
// import brandamachine from 'assets/images/brandamachine.png'
import classNames from 'classnames'
import Accordion from '@mui/material/Accordion'
import EveryOrder from 'assets/images/EveryOrder.jpg'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { CaretDownIcon } from 'assets/icons'
// import Link from 'components/common/Link'
import LoginDialog from 'containers/authentication/LoginDialog'
import HtmlTitle from 'utils/HtmlTitle'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { constants } from 'utils/constants'
import { getFlag, getFromLocalStorage, getShortAddress } from 'utils/Utils'
import useDummyQuotes from 'common-hooks/landing-page/useDummyQuotes'
import useIndustryList from 'common-hooks/useIndustryList'
import { Tab, Tabs as MuiTab } from '@mui/material'
// import useLandingpageQuoteReqList from 'common-hooks/buyer-supplier-list/useLandingpageQuoteReqList'
import { Tabs } from 'components/common/Tabs'
import LandingPageHeader from './LandingPageHeader'
import { Brands, Testinomial } from './LandingPageUtils'
import LandingPageFooter from './LandingPageFooter'
import BrandsSlider from './BrandsSlider'
import TestinomialSlider from './TestinomialSlider'
import useProductList from 'common-hooks/inventory/useProductList'
import Image from 'components/common/Image'
import ProductSearch from 'components/custom/ProductSearch'
import { BuyerQuoteContext } from 'containers/buyer-quotes/BuyerQuotesUtils'
import CreateQuoteDialog from 'containers/buyer-quotes/CreateQuoteDialog'
import useToggle from 'common-hooks/useToggle'

const useStyles = makeStyles({
  landingTabs: {
    '& .MuiTabs-indicator': { backgroundColor: getColor('orange-main') },
    '& .MuiTab-root': { color: getColor('grey-g65') },
    '& .Mui-selected': { color: getColor('grey-g25') },

    fontFamily: 'Lexend Deca',
    fontStyle: ' normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    minHeight: 'unset',
    '& div': {
      justifyContent: 'center',
    },
    '& button': {
      padding: '0',
      fontFamily: 'Lexend Deca',
      fontStyle: ' normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      width: '190px',
      height: '50px',
      borderBottom: '1px solid #f0f0f4',

      borderBottomWidth: '2px',
      // paddingBottom: '13px',

      minHeight: 'unset',
      minWidth: 'auto',
    },
  },
  btmLandingTabs: {
    '& button': {
      width: '226px',
    },
  },
  everyOrderBtn: {
    border: '1px solid #9799B5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: getColor('grey-g35'),
    width: '240px',
    height: '50px',
    borderRadius: '4px',
  },
  blueLinks: {
    background: 'transparent',
    height: 'unset',
    width: 'unset',
    padding: 'unset',
    minHeight: 'unset',
    color: getColor('func-info'),
    borderBottom: '1px solid',
    borderRadius: 'unset',
    lineHeight: '1',
    zIndex: 99999,
    '& div': {
      '& span': {
        lineHeight: 'unset',
      },
    },
    borderColor: getColor('func-info'),
    '&:hover': {
      background: 'transparent',
    },
  },
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '15%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
})

const LandingPage = () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  // const [tab, setRequestvaluee] = useState(0)
  const { industryData } = useIndustryList()
  const [formStep, setFormStep] = useState(0)
  const [quoteTab, setQuoteTab] = useState()
  const [openQuoteDialog, setOpenQuoteDialog] = useToggle(false)

  if (industryData && industryData.length > 3) {
    industryData.length = 3
  }
  const [tabId, setTabId] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [tab, setTab] = useState(0)
  useEffect(() => {
    if (industryData) {
      setTabId(industryData[0].id)
    }
  }, [industryData])
  const handleTabChange = (event, newValue) => {
    setValue('equipmentType', event.target.name)
    setTab(newValue)
    switch (newValue) {
      case 0:
        setTabId(industryData[0].id)
        break
      case 1:
        setTabId(industryData[1].id)
        break
      case 2:
        setTabId(industryData[2].id)
        break
      default:
        setTabId(industryData[0].id)
        break
    }
  }

  const handleQuoteOpen = (e, state) => {
    setQuoteTab(state)
    setOpenQuoteDialog(true)
    e.stopPropagation()
  }
  const isAuthorised = !!getFromLocalStorage(constants.TOKEN)

  const getSerialNumber = () => (isAuthorised ? filtered?.quote_brand_serial_number || '-' : 'Unlock Serial Number')

  const getBuyerDetails = () => (isAuthorised ? filtered?.buyer_full_name || '-' : 'Unlock Buyer Details')

  const { reqQuoteData, isLoadingReq } = useDummyQuotes(tabId)
  const filtered = reqQuoteData ? reqQuoteData.filter((item) => Object.values(item).join('').toLowerCase()) : []
  const { productData, search, onSearch } = useProductList('customer')
  const [selectedParts, setSelectedParts] = useState([])
  const [quoteData, setQuoteData] = useState([])
  const [partsSelectionTreeView, setPartsTreeview] = useState()

  const contextValue = {
    onSaveBuyerQuoteInformaton: setQuoteData,
    BuyerQueoteInformation: quoteData,
    visibleTextBoxData: selectedParts,
    setTextBoxVisibilityData: setSelectedParts,
    setPartsTreeViewData: setPartsTreeview,
    partsTreeViewData: partsSelectionTreeView,
  }
  const navigate = useNavigate()
  const handleClick = () => {
    setFormStep(0)
    setQuoteData([])
    setOpenDialog(true)
  }

  return (
    <Container className="landing-page-main">
      <HtmlTitle title={'Home'} />
      <Container className="container">
        <LandingPageHeader />
      </Container>
      <Container className="banner-section">
        <Container className="banner-img">
          <img src={LandingpageNewBanner} alt="LandingPageBanner" className="banner-desktop" />
          {/* <img src={LandingBannerMobile} alt="LandingPageBanner" className="banner-mobile" />
           */}
          <img src={LandingpageNewBanner} alt="LandingPageBanner" className="banner-mobile" />
        </Container>
        <Container className="banner-img-content">
          <Container>
            <Typography variant="body2" color={getColor('light-main')}>
              Buy Parts
            </Typography>
            <Typography variant="body2" color={getColor('light-main')} className="dot">
              {' '}
              •{' '}
            </Typography>
            <Typography variant="body2" color={getColor('light-main')}>
              Sell Parts
            </Typography>
            <Typography variant="body2" color={getColor('light-main')} className="dot">
              {' '}
              •{' '}
            </Typography>
            <Typography variant="body2" color={getColor('light-main')}>
              Fix Equipment
            </Typography>
          </Container>
          <Typography component="h1">#1 Marketplace for Heavy-Duty Parts</Typography>
          <Typography component="p">
            Get great deals on heavy-duty parts from over 500+ verified OEM & aftermarket suppliers
          </Typography>
          <Container className="searchfield-root">
            <ProductSearch
              placeholder="Search by part number, brand, description"
              value={search}
              onSearch={(e) => {
                onSearch(e)
              }}
              onSearchClick={() => {
                if (search !== '' && search !== undefined) {
                  navigate('/parts', { state: { searchValue: search } })
                }
              }}
            />

            {search !== '' && productData?.length > 0 && (
              <Container className={'searchResultDataRooot'}>
                <Container className={'searchResultData'}>
                  {productData?.map((obj, index) => {
                    const productName = `${obj?.brand_name} - ${obj?.part_desp} - ${obj?.part_no}`
                      .replace('null', '')
                      .replace('undefined', '')
                    return (
                      <Container
                        key={index}
                        className={'p-cardRoot'}
                        onClick={() => navigate(`/parts/${productName}/${obj?.id}`)}
                      >
                        <Container className={'p-cardLeft'}>
                          <Image src={obj?.brand_cover} />
                        </Container>
                        <Container className={'p-cardRight'}>
                          <Container className={'p-card-subflex'}>
                            <Typography variant="body1" color={getColor('grey-g25')} component="h5">
                              {obj?.brand_name} - {obj?.part_desp} - {obj?.part_no}
                            </Typography>
                            <Typography variant="body1" color={getColor('grey-g35')} component="h5">
                              ${obj?.unit_price}
                            </Typography>
                          </Container>
                          <Container className={'p-card-subflex'}>
                            <Typography variant="paragraph1" color={getColor('grey-g65')}>
                              {obj?.company_name}
                            </Typography>
                            <Typography variant="paragraph1" color={'#0DA50D'}>
                              {obj?.stock ? 'In Stock' : 'Out Of Stock'}
                            </Typography>
                          </Container>
                          <Container flex justify="space-between">
                            <Typography variant="paragraph2" color={getColor('grey-g65')}>
                              {obj?.company_city}, {obj?.company_state_abbrv}
                            </Typography>
                          </Container>
                        </Container>
                      </Container>
                    )
                  })}
                </Container>
              </Container>
            )}
            {search !== '' && productData?.length <= 0 && (
              <Container className={'searchResultDataRooot'}>
                <Container className="empty-search">
                  <Typography component="p">
                    No search results -{' '}
                    <Container className="free-quote-link" onClick={setOpenQuoteDialog}>
                      Start a free quote request now
                    </Container>
                  </Typography>
                </Container>
              </Container>
            )}
            {/* <ProductSearch
              placeholder="Search by part number, brand, description"
              options={searchOpt}
              value={search}
              onInputChange={(e) => {
                onSearch(e)
              }}
            /> */}
          </Container>
        </Container>
      </Container>
      <Container className="brands-slider-section">
        <Container className="container">
          {/* <Typography className="brands-title" component={'p'}>
            Get parts quotes for all major equipment brand
          </Typography> */}
          <BrandsSlider sliderData={Brands} dot={false} />
        </Container>
      </Container>
      <Container className="industry-part p90">
        <Container className="industry-part-title">
          <Typography component="h2">Looking for a parts quote? Get fast & free quotes.</Typography>
          <Typography component="p">Compare quotes from verified parts suppliers</Typography>
        </Container>
        <Container className="industry-grid-inner">
          <Container className="industry-grid">
            <Container className="industry-grid-item">
              <Container className="industry-grid-img">
                <img src={Agriculture} alt="Agriculture" className="desktop-img" />
                <img src={AgricultureMobile} alt="Agriculture" className="mobile-img" />
              </Container>
              <Container className="industry-grid-img-con">
                <Typography variant="title" component={'p'} color={getColor('grey-g35')}>
                  Agricultural & Turf
                </Typography>
                <Button className="start-quote-btn" onClick={(e) => handleQuoteOpen(e, 'Agricultural & Turf')}>
                  Get Quotes
                </Button>
              </Container>
            </Container>
            <Container className="industry-grid-item">
              <Container className="industry-grid-img">
                <img src={ContstructionMining} alt="ContstructionMining" className="desktop-img" />
                <img src={ConstuctionMobile} alt="ContstructionMining" className="mobile-img" />
              </Container>
              <Container className="industry-grid-img-con">
                <Typography variant="title" component={'p'} color={getColor('grey-g35')}>
                  {' '}
                  Construction & Mining
                </Typography>
                <Button className="start-quote-btn" onClick={(e) => handleQuoteOpen(e, 'Construction & Mining')}>
                  Get Quotes
                </Button>
              </Container>
            </Container>
            <Container className="industry-grid-item">
              <Container className="industry-grid-img">
                <img src={Industrial} alt="Industrial" className="desktop-img" />
                <img src={IndustrialMobile} alt="Industrial" className="mobile-img" />
              </Container>
              <Container className="industry-grid-img-con">
                <Typography variant="title" component={'p'} color={getColor('grey-g35')}>
                  Industrial & Aerial
                </Typography>
                <Button className="start-quote-btn" onClick={(e) => handleQuoteOpen(e, 'Industrial & Aerial')}>
                  Get Quotes
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className="create-quote-request">
        <Container className="container">
          <Container className="create-quote-request-inner p90">
            <Container className="create-quote-request-grid">
              <Container className="create-quote-request-left">
                <Typography className="title " component="h1">
                  Get personalized quotes in minutes
                </Typography>
                <Typography component="p" variant="body1" color={getColor('grey-g35')}>
                  No more filling out multiple emails or parts requests forms to get pricing and availability.
                </Typography>
                <Container className="sign-seeAll-btn" flex gap="0 20px">
                  <Button variant="primary" className="startBtn" onClick={handleQuoteOpen}>
                    Start Quote
                  </Button>
                  <Button className="seeAllRqBtn" variant="transparent" onClick={() => navigate('/quoterequest')}>
                    See All Requests
                  </Button>
                </Container>
              </Container>
              <Container className="create-quote-request-right">
                <Container className="create-quote-request-right-tabs">
                  <Tabs
                    value={tab}
                    onchange={handleTabChange}
                    array={industryData}
                    className={classNames(classes.landingTabs, classes.btmLandingTabs)}
                  />
                  {tab === 0 && (
                    <Container className="create-quote-request-list">
                      {isLoadingReq && (
                        <Container className={classes.overlayRoot}>
                          <LoadingOverlay color="secondary" size={40} />
                        </Container>
                      )}
                      {filtered?.map((filteredData, id) => (
                        <Container key={id} flex className="create-quote-request-list-card" gap="0 10px">
                          <Container flex direction={'column'} className="card-left-img">
                            <img src={filteredData?.brand_cover_image} alt="BRAND LOGO" />
                            <img src={filteredData?.equip_cover_image} alt="Machine parts" />
                          </Container>
                          <Container width={'100%'} className={'card-right'}>
                            <Container className="card-title">
                              <Typography variant="title" component="h3" color={getColor('grey-g25')}>
                                {filteredData?.brand_name} {filteredData?.part_name} {filteredData?.quote_part_number}
                              </Typography>

                              <Typography variant="paragraph1" component={'p'} color={getColor('grey-g35')}>
                                {filteredData?.equip_name ? <>{filteredData?.equip_name}</> : <>{''}</>}
                                {filteredData?.quote_brand_model_number ? (
                                  <>
                                    <span className={classes.dot}> • </span>
                                    {filteredData?.quote_brand_model_number}
                                  </>
                                ) : (
                                  <>{''}</>
                                )}
                                {filteredData?.quote_brand_serial_number ? (
                                  <>
                                    <span className={classes.dot}> • </span>
                                    <Button onClick={handleClick} className={classes.blueLinks}>
                                      {/* Unlock serial number */}
                                      {getSerialNumber()}
                                      {/* {filteredData?.quote_brand_serial_number} */}
                                    </Button>
                                  </>
                                ) : (
                                  <>{''}</>
                                )}
                              </Typography>
                            </Container>
                            <Container>
                              {/* <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                                123 Equipment Rental Company
                              </Typography> */}
                              <Container>
                                <Button onClick={handleClick} className={classes.blueLinks}>
                                  {/* {filteredData?.buyer_full_name} */}
                                  {getBuyerDetails()}
                                  {/* Unlock Buyer Details */}
                                </Button>
                              </Container>
                              <Container flex justify="flex-start" gap="0 5px">
                                <Typography
                                  variant="paragraph1"
                                  component="p"
                                  color={getColor('grey-g35')}
                                  className="flag-content"
                                >
                                  {getShortAddress(
                                    filteredData?.cust_addr_city,
                                    filteredData?.cust_addr_state,
                                    filteredData?.cust_addr_country
                                  )}
                                </Typography>
                                {getFlag(filteredData?.cust_addr_country)}
                              </Container>
                            </Container>
                            <Container flex width={'100%'} justify={'space-between'}>
                              <Container>
                                <Typography
                                  variant="paragraph1"
                                  color={getColor('grey-g25')}
                                  component="p"
                                  className="brand-paragraph"
                                >
                                  {filteredData?.brand_name}
                                  {filteredData?.equip_name ? (
                                    <>
                                      <span className={classes.dot}> • </span>
                                      {filteredData?.equip_name}
                                    </>
                                  ) : (
                                    <>{''}</>
                                  )}
                                  {filteredData?.part_name || filteredData?.quote_brand_name_ref ? (
                                    <>
                                      <span className={classes.dot}> • </span>
                                      {filteredData?.part_name || filteredData?.quote_brand_name_ref}
                                    </>
                                  ) : (
                                    <>{''}</>
                                  )}
                                </Typography>
                              </Container>
                              <Container>
                                <Button
                                  variant="positive"
                                  width={125}
                                  onClick={(e) => handleQuoteOpen(e, 'Agricultural & Turf')}
                                >
                                  Start Request
                                </Button>
                              </Container>
                            </Container>
                          </Container>
                        </Container>
                      ))}
                    </Container>
                  )}
                  {tab === 1 && (
                    <Container className="create-quote-request-list">
                      {isLoadingReq && (
                        <Container className={classes.overlayRoot}>
                          <LoadingOverlay color="secondary" size={40} />
                        </Container>
                      )}
                      {filtered?.map((filteredData, id) => (
                        <Container key={id} flex className="create-quote-request-list-card" gap="0 10px">
                          <Container flex direction={'column'} className="card-left-img">
                            <img src={filteredData?.brand_cover_image} alt="BRAND LOGO" />
                            <img src={filteredData?.equip_cover_image} alt="Machine parts" />
                          </Container>
                          <Container width={'100%'} className={'card-right'}>
                            <Container className="card-title">
                              <Typography variant="title" component="h3" color={getColor('grey-g25')}>
                                {filteredData?.brand_name} {filteredData?.part_name} {filteredData?.quote_part_number}
                              </Typography>

                              <Typography variant="paragraph1" component={'p'} color={getColor('grey-g35')}>
                                {filteredData?.equip_name ? <>{filteredData?.equip_name}</> : <>{''}</>}
                                {filteredData?.quote_brand_model_number ? (
                                  <>
                                    <span className={classes.dot}> • </span>
                                    {filteredData?.quote_brand_model_number}
                                  </>
                                ) : (
                                  <>{''}</>
                                )}
                                {filteredData?.quote_brand_serial_number ? (
                                  <>
                                    <span className={classes.dot}> • </span>
                                    <Button onClick={handleClick} className={classes.blueLinks}>
                                      {/* Unlock serial number */}
                                      {getSerialNumber()}
                                      {/* {filteredData?.quote_brand_serial_number} */}
                                    </Button>
                                  </>
                                ) : (
                                  <>{''}</>
                                )}
                              </Typography>
                            </Container>
                            <Container>
                              {/* <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                                123 Equipment Rental Company
                              </Typography> */}
                              <Container>
                                <Button onClick={handleClick} className={classes.blueLinks}>
                                  {/* {filteredData?.buyer_full_name} */}
                                  {getBuyerDetails()}
                                  {/* Unlock Buyer Details */}
                                </Button>
                              </Container>

                              <Container flex justify="flex-start" gap="0 5px">
                                <Typography
                                  variant="paragraph1"
                                  component="p"
                                  color={getColor('grey-g35')}
                                  className="flag-content"
                                >
                                  {getShortAddress(
                                    filteredData?.cust_addr_city,
                                    filteredData?.cust_addr_state,
                                    filteredData?.cust_addr_country
                                  )}
                                </Typography>
                                {getFlag(filteredData?.cust_addr_country)}
                              </Container>
                            </Container>
                            <Container flex width={'100%'} justify={'space-between'}>
                              <Container>
                                <Typography
                                  variant="paragraph1"
                                  color={getColor('grey-g25')}
                                  component="p"
                                  className="brand-paragraph"
                                >
                                  {filteredData?.brand_name}

                                  {filteredData?.equip_name ? (
                                    <>
                                      <span className={classes.dot}> • </span>
                                      {filteredData?.equip_name}
                                    </>
                                  ) : (
                                    <>{''}</>
                                  )}
                                  {filteredData?.part_name || filteredData?.quote_brand_name_ref ? (
                                    <>
                                      <span className={classes.dot}> • </span>
                                      {filteredData?.part_name || filteredData?.quote_brand_name_ref}
                                    </>
                                  ) : (
                                    <>{''}</>
                                  )}

                                  {/* {filteredData?.quote_quantity ? (
                            <>
                              <span className={classes.dot}> • </span>
                              {filteredData?.quote_quantity}
                            </>
                          ) : (
                            <>{''}</>
                          )} */}
                                </Typography>
                              </Container>
                              <Container>
                                <Button
                                  variant="positive"
                                  width={125}
                                  onClick={(e) => handleQuoteOpen(e, 'Construction & Mining')}
                                >
                                  Start Request
                                </Button>
                              </Container>
                            </Container>
                          </Container>
                        </Container>
                      ))}
                    </Container>
                  )}
                  {tab === 2 && (
                    <Container className="create-quote-request-list">
                      {isLoadingReq && (
                        <Container className={classes.overlayRoot}>
                          <LoadingOverlay color="secondary" size={40} />
                        </Container>
                      )}
                      {filtered?.map((filteredData, id) => (
                        <Container key={id} flex className="create-quote-request-list-card" gap="0 10px">
                          <Container flex direction={'column'} className="card-left-img">
                            <img src={filteredData?.brand_cover_image} alt="BRAND LOGO" />
                            <img src={filteredData?.equip_cover_image} alt="Machine parts" />
                          </Container>
                          <Container width={'100%'} className={'card-right'}>
                            <Container className="card-title">
                              <Typography variant="title" component="h3" color={getColor('grey-g25')}>
                                {filteredData?.brand_name} {filteredData?.part_name} {filteredData?.quote_part_number}
                              </Typography>

                              <Typography variant="paragraph1" component={'p'} color={getColor('grey-g35')}>
                                {filteredData?.equip_name ? <>{filteredData?.equip_name}</> : <>{''}</>}
                                {filteredData?.quote_brand_model_number ? (
                                  <>
                                    <span className={classes.dot}> • </span>
                                    {filteredData?.quote_brand_model_number}
                                  </>
                                ) : (
                                  <>{''}</>
                                )}
                                {filteredData?.quote_brand_serial_number ? (
                                  <>
                                    <span className={classes.dot}> • </span>
                                    <Button onClick={handleClick} className={classes.blueLinks}>
                                      {/* Unlock serial number */}
                                      {getSerialNumber()}
                                      {/* {filteredData?.quote_brand_serial_number} */}
                                    </Button>
                                  </>
                                ) : (
                                  <>{''}</>
                                )}
                              </Typography>
                            </Container>
                            <Container>
                              {/* <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                                123 Equipment Rental Company
                              </Typography> */}
                              <Container>
                                <Button onClick={handleClick} className={classes.blueLinks}>
                                  {/* {filteredData?.buyer_full_name} */}
                                  {getBuyerDetails()}
                                  {/* Unlock Buyer Details */}
                                </Button>
                              </Container>

                              <Container flex justify="flex-start" gap="0 5px">
                                <Typography
                                  variant="paragraph1"
                                  component="p"
                                  color={getColor('grey-g35')}
                                  className="flag-content"
                                >
                                  {getShortAddress(
                                    filteredData?.cust_addr_city,
                                    filteredData?.cust_addr_state,
                                    filteredData?.cust_addr_country
                                  )}
                                </Typography>
                                {getFlag(filteredData?.cust_addr_country)}
                              </Container>
                            </Container>
                            <Container flex width={'100%'} justify={'space-between'}>
                              <Container>
                                <Typography
                                  variant="paragraph1"
                                  color={getColor('grey-g25')}
                                  component="p"
                                  className="brand-paragraph"
                                >
                                  {filteredData?.brand_name}
                                  {filteredData?.equip_name ? (
                                    <>
                                      <span className={classes.dot}> • </span>
                                      {filteredData?.equip_name}
                                    </>
                                  ) : (
                                    <>{''}</>
                                  )}
                                  {filteredData?.part_name || filteredData?.quote_brand_name_ref ? (
                                    <>
                                      <span className={classes.dot}> • </span>
                                      {filteredData?.part_name || filteredData?.quote_brand_name_ref}
                                    </>
                                  ) : (
                                    <>{''}</>
                                  )}

                                  {/* {filteredData?.quote_quantity ? (
                            <>
                              <span className={classes.dot}> • </span>
                              {filteredData?.quote_quantity}
                            </>
                          ) : (
                            <>{''}</>
                          )} */}
                                </Typography>
                              </Container>
                              <Container>
                                <Button
                                  variant="positive"
                                  width={125}
                                  onClick={(e) => handleQuoteOpen(e, 'Industrial & Aerial')}
                                >
                                  Start Request
                                </Button>
                              </Container>
                            </Container>
                          </Container>
                        </Container>
                      ))}
                    </Container>
                  )}
                  {tab === 3 && (
                    <Container className="create-quote-request-list">
                      {isLoadingReq && (
                        <Container className={classes.overlayRoot}>
                          <LoadingOverlay color="secondary" size={40} className={classes.progress} />
                        </Container>
                      )}
                      {filtered?.map((filteredData, id) => (
                        <Container key={id} flex className="create-quote-request-list-card" gap="0 10px">
                          <Container flex direction={'column'} className="card-left-img">
                            <img src={filteredData.brand_cover_image} alt="BRAND LOGO" />
                            <img src={filteredData.equip_cover_image} alt="Machine parts" />
                          </Container>
                          <Container width={'100%'} className={'card-right'}>
                            <Container className="card-title">
                              <Typography variant="title" component="h3" color={getColor('grey-g25')}>
                                {filteredData?.brand_name} {filteredData?.part_name} {filteredData?.quote_part_number}
                              </Typography>

                              <Typography variant="paragraph1" component={'p'} color={getColor('grey-g35')}>
                                {filteredData?.equip_name ? <>{filteredData?.equip_name}</> : <>{''}</>}
                                {filteredData?.quote_brand_model_number ? (
                                  <>
                                    <span className={classes.dot}> • </span>
                                    {filteredData?.quote_brand_model_number}
                                  </>
                                ) : (
                                  <>{''}</>
                                )}
                                {filteredData?.quote_brand_serial_number ? (
                                  <>
                                    <span className={classes.dot}> • </span>
                                    <Button onClick={handleClick} className={classes.blueLinks}>
                                      {/* Unlock serial number */}
                                      {getSerialNumber()}
                                      {/* {filteredData?.quote_brand_serial_number} */}
                                    </Button>
                                  </>
                                ) : (
                                  <>{''}</>
                                )}
                              </Typography>
                            </Container>
                            <Container>
                              {/* <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                                123 Equipment Rental Company
                              </Typography> */}
                              <Container>
                                <Button onClick={handleClick} className={classes.blueLinks}>
                                  {/* {filteredData?.buyer_full_name} */}
                                  {getBuyerDetails()}
                                  {/* Unlock Buyer Details */}
                                </Button>
                              </Container>

                              <Typography
                                variant="paragraph1"
                                component="p"
                                color={getColor('grey-g35')}
                                className="flag-content"
                              >
                                {filteredData?.cust_addr_city ? filteredData?.cust_addr_city : <>{''}</>}
                                {filteredData?.cust_addr_state_abbrv ? (
                                  <>, {filteredData?.cust_addr_state_abbrv} </>
                                ) : (
                                  <>{''}</>
                                )}
                              </Typography>
                            </Container>
                            <Container flex width={'100%'} justify={'space-between'}>
                              <Container>
                                <Typography
                                  variant="paragraph1"
                                  color={getColor('grey-g25')}
                                  component="p"
                                  className="brand-paragraph"
                                >
                                  {filteredData?.brand_name}
                                  {filteredData?.equip_name ? (
                                    <>
                                      <span className={classes.dot}> • </span>
                                      {filteredData?.equip_name}
                                    </>
                                  ) : (
                                    <>{''}</>
                                  )}
                                  {filteredData?.part_name || filteredData?.quote_brand_name_ref ? (
                                    <>
                                      <span className={classes.dot}> • </span>
                                      {filteredData?.part_name || filteredData?.quote_brand_name_ref}
                                    </>
                                  ) : (
                                    <>{''}</>
                                  )}

                                  {/* {filteredData?.quote_quantity ? (
                            <>
                              <span className={classes.dot}> • </span>
                              {filteredData?.quote_quantity}
                            </>
                          ) : (
                            <>{''}</>
                          )} */}
                                </Typography>
                              </Container>
                              <Container>
                                <Button variant="positive" width={111} onClick={() => navigate('/createquote')}>
                                  Start Quote
                                </Button>
                              </Container>
                            </Container>
                          </Container>
                        </Container>
                      ))}
                    </Container>
                  )}
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className="how-it-works-section">
        <Container className="container">
          <Container className="how-it-works-inner p90">
            <Typography className="title" component="h1">
              How It Works
            </Typography>
            <Container className="how-it-work-tabs">
              <MuiTab className={classes.landingTabs} value={value} onChange={handleChange}>
                <Tab label="For Buyers" />
                <Tab label="For Suppliers" />
              </MuiTab>
              {value === 0 && (
                <Container>
                  <Container className="how-it-work-grid">
                    <Container className="how-it-work-item">
                      <Container className="how-it-work-item-inner commonflex" direction="column" gap="20px 0">
                        <img src={CreateRequest} alt="CreateRequest" className="desktop-img" />
                        <img src={CreaterqMobile} alt="CreateRequest" className="mobile-img" />

                        <Container className="how-it-work-item-content ">
                          <Typography component="h3" variant="h3" className="commoncolor p6">
                            Create a Request
                          </Typography>
                          <Typography component="p" variant="body1" color={getColor('grey-g35')}>
                            Simply input what machine you’re working on and what parts you need. We’ll take it from
                            there!
                          </Typography>
                        </Container>
                      </Container>
                    </Container>
                    <Container className="how-it-work-item">
                      <Container className="how-it-work-item-inner commonflex" direction="column" gap="20px 0">
                        <img src={QuoteList} alt="QuoteList" className="desktop-img" />
                        <img src={RecieveOfferMobile} alt="QuoteList" className="mobile-img" />
                        <Container className="how-it-work-item-content">
                          <Typography component="h3" variant="h3" className="commoncolor p6">
                            Receive Offers
                          </Typography>
                          <Typography component="p" variant="body1" color={getColor('grey-g35')}>
                            Your request is routed to vetted suppliers who specialize in the types of parts you’re
                            looking for.
                          </Typography>
                        </Container>
                      </Container>
                    </Container>
                    <Container className="how-it-work-item">
                      <Container className="how-it-work-item-inner commonflex" direction="column" gap="20px 0">
                        <img src={BuyNow} alt="BuyNow" className="desktop-img" />
                        <img src={CheckoutMobile} alt="BuyNow" className="mobile-img" />
                        <Container className="how-it-work-item-content">
                          <Typography component="h3" variant="h3" className="commoncolor p6">
                            Checkout
                          </Typography>
                          <Typography component="p" variant="body1" color={getColor('grey-g35')}>
                            Approve the best offer, purchase the parts in seconds, and receive tracking right on
                            PartsClub.
                          </Typography>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              )}
              {value === 1 && (
                <Container>
                  <Container className="how-it-work-grid">
                    <Container className="how-it-work-item">
                      <Container className="how-it-work-item-inner commonflex" direction="column" gap="20px 0">
                        <img src={MatchingRequest} alt="CreateRequest" />
                        <Container className="how-it-work-item-content ">
                          <Typography component="h3" variant="h3" className="commoncolor p6">
                            Get Matching Requests
                          </Typography>
                          <Typography component="p" variant="body1" color={getColor('grey-g35')}>
                            Set your preferences and receive part requests you can win from buyers in your desired
                            territory.
                          </Typography>
                        </Container>
                      </Container>
                    </Container>
                    <Container className="how-it-work-item">
                      <Container className="how-it-work-item-inner commonflex" direction="column" gap="20px 0">
                        <img src={QuoteBuyer} alt="QuoteList" />
                        <Container className="how-it-work-item-content">
                          <Typography component="h3" variant="h3" className="commoncolor p6">
                            Quote Buyers
                          </Typography>
                          <Typography component="p" variant="body1" color={getColor('grey-g35')}>
                            Send professional and powerful quotes to buyers in under 1-minute. Buyers can instantly
                            approve your quote to complete a purchase.
                          </Typography>
                        </Container>
                      </Container>
                    </Container>
                    <Container className="how-it-work-item">
                      <Container className="how-it-work-item-inner commonflex" direction="column" gap="20px 0">
                        <img src={Receiveorder} alt="BuyNow" />
                        <Container className="how-it-work-item-content">
                          <Typography component="h3" variant="h3" className="commoncolor p6">
                            Receive Orders
                          </Typography>
                          <Typography component="p" variant="body1" color={getColor('grey-g35')}>
                            Just add a tracking number and receive payment when the parts are confirmed as delivered.
                          </Typography>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              )}
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className="trusted-supplier-sec">
        <Container className="container">
          <Container className="trusted-supplier-inner p90">
            <Container className="trusted-supplier-title">
              <Typography className="title veryfied" component="h1">
                Get great deals from verified suppliers
                <img src={verfiedBigIcon} alt="verify" />
              </Typography>
              <Typography component="p" variant="body1" color={getColor('grey-g35')}>
                Get great pricing from a network of reputable suppliers. All suppliers are manually verified and must
                meet our buyer protection standards to participate on PartsClub.
              </Typography>
            </Container>
            <Container className="trusted-supplier-grid" gap="20">
              <Container className="trusted-supplier-item">
                <Container className="trusted-supplier-item-inner">
                  <img src={ReliableAftermarket} alt="AisReplacement" />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    Reliable Aftermarket Parts
                  </Typography>
                </Container>
              </Container>
              <Container className="trusted-supplier-item">
                <Container className="trusted-supplier-item-inner">
                  <img src={ConEquip} alt="ConEquip" />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    ConEquip Parts
                  </Typography>
                </Container>
              </Container>
              <Container className="trusted-supplier-item">
                <Container className="trusted-supplier-item-inner">
                  <img src={AmsConstruction} alt="AmsConstruction" />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    AMS Construction Parts
                  </Typography>
                </Container>
              </Container>
              <Container className="trusted-supplier-item">
                <Container className="trusted-supplier-item-inner">
                  <img src={EquipmentShare} alt="EquipmentShare" />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    EquipmentShare
                  </Typography>
                </Container>
              </Container>
              <Container className="trusted-supplier-item">
                <Container className="trusted-supplier-item-inner">
                  <img src={NashLift} alt="NashLift" />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    NashLift
                  </Typography>
                </Container>
              </Container>
              <Container className="trusted-supplier-item">
                <Container className="trusted-supplier-item-inner">
                  <img src={EasternLiftTruck} alt="EasternLiftTruck" />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    Eastern Lift Truck Co.
                  </Typography>
                </Container>
              </Container>
              <Container className="trusted-supplier-item">
                <Container className="trusted-supplier-item-inner">
                  <img src={BedrockMachinery} alt="BedrockMachinery" />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    Bedrock Machinery
                  </Typography>
                </Container>
              </Container>
              <Container className="trusted-supplier-item">
                <Container className="trusted-supplier-item-inner">
                  <img src={RomacParts} alt="RomacParts" />
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    Romac Parts
                  </Typography>
                </Container>
              </Container>
            </Container>
            <Container className="findGreatOverlay">
              <Typography variant="title" color={getColor('orange-main')} component={'p'}>
                Find great OEM & aftermarket suppliers for new, used, and rebuilt equipment parts.
              </Typography>
            </Container>
            <Container className="sign-seeAll-btn" flex gap="0 20px">
              <Button variant="primary" className="signBtn" onClick={setOpenDialog}>
                Sign Up Free
              </Button>
              <Button className="seeAllBtn" variant="transparent" onClick={() => navigate('/supplier')}>
                See All Suppliers
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>

      <Container className="parts-search-sales-sec">
        <Container className="container">
          <Container className="parts-search-sales-inner p90">
            <Typography className="parts-title" component="h1">
              Buying and selling parts online just got easy
            </Typography>
            <TestinomialSlider>
              {Testinomial?.map((data, index) => (
                <Container key={index} className="parts-search-sales-item">
                  <Container className="parts-search-sales-item-inner">
                    <Typography component="h3">{data.feedBack}</Typography>
                    <Container className="parts-search-sales-flex">
                      <img src={data.img} alt="jamesSchneider" />
                      <Container>
                        <Typography variant="body1" component={'h5'} color={getColor('light-main')}>
                          {data.name}
                        </Typography>
                        <Typography variant="body2" component={'p'} color={getColor('grey-g80')}>
                          {data.profile}
                        </Typography>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              ))}
            </TestinomialSlider>
          </Container>
        </Container>
      </Container>
      <Container className="frequently-ask-question-section">
        <Container className="container">
          <Container className="frequently-ask-question-inner p90">
            <Typography className="title " component="h1">
              Frequently Asked Questions (FAQ)
            </Typography>
            <Container className="frequently-ask-question-accordian">
              <Accordion>
                <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                    Is PartsClub like eBay or Amazon
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    PartsClub competes directly with both eBay and Amazon by providing unique features and functionality
                    to facilitate buyer-seller interactions and transactions specific to the equipment parts industry.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    These features include seller fees that are up to up to 70% less than competitors, account-specific
                    pricing discounts between buyers and sellers, the ability to checkout without a credit card, and
                    more.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                    How does PartsClub make money?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    PartsClub has an advertising-supported free tier for both buyers and suppliers.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    Sellers also pay the industry’s lowest transaction fees (3% + applicable credit card fees) and we
                    have upgraded account options for both buyers and sellers looking for greater functionality and
                    performance reporting.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    *PartsClub does not sell or share specific personal or activity data of individuals or businesses
                    using the platform.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                    If I buy a part on PartsClub - what happens next?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    PartsClub has an identical eCommerce process to eBay or Amazon - orders on PartsClub are sent to the
                    supplier for order and shipping confirmations. The supplier will upload a tracking number once the
                    product ships so you can track your order to your door.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    If you require any assistance with an order, PartsClub has dedicated Support Staff to assist you.
                    Our team can be reached during business hours at sellmoreparts@partsclub.us.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                    What if there is something wrong with my order?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    We want all buyers and sellers to have a positive experience working together on PartsClub but
                    understand that issues do occur periodically. Let us help and make it right.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    If you require any assistance with an order, PartsClub has dedicated Support Staff to assist you.
                    Our team can be reached during business hours at sellmoreparts@partsclub.us.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                    How do I return an order?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    Returns can easily be started in PartsClub right from your order screen.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    Just upload the tracking number when you send the order back to the supplier and the refund will be
                    sent back when the supplier receives the return.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                    Who owns PartsClub?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    PartsClub, Inc. is an independent corporation based in Austin, Texas and is not financially
                    affiliated with any organizations in the equipment or equipment parts industries.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    Neutrality is one of our core values - PartsClub has intentionally avoided accepting funding or
                    financing from any current or future platform participant to maintain impartiality and the trust of
                    PartsClub’s buyers and sellers.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    Our founder Zac Jones created PartsClub to help agricultural, construction and industrial equipment
                    businesses grow parts sales by easily listing parts, quoting buyers and selling parts on a safe and
                    neutral platform.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                    Is PartsClub only in North America?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    PartsClub is initially focused on serving North America - exclusively processing transactions
                    between buyers and sellers in the United States, Canada, and Mexico. PartsClub will be launching in
                    Europe in Spring 2024.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    PartsClub is also projected to launch in the Caribbean, Central America, and South America in Fall
                    2025 with further expansion to follow.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Container>
            <Container className="still-question">
              <Container className="still-question-inner">
                <img src={stillQuestion} alt="still" />
                <Container className="still-question-title">
                  <Typography component="h3">Still have questions?</Typography>
                  <Typography component="p" className="contact-line">
                    Can’t find answer you’re looking for? Ask us, our team always here to help you. Contact us at{' '}
                    <a href="mailto:sellmoreparts@partsclub.us"> sellmoreparts@partsclub.us</a>
                  </Typography>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className="every-order-sec">
        <Container className="container">
          <Container className="every-order-inner p90">
            <Container className="every-order-grid">
              <Container className="every-order-left">
                <Container className="every-order-left-inner">
                  <Typography className="title" component="h1">
                    Every order supports military veterans & their families
                  </Typography>
                  <Typography variant="h3" component="h3" color={getColor('grey-g25')}>
                    Supporting those who serve and sacrifice to keep us safe.
                  </Typography>
                  <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                    We are committed to donating a portion of each sale on PartsClub to the Wounded Warrior Project in
                    support of Veterans and their families.
                  </Typography>
                  <a
                    // variant="transparent"
                    className={'everyOrderBtn'}
                    href={'https://www.woundedwarriorproject.org'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Wounded Warrior Project
                  </a>
                </Container>
              </Container>
              <Container className="every-order-right">
                <img src={EveryOrder} alt="EveryOrder" />
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <LandingPageFooter />
      <BuyerQuoteContext.Provider value={contextValue}>
        <CreateQuoteDialog
          open={openQuoteDialog}
          onClose={setOpenQuoteDialog}
          setFormStep={setFormStep}
          formStep={formStep}
          quoteTab={quoteTab}
        />
      </BuyerQuoteContext.Provider>
      <LoginDialog open={openDialog} onClose={setOpenDialog} />
    </Container>
  )
}

export default LandingPage
