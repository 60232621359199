import Api from 'services/Api'

export async function getEquipmentsData(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getBrandsData(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getPartsData(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function createQuotePost(data) {
  const response = await Api.post(`/quote`, data)
  return response.data.data
}

export async function approveQuote(data) {
  const response = await Api.post(`/quote/order`, data)
  return response.data.data
}

export async function getSupplierList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getSupplierInvitedList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function inviteSuppliersList(data) {
  const response = await Api.post(`/quote-invite`, data)
  return response.data.data
}
