import Container from 'components/common/Container'
import { React } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Select from 'components/common/select/Select'
import { Controller, useForm, useWatch } from 'react-hook-form'
import useToastify from 'common-hooks/useToastify'
import { createOptionDialog } from 'components/common/text/DropDownTextField'
import { parsedBrandOptions } from 'containers/super-admin/supplier-mgt/SupplierUtils'
import useDistributorBrandList from 'common-hooks/super-admin/useDistributorBrandList'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    overflow: 'hidden',
  },
})

const DistributorDialog = ({ open, onclose, distBrands, setDistBrands }) => {
  const { toastMessage } = useToastify()
  const { brandList, onSearch } = useDistributorBrandList()
  const { control, reset } = useForm()
  const [Distbrand] = useWatch({
    control,
    name: ['Distbrand'],
  })
  // const option = brandList?.map((obj) => ({ id: obj.id, label: obj.name, img: obj?.cover_image }))
  const option = brandList?.map((obj) => createOptionDialog(obj.id, obj.name, obj?.cover_image))
  const classes = useStyles()
  const handleClose = () => {
    onclose()
    reset()
  }
  const handleAdd = () => {
    if (!Distbrand) {
      toastMessage('error', 'Please select brand')
    }
    const parsedOptions = Distbrand?.map((obj) => parsedBrandOptions(obj?.value, obj?.label, obj?.image))
    if (!JSON.stringify(distBrands).includes(Distbrand?.map((item) => item.value))) {
      setDistBrands([...distBrands, ...parsedOptions])
      onclose()
      reset()
    } else {
      toastMessage('error', 'Brand is already added')
    }
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Product Line Distributor'} onClose={onclose} />
          <Container className={classes.selectField}>
            <Controller
              name={'Distbrand'}
              control={control}
              render={({ field: { name, onChange } }) => (
                <Select
                  options={option}
                  id={name}
                  multi
                  name={name}
                  onChange={onChange}
                  onInputChange={(e) => onSearch(e)}
                  label={'Brand*'}
                  menuPosition={'fixed'}
                />
              )}
            />
            {/* <Select options={option} id={'Status'} name={'Status'} label={'Brand'} /> */}
          </Container>

          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={handleClose}>
                Cancel
              </Button>
              <Button width={80} onClick={handleAdd}>
                Add
              </Button>
            </Container>
          </DialogFooter>
        </Dialog>
      </Container>
    </>
  )
}
DistributorDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  distBrands: PropTypes.array,
  setDistBrands: PropTypes.func,
}

export default DistributorDialog
