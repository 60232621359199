/* eslint-disable react/jsx-no-bind */
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import PropTypes from 'prop-types'
import React from 'react'
// import { RightIcon } from 'assets/icons'
import SucessIcon from 'assets/icons/RightIcon.svg'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import { useLocation, useNavigate } from 'react-router-dom'
import useOrderDetails from 'common-hooks/orders/useOrderDetails'

const useStyle = makeStyles(() => ({
  root: {
    paddingTop: '0px',
    paddingBottom: '40px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  headerRoot: {
    border: 'unset',
  },
  link: {
    color: getColor('func-info'),
  },
}))
export default function QuoteSuccessDialog({ open, onClose, orderId }) {
  const classes = useStyle()
  const navigate = useNavigate()
  const location = useLocation()
  const { orderDetailsData } = useOrderDetails(orderId)
  const handleClick = () => {
    navigate(`/orders/AwaitingShipment/${orderDetailsData?.order_id}/${orderId}`)
    onClose()
  }
  return (
    <Dialog open={open} size={'sm'}>
      <DialogHead className={classes.headerRoot} borderBottom={'0px'} onClose={handleClick} />
      <Container className={classes.root} flex direction="column" gap="20px">
        <Container flex justify="center">
          <img src={SucessIcon} alt={'RightIcon'} />
        </Container>

        {location.pathname.includes('supplierdetail') && (
          <>
            <Container flex direction="column">
              <Typography variant="title" color={getColor('grey-g25')}>
                Quote Request Created!
              </Typography>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {' '}
                Your quote has been sent successfully to the buyer.{' '}
              </Typography>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {' '}
                We will notify you as soon as the buyer responds.
              </Typography>
            </Container>
            <Container flex>
              <Button type="submit" id={`hook-form-succesh`} onClick={handleClick} width={'100px'}>
                Got it
              </Button>
            </Container>
          </>
        )}
        {location.pathname.includes('/quotes/active') && (
          <>
            <Container flex direction="column">
              <Typography variant="title" color={getColor('grey-g25')}>
                Order Sent Successfully!
              </Typography>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {' '}
                Your order has been successfully sent to seller. Contact
              </Typography>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {' '}
                seller for further process.
              </Typography>
            </Container>
          </>
        )}
      </Container>
    </Dialog>
  )
}

QuoteSuccessDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  orderId: PropTypes.string,
}
