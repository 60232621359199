import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import TextArea from 'components/common/text/TextArea'
import { RedDeleteIcon } from 'assets/icons'
import { useEffect } from 'react'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px ',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  message: {
    border: 'unset',
    '& input': {
      border: 'unset',
      backgroundColor: 'unset',
    },
  },
  TextArea: {
    height: '200px',
    '& div': {
      height: '200px',
    },
    '& textarea': {
      border: 'unset',
      backgroundColor: 'unset',
      padding: '0',
      height: '200px!important',
    },
  },
  subject: {
    height: '46px',
    borderTop: '1px solid #F0F0F4',
    borderBottom: '1px solid #F0F0F4',
    '& input': {
      border: 'unset',
      backgroundColor: 'unset',
      padding: '0',
      fontWeight: ' 300',
    },
    '& input:placeholder': {
      color: '#9799B5',
    },
  },
  deletIcon: {
    background: 'unset',
    border: '1px solid #9799B5',
    borderRadius: '4px',
    width: '36px',
    '& span': {
      display: 'flex',
    },
    '&:hover': {
      background: 'unset',
    },
  },
})

export default function NotesDialog({ open, onClose, editnoteData, noteId, productUpdate, setEditNoteData }) {
  const classes = useStyles()
  const { control, handleSubmit, reset, setValue } = useForm()
  const { toastMessage } = useToastify()

  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    if (data?.notes === '' || data?.notes === undefined) {
      toastMessage('error', 'Notes cant be empty')
      return 0
    }
    productUpdate(editnoteData ? { id: noteId, notes: data?.notes, update: true } : { id: noteId, notes: data?.notes })
    handleOnClose()
  }

  const handleOnClose = () => {
    reset({
      notes: '',
    })
    setEditNoteData(null)
    onClose()
  }

  useEffect(() => {
    if (editnoteData) {
      setValue('notes', editnoteData)
    }
  }, [editnoteData])

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Notes'} onClose={handleOnClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.selectField}>
              <Container className={classes.TextArea}>
                <Controller
                  name={'notes'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextArea
                      name={name}
                      value={value}
                      onChange={onChange}
                      // className={classes.TextArea}
                      // error={getFormError(errors, name)}
                      placeholder={'Write notes here...'}
                    />
                  )}
                />
              </Container>
            </Container>
            <DialogFooter justify={'space-between'}>
              <Container>
                {editnoteData && (
                  <Button
                    className={classes.deletIcon}
                    onClick={() => {
                      productUpdate({ id: noteId, notes: '' })
                      handleOnClose()
                    }}
                  >
                    <RedDeleteIcon />
                  </Button>
                )}
              </Container>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={handleOnClose}>
                  Cancel
                </Button>
                <Button type="submit">{editnoteData ? 'Update' : 'Add'} </Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
NotesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editnoteData: PropTypes.string,
  noteId: PropTypes.string,
  productUpdate: PropTypes.func,
  setEditNoteData: PropTypes.func,
}
