import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Select from 'components/common/select/Select'
import { Controller, useForm, useWatch } from 'react-hook-form'
import useProductList from 'common-hooks/inventory/useProductList'
import useToastify from 'common-hooks/useToastify'
import { useEffect, useState } from 'react'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    overflow: 'hidden',
  },
})

export default function RelatedProductDialog({ open, onClose, setRelatedProducts, relatedProducts, setflag }) {
  const classes = useStyles()
  const { control, reset } = useForm()
  const { toastMessage } = useToastify()
  const { productData } = useProductList('supplier', '')
  const [btnDisable, setBtnDisable] = useState(true)
  const [Product] = useWatch({
    control,
    name: ['Product'],
  })
  //   const options = countryOption?.map((item) => createOption(item, item))
  const option = productData?.map((obj) => ({
    value: obj?.id,
    label: obj?.title,
    price: obj?.unit_price,
    cover_image: obj?.cover_image,
    condition: obj?.condition,
  }))

  const handleAdd = () => {
    // if (Product) {
    //   setRelatedProducts((pre) => [...pre, Product])
    //   reset()
    //   onClose()
    // } else {
    //   toastMessage('error', 'Please select a product')
    // }

    if (!Product) {
      toastMessage('error', 'Please select a product')
    }
    if (relatedProducts?.length > 0 && JSON.stringify(relatedProducts)?.includes(Product?.value)) {
      toastMessage('error', 'Product is already added')
    } else {
      setRelatedProducts((pre) => [...pre, Product])
      reset()
      onClose()
    }
    setflag(false)
  }
  useEffect(() => {
    if (Product?.value !== '' && Product?.value !== undefined) {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }, [Product])
  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Related Products'} onClose={onClose} />
          <Container className={classes.selectField}>
            <Controller
              name={'Product'}
              control={control}
              render={({ field: { name, onChange } }) => (
                <Select
                  options={option}
                  id={name}
                  name={name}
                  onChange={onChange}
                  // label={'Search or Select'}
                  placeholder={'Search by title'}
                  menuPosition={'fixed'}
                />
              )}
            />
            {/* <Select options={option} id={'Status'} name={'Status'} label={'Brand'} /> */}
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={onClose}>
                Cancel
              </Button>
              <Button width={80} onClick={() => handleAdd()} disabled={btnDisable}>
                Add
              </Button>
            </Container>
          </DialogFooter>
        </Dialog>
      </Container>
    </>
  )
}
RelatedProductDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setRelatedProducts: PropTypes.func,
  relatedProducts: PropTypes.array,
  setflag: PropTypes.func,
}
