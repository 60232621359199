import { makeStyles } from '@mui/styles'
import useOrdersList from 'common-hooks/orders/useOrdersList'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import LoadingOverlay from 'components/common/LoadingOverlay'
import SearchField from 'components/common/search/SearchField'
import orderEmptyState from 'assets/images/orderEmptyState.svg'
import orderCompletedEmpty from 'assets/images/orderCompletedEmpty.svg'
import { Tabs } from 'components/common/Tabs'
import Typography from 'components/common/Typography'
import React, { useState, useEffect } from 'react'
import { getColor } from 'theme/colors'
import { TabsList } from '../OrdersUtils'
import SupplierOrderAwaitingList from './SupplierOrderAwaitingList'
import SupplierOrderCancelledList from './SupplierOrderCancelledList'
import SupplierOrderCompletedList from './SupplierOrderCompletedList'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  orderBody: {
    // background: '#fff',
    padding: '30px',
  },
  position: {
    position: 'relative',
    background: '#F8F9F9',
    height: '82vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '0 auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      paddingtop: '50px',
      backgroundColor: '#F0F0F4',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
  },
  orderTitile: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
    '& input': {
      backgroundColor: '#fff',
    },
  },
  placeHolder: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  placeholderTxt: {
    marginTop: '20px',
  },
}))

export default function SupplierOrders() {
  const classes = useStyles()
  const [tab, setTab] = useState(0)

  const [orderStatus, setOrderStatus] = useState('awaiting_shipment')
  const type = 'supplier'

  const handleChange = (event, newValue) => {
    setTab(newValue)
    switch (newValue) {
      case 0:
        setOrderStatus('awaiting_shipment')
        break
      case 1:
        setOrderStatus('shipped')
        break
      case 2:
        setOrderStatus('cancelled')
        break
      default:
        setOrderStatus('awaiting_shipment')
    }
  }
  const location = useLocation()

  const { orderData, isLoading, onSearch, search } = useOrdersList(orderStatus, type)

  useEffect(() => {
    if (location?.state?.status === 'cancelled') {
      setTab(2)
      setOrderStatus('cancelled')
    }
    if (location?.state?.status === 'shipped') {
      setTab(1)
      setOrderStatus('shipped')
    }
    if (location?.state?.status === 'awaiting_shipment') {
      setTab(0)
      setOrderStatus('awaiting_shipment')
    }
  }, [location])
  return (
    <Container className="orderbody-root">
      <Container className={classes.orderBody}>
        <Container className={classes.orderTitile}>
          <Tabs value={tab} onchange={handleChange} array={TabsList} />
          <SearchField
            id={'searchField'}
            width={365}
            onSearch={(e) => {
              onSearch(e)
            }}
            value={search}
          />
        </Container>
        <Container className={classes.position}>
          <Container>
            {tab === 0 && (
              <>
                {isLoading && <LoadingOverlay bgColor={'#F8F9F9'} />}
                <SupplierOrderAwaitingList orderData={orderData} />
                {search === '' && orderData?.length <= 0 && (
                  <>
                    {/* <Container className={classes.placeHolder}>
                      <Typography variant="h1" color={getColor('grey-g35')} component={'p'}>
                        No orders
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        Seems like you haven't received any orders yet.
                      </Typography>
                    </Container> */}
                    <Container className={classes.placeHolder}>
                      <Image src={orderEmptyState} />
                      <Typography
                        variant="title"
                        color={getColor('grey-g35')}
                        component={'p'}
                        className={classes.placeholderTxt}
                      >
                        All your orders have shipped
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        Orders that have been shipped will be under ‘Completed’
                      </Typography>
                    </Container>
                  </>
                )}
                {search !== '' && orderData?.length <= 0 && (
                  <>
                    <Container className={classes.placeHolder}>
                      <Typography variant="h1" color={getColor('grey-g35')} component={'p'}>
                        No orders
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        No Search Data Found
                      </Typography>
                    </Container>
                  </>
                )}
              </>
            )}
          </Container>
          <Container>
            {tab === 1 && (
              <>
                {isLoading && <LoadingOverlay bgColor={'#F8F9F9'} />}
                <SupplierOrderCompletedList orderData={orderData} />
                {search === '' && orderData?.length <= 0 && (
                  <>
                    <Container className={classes.placeHolder}>
                      <Image src={orderCompletedEmpty} />
                      <Typography
                        variant="title"
                        color={getColor('grey-g35')}
                        component={'p'}
                        className={classes.placeholderTxt}
                      >
                        You have no completed orders yet
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        When you shipped order - it will appear here
                      </Typography>
                    </Container>
                  </>
                )}{' '}
                {search !== '' && orderData?.length <= 0 && (
                  <>
                    <Container className={classes.placeHolder}>
                      <Typography variant="h1" color={getColor('grey-g35')} component={'p'}>
                        No orders
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        No Search Data Found
                      </Typography>
                    </Container>
                  </>
                )}
              </>
            )}
          </Container>
          <Container>
            {tab === 2 && (
              <>
                {isLoading && <LoadingOverlay bgColor={'#F8F9F9'} />}
                <SupplierOrderCancelledList orderData={orderData} />
                {search === '' && orderData?.length <= 0 && (
                  <>
                    <Container className={classes.placeHolder}>
                      <Image src={orderEmptyState} />
                      <Typography
                        variant="title"
                        color={getColor('grey-g35')}
                        component={'p'}
                        className={classes.placeholderTxt}
                      >
                        You have no cancelled orders
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        You can cancel an order until it has been shipped
                      </Typography>
                    </Container>
                  </>
                )}
                {search !== '' && orderData?.length <= 0 && (
                  <>
                    <Container className={classes.placeHolder}>
                      <Typography variant="h1" color={getColor('grey-g35')} component={'p'}>
                        No orders
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        No Search Data Found
                      </Typography>
                    </Container>
                  </>
                )}
              </>
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
