import {
  deleteUserProfileImage,
  editSettingProfile,
  updateProfileEmail,
  verifyProfileEmail,
} from 'common-api/settings/settings'
import useToastify from 'common-hooks/useToastify'
import { addProfileParseData } from 'containers/settings/profile/ProfileSettingsUtils'

export default function useProfileSettings() {
  const { toastMessage } = useToastify()

  const editProfileData = async (data, headerImage, profileImage, mutate) => {
    const formData = new FormData()
    const parsedata = addProfileParseData(data)
    if (headerImage) {
      formData.append('img_header', headerImage)
    }
    if (profileImage) {
      formData.append('logo', profileImage)
    }

    formData.append('document', parsedata)
    try {
      const response = await editSettingProfile(formData)
      toastMessage('success', 'Profile updated successfully')
      mutate()
      return response
    } catch {
      return false
    }
  }

  const profileImagesDelete = async (data) => {
    try {
      const response = await deleteUserProfileImage(data)
      if (data.type === 'cover') {
        toastMessage('success', 'Cover image deleted successfully')
      }
      if (data.type === 'logo') {
        toastMessage('success', 'Profile image deleted successfully')
      }
      return response
    } catch {
      return false
    }
  }
  return { editProfileData, verifyProfileEmail, updateProfileEmail, profileImagesDelete }
}
