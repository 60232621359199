/* eslint-disable camelcase */
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import * as yup from 'yup'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import GooglePlaces from 'components/custom/GooglePlaces'
import { getFormError } from 'utils/form/form'
import { useEffect, useState } from 'react'
import useCompanyList from 'common-hooks/useCompanyList'
import Checkbox from 'components/common/Checkbox'
import MonthYearPicker, { createOption } from 'components/common/MonthYearPicker'
import TextField from 'components/common/text/TextField'
import { yupResolver } from '@hookform/resolvers/yup'
import { getWellKnownAddress } from 'utils/Utils'
import useToastify from 'common-hooks/useToastify'
import SelectCreateable from 'components/common/select/SelectCreateable'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: '690px',
    overflowY: 'scroll',
  },
  group: {
    display: 'flex',
    gap: '0 16px',
  },
  radiogrp: {
    display: 'flex',
    gap: '0 26px',
    height: '20px',
    // flexDirection: 'row',
  },
  pb10: {
    paddingBottom: '10px',
  },
  billing: {
    fontWeight: '700',
    color: getColor('grey-g25'),
    paddingTop: '5px',
  },
  bilingAddrres: {
    height: '20px',
  },
})

const schema = yup.object({
  title: yup.string().required('Title name is required.'),
})

const ExperinceDialog = ({ open, onclose, setExperiences, editExperienceData, setEditExperienceData }) => {
  const classes = useStyles()
  const { toastMessage } = useToastify()
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      start_date: new Date(),
      end_date: new Date(),
    },
  })

  const { companyList, onSearch, search } = useCompanyList()
  const [companyOptions, setCompanyOptions] = useState([])

  const setAddressValues = (addr, addr_line_1, addr_line_2) => {
    const loc = formatAddressOfGAPI(addr, addr_line_1, addr_line_2, null)
    setValue('addr_line_1', addr_line_1)
    setValue('addr_line_2', addr_line_2)
    setValue('city', loc.city)
    setValue('state', loc.state)
    // setValue('country', loc.country)
    // setValue('zipcode', loc.zipcode)
    setValue('city_abbrv', loc.city_abbrv)
    setValue('state_abbrv', loc.state_abbrv)
    setValue('country_abbrv', loc.country_abbrv)
  }
  const [startDate] = useWatch({
    control,
    name: ['start_date'],
  })
  const handleOnClose = () => {
    reset({
      title: '',
      company_name: '',
      location: '',
      start_date: new Date(),
      end_date: new Date(),
      currently_working: '',
    })
    setEditExperienceData(null)
    onclose()
  }
  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    if (data?.company_name === undefined || data?.company_name === '' || data?.company_name?.label === '') {
      toastMessage('error', 'Company name is required')
      return 0
    }
    if (data?.addr_line_1 === undefined || data?.addr_line_1 === '') {
      toastMessage('error', 'Location is required')
      return 0
    }
    // data.company_name = data?.company_name?.label

    setExperiences((pre) => {
      const newArray = pre.filter((obj) => obj.id !== data.id)
      return [data, ...newArray]
    })
    handleOnClose()
  }

  useEffect(() => {
    if (editExperienceData) {
      Object.keys(editExperienceData).map((key) => {
        if (key === 'company_name') {
          setValue(key, createOption(editExperienceData[key], editExperienceData[key]))
        } else {
          setValue(key, editExperienceData[key])
        }
        return 0
      })
    } else {
      reset()
    }
  }, [editExperienceData, setValue, reset])

  useEffect(() => {
    if (search?.length > 0) setCompanyOptions(companyList)
  }, [companyList.length > 0])

  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Experience'} onClose={handleOnClose} />
        <form onSubmit={handleSubmit(onSubmit)} id={'experienceForm'}>
          <Container className={classes.dialogbody} direction={'column'} gap={'10px 0'}>
            <Controller
              name={'title'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  label={'Title*'}
                  name={name}
                  placeholder={'Position'}
                  type="text"
                  value={value}
                  onChange={onChange}
                  error={getFormError(errors, name)}
                  className={classes.nameFiled}
                />
              )}
            />
            <Controller
              name="company_name"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                // <Select
                //   id="companyOptions"
                //   label="Company Name"
                //   options={companyOptions}
                //   name={name}
                //   value={value}
                //   placeholder={'Select Company'}
                //   onChange={onChange}
                //   onInputChange={(e) => {
                //     onSearch(e)
                //   }}
                //   ref={ref}
                // />
                <SelectCreateable
                  options={companyOptions}
                  id={'companyName'}
                  placeholder={'Select'}
                  name={name}
                  value={value}
                  // onChange={(event) => setCompanyNewName({ label: event?.label, id: event?.value || '' })}
                  onChange={onChange}
                  // ref={ref}
                  label={'Company Name'}
                  onInputChange={(e) => {
                    onSearch(e)
                  }}
                  isCompay
                  menuPosition={'fixed'}
                />
              )}
            />

            <Controller
              name={'location'}
              control={control}
              render={({ field: { name, value } }) => (
                <GooglePlaces
                  id={name}
                  name={name}
                  label="Location"
                  spacing={3}
                  onClearInput={() => setValue('addr_line_1', '')}
                  placeholder={'e.g. 4517 Washington Ave'}
                  value={
                    value ||
                    (getValues()?.addr_line_1 &&
                      getValues()?.addr_line_2 &&
                      getWellKnownAddress(getValues()?.addr_line_1, getValues()?.addr_line_2))
                  }
                  onSelect={(loc) => {
                    setAddressValues(loc.addr, loc.addr_line_1, loc.addr_line_2)
                  }}
                />
              )}
            />

            <Container flex gap={'20px'}>
              <Controller
                name="start_date"
                control={control}
                render={({ field: { name, value } }) => (
                  <MonthYearPicker
                    views={['year', 'month']}
                    label="Start Date"
                    maxDate={new Date()}
                    value={value}
                    onChange={(newValue) => {
                      setValue(name, newValue)
                    }}
                  />
                )}
              />
              <Controller
                name="end_date"
                control={control}
                render={({ field: { name, value } }) => (
                  <MonthYearPicker
                    views={['year', 'month']}
                    label="End Date"
                    minDate={getValues().start_date || startDate}
                    maxDate={new Date()}
                    value={value}
                    onChange={(newValue) => {
                      setValue(name, newValue)
                    }}
                  />
                )}
              />
            </Container>
            <Controller
              name={'currently_working'}
              control={control}
              render={({ field: { name, value } }) => (
                <Checkbox
                  id={name}
                  name={name}
                  value={value}
                  label={'I’m currently working here'}
                  onChange={(e) => setValue(name, e.target.checked)}
                />
              )}
            />
          </Container>

          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button type="submit" width={70}>
                Add
              </Button>
            </Container>
          </DialogFooter>
        </form>
      </Dialog>
    </Container>
  )
}
ExperinceDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  setExperiences: PropTypes.func,
  editExperienceData: PropTypes.object,
  setEditExperienceData: PropTypes.func,
}

export default ExperinceDialog
