import { getSupplierList } from 'common-api/super-admin/superAdmin'
import useToastify from 'common-hooks/useToastify'
import { useState } from 'react'
import useSWR from 'swr'
import { getSize } from 'utils/constants'

export default function useLandingPageSupplierList() {
  const [search, onSearch] = useState('')
  const queryParams = new URLSearchParams()
  const PAGE_SIZE = getSize()
  const { toastMessage } = useToastify()
  const [page, setPage] = useState(1)
  const { data, mutate } = useSWR(() => {
    if (search === '') {
      return `/services/supplier/active-list?page=${page}&limit=${PAGE_SIZE}`
    }
    if (search !== '') {
      queryParams.append('search', search)
      const url = `/services/supplier/active-list?${queryParams.toString()}&page=${page}&limit=${PAGE_SIZE}`
      return url
      // return `/services/supplier-list?search=${search}&page=${page}&limit=${PAGE_SIZE}`
    }
    return null
  }, getSupplierList)

  if (data && data.length === 0 && page > 1) {
    toastMessage('error', 'There are no more suppliers')
    setPage((pre) => pre - 1)
  }

  // `/services/supplier-list?search=${search}&page=${page}&limit=${limit}`, getSupplierList)
  return {
    supplierList: data,
    search,
    listMutate: mutate,
    isLoading: !data,
    onSearch,
    setPage,
    page,
  }
}
