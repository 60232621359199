import { makeStyles } from '@mui/styles'
import Button from 'components/common/Button'
import Typography from 'components/common/Typography'
import React, { useState } from 'react'
import { getColor } from 'theme/colors'
import propTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  viewMore: {
    fontWeight: '400',
    height: 'unset',
    padding: '0',
    minHeight: 'unset',
    '&:hover': {
      color: getColor('func-info'),
      backgroundColor: '#fff',
    },
    '& span': {
      color: getColor('func-info'),
      textDecoration: 'underline',
      padding: '0',
    },
  },
}))
const ReadMoreContentToggle = ({ children, wordSlice }) => {
  const [isReadMore, setIsReadMore] = useState(true)
  const text = children
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  const classes = useStyles()
  return (
    <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
      {isReadMore ? `${text.slice(0, wordSlice)}...` : `${text}  `}
      {children?.length > wordSlice && (
        <Button className={classes.viewMore} variant="transparent" onClick={toggleReadMore}>
          {isReadMore ? ' read more ' : ' read less '}
        </Button>
      )}
    </Typography>
  )
}

export default ReadMoreContentToggle

ReadMoreContentToggle.propTypes = {
  children: propTypes.node,
  wordSlice: propTypes.number,
}
