import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import { makeStyles } from '@mui/styles'
import Popover from 'components/common/popover/Popover'
import { NoNotificationsIcon } from 'assets/icons'
import { getColor } from 'theme/colors'
import Spacing from 'components/common/Spacing'
import DialogHead from 'components/custom/dialog/DialogHead'
import useToggle from 'common-hooks/useToggle'
import QuickInfoDialog from 'containers/supplier-quotes/QuickInfoDialog'

import NotificationCard from './NotificationCard'
import EmptyContainer from './EmptyContainer'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 340,
    height: 535,
    overflowY: 'auto',
    '&::-webkit-scrollbar': { width: 0 },
    border: '1px solid',
    borderColor: getColor('grey-g95'),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  main: {
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    top: '46px !important',
  },

  notificationgrid: {
    height: '650px',
    overflow: 'auto',
    paddingTop: '60px',
    '&::-webkit-scrollbar': { width: 0 },
  },
  markAll: {
    padding: theme.spacing(2.5, 5),
  },
  dialogHead: {
    position: 'absolute',
    width: '340px',
  },
}))

export default function NotificationsDialog({ open, onClose, anchorEl }) {
  const classes = useStyles()
  //   Brightspot Equipment Rental invited you to send a quote for John Deere - Bucket Cylinder - LW8924656
  const [toggle, setToggle] = useToggle(false)
  const data = [
    {
      id: '7c786eb6-53a3-4082-a40e-3e24125c5b94',
      userName: 'Brightspot Equipment Rental',
      message: 'invited you to send a quote for',
      productName: 'John Deere -',
      partName: 'Bucket Cylinder -',
      partSerialNumber: 'LW8924656',
      date: '5 mints ago',
    },

    {
      id: '7c786eb6-53a3-4082-a40e-3e24125c5b94',
      userName: 'Brightspot Equipment Rental',
      message: 'invited you to send a quote for',
      productName: 'John Deere -',
      partName: 'Bucket Cylinder -',
      partSerialNumber: 'LW8924656',
      date: '5 mints ago',
    },
    {
      id: '7c786eb6-53a3-4082-a40e-3e24125c5b94',
      userName: 'Brightspot Equipment Rental',
      message: 'invited you to send a quote for',
      productName: 'John Deere -',
      partName: 'Bucket Cylinder -',
      partSerialNumber: 'LW8924656',
      date: '5 mints ago',
    },
    {
      id: '7c786eb6-53a3-4082-a40e-3e24125c5b94',
      userName: 'Brightspot Equipment Rental',
      message: 'invited you to send a quote for',
      productName: 'John Deere -',
      partName: 'Bucket Cylinder -',
      partSerialNumber: 'LW8924656',
      date: '5 mints ago',
    },
    {
      id: '7c786eb6-53a3-4082-a40e-3e24125c5b94',
      userName: 'Brightspot Equipment Rental',
      message: 'invited you to send a quote for',
      productName: 'John Deere -',
      partName: 'Bucket Cylinder -',
      partSerialNumber: 'LW8924656',
      date: '5 mints ago',
    },
    {
      id: '7c786eb6-53a3-4082-a40e-3e24125c5b94',
      userName: 'Brightspot Equipment Rental',
      message: 'invited you to send a quote for',
      productName: 'John Deere -',
      partName: 'Bucket Cylinder -',
      partSerialNumber: 'LW8924656',
      date: '5 mints ago',
    },
    {
      id: '7c786eb6-53a3-4082-a40e-3e24125c5b94',
      userName: 'Brightspot Equipment Rental',
      message: 'invited you to send a quote for',
      productName: 'John Deere -',
      partName: 'Bucket Cylinder -',
      partSerialNumber: 'LW8924656',
      date: '5 mints ago',
    },
  ]
  return (
    <>
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOriginVertical="bottom"
        anchorOriginHorizontal="center"
        transformOriginVertical="top"
        transformoriginhorizontal="center"
        paperClassName={classes.main}
      >
        <Container container direction="column" className={classes.root}>
          <DialogHead divider title="Notification" onClose={onClose} className={classes.dialogHead} />
          <Container height={'500px'} className={classes.notificationgrid}>
            {data.length ? (
              <Container flex direction="column">
                {data.map((row, index) => (
                  <Container onClick={() => setToggle(true)} key={index}>
                    <NotificationCard
                      message={row.message}
                      date={row.date}
                      userName={row.userName}
                      productName={row.productName}
                      partSerialNumber={row.partSerialNumber}
                      partName={row.partName}
                    />
                  </Container>
                ))}
              </Container>
            ) : (
              <EmptyContainer
                imgComponent={
                  <Spacing bottom={1.5}>
                    <NoNotificationsIcon />
                  </Spacing>
                }
                title="We’ll send a notification here"
              />
            )}
          </Container>
        </Container>
      </Popover>
      <QuickInfoDialog open={toggle} onclose={setToggle} />
    </>
  )
}

NotificationsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
}
