import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Tooltip from 'components/common/Tooltip'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getColor } from 'theme/colors'
import typography from 'theme/typography'

const useStyles = makeStyles(() => ({
  root: {
    // padding: theme.spacing(0, 2),
    padding: '10px',
    margin: '0 10px',
    height: 44,
    borderRadius: '4px',
    // backgroundColor: getColor('dark-d25'),
    width: '220px',
    '&:hover': {
      backgroundColor: '#4A4D68',
    },
    backgroundColor: (props) => (props.active ? '#4A4D68' : ''),
  },
  root2: {
    padding: '10px',
    margin: '0 10px',
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    // backgroundColor: getColor('dark-d25'),
    width: '50px',
    '&:hover': {
      backgroundColor: '#4A4D68',
    },
    backgroundColor: (props) => (props.active ? '#4A4D68' : ''),
  },
  icon: (props) => ({
    minWidth: 'auto',
    // padding: theme.spacing(2, 3),
    paddingRight: '10px',
    borderRadius: 5,
    '& > svg path': {
      fill: props.active ? getColor('light') : '',
    },
  }),
  icon1: {
    minWidth: 'auto',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 5,
    '& > svg path': {
      fill: (props) => (props.active ? getColor('light') : ''),
    },
  },
  text: {
    margin: 0,
  },
  textPrimary: {
    fontWeight: typography.body2.fontWeight,
    fontSize: typography.body2.fontSize,
    // eslint-disable-next-line no-nested-ternary
    color: (props) => (props.active ? getColor('light') : props.inactiveList ? '#606280' : getColor('grey-g65')),
  },
}))

export default function AppDrawerList({ children, href, Icon, active, textVisible, inactiveList }) {
  const classes = useStyles({ active, textVisible, inactiveList })

  return (
    <ListItem button component={Link} to={href} className={textVisible ? classes.root : classes.root2}>
      {!textVisible ? (
        <Tooltip placement={'right-start'} title={children}>
          <ListItemIcon className={classes.icon1}>
            <Icon />
          </ListItemIcon>
        </Tooltip>
      ) : (
        <ListItemIcon className={classes.icon}>
          <Icon />
        </ListItemIcon>
      )}
      {textVisible && (
        <ListItemText primary={children} classes={{ root: classes.text, primary: classes.textPrimary }} />
      )}
    </ListItem>
  )
}

AppDrawerList.propTypes = {
  children: PropTypes.string,
  href: PropTypes.string,
  Icon: PropTypes.object,
  active: PropTypes.bool,
  textVisible: PropTypes.bool,
  inactiveList: PropTypes.bool,
}
