import PropTypes from 'prop-types'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
// import useSearch from 'common-hooks/useSearch'
import SearchField from 'components/common/search/SearchField'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import Chip from 'components/common/Chip'
import Button from 'components/common/Button'
import LabeledImageCard from 'containers/buyer-quotes/LabeledImageCard'
import useEquipmentsList from 'common-hooks/create-quote/useEquipmentsList'
import useIndustryList from 'common-hooks/useIndustryList'
import { Tabs } from 'components/common/Tabs'
import { CreateSupplierContext } from '../super-admin/supplier-mgt/SupplierUtils'

const useStyle = makeStyles(() => ({
  root: {
    height: '840px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  main: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabLabel: {
    padding: '0px 0px',
    height: '24px',
    fontFamily: 'Lexend Deca',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: getColor('grey-g35'),
    '& .MuiTabs-indicator': {
      backgroundColor: 'orange',
      height: 3,
    },
    '& .MuiTab-root.Mui-selected': {
      color: 'red',
    },
  },
  dailogBody: {
    height: 670,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    backgroundColor: getColor('light'),
  },
  gridContainer: {
    gridTemplateColumns: 'auto auto auto',
    padding: '10px',
    '& div': {
      display: 'block',
    },
  },
  gridItem: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'lightblue',
    border: '1px solid black',
  },
  mainBox: {
    padding: '10px',
  },
  gridMediaContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '579px',
    overflowY: 'scroll',
    flexDirection: 'column',
    backgroundColor: getColor('light'),
    padding: 0,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  body: {
    backgroundColor: getColor('light'),
    padding: '0px 30px 0 30px',
  },
  popular: {
    alignItems: 'unset',
    gap: '10px 0',
    marginBottom: '10px',
  },
  populargrid: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '20px',
    // width: '780px',
  },
  bodyheaderbg: {
    backgroundColor: getColor('system-bg'),
    padding: '0 30px',
    paddingTop: '21px',
    paddingBottom: '21px',
    position: 'absolute',
    width: '100%',
    zIndex: '999',
  },
  search: {
    '& input': {
      borderRadius: 'unset',
    },
    padding: '20px 30px 20px 0px',
  },
  tabs: {
    padding: '20px 0px 20px 30px',
  },
  infotooltip: {
    padding: '0 30px',
    backgroundColor: getColor('system-bg'),
    '& div': {
      padding: '8px 0',
    },
  },
  dailogFooter: {
    padding: '20px 30px',
    maxheight: '100px',
    position: 'absolute',
    bottom: 72,
    width: '100%',
    backgroundColor: getColor('light'),
    display: 'flex',
    gap: '10px',
    justify: 'flex-start',
    flexFlow: 'wrap',
  },
  padding: {
    padding: '20px 0',
  },
  body2: {
    padding: '0px 30px 100px 30px',

    backgroundColor: getColor('light'),
  },
  cancelBtn: {
    border: '  1px solid #9799B5',
  },
}))

export default function AddEquipmentDialog({ open, onClose, setEquips, filterActiveCard }) {
  const classes = useStyle()
  // const { search, onSearch } = useSearch()
  const [selectedItems, setSelectedItems] = useState([])
  const [activeCards, setActiveCards] = useState([])
  const [tab, setTab] = useState(0)

  const [industryId, setIndustryId] = useState()

  const { industryData } = useIndustryList()
  const { equipmentsList, onSearchEqup, searchEqup } = useEquipmentsList(industryId)

  const { onSelectNode, specializationTreeViewData } = useContext(CreateSupplierContext)

  const selectEquipment = (type, id, name) => {
    setEquips((eq) => {
      if (type === 'add' && !activeCards.includes(id)) {
        const arr = eq.equips
        arr.push(id)
        eq.equips = [...new Set(arr)]
      } else {
        eq.del_equips.push(id)
        eq.del_equips = [...new Set(eq.del_equips)]
        eq.equips = eq?.equips?.filter((e) => e !== id)
      }
      return eq
    })
    setSelectedItems((pre) => {
      if (type === 'add' && !activeCards.includes(id)) {
        pre.push({ id, name, children: [{}] })
      } else {
        pre.splice(
          pre.findIndex((e) => e.id === id),
          1
        )
      }
      setActiveCards(pre.map((obj) => obj.id))
      return pre.filter((item, index) => pre.indexOf(item) === index)
    })
  }

  const addEquipments = () => {
    onSelectNode(selectedItems)
    onClose()
  }

  const changeTab = (e, val) => {
    setTab(val)
    setIndustryId(industryData[val].id)
  }

  useEffect(() => {
    if (industryData) {
      setIndustryId(industryData?.[0]?.id)
    }

    if (specializationTreeViewData) {
      const arr = []
      const ids = []
      specializationTreeViewData?.map((obj) => {
        arr.push({ id: obj.id, name: obj.name, children: obj.children })
        ids.push(obj.id)
        return 0
      })
      setSelectedItems(arr)
      setActiveCards(ids)
    }
  }, [industryData, specializationTreeViewData])

  useEffect(() => {
    if (filterActiveCard) {
      setActiveCards(activeCards?.filter((obj) => obj !== filterActiveCard))
      setSelectedItems(selectedItems?.filter((obj) => obj.id !== filterActiveCard))
    }
  }, [filterActiveCard])

  return (
    <>
      <Dialog open={open} size={'5.5xl'} className={classes.main}>
        <Container className={classes.dailogHeader}>
          <DialogHead onClose={onClose}>
            <Typography>{'Equipment'}</Typography>
          </DialogHead>
        </Container>

        <Container className={classes.dailogBody}>
          <Container padding="0 30px">
            <Container flex direction="row" justify="flex-start" gap={'15px'}>
              <Container className={classes.tabs}>
                <Tabs value={tab} onchange={changeTab} array={industryData} />
              </Container>
              <Container width="250px">
                <SearchField
                  id="search-feild"
                  className={classes.search}
                  border={'unset'}
                  placeholder={'Search equipment'}
                  onSearch={(e) => {
                    onSearchEqup(e)
                  }}
                  value={searchEqup}
                />
              </Container>
            </Container>

            <Container className={selectedItems.length > 0 ? classes.body2 : classes.body}>
              <div className={classes.gridMediaContainer}>
                <Container flex direction={'column'} className={classes.popular}>
                  <Container className={classes.populargrid}>
                    {equipmentsList?.map((eItem, index) => (
                      <LabeledImageCard
                        key={index}
                        {...eItem}
                        value={activeCards.includes(eItem.id) ? eItem.id : ''}
                        onChange={() => selectEquipment('add', eItem.id, eItem.name)}
                      />
                    ))}
                  </Container>
                </Container>
              </div>
            </Container>
            {selectedItems.length > 0 && (
              <Container id="height" className={classes.dailogFooter}>
                {selectedItems.map((chip) => (
                  <Chip
                    key={chip.id}
                    label={chip.name}
                    removable
                    onRemove={() => selectEquipment('remove', chip.id, chip.name)}
                  />
                ))}
              </Container>
            )}
          </Container>
        </Container>

        <DialogFooter>
          <Container flex justify="flex-end" gap={'0 10px'}>
            <Button variant="transparent" className={classes.cancelBtn} width={77} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" width={58} onClick={addEquipments}>
              Add
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </>
  )
}

AddEquipmentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setEquips: PropTypes.func,
  filterActiveCard: PropTypes.array,
}
