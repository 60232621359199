import { getDetailQuoteList } from 'common-api/buyer-supplier/buyerSupplier'
import useSWR from 'swr'

export default function useDetailQuoteList(id) {
  const { data, mutate } = useSWR(`/quote-request/list/${id}`, getDetailQuoteList)
  return {
    detailQuoteList: data,
    isLoading: !data,
    mutate,
  }
}
