import { getCantParticipateList, putNudge } from 'common-api/buyer-supplier/buyerSupplier'
import useToastify from 'common-hooks/useToastify'
import useSWR from 'swr'

export default function useCantParticipateList(id) {
  const { toastMessage } = useToastify()
  const { data } = useSWR(
    `/quote-invite/quote/list?quote_id=${id}&quote_status=can_not_participate`,
    getCantParticipateList
  )

  const nudgeMail = async (id) => {
    try {
      const response = await putNudge(id)
      toastMessage('success', 'Supplier reminded of your quote request')
      return response
    } catch {
      return false
    }
  }

  return {
    cantparticipateList: data,
    nudgeMail,
  }
}
