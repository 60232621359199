import { makeStyles } from '@mui/styles'
import { CaretLeftIcon, PencilIcon, ShipingFromIcon, ShipingToIcon } from 'assets/icons'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Link from 'components/common/Link'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import { useState, useEffect, React } from 'react'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import { getColor } from 'theme/colors'
import IconButton from 'components/common/IconButton'
import { formatDate, DATE_FORMAT_PRIMARY, DATE_FORMAT_COMMA } from 'utils/dateUtils'
import useToggle from 'common-hooks/useToggle'
import useOrderDetails from 'common-hooks/orders/useOrderDetails'
import PurchaseOrderNumberDialog from './PurchaseOrderNumberDialog'
import HelpDialog from './HelpDialog'
import OrderCancelDialog from './OrderCancelDialog'
import ContactSellerDialog from './ContactSellerDialog'
import { formatPrice, getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  orderDetailBody: {
    backgroundColor: '#fff',
    // padding: '30px',
  },
  orderInfoCardGrid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ordeInfoCard: {
    border: '1px solid #F0F0F4',
    width: '800px',
    padding: '20px',
    margin: '0 auto',
  },
  blueLink: {
    color: '#2680D9',
    '& a': {
      color: '#2680D9',
    },
  },
  orderInfoCardRight: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px 0',
    width: '330px',
  },
  btnBottm: {
    marginTop: '4px',
  },
  orderInfoSmallGrid: {
    gap: '0 10px',
    '& p:first-child': {
      width: '172px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& p': {
      width: '150px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  orderLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px 0',
    padding: '30px',
    width: '73%',
    // maxWidth: '910px',
  },
  shpingadress: {
    paddingLeft: '33px',
    paddingTop: '5px',
  },
  shpingadress1: {
    paddingLeft: '21px',
    marginLeft: '11px',
    borderLeft: ' 1px dashed #C3C5D5',
    paddingTop: '5px',
    paddingBottom: '28px',
  },
  tableHeader: {
    display: 'flex',
    paddingBottom: '13px',
    borderBottom: '1px solid #F0F0F4',
    '& .col1': {
      width: '624px',
    },
    '& .col2': {
      width: '30px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .col3': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .col4': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  rowRoot: {
    display: 'flex',
    paddingTop: '10px',
    '& .row1': {
      width: '624px',
    },
    '& .row2': {
      width: '30px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .row3': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .row4': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  productTable: {
    paddingTop: '13px',
    paddingBottom: '13px',
    borderBottom: '1px solid #F0F0F4',
    width: '800px',
  },

  totolRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '30px',
    width: '800px',
    paddingTop: '10px',
    '& p:first-child': {
      width: '109px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    '& p': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  orderGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '96vh',
  },
  soldProfileRoot: {
    display: 'flex',
    gap: '0 10px',
    paddingTop: '8px',
    alignItems: 'flex-start',

    '& img': {
      width: '60px',
      height: '60px',
      borderRadius: '4px',
      objectFit: 'cover',
    },
  },
  orderRight: {
    padding: '30px',
    background: '#F8F9F9',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px 0',
    width: '27%',
  },
  p8: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  cancleOrderBtn: {
    border: '1px solid #9799B5',
    color: '#4A4D68',
  },
  contanctUS: {
    paddingTop: '8px',
  },
  help: {
    paddingTop: '18px',
    color: '#36384C',
  },
  shippingAddress: {
    width: '800px',
    margin: '0 auto',
  },
  productDetail: {
    width: '800px',
    margin: '0 auto',
  },
  separatorWidth: {
    width: '800px',
    margin: '0 auto',
  },
  helpRoot: {
    height: '52vh',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  IconButton: {
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  pencilIcon: {
    display: 'flex',
    alignItems: 'center',
    color: '#626583',
  },
  blueLinkBtn: {
    width: 'unset',
    height: '15px',
    minHeight: 'unset',
    background: 'unset',
    color: '#2680D9',
    padding: 'unset',
    borderBottom: '1px solid #2680D9',
    borderRadius: 'unset',
    '&:hover': {
      background: 'unset',
      color: '#2680D9',
    },
  },
  loadingOverlay: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
  },
}))

export default function BuyerOrderShipDetails() {
  const classes = useStyles()
  const navigate = useNavigate()
  const param = useParams()
  const { orderDetailsData, isLoading, mutate } = useOrderDetails(param.id)

  const oderDetails = orderDetailsData

  const [editPonNumber, setEditPoNumber] = useState()
  const [contactDeliveryDialog, setConatctDeliveryDialog] = useToggle(false)
  const [purchaseOrderDialog, setPurchaseOrderDialog] = useToggle(false)
  const [helpDialog, setHelpDialog] = useToggle(false)
  const [orderCancelDialog, setOrderCancelDialog] = useToggle(false)

  useEffect(() => {
    if (oderDetails) {
      setEditPoNumber(oderDetails?.po_number)
    }
  }, [oderDetails])

  const sellerName = orderDetailsData?.seller_full_name.split(' ')[0]

  return (
    <Container className={classes.orderDetailBody}>
      {isLoading && (
        <Container className={classes.loadingOverlay}>
          <LoadingOverlay />
        </Container>
      )}
      {!isLoading && (
        <Container className={classes.orderDetailBody}>
          <Container className={classes.orderDetailInner}>
            <Container className={classes.orderGrid}>
              <Container className={classes.orderLeft}>
                <Container className={classes.orderTitle} flex justify="flex-start" gap={'0 20px'}>
                  <Button
                    variant={'secondary'}
                    border
                    icon={<CaretLeftIcon />}
                    onClick={() => navigate('/orders', { state: { status: oderDetails?.order_status } })}
                  />
                  <Typography variant="h1" color={getColor('grey-g25')}>
                    Order ID: {param.orderid}
                  </Typography>
                </Container>
                <Container className={classes.ordeInfoCard}>
                  <Container className={classes.orderInfoCardGrid}>
                    <Container className={classes.orderInfoCardLeft}>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {/* {oderDetails?.order_status === 'shipped' || oderDetails?.order_status === 'awaiting_shipment'
                          ? 'Order is'
                          : ''} */}
                      </Typography>
                      <Typography variant="h2" color={getColor('grey-g25')} component={'p'}>
                        {oderDetails?.order_status === 'shipped' && oderDetails?.order_pickup === false && 'Shipped'}
                        {oderDetails?.order_status === 'awaiting_shipment' && 'Awaiting Shipment'}
                        {oderDetails?.order_status === 'cancelled' && 'Order Cancelled'}
                        {oderDetails?.order_status === 'shipped' && oderDetails?.order_pickup === true
                          ? 'Ready For Pickup'
                          : ''}
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {orderDetailsData?.order_status === 'shipped' && orderDetailsData?.ordered_shipped_date
                          ? `on ${formatDate(oderDetails?.ordered_shipped_date, DATE_FORMAT_COMMA)}`
                          : ''}
                        {orderDetailsData?.order_status === 'cancelled' && orderDetailsData?.ordered_cancelled_date
                          ? `on ${formatDate(oderDetails?.ordered_cancelled_date, DATE_FORMAT_COMMA)}`
                          : ''}
                      </Typography>
                      <Container className={classes.btnBottm}>
                        {Object.keys(oderDetails?.delivery_options).length > 1 ? (
                          <>
                            {oderDetails?.delivery_options?.freight_next_day &&
                              oderDetails?.order_status === 'shipped' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Tracking Number: {oderDetails?.tracking_no}{' '}
                                </Typography>
                              )}
                            {/* {oderDetails?.delivery_options?.freight_next_day &&
                              oderDetails?.order_status === 'awaiting_shipment' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Tracking Number:-
                                </Typography>
                              )} */}
                            {oderDetails?.delivery_options?.freight_next_day &&
                              oderDetails?.order_status === 'cancelled' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Tracking Number: {oderDetails?.tracking_no}{' '}
                                </Typography>
                              )}
                          </>
                        ) : (
                          <>
                            {!oderDetails?.delivery_options?.mdo_local_delivery &&
                              !oderDetails?.delivery_options?.mdo_pick_up &&
                              oderDetails?.order_status === 'shipped' && (
                                <>
                                  {/* <Typography variant="body1" color={getColor('grey-g35')}>
                                    Tracking Number: {oderDetails?.tracking_no}{' '}
                                  </Typography> */}
                                  {Object.keys(oderDetails?.delivery_options).length > 1 ? (
                                    <>
                                      {oderDetails?.order_status === 'awaiting_shipment' ? (
                                        ''
                                      ) : (
                                        <Container
                                          flex
                                          justify="flex-start"
                                          // height="33px"
                                          // className={classes.orderInfoSmallGrid}
                                        >
                                          <Typography variant="body1" color={getColor('grey-g45')}>
                                            Shipped via:
                                          </Typography>
                                          <Typography variant="body1" color={getColor('grey-g45')}>
                                            {' '}
                                            {oderDetails?.courier_name ? (
                                              <>
                                                {' '}
                                                {oderDetails?.courier_name} • {oderDetails?.tracking_no}
                                              </>
                                            ) : (
                                              'Not shipped yet'
                                            )}
                                          </Typography>
                                        </Container>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {oderDetails?.order_status === 'awaiting_shipment' ? (
                                        ''
                                      ) : (
                                        <>
                                          {!oderDetails?.delivery_options?.mdo_local_delivery &&
                                            !oderDetails?.delivery_options?.mdo_pick_up && (
                                              <Container
                                                flex
                                                justify="flex-start"
                                                // height="33px"
                                                // className={classes.orderInfoSmallGrid}
                                              >
                                                <Typography
                                                  variant="body1"
                                                  color={getColor('grey-g45')}
                                                  // component={'p'}
                                                >
                                                  Shipped via:
                                                </Typography>
                                                <Typography
                                                  variant="body1"
                                                  color={getColor('grey-g45')}
                                                  // component={'p'}
                                                >
                                                  {' '}
                                                  {oderDetails?.courier_name ? (
                                                    <>
                                                      {oderDetails?.courier_name} • {oderDetails?.tracking_no}
                                                    </>
                                                  ) : (
                                                    'Not shipped yet'
                                                  )}
                                                </Typography>
                                              </Container>
                                            )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                            {/* {!oderDetails?.delivery_options?.mdo_local_delivery &&
                              !oderDetails?.delivery_options?.mdo_pick_up &&
                              oderDetails?.order_status === 'cancelled' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Tracking Number: {oderDetails?.tracking_no}{' '}
                                </Typography>
                              )} */}

                            {/* {oderDetails?.delivery_options?.mdo_local_delivery &&
                              oderDetails?.order_status === 'cancelled' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                 
                                  Order Deliver on
                                </Typography>
                              )} */}
                            {oderDetails?.delivery_options?.mdo_pick_up && oderDetails?.order_status === 'cancelled' && (
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                {''}
                              </Typography>
                            )}

                            {oderDetails?.delivery_options?.mdo_local_delivery &&
                              oderDetails?.order_status === 'shipped' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Order Deliver on {formatDate(oderDetails?.deliver_on, DATE_FORMAT_COMMA)} between{' '}
                                  {oderDetails?.delivered_time?.start} to {oderDetails?.delivered_time?.end}
                                </Typography>
                              )}
                            {oderDetails?.delivery_options?.mdo_pick_up && oderDetails?.order_status === 'shipped' && (
                              <Typography variant="body1" color={getColor('grey-g35')}>
                                Order ready for pickup
                              </Typography>
                            )}
                            {/* 
                            {oderDetails?.delivery_options?.mdo_local_delivery &&
                              oderDetails?.order_status === 'awaiting_shipment' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Order Deliver on :-
                                </Typography>
                              )} */}
                            {oderDetails?.delivery_options?.mdo_pick_up &&
                              oderDetails?.order_status === 'awaiting_shipment' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  {`(Not ready for pickup)`}
                                </Typography>
                              )}
                            {/* {!oderDetails?.delivery_options?.mdo_local_delivery &&
                              !oderDetails?.delivery_options?.mdo_pick_up &&
                              oderDetails?.order_status === 'awaiting_shipment' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Tracking Number:-
                                </Typography>
                              )} */}
                          </>
                        )}
                      </Container>
                    </Container>
                    <Container className={classes.orderInfoCardRight}>
                      <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                        <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                          Ordered On:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                          {/* mon. dd, yyyy */}
                          {formatDate(oderDetails?.ordered_date, DATE_FORMAT_PRIMARY)}
                        </Typography>
                      </Container>

                      {/* {oderDetails?.order_status === 'shipped' ? (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography component={'p'}>
                            <IconButton className={classes.IconButton} onClick={setPurchaseOrderDialog}>
                              <PencilIcon />
                            </IconButton>
                            <Typography variant="body1" color={getColor('grey-g65')} className={classes.pencilIcon}>
                              PO Number:
                            </Typography>
                          </Typography>
                          <Typography variant="body1" color={getColor('grey-g65')} component={'p'}>
                            {oderDetails?.po_number ? oderDetails?.po_number : '-'}
                          </Typography>
                        </Container>
                      ) : (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography variant="body1" color={getColor('grey-g65')} component={'p'}>
                            PO Number:
                          </Typography>
                          {oderDetails?.po_number ? (
                            <Typography variant="body1" color={getColor('grey-g65')} component={'p'}>
                              {oderDetails?.po_number}
                            </Typography>
                          ) : (
                            !oderDetails?.order_status === 'cancelled' && (
                              <Button className={classes.blueLinkBtn} onClick={() => setPurchaseOrderDialog()}>
                                Add
                              </Button>
                            )
                          )}
                        </Container>
                      )} */}

                      {oderDetails?.order_status === 'shipped' || oderDetails?.order_status === 'awaiting_shipment' ? (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography component={'p'}>
                            {oderDetails?.po_number && (
                              <IconButton className={classes.IconButton} onClick={setPurchaseOrderDialog}>
                                <PencilIcon />
                              </IconButton>
                            )}
                            <Typography variant="body1" color={getColor('grey-g45')} className={classes.pencilIcon}>
                              PO Number:
                            </Typography>
                          </Typography>
                          {oderDetails?.po_number ? (
                            <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                              {oderDetails?.po_number}
                            </Typography>
                          ) : (
                            <Button className={classes.blueLinkBtn} onClick={() => setPurchaseOrderDialog()}>
                              Add
                            </Button>
                          )}
                        </Container>
                      ) : (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                            PO Number:
                          </Typography>
                          <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                            {oderDetails?.po_number ? oderDetails?.po_number : '-'}
                          </Typography>
                        </Container>
                      )}
                      {oderDetails?.order_status === 'awaiting_shipment' ? (
                        ''
                      ) : (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography
                            variant="body1"
                            color={getColor('grey-g45')}
                            component={'p'}
                            className={classes.pencilIcon}
                          >
                            Sales Order Number:
                          </Typography>
                          <Typography
                            variant="body1"
                            color={getColor('grey-g45')}
                            component={'p'}
                            className={classes.pencilIcon}
                          >
                            {oderDetails?.sales_order_number ? oderDetails?.sales_order_number : '-'}
                          </Typography>
                        </Container>
                      )}

                      {/* {Object.keys(oderDetails?.delivery_options).length > 1 ? (
                        <>
                          {oderDetails?.order_status === 'awaiting_shipment' ? (
                            ''
                          ) : (
                            <Container
                              flex
                              justify="space-between"
                              height="33px"
                              className={classes.orderInfoSmallGrid}
                            >
                              <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                                Shipped via:
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                                {oderDetails?.courier_name ? oderDetails?.courier_name : 'Not shipped yet'}
                              </Typography>
                            </Container>
                          )}
                        </>
                      ) : (
                        <>
                          {oderDetails?.order_status === 'awaiting_shipment' ? (
                            ''
                          ) : (
                            <>
                              {!oderDetails?.delivery_options?.mdo_local_delivery &&
                                !oderDetails?.delivery_options?.mdo_pick_up && (
                                  <Container
                                    flex
                                    justify="space-between"
                                    height="33px"
                                    className={classes.orderInfoSmallGrid}
                                  >
                                    <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                                      Shipped via:
                                    </Typography>
                                    <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                                      {oderDetails?.courier_name ? oderDetails?.courier_name : 'Not shipped yet'}
                                    </Typography>
                                  </Container>
                                )}
                            </>
                          )}
                        </>
                      )} */}
                    </Container>
                  </Container>
                </Container>
                <Container className={classes.shippingAddress}>
                  <Container>
                    <Container flex justify="flex-start" gap={'0 9px'}>
                      <ShipingFromIcon />
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {oderDetails?.delivery_options?.mdo_pick_up ? 'Pickup Address' : 'Shipping From'}
                      </Typography>
                    </Container>
                    <Container className={classes.shpingadress1}>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {oderDetails?.shipping_from_addr_line_1 ? oderDetails?.shipping_from_addr_line_1 : 'AGA Parts '}
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {oderDetails?.shipping_from_addr_line_2 ? (
                          <>
                            {getWellKnownAddress(
                              oderDetails?.shipping_from_addr_line_2,
                              oderDetails?.shipping_from_city,
                              oderDetails?.shipping_from_state,
                              oderDetails?.shipping_from_country,
                              oderDetails?.shipping_from_zipcode
                            )}
                          </>
                        ) : (
                          '2972 Westheimer Rd. Santa Ana, Illinoise - 85486'
                        )}
                      </Typography>
                    </Container>
                  </Container>
                  <Container>
                    <Container flex justify="flex-start" gap={'0 9px'}>
                      <ShipingToIcon />
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {oderDetails?.delivery_options?.mdo_pick_up ? 'Buyer Address' : '  Shipping To'}
                      </Typography>
                    </Container>
                    <Container className={classes.shpingadress}>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {oderDetails?.shipping_to_addr_line_1
                          ? oderDetails?.shipping_to_addr_line_1
                          : ' Brightspot Equipment Rental '}
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {oderDetails?.shipping_to_addr_line_2 ? (
                          <>
                            {getWellKnownAddress(
                              oderDetails?.shipping_to_addr_line_2,
                              oderDetails?.shipping_to_city,
                              oderDetails?.shipping_to_state,
                              oderDetails?.shipping_to_country,
                              oderDetails?.shipping_to_zipcode
                            )}
                          </>
                        ) : (
                          ' S 5th Ave, Merrll Street, Castlewood, SD - 57078, US'
                        )}
                      </Typography>
                    </Container>
                  </Container>
                </Container>
                <Container className={classes.separatorWidth}>
                  <Seperator />
                </Container>
                <Container className={classes.productDetail}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Product Details
                  </Typography>
                  <Container className={classes.productTable}>
                    <Container className={classes.tableHeader}>
                      <Container className="col1">
                        <Typography variant="body1" color={getColor('grey-g65')}>
                          Items
                        </Typography>
                      </Container>
                      <Container className="col2">
                        <Typography variant="body1" color={getColor('grey-g65')}>
                          Qty
                        </Typography>
                      </Container>
                      <Container className="col3">
                        <Typography variant="body1" color={getColor('grey-g65')}>
                          Unit Price
                        </Typography>
                      </Container>
                      <Container className="col4">
                        <Typography variant="body1" color={getColor('grey-g65')}>
                          Amount
                        </Typography>
                      </Container>
                    </Container>
                    <Container className={classes.tableBody}>
                      <Container className={classes.rowRoot}>
                        <Container className="row1">
                          <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                            {oderDetails?.brand_name} - {oderDetails?.part_name} - {oderDetails?.part_number}
                          </Typography>
                          <Typography variant="paragraph1" color={getColor('grey-g65')}>
                            {oderDetails?.condition === 'new_aftermarket' && 'New Aftermarket'}
                            {oderDetails?.condition === 'new_oem' && 'New OEM'}
                            {oderDetails?.condition === 'used' && 'Used'}

                            {oderDetails?.weight && ` •  ${oderDetails?.weight} lbs`}
                          </Typography>
                        </Container>
                        <Container className="row2">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {oderDetails?.qty ? oderDetails?.qty : '-'}
                          </Typography>
                        </Container>
                        <Container className="row3">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {/* {oderDetails?.unit_price ? ` $ ${String(oderDetails?.unit_price).substring(0, 6)}` : '-'} */}
                            {oderDetails?.unit_price ? formatPrice(oderDetails?.unit_price) : '-'}
                          </Typography>
                        </Container>
                        <Container className="row4">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {/* {oderDetails?.amount ? ` $ ${oderDetails?.amount}` : '-'} */}
                            {oderDetails?.amount ? formatPrice(oderDetails?.amount) : '-'}
                          </Typography>
                        </Container>
                      </Container>
                      <Container className={classes.rowRoot}>
                        <Container className="row1">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {Object.keys(orderDetailsData?.delivery_options).length > 1 ? (
                              `Shipping: ${oderDetails?.delivery_options?.freight_next_day && 'Next Day Delivery'}`
                            ) : (
                              <>
                                Shipping: {oderDetails?.delivery_options?.freight_next_day && 'Next Day Delivery'}
                                {oderDetails?.delivery_options?.mdo_local_delivery && 'Local Delivery'}
                                {oderDetails?.delivery_options?.freight_ground && 'Ground'}
                                {oderDetails?.delivery_options?.mdo_pick_up && 'Pickup'}
                                {oderDetails?.delivery_options?.mdo_ltl && 'LTL'}
                                {oderDetails?.delivery_options?.freight_second_day && 'Second Day'}
                              </>
                            )}
                          </Typography>
                        </Container>
                        <Container className="row2">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {}
                          </Typography>
                        </Container>
                        <Container className="row3">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {}
                          </Typography>
                        </Container>
                        <Container className="row4">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {Object.keys(oderDetails?.delivery_options).length > 1 ? (
                              formatPrice(oderDetails?.delivery_options?.freight_next_day)
                            ) : (
                              <>
                                {oderDetails?.delivery_options?.freight_next_day && (
                                  <>
                                    {formatPrice(oderDetails?.delivery_options?.freight_next_day) === '$0.00'
                                      ? 'free'
                                      : formatPrice(oderDetails?.delivery_options?.freight_next_day)}
                                  </>
                                )}
                                {oderDetails?.delivery_options?.mdo_local_delivery && (
                                  <>
                                    {formatPrice(oderDetails?.delivery_options?.mdo_local_delivery) === '$0.00'
                                      ? 'free'
                                      : formatPrice(oderDetails?.delivery_options?.mdo_local_delivery)}
                                  </>
                                )}
                                {oderDetails?.delivery_options?.freight_ground && (
                                  <>
                                    {formatPrice(oderDetails?.delivery_options?.freight_ground) === '$0.00'
                                      ? 'free'
                                      : formatPrice(oderDetails?.delivery_options?.freight_ground)}
                                  </>
                                )}
                                {oderDetails?.delivery_options?.mdo_pick_up && (
                                  <>
                                    {formatPrice(oderDetails?.delivery_options?.mdo_pick_up) === '$0.00'
                                      ? 'free'
                                      : formatPrice(oderDetails?.delivery_options?.mdo_pick_up)}
                                  </>
                                )}
                                {oderDetails?.delivery_options?.mdo_ltl && (
                                  <>
                                    {formatPrice(oderDetails?.delivery_options?.mdo_ltl) === '$0.00'
                                      ? 'free'
                                      : formatPrice(oderDetails?.delivery_options?.mdo_ltl)}
                                  </>
                                )}
                                {oderDetails?.delivery_options?.freight_second_day && (
                                  <>
                                    {formatPrice(oderDetails?.delivery_options?.freight_second_day) === '$0.00'
                                      ? 'free'
                                      : formatPrice(oderDetails?.delivery_options?.freight_second_day)}
                                  </>
                                )}
                              </>
                            )}
                          </Typography>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                  <Container className={classes.totolRoot}>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Total
                    </Typography>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      {oderDetails?.order_status === 'cancelled' ? 'Cancelled' : formatPrice(oderDetails?.final_amount)}
                    </Typography>
                  </Container>
                </Container>
              </Container>
              <Container className={classes.orderRight}>
                <Container>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Sold By
                  </Typography>
                  <Container className={classes.soldProfileRoot}>
                    <Image src={oderDetails?.seller_logo} />
                    <Container>
                      <Typography variant="paragraph1" color={getColor('grey-g25')} component={'p'}>
                        {oderDetails?.seller_full_name}
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        E:{oderDetails?.seller_email}
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        P: {oderDetails?.seller_phone_numbe}
                      </Typography>
                    </Container>
                  </Container>
                </Container>

                {oderDetails?.order_status === 'cancelled' && (
                  <>
                    <Seperator />
                    <Container className={classes.p8}>
                      <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                        Cancel Reason
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {oderDetails?.order_cancle_reason}
                      </Typography>
                    </Container>
                  </>
                )}

                {oderDetails?.order_status === 'cancelled' ? (
                  ''
                ) : (
                  <>
                    {oderDetails?.shipping_to_delivery_instructions && (
                      <>
                        <Seperator />
                        <Container className={classes.p8}>
                          <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                            Delivery Instructions
                          </Typography>
                          <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                            {oderDetails?.shipping_to_delivery_instructions
                              ? oderDetails?.shipping_to_delivery_instructions
                              : ' Make sure you deliver to Door 122 and ask for Sam Johnson in the Receiving Dept.'}
                          </Typography>
                        </Container>
                      </>
                    )}
                  </>
                )}

                {oderDetails?.order_status === 'shipped' && (
                  <>
                    <Seperator />
                    <Container className={classes.p8}>
                      <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                        Return Order
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        Do you want to return your order?
                        <br />
                        contact seller at{' '}
                        <Button className={classes.blueLinkBtn} onClick={setConatctDeliveryDialog}>
                          {oderDetails?.seller_email ? oderDetails?.seller_email : 'sales@reliableaftermarketparts.com'}
                        </Button>{' '}
                      </Typography>
                    </Container>
                  </>
                )}

                {oderDetails?.order_status === 'awaiting_shipment' && (
                  <>
                    <Seperator />
                    <Container className={classes.p8}>
                      <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                        Cancel Order
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        You can cancel your order until it has been shipped or{' '}
                        <Button className={classes.blueLinkBtn} onClick={setConatctDeliveryDialog}>
                          contact seller
                        </Button>{' '}
                        for more details
                      </Typography>
                      <Button
                        variant="transparent"
                        border
                        width={'121px'}
                        className={classes.cancleOrderBtn}
                        onClick={setOrderCancelDialog}
                      >
                        Cancel Order
                      </Button>
                    </Container>
                  </>
                )}

                <Container className={classes.helpRoot}>
                  <Seperator />
                  <Typography variant="title" color={getColor('grey-g25')} component={'p'} className={classes.help}>
                    Help
                  </Typography>
                  <Container className={classes.contanctUS}>
                    <Typography component={'p'}>Need additional help with an order?</Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Contact us at{' '}
                      <Link to="" className={classes.blueLink} onClick={() => setHelpDialog()}>
                        support@partsclub.us
                      </Link>
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      )}

      <PurchaseOrderNumberDialog
        open={purchaseOrderDialog}
        onClose={setPurchaseOrderDialog}
        editPonNumber={editPonNumber}
        mutate={mutate}
      />
      <HelpDialog open={helpDialog} onClose={setHelpDialog} section={'Order'} />
      <OrderCancelDialog open={orderCancelDialog} onClose={setOrderCancelDialog} mutate={mutate} />
      <ContactSellerDialog
        open={contactDeliveryDialog}
        onClose={setConatctDeliveryDialog}
        email={oderDetails?.seller_email}
        sellerName={sellerName}
      />
    </Container>
  )
}
