import React from 'react'
import { Switch as MuiSwitch } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'

const useStyles = makeStyles({
  root: (props) => ({
    width: props.width || 56,
    height: props.height || 26,
    padding: 0,
    display: 'flex',
    opacity: (props) => (props.disabled ? 0.75 : 1),
  }),
  switchBase: {
    padding: 2,
    color: getColor('light'),
    '&:before': {
      content: '"%"',
      position: 'absolute',
      fontSize: '14px',
      color: getColor('dark-d25'),
      display: 'flex',
      alignItems: ' center',
      right: '0',
      top: '0',
      bottom: ' 0',
      width: '100%',
      height: '100%',
      left: '0',
      zIndex: '9',
      justifyContent: ' center',
      fontFamily: 'Lexend Deca',
      fontStyle: 'normal',
      fontWeight: ' 400',
    },
    '&$checked': {
      transform: 'translateX(25px)',
      color: getColor('light'),
      '& + $track': {
        opacity: 1,
        backgroundColor: getColor('system-bg'),
        border: '0.5px solid ',
        borderColor: getColor('grey-g80'),
      },
      '&:before': {
        content: '"$"',
        position: 'absolute',
        fontSize: '14px',
        color: getColor('dark-d25'),
        display: 'flex',
        alignItems: ' center',
        right: '0',
        top: '0',
        bottom: ' 0',
        width: '100%',
        height: '100%',
        zIndex: '9',
        justifyContent: ' center',
        fontFamily: 'Lexend Deca',
        fontStyle: 'normal',
        fontWeight: ' 400',
      },
    },
    '&.Mui-disabled': {
      color: getColor('light'),
    },
  },
  thumb: (props) => ({
    width: props.thumbWidth || 27,
    height: props.thumbHeight || 22,
    boxShadow: ' 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06)',
    backgroundColor: '#ffffff',
    borderRadius: 1,
    position: 'relative',
  }),

  track: {
    // border: `1px solid ${getColor('system-bg')}`,
    borderRadius: 2,
    opacity: 1,
    border: '0.5px solid #C3C5D5',
    backgroundColor: getColor('system-bg'),
    position: 'relative',
    ' &:before': {
      content: '"%"',
      position: 'absolute',
      fontSize: '14px',
      color: getColor('grey-g35'),
      display: 'flex',
      fontFamily: 'Lexend Deca',
      fontStyle: 'normal',
      fontWeight: '400',
      alignItems: 'center',
      left: '0',
      top: '0',
      bottom: ' 0',
      width: '25px',
      justifyContent: 'center',
      zIndex: '9',
    },
    ' &:after': {
      content: '"$"',
      position: 'absolute',
      fontSize: '14px',
      color: getColor('grey-g35'),
      display: 'flex',
      fontFamily: 'Lexend Deca',
      fontStyle: 'normal',
      fontWeight: '400',
      alignItems: 'center',
      right: '0',
      top: '0',
      bottom: ' 0',
      width: '25px',
      justifyContent: 'center',
      zIndex: '9',
    },
  },

  checked: {},
  switchicon: {},
})

export default function SwitchTogglePrefix({
  id,
  onChange,
  defaultValue,
  disabled,
  value,
  width,
  height,
  thumbWidth,
  thumbHeight,
}) {
  const classes = useStyles({ disabled, width, height, thumbWidth, thumbHeight })
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.checked)
    }
  }
  return (
    <Container container direction="column" id="switchicon">
      <MuiSwitch
        id={id}
        defaultChecked={value ?? defaultValue}
        onChange={handleChange}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
          switchicon: classes.switchicon,
        }}
        disabled={disabled}
      />
    </Container>
  )
}
SwitchTogglePrefix.propTypes = {
  id: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  thumbWidth: PropTypes.string,
  thumbHeight: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
}
