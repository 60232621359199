import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import HtmlTitle from 'utils/HtmlTitle'
import BuyerQuoteSidebar from './BuyerQuoteSidebar'
import { useLocation, useParams } from 'react-router-dom'
import { getColor } from 'theme/colors'

import useQuoteDetails from 'common-hooks/buyer/quotes-details/useQuoteDetails'
import { BuyerQuoteDataContext } from './BuyerQuoteUtils'
import BuyerQuoteList from './BuyerQuoteList'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: 'unset',
    // overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  backBtn: {
    height: '36px',
    width: '36px',
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: getColor('grey-g65'),
    marginLeft: '20px',
  },
  titleContainer: {
    height: '80px',
    background: getColor('light-main'),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${getColor('grey-g95')}`,
    gap: '20px',
  },
}))
export default function BuyerQuoteDetails() {
  const classes = useStyles()
  const params = useParams()

  const location = useLocation()
  const url = location.pathname
  const { quotesDetailDataList } = useQuoteDetails(params.id, url)

  const [buyerQuoteData, saveBuyerQuoteData] = useState([])
  const contextValue = {
    saveBuyerQuoteData,
    buyerQuoteData,
  }

  return (
    <Container>
      <HtmlTitle title={'Quote Details'} />
      {/* <Container className={classes.titleContainer}>
        <IconButton className={classes.backBtn} onClick={() => navigate('/quotes')}>
          <CaretLeftIcon />
        </IconButton>
        <Typography variant="h1" color={getColor('grey-g25')}>
          {quoteName.replace('null', '').replace('undefined', '')}
        </Typography>
      </Container> */}
      <BuyerQuoteDataContext.Provider value={contextValue}>
        <Container className={classes.root}>
          <BuyerQuoteSidebar />
          <BuyerQuoteList status={quotesDetailDataList?.private} />
        </Container>
      </BuyerQuoteDataContext.Provider>
    </Container>
  )
}
