import PropTypes from 'prop-types'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import SearchField from 'components/common/search/SearchField'
import { useState, useEffect, useContext } from 'react'
import { getColor } from 'theme/colors'
import useBrandList from 'common-hooks/create-quote/useBrandList'
import EllipsisVerticleIcon from 'assets/icons/UnionIcon.svg'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Chip from 'components/common/Chip'
import { useFormData } from './FormProvider'
import LabeledImageCard from '../LabeledImageCard'
import { BuyerQuoteContext } from '../BuyerQuotesUtils'
import FormFooter from './FormFooter'
// import Typography from 'components/common/Typography'

const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    width: '780px',
  },
  gridContainer: {
    gridTemplateColumns: 'auto auto auto',
    padding: '10px',
    '& div': {
      display: 'block',
    },
  },
  gridItem: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'lightblue',
    border: '1px solid black',
  },
  mainBox: {
    padding: '10px',
  },
  gridMediaContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '579px',
    overflowY: 'scroll',
    flexDirection: 'column',
    backgroundColor: getColor('system-bg'),
    padding: (props) => getPadding(props.filedVisibility),

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  body: {
    backgroundColor: getColor('system-bg'),
    padding: '75px 30px 0 30px',
  },
  popular: {
    alignItems: 'unset',
    gap: '10px 0',
    marginBottom: '10px',
  },
  populargrid: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '20px',
  },
  bodyheaderbg: {
    backgroundColor: getColor('system-bg'),
    padding: '0 20px',
    paddingTop: '21px',
    paddingBottom: '21px',
    position: 'absolute',
    width: '100%',
    zIndex: '999',
  },
  search: {
    width: '100%',
    padding: '0px 0px 20px 0px',
  },
  infotooltip: {
    padding: '0 30px',
    backgroundColor: getColor('system-bg'),
    '& div': {
      padding: '8px 0',
    },
  },
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '47%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
  searchBar: {
    background: '#fff',
  },
}))
export default function BrandsForm({ formStep, nextFormStep, setnextBtn, nextBtn, prevFormStep }) {
  const { onSaveBuyerQuoteInformaton, BuyerQueoteInformation } = useContext(BuyerQuoteContext)
  const { setFormValues } = useFormData()
  const [activeCard, setActiveCard] = useState(BuyerQueoteInformation?.Brands?.brand?.name || '')
  const [filedVisibility, setFiledVisibility] = useState(true)
  const classes = useStyles({ filedVisibility })
  const methods = useForm({
    defaultValues: {
      ...BuyerQueoteInformation?.Brands,
      ...BuyerQueoteInformation?.Brands?.modelNumber,
      ...BuyerQueoteInformation?.Brands?.serialNumber,
    },
  })
  const { handleSubmit, setValue, watch, control } = methods

  const onSubmit = (values) => {
    setFormValues(values)
    onSaveContextValue()
    nextFormStep(formStep + 1)
  }

  const selectEquipment = (name, id, coverImg) => {
    setValue('brand', { name, id, coverImg })
    setActiveCard(name)
  }

  useEffect(() => {
    if (activeCard !== '' && activeCard !== 'Other') {
      setnextBtn(false)
    } else if (activeCard === 'Other' && brandName !== '') {
      setnextBtn(false)
    } else {
      setnextBtn(true)
    }
  })
  // const [modelNumber, setModelNumber] = useState(BuyerQueoteInformation?.Brands?.modelNumber || '')
  // const [serialNumber, setSerialNumber] = useState(BuyerQueoteInformation?.Brands?.serialNumber || '')
  const [brandName, setBrandName] = useState(BuyerQueoteInformation?.Brands?.brandName || '')
  const [brand] = useWatch({
    control,
    name: ['brand'],
  })
  function equipmentName(val) {
    if (val?.equipment?.name === 'Other') {
      return val?.equipmentName
    }
    return val?.equipment?.name
  }
  const onSaveContextValue = () => {
    const Brands = {
      brand,
      // modelNumber,
      // serialNumber,
      brandName,
    }
    onSaveBuyerQuoteInformaton({ ...BuyerQueoteInformation, Brands })
  }
  const fields = watch()
  useEffect(() => {
    if (fields.brand) {
      setFiledVisibility(true)
    } else {
      setFiledVisibility(false)
    }
  }, [fields.brand])

  const other = {
    id: 81,
    name: 'Other',
    cover_image: EllipsisVerticleIcon,
  }
  const { brandList, search, onSearch, isLoading } = useBrandList(BuyerQueoteInformation?.Equipments?.equipment.id)
  const popular = []
  const all = []
  brandList?.map((obj) => {
    if (obj?.is_popular) {
      popular.push(obj)
    }
    if (!obj?.is_popular) {
      all.push(obj)
    }
    return 0
  })
  function handleRemoveChip(id) {
    prevFormStep()
    onSaveBuyerQuoteInformaton((pre) => {
      if (pre?.Equipments?.equipment?.id === id) {
        delete pre?.Equipments?.equipment
      }
      return pre
    })
  }
  return (
    <FormProvider {...methods}>
      <Container>
        <form id={`hook-form-${formStep}`} onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.bodyheaderbg}>
            <Container className={classes.search}>
              <SearchField
                id="search-feild"
                border={'unset'}
                onSearch={(e) => {
                  onSearch(e)
                }}
                className={classes.searchBar}
                value={search}
                placeholder={'Search brand'}
              />
            </Container>
            <Container className={classes.chips}>
              <Chip
                label={equipmentName(BuyerQueoteInformation?.Equipments)}
                removable
                onRemove={() => handleRemoveChip(BuyerQueoteInformation?.Equipments?.equipment?.id)}
              />
            </Container>
          </Container>
          <Container className={classes.body}>
            <div className={classes.gridMediaContainer}>
              {isLoading && <LoadingOverlay />}
              <Container flex direction={'column'} className={classes.popular}>
                {/* If need to change popular brand this code will help */}
                {/* {!isLoading && popular?.length > 0 && <Typography variant="title1">Popular</Typography>} */}
                {brandList?.length > 0 && (
                  <>
                    <Container className={classes.populargrid}>
                      {brandList?.map((eItem, index) => (
                        <LabeledImageCard
                          key={index}
                          {...eItem}
                          value={activeCard}
                          onChange={selectEquipment}
                          formStep={formStep}
                        />
                      ))}
                      {!isLoading && <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />}
                    </Container>
                  </>
                )}
                {!brandList?.length > 0 && (
                  <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />
                )}
              </Container>
              {/* If need to change popular brand this code will help */}
              {/* <Container flex direction={'column'} className={classes.popular}>
                {!isLoading && all?.length > 0 && <Typography variant="title1">All</Typography>}
                {all?.length > 0 && (
                  <Container className={classes.populargrid}>
                    {all?.map((eItem, index) => (
                      <LabeledImageCard
                        key={index}
                        {...eItem}
                        value={activeCard}
                        onChange={selectEquipment}
                        formStep={formStep}
                      />
                    ))}
                    {!isLoading && <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />}
                  </Container>
                )}
              </Container> */}
            </div>
          </Container>
          {/* <Container className={classes.infotooltip}>
          <BuyerQuotesInfoToolTip currentStep={formStep} />
        </Container> */}
          <Container className={classes.dailogFooter}>
            <FormFooter
              brandTypes={fields.brand}
              currentStep={formStep}
              // setModelNumber={setModelNumber}
              // setSerialNumber={setSerialNumber}
              // modelNumber={modelNumber}
              // serialNumber={serialNumber}
              setBrandName={setBrandName}
              brandName={brandName}
              nextBtn={nextBtn}
            />
          </Container>
        </form>
      </Container>
    </FormProvider>
  )
}

BrandsForm.propTypes = {
  onClose: PropTypes.func,
  formStep: PropTypes.number,
  nextFormStep: PropTypes.func,
  prevFormStep: PropTypes.func,
  setnextBtn: PropTypes.func,
  nextBtn: PropTypes.func,
}
function getPadding(textFiledVisibility) {
  return `${!textFiledVisibility ? '52px 0 30px 0' : '52px 0 100px 0'}`
}
