import { makeStyles } from '@mui/styles'
import Radio from 'components/common/radio/Radio'
import Typography from 'components/common/Typography'
import React from 'react'
import propTypes from 'prop-types'
import Image from 'components/common/Image'
import Container from 'components/common/Container'
import { getColor } from 'theme/colors'
import Button from 'components/common/Button'

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '12px',
    width: '200px',
    height: '190px',
    background: '#FFFFFF',
    // border: '1px solid #F0F0F4',
    border: '1px solid',
    borderColor: (props) => (props.checked ? '#FF8C1A' : '#F0F0F4'),
    position: 'relative',
    transition: 'boxShadow 1s',
    '&:hover': {
      boxShadow: '0px 16px 14px rgba(0, 0, 0, 0.06),0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04)',
    },
    '& .radio_btn': {
      position: 'absolute',
      width: '13%',
      height: ' 13%',
      /* flex-direction: column; */
      justifyContent: 'flex-end',
      alignItems: 'baseline',
    },
  },
  radioRoot: {
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: '0px',
    },
  },
  radioCard: {
    width: '200px',
    height: '190px',
    background: '#FFFFFF',
    position: 'relative',
    border: '1px solid #F0F0F4',
    '& img': {
      width: '50%',
      objectFit: 'cover',
    },
    '& .MuiFormControlLabel-root ': {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      fontSize: 'inherit',
      margin: '0',
      //  position: 'absolute',
      right: 'calc(1em + 2px)',
      //  top: 'calc(1em + 2px)',
    },
    transition: 'boxShadow 1s',
    '&:hover': {
      boxShadow: '0px 16px 14px rgba(0, 0, 0, 0.06),0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04)',
    },
    newRadio: {
      position: 'absolute',
      background: 'red',
      opacity: 0,
      width: 0,
      height: 900,
    },
    '& [type=radio] + img': {
      cursor: 'pointer',
    },
  },
  submitbtn: {
    zIndex: '999',
    background: 'unset',
    padding: '0',
  },
}))
export default function UserTypeRadioCard({ name, img, id, onChange, checked, onClick }) {
  const classes = useStyles({ checked })
  return (
    <Button type="submit" form={'singup-account-type'} onClick={onClick} className={classes.submitbtn}>
      <Container className={classes.root} id={'radio-group'}>
        <Radio value={id} className="radio_btn" onChange={onChange} checked={checked} />
        <Container flex height={'100%'} width={'100%'} gap={'10px'} direction={'column'}>
          <Image src={img} alt={name} height={80} width={80} />
          <Typography variant="body1" color={getColor('grey-g35')}>
            {name}
          </Typography>
        </Container>
      </Container>
    </Button>
  )
}

UserTypeRadioCard.propTypes = {
  name: propTypes.string,
  img: propTypes.string,
  id: propTypes.string,
  onChange: propTypes.func,
  checked: propTypes.bool,
  onClick: propTypes.func,
}
