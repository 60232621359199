import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
// import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
// import { Divider } from '@mui/material'
// import { getColor } from 'theme/colors'
// import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'

import PropTypes from 'prop-types'
import { Step } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { makeStyles } from '@mui/styles'

const steps = ['Equipment', 'Brand', 'Parts', 'Summary']

const useStyles = makeStyles({
  myClass: {
    '& .MuiStepLabel-label': {
      marginTop: '5px',
      fontWeight: 400,
      fontSize: 11,
    },
  },
})
export default function CreateQuoteSteps({ formStep }) {
  const [active, setActive] = useState(0)
  const classes = useStyles()
  useEffect(() => {
    if (formStep === 0) {
      setActive(0)
    } else if (formStep === 1) {
      setActive(1)
    } else if (formStep === 2) {
      setActive(2)
    } else if (formStep === 3) {
      setActive(3)
    }
  }, [formStep])
  return (
    <Container flex direction="column" gap="40px">
      <Container width="100%">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={active} alternativeLabel connector={<ColorlibConnector />} className={classes.myClass}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Container>
    </Container>
  )
}

CreateQuoteSteps.propTypes = {
  formStep: PropTypes.number,
  // onStepChange: PropTypes.func,
}
const ColorlibStepIconRoot = styled('div')(({ ownerState }) => ({
  backgroundColor: '#FFFFFF',
  color: '#9799B5',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  border: '1.5px solid #9799B5',
  justifyContent: 'center',
  fontFamily: "'Lexend Deca'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#FF8C1A',
    border: '1px solid #FF8C1A',
  }),
  ...(ownerState.completed && {
    color: '#FF8C1A',
    border: '1.5px solid #FF8C1A',
  }),
}))
function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <div key="1">1</div>,
    2: <div key="2">2</div>,
    3: <div key="3">3</div>,
    4: <div key="4">4</div>,
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}
ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}
const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 13,
    left: 'calc(-54% + 18px)',
    right: 'calc(42% + 23px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#FF8C1A',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#FF8C1A',
      border: '1px solid #FF8C1A',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: '#C3C5D5',
    borderRadius: 1,
  },
}))
