import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import PropTypes from 'prop-types'
import BuyerOrderCompletedCard from './BuyerOrderCompletedCard'
import { parseOrderData } from '../OrdersUtils'
import { Fragment } from 'react'

const useStyles = makeStyles(() => ({
  awainglistBody: {
    maxWidth: '800px',
    // height: '80vh',
    // overflowY: 'scroll',
    margin: '0 auto',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  awaingcardList: {
    marginTop: '17px',
  },
  dateClass: {
    paddingBottom: '17px',
  },
}))

export default function BuyerOrderCompletedList({ orderData }) {
  const classes = useStyles()
  const rows = parseOrderData(orderData, 'ordered_shipped_date')
  const keys = Object.keys(rows)

  return (
    <Container>
      <Container className={classes.awainglistBody}>
        <Container className={classes.awaingcardList}>
          {keys?.map((key, index) => (
            <Fragment key={index}>
              <Container className={classes.dateClass}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  {key.replace(',', '').toUpperCase()}
                </Typography>
              </Container>

              {rows[key].map((obj, index) => (
                <BuyerOrderCompletedCard
                  id={obj.id}
                  key={index}
                  partName={obj.part_name}
                  brandName={obj.brand_name}
                  brandImg={obj.brand_cover_image}
                  equpImg={obj.equip_cover_image}
                  partNumber={obj.part_number}
                  orderId={obj.order_id}
                  poNumber={obj.po_number}
                  buyername={obj.seller_full_name}
                  city={obj.city}
                  state={obj.state}
                  amount={obj.amount}
                  delivery={obj.delivery_options}
                  quantity={obj.qty}
                  deliverOn={obj.deliver_on}
                  dilverTime={obj.delivered_time}
                  trackingNumber={obj.tracking_no}
                  courierName={obj.courier_name}
                  country={obj?.country}
                />
              ))}
            </Fragment>
          ))}
        </Container>
        {/* <Container className={classes.awaingcardList}>
          {orderData?.map((data, index) => (
            <BuyerOrderCompletedCard
              id={data.id}
              key={index}
              partName={data.part_name}
              brandName={data.brand_name}
              brandImg={data.brand_cover_image}
              equpImg={data.equip_cover_image}
              partNumber={data.part_number}
              orderId={data.order_id}
              poNumber={data.po_number}
              buyername={data.seller_full_name}
              city={data.city}
              state={data.state}
              amount={data.amount}
              delivery={data.delivery_options}
              quantity={data.qty}
              deliverOn={data.deliver_on}
              dilverTime={data.delivered_time}
              trackingNumber={data.tracking_no}
            />
          ))}
        </Container> */}
      </Container>
    </Container>
  )
}
BuyerOrderCompletedList.propTypes = {
  orderData: PropTypes.array,
}
