import { makeStyles } from '@mui/styles'
import { Done } from 'assets/icons'
// import useToggle from 'common-hooks/useToggle'
import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import reportIcon from 'assets/images/reportIcon.svg'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Seperator from 'components/common/Seperator'
import LabelTextField from 'components/common/text/LabelTextField'
import TextArea from 'components/common/text/TextArea'
import Typography from 'components/common/Typography'
import { useState } from 'react'
import { getColor, getTagColor } from 'theme/colors'
import { formatPrice } from 'utils/Utils'
import useToggle from 'common-hooks/useToggle'
import ReportDialog from 'containers/product-search/ReportDialog'

const useStyles = makeStyles({
  cardRoot: {
    background: '#FFFFFF',
    border: ' 1px solid #F0F0F4',
    padding: '20px',
  },
  acptBtn: {
    display: 'flex',
    gap: '0 10px',
    paddingLeft: '140px',
  },
  offerPricing: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
    '& p': {
      width: '140px',
    },
  },
  cardBuyerSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  offerSend: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '15px 0',
    '& p': {
      width: '140px',
    },
  },
  cardInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  Notes: {
    width: '100%',
    '& div': {
      width: '800px',
    },
  },
  offerSendWaingResponse: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
    '& p': {
      width: '140px',
    },
  },
  couterPrice: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
  },
  finalPrice: {
    display: 'flex',

    gap: '10px 0',
    '& p': {
      width: '140px',
    },
  },
  supplierAccpeptOffer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  expirdOfferPrice: {
    display: 'flex',
    '& p': {
      width: '140px',
    },
  },
  NudgeBtn: {
    marginLeft: '140px',
  },
  expirdOffer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  reportthiLink: {
    fontSize: ' 12px',
    lineHeight: '10px',
    backgroundColor: 'unset',
    color: '#9799B5',
    borderBottom: '1px solid #9799B5',
    height: 'unset',
    width: 'unset',
    minHeight: 'unset',
    borderRadius: 'unset',
    padding: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& span': {
      fontSize: ' 12px',
      lineHeight: '12px',
    },
  },
})
export default function CounterOfferCardBuyer() {
  const classes = useStyles()
  const [reportDialog, setReportDialog] = useToggle(false)
  const [counterOffer, setCounterOffer] = useState(false)
  //   const [confirmationDialog, setConfirmatioDialog] = useToggle(false)

  const waitngForResponse = false
  const BuyerResponsed = false
  const supplierAccptOffer = false
  const buyerAccptOffer = false
  const supplierSendedOfferExpired = false

  return (
    <>
      <Container className={classes.cardRoot}>
        <Container className={classes.cardInner}>
          <Container className={classes.cardBuyerSide}>
            <Container flex justify="space-between">
              <Typography variant="title" color="#13141B">
                Offer #1
              </Typography>
              <Container flex gap={'0 5px'}>
                <Chip label="Waiting For Seller" /> •
                <Typography variant="paragraph1" color="#AD1F36">
                  Expires: 1d 23h
                </Typography>
                {/* <Typography variant="paragraph1" color="#AD1F36">
                Offer Expired
              </Typography> */}
                <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                  • mm/dd/yyyy
                </Typography>
              </Container>
            </Container>
            <Container className={classes.offerPricing}>
              <Container flex justify="flex-start">
                <Typography variant="title" color={getColor('grey-g35')} component="p">
                  Your Offer
                </Typography>
                <Typography variant="title" color={getColor('grey-g35')}>
                  $180.50
                </Typography>
              </Container>
              <Container flex justify="flex-start">
                <Typography variant="body1" color={getColor('grey-g35')} component="p">
                  Original Price:
                </Typography>
                <Typography variant="body1" color={getColor('grey-g35')}>
                  $165.00
                </Typography>
              </Container>
              <Container flex justify="flex-start">
                <Typography variant="body1" color={getColor('grey-g35')} component="p">
                  Quantity:
                </Typography>
                <Typography variant="body1" color={getColor('grey-g35')}>
                  12
                </Typography>
              </Container>
              <Container flex justify="flex-start">
                <Typography variant="body1" color={getColor('grey-g35')} component="p">
                  Notes:
                </Typography>
                <Typography variant="body1" color={getColor('grey-g35')}>
                  Let me know if anything else is needed. Thank you.
                </Typography>
              </Container>
            </Container>
            {!counterOffer && (
              <Container className={classes.acptBtn}>
                <Button variant="positive">Accept Offer</Button>
                <Button variant="secondary" border onClick={() => setCounterOffer(!counterOffer)}>
                  Counter
                </Button>
              </Container>
            )}
          </Container>
          {counterOffer && (
            <>
              <Seperator />
              <Container className={classes.offerSend}>
                <Container flex justify="flex-start">
                  <Typography variant="body1" color={getColor('grey-g35')} component="p">
                    Counter Price*:
                  </Typography>
                  <LabelTextField labelText={'$'} width={'150px'} />
                </Container>
                <Container flex justify="flex-start" width={'100%'}>
                  <Typography variant="body1" color={getColor('grey-g35')} component="p">
                    Notes:
                  </Typography>
                  <Container width={'86%'}>
                    <TextArea />
                  </Container>
                </Container>
                <Container flex gap={'0 10px'} justify="flex-start">
                  <Button>Send Offer</Button>
                  <Button
                    variant="secondary"
                    border
                    onClick={() => {
                      setCounterOffer(!counterOffer)
                    }}
                  >
                    Cancel
                  </Button>
                </Container>
              </Container>
            </>
          )}
          {waitngForResponse && (
            <>
              <Seperator />
              <Container className={classes.offerSendWaingResponse}>
                <Container flex justify="space-between">
                  <Container flex gap="60px">
                    <Typography variant="title" color="#9799B5">
                      Your Offer
                    </Typography>
                    <Typography variant="title" color="#9799B5">
                      {formatPrice(35)}
                    </Typography>
                  </Container>

                  <Container flex gap={'0 5px'}>
                    <Typography variant="paragraph1" color="#AD1F36">
                      Expires: 1d 23h
                    </Typography>
                    <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                      • mm/dd/yyyy
                    </Typography>
                  </Container>
                </Container>
                <Container className={classes.couterPrice}>
                  <Container flex justify="flex-start">
                    <Typography variant="body1" color={getColor('grey-g65')} component="p">
                      Original Price:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g65')} component="p">
                      {formatPrice(45)}
                    </Typography>
                  </Container>
                  <Container flex justify="flex-start">
                    <Typography variant="body1" color={getColor('grey-g65')} component="p">
                      Quantity:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g65')}>
                      12
                    </Typography>
                  </Container>
                  <Container flex justify="flex-start">
                    <Typography variant="body1" color={getColor('grey-g65')} component="p">
                      Notes:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g65')}>
                      Let me know if anything else is needed.Thank you.
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </>
          )}
          {BuyerResponsed && (
            <>
              <Seperator />
              <Container className={classes.offerSendWaingResponse}>
                <Container flex justify="space-between">
                  <Typography variant="title" color="#13141B">
                    You responded with counter offer!
                  </Typography>
                  <Container flex gap={'0 5px'}>
                    <Typography variant="paragraph1" color="#AD1F36">
                      Expires: 1d 23h
                    </Typography>
                    <Typography variant="paragraph1" color={getTagColor('grey-g25')}>
                      • mm/dd/yyyy
                    </Typography>
                  </Container>
                </Container>
                <Container className={classes.couterPrice}>
                  <Container flex justify="flex-start">
                    <Typography variant="body1" color={getColor('grey-g35')} component="p">
                      Counteroffer Price:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')} component="p">
                      $175.50
                    </Typography>
                  </Container>
                  <Container flex justify="flex-start">
                    <Typography variant="body1" color={getColor('grey-g35')} component="p">
                      Notes:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Hey, I'm ready to sell this product at this price. Let me know if anything.
                      <br />
                      Thank you.
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </>
          )}
          {supplierAccptOffer && (
            <>
              <Seperator />
              <Container className={classes.supplierAccpeptOffer}>
                <Container flex justify="space-between">
                  <Container flex gap="0 5px">
                    <Done />
                    <Typography variant="title" color="#13141B">
                      Congratulations - you've locked in the order!{' '}
                    </Typography>
                  </Container>
                  <Container flex gap={'0 5px'}>
                    <Typography variant="paragraph1" color="#0DA50D">
                      Offer Approved
                    </Typography>
                    <Typography color="#4A4D68">•</Typography>
                    <Typography variant="paragraph1" color="#AD1F36">
                      Expires In: 1d 23h
                    </Typography>
                    <Typography color="#4A4D68">•</Typography>
                    <Typography variant="paragraph1" color="#4A4D68">
                      11/01/2022
                    </Typography>
                  </Container>
                </Container>
                <Container className={classes.finalPrice}>
                  <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                    Final Price:
                  </Typography>
                  <Container>
                    <Typography variant="body1" color={getColor('grey-g35')} component={'h5'}>
                      $165.00
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      You’ll receive an order confirmation email when the buyer has paid{' '}
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </>
          )}
          {buyerAccptOffer && (
            <>
              {' '}
              <Seperator />
              <Container className={classes.supplierAccpeptOffer}>
                <Container flex justify="space-between">
                  <Container flex gap="0 5px">
                    <Done />
                    <Typography variant="title" color="#13141B">
                      Congratulations - Order created 5440329FGUB29F
                    </Typography>
                  </Container>
                  <Container flex gap={'0 5px'}>
                    <Typography variant="paragraph1" color="#0DA50D">
                      Offer Approved
                    </Typography>
                    <Typography color="#4A4D68">•</Typography>
                    <Typography variant="paragraph1" color="#AD1F36">
                      Expires In: 1d 23h
                    </Typography>
                    <Typography color="#4A4D68">•</Typography>
                    <Typography variant="paragraph1" color="#4A4D68">
                      11/01/2022
                    </Typography>
                  </Container>
                </Container>
                <Container className={classes.finalPrice}>
                  <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                    Final Price:
                  </Typography>
                  <Container>
                    <Typography variant="body1" color={getColor('grey-g35')} component={'h5'}>
                      $165.00
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      The buyer has paid for this order and you are now ready to ship this product.
                    </Typography>
                  </Container>
                </Container>
                <Container flex justify="flex-start" gap={'0 10px'}>
                  <Button>Update Tracking</Button>
                  <Button variant="secondary" border>
                    View Order
                  </Button>
                </Container>
              </Container>
            </>
          )}
          {supplierSendedOfferExpired && (
            <>
              {' '}
              <Seperator />
              <Container className={classes.expirdOffer}>
                <Container flex justify="space-between">
                  <Container flex gap="0 5px">
                    <Typography variant="title" color="#13141B">
                      You responded with counteroffer!
                    </Typography>
                  </Container>
                  <Container flex gap={'0 5px'}>
                    <Typography color="#4A4D68">•</Typography>
                    <Typography variant="paragraph1" color="#AD1F36">
                      Offer Expired
                    </Typography>
                    <Typography color="#4A4D68">•</Typography>
                    <Typography variant="paragraph1" color="#4A4D68">
                      11/01/2022
                    </Typography>
                  </Container>
                </Container>
                <Container>
                  <Container className={classes.expirdOfferPrice}>
                    <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                      Counteroffer Price:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      $175.50
                    </Typography>
                  </Container>
                  <Container className={classes.expirdOfferPrice}>
                    <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                      Notes:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Hey, I'm ready to sell this product at this price. Let me know if anything.Thank you.
                    </Typography>
                  </Container>
                </Container>
                <Button variant="secondary" border className={classes.NudgeBtn} width={'93px'}>
                  Nudge
                </Button>
              </Container>
            </>
          )}
        </Container>
      </Container>
      <Container flex gap="0 2px" justify="flex-end">
        <Image src={reportIcon} />
        <Button className={classes.reportthiLink} onClick={setReportDialog}>
          Report this listing
        </Button>
      </Container>
      <ReportDialog onClose={setReportDialog} open={reportDialog} />
    </>
  )
}
