import { lighten, darken } from '@mui/material/styles'
import { getColor } from './colors'

// TODO Refactor color palette
export default function createPalette(clientColor) {
  return {
    primary: {
      main: '#f2f3f5',
      dark: '#e4e6eB',
      darker: '#ced2d9',
    },
    secondary: {
      main: '#ffffff',
    },
    dark: {
      lighter: '#707580',
      light: '#40444d',
      main: '#000000',
    },
    domain: {
      light: lighten(clientColor || '#196EE5', 0.9),
      main: clientColor || '#196EE5',
      dark: darken(clientColor || '#196EE5', 0.1),
    },
    error: {
      main: getColor('func-negative'),
    },
  }
}
