import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import TextField from 'components/common/text/TextField'
import React from 'react'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Typography from 'components/common/Typography'
import Tooltip from 'components/common/Tooltip'
import { InfoSmallIcon } from 'assets/icons'
import Button from 'components/common/Button'

const useStyles = makeStyles(() => ({
  root: {
    padding: '15px 0',
    position: 'relative',
    background: getColor('light-main'),
    // border: `1px solid ${getColor('grey-g95')}`,
  },
  main: {
    position: 'absolute',
    width: '100%',
    maxWidth: '780px',
    zIndex: '999',
    bottom: '0',
    background: '#fff',
  },
  toolTipInfo: {
    padding: '15px 0',
    // background: getColor('system-bg'),
  },
  textfield: {
    '& input': {
      borderRadius: 'unset',
    },
  },
  doneBtn: {
    marginTop: '20px',
  },
}))

export default function FormFooter({ currentStep, equipmentTypes, setEquipmentName, equipmentName, nextBtn }) {
  const classes = useStyles()
  let label = 'Equipment'
  if (currentStep === 1) {
    label = 'Brand'
  }

  // const handleOnModelNumber = (e) => {
  //   setModelNumber(e.target.value)
  // }
  // const handleOnSerialNumber = (e) => {
  //   setSerialNumber(e.target.value)
  // }
  const handleOnEqChange = (e) => {
    setEquipmentName(e.target.value)
  }

  // const handleBrandName = (e) => {
  //   setBrandName(e.target.value)
  // }

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  return (
    <Container className={classes.main}>
      <Container className={classes.toolTipInfo}>
        <Container width={'100%'} flex justify="space-between">
          <Container flex gap="10px">
            <Typography variant="overline">Images provided for reference only</Typography>
            <Tooltip
              placement="right-end"
              maxWidth={'565px'}
              title={
                'All trademarks, logos and brand names are the exclusive property of their respective owners, are for reference purposes only, and do not imply endorsement or affiliation with PartsClub, Inc.'
              }
            >
              <InfoSmallIcon className={classes.icon} />
            </Tooltip>
          </Container>

          {currentStep === 0 && (
            <Typography variant="overline">
              *Patent Pending with the United States Patent & Trademark Office (USPTO)
            </Typography>
          )}
        </Container>
      </Container>

      {equipmentTypes?.name === 'Other' && (
        <Container flex justify="flex-start" gap={'20px'} className={classes.root}>
          <TextField
            className={classes.textfield}
            width={246}
            label={label}
            value={equipmentName}
            id={`eq-number-${equipmentTypes}-${currentStep}`}
            onChange={handleOnEqChange}
            onKeyDown={handleKeyDown}
          />
          <Button className={classes.doneBtn} disabled={nextBtn} type={'submit'} form={`hook-form-${currentStep}`}>
            Done
          </Button>
        </Container>
      )}
      {/* {brandTypes && (
        <>
          <form id={`hook-form-${1}`}>
            <Container flex justify="flex-start" gap={'20px'} className={classes.root}>
              {brandTypes?.name === 'Other' && (
                <TextField
                  className={classes.textfield}
                  width={246}
                  value={brandName}
                  label="Brand"
                  placeholder="Type"
                  id={`eq-number-model-${brandTypes}-${currentStep}`}
                  labelVariant="paragraph2"
                  onChange={handleBrandName}
                  onKeyDown={handleKeyDown}
                />
              )}

              <TextField
                className={classes.textfield}
                width={246}
                label="Model Number"
                placeholder="Type"
                value={modelNumber}
                id={`eq-number-model-${brandTypes}-${currentStep}`}
                labelVariant="paragraph2"
                onChange={handleOnModelNumber}
                onKeyDown={(e) => allowOnlyAlphaNumric(e)}
              />
              <TextField
                className={classes.textfield}
                width={246}
                value={serialNumber}
                label="Serial Number"
                placeholder="Type"
                id={`eq-number-serial-${brandTypes}-${currentStep}`}
                labelVariant="paragraph2"
                onChange={handleOnSerialNumber}
                onKeyDown={(e) => allowOnlyAlphaNumric(e)}
              />
            </Container>
          </form>
        </>
      )} */}
    </Container>
  )
}

FormFooter.propTypes = {
  equipmentTypes: PropTypes.object,
  currentStep: PropTypes.number,
  setEquipmentName: PropTypes.func,
  equipmentName: PropTypes.string,
  nextBtn: PropTypes.number,
}
