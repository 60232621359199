import PropTypes from 'prop-types'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'
// import SuccessDialog from 'containers/supplier-quote-details/SuccessDialog'
// import useToggle from 'common-hooks/useToggle'
import FormProvider from './forms/FormProvider'
import EquipmentsForm from './forms/EquipmentsForm'
import BrandsForm from './forms/BrandsForm'
import Steps from '../../components/common/Steps'
import SummaryForm from './forms/SummaryForm'
import CreateQuoteDialogFooter from './CreateQuoteDialogFooter'
import PartsForm from './forms/PartsForm'
import SuccessDialog from 'containers/supplier-quote-details/SuccessDialog'
import useToggle from 'common-hooks/useToggle'
import { useLocation } from 'react-router-dom'

const useStyle = makeStyles(() => ({
  root: {
    height: '840px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  main: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabLabel: {
    padding: '0px 0px',
    height: '24px',
    fontFamily: 'Lexend Deca',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: getColor('grey-g35'),
    '& .MuiTabs-indicator': {
      backgroundColor: 'orange',
      height: 3,
    },
    '& .MuiTab-root.Mui-selected': {
      color: 'red',
    },
  },
  dailogBody: {
    height: 670,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    backgroundColor: getColor('system-bg'),
  },
}))

// function FormCompleted() {
//   const { data } = useFormData()

//   return (
//     <>
//       <h2>Thank you for Create Quote! 🎉</h2>

//       <pre>{JSON.stringify(data)}</pre>
//     </>
//   )
// }

const TabsList = [
  { tabIndex: 0, name: 'Equipment' },
  { tabIndex: 1, name: 'Brand' },
  { tabIndex: 2, name: 'Parts' },
  { tabIndex: 3, name: 'Summary' },
]
export default function CreateQuoteDialog({ open, onClose, setFormStep, formStep, quoteTab }) {
  const [nextBtn, setnextBtn] = useState(true)
  const location = useLocation()
  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1)
  const [opneSuccesDialog, setSuccesDialog] = useToggle(false)
  const [succesLink, setSuccesLink] = useState()

  const prevFormStep = () => {
    setFormStep((currentStep) => currentStep - 1)
  }

  const onCloseDialog = useCallback(() => {
    setFormStep(0)
    onClose()
  }, [onClose, setFormStep])
  const handleTabChange = (newValue) => {
    setFormStep(newValue)
  }
  useEffect(() => {
    if (formStep > 3) {
      onCloseDialog()
    }
  }, [formStep, onCloseDialog])
  const classes = useStyle()

  return (
    <>
      <Dialog open={open} size={'5.5xl'} className={classes.main}>
        {/* <Container className={classes.mainDailog}> */}
        <Container className={classes.dailogHeader}>
          <DialogHead onClose={onCloseDialog}>
            <Steps
              className={classes.root}
              currentStep={formStep}
              TabsList={TabsList}
              handleTabChange={handleTabChange}
            />
          </DialogHead>
        </Container>

        <Container className={classes.dailogBody}>
          <Container padding="0 30px">
            <FormProvider>
              {formStep === 0 && (
                <EquipmentsForm
                  onClose={onCloseDialog}
                  formStep={formStep}
                  nextFormStep={nextFormStep}
                  setnextBtn={setnextBtn}
                  nextBtn={nextBtn}
                  quoteTab={quoteTab}
                />
              )}
              {formStep === 1 && (
                <BrandsForm
                  onClose={onCloseDialog}
                  formStep={formStep}
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                  setnextBtn={setnextBtn}
                  nextBtn={nextBtn}
                />
              )}
              {formStep === 2 && (
                <PartsForm
                  setnextBtn={setnextBtn}
                  formStep={formStep}
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                  nextBtn={nextBtn}
                  setFormStep={setFormStep}
                />
              )}
              {formStep === 3 && (
                <SummaryForm
                  setnextBtn={setnextBtn}
                  onClose={onClose}
                  prevFormStep={prevFormStep}
                  setFormStep={setFormStep}
                  setSuccesLink={setSuccesLink}
                  setSuccesDialog={setSuccesDialog}
                />
              )}
            </FormProvider>
          </Container>
        </Container>

        <Container className={classes.dailogFooter}>
          <CreateQuoteDialogFooter
            currentStep={formStep}
            prevFormStep={prevFormStep}
            setFormStep={setFormStep}
            onClose={onCloseDialog}
            nextBtn={nextBtn}
          />
        </Container>

        {/* </Container> */}
      </Dialog>
      {location?.pathname === '/quotes' && (
        <SuccessDialog open={opneSuccesDialog} onClose={setSuccesDialog} succesLink={succesLink} />
      )}
    </>
  )
}

CreateQuoteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setFormStep: PropTypes.func,
  formStep: PropTypes.number,
  quoteTab: PropTypes.string,
}
