import Container from 'components/common/Container'
import { React } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { Controller, useForm } from 'react-hook-form'
import TextArea from 'components/common/text/TextArea'
import Checkbox from 'components/common/Checkbox'
import { getColor } from 'theme/colors'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  feedback: {
    padding: '10px 0px 20px 0px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    paddingBottom: '10px',
  },
  titleTypo: {
    fontFamily: 'Lexend Deca',
    fontStyle: ' normal',
    fontWeight: ' 500',
    fontSize: ' 14px',
    lineHeight: ' 20px',
    color: getColor('grey-g25'),
  },
  cancelBtn: {
    background: ' #AD1F36',
    '&:hover': {
      background: '  #88091d',
    },
  },
})

const ReasonDailog = ({ open, onclose }) => {
  const classes = useStyles()
  const { handleSubmit, register, setValue, control } = useForm({
    defaultValues: {
      noteValue: '',
    },
  })
  const onSubmit = () => {
    onclose()
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <form id={'resonForm'} onSubmit={handleSubmit(onSubmit)}>
            <DialogHead title={'Reason '} onClose={onclose} />
            <Container className={classes.dialogbody}>
              <Container className={classes.feedback}>
                <Typography variant="paragraph1" className={classes.titleTypo}>
                  Your feedback is greatly appreciated. Select one:{' '}
                </Typography>
              </Container>
              <Container>
                <Container className={classes.radioContainer}>
                  <Controller
                    name={'noLonger'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        label={'No longer need'}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValue(name, 'No longer need')
                          } else {
                            setValue(name, '')
                          }
                        }}
                      />
                    )}
                  />
                  <Controller
                    name={'noHelpful'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        label={'Part not offered'}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValue(name, 'Not helpful enough')
                          } else {
                            setValue(name, '')
                          }
                        }}
                      />
                    )}
                  />
                  <Controller
                    name={'tooExpensive'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        name={name}
                        label={'Too expensive'}
                        value={value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValue(name, 'Too expensive')
                          } else {
                            setValue(name, '')
                          }
                        }}
                      />
                    )}
                  />
                  <Controller
                    name={'other'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        name={name}
                        label={'other'}
                        value={value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValue(name, 'other')
                          } else {
                            setValue(name, '')
                          }
                        }}
                      />
                    )}
                  />
                </Container>
              </Container>
              <Container>
                <TextArea
                  id={'noteArea'}
                  placeholder={'Paragraph'}
                  name={'noteValue'}
                  rows={4}
                  {...register('noteValue')}
                />
              </Container>
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onclose}>
                  Cancel
                </Button>
                <Button width={111} type="submit" className={classes.cancelBtn}>
                  Cancel Plan
                </Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
ReasonDailog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  inviteId: PropTypes.string,
}

export default ReasonDailog
