import { makeStyles } from '@mui/styles'
import useToggle from 'common-hooks/useToggle'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import Select from 'components/common/select/Select'
import Seperator from 'components/common/Seperator'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import LabelTextField from 'components/common/text/LabelTextField'
import TextArea from 'components/common/text/TextArea'
import TextField from 'components/common/text/TextField'
import Typography from 'components/common/Typography'
import ImageSliderContainer from 'components/custom/image-slider/ImageSliderContainer'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { getColor } from 'theme/colors'
import { useParams } from 'react-router-dom'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import { createOption } from 'components/common/text/DropDownTextField'
import { exceptThisSymbols, getSupplierData, SupplierQuoteContext } from '../SupplierQuoteDetailsUtils'

const useStyles = makeStyles(() => ({
  mainFlex: {
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  eqpimage: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',

    '& div': {
      width: '32.2%',
      height: '120px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',

      '& img': {
        width: '120px',
        objectFit: 'cover',
        height: '120px',
      },
    },
  },
  partsimages: {
    '& div': {
      '& div': {
        width: 'unset',
        border: 'unset',
        height: 'unset',
        display: 'unset',
        alignItems: ' center',
        justifyContent: ' center',
      },
    },
  },
  p8: {
    padding: '8px 0',
    color: '#36384C',
  },
  p10: {
    padding: '10px 0',
    color: '#36384C',
  },
  partNumber: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  pb10: {
    paddingBottom: '10px',
  },
  labelTextField: {
    '& input': {
      textAlign: 'right',
    },
  },
}))
const SupplierQuoteParts = ({ setBttonDisable }) => {
  const [openImageDialog, toggleImageDialog] = useToggle(false)
  const params = useParams()
  const [imagesArray, setImagesArray] = useState([])
  const { supplierReqDetails } = useReqSupplierDetails(params?.id, params?.reqId)
  const { onSaveSupplierQuoteInformaton, supplierQueoteInformation } = useContext(SupplierQuoteContext)
  // const [removeAssetIds, setRemoveAssetIds] = useState([])
  const parsedSupplierData = getSupplierData(supplierReqDetails)
  const [flag, setFlag] = useState(supplierQueoteInformation?.flag || false)
  const { control, setValue } = useForm({
    defaultValues: {
      ...supplierQueoteInformation,
      inStock: parsedSupplierData?.inStock,
    },
  })
  const options = [
    { value: 'new_aftermarket', label: 'New Aftermarket' },
    { value: 'new_oem', label: 'New OEM' },
    { value: 'used', label: 'Used' },
  ]
  const [
    partNumber,
    // productImages,
    partDescription,
    unitPrice,
    inStock,
    condition,
    availableQty,
    weight,
    notes,
    ships,
  ] = useWatch({
    control,
    name: [
      'partNumber',
      // 'productImage',
      'partDescription',
      'unitPrice',
      'inStock',
      'condition',
      'availableQty',
      'weight',
      'notes',
      'ships',
    ],
  })
  const onSaveContextValue = () => {
    const Information = {
      imagesArray,
      partNumber,
      partDescription,
      unitPrice,
      inStock,
      condition,
      availableQty,
      weight,
      notes,
      ships,
      // removeAssetIds,
      addChange: false,
      flag,
    }
    onSaveSupplierQuoteInformaton(Information)
  }
  useEffect(() => {
    onSaveContextValue()
    if (!partNumber || !partDescription || !unitPrice || !availableQty || !weight || (!inStock && !ships)) {
      setBttonDisable(true)
    } else {
      setBttonDisable(false)
    }
  }, [partNumber, imagesArray, partDescription, unitPrice, inStock, availableQty, ships, weight, notes, flag])
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  const classes = useStyles()

  useEffect(() => {
    if (flag === false && parsedSupplierData) {
      Object.keys(parsedSupplierData).map((key) => setValue(key, parsedSupplierData[key]))
    }
    if (parsedSupplierData?.condition) {
      setValue('condition', createOption(parsedSupplierData?.condition?.value, parsedSupplierData?.condition?.label))
    }
    if (parsedSupplierData?.partsImages) {
      setImagesArray(parsedSupplierData?.partsImages)
    }
  }, [])
  return (
    <Container className={classes.mainFlex} direction={'column'} gap={'10px 0'}>
      <Container>
        <Container>
          <Typography variant="title" component="h3" color={getColor('grey-g25')} className={classes.p8}>
            Part Details
          </Typography>
        </Container>
        <Container flex justify={'space-between'}>
          <Typography variant="body1" component={'label'} color={getColor('grey-g25')}>
            Part Number*
          </Typography>
          <Controller
            name={'partNumber'}
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <TextField
                id={'partNumber'}
                placeholder={'AT1089909'}
                width={175}
                autocomplete="off"
                name={name}
                onChange={(value) => {
                  onChange(value)
                  setFlag(true)
                }}
                value={value}
                onKeyDown={handleKeyDown}
              />
            )}
          />
        </Container>
        <Container flex justify={'space-between'} className={classes.partNumber}>
          <Typography variant="body1" component={'label'} color={getColor('grey-g25')}>
            Part Description*
          </Typography>
          <Controller
            name={'partDescription'}
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <TextField
                id={'partDescription'}
                placeholder={'Bucket Cylinder'}
                width={175}
                name={name}
                onChange={(value) => {
                  onChange(value)
                  setFlag(true)
                }}
                value={value}
              />
            )}
          />
        </Container>
      </Container>
      <Seperator />
      <Container>
        <Container>
          <Typography variant="title" component="h3" color={getColor('grey-g25')} className={classes.p8}>
            Pricing
          </Typography>
        </Container>
        <Container flex justify={'space-between'} className={classes.pb10}>
          <Typography variant="body1" component={'label'} color={getColor('grey-g25')}>
            Unit Price*
          </Typography>
          <Controller
            name={'unitPrice'}
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <LabelTextField
                id={'ground-rate'}
                name={name}
                type={'number'}
                onChange={onChange}
                labelText={'$'}
                width={'120px'}
                className={classes.labelTextField}
                placeholder={'00.00'}
                value={value}
                // onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              />
            )}
          />
        </Container>
      </Container>
      <Seperator />
      <Container>
        <Container flex justify="space-between">
          <Container className={classes.p10}>
            <Typography variant="title" component={'h3'} color={getColor('grey-g25')}>
              In Stock
            </Typography>
            <Typography variant="paragraph1" component={'label'} color={getColor('grey-g35')}>
              Is this product available in your stock?
            </Typography>
          </Container>
          <Controller
            name={'inStock'}
            control={control}
            render={({ field: { name, value } }) => (
              <SwitchToggle
                name={name}
                value={value}
                // defaultChecked={!value ? supplierReqDetails?.quote_request_in_stock : value}
                defaultChecked={value}
                onChange={() => {
                  setValue(name, !value)
                }}
                id={'in-stock'}
              />
            )}
          />
        </Container>

        <Container className={classes.mainFlex} gap={'5px 0'} direction={'column'}>
          {!inStock && (
            <Container className={classes.mainFlex} gap={'5px 0'} direction={'column'}>
              <Container flex justify="space-between">
                <Typography variant="body1" component={'label'} color={getColor('grey-g25')}>
                  Ships In (Lead Time)*
                </Typography>
                <Controller
                  name={'ships'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <LabelTextField
                      name={name}
                      onChange={onChange}
                      value={value}
                      type={'number'}
                      id={'ships'}
                      labelText={'days'}
                      lablePosition={'right'}
                      width={'120px'}
                      placeholder={'Type'}
                      onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    />
                  )}
                />
                {/* <Controller
                  name={'ships'}
                  control={control}
                  render={({ field: { name, value, ref, onChange } }) => (
                    <Select
                      id={'ships'}
                      className={classes.container}
                      options={shipsOptions}
                      placeholder="Select"
                      value={value}
                      ref={ref}
                      name={name}
                      closeMenuOnScroll={false}
                      searchable={false}
                      width={120}
                      onChange={onChange}
                    />
                  )}
                /> */}
              </Container>
            </Container>
          )}

          <Container flex justify="space-between">
            <Typography variant="body1" component={'label'} color={getColor('grey-g25')}>
              Condition*
            </Typography>
            <Controller
              name={'condition'}
              control={control}
              defaultValue={{ label: 'New Aftermarket', value: 'new_aftermarket' }}
              render={({ field: { name, value, ref, onChange } }) => (
                <Select
                  id={'condition'}
                  className={classes.container}
                  options={options}
                  placeholder="Select"
                  value={value}
                  ref={ref}
                  name={name}
                  closeMenuOnScroll={false}
                  searchable={false}
                  width={175}
                  onChange={onChange}
                />
              )}
            />
          </Container>
          <Container flex justify="space-between">
            <Typography variant="body1" component={'label'} color={getColor('grey-g25')}>
              {/* Available Qty* */}
              Quantity*
            </Typography>
            <Controller
              name={'availableQty'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={'availableQty'}
                  placeholder={'Qty'}
                  width={120}
                  name={name}
                  onChange={(value) => {
                    onChange(value)
                    setFlag(true)
                  }}
                  type={'number'}
                  value={value}
                  onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                />
              )}
            />
            {/* <TextField id={'availableQty'} width={120} placeholder={'10'} /> */}
          </Container>
          <Container flex justify="space-between" className={classes.pb10}>
            <Typography variant="body1" component={'label'} color={getColor('grey-g25')}>
              Weight (lbs)*
            </Typography>
            <Controller
              name={'weight'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <LabelTextField
                  name={name}
                  onChange={onChange}
                  value={value}
                  type={'number'}
                  id={'weight'}
                  labelText={'Lbs'}
                  lablePosition={'right'}
                  width={'120px'}
                  placeholder={'10'}
                  onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                />
              )}
            />
          </Container>
        </Container>
      </Container>
      {/* <Seperator />
      <Container>
        <TitleList
          title={'Photos'}
          tooltip={'Upload JPEG or PNG files (2mb max)'}
          tooltipMaxWidth={'500px'}
          className={classes.freightClass}
          height={'40px'}
        />
        <Container className={classNames(classes.eqpimage, classes.partsimages, classes.pb10)}>
          {imagesArray?.map((file, index) => {
            const props = {
              url: file.file ? URL.createObjectURL(file.file) : file?.part_image,
              index,
              assetId: file.id,
              indexImage: 0,
              disableDropdown: true,
              deleteAddImage: true,
              onClick: () => handleImage(index),
            }
            return <FileUpload key={index} removeImage={removeImage} {...props} />
          })}
          <Dropzone
            multiple
            accept={IMAGE_MIME}
            // onChange={(e) => handleFileChange('productImage', productImages, e)}
            onChange={(e) => handleFileChange('productImage', e)}
            className={classes.textCover}
            label={'Photos'}
            actionComponent={<AddIcon />}
          />
        </Container>
      </Container> */}
      <Seperator />
      <Container>
        <Container>
          <Typography variant="title" component="h3" color={getColor('grey-g25')} className={classes.p8}>
            Notes
          </Typography>
        </Container>
        <Controller
          name={'notes'}
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <TextArea
              id={'notes'}
              placeholder="Write your notes here..."
              rows={3}
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {/* <TextArea id={'notes'} placeholder="Write your notes here..." rows={3} /> */}
      </Container>
      <ImageSliderContainer
        open={openImageDialog}
        onclose={toggleImageDialog}
        imageData={imagesArray}
        // index={imageIndex}
      />
    </Container>
  )
}
SupplierQuoteParts.propTypes = {
  setBttonDisable: PropTypes.func,
}
export default SupplierQuoteParts
