import React, { useEffect } from 'react'
import Dialog from 'components/custom/dialog/Dialog'
import PropTypes from 'prop-types'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { Divider } from '@mui/material'
import Placeholder from 'assets/images/Placeholder.png'
import { useParams } from 'react-router-dom'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import Image from 'components/common/Image'
import { formatPrice, getWellKnownAddress } from 'utils/Utils'
import RadioGroup from 'components/common/radio/RadioGroup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import useQuoteDetails from 'common-hooks/buyer/quotes-details/useQuoteDetails'
import { shippingDetail } from './BuyerQuotesUtils'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    // display: 'unset',
    maxWidth: '1920px',
    // height: '792px',
    boxShadow: 'unset',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  previewHeader: {
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
    border: 'unset',
    '& div:first-child': {
      display: ' flex',
      justifyContent: ' end',
      marginRight: '20px',
    },

    '& button': {
      backgroundColor: '#fff',
      '& div': {
        marginRight: 'unset!important',
      },
    },
  },
  downloadButton: {
    backgroundColor: 'transparent!important',
    border: '0.5px solid #fff',
    color: getColor('light-main'),
    fontSize: '0.875rem',
  },
  mainContainer: {
    // height: '100%',
    margin: '0 auto',
    width: '612px',
    height: '792px',
    // position: 'absolute',
    left: '50%',
    top: '50%',
    // transform: 'translate(-50%, -50%)',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    background: getColor('light-main'),
  },
  subContainer: {
    padding: '40px',
    width: '100%',
    height: '100%',
  },
  text: {
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '14px',
    color: '#13141B',
  },
  otherText: {
    fontWeight: '300',
    fontSize: '10px',
    lineHeight: '14px',
    color: getColor('dark-main'),
    width: '140px',
  },
  header: {
    fontWeight: '700',
  },
  partsDetail: {
    padding: '30px 0px 7px 0px',
  },
  afterDivider: {
    alignItems: 'unset',
    padding: '10px 0px 30px 0px',
  },
  buyNowBtn: {
    width: '142px',
    height: '32px',
    background: getColor('func-positive'),
    borderRadius: '4px',
    '&:hover': {
      background: getColor('func-positive'),
      color: getColor('light-main'),
    },
  },

  counterBtn: {
    width: '100px',
    height: '36px',
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  counterBtnText: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    color: getColor('grey-g35'),
  },
  chckoutText: {
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '14px',
  },
  footerbtns: {
    alignItems: 'flex-Start',
    // paddingTop: '28px ',
  },
  addresDetails: {
    lineHeight: '14px',
    fontSize: '10px',
    fontWeight: '300',
  },
  stockDetails: {
    lineHeight: '14px',
    fontSize: '10px',
    fontWeight: '400',
    color: getColor('grey-g65'),
  },

  quotesDayDetail: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'end',
  },
  partsDetailBox: {
    alignItems: 'flex-Start',
  },
  list: {
    margin: '0px 5px',
    paddingLeft: '17px',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '14px',
  },
  BbillText: {
    fontSize: '10px',
    fontWeight: '400',
  },
  crossPrize: {
    color: '#13141B',

    fontWeight: 500,
    fontSize: '10px',
  },
  radioBtn: {
    fontSize: '10px!important',
    // height: '20px',
    '& span': {
      fontSize: '10px!important',
    },
  },
  shppingAddress: {
    display: 'inline-grid',
  },
  notesContainer: {
    alignItems: 'baseline',
  },
  dateTypography: {
    fontWeight: '300',
    fontSize: '10px',
    lineHeight: ' 14px',
    color: '#13141B',
  },
  acknowledge: {
    cursor: ' not-allowed',
    pointerEvents: ' none',
    '& svg': {
      width: 11,
      height: 11,
    },
    '& .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiTypography-body1': {
      fontWeight: 300,
      fontSize: '10px',
      lineHeight: '14px',
      color: '#13141B',
    },
  },
  qntyTypography: {
    fontWeight: '300',
    fontSize: ' 10px',
    lineHeight: '14px',
    color: ' #13141B',
  },
  notesText: {
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '14px',
    color: ' #13141B',
  },
  shipingrootFlex: {
    display: 'flex',
    gap: '0 67px',
    width: '37%',
  },
  shipingOption: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '14px',
    color: '#13141B',
    marginBottom: '5px',
    display: 'inline-block',
  },
  bandImg: {
    border: '1px solid',
    borderColor: getColor('grey-g65'),
  },
  shipingOptionRoot: {
    width: '100%',
    '& ul': {
      padding: '0',
      margin: '0',
      '& li': {
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '5px',
      },
    },
    '& .shipingRadio': {
      cursor: ' not-allowed',
      pointerEvents: ' none',
      '& .MuiRadio-root': {
        padding: '0',
        paddingRight: '5px',
        paddingLeft: '7px',
      },
      '& .MuiFormControlLabel-label': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontStyle: 'normal',
          fontWeight: 300,
          fontSize: '10px',
          lineHeight: '14px',
          color: '#13141B',
        },
      },
    },
  },
  shipingOptionRoot2: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
    width: '193px',
  },
  radioValue: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '14px',
    color: '#13141B',
  },
  termFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '81px',
    paddingBottom: '81px',
  },
  totalContainer: {
    width: '37%',
    float: 'right',
  },
  imageDiv: {
    marginBottom: '15px',
  },
  shippingToDiv: {
    marginTop: '15px',
  },
}))

const PreviewDialog = ({ open, onclose, quoteReqId, logo, name }) => {
  const classes = useStyles()
  const params = useParams()
  const { supplierReqDetails } = useReqSupplierDetails(params?.id, quoteReqId)
  const { quotesDetailDataList } = useQuoteDetails(params?.id)
  const methods = useForm()
  const { control, setValue } = methods
  const [options] = useWatch({
    control,
    name: ['options'],
  })
  useEffect(() => {
    setValue('options', supplierReqDetails?.quote_request_delivery_options?.freight_next_day)
  }, [setValue, supplierReqDetails])
  function freightTotal(freight) {
    let total = supplierReqDetails?.quote_request_amount
    if (freight) {
      total += parseInt(freight, 10)
    }
    return total
  }
  const createDate = supplierReqDetails?.created_at
  const validDate = supplierReqDetails?.valid_until

  return (
    <Dialog className={classes.root} open={open} onClose={onclose} onClick={() => onclose()}>
      <DialogHead onClose={() => onclose} className={classes.previewHeader} />
      <Container className={classes.mainContainer} id={'divToPrint'} onClick={(e) => e.stopPropagation()}>
        {shippingDetail?.map((data, id) => (
          <Container className={classes.subContainer} key={id}>
            <Container flex justify={'space-between'} className={classes.imageDiv}>
              <Image src={logo || Placeholder} alt="Quotes Preview" width={'43.88px'} height={'43.88px'} />
              <Typography variant="body1" className={classes.header}>
                Quote
              </Typography>
            </Container>
            <Container>
              <Container flex justify={'space - between'}>
                <Container className={classes.otherText}>
                  <Typography className={classes.text} component={'p'}>
                    {name}
                  </Typography>
                  <Typography className={classes.addresDetails}>
                    {getWellKnownAddress(
                      supplierReqDetails?.supplier_addr_addrLine1,
                      supplierReqDetails?.supplier_addr_addrLine2
                    )}
                  </Typography>
                </Container>
                <Container flex width={'80%'} justify={'flex-end'} gap={'30px'}>
                  <Container className={classes.quotesDayDetail}>
                    <Typography className={classes.dateTypography}>Quote:</Typography>
                    <Typography className={classes.dateTypography}>Date:</Typography>
                    <Typography className={classes.dateTypography}>Valid Until:</Typography>
                  </Container>
                  <Container className={classes.quotesDayDetail}>
                    <Typography className={classes.dateTypography}>{supplierReqDetails?.quote_number}</Typography>
                    <Typography className={classes.dateTypography}>
                      {formatDate(createDate, DATE_FORMAT_PRIMARY)}
                    </Typography>
                    <Typography className={classes.dateTypography}>
                      {formatDate(validDate, DATE_FORMAT_PRIMARY)}
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container className={classes.shippingToDiv}>
              <Container>
                <Container>
                  <Typography className={classes.text}>Shipping To :</Typography>
                </Container>
                <Container className={classes.otherText}>
                  <Typography className={classes.addresDetails}>
                    {getWellKnownAddress(
                      quotesDetailDataList?.cmp_billing_addr_addr_line_1,
                      quotesDetailDataList?.cmp_billing_addr_addr_line_2
                    )}
                  </Typography>
                </Container>
              </Container>
            </Container>
            {/* Image shipping detail starts */}
            <Container flex className={classes.partsDetail} gap={'0 10px'}>
              <Container width={'322px'}>
                <Typography className={classes.text}>Parts</Typography>
              </Container>
              <Container width={100} justify={'flex-end'}>
                <Typography className={classes.text} component={'p'}>
                  Quantity
                </Typography>
              </Container>
              <Container width={100} justify={'flex-end'}>
                <Typography className={classes.text} component={'p'}>
                  Total
                </Typography>
              </Container>
            </Container>
            <Divider />
            <Container flex justify={'flex-start'} gap={'5px'} className={classes.afterDivider}>
              <Container>
                <Image
                  src={supplierReqDetails?.quote_assets[0]?.equip_cover_image || Placeholder}
                  alt="Quotes Preview"
                  width={'62px'}
                  height={'58px'}
                  className={classes.bandImg}
                />
              </Container>
              <Container
                width={'345px'}
                flex
                direction={'column'}
                justify={'flex-start'}
                className={classes.partsDetailBox}
              >
                <Typography className={classes.text}>
                  {supplierReqDetails?.brand_name} - {supplierReqDetails?.part_name}{' '}
                  {supplierReqDetails?.part_number !== null ? `- ${supplierReqDetails?.part_number}` : ''}
                </Typography>
                <Typography className={classes.stockDetails}>
                  {supplierReqDetails?.quote_request_condition === 'new_aftermarket' && 'New - Aftermarket'}
                  {supplierReqDetails?.quote_request_condition === 'new_oem' && 'New - OEM'}
                  {supplierReqDetails?.quote_request_condition === 'used' && 'Used'}
                  {supplierReqDetails?.quote_request_weight && ` • ${supplierReqDetails?.quote_request_weight}lbs`}
                </Typography>
                {supplierReqDetails?.quote_request_in_stock && (
                  <Typography className={classes.stockDetails}>
                    {supplierReqDetails?.quote_request_in_stock ? 'In Stock' : ''}
                  </Typography>
                )}
                {!supplierReqDetails?.quote_request_in_stock && (
                  <Typography className={classes.stockDetails}>
                    {`Ships in  ${supplierReqDetails?.quote_request_ships_in} business days`}
                  </Typography>
                )}

                {/* <Container className={classes.acknowledge}>
                  {supplierReqDetails?.quote_request_can_return && <Typography>Item is non-returnable</Typography>}
                </Container> */}
              </Container>
              <Container flex gap={'0 10px'} className={classes.partsDetailBox}>
                <Container width={100} justify={'flex-end'}>
                  <Typography className={classes.qntyTypography}>{supplierReqDetails?.quantity}</Typography>
                </Container>
                <Container width={100} justify={'flex-end'}>
                  <Typography className={classes.crossPrize} width={100} justify={'flex-end'}>
                    {formatPrice(supplierReqDetails?.quote_request_amount) || formatPrice(0)}
                  </Typography>
                </Container>
              </Container>
            </Container>

            <Container flex className={classes.notesContainer} justify="space-between">
              <Container width={'330px'}>
                {supplierReqDetails?.note && (
                  <>
                    <span className={classes.text}>Notes:</span>
                    <span className={classes.notesText}>{supplierReqDetails?.note}</span>
                  </>
                )}
              </Container>
              <Container className={classes.shipingrootFlex} justify="space-between">
                <Container className={classes.shipingOptionRoot}>
                  <Typography className={classes.shipingOption}>Shipping Options</Typography>
                  <Container>
                    <Controller
                      name={'options'}
                      control={control}
                      defaultValue={supplierReqDetails?.quote_request_delivery_options?.freight_next_day}
                      render={({ field: { name, value } }) => (
                        <RadioGroup
                          defaultValue={supplierReqDetails?.quote_request_delivery_options?.freight_next_day}
                          value={value}
                          onChange={(e) => {
                            setValue(name, e.target.value)
                          }}
                        >
                          <ul>
                            <li className={classes.shipingOptionRoot2}>
                              {supplierReqDetails?.quote_request_delivery_options?.freight_next_day && (
                                <>
                                  <Typography className={classes.radioValue}>Next Day Delivery</Typography>
                                  <Typography variant="paragraph1" className={classes.radioValue}>
                                    {formatPrice(supplierReqDetails?.quote_request_delivery_options?.freight_next_day)}
                                  </Typography>
                                </>
                              )}
                            </li>
                            <li>
                              {supplierReqDetails?.quote_request_delivery_options?.freight_second_day && (
                                <>
                                  <Typography className={classes.radioValue}>2nd Day</Typography>
                                  <Typography variant="paragraph1" className={classes.radioValue}>
                                    {formatPrice(
                                      supplierReqDetails?.quote_request_delivery_options?.freight_second_day
                                    )}
                                  </Typography>
                                </>
                              )}
                            </li>
                            <li>
                              {supplierReqDetails?.quote_request_delivery_options?.freight_ground && (
                                <>
                                  <Typography className={classes.radioValue}>Ground</Typography>
                                  <Typography variant="paragraph1" className={classes.radioValue}>
                                    {formatPrice(supplierReqDetails?.quote_request_delivery_options?.freight_ground)}
                                  </Typography>
                                </>
                              )}
                            </li>
                            <li>
                              {supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up && (
                                <>
                                  <Typography className={classes.radioValue}>Pick Up</Typography>
                                  <Typography variant="paragraph1" className={classes.radioValue}>
                                    {formatPrice(supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up)}
                                  </Typography>
                                </>
                              )}
                            </li>
                            <li>
                              {supplierReqDetails?.quote_request_delivery_options?.mdo_ltl && (
                                <>
                                  <Typography className={classes.radioValue}>LTL</Typography>
                                  <Typography variant="paragraph1" className={classes.radioValue}>
                                    {formatPrice(supplierReqDetails?.quote_request_delivery_options?.mdo_ltl)}
                                  </Typography>
                                </>
                              )}
                            </li>
                            <li>
                              {supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery && (
                                <>
                                  <Typography className={classes.radioValue}>Local Delivery</Typography>
                                  <Typography variant="paragraph1" className={classes.radioValue}>
                                    {formatPrice(
                                      supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery
                                    )}
                                  </Typography>
                                </>
                              )}
                            </li>
                          </ul>
                        </RadioGroup>
                      )}
                    />
                  </Container>
                </Container>
              </Container>
            </Container>

            <Container className={classes.termFlex}>
              {/* {supplierReqDetails?.quote_request_can_return && ( */}
              <Container>
                <Typography className={classes.text}>Terms & Conditions</Typography>
                {/* <ul className={classes.list}>
                    <li>Refundable: No</li>
                  </ul> */}
                <ul className={classes.list}>
                  <li>Returnable:{supplierReqDetails?.quote_request_can_return ? ' Yes ' : ' No '} </li>
                  {supplierReqDetails?.quote_request_is_warranty && <li> Warranty: 365 days </li>}
                  {supplierReqDetails?.quote_request_can_cancel_product && <li>Cancelable: Prior to shipment</li>}
                </ul>
              </Container>
              {/* )} */}

              <Container className={classes.totalContainer}>
                <Container flex justify={'space-between'}>
                  <Typography className={classes.BbillText}>Subtotal</Typography>
                  <Typography className={classes.BbillText}>
                    {formatPrice(supplierReqDetails?.quote_request_amount)}
                  </Typography>
                </Container>
                <Container flex justify={'space-between'}>
                  <Typography className={classes.BbillText}>Freight</Typography>
                  <Typography className={classes.BbillText}>{formatPrice(options)}</Typography>
                </Container>
                <Divider color={'1px solid '} />
                <Container flex justify={'space-between'}>
                  <Typography className={classes.text}>Total</Typography>
                  <Typography className={classes.BbillText}>{formatPrice(freightTotal(options))}</Typography>
                </Container>
                <Divider color={'1px solid '} />
              </Container>
            </Container>
          </Container>
        ))}
      </Container>
    </Dialog>
  )
}
PreviewDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  quoteReqId: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
}
export default PreviewDialog
