import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import React, { useEffect, useState } from 'react'
import { getColor } from 'theme/colors'
import { CaretRightIcon } from 'assets/icons'

const useStyle = makeStyles(() => ({
  button: {
    padding: '5px 10px',
    backgroundColor: 'transparent',
    color: getColor('grey-g35'),
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  activeButton: {
    padding: '5px 10px',
    backgroundColor: 'transparent',
    color: getColor('func-info'),
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}))

export default function Steps({ TabsList, currentStep }) {
  const classes = useStyle()
  const [active, setActive] = useState(0)
  // const changeTabslist = (index) => {
  //   handleTabChange(index)
  //   setActive(index)
  // }

  useEffect(() => {
    setActive(currentStep)
  }, [currentStep])

  return (
    <Container flex direction="row" justify="flex-start">
      {TabsList.map((tab, index) => (
        <Container key={index} flex>
          <Button
            disableRipple
            className={index === active ? classes.activeButton : classes.button}
            // onClick={() => changeTabslist(tab.tabIndex)}
          >
            <Typography variant="title">{tab.name}</Typography>
          </Button>
          {TabsList.length !== index + 1 && <CaretRightIcon />}
        </Container>
      ))}
    </Container>
  )
}
Steps.propTypes = {
  currentStep: PropTypes.number,
  TabsList: PropTypes.array,
  // handleTabChange: PropTypes.func,
}
