import React, { useState } from 'react'
import Container from 'components/common/Container'
import { TabPanel, Tabs } from 'components/common/Tabs'
import SearchField from 'components/common/search/SearchField'
import { makeStyles } from '@mui/styles'
import useSearch from 'common-hooks/useSearch'
import { getColor } from 'theme/colors'
import Typography from 'components/common/Typography'
import HtmlTitle from 'utils/HtmlTitle'
import { tabObject } from 'containers/buyer-quotes/BuyerQuotesUtils'
import { quotesData } from 'containers/buyer-quote-details/BuyerQuoteUtils'
import OfferCard from './OfferCard'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    padding: '30px',
  },
  marginHead: {
    position: 'sticky',
    top: '60px',
    background: getColor('system-bg'),
    zIndex: '1',
    '& input': {
      background: '#fff',
    },
  },
  img: {
    margin: '151px auto',
  },
  list: {
    height: '83%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  progress: {
    color: getColor('orange-main'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const OffersList = () => {
  const classes = useStyles()
  const { search, onSearch } = useSearch()
  const [value, setValue] = useState(0)
  //   const [status, setStatus] = useState('active')
  const handleChange = (event, newValue) => {
    setValue(newValue)
    // switch (newValue) {
    //   case 0:
    //     setStatus('active')
    //     break
    //   case 1:
    //     setStatus('closed')
    //     break

    //   default:
    //     setStatus('active')
    //     break
    // }
  }
  return (
    <Container height={'92.9vh'}>
      <HtmlTitle title={'Quotes'} />
      <Container className={classes.mainContainer} width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            <Tabs value={value} onchange={handleChange} array={tabObject} />
            <Container flex gap={'20px'}>
              <Container>
                <SearchField
                  id={'searchField'}
                  onSearch={(e) => {
                    onSearch(e)
                  }}
                  value={search}
                />
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={classes.list}>
        <TabPanel value={value} index={0}>
          {/* {quotesData?.length === 0 && search === '' && (
            <>
              <Container height={'77vh'} flex direction={'column'}>
                <Container flex direction={'column'}>
                  <Container>
                    <img src={placeholderCenter} alt="LET'S SPARK UP LOGO" width={'305px'} height={'290px'} />
                  </Container>
                </Container>
              </Container>
            </>
          )} */}

          {/* {quotesData?.length !== 0 && (
            <Container height={'100%'}>
              {quotesData?.map((obj, index) => (
                <QuoteActiveCard
                  key={index}
                  quoteImg={obj?.quote_assets_url}
                  quoteId={obj?.id}
                  brandName={obj?.brand_name}
                  brandSerialNum={obj?.brand_serial_number}
                  brandModelNum={obj?.brand_model_number}
                  partName={obj?.part_name}
                  quantity={obj?.quantity}
                  equipName={obj?.equip_name}
                  partNo={obj?.part_number}
                  supplier={obj?.supplier}
                  publicPrivate={obj?.private}
                  brandNameRef={obj?.brand_name_ref}
                  imagesCount={obj?.quote_assets_count}
                  createdAt={obj?.created_at}
                  quoteCount={obj?.requests_count}
                  supplierCount={obj?.invites_count}
                />
              ))}
            </Container>
          )} */}
          <Container height={'100%'}>
            <OfferCard />
          </Container>
          {quotesData?.length === 0 && search !== '' && (
            <Container flex>
              <Typography>No Data Found</Typography>
            </Container>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Container height={'77vh'} flex direction={'column'}>
            <OfferCard />
          </Container>
        </TabPanel>
      </Container>
    </Container>
  )
}

export default OffersList
