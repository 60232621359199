/* eslint-disable camelcase */
import Container from 'components/common/Container'
import { React, useState, useEffect } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import TextField from 'components/common/text/TextField'
import RadioGroup from 'components/common/radio/RadioGroup'
import Radio from 'components/common/radio/Radio'
import Typography from 'components/common/Typography'
import Checkbox from 'components/common/Checkbox'
import { getColor } from 'theme/colors'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import GooglePlaces from 'components/custom/GooglePlaces'
import TextArea from 'components/common/text/TextArea'
import { parsingShippingAddress } from 'containers/buyer-quote-details/BuyerQuoteUtils'
import { yupResolver } from '@hookform/resolvers/yup'
import { getFormError } from 'utils/form/form'

import { getWellKnownAddress } from 'utils/Utils'
import useToastify from 'common-hooks/useToastify'
import LabelTextField from 'components/common/text/LabelTextField'
// import { BuyerQuoteDataContext, parsingAddress } from './BuyerQuoteUtils'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: '690px',
    overflowY: 'scroll',
  },
  group: {
    display: 'flex',
    gap: '0 16px',
  },
  radiogrp: {
    display: 'flex',
    gap: '0 26px',
    height: '20px',
    // flexDirection: 'row',
  },
  pb10: {
    paddingBottom: '10px',
  },
  billing: {
    fontWeight: '700',
    color: getColor('grey-g25'),
    paddingTop: '5px',
  },
  bilingAddrres: {
    height: '20px',
  },
})

export default function ShipingAddressDialog({ open, onClose, profileData, setShipingAddressData }) {
  const schema = yup.object({
    fullname: yup.string().required('Full name is required.'),
    email: yup.string().required('email is required.'),
    phone: yup
      .string()
      .nullable(false)
      .matches(/^\d{10}$/, 'Please enter the valid mobile number')
      .required(),
  })

  const [radioValue, setRadioValue] = useState('business')
  // const options = ['+1']
  const [checkbox, setCheckBox] = useState(false)
  const { toastMessage } = useToastify()
  const handleOnradioValue = (e) => {
    setRadioValue(e.target.value)
  }

  const {
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    register,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    const parseData = parsingShippingAddress(profileData)
    if (profileData && parseData) {
      Object.keys(parseData).map((key) => setValue(key, parseData[key]))
      setValue('fullname', profileData?.user_address?.full_name)
      setValue('email', profileData?.user_address?.email)
      if (profileData?.user_address?.type === 'residence') {
        setValue('company_name', '')
      } else {
        setValue('company_name', profileData?.user_address?.company_name)
      }
      if (profileData?.user_address?.phone) {
        const split = profileData?.user_address?.phone?.split('-')
        setValue('prefix', profileData?.user_address?.phone?.includes('+') ? split?.[0]?.replace('+', '') : '1')
        setValue('phone', profileData?.user_address.phone?.includes('+') ? split?.[1] : profileData?.user_address.phone)
      }
      if (profileData?.user_address?.delivery_instructions) {
        setValue('delivery_instructions', profileData?.user_address?.delivery_instructions)
      }
      if (profileData?.user_address?.unit_number) {
        setValue('UnitNumber', profileData?.user_address?.unit_number)
      }
      if (profileData?.user_address?.type) {
        setRadioValue(profileData?.user_address?.type)
      }

      // setValue('type', 'residence')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData])

  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    if (radioValue === 'business' && !data?.company_name) {
      toastMessage('error', 'Please enter company name')
      onclose(false)
    }
    if (data?.prefix === '' || data?.prefix === undefined) {
      toastMessage('error', 'Country code is required')
      return 0
    }
    const company_delivery_addr = {
      ...data?.company_delivery_addr,
    }
    const company_billing_addr = {
      ...data?.company_billing_addr,
    }
    const dialogDetails = {
      company_name: data.company_name,
      email: data.email,
      // phone: data.phone.toString(),
      phone: `+${data?.prefix}-${data?.phone}`.toString(),
      full_name: data.fullname,
      unit_number: data.UnitNumber,
      delivery_instructions: data.delivery_instructions,
      type: radioValue,
    }

    setShipingAddressData({
      ...data,
      ...(!checkbox ? { company_billing_addr: company_delivery_addr } : { company_billing_addr }),
      dialogDetails,
    })
    onClose()
  }
  const classes = useStyles()
  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead
          title={'Shipping Address'}
          onClose={() => {
            clearErrors()
            onClose(true)
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.dialogbody} direction={'column'} gap={'10px 0'}>
            <RadioGroup className={classes.radiogrp} onChange={handleOnradioValue} value={radioValue}>
              <Container className={classes.radiogrp}>
                <Radio name={'business'} label={'Business'} checked={radioValue === 'business'} value={'business'} />
                <Radio
                  name={'residence'}
                  label={'Residence'}
                  checked={radioValue === 'residence'}
                  value={'residence'}
                />
              </Container>
            </RadioGroup>
            {radioValue === 'business' ? (
              <>
                <Controller
                  name={'fullname'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      id={'name'}
                      name={name}
                      label={'Full Name*'}
                      placeholder={'e.g. John Doe'}
                      value={value}
                      type="text"
                      onChange={onChange}
                      error={getFormError(errors, 'fullname')}
                    />
                  )}
                />
                <Controller
                  name={'company_name'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      id={'company_name'}
                      name={name}
                      value={value}
                      label={'Company Name*'}
                      placeholder={'e.g. John Deere'}
                      type="text"
                      onChange={onChange}
                      error={getFormError(errors, 'company_name')}
                    />
                  )}
                />
              </>
            ) : (
              <Controller
                name={'fullname'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={'Full-Name'}
                    name={name}
                    label={'Full Name*'}
                    placeholder={'e.g. John Deere'}
                    type="text"
                    onChange={onChange}
                    value={value}
                    error={getFormError(errors, 'fullname')}
                  />
                )}
              />
            )}

            <Controller
              name={'email'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={'Email'}
                  name={name}
                  label={'Email*'}
                  placeholder={'john.d@partsclub.us'}
                  type="email"
                  onChange={onChange}
                  value={value}
                  error={getFormError(errors, 'email')}
                />
              )}
            />
            <Container flex justify="space-between">
              {/* <Controller
                name={'phone'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropDownTextField
                    options={options}
                    disabled
                    value={value}
                    id={'phone'}
                    type={'number'}
                    className={classes.phoneFlied}
                    label={'Phone Number*'}
                    control={control}
                    onChange={onChange}
                    placeholder="235 567 2355"
                    error={getFormError(errors, 'phone')}
                    width={'240px'}
                  />
                )}
              /> */}
              <Container>
                <Typography variant="paragraph2" color={getColor('grey-g35')}>
                  Phone Number*
                </Typography>
                <Container flex gap="2px">
                  <Controller
                    name={'prefix'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <LabelTextField
                        id={name}
                        type={'number'}
                        labelText={'+'}
                        className={classes.labelTextField}
                        placeholder={'1'}
                        onChange={onChange}
                        name={name}
                        value={value}
                        width={'50px'}
                        // error={getFormError(errors, 'prefix')}
                      />
                    )}
                  />
                  <TextField
                    id={'phone'}
                    type={'number'}
                    control={control}
                    {...register('phone', { required: true })}
                    error={getFormError(errors, 'phone')}
                    placeholder="235 567 2355"
                    width={'180px'}
                  />
                </Container>
              </Container>
              <Controller
                name={'UnitNumber'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={'Unit Number'}
                    name={name}
                    label={'Unit Number'}
                    placeholder={'e.g. 21'}
                    onChange={onChange}
                    value={value}
                    width={'150px'}
                  />
                )}
              />
            </Container>
            <Controller
              name={'company_delivery_addr'}
              control={control}
              render={({ field: { name } }) => (
                <GooglePlaces
                  id={name}
                  name={name}
                  label="Location"
                  onClearInput={() => setValue(name, { id: getValues()?.company_delivery_addr?.id })}
                  value={
                    getValues()?.company_delivery_addr?.addr_line_1
                      ? getWellKnownAddress(
                          getValues()?.company_delivery_addr?.addr_line_1,
                          getValues()?.company_delivery_addr?.addr_line_2
                        )
                      : ''
                  }
                  spacing={3}
                  placeholder={'e.g. 4517 Washington Ave'}
                  onSelect={(loc, i) => {
                    if (i === 0)
                      setValue(
                        'company_delivery_addr',
                        formatAddressOfGAPI(
                          loc.addr,
                          loc.addr_line_1,
                          loc.addr_line_2,
                          null,
                          getValues()?.company_delivery_addr?.id
                        )
                      )
                    else
                      setValue(
                        'company_billing_addr',
                        formatAddressOfGAPI(
                          loc.addr,
                          loc.addr_line_1,
                          loc.addr_line_2,
                          null,
                          getValues()?.company_billing_addr?.id
                        )
                      )
                  }}
                />
              )}
            />
            <Container>
              <Typography variant="paragraph2">Delivery Instructions</Typography>
              <Controller
                name={'delivery_instructions'}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <TextArea name={name} onChange={onChange} value={value} placeholder="Paragraph" />
                )}
              />
            </Container>

            <Typography variant="body1" className={classes.billing}>
              Billing Address
            </Typography>
            <Checkbox
              className={classes.bilingAddrres}
              name={'biling-address'}
              id={'biling-address'}
              value={!checkbox}
              label={'Billing address is same as shipping address'}
              onChange={() => setCheckBox(!checkbox)}
            />
            {checkbox && (
              <Container flex gap={'20px'}>
                <Controller
                  name={'company_billing_addr'}
                  control={control}
                  render={({ field: { name } }) => (
                    <GooglePlaces
                      id={name}
                      name={name}
                      label="Location"
                      spacing={3}
                      placeholder={'e.g. 4517 Washington Ave'}
                      onClearInput={() => setValue(name, { id: getValues()?.company_billing_addr?.id })}
                      value={
                        getValues()?.company_billing_addr?.addr_line_1
                          ? getWellKnownAddress(
                              getValues()?.company_billing_addr?.addr_line_1,
                              getValues()?.company_billing_addr?.addr_line_2
                            )
                          : ''
                      }
                      onSelect={(loc, i) => {
                        if (i === 0)
                          setValue(
                            'company_delivery_addr',
                            formatAddressOfGAPI(
                              loc.addr,
                              loc.addr_line_1,
                              loc.addr_line_2,
                              null,
                              getValues()?.company_delivery_addr?.id
                            )
                          )
                        else
                          setValue(
                            'company_billing_addr',
                            formatAddressOfGAPI(
                              loc.addr,
                              loc.addr_line_1,
                              loc.addr_line_2,
                              null,
                              getValues()?.company_billing_addr?.id
                            )
                          )
                      }}
                    />
                  )}
                />
              </Container>
            )}
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button
                variant="secondary"
                border
                width={77}
                onClick={() => {
                  clearErrors()
                  onClose()
                }}
              >
                Cancel
              </Button>
              <Button width={70} type="submit">
                Apply
              </Button>
            </Container>
          </DialogFooter>
        </form>
      </Dialog>
      {/* <Button onClick={onclose}>Open Dilaog</Button> */}
    </Container>
  )
}
ShipingAddressDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  profileData: PropTypes.array,
  setShipingAddressData: PropTypes.func,
}
