import PropTypes from 'prop-types'
import useToggle from 'common-hooks/useToggle'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import { getColor } from 'theme/colors'
import Button from 'components/common/Button'
// import UserProfileFillIcon from 'assets/icons/UserIcon.svg'
import Placeholder from 'assets/images/Placeholder.png'
import classNames from 'classnames'
import Typography from 'components/common/Typography'
import Image from 'components/common/Image'
import PreviewDialog from 'containers/buyer-quotes/PreviewDialog'
import { CaretDownIcon } from 'assets/icons'
import Chip from 'components/common/Chip'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import { useNavigate, useParams } from 'react-router-dom'
import CounterDialog from './CounterDialog'
import CounterOfferCard from './CounterOfferCard'
import ConfirmationDialog from './ConfirmationDialog'
import useGetCounterListBuyer from 'common-hooks/buyer/counter/useGetCounterListBuyer'
import { useState } from 'react'
import useOrderDetails from 'common-hooks/orders/useOrderDetails'
import { getFlag } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    // maxWidth: '1200px',
    margin: '20px auto',
    height: '166px',
    padding: '20px',
    backgroundColor: '#fff',
    border: '1px solid #F0F0F4',
    marginBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },
  mainFlex: {
    display: 'flex',
  },
  cardLeft: {
    width: '80px',
    height: '80px',
    '& img': {
      width: '80px',
      ObjectFit: 'cover',
      height: '100%',
      border: '1px solid #F0F0F4 ',
    },
  },
  cardRight: {
    width: '93%',
  },
  oldPrice: {
    fontWeight: '300',
    fontSize: '1.25rem',
    color: getColor('grey-g65'),
  },
  price: {
    fontWeight: '600',
    fontSize: '20px',
    color: getColor('dark-main'),
  },
  title: {
    color: getColor('dark-main'),
  },
  paragraphColor: {
    color: getColor('grey-g35'),
  },
  stockColor: {
    color: getColor('func-positive'),
  },
  shipColor: {
    color: getColor('func-info'),
  },
  partnumberColor: {
    color: getColor('grey-g65'),
  },

  viewquoteColor: {
    color: '#2680D9!important',
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  pb5: {
    paddingTop: '5px',
    paddingBottom: '8px',
  },
  pb10: {
    paddingBottom: '10px',
    alignItems: 'center',
  },
  titleAlign: {
    alignItems: 'center',
  },
  flag: {
    display: 'flex',
    alignItems: 'center',
  },
  counterBtn: {
    margin: '0 10px',
  },
  adChip: {
    width: '28px',
    height: '18px',
    borderRadius: '2px',
    backgroundColor: '#F0F0F4',
    '& span': {
      padding: '0',
      fontWeight: '500',
      height: '100%',
      fontSize: '12px',
      lineHeight: ' 14px',
      color: getColor('grey-g65'),
    },
  },
  premimumChip: {
    height: '20px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    color: '#13141B',
    borderRadius: '100px',
  },
  stockColorFalse: {
    color: getColor('func-hint'),
  },
  shppingBtn: (props) => ({
    '& svg': {
      rotate: props.counterCard ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  counterSection: {
    background: '#fff',
    borderLeft: '1px solid #F0F0F4',
    borderRight: '1px solid #F0F0F4',
    borderBottom: '1px solid #F0F0F4',
    padding: '20px',
    marginTop: '-20px',
  },
  number: {
    width: '20px',
    height: '16px',
    borderRadius: '100px',
    background: '#2680D9',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
  offerCardGrid: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default function BuyerQuoteCard({
  logo,
  name,
  amount,
  city,
  state,
  instock,
  eqpName,
  brandName,
  partName,
  partNumber,
  shipping,
  condition,
  premium,
  quoteReqId,
  companyname,
  listMutate,
  country,
}) {
  const [approveDialog, setApproveDialog] = useToggle(false)
  const [previewToggle, setPreviewToggle] = useToggle(false)
  const [counterCard, toggleCounterCard] = useToggle(true)
  const [counterToggle, setCounterToggle] = useToggle(false)
  const [currectPrice, setCurrectPrice] = useState(amount)
  const navigate = useNavigate()
  const params = useParams()
  const { counterData, mutate } = useGetCounterListBuyer(quoteReqId)
  const [offerPrice, setOfferPrice] = useState()
  const classes = useStyles({ counterCard })
  const { supplierReqDetails, supplierdetailsMutate } = useReqSupplierDetails(params?.id, quoteReqId)
  const [orderId, setOrderId] = useState()
  const { orderDetailsData } = useOrderDetails(orderId)
  return (
    <>
      <Container className={classes.root}>
        <Container className={classNames(classes.mainFlex, classes.pb10)} gap={'0 10px'}>
          <Container className={classes.cardLeft}>
            {/* <img src={QuoteImage} alt="placehodler" />
             */}
            <Image src={logo !== null ? logo : Placeholder} alt="placehodler" />
          </Container>
          <Container className={classes.cardRight}>
            <Container className={classNames(classes.mainFlex, classes.titleAlign)} justify={'space-between'}>
              <Container className={classNames(classes.mainFlex, classes.titleAlign)} gap={'0 5px'}>
                <Typography variant="title" className={classes.title}>
                  {/* AAM Parts & Equipment */}
                  {name}
                </Typography>

                {premium && <Chip className={classes.premimumChip} label={'Premium'} />}
              </Container>
              <Container className={classes.mainFlex} gap={'0 5px'}>
                <Typography className={classes.price}>${amount}</Typography>
              </Container>
            </Container>
            <Container className={classNames(classes.mainFlex, classes.pb5)} justify={'space-between'}>
              <Container className={classNames(classes.mainFlex, classes.titleAlign)} gap={'0 5px'}>
                <Typography variant="paragraph1" className={classes.paragraphColor}>
                  {city}, {state}
                </Typography>
                {getFlag(country)}
              </Container>
              {instock && (
                <Typography variant="body1" className={classes.stockColor}>
                  In stock
                </Typography>
              )}
            </Container>
            <Container className={classes.mainFlex} justify={'space-between'}>
              <Typography variant="paragraph1" className={classes.paragraphColor}>
                {brandName} <span>•</span> {eqpName} <span>•</span> {partName}
              </Typography>
              <Container className={classes.mainFlex} gap={'0 5px'}>
                <Typography variant="body1" className={classes.paragraphColor}>
                  {condition === 'new_aftermarket' && 'New - Aftermarket'}
                  {condition === 'new_oem' && 'New - OEM'}
                  {condition === 'used' && 'Used'}
                </Typography>
                <span>•</span>
                {instock ? (
                  <Typography className={classes.shipColor} variant="body1">
                    Ships Today
                  </Typography>
                ) : (
                  <Typography variant="body1" className={classes.stockColorFalse}>
                    Ships in {shipping} Days
                  </Typography>
                )}
              </Container>
            </Container>
          </Container>
        </Container>
        <Container className={classes.mainFlex} justify={'flex-end'}>
          {/* <Container flex>
            {<UserProfileFillIcon />}
            <Typography variant="body2" className={classes.partnumberColor}>
              Part Number Match -
            </Typography>
          </Container> */}
          <Container className={classes.mainFlex}>
            <Button className={classes.viewquoteColor} variant={'transparent'} onClick={() => setPreviewToggle(true)}>
              View Quote
            </Button>
            {supplierReqDetails?.status === 'closed' && !counterData?.[0]?.type && (
              <Button
                variant="secondary"
                className={classes.counterBtn}
                border
                onClick={() => navigate(`/orders/AwaitingShipment/${orderDetailsData?.order_id}/${orderId}`)}
              >
                View Order
              </Button>
            )}

            {!counterData?.[0]?.type && (
              <>
                {supplierReqDetails?.status !== 'closed' && (
                  <>
                    <>
                      {supplierReqDetails?.quote_request_can_negotiate && (
                        <Button
                          variant="secondary"
                          className={classes.counterBtn}
                          border
                          width={85}
                          onClick={() => setCounterToggle(true)}
                        >
                          Counter
                        </Button>
                      )}
                    </>

                    <Button width={100} variant={'positive'} onClick={() => setApproveDialog(true)}>
                      Approve
                    </Button>
                  </>
                )}
              </>
            )}
          </Container>
        </Container>
      </Container>
      {counterData?.[0]?.type && (
        <Container className={classes.counterSection}>
          <Container flex justify="space-between">
            <Container flex gap={'0 5px'}>
              <Typography variant="title" color={getColor('grey-g25')}>
                Counter Offers
              </Typography>
              <Typography className={classes.number}>1</Typography>

              {/* {counterData?.map((obj, index) => (
                <>
                  {obj?.type === 'customer' && (
                    <Typography className={classes.number} key={index}>
                      {obj?.pricing_count}
                    </Typography>
                  )}
                </>
              ))} */}
            </Container>
            <Button
              variant="secondary"
              className={classes.shppingBtn}
              onClick={() => toggleCounterCard(true)}
              icon={<CaretDownIcon />}
            />
          </Container>
          {counterCard && (
            <Container className={classes.offerCardGrid}>
              <CounterOfferCard
                quoteReqId={quoteReqId}
                setCurrectPrice={setCurrectPrice}
                obj={counterData}
                setCounterToggle={setCounterToggle}
                setApproveDialog={setApproveDialog}
                setOrderId={setOrderId}
                orderId={orderId}
                setOfferPrice={setOfferPrice}
              />
            </Container>
          )}
        </Container>
      )}
      <CounterDialog
        open={counterToggle}
        onclose={setCounterToggle}
        quoteReqId={quoteReqId}
        amount={currectPrice}
        obj={counterData}
        mutate={mutate}
        returnAble={!supplierReqDetails?.quote_request_can_return}
      />
      <PreviewDialog open={previewToggle} onclose={setPreviewToggle} quoteReqId={quoteReqId} logo={logo} name={name} />
      <ConfirmationDialog
        open={approveDialog}
        onclose={setApproveDialog}
        shippingOptions={supplierReqDetails?.quote_request_delivery_options}
        brandName={brandName}
        quoteReqId={quoteReqId}
        partNumber={partNumber}
        partName={partName}
        amount={amount}
        companyname={companyname}
        listMutate={listMutate}
        mutate={mutate}
        supplierdetailsMutate={supplierdetailsMutate}
        zipCode={supplierReqDetails?.cmp_delivery_addr_zipcode}
        returnAble={!supplierReqDetails?.quote_request_can_return}
        offerPrice={offerPrice}
        orderDetailsId={orderDetailsData?.order_id}
        orderId={orderId}
      />
    </>
  )
}

BuyerQuoteCard.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  amount: PropTypes.string,
  partNumber: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  instock: PropTypes.string,
  eqpName: PropTypes.string,
  brandName: PropTypes.string,
  partName: PropTypes.string,
  shipping: PropTypes.string,
  condition: PropTypes.string,
  premium: PropTypes.bool,
  quoteReqId: PropTypes.string,
  companyname: PropTypes.string,
  listMutate: PropTypes.func,
  country: PropTypes.string,
}
