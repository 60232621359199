import { makeStyles } from '@mui/styles'
import { CaretLeftIcon, PencilIcon, ShipingFromIcon, ShipingToIcon } from 'assets/icons'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getColor } from 'theme/colors'
import IconButton from 'components/common/IconButton'
import { DATE_FORMAT_COMMA, DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import LoadingOverlay from 'components/common/LoadingOverlay'
import useToggle from 'common-hooks/useToggle'
import useOrderDetails from 'common-hooks/orders/useOrderDetails'
import ShipOrderDialog from './ShipOrderDialog'
import SalesOrderNumberDialog from './SalesOrderNumberDialog'
import HelpDialog from './HelpDialog'
import OrderCancelDialog from './OrderCancelDialog'
import LocalDeliveryDialog from './LocalDeliveryDialog'
import ConfirmationDialog from './ConfirmationDialog'
import { formatPrice, getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  orderDetailBody: {
    backgroundColor: '#fff',
    // padding: '30px',
  },
  orderInfoCardGrid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ordeInfoCard: {
    border: '1px solid #F0F0F4',
    width: '800px',
    padding: '20px',
    margin: '0 auto',
  },
  blueLink: {
    color: '#2680D9',
    '& a': {
      color: '#2680D9',
    },
  },
  orderInfoCardRight: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px 0',
    width: '330px',
  },
  btnBottm: {
    marginTop: '5px',
  },
  orderInfoSmallGrid: {
    gap: '0 10px',
    '& p:first-child': {
      width: '173px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& p': {
      width: '150px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  orderLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px 0',
    padding: '30px',
    width: '73%',
    // maxWidth: '910px',
  },
  shpingadress: {
    paddingLeft: '33px',
    paddingTop: '5px',
  },
  shpingadress1: {
    paddingLeft: '21px',
    marginLeft: '11px',
    borderLeft: ' 1px dashed #C3C5D5',
    paddingTop: '5px',
    paddingBottom: '28px',
  },
  tableHeader: {
    display: 'flex',
    paddingBottom: '13px',
    borderBottom: '1px solid #F0F0F4',
    '& .col1': {
      width: '624px',
    },
    '& .col2': {
      width: '30px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .col3': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .col4': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  rowRoot: {
    display: 'flex',
    paddingTop: '10px',
    '& .row1': {
      width: '624px',
    },
    '& .row2': {
      width: '30px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .row3': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .row4': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  productTable: {
    paddingTop: '13px',
    paddingBottom: '13px',
    borderBottom: '1px solid #F0F0F4',
    width: '800px',
  },

  totolRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '800px',
    paddingBottom: '30px',
    paddingTop: '10px',
    '& p:first-child': {
      width: '109px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    '& p': {
      width: '98px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  orderGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '96vh',
  },
  soldProfileRoot: {
    display: 'flex',
    gap: '0 10px',
    paddingTop: '8px',
    alignItems: 'flex-start',
    '& img': {
      width: '60px',
      height: '60px',
      borderRadius: '4px',
      objectFit: 'unset',
    },
  },
  orderRight: {
    padding: '30px',
    background: '#F8F9F9',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px 0',
    width: '27%',
    borderLeft: '1px solid #f0f0f4',
  },
  p8: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  cancleOrderBtn: {
    border: '1px solid #9799B5',
    color: '#4A4D68',
  },
  contanctUS: {
    paddingTop: '8px',
  },
  help: {
    paddingTop: '18px',
    color: '#36384C',
  },
  shippingAddress: {
    width: '800px',
    margin: '0 auto',
  },
  productDetail: {
    width: '800px',
    margin: '0 auto',
  },
  separatorWidth: {
    width: '800px',
    margin: '0 auto',
  },
  helpRoot: {
    height: '72vh',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  IconButton: {
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  pencilIcon: {
    display: 'flex',
    alignItems: 'center',
    color: '#626583',
  },
  blueLinkBtn: {
    width: 'unset',
    height: '15px',
    minHeight: 'unset',
    background: 'unset',
    color: '#2680D9',
    padding: 'unset',
    borderBottom: '1px solid #2680D9',
    borderRadius: 'unset',
    '&:hover': {
      background: 'unset',
      color: '#2680D9',
    },
  },
  loadingOverlay: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
  },
}))

export default function SupplierOrderShipDetails() {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  const [editTrackingNumber, setEditTrackingNumber] = useState()
  const [editSoNumber, setEditSoNumber] = useState()
  const [editLocalDelivery, setEditLocalDelivery] = useState()

  const [shipOrderDialog, setShipOrderDialog] = useToggle(false)
  const [localDeliveryDialog, setLocalDeliveryDialog] = useToggle(false)
  const [confirmationDialog, setConfirmationDialog] = useToggle(false)
  const [salseOrderDialog, setSalseOrderDialog] = useToggle(false)
  const [helpDialog, setHelpDialog] = useToggle(false)
  const [orderCancelDialog, setOrderCancelDialog] = useToggle(false)

  const param = useParams()

  const { orderDetailsData, isLoading, mutate } = useOrderDetails(param?.id)
  useEffect(() => {
    if (orderDetailsData) {
      if (orderDetailsData?.courier_name && orderDetailsData?.tracking_no && orderDetailsData?.courier_name) {
        setEditTrackingNumber({
          Courier: orderDetailsData?.courier_name,
          trackingnumber: orderDetailsData?.tracking_no,
          couriername: orderDetailsData?.courier_name,
        })
      }
      if (orderDetailsData?.sales_order_number) {
        setEditSoNumber(orderDetailsData?.sales_order_number)
      }
      if (
        orderDetailsData?.deliver_on &&
        orderDetailsData?.delivered_time?.start &&
        orderDetailsData?.delivered_time?.end
      ) {
        setEditLocalDelivery({
          diliver_on: orderDetailsData?.deliver_on,
          start: orderDetailsData?.delivered_time?.start,
          end: orderDetailsData?.delivered_time?.end,
        })
      }
    }
  }, [orderDetailsData])

  useEffect(() => {
    if (
      location?.state?.upatetraking &&
      orderDetailsData?.order_status === 'awaiting_shipment' &&
      !orderDetailsData?.delivery_options?.mdo_local_delivery &&
      !orderDetailsData?.delivery_options?.mdo_pick_up
    ) {
      setShipOrderDialog(true)
    }
    if (
      location?.state?.upatetraking &&
      orderDetailsData?.delivery_options?.mdo_local_delivery &&
      orderDetailsData?.order_status === 'awaiting_shipment'
    ) {
      setLocalDeliveryDialog(true)
    }
    if (
      location?.state?.upatetraking &&
      orderDetailsData?.delivery_options?.mdo_pick_up &&
      orderDetailsData?.order_status === 'awaiting_shipment'
    ) {
      setConfirmationDialog(true)
    }
  }, [location?.state])

  return (
    <Container className={classes.orderDetailBody}>
      {isLoading && (
        <Container className={classes.loadingOverlay}>
          <LoadingOverlay />
        </Container>
      )}
      {!isLoading && (
        <Container className={classes.orderDetailBody}>
          <Container className={classes.orderDetailInner}>
            <Container className={classes.orderGrid}>
              <Container className={classes.orderLeft}>
                <Container className={classes.orderTitle} flex justify="flex-start" gap={'0 20px'}>
                  <Button
                    variant={'secondary'}
                    border
                    icon={<CaretLeftIcon />}
                    onClick={() => navigate('/orders', { state: { status: orderDetailsData?.order_status } })}
                  />
                  <Typography variant="h1" color={getColor('grey-g25')}>
                    Order ID: {orderDetailsData?.order_id}
                  </Typography>
                </Container>
                <Container className={classes.ordeInfoCard}>
                  <Container className={classes.orderInfoCardGrid}>
                    <Container className={classes.orderInfoCardLeft}>
                      {/* <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {orderDetailsData?.order_status === 'awaiting_shipment' ||
                        orderDetailsData?.order_status === 'shipped'
                          ? 'Order is'
                          : ''}
                      </Typography> */}
                      <Typography variant="h2" color={getColor('grey-g25')} component={'p'}>
                        {orderDetailsData?.order_status === 'shipped' &&
                          orderDetailsData?.order_pickup === false &&
                          'Shipped'}
                        {orderDetailsData?.order_status === 'shipped' && orderDetailsData?.order_pickup === true
                          ? 'Ready For Pickup'
                          : ''}
                        {orderDetailsData?.order_status === 'awaiting_shipment' && 'Awaiting Shipment'}
                        {orderDetailsData?.order_status === 'cancelled' && 'Cancelled'}
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {orderDetailsData?.order_status === 'shipped' && orderDetailsData?.ordered_shipped_date
                          ? `on ${formatDate(orderDetailsData?.ordered_shipped_date, DATE_FORMAT_COMMA)}`
                          : ''}
                        {orderDetailsData?.order_status === 'cancelled' && orderDetailsData?.ordered_cancelled_date
                          ? `on ${formatDate(orderDetailsData?.ordered_cancelled_date, DATE_FORMAT_COMMA)}`
                          : ''}
                      </Typography>
                      <Container className={classes.btnBottm}>
                        {Object.keys(orderDetailsData?.delivery_options).length > 1 &&
                        orderDetailsData?.order_status === 'awaiting_shipment' ? (
                          <>
                            <Button variant="positive" width={'105px'} onClick={setShipOrderDialog}>
                              Ship Order
                            </Button>
                          </>
                        ) : (
                          <>
                            {orderDetailsData?.order_status === 'awaiting_shipment' &&
                              !orderDetailsData?.delivery_options?.mdo_local_delivery &&
                              !orderDetailsData?.delivery_options?.mdo_pick_up && (
                                <Button variant="positive" width={'105px'} onClick={setShipOrderDialog}>
                                  Ship Order
                                </Button>
                              )}
                            {orderDetailsData?.delivery_options?.mdo_local_delivery &&
                              orderDetailsData?.order_status === 'awaiting_shipment' && (
                                <Button variant="positive" width={'105px'} onClick={setLocalDeliveryDialog}>
                                  Ship Order
                                </Button>
                              )}

                            {orderDetailsData?.delivery_options?.mdo_pick_up &&
                              orderDetailsData?.order_status === 'awaiting_shipment' && (
                                <Button variant="positive" width={'152px'} onClick={setConfirmationDialog}>
                                  Ready For Pickup
                                </Button>
                              )}
                          </>
                        )}

                        {Object.keys(orderDetailsData?.delivery_options).length > 1 ? (
                          <>
                            {orderDetailsData?.delivery_options?.freight_next_day &&
                              orderDetailsData?.order_status === 'shipped' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Tracking Number: {orderDetailsData?.tracking_no}{' '}
                                  <Button className={classes.blueLinkBtn} onClick={setShipOrderDialog}>
                                    Change
                                  </Button>
                                </Typography>
                              )}
                            {orderDetailsData?.delivery_options?.freight_next_day &&
                              orderDetailsData?.order_status === 'cancelled' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Tracking Number: {orderDetailsData?.tracking_no}{' '}
                                </Typography>
                              )}
                          </>
                        ) : (
                          <>
                            {!orderDetailsData?.delivery_options?.mdo_local_delivery &&
                              !orderDetailsData?.delivery_options?.mdo_pick_up &&
                              orderDetailsData?.order_status === 'shipped' && (
                                <>
                                  {/* <Typography variant="body1" color={getColor('grey-g35')}>
                                    Tracking Number: {orderDetailsData?.tracking_no}{' '}
                                    <Button className={classes.blueLinkBtn} onClick={setShipOrderDialog}>
                                      Change
                                    </Button>
                                  </Typography> */}
                                  {Object.keys(orderDetailsData?.delivery_options).length > 1 ? (
                                    <>
                                      {orderDetailsData?.order_status === 'awaiting_shipment' ? (
                                        ''
                                      ) : (
                                        <Container
                                          flex
                                          justify="flex-start"
                                          // height="33px"
                                          // className={classes.orderInfoSmallGrid}
                                        >
                                          <Typography variant="body1" color={getColor('grey-g45')}>
                                            Shipped via:
                                          </Typography>
                                          <Typography variant="body1" color={getColor('grey-g45')}>
                                            {/* {orderDetailsData?.courier_name
                                              ? orderDetailsData?.courier_name
                                              : 'Not shipped yet'} */}{' '}
                                            {orderDetailsData?.courier_name ? (
                                              <>
                                                {orderDetailsData?.courier_name} • {orderDetailsData?.tracking_no}
                                              </>
                                            ) : (
                                              'Not shipped yet'
                                            )}
                                            <Button className={classes.blueLinkBtn} onClick={setShipOrderDialog}>
                                              Change
                                            </Button>
                                          </Typography>
                                        </Container>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {orderDetailsData?.order_status === 'awaiting_shipment' ? (
                                        ''
                                      ) : (
                                        <>
                                          {!orderDetailsData?.delivery_options?.mdo_pick_up && (
                                            <Container
                                              flex
                                              justify="flex-start"
                                              // height="33px"
                                              // className={classes.orderInfoSmallGrid}
                                            >
                                              <Typography variant="body1" color={getColor('grey-g45')}>
                                                Shipped via:
                                              </Typography>
                                              <Typography variant="body1" color={getColor('grey-g45')}>
                                                {'  '}
                                                {orderDetailsData?.courier_name ? (
                                                  <>
                                                    {orderDetailsData?.courier_name} • {orderDetailsData?.tracking_no}
                                                  </>
                                                ) : (
                                                  'Not shipped yet'
                                                )}
                                                {'  '}
                                                <Button className={classes.blueLinkBtn} onClick={setShipOrderDialog}>
                                                  Change
                                                </Button>
                                              </Typography>
                                            </Container>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                            {!orderDetailsData?.delivery_options?.mdo_local_delivery &&
                              !orderDetailsData?.delivery_options?.mdo_pick_up &&
                              orderDetailsData?.order_status === 'cancelled' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  {/* Tracking Number: {orderDetailsData?.tracking_no}{' '} */}
                                </Typography>
                              )}

                            {orderDetailsData?.delivery_options?.mdo_local_delivery &&
                              orderDetailsData?.order_status === 'cancelled' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  {/* Order Deliver on */}
                                  {''}
                                  {/* {formatDate(orderDetailsData?.deliver_on, DATE_FORMAT_COMMA)} between{' '}
                                  {orderDetailsData?.delivered_time?.start} to {orderDetailsData?.delivered_time?.end} */}
                                </Typography>
                              )}
                            {orderDetailsData?.delivery_options?.mdo_pick_up &&
                              orderDetailsData?.order_status === 'cancelled' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  {''}
                                </Typography>
                              )}

                            {orderDetailsData?.delivery_options?.mdo_local_delivery &&
                              orderDetailsData?.order_status === 'shipped' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Order Deliver on {formatDate(orderDetailsData?.deliver_on, DATE_FORMAT_COMMA)} between{' '}
                                  {orderDetailsData?.delivered_time?.start} to {orderDetailsData?.delivered_time?.end}{' '}
                                  <Button className={classes.blueLinkBtn} onClick={setLocalDeliveryDialog}>
                                    Change
                                  </Button>
                                </Typography>
                              )}
                            {/* {orderDetailsData?.delivery_options?.mdo_pick_up &&
                              orderDetailsData?.order_status === 'shipped' && (
                                <Typography variant="body1" color={getColor('grey-g35')}>
                                  Order ready for pickup
                                </Typography>
                              )} */}
                          </>
                        )}
                      </Container>
                    </Container>
                    <Container className={classes.orderInfoCardRight}>
                      <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                        <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                          Ordered On:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                          {formatDate(orderDetailsData?.ordered_date, DATE_FORMAT_PRIMARY)}
                        </Typography>
                      </Container>
                      <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                        <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                          PO Number:
                        </Typography>
                        <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                          {orderDetailsData?.po_number ? orderDetailsData?.po_number : '-'}
                        </Typography>
                      </Container>

                      {/* {orderDetailsData?.order_status === 'shipped' ||
                      (orderDetailsData?.order_status === 'awaiting_shipment' &&
                        orderDetailsData?.sales_order_number) ? (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography
                            variant="body1"
                            color={getColor('grey-g65')}
                            component={'p'}
                            className={classes.pencilIcon}
                          >
                            <IconButton className={classes.IconButton} onClick={setSalseOrderDialog}>
                              <PencilIcon />
                            </IconButton>
                            Sales Order Number:
                          </Typography>
                          <Typography variant="body1" color={getColor('grey-g65')} component={'p'}>
                            {orderDetailsData?.sales_order_number ? orderDetailsData?.sales_order_number : '-'}
                          </Typography>
                        </Container>
                      ) : (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography variant="body1" color={getColor('grey-g65')} component={'p'}>
                            Sales Order Number:
                          </Typography>
                          {orderDetailsData?.sales_order_number ? (
                            <Typography variant="body1" color={getColor('grey-g65')} component={'p'}>
                              {orderDetailsData?.sales_order_number}
                            </Typography>
                          ) : (
                            !orderDetailsData?.order_status === 'cancelled' && (
                              <Button className={classes.blueLinkBtn} onClick={setSalseOrderDialog}>
                                Add
                              </Button>
                            )
                          )}
                        </Container>
                      )} */}

                      {orderDetailsData?.order_status === 'shipped' ||
                      orderDetailsData?.order_status === 'awaiting_shipment' ? (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography component={'p'}>
                            {orderDetailsData?.sales_order_number && (
                              <IconButton className={classes.IconButton} onClick={setSalseOrderDialog}>
                                <PencilIcon />
                              </IconButton>
                            )}
                            <Typography variant="body1" color={getColor('grey-g45')} className={classes.pencilIcon}>
                              Sales Order Number:
                            </Typography>
                          </Typography>
                          {orderDetailsData?.sales_order_number ? (
                            <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                              {orderDetailsData?.sales_order_number}
                            </Typography>
                          ) : (
                            <Button className={classes.blueLinkBtn} onClick={() => setSalseOrderDialog()}>
                              Add
                            </Button>
                          )}
                        </Container>
                      ) : (
                        <Container flex justify="space-between" className={classes.orderInfoSmallGrid}>
                          <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                            Sales Order Number:
                          </Typography>
                          <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                            {orderDetailsData?.sales_order_number ? orderDetailsData?.sales_order_number : '-'}
                          </Typography>
                        </Container>
                      )}

                      {/* {Object.keys(orderDetailsData?.delivery_options).length > 1 ? (
                        <>
                          {orderDetailsData?.order_status === 'awaiting_shipment' ? (
                            ''
                          ) : (
                            <Container
                              flex
                              justify="space-between"
                              height="33px"
                              className={classes.orderInfoSmallGrid}
                            >
                              <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                                Shipped via:
                              </Typography>
                              <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                                {orderDetailsData?.courier_name ? orderDetailsData?.courier_name : 'Not shipped yet'}
                              </Typography>
                            </Container>
                          )}
                        </>
                      ) : (
                        <>
                          {orderDetailsData?.order_status === 'awaiting_shipment' ? (
                            ''
                          ) : (
                            <>
                              {!orderDetailsData?.delivery_options?.mdo_pick_up && (
                                <Container
                                  flex
                                  justify="space-between"
                                  height="33px"
                                  className={classes.orderInfoSmallGrid}
                                >
                                  <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                                    Shipped via:
                                  </Typography>
                                  <Typography variant="body1" color={getColor('grey-g45')} component={'p'}>
                                    {orderDetailsData?.courier_name
                                      ? orderDetailsData?.courier_name
                                      : 'Not shipped yet'}
                                  </Typography>
                                </Container>
                              )}
                            </>
                          )}
                        </>
                      )} */}
                    </Container>
                  </Container>
                </Container>
                <Container className={classes.shippingAddress}>
                  <Container>
                    <Container flex justify="flex-start" gap={'0 9px'}>
                      <ShipingFromIcon />
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {orderDetailsData?.delivery_options?.mdo_pick_up ? 'Pickup Address' : 'Shipping From'}
                      </Typography>
                    </Container>
                    <Container className={classes.shpingadress1}>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {orderDetailsData?.shipping_from_addr_line_1
                          ? orderDetailsData?.shipping_from_addr_line_1
                          : 'AGA Parts '}
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {orderDetailsData?.shipping_from_addr_line_2 ? (
                          <>
                            {getWellKnownAddress(
                              orderDetailsData?.shipping_from_addr_line_2,
                              orderDetailsData?.shipping_from_city,
                              orderDetailsData?.shipping_from_state,
                              orderDetailsData?.shipping_from_country,
                              orderDetailsData?.shipping_from_zipcode
                            )}
                          </>
                        ) : (
                          '2972 Westheimer Rd. Santa Ana, Illinoise - 85486'
                        )}
                      </Typography>
                    </Container>
                  </Container>
                  <Container>
                    <Container flex justify="flex-start" gap={'0 9px'}>
                      <ShipingToIcon />
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {orderDetailsData?.delivery_options?.mdo_pick_up ? 'Buyer Address' : '  Shipping To'}
                      </Typography>
                    </Container>
                    <Container className={classes.shpingadress}>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {orderDetailsData?.shipping_to_addr_line_1
                          ? orderDetailsData?.shipping_to_addr_line_1
                          : ' Brightspot Equipment Rental '}
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        {orderDetailsData?.shipping_to_addr_line_2 ? (
                          <>
                            {getWellKnownAddress(
                              orderDetailsData?.shipping_to_addr_line_2,
                              orderDetailsData?.shipping_to_city,
                              orderDetailsData?.shipping_to_state,
                              orderDetailsData?.shipping_to_country,
                              orderDetailsData?.shipping_to_zipcode
                            )}
                          </>
                        ) : (
                          ' S 5th Ave, Merrll Street, Castlewood, SD - 57078, US'
                        )}
                      </Typography>
                    </Container>
                  </Container>
                </Container>
                <Container className={classes.separatorWidth}>
                  <Seperator />
                </Container>
                <Container className={classes.productDetail}>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Product Details
                  </Typography>
                  <Container className={classes.productTable}>
                    <Container className={classes.tableHeader}>
                      <Container className="col1">
                        <Typography variant="body1" color={getColor('grey-g65')}>
                          Items
                        </Typography>
                      </Container>
                      <Container className="col2">
                        <Typography variant="body1" color={getColor('grey-g65')}>
                          Qty
                        </Typography>
                      </Container>
                      <Container className="col3">
                        <Typography variant="body1" color={getColor('grey-g65')}>
                          Unit Price
                        </Typography>
                      </Container>
                      <Container className="col4">
                        <Typography variant="body1" color={getColor('grey-g65')}>
                          Amount
                        </Typography>
                      </Container>
                    </Container>
                    <Container className={classes.tableBody}>
                      <Container className={classes.rowRoot}>
                        <Container className="row1">
                          <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                            {orderDetailsData?.brand_name} - {orderDetailsData?.part_name} -{' '}
                            {orderDetailsData?.part_number}
                          </Typography>
                          <Typography variant="paragraph1" color={getColor('grey-g65')}>
                            {orderDetailsData?.condition === 'new_aftermarket' && 'New Aftermarket'}
                            {orderDetailsData?.condition === 'new_oem' && 'New OEM'}
                            {orderDetailsData?.condition === 'used' && 'Used'}

                            {orderDetailsData?.weight && ` •  ${orderDetailsData?.weight} lbs`}
                          </Typography>
                        </Container>
                        <Container className="row2">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {orderDetailsData?.qty ? orderDetailsData?.qty : '-'}
                          </Typography>
                        </Container>
                        <Container className="row3">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {formatPrice(orderDetailsData?.unit_price)}
                          </Typography>
                        </Container>
                        <Container className="row4">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {formatPrice(orderDetailsData?.amount)}
                          </Typography>
                        </Container>
                      </Container>
                      <Container className={classes.rowRoot}>
                        <Container className="row1">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {Object.keys(orderDetailsData?.delivery_options).length > 1 ? (
                              `Shipping: ${orderDetailsData?.delivery_options?.freight_next_day && 'Next Day Delivery'}`
                            ) : (
                              <>
                                Shipping: {orderDetailsData?.delivery_options?.freight_next_day && 'Next Day Delivery'}
                                {orderDetailsData?.delivery_options?.mdo_local_delivery && 'Local Delivery'}
                                {orderDetailsData?.delivery_options?.freight_ground && 'Ground'}
                                {orderDetailsData?.delivery_options?.mdo_pick_up && 'Pickup'}
                                {orderDetailsData?.delivery_options?.mdo_ltl && 'LTL'}
                                {orderDetailsData?.delivery_options?.freight_second_day && 'Second Day'}
                              </>
                            )}
                          </Typography>
                        </Container>
                        <Container className="row2">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {}
                          </Typography>
                        </Container>
                        <Container className="row3">
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {}
                          </Typography>
                        </Container>
                        <Container className="row4">
                          {/* <Typography variant="body1" color={getColor('grey-g25')}>
                            {Object.keys(orderDetailsData?.delivery_options).length > 1 ? (
                              `$ ${
                                orderDetailsData?.delivery_options?.freight_next_day &&
                                orderDetailsData?.delivery_options?.freight_next_day
                              }`
                            ) : (
                              <>
                                ${' '}
                                {orderDetailsData?.delivery_options?.freight_next_day &&
                                  orderDetailsData?.delivery_options?.freight_next_day}
                                {orderDetailsData?.delivery_options?.mdo_local_delivery &&
                                  orderDetailsData?.delivery_options?.mdo_local_delivery}
                                {orderDetailsData?.delivery_options?.freight_ground &&
                                  orderDetailsData?.delivery_options?.freight_ground}
                                {orderDetailsData?.delivery_options?.mdo_pick_up &&
                                  orderDetailsData?.delivery_options?.mdo_pick_up}
                                {orderDetailsData?.delivery_options?.mdo_ltl &&
                                  orderDetailsData?.delivery_options?.mdo_ltl}
                                {orderDetailsData?.delivery_options?.freight_second_day &&
                                  orderDetailsData?.delivery_options?.freight_second_day}
                              </>
                            )}
                          </Typography> */}

                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {Object.keys(orderDetailsData?.delivery_options).length > 1 ? (
                              formatPrice(orderDetailsData?.delivery_options?.freight_next_day)
                            ) : (
                              <>
                                {orderDetailsData?.delivery_options?.freight_next_day && (
                                  <>
                                    {formatPrice(orderDetailsData?.delivery_options?.freight_next_day) === '$0.00'
                                      ? 'free'
                                      : formatPrice(orderDetailsData?.delivery_options?.freight_next_day)}
                                  </>
                                )}
                                {orderDetailsData?.delivery_options?.mdo_local_delivery && (
                                  <>
                                    {formatPrice(orderDetailsData?.delivery_options?.mdo_local_delivery) === '$0.00'
                                      ? 'free'
                                      : formatPrice(orderDetailsData?.delivery_options?.mdo_local_delivery)}
                                  </>
                                )}
                                {orderDetailsData?.delivery_options?.freight_ground && (
                                  <>
                                    {formatPrice(orderDetailsData?.delivery_options?.freight_ground) === '$0.00'
                                      ? 'free'
                                      : formatPrice(orderDetailsData?.delivery_options?.freight_ground)}
                                  </>
                                )}
                                {orderDetailsData?.delivery_options?.mdo_pick_up && (
                                  <>
                                    {formatPrice(orderDetailsData?.delivery_options?.mdo_pick_up) === '$0.00'
                                      ? 'free'
                                      : formatPrice(orderDetailsData?.delivery_options?.mdo_pick_up)}
                                  </>
                                )}
                                {orderDetailsData?.delivery_options?.mdo_ltl && (
                                  <>
                                    {formatPrice(orderDetailsData?.delivery_options?.mdo_ltl) === '$0.00'
                                      ? 'free'
                                      : formatPrice(orderDetailsData?.delivery_options?.mdo_ltl)}
                                  </>
                                )}
                                {orderDetailsData?.delivery_options?.freight_second_day && (
                                  <>
                                    {formatPrice(orderDetailsData?.delivery_options?.freight_second_day) === '$0.00'
                                      ? 'free'
                                      : formatPrice(orderDetailsData?.delivery_options?.freight_second_day)}
                                  </>
                                )}
                              </>
                            )}
                          </Typography>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                  <Container className={classes.totolRoot}>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Total
                    </Typography>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      {orderDetailsData?.order_status === 'cancelled'
                        ? 'Cancelled'
                        : formatPrice(orderDetailsData?.final_amount)}
                    </Typography>
                  </Container>
                </Container>
              </Container>
              <Container className={classes.orderRight}>
                <Container>
                  <Typography variant="title" color={getColor('grey-g25')}>
                    Sold To
                  </Typography>
                  <Container className={classes.soldProfileRoot}>
                    <Image src={orderDetailsData?.buyer_logo} />
                    <Container>
                      <Typography variant="paragraph1" color={getColor('grey-g25')} component={'p'}>
                        {orderDetailsData?.buyer_full_name}
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        Brightspot Equipment Rental
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        E: {orderDetailsData?.buyer_email}
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        P: {orderDetailsData?.buyer_phone_numbe}
                      </Typography>
                    </Container>
                  </Container>
                </Container>
                <Seperator />

                {orderDetailsData?.order_status === 'cancelled' && (
                  <>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Cancel Reason
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                      {orderDetailsData?.order_cancle_reason}
                    </Typography>
                  </>
                )}
                {orderDetailsData?.order_status === 'cancelled' ? (
                  ''
                ) : (
                  <>
                    {orderDetailsData?.shipping_to_delivery_instructions && (
                      <>
                        <Container className={classes.p8}>
                          <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                            Delivery Instructions
                          </Typography>
                          <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                            {orderDetailsData?.shipping_to_delivery_instructions
                              ? orderDetailsData?.shipping_to_delivery_instructions
                              : ' Make sure you deliver to Door 122 and ask for Sam Johnson in the Receiving Dept.'}
                          </Typography>
                        </Container>
                      </>
                    )}
                  </>
                  // <Container className={classes.p8}>
                  //   <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                  //     Delivery Instructions
                  //   </Typography>
                  //   <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                  //     {orderDetailsData?.shipping_to_delivery_instructions
                  //       ? orderDetailsData?.shipping_to_delivery_instructions
                  //       : ' Make sure you deliver to Door 122 and ask for Sam Johnson in the Receiving Dept.'}
                  //   </Typography>
                  // </Container>
                )}

                {orderDetailsData?.order_status === 'awaiting_shipment' && (
                  <>
                    {orderDetailsData?.shipping_to_delivery_instructions && <Seperator />}
                    <Container className={classes.p8}>
                      <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                        Cancel Order
                      </Typography>
                      <Typography variant="body1" color={getColor('grey-g35')} component={'p'}>
                        You can cancel this order until it has been shipped
                      </Typography>
                      <Button
                        variant="transparent"
                        border
                        width={'121px'}
                        className={classes.cancleOrderBtn}
                        onClick={setOrderCancelDialog}
                      >
                        Cancel Order
                      </Button>
                    </Container>
                  </>
                )}

                <Container className={classes.helpRoot}>
                  <Seperator />
                  <Typography variant="title" color={getColor('grey-g25')} component={'p'} className={classes.help}>
                    Help
                  </Typography>
                  <Container className={classes.contanctUS}>
                    <Typography component={'p'}>Need additional help with an order?</Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Contact us at{' '}
                      <Button className={classes.blueLinkBtn} onClick={() => setHelpDialog()}>
                        support@partsclub.us
                      </Button>
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      )}

      <ShipOrderDialog
        open={shipOrderDialog}
        onClose={setShipOrderDialog}
        mutate={mutate}
        editTrackingNumber={editTrackingNumber}
      />
      <SalesOrderNumberDialog
        open={salseOrderDialog}
        onClose={setSalseOrderDialog}
        editSoNumber={editSoNumber}
        mutate={mutate}
      />
      <HelpDialog open={helpDialog} onClose={setHelpDialog} section={'Order'} />
      <OrderCancelDialog open={orderCancelDialog} onClose={setOrderCancelDialog} />
      <LocalDeliveryDialog
        open={localDeliveryDialog}
        onClose={setLocalDeliveryDialog}
        editLocalDelivery={editLocalDelivery}
        mutate={mutate}
      />
      <ConfirmationDialog open={confirmationDialog} onClose={setConfirmationDialog} mutate={mutate} />
    </Container>
  )
}
