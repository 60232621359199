export function getHeight(size) {
  switch (size) {
    case 'sm':
      return 36
    case 'md':
      return 40
    default:
      return 36
  }
}
