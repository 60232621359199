// import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import PropTypes from 'prop-types'
import SucessIcon from 'assets/icons/RightIcon.svg'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import useActiveQuotes from 'common-hooks/buyer/quotes/useActiveQuotes'
import Link from 'components/common/Link'
import DialogHead from 'components/custom/dialog/DialogHead'

const useStyle = makeStyles(() => ({
  root: {
    paddingTop: '40px',
    paddingBottom: '40px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  headerRoot: {
    border: 'unset',
  },
  link: {
    color: getColor('func-info'),
  },
  linkColor: {
    color: getColor('func-info'),

    '& a': {
      color: getColor('func-info'),
      textDecoration: 'underline',
    },
  },
  head: {
    border: 'none',
  },
}))
export default function SuccessDialog({ open, onClose, succesLink }) {
  const classes = useStyle()
  const { mutate } = useActiveQuotes()
  if (open === false) {
    mutate()
  }
  return (
    <Dialog open={open} size={'sm'}>
      <DialogHead onClose={onClose} className={classes?.head} />
      <Container className={classes.root} flex direction="column" gap="20px">
        <Container flex justify="center">
          <img src={SucessIcon} alt={'RightIcon'} />
        </Container>

        <Container flex direction="column" gap="20px 0">
          <Container flex direction="column">
            <Typography variant="title">Quote Request Created!</Typography>
            <Typography variant="paragraph1" color={getColor('grey-g25')}>
              You have successfully created requests for parts.
            </Typography>
            <Typography variant="paragraph1" color={getColor('grey-g25')}>
              Click the request below to see invited suppliers.
            </Typography>
          </Container>
          <Container flex direction="column" gap="15px 0">
            {succesLink &&
              succesLink?.map((data, index) => (
                <Container key={index} flex gap={'0 5px'}>
                  <Typography variant="paragraph1">{index + 1}. </Typography>
                  <Typography color={getColor('func-info')} className={classes.linkColor}>
                    <Link to={data.link}>
                      {data.brandName} - {data.partName} {data.partNumber ? `- ${data.partNumber}` : ''}
                    </Link>
                  </Typography>
                </Container>
              ))}
          </Container>
        </Container>
        {/* <Container flex>
          <Button type="submit" id={`hook-form-succesh`} onClick={handleClick}>
            Go To Active Quotes
          </Button>
        </Container> */}
      </Container>
    </Dialog>
  )
}

SuccessDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  succesLink: PropTypes.array,
}
