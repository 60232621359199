import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import Placeholder from 'assets/images/Placeholder.png'
import lockmode from 'assets/icons/lockmode.svg'
import { getColor } from 'theme/colors'
import { Divider } from '@mui/material'
import Button from 'components/common/Button'
import { useParams } from 'react-router-dom'
import Image from 'components/common/Image'
import useUserProfile from 'common-hooks/user-profile/useUserProfile'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    justifyContent: 'center',
    backgroundColor: '#f8f9f9',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'unset',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  previewHeader: {
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
    border: 'unset',
    '& div:first-child': {
      display: ' flex',
      justifyContent: ' end',
      marginRight: '20px',
    },

    '& button': {
      backgroundColor: '#fff',
      '& div': {
        marginRight: 'unset!important',
      },
    },
  },
  downloadButton: {
    backgroundColor: 'transparent!important',
    border: '0.5px solid #fff',
    color: getColor('light-main'),
    fontSize: '0.875rem',
  },
  mainContainer: {
    height: '792px',
    width: '612px',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    background: getColor('light-main'),
  },
  subContainer: {
    padding: '40px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: '500',
    color: '#13141B',
    fontSize: '10px',
    lineHeight: '14px',
  },
  otherText: {
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '14px',
    color: getColor('dark-main'),
    width: '140px',
  },
  header: {
    fontWeight: '700',
  },
  partsDetail: {
    padding: '30px 0px 7px 0px',
  },
  afterDivider: {
    padding: '10px 0px 30px 0px',
    alignItems: 'normal',
  },
  note: {
    margin: '56px 0px 40px 0px',
  },
  buyNowBtn: {
    width: '142px',
    height: '32px',
    background: getColor('func-positive'),
    borderRadius: '4px',
    '&:hover': {
      background: getColor('func-positive'),
      color: getColor('light-main'),
    },
  },

  counterBtn: {
    width: '100px',
    height: '36px',
    border: `1px solid ${getColor('grey-g65')}`,
    borderRadius: '4px',
    color: getColor('grey-g35'),
  },
  counterBtnText: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    color: getColor('grey-g35'),
  },
  chckoutText: {
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '14px',
  },
  footerbtns: {
    alignItems: 'flex-Start',
    paddingTop: '28px ',
  },
  addresDetails: {
    lineHeight: '14px',
    fontSize: '10px',
    fontWeight: '300',
  },
  stockDetails: {
    lineHeight: '14px',
    fontSize: '10px',
    fontWeight: '400',
    color: getColor('grey-g65'),
  },

  quotesDayDetail: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'end',
  },
  partsDetailBox: {
    alignItems: 'flex-Start',
    justifyContent: 'normal',
  },
  list: {
    margin: '0px 5px',
    paddingLeft: '17px',
    fontSize: '10px',
    fontWeight: '300',
    lineHeight: '14px',
  },
  BbillText: {
    fontSize: '10px',
    fontWeight: '300',
  },
  crossPrize: {
    color: getColor('grey-g65'),
    fontWeight: 500,
    fontSize: '10px',
  },
  radioBtn: {
    fontSize: '10px!important',
    '& span': {
      fontSize: '10px!important',
    },
  },
  title: {
    padding: '20px 0',
    textAlign: 'left',
    width: '612px',
  },
  imgPlaceHolder: {
    border: '1px solid #C3C5D5',
  },
  qntyTypography: {
    fontWeight: '500',
    fontSize: ' 10px',
    lineHeight: '14px',
    color: ' #13141B',
  },
  bandImg: {
    border: '1px solid',
    borderColor: getColor('grey-g65'),
  },
  qntyWidh: {
    fontWeight: '500',
    fontSize: ' 10px',
    lineHeight: '14px',
    color: ' #13141B',
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  partWidth: {
    fontWeight: '500',
    fontSize: ' 10px',
    lineHeight: '14px',
    color: ' #13141B',
    width: '316px',
  },
}))
export default function RequestPreview() {
  const classes = useStyles()
  const params = useParams()
  const { supplierReqDetails, isLoading } = useReqSupplierDetails(params?.id)
  const createDate = supplierReqDetails?.createdAt
  const validDate = supplierReqDetails?.valid_until
  const { profileData } = useUserProfile()
  // const cmpAddress = profileData?.user_address

  return (
    <Container className={classes.root}>
      <Container className={classes.title}>
        <Typography variant="body1">Quote Preview</Typography>
      </Container>
      <Container className={classes.mainContainer} id={'divToPrint'}>
        {isLoading && <LoadingOverlay />}
        <Container className={classes.subContainer}>
          <Container>
            <Container flex justify={'space-between'}>
              <Image
                src={profileData?.company_profile || Placeholder}
                alt="Quotes Preview"
                width={'43.88px'}
                height={'43.88px'}
                className={classes.bandImg}
              />

              <Typography variant="body1" className={classes.header}>
                Quote
              </Typography>
            </Container>
            <Container>
              <Container>
                <Typography className={classes.text}>{profileData?.company_name || ''}</Typography>
              </Container>
              <Container flex justify={'space - between'}>
                <Container className={classes.otherText}>
                  <Typography className={classes.addresDetails}>
                    {getWellKnownAddress(profileData?.company_addr_line_1, profileData?.company_addr_line_2)}
                  </Typography>
                </Container>
                <Container flex width={'80%'} justify={'flex-end'} gap={'30px'}>
                  <Container className={classes.quotesDayDetail}>
                    <Typography className={classes.addresDetails}>Quote:</Typography>
                    <Typography className={classes.addresDetails}>Date:</Typography>
                    <Typography className={classes.addresDetails}>Valid Until:</Typography>
                  </Container>
                  <Container className={classes.quotesDayDetail}>
                    <Typography className={classes.addresDetails}>{supplierReqDetails?.quote_number}</Typography>
                    <Typography className={classes.addresDetails}>
                      {formatDate(createDate, DATE_FORMAT_PRIMARY)}
                    </Typography>
                    <Typography className={classes.addresDetails}>
                      {formatDate(validDate, DATE_FORMAT_PRIMARY)}
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container>
              <Container>
                <Container>
                  <Typography className={classes.text}>Shipping To :</Typography>
                </Container>
                <Container className={classes.otherText}>
                  <Typography className={classes.addresDetails}>
                    {supplierReqDetails?.cmp_billing_addr_company_name}
                  </Typography>
                  <Typography className={classes.addresDetails}>
                    {getWellKnownAddress(
                      supplierReqDetails?.cmp_delivery_addr_addr_line_1,
                      supplierReqDetails?.cmp_delivery_addr_addr_line_2
                    )}
                  </Typography>
                </Container>
              </Container>
            </Container>
            {/* Image shipping detail starts */}
            <Container flex className={classes.partsDetail} gap={'0 10px'}>
              <Container width={'316px'}>
                <Typography className={classes.partWidth}>Part</Typography>
              </Container>
              <Typography className={classes.qntyWidh}>Quantity</Typography>
              <Typography className={classes.qntyWidh}>Total</Typography>
            </Container>
            <Divider />
            <Container flex justify={'flex-start'} gap={'5px'} className={classes.afterDivider}>
              {/* <Image
                  src={supplierReqDetails?.quote_assets[0]?.part_image || Placeholder}
                  alt="Quotes Preview"
                  width={'62px'}
                  height={'58px'}
                  className={classes.bandImg}
                /> */}

              <Container width={'345px'} flex direction={'column'} className={classes.partsDetailBox}>
                <Typography className={classes.text}>
                  {supplierReqDetails?.brand_name} - {supplierReqDetails?.part_name}{' '}
                  {supplierReqDetails?.part_number !== null ? `- ${supplierReqDetails?.part_number}` : ''}
                </Typography>
              </Container>
              <Container flex gap={'0 10px'} className={classes.partsDetailBox}>
                <Container width={100} justify={'flex-end'}>
                  <Typography className={classes.qntyTypography}>1</Typography>
                </Container>
                <Container width={100} justify={'flex-end'}>
                  <Typography className={classes.crossPrize}>-</Typography>
                </Container>
              </Container>
            </Container>
          </Container>

          <Container>
            <Container>
              <Typography className={classes.text}>Terms & Conditions</Typography>

              <ul className={classes.list}>
                <li>Returnable:Yes </li>
                <li> Warranty: 365 days </li>
                <li>Cancelable: Prior to shipment</li>
              </ul>
            </Container>

            <Container flex width={'100%'} gap={'15px'} justify={'flex-end'} className={classes.footerbtns}>
              <Button className={classes.counterBtn} variant={'transparent'}>
                <Typography classNamw={classes.counterBtnText}>Counter</Typography>
              </Button>
              <Container flex gap={'5px'} direction={'column'}>
                <Container>
                  <Button className={classes.buyNowBtn}>
                    <Typography className={classes.btnText}>Approve</Typography>
                  </Button>
                </Container>
                <Container flex>
                  <img src={lockmode} alt="Secure Checkout" width={'16px'} height={'16px'} />
                  <Typography className={classes.chckoutText}>Secure Checkout </Typography>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
