import { getInvitedDataList } from 'common-api/buyer-supplier/buyerSupplier'
import useSWR from 'swr'
import { getSize } from 'utils/constants'

export default function useOtherSuppliers(id) {
  const PAGE_SIZE = getSize()
  const { data, mutate } = useSWR(() => {
    if (id) {
      return `quote-invite/quote/list?quote_id=${id}&is_other=true&page=1&limit=${PAGE_SIZE}`
    }
    return null
  }, getInvitedDataList)
  return {
    otherSuppliersList: data,
    mutate,
  }
}
