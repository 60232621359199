import { deleteCard, getPaymentMethods, setAsDefalutCard } from 'common-api/settings/settings'
import useToastify from 'common-hooks/useToastify'
import useSWR from 'swr'

export default function usePaymentMethods() {
  const { data, mutate } = useSWR(`/subscription/paymemt-method`, getPaymentMethods)
  const { toastMessage } = useToastify()

  const deletePaymentCard = async (id, mutate) => {
    try {
      const response = await deleteCard(id)
      toastMessage('success', 'Card deleted successfully')
      mutate()
      return response
    } catch {
      return false
    }
  }
  const setDefalutCard = async (id, mutate) => {
    try {
      const response = await setAsDefalutCard(id)
      toastMessage('success', 'Card set as defalut successfully')
      mutate()
      return response
    } catch {
      return false
    }
  }
  return {
    paymentMethods: data || [],
    mutate,
    deletePaymentCard,
    setDefalutCard,
  }
}
