import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { getColor } from 'theme/colors'
import { Verfied } from 'assets/icons'
import Button from 'components/common/Button'
import { useNavigate } from 'react-router-dom'
import RequestQuoteDialog from './RequestQuoteDialog'
import useToggle from 'common-hooks/useToggle'
import { useState } from 'react'
import { formatPrice, getFlag, getShortAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  cardRoot: {
    width: '908px',
    margin: '0 auto',
    border: '1px solid #F0F0F4',
    padding: '20px',
    marginBottom: '20px',
    display: 'flex',
    background: '#fff',
    gap: '0 10px',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },
  img: {
    width: '96px',
    height: '96px',
    border: '1px solid #F0F0F4',
    objectFit: 'cover',
  },
  cardLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  counterOfferChip: {
    background: '#E0F0FF',
    width: '115px',
    borderRadius: '100px',
    '& span': {
      color: '#19528C',
    },
  },
  orderPlacedChip: {
    background: '#E0FFE0',
    width: '115px',
    borderRadius: '100px',
    '& span': {
      color: '#097309',
    },
  },
  waitingForResponseChip: {
    background: '#F0F0F4',
    width: '141px',
    borderRadius: '100px',
    '& span': {
      color: '#36384C',
    },
  },
  cardRight: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px 0',
  },
  cardRight1: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px 0',
  },
  viewQuote: {
    background: 'unset',
    color: '#2680D9',
    '&:hover': {
      background: 'unset',
    },
  },
}))

export default function SearchCard({
  id,
  brandName,
  partName,
  partNumber,
  condition,
  companyName,
  city,
  state,
  amout,
  qty,
  stock,
  brandImage,
  obj,
  country,
  // weight,
}) {
  const classes = useStyles()
  const navigate = useNavigate()

  const productName = `${brandName} - ${partName} - ${partNumber?.replace('/', '')}`
    .replace('null', '')
    .replace('undefined', '')
  const [quantity, setQuantity] = useState(1)
  const [quoteRequestDialog, setQuoteRequestDialog] = useToggle(false)
  // const [buyNowBtnDisabled, setBuyNowBtnDisabled] = useState(true)

  // useEffect(() => {
  //   if (Number(weight) > 0 && Number(amout) > 0 && Number(qty) > 0) {
  //     setBuyNowBtnDisabled(false)
  //   } else {
  //     setBuyNowBtnDisabled(true)
  //   }
  // }, [weight, amout, qty])

  return (
    <Container>
      <Container className={classes.cardRoot} onClick={() => navigate(`/search/${productName}/${id}`)}>
        <Container className={classes.cardLeft}>
          <img src={brandImage} alt="BRAND LOGO" className={classes.img} />
        </Container>
        <Container className={classes.cardRight}>
          <Container className={classes.cardRight1}>
            <Container flex justify="space-between">
              <Typography component={'p'} variant="title" color={getColor('grey-g25')}>
                {brandName} - {partName} {partNumber ? `- ${partNumber}` : ''}
              </Typography>
              <Typography variant="h2" color={getColor('grey-g25')} component={'p'}>
                {formatPrice(amout)}
              </Typography>
            </Container>
            <Container flex justify="space-between">
              {condition === 'new_oem' && (
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  New OEM
                </Typography>
              )}
              {condition === 'used' && (
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  Used
                </Typography>
              )}
              {condition === 'new_aftermarket' && (
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  New Aftermarket
                </Typography>
              )}
              <Container flex gap={'0 10px'}>
                {!stock ? (
                  <Typography variant="body1" color="#AD1F36">
                    Out of Stock
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body1" color="#0DA50D">
                      In Stock
                    </Typography>
                    <Typography variant="body1" color="#9799B5">
                      {qty && `• ${qty} Qty `}
                    </Typography>
                  </>
                )}
              </Container>
            </Container>
          </Container>
          <Container flex justify="space-between">
            <Container>
              <Container flex justify="flex-start" gap={'0 5px'}>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {companyName}
                </Typography>
                <Verfied />
              </Container>
              <Container flex gap="0 5px" justify="flex-start">
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  {getShortAddress(city, state, country)}
                </Typography>
                {getFlag(country)}
              </Container>
            </Container>
            <Container flex gap={'0 10px'}>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  setQuoteRequestDialog()
                }}
                // disabled={buyNowBtnDisabled}
              >
                Request Quote
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>
      <RequestQuoteDialog
        open={quoteRequestDialog}
        onClose={setQuoteRequestDialog}
        searchDetails={obj}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    </Container>
  )
}
SearchCard.propTypes = {
  partName: PropTypes.string,
  brandName: PropTypes.string,
  partNumber: PropTypes.string,
  condition: PropTypes.string,
  companyName: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  amout: PropTypes.string,
  qty: PropTypes.string,
  stock: PropTypes.string,
  brandImage: PropTypes.string,
  id: PropTypes.string,
  obj: PropTypes.object,
  country: PropTypes.string,
  // weight: PropTypes.string,
}
