import Container from 'components/common/Container'
import './css/landingpage.css'
import './css/responsive.css'
import React, { useState } from 'react'
import listingStar from 'assets/images/listingStar.svg'
import transactionRightImg from 'assets/images/transactionRightImg.png'
// import sellingBannerImage from 'assets/images/sellingBannerImage.png'
import sellingBannerImage from 'assets/images/sellingBannerImage.svg'
import stillQuestion from 'assets/images/stillQuestion.svg'
import Button from 'components/common/Button'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Typography from 'components/common/Typography'
import { CaretDownIcon } from 'assets/icons'
import { getColor } from 'theme/colors'
import LoginDialog from 'containers/authentication/LoginDialog'
import Seperator from 'components/common/Seperator'
import HtmlTitle from 'utils/HtmlTitle'
import LandingPageFooter from './LandingPageFooter'
import LandingPageHeader from './LandingPageHeader'

const SellingPage = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [plan, setPlan] = useState(true)

  const handlePlan = (e) => {
    setPlan(e)
  }
  return (
    <Container className="selling-main">
      <HtmlTitle title={'Selling'} />
      <Container className="container">
        <LandingPageHeader />
      </Container>
      <Container className="selling-page-body">
        {/* <section className="section-about-us">
        <div className="container">
          <div className="about-hero">
            <div className="about-text-box">
              <h1 className="heading-about-us">The #1 marketplace for heavy-duty parts.</h1>
              <p className="hero-heading-description">
                PartsClub makes it easy for buyers and suppliers to connect and work together on quotes and orders.
              </p>
              <p className="hero-description">
                We want to dramatically reduce the number of phone calls and emails it takes to order parts. Our suite
                of digital procurement and sales tools help buyers and sellers communicate effectively and in real-time
                to get more done than ever before.
              </p>
            </div>
            <div className="about-img-box">
              <img src={Snippets} className="about-img" alt="search and save like a pro" />
            </div>
          </div>
        </div>
      </section> */}
        <section className="section-hero p90">
          <div className="container">
            <div className="seling-hero">
              <div className="hero-text-box">
                <h1 className="heading-primary">Start selling in minutes. No parts upload required</h1>
                <p className="hero-heading-description">
                  Sell parts by uploading inventory, quoting buyer requests, or both.
                </p>
                <p className="hero-description">
                  We know that parts inventories are difficult to manage and that gathering weights, photos,
                  descriptions and up-to-date pricing can be a real challenge. You can get started with any inventory
                  just by quoting buyers. Get started today!
                </p>
                {/* <p className="hero-description">
                  So we made it easy. If you have your data ready, you're welcome to upload unlimited parts listings.
                  But if you're still working on your inventory data, you can easily quote buyers' parts requests to win
                  parts sales every day without a single upload.
                </p> */}
                <Button className="become-seller-btn" onClick={setOpenDialog}>
                  Become Seller
                </Button>
              </div>
              <div className="hero-img-box">
                <img src={sellingBannerImage} className="hero-img" alt="search and save like a pro" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-prizing p90 selling-page-pricing">
          <div className="container">
            <h2 className="heading-secondary">Grow your parts sales with the lowest fees online.</h2>
            <div className="heading-text">
              monthly <SwitchToggle defaultChecked={plan} onChange={handlePlan} /> Yearly (25% off)
            </div>
          </div>

          <div className="container">
            <div className="pricing">
              <div className="pricing-plan pricing-plan-free">
                <header className="plan-header">
                  <p className="plan-name">Free</p>
                  <p className="plan-price">
                    {plan ? (
                      <>
                        <span>$0.00</span>/month
                      </>
                    ) : (
                      <>
                        <span>$0.00</span>/month
                      </>
                    )}
                  </p>
                  <p className="list-item">For small parts businesses and brokers</p>
                </header>
                <Seperator />
                <ul className="list">
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Unlimited Parts Listings
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Unlimited Quote Requests
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Unlimited Sales
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Basic Company Profile Page
                  </li>
                </ul>
                <div className="plan-sign-up">
                  <Button className="start-free-btn" onClick={setOpenDialog}>
                    Start For Free
                  </Button>
                </div>
              </div>

              <div className="pricing-plan pricing-plan-super-saver pricing-plan-super-saver-height">
                <header className="plan-header">
                  <p className="plan-name">Pro (Popular)</p>
                  <p className="plan-price">
                    {plan ? (
                      <>
                        <span>$499.99</span>/month
                      </>
                    ) : (
                      <>
                        <span>$624.99</span>/month
                      </>
                    )}
                  </p>
                  <p className="list-item">For parts businesses looking for revenue</p>
                </header>
                <Seperator />
                <ul className="list">
                  <li className="list-item">
                    <span>Everything in the Plus+, plus</span>
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Top Search Result Placement
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Exclusive 'Premium Seller' Badge
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Exclusive Promotional Opportunities
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Exclusive Seller Support Resources
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Sell Products Through Your Distributors
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Advanced Reporting
                  </li>
                </ul>
                <div className="plan-sign-up">
                  <Button className="commingsoon-button">Coming Soon</Button>
                </div>
              </div>

              <div className="pricing-plan pricing-plan-saver pricing-plan-saver-height">
                <header className="plan-header">
                  <p className="plan-name">Plus</p>
                  <p className="plan-price">
                    {plan ? (
                      <>
                        <span>$399.99</span>/month
                      </>
                    ) : (
                      <>
                        <span>$499.99</span>/month
                      </>
                    )}
                  </p>
                  <p className="list-item">For parts businesses looking for exposure</p>
                </header>
                <Seperator />
                <ul className="list">
                  <li className="list-item">Everything in free, plus</li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Automated Inventory Uploads
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Send Preemptive Offers to Buyers
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Upgraded Company Profile Page
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Better Search Result Placement
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Basic Reporting
                  </li>
                </ul>
                <div className="plan-sign-up">
                  <Button className="commingsoon-button">Coming Soon</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-verification p90">
          <div className="container">
            <div className="verification-title">
              <h2 className="heading-secondary">10-Step Supplier Verification</h2>
              <p className="performance-text">
                PartsClub protects buyers from fraudulent and ill-willed suppliers by vetting each supplier application
                manually before allowing them to start working with buyers. All suppliers must be validated and comply
                with Terms & Conditions.
              </p>
            </div>
            <div className="verification">
              <ul className="verification-list">
                <li className="verification-list-item">
                  <span>1</span>
                  Mailing Address
                </li>
                <li className="verification-list-item">
                  <span>2</span> Physical Address
                </li>
                <li className="verification-list-item">
                  <span>3</span> Tax ID Number
                </li>
                <li className="verification-list-item">
                  <span>4</span> Bank Account
                </li>
                <li className="verification-list-item">
                  <span>5</span> Credit Card on File
                </li>
                <li className="verification-list-item">
                  <span>6</span> Team Members
                </li>
                <li className="verification-list-item">
                  <span>7</span> Product/Service Offering
                </li>
                <li className="verification-list-item">
                  <span>8</span> Industry References
                </li>
                <li className="verification-list-item">
                  <span>9</span> Customer Service References
                </li>
                <li className="verification-list-item">
                  <span>10</span> Contact Information
                </li>
              </ul>
            </div>
            <div className="sign-up">
              <Button className="button-sign-up" onClick={setOpenDialog}>
                Sign up free
              </Button>
            </div>
          </div>
        </section>
        <section className="section-cta p90">
          <div className="container">
            <div className="call-to-action">
              <div className="call-to-action-text">
                <h1 className="heading-cta">Transaction fees up to 70% lower than competing eCommerce platforms.</h1>
                <p className="hero-heading-description">Lower transaction fees means more profit margin for you.</p>
                <p className="hero-description">
                  Transaction fees on PartsClub are 3% + applicable credit card fees. This is much lower than eBay or
                  Amazon and allows PartsClub Suppliers to keep vastly more profit than they would on competing
                  eCommerce sites.
                </p>
                <div className="sign-up-free">
                  <Button href="#" className="button-sign-up" onClick={setOpenDialog}>
                    Start For Free
                  </Button>
                </div>
              </div>
              <div className="cta-img-box">
                <img src={transactionRightImg} className="hero-img" alt="search and save like a pro" />
              </div>
            </div>
          </div>
        </section>
        <Container className="frequently-ask-question-section">
          <Container className="container">
            <Container className="frequently-ask-question-inner p90">
              <Typography className="title " component="h1">
                Frequently Asked Questions (FAQ)
              </Typography>
              <Container className="frequently-ask-question-accordian">
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      Is PartsClub like eBay or Amazon
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub competes directly with both eBay and Amazon by providing unique features and
                      functionality to facilitate buyer-seller interactions and transactions specific to the equipment
                      parts industry.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      These features include seller fees that are up to up to 70% less than competitors,
                      account-specific pricing discounts between buyers and sellers, the ability to checkout without a
                      credit card, and more.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      How does PartsClub make money?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub has an advertising-supported free tier for both buyers and suppliers.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Sellers also pay the industry’s lowest transaction fees (3% + applicable credit card fees) and we
                      have upgraded account options for both buyers and sellers looking for greater functionality and
                      performance reporting.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      *PartsClub does not sell or share specific personal or activity data of individuals or businesses
                      using the platform.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      If I buy a part on PartsClub - what happens next?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub has an identical eCommerce process to eBay or Amazon - orders on PartsClub are sent to
                      the supplier for order and shipping confirmations. The supplier will upload a tracking number once
                      the product ships so you can track your order to your door.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      If you require any assistance with an order, PartsClub has dedicated Support Staff to assist you.
                      Our team can be reached during business hours at sellmoreparts@partsclub.us.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      What if there is something wrong with my order?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      We want all buyers and sellers to have a positive experience working together on PartsClub but
                      understand that issues do occur periodically. Let us help and make it right.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      If you require any assistance with an order, PartsClub has dedicated Support Staff to assist you.
                      Our team can be reached during business hours at sellmoreparts@partsclub.us.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      How do I return an order?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Returns can easily be started in PartsClub right from your order screen.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Just upload the tracking number when you send the order back to the supplier and the refund will
                      be sent back when the supplier receives the return.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      Who owns PartsClub?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub, Inc. is an independent corporation based in Austin, Texas and is not financially
                      affiliated with any organizations in the equipment or equipment parts industries.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Neutrality is one of our core values - PartsClub has intentionally avoided accepting funding or
                      financing from any current or future platform participant to maintain impartiality and the trust
                      of PartsClub’s buyers and sellers.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Our founder Zac Jones created PartsClub to help agricultural, construction and industrial
                      equipment businesses grow parts sales by easily listing parts, quoting buyers and selling parts on
                      a safe and neutral platform.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      Is PartsClub only in North America?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub is initially focused on serving North America - exclusively processing transactions
                      between buyers and sellers in the United States, Canada, and Mexico. PartsClub will be launching
                      in Europe in Spring 2024.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub is also projected to launch in the Caribbean, Central America, and South America in Fall
                      2025 with further expansion to follow.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Container>
              <Container className="still-question">
                <Container className="still-question-inner">
                  <img src={stillQuestion} alt="still" />
                  <Container className="still-question-title">
                    <Typography component="h3">Still have questions?</Typography>
                    <Typography component="p">
                      Can’t find answer you’re looking for? Ask us, our team always here to help you. Contact us at{' '}
                      <a href="mailto:sellmoreparts@partsclub.us"> sellmoreparts@partsclub.us</a>
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <LandingPageFooter />
      <LoginDialog open={openDialog} onClose={setOpenDialog} />
    </Container>
  )
}
export default SellingPage
