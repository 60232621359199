/* eslint-disable camelcase */
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import VerificationCode from 'components/custom/VerificationCode'
import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import useToggle from 'common-hooks/useToggle'
import EmailPasswordDialog from './EmailPasswordDialog'

const useStyles = makeStyles({
  dialogbody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px',
  },
  footerDiv: {
    gap: '10px !important',
  },
})

export default function EmailVerificationDialog({ open, onclose, oldEmail, newEmail, updateEmail }) {
  const handeleCloseDilaog = () => {
    onclose()
  }
  const [codeValue, setCodeValue] = useState()
  const [btnDisable, setBtnDisable] = useState(true)
  useEffect(() => {
    if (codeValue?.length === 6) {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }, [codeValue?.length, open])
  const onSubmit = () => {
    toggleDialog(true)
    onclose()
  }
  const classes = useStyles()
  const [openDialog, toggleDialog] = useToggle(false)
  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Email'} onClose={handeleCloseDilaog} />
        <form id={'varificationCode'}>
          <Container className={classes.dialogbody} direction={'column'} gap={'10px 0'}>
            <Typography variant="body1">Enter verification code that we have sent on {newEmail}</Typography>
            <VerificationCode id={'varificationCode'} setCodeValue={setCodeValue} />
          </Container>

          <DialogFooter justify={'flex-end'}>
            <Container flex className={classes.footerDiv}>
              <Button variant="secondary" border width={77} onClick={handeleCloseDilaog}>
                Cancel
              </Button>
              <Button onClick={onSubmit} disabled={btnDisable}>
                Continue
              </Button>
            </Container>
          </DialogFooter>
        </form>
      </Dialog>
      <EmailPasswordDialog
        open={openDialog}
        onclose={toggleDialog}
        oldEmail={oldEmail}
        newEmail={newEmail}
        updateEmail={updateEmail}
        code={codeValue}
      />
    </Container>
  )
}
EmailVerificationDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  oldEmail: PropTypes.string,
  newEmail: PropTypes.string,
  updateEmail: PropTypes.func,
}
