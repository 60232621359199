import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Select from 'components/common/select/Select'
import { Controller, useForm, useWatch } from 'react-hook-form'
import useToastify from 'common-hooks/useToastify'
import { createOptionDialog } from 'components/common/text/DropDownTextField'
import { parsedBrandOptions } from 'containers/super-admin/supplier-mgt/SupplierUtils'
import useOEMbrandList from 'common-hooks/super-admin/useOEMbrandList'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    overflow: 'hidden',
  },
})

const OemDialog = ({ open, onclose, setDealerBrands, dealerBrands }) => {
  const { toastMessage } = useToastify()
  const classes = useStyles()
  // const [brandData, setBrandData] = useState([])
  const { brandList, onSearch } = useOEMbrandList()
  const { control, reset } = useForm()
  const [brand] = useWatch({
    control,
    name: ['brand'],
  })
  const option = brandList?.map((obj) => createOptionDialog(obj.id, obj.name, obj?.cover_image))
  const handleAdd = () => {
    if (!brand) {
      toastMessage('error', 'Please select brand')
    }
    const parsedOptions = brand?.map((obj) => parsedBrandOptions(obj?.value, obj?.label, obj?.image))
    if (!JSON.stringify(dealerBrands)?.includes(brand?.map((item) => item.value))) {
      setDealerBrands([...dealerBrands, ...parsedOptions])
      onclose()
      reset()
    } else {
      toastMessage('error', 'Brand is already added')
    }
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'OEM Dealer'} onClose={onclose} />
          <Container className={classes.selectField}>
            <Controller
              name={'brand'}
              control={control}
              render={({ field: { name, onChange } }) => (
                <Select
                  options={option}
                  id={name}
                  multi
                  name={name}
                  onChange={onChange}
                  onInputChange={(e) => onSearch(e)}
                  label={'Brand*'}
                  menuPosition={'fixed'}
                />
              )}
            />
            {/* <Select options={option} id={'Status'} name={'Status'} label={'Brand'} /> */}
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={onclose}>
                Cancel
              </Button>
              <Button width={80} onClick={() => handleAdd()}>
                Add
              </Button>
            </Container>
          </DialogFooter>
        </Dialog>
      </Container>
    </>
  )
}
OemDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  setDealerBrands: PropTypes.func,
  dealerBrands: PropTypes.array,
}

export default OemDialog
