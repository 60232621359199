import PropTypes from 'prop-types'
import TreeView from '@mui/lab/TreeView'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { AddIcon, RedDeleteIcon, PencilIcon, CaretRightIcon, CaretUpIcon } from 'assets/icons'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'
import typography from 'theme/typography'
import Seperator from 'components/common/Seperator'
import { Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { CreateSupplierContext } from 'containers/super-admin/supplier-mgt/SupplierUtils'
import useToggle from 'common-hooks/useToggle'
import EquipmentConformationDialog from 'containers/super-admin/supplier-mgt/create-supplier/EquipmentConformationDialog'
import PartConformationDialog from 'containers/super-admin/supplier-mgt/create-supplier/PartConformationDialog'

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderTop: 'unset',
    overflowX: 'hidden',
    '& .MuiTreeItem-root ul li div': {
      backgroundColor: `${getColor('light-main')} !important`,
    },
    '& .makeStyles-customSeperator-232': {
      borderBottom: 'none',
    },
    '& .css-9mgopn-MuiDivider-root': {
      marginLeft: 40,
    },
    '& .css-1jsrtl5-MuiTreeItem-content.Mui-selected': {
      backgroundColor: `${getColor('light-main')} !important`,
    },
    paddingBottom: '20px',
  },
  treeViewTextfiled: {
    border: 0,
    margin: '2px 0',
    borderRadius: 0,
    '&:hover': {
      borderColor: getColor('grey-g95'),
    },
    '&:focus': {
      borderColor: getColor('grey-g95'),
    },
    fontWeight: typography.paragraph1.fontWeight,
    fontSize: typography.paragraph1.fontSize,
  },
  actions: {
    zIndex: '999',
  },
}))

export default function SpecializationInTreeView({
  toggleParts,
  setEquipId,
  setPartId,
  setEquips,
  setFilterActiveCard,
}) {
  const classes = useStyle()
  const ids = []
  const [expanded, setExpanded] = useState(ids)
  const [equipDeleteId, setEquipDeleteId] = useState('')
  const [partsDeleteId, setPartsDeleteId] = useState({})
  const [confirmationDialog, setConfirmationDialog] = useToggle(false)
  const [partsConfirmationDialog, setPartsConfirmationDialog] = useToggle(false)
  let isAction = false
  const { specializationTreeViewData, onSelectNode, setPartsData } = useContext(CreateSupplierContext)
  const handleExpand = (id) => {
    setExpanded((pre) => {
      if (pre.includes(id)) {
        pre.splice(pre.indexOf(id), 1)
      } else {
        pre.push(id)
      }
      return pre.filter((val) => val !== undefined)
    })
  }

  const getTreeItemsFromData = (treeItems) =>
    treeItems.map((treeItemData) => {
      let children
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children)
      }
      ids.push(treeItemData.id)

      const handleAction = (type, equipId, partId) => {
        isAction = true
        if (type === 'add') {
          setEquipId(equipId)
          setPartId(null)
          toggleParts()
        } else if (type === 'edit') {
          setEquipId(equipId)
          setPartId(partId)
          toggleParts()
        } else if (type === 'perent-delete') {
          setEquips((eq) => {
            eq.del_equips.push(equipId)
            eq.del_equips = [...new Set(eq.del_equips)]
            eq.equips = eq?.equips?.filter((e) => e !== equipId)
            return eq
          })
          setFilterActiveCard(equipId)
          onSelectNode((pre) => pre?.filter((obj) => obj.id !== equipId))
          setPartsData((pre) => pre?.filter((obj) => obj.equip_id !== equipId))
        } else {
          let pData = []
          setPartsData((pre) => {
            const ids = [partId]
            pre?.map((obj) => {
              pre?.map((obj2) => {
                if ((ids.includes(obj2.id) || ids.includes(obj2.parent_id)) && obj.equip_id === equipId) {
                  ids.push(obj2.id)
                }
                return 0
              })
              return 0
            })
            pData = pre.filter((item) => !ids.includes(item.id))
            return pData
          })
          onSelectNode((pre) => {
            pre?.map((obj) => {
              if (obj.id === equipId) {
                obj.children = [{}]
                pData?.map((el) => {
                  if (!el.parent_id && el.equip_id === equipId) {
                    if (JSON.stringify(obj.children[0]) === '{}') {
                      obj.children.splice(0, 1)
                    }
                    if (el.id) obj.children.push(el)
                  }
                  return 0
                })
              }
              return 0
            })
            return pre
          })
        }
      }

      return (
        <>
          <EquipmentConformationDialog
            open={confirmationDialog}
            onClose={setConfirmationDialog}
            equipDeleteId={equipDeleteId}
            handleAction={handleAction}
          />
          <PartConformationDialog
            open={partsConfirmationDialog}
            onClose={setPartsConfirmationDialog}
            partsDeleteId={partsDeleteId}
            handleAction={handleAction}
          />
          <StyledTreeItem
            key={treeItemData.id}
            nodeId={treeItemData.id}
            labelText={treeItemData.name}
            children={children}
            labelInfo={
              treeItemData?.count !== undefined ? (
                <span className={classes.actions}>
                  <PencilIcon
                    onClick={() =>
                      handleAction('edit', treeItemData.equip_id, {
                        id: treeItemData.id,
                        name: treeItemData.name,
                        parent_id: treeItemData.parent_id,
                        equip_id: treeItemData.equip_id,
                        count: 0,
                      })
                    }
                  />
                  {/* <RedDeleteIcon onClick={() => handleAction('child-delete', treeItemData.equip_id, treeItemData.id)} /> */}
                  <RedDeleteIcon
                    onClick={() => {
                      setPartsDeleteId({ equipId: treeItemData.equip_id, partId: treeItemData.id })
                      setPartsConfirmationDialog(true)
                    }}
                  />
                </span>
              ) : (
                <span className={classes.actions}>
                  <AddIcon onClick={() => handleAction('add', treeItemData.id)} />
                  {/* <RedDeleteIcon onClick={() => handleAction('perent-delete', treeItemData.id)} /> */}
                  <RedDeleteIcon
                    onClick={() => {
                      setEquipDeleteId(treeItemData.id)
                      setConfirmationDialog(true)
                    }}
                  />
                </span>
              )
            }
            count={treeItemData.count}
          />
        </>
      )
    })

  return (
    <TreeView
      className={classes.root}
      onNodeSelect={(e, nodeId) => {
        if (!isAction) handleExpand(nodeId)
        else isAction = false
      }}
      defaultCollapseIcon={
        <span style={{ width: 30, marginTop: 6 }}>
          <CaretUpIcon />
        </span>
      }
      defaultExpandIcon={
        <span style={{ width: 30, marginTop: 6 }}>
          <CaretRightIcon />
        </span>
      }
      sx={{ flexGrow: 1, overflowY: 'auto' }}
      expanded={expanded}
    >
      {getTreeItemsFromData(specializationTreeViewData)}
    </TreeView>
  )
}

SpecializationInTreeView.propTypes = {
  setEquipId: PropTypes.func,
  setPartId: PropTypes.func,
  toggleParts: PropTypes.func,
  setEquips: PropTypes.func,
  setFilterActiveCard: PropTypes.func,
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: getColor('dark-d25'),
    padding: '0 20px',
    fontWeight: theme.typography.fontWeightMedium,
    height: '42px',
    border: `0px`,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  [`& .${treeItemClasses.expanded}`]: {
    fontWeight: typography.body1.fontWeight,
    fontSize: typography.body1.fontSize,
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
  },
}))

function StyledTreeItem(props) {
  const { bgColor, color, labelIcon: LabelIcon, count, labelInfo, labelText, ...other } = props
  return (
    <>
      <Seperator key={1} />
      <StyledTreeItemRoot
        label={
          <Container flex justify="space-between">
            <Container flex gap={'10px'}>
              <Typography variant="body1" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                {labelText}
              </Typography>
              {/* {count && (
                <Typography variant="body1" color={getColor('grey-g65')}>
                  {`(${count !== undefined || count !== null ? count : 0})`}
                </Typography>
              )} */}
            </Container>
            {labelText && (
              <Typography variant="body1" color="inherit">
                {labelInfo}
              </Typography>
            )}
          </Container>
        }
        {...other}
      />
    </>
  )
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.func,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.any,
  labelText: PropTypes.string.isRequired,
  count: PropTypes.string,
}
