import { makeStyles } from '@mui/styles'
import { Tooltip as MuiTooltip } from '@mui/material'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import { PropTypes } from 'prop-types'

const useStyles = makeStyles({
  customTooltip: (props) => ({
    fontFamily: 'Lexend Deca',
    fontStyle: 'normal',
    maxWidth: props.maxWidth || 200,
    width: '100%',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    background: getColor('dark-d25'),
    padding: '5px 10px',
    borderRadius: '2px',
    textAlign: 'left',
    color: getColor('light'),
    margin: '5px',
  }),
})

const Tooltip = ({ children, title, placement = 'bottom', maxWidth }) => {
  const classes = useStyles({ maxWidth })
  return (
    <MuiTooltip
      title={
        <Typography variant="paragraph1" color={getColor('light')}>
          {title}
        </Typography>
      }
      classes={{ tooltip: classes.customTooltip }}
      placement={placement}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.any,
  placement: PropTypes.string,
  maxWidth: PropTypes.string,
}
