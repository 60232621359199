import { getOrderDetails } from 'common-api/orders/orders'
import useSWR from 'swr'

export default function useOrderDetails(id) {
  const { data, mutate } = useSWR(id ? `/order-details/${id}` : null, getOrderDetails)
  return {
    orderDetailsData: data,
    mutate,
    isLoading: !data,
  }
}
