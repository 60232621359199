import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    overflow: 'hidden',
  },
})

export default function DeleteConformation({ open, onclose, handleDelete }) {
  const classes = useStyles()
  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Confirmation'} onClose={onclose} />
          <Container className={classes.selectField}>
            <Typography variant="body1">
              Are you sure you want to delete company? Once you delete it you won’t be able to get it back.
            </Typography>
            {/* <Select options={option} id={'Status'} name={'Status'} label={'Brand'} /> */}
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={onclose}>
                Cancel
              </Button>
              <Button width={80} onClick={handleDelete} variant={'error'}>
                Delete
              </Button>
            </Container>
          </DialogFooter>
        </Dialog>
      </Container>
    </>
  )
}
DeleteConformation.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  handleDelete: PropTypes.func,
}
