import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import TextField from 'components/common/text/TextField'
import Link from 'components/common/Link'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import useAuth from 'common-hooks/useAuth'
import { getColor } from 'theme/colors'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  forgetpassword: {
    margin: '20px 0',
  },
  loginbtn: {
    color: getColor('grey-g35'),
    marginTop: '10px',
  },
}))

export default function SignIn({ userId, id }) {
  const classes = useStyles()
  const navigation = useNavigate()
  const { setLoginData, isLoading } = useAuth()
  const { handleSubmit, control } = useForm()
  const partid = JSON.parse(getFromLocalStorage(constants.UNAUTHORIZEDPARTID))
  const partname = JSON.parse(getFromLocalStorage(constants.UNAUTHORIZEDPARTNAME))
  const onSubmit = async (data) => {
    const res = await setLoginData(userId, data.password)
    if (res !== false && res !== undefined) {
      if (partid && res?.type === 'customer' && !partname) {
        navigation('/search')
        // eslint-disable-next-line no-dupe-else-if
      } else if (partid && res?.type === 'customer' && partname) {
        navigation(`/search/${partname}/${partid}`)
      } else if (res?.type === 'super_admin') {
        navigation('/sa')
      } else if (res?.type === 'supplier') {
        navigation('/requestboard')
      } else {
        navigation('/quotes')
      }
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Container flex alignItems="center" direction={'column'} width={'100%'} gap={'10px'}>
        <Controller
          name={'email'}
          control={control}
          defaultValue={id}
          render={({ field: { name, value } }) => (
            <TextField id="login-email" type={'email'} name={name} label={'Email'} disabled value={value} />
          )}
        />
        <Controller
          name={'password'}
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <TextField
              id="login-password"
              type={'password'}
              name={name}
              label={'Password'}
              value={value}
              onChange={onChange}
              placeholder={'Password'}
            />
          )}
        />

        <Button
          type="submit"
          className={classes.loginbtn}
          loading={isLoading}
          variant={'secondary'}
          border
          width={'100%'}
        >
          Sign In
        </Button>

        <Link variant="body2" underline to={id ? `/forgot-password/${id}` : '/forgot-password'}>
          Forgot Password?
        </Link>
      </Container>
    </form>
  )
}
SignIn.propTypes = {
  userId: PropTypes.string,
  id: PropTypes.string,
}
