import { makeStyles } from '@mui/styles'
import { DeleteIcon } from 'assets/icons'
import useBrandList from 'common-hooks/create-quote/useBrandList'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'
import Seperator from 'components/common/Seperator'
import TextField from 'components/common/text/TextField'
import Typography from 'components/common/Typography'
import PropTypes from 'prop-types'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { useEffect, useState } from 'react'
import { createOption } from 'components/common/text/DropDownTextField'
import useAddProduct from 'common-hooks/inventory/useAddProduct'
import { useParams } from 'react-router-dom'
import SelectCreateable from 'components/common/select/SelectCreateable'

const useStyles = makeStyles(() => ({
  body: {
    padding: '20px',
  },
  brandRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
    marginBottom: '20px',
    position: 'relative',
  },
  IconButton: {
    backgroundColor: 'unset',
    position: 'absolute',
    right: '0',
  },
  toCss: {
    fontFamily: 'Lexend Deca',
    fontStyle: ' normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: ' 18px',
    color: '#36384C',
    paddingTop: '20px',
  },
  modelNumberRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  iconBtn: {
    background: 'unset',
    marginTop: '21px',
    '&:hover': {
      background: 'unset',
    },
  },
  brandBtn: {
    backgroundColor: 'unset',
    color: '#2680D9!important',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& span': {
      color: '#2680D9!important',
    },
  },
}))

export default function FitsWithDialog({
  open,
  onClose,
  setFitsWithData,
  editFitsWithData,
  setEditFitsWithData,
  mutate,
  setflag,
}) {
  const classes = useStyles()
  const param = useParams()
  const { brandList } = useBrandList()
  const brandOption = brandList?.map((obj) => ({ value: obj.id, label: obj.name }))
  const [disableBtn, setDisableBtn] = useState(true)
  const { deleteFitsWithData } = useAddProduct()
  const [sectionFields, setSectionFields] = useState([
    {
      brand_name: '',
      modelNumbers: [
        {
          model_no: '',
          serial_rang_from: '',
          serial_rang_to: '',
        },
      ],
    },
  ])

  const addNewBrand = () => {
    setSectionFields((prevState) => [
      ...prevState,
      {
        brand_name: '',
        modelNumbers: [{ model_no: '', serial_rang_from: '', serial_rang_to: '' }],
      },
    ])
  }

  const removeBrand = (index) => {
    const values = [...sectionFields]
    values.splice(index, 1)

    setSectionFields(values)
    if (index === 0 && !editFitsWithData?.id && sectionFields?.length === 1) {
      handleOnClose()
    }
    if (editFitsWithData?.id) {
      deleteFitsWithData(param.id, editFitsWithData?.brand_id, editFitsWithData?.id, mutate)
      handleOnClose()
    }
  }

  const addNewModelNo = (i) => {
    setSectionFields((prev) => {
      const updatedSection = {
        ...prev[i],
        modelNumbers: [...prev[i].modelNumbers, { model_no: '', serial_rang_from: '', serial_rang_to: '' }],
      }
      // eslint-disable-next-line arrow-body-style
      return prev.map((section, index) => {
        return index === i ? updatedSection : section
      })
    })
  }
  const removeModelNo = (index, i) => {
    const values = [...sectionFields]
    values[index].modelNumbers.splice(i, 1)
    setSectionFields(values)

    if (editFitsWithData?.id) {
      if (i === 0) {
        deleteFitsWithData(param.id, null, editFitsWithData?.id, mutate)
        handleOnClose()
      } else {
        deleteFitsWithData(param.id, null, editFitsWithData?.id, mutate)
      }
    }
  }

  function handleBrandInputChange(index, event, name) {
    const values = [...sectionFields]
    values[index][name] = event
    setSectionFields(values)
  }

  function handleModelNoInputChange(index, i, event) {
    const values = [...sectionFields]
    values[index].modelNumbers[i][event.target.name] = event.target.value
    setSectionFields(values)
  }

  function getValue(type, pi, ci, key) {
    if (type === 'brand_name') {
      return sectionFields[pi]?.brand_name
    }
    return sectionFields[pi]?.modelNumbers[ci]?.[key]
  }

  const handleOnClose = () => {
    setEditFitsWithData(null)
    setSectionFields([
      {
        brand_name: '',
        modelNumbers: [
          {
            model_no: '',
            serial_rang_from: '',
            serial_rang_to: '',
          },
        ],
      },
    ])
    onClose()
  }

  useEffect(() => {
    if (editFitsWithData) {
      Object.keys(editFitsWithData).map((key) => {
        if (['brand_name', 'modelNumbers'].includes(key)) {
          setSectionFields([
            {
              brand_name:
                typeof editFitsWithData?.brand_name === 'object'
                  ? editFitsWithData?.brand_name
                  : createOption(editFitsWithData?.brand_name, editFitsWithData?.brand_name),
              modelNumbers: editFitsWithData?.modelNumbers,
              ...(editFitsWithData?.brand_id && { brand_id: editFitsWithData?.brand_id }),
            },
          ])
        }
        return 0
      })
      // setSectionFields([editFitsWithData])
    }
  }, [editFitsWithData])

  useEffect(() => {
    sectionFields?.forEach((obj) => {
      obj?.modelNumbers?.forEach((ob) => {
        if (ob.model_no && obj.brand_name) {
          setDisableBtn(false)
        } else {
          setDisableBtn(true)
        }
      })
    })
  }, [sectionFields])

  return (
    <Container>
      <Dialog open={open} size={'3xl'}>
        <DialogHead title={'Fits With'} onClose={handleOnClose} />
        <Container className={classes.body}>
          {/* <form className={classes.root} onSubmit={handleSubmit}> */}
          <Container>
            {sectionFields?.map((inputField, index) => (
              <Container className={classes.brandRoot} key={index}>
                <Container flex justify="space-between">
                  <SelectCreateable
                    options={brandOption}
                    id={'brand'}
                    placeholder={'Select'}
                    width={'360px'}
                    defaultValue=""
                    value={getValue('brand_name', index)}
                    name={'brand_name'}
                    disabled={editFitsWithData}
                    onChange={(event) => handleBrandInputChange(index, event, 'brand_name')}
                    label={'Brand*'}
                    menuPosition={'fixed'}
                  />
                  {sectionFields.length >= 2 && (
                    <IconButton onClick={() => removeBrand(index)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {editFitsWithData && (
                    <IconButton onClick={() => removeBrand(index)} className={classes.iconBtn}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Container>
                <Container className={classes.modelNumberRoot}>
                  {inputField?.modelNumbers?.map((v, i) => (
                    <Container flex justify="flex-start" gap={'0 20px'} key={i}>
                      <TextField
                        name="model_no"
                        label="Model Number*"
                        width={'195px'}
                        placeholder={'e.g. 310C'}
                        defaultValue=""
                        value={getValue('model', index, i, 'model_no')}
                        onChange={(event) => handleModelNoInputChange(index, i, event)}
                      />
                      <Container flex justify="flex-start" gap={'0 20px'}>
                        <TextField
                          name="serial_rang_from"
                          label="Serial Range From"
                          width={'195px'}
                          defaultValue=""
                          placeholder={'e.g. 12000992'}
                          value={getValue('model', index, i, 'serial_rang_from')}
                          onChange={(event) => handleModelNoInputChange(index, i, event)}
                        />
                        <Typography className={classes.toCss}> to </Typography>
                        <TextField
                          name="serial_rang_to"
                          label="Serial Range To"
                          width={'195px'}
                          defaultValue=""
                          placeholder={'e.g. 140009098'}
                          value={getValue('model', index, i, 'serial_rang_to')}
                          onChange={(event) => handleModelNoInputChange(index, i, event)}
                        />
                        {!editFitsWithData && inputField?.modelNumbers.length >= 2 && (
                          <IconButton onClick={() => removeModelNo(index, i)} className={classes.iconBtn}>
                            <DeleteIcon />
                          </IconButton>
                        )}

                        {editFitsWithData && inputField?.modelNumbers.length >= 2 && (
                          <IconButton onClick={() => removeModelNo(index, i)} className={classes.iconBtn}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Container>
                    </Container>
                  ))}
                </Container>
                <Button className={classes.brandBtn} width={'145px'} onClick={() => addNewModelNo(index)}>
                  + Model Number
                </Button>
                <Seperator />
              </Container>
            ))}
            {editFitsWithData ? (
              ''
            ) : (
              <Button disabled={disableBtn} className={classes.brandBtn} width={'85px'} onClick={() => addNewBrand()}>
                + Brand
              </Button>
            )}
          </Container>
        </Container>
        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={disableBtn}
              onClick={() => {
                setFitsWithData((pre) => {
                  if (editFitsWithData?.editIndex >= 0 && editFitsWithData) {
                    const newArray = pre.filter((obj, index) => index !== editFitsWithData?.editIndex)
                    return [...newArray, ...sectionFields]
                  }
                  return [...pre, ...sectionFields]
                })
                setSectionFields([
                  {
                    brand_name: '',
                    modelNumbers: [
                      {
                        model_no: '',
                        serial_rang_from: '',
                        serial_rang_to: '',
                      },
                    ],
                  },
                ])
                setflag(false)
                handleOnClose()
              }}
            >
              {editFitsWithData ? 'Update' : 'Add'}
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </Container>
  )
}

FitsWithDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setFitsWithData: PropTypes.func,
  editFitsWithData: PropTypes.object,
  setEditFitsWithData: PropTypes.func,
  mutate: PropTypes.func,
  setflag: PropTypes.func,
}
