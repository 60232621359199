import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px 0',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  bodyInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  subTextLine: {
    color: getColor('grey-g65'),
  },
})

export default function HowOfferWorkBuyerDialog({ open, onClose }) {
  const classes = useStyles()
  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'How do offers work?'} onClose={onClose} />
          <Container className={classes.dialogbody}>
            <Container>
              <Typography variant="body2" color={getColor('grey-g65')} component="p">
                Offers allow buyers and sellers to easily and privately negotiate on PartsClub.
              </Typography>
            </Container>
            <Container className={classes.bodyInner}>
              <Typography variant="body1" color={getColor('grey-g25')}>
                • Buyers can submit up to (3) offers to a supplier
              </Typography>
              <Typography variant="body1" color={getColor('grey-g25')}>
                • If a buyer or supplier accepts an offer -
                <span className={classes.subTextLine}>the buyer has 48 hours to complete an order before the</span>
                offer expires
              </Typography>
              <Typography variant="body1" color={getColor('grey-g25')}>
                • If a buyer fails to respond in 48 hours -
                <span className={classes.subTextLine}>
                  they will lose one of their (3) offers in the negotiation and the price will revert back to the
                  original asking price
                </span>
              </Typography>
              <Typography variant="body1" color={getColor('grey-g25')}>
                • If a seller fails to respond in 48 hours -{' '}
                <span className={classes.subTextLine}>the negotiation will end</span>
              </Typography>
              <Typography variant="body1" color={getColor('grey-g25')}>
                • Both parties will receive notifications
                <span className={classes.subTextLine}>
                  when a counteroffer is submitted and/or when their 48 hour response window is expiring
                </span>
              </Typography>
              <Typography variant="body1" color={getColor('grey-g25')}>
                • A buyer or seller can end the negotiation at
                <span className={classes.subTextLine}>
                  any time by clicking the ‘End Negotiation’ button on the upper right corner
                </span>
              </Typography>
              <Typography variant="body1" color={getColor('grey-g25')}>
                A buyer or seller can report the negotiation
                <span className={classes.subTextLine}>
                  {' '}
                  to PartsClub by clicking ‘Report this negotiation’ on the bottom right corner
                </span>
              </Typography>
            </Container>
          </Container>
        </Dialog>
      </Container>
    </>
  )
}
HowOfferWorkBuyerDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
