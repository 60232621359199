import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'

const useStyles = makeStyles({
  productInto: {
    fontWeight: '600',
    fontSize: ' 14px',
    lineHeight: ' 20px',
    color: '#36384C',
  },
  numberRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
    padding: '20px 0',
  },
  dialogBody: {
    padding: '20px',
  },
  inventTemp: {
    color: '#4A4D68',
    '& a': {
      textDecoration: 'underline',
      color: '#2680D9',
    },
  },
  suppport: {
    color: '#9799B5',
    '& a': {
      textDecoration: 'underline',
      color: '#9799B5',
    },
  },
})

export default function InstructionDialog({ open, onClose }) {
  const classes = useStyles()

  return (
    <>
      <Dialog open={open} width={'450px'}>
        <DialogHead title={'Instructions'} onClose={onClose} />
        <Container className={classes.dialogBody}>
          <Typography className={classes.productInto}>Easily upload your products into PartsClub:</Typography>
          <Container className={classes.numberRoot}>
            <Typography variant="body1" color={getColor('grey-g35')} className={classes.inventTemp}>
              1. <a href="3">Download </a> our inventory template
            </Typography>
            <Typography variant="body1" color={getColor('grey-g35')}>
              2. Insert your inventory data into the template
            </Typography>
            <Typography variant="body1" color={getColor('grey-g35')}>
              3. Upload your edited inventory template into PartsClub
            </Typography>
          </Container>
          <Typography className={classes.suppport} variant="paragraph2" color={getColor('grey-g65')}>
            Please contact <a href="mailto:sellmoreparts@partsclub.us"> sellmoreparts@partsclub.us </a> for help with
            inventory
          </Typography>
        </Container>
      </Dialog>
    </>
  )
}
InstructionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
