import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'
import { forwardRef } from 'react'
import { Grid } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    marginBottom: theme.spacing(props.bottom),
    marginRight: theme.spacing(props.right),
    marginLeft: theme.spacing(props.left),
    paddingTop: theme.spacing(props.innerTop),
    paddingBottom: theme.spacing(props.innerBottom),
    paddingRight: theme.spacing(props.innerRight),
    paddingLeft: theme.spacing(props.innerLeft),
    ...getRootStyle(props, theme),
  }),
}))

const Spacing = forwardRef(
  (
    {
      x,
      y,
      children,
      bottom,
      right,
      left,
      innerTop,
      innerBottom,
      innerRight,
      innerLeft,
      className,
      flex,
      relative,
      containerAuto,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      x,
      y,
      bottom,
      right,
      left,
      innerTop,
      innerBottom,
      innerRight,
      innerLeft,
      flex,
      relative,
      containerAuto,
    })
    return (
      <Grid className={classNames(classes.root, className)} ref={ref} {...props}>
        {children}
      </Grid>
    )
  }
)

Spacing.displayName = 'Spacing'

Spacing.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  left: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  innerTop: PropTypes.number,
  innerBottom: PropTypes.number,
  innerRight: PropTypes.number,
  innerLeft: PropTypes.number,
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  relative: PropTypes.bool,
  containerAuto: PropTypes.bool,
}

export default Spacing

function getRootStyle(props, theme) {
  return {
    ...(props.flex && {
      flex: props.flex,
    }),
    ...(props.relative && {
      position: 'relative',
    }),
    ...(props.x && {
      marginLeft: theme.spacing(props.x),
      marginRight: theme.spacing(props.x),
    }),
    ...(props.y && {
      marginTop: theme.spacing(props.y),
      marginBottom: theme.spacing(props.y),
    }),
    ...(props.containerAuto && {
      width: 'auto',
      minWidth: '100%',
    }),
  }
}
