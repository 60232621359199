/* eslint-disable camelcase */
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import TextField from 'components/common/text/TextField'
import { useEffect, useState } from 'react'

const useStyles = makeStyles({
  dialogbody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px',
  },
  footerDiv: {
    gap: '10px !important',
  },
})

export default function EmailPasswordDialog({ open, onclose, oldEmail, newEmail, updateEmail, code }) {
  const handeleCloseDilaog = () => {
    onclose()
  }
  const [password, setPassword] = useState()
  const [btnDisable, setBtnDisable] = useState(true)
  useEffect(() => {
    if (password) {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }, [password])
  const onSubmit = () => {
    updateEmail(newEmail, code, password)
    onclose()
  }
  const classes = useStyles()
  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Email'} onClose={handeleCloseDilaog} />
        <Container className={classes.dialogbody} direction={'column'} gap={'10px 0'}>
          <Container display="flex" direction={'column'} gap={'20px 0'}>
            <Container>
              <Typography variant="body1">
                Enter the password to update your email for your PartsClub account.
              </Typography>
            </Container>
            <Container>
              <Typography variant="body1">Previous Email: {oldEmail}</Typography>
              <Typography variant="body1">New Email:: {newEmail}</Typography>
            </Container>
          </Container>

          {/* <VerificationCode id={'varificationCode'} setCodeValue={setCodeValue} /> */}
          <TextField
            id="login-email"
            type={'password'}
            name={'email'}
            placeholder="Type"
            label={'Password'}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Container>

        <DialogFooter justify={'flex-end'}>
          <Container flex className={classes.footerDiv}>
            <Button variant="secondary" border width={77} onClick={handeleCloseDilaog}>
              Cancel
            </Button>
            <Button onClick={onSubmit} disabled={btnDisable}>
              Continue
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </Container>
  )
}
EmailPasswordDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  oldEmail: PropTypes.string,
  newEmail: PropTypes.string,
  code: PropTypes.string,
  updateEmail: PropTypes.func,
}
