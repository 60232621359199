/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import Container from 'components/common/Container'
import SearchField from 'components/common/search/SearchField'
import inventoryEmptyState from 'assets/images/inventoryEmptyState.svg'
import Button from 'components/common/Button'
import { makeStyles } from '@mui/styles'
import {
  AddIcon,
  CaretLeftIcon,
  CaretRightIcon,
  DropdownBigIcon,
  EditIcon,
  InventoryFilterIcon,
  RedDeleteIcon,
  SortArrowIcon,
} from 'assets/icons'
import { Table } from 'components/common/Table'
import { getColor } from 'theme/colors'
import IconButton from 'components/common/IconButton'
import correctionIcon from 'assets/images/correctionIcon.svg'
import Chip from 'components/common/Chip'
import ContextMenu from 'components/custom/drop-downs/ContextMenu'
import ContextMenuItem from 'components/custom/drop-downs/ContextMenuItem'
import Image from 'components/common/Image'
import NotesDialog from '../NotesDialog'
import useToggle from 'common-hooks/useToggle'
import FilterDialog from './FilterDialog'
import Typography from 'components/common/Typography'
import useSuperAdminUserList from 'common-hooks/super-admin/useSuperAdminUserList'
import { DATE_FORMAT_COMMA_SUPER, formatDate } from 'utils/dateUtils'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { userTabs } from '../superAdminUtils'
import { Tabs } from 'components/common/Tabs'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    // padding: '30px',
  },
  marginHead: {
    position: 'sticky',
    top: '60px',
    // background: getColor('system-bg'),
    zIndex: '1',
  },
  mainContainer: {
    background: '#fff',
    padding: '30px',
    // '& svg': {
    //   '& path': {
    //     fill: '#4A4D68',
    //   },
    // },
  },
  SearchField: {
    '& input': {
      background: '#F8F9F9',
    },
  },
  list: {
    marginTop: '20px',
    maxHeight: '75vh',
    overflowY: 'scroll',

    whiteSpace: ' nowrap',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      paddingtop: '50px',
      backgroundColor: '#F0F0F4',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
    '& table': {
      overflowX: 'hidden',
      width: '1920px',
      // overflowX: 'scroll',
      '& tr': {
        cursor: 'pointer',
      },
    },
  },
  pagination: {
    borderTop: '1px solid #F0F0F4 ',
    padding: '30px',
    display: 'flex',
    background: '#fff',
    justifyContent: 'space-between',
  },
  paginationFooter: {
    position: 'sticky',
    bottom: '0px',
    height: 'unset',
    paddingTop: '30px',
    // marginLeft: '-20px',
    // marginRight: '-20px',
    padding: '0 0',
    zIndex: '1',
    borderTop: `1px solid ${getColor('grey-g95')}`,
    background: getColor('light-main'),
    '& svg path': {
      fill: 'unset',
    },
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  customTableContainer: {
    overflowX: 'initial',
  },
  placeholder: {
    // position: 'absolute',
    height: '72vh',
    display: 'flex',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px 0',
  },
  iconBtn: {
    background: 'unset',
    border: ' 1px solid #9799B5',
    borderRadius: ' 4px',
    width: ' 36px',
    height: '36px',
    '&:hover': {
      background: 'unset',
    },
  },
  btn: {
    width: '100%',

    justifyContent: ' space-between',
    padding: 'unset',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  addBtn: {
    background: 'unset',
    color: '#2680D9',
    padding: 'unset',
    height: 'unset',
    minHeight: 'unset',
    '& svg path': {
      fill: '#2680D9',
      stroke: '#2680D9',
    },
    '&:hover': {
      background: 'unset',
    },
  },
  noteRoot: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    '& svg path': {
      fill: 'unset',
    },
    '& .hoverIcon': {
      display: 'none',
      gap: '0 10px',
      position: 'absolute',
      background: '#F8F9F9',
      right: '5px',
      '& .btn': {
        background: 'unset',
        padding: 'unset',
        '&:hover': {
          background: 'unset',
        },
      },
    },
    '&:hover .hoverIcon': {
      display: 'flex',
    },
  },
  sortBtn: {
    padding: '0',
    width: 'unset',
    height: 'unset',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  rootBg: {
    background: '#fff',
  },
  resonRoot: {
    '& div': {
      display: 'flex',
    },
    '& p': {
      margin: '0',
      display: 'inline-block',
    },
  },
}))

export default function Users() {
  const classes = useStyles()

  const [tabValue, setTabValue] = useState(0)
  const [rows, setRows] = useState([])

  const [notesDialog, setNotesDialog] = useToggle(false)
  const [filterDialog, setfilterDialog] = useToggle(false)

  const [editnoteData, setEditNoteData] = useState()
  const [noteId, setNoteId] = useState()
  const [orderBy, setOrderBy] = useState()
  const [filterData, setFilterData] = useState()

  const { userList, search, onSearch, isLoading, page, setPage, userUpdate } = useSuperAdminUserList(
    tabValue ? 'supplier' : 'customer',
    orderBy,
    filterData
  )

  const handleChange = (event, newValue) => {
    setFilterData()
    setTabValue(newValue)
  }

  function createData(status, joined, country, name, phone, email, company, jobTitle, subscription, Notes) {
    return { status, joined, country, name, phone, email, company, jobTitle, subscription, Notes }
  }
  function statusProduct(status) {
    if (status === 'not set') return 'Not Set'
    if (status === 'In Progress' || status === 'Inprogress') return 'In Progress'
    if (status === 'Completed') return 'Completed'
    return 'Not Set'
  }

  useEffect(() => {
    if (userList?.length > 0) {
      const data = userList?.map((obj) =>
        createData(
          <ContextMenu
            width={'205px'}
            closeOnClick
            trigger={
              <IconButton className={classes.btn}>
                <Chip
                  label={statusProduct(obj?.user_preferences?.[0]?.status)}
                  // eslint-disable-next-line no-nested-ternary
                  variant={
                    // eslint-disable-next-line no-nested-ternary
                    statusProduct(obj?.user_preferences?.[0]?.status) === 'In Progress'
                      ? 'secondary'
                      : statusProduct(obj?.user_preferences?.[0]?.status) === 'Completed'
                      ? 'success'
                      : ''
                  }
                />
                <DropdownBigIcon />
              </IconButton>
            }
          >
            <ContextMenuItem onClick={() => userUpdate({ id: obj?.user_preferences?.[0]?.id, status: 'Not Set' })}>
              <Container flex justify="space-between" width={'100%'}>
                <Chip label={'Not Set'} />
                {statusProduct(obj?.user_preferences?.[0]?.status) === 'Not Set' && <Image src={correctionIcon} />}
              </Container>
            </ContextMenuItem>
            <ContextMenuItem onClick={() => userUpdate({ id: obj?.user_preferences?.[0]?.id, status: 'In Progress' })}>
              <Container flex justify="space-between" width={'100%'}>
                <Chip label={'In Progress'} variant={'secondary'} />
                {statusProduct(obj?.user_preferences?.[0]?.status) === 'In Progress' && <Image src={correctionIcon} />}
              </Container>
            </ContextMenuItem>
            <ContextMenuItem onClick={() => userUpdate({ id: obj?.user_preferences?.[0]?.id, status: 'Completed' })}>
              <Container flex justify="space-between" width={'100%'}>
                <Chip label={'Completed'} variant={'success'} />
                {statusProduct(obj?.user_preferences?.[0]?.status) === 'Completed' && <Image src={correctionIcon} />}
              </Container>
            </ContextMenuItem>
          </ContextMenu>,

          formatDate(obj?.created_at, DATE_FORMAT_COMMA_SUPER),
          obj?.country ? obj?.country : '-',
          // obj?.industry ? obj?.industry : '-',
          obj?.full_name ? obj?.full_name : '-',
          obj?.phone_number ? obj?.phone_number : '-',
          obj?.email ? obj?.email : '-',
          obj?.name ? obj?.name : '-',
          obj?.job_title ? obj?.job_title : '-',
          obj?.plan_name ? obj?.plan_name : '-',
          obj?.user_preferences?.[0]?.notes ? (
            <Container className={classes.noteRoot}>
              {/* {obj?.user_preferences?.[0]?.notes} */}

              <div className={classes.resonRoot}>
                {obj?.user_preferences?.[0]?.notes?.length > 40 ? (
                  <div>{obj?.user_preferences?.[0]?.notes.slice(0, 40)}...</div>
                ) : (
                  obj?.user_preferences?.[0]?.notes
                )}
              </div>

              <Container className={'hoverIcon'}>
                <IconButton
                  className={'btn'}
                  onClick={() => {
                    setNotesDialog()
                    setEditNoteData(obj?.user_preferences?.[0]?.notes)
                    setNoteId(obj?.user_preferences?.[0]?.id)
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  className={'btn'}
                  onClick={() => userUpdate({ id: obj?.user_preferences?.[0]?.id, notes: '' })}
                >
                  <RedDeleteIcon />
                </IconButton>
              </Container>
            </Container>
          ) : (
            <Button
              onClick={() => {
                setNotesDialog()
                setEditNoteData('')
                setNoteId(obj?.user_preferences?.[0]?.id)
              }}
              className={classes.addBtn}
              icon={<AddIcon />}
            >
              Add
            </Button>
          )
        )
      )
      setRows(data)
    }
  }, [userList, filterData])

  const allProductcols = [
    <Container width={'100%'} flex justify="space-between">
      Status
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Joined
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'created_at', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'created_at', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Country
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'country', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'country', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    // <Container width={'100%'} flex justify="space-between">
    //   Industry
    //   <ContextMenu
    //     width={'205px'}
    //     closeOnClick
    //     trigger={
    //       <IconButton className={classes.sortBtn}>
    //         <SortArrowIcon />
    //       </IconButton>
    //     }
    //   >
    //     <ContextMenuItem>
    //       <Typography>Relevance</Typography>
    //     </ContextMenuItem>
    //     <ContextMenuItem>
    //       <Typography>A - Z</Typography>
    //     </ContextMenuItem>
    //     <ContextMenuItem>
    //       <Typography>Z - A</Typography>
    //     </ContextMenuItem>
    //   </ContextMenu>
    // </Container>,
    <Container width={'100%'} flex justify="space-between">
      Name
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'full_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'full_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Phone
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'phone_number', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'phone_number', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Email
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'email', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'email', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Company
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Job Title
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'job_title', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'job_title', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Subscription
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'plan_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'plan_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Notes{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
  ]
  console.log(filterData, 'filterData')
  return (
    <Container height={'93vh'} className={classes.rootBg}>
      <Container className={classes.mainContainer} width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            <Tabs value={tabValue} onchange={handleChange} array={userTabs} />
            <Container flex gap={'20px'}>
              <Container>
                <SearchField
                  id={'searchField'}
                  width={'315px'}
                  className={classes.SearchField}
                  onSearch={(e) => {
                    onSearch(e)
                  }}
                  value={search}
                />
              </Container>
              <Container flex gap={'10px'}>
                <IconButton className={classes.iconBtn} onClick={setfilterDialog}>
                  <InventoryFilterIcon />
                </IconButton>
              </Container>
            </Container>
          </Container>
        </Container>

        <Container className={classes.list}>
          {/* <TabPanel value={tabValue} index={0}> */}
          <Container>
            {isLoading && <LoadingOverlay />}
            {userList?.length > 0 ? (
              <Table stickyHeader cols={allProductcols} rows={rows} />
            ) : (
              <>
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={inventoryEmptyState} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    {search === '' && filterData === undefined ? (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          {tabValue === 0 ? 'No buyers :(' : 'No sellers :('}
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          {tabValue === 0
                            ? 'Looks like no buyers has been registered yet'
                            : 'Looks like no sellers has been registered yet'}
                        </Typography>
                      </Container>
                    ) : (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No result found
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          {tabValue === 0
                            ? 'Looks like there are no buyer with this value'
                            : 'Looks like there are no seller with this value'}
                        </Typography>
                      </Container>
                    )}
                  </Container>
                </Container>
              </>
            )}
          </Container>
          {/* </TabPanel> */}
          {/* <TabPanel value={tabValue} index={1}>
            <Container>
              {isLoading && <LoadingOverlay />}
              {!isLoading && activeRows?.length > 0 && <Table stickyHeader cols={allProductcols} rows={activeRows} />}

              {activeRows?.length === 0 && (
                <>
                  {' '}
                  <Container className={classes.placeholder}>
                    <Container>
                      <Image src={inventoryEmptyState} />
                    </Container>
                    <Container flex direction="column" gap="20px 0">
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No active product
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          You don’t have any active product yet.
                        </Typography>
                      </Container>
                    </Container>
                  </Container>
                </>
              )}
            </Container>
          </TabPanel> */}
        </Container>
        <Container flex justify={'space-between'} className={classes.paginationFooter}>
          <Button
            icon={<CaretLeftIcon />}
            variant={'transparent'}
            className={classes.prevBtn}
            onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
            disabled={page === 1}
          >
            Prev.
          </Button>
          <Button
            icon={<CaretRightIcon />}
            variant={'transparent'}
            className={classes.nextBtn}
            onClick={() => setPage((pre) => pre + 1)}
            disabled={userList?.length < 35}
          >
            Next
          </Button>
        </Container>
      </Container>
      <NotesDialog
        onClose={setNotesDialog}
        open={notesDialog}
        editnoteData={editnoteData}
        noteId={noteId}
        productUpdate={userUpdate}
        setEditNoteData={setEditNoteData}
      />
      <FilterDialog open={filterDialog} onClose={setfilterDialog} setFilterData={setFilterData} />
    </Container>
  )
}
