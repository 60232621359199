import { makeStyles } from '@mui/styles'
import { CaretLeftIcon, CaretRightIcon } from 'assets/icons'
import useSupplierList from 'common-hooks/super-admin/useSupplierList'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import LoadingOverlay from 'components/common/LoadingOverlay'
import SearchField from 'components/common/search/SearchField'
import React from 'react'
import { getColor } from 'theme/colors'

// import { supplierListLimit } from 'utils/constants'
import HtmlTitle from 'utils/HtmlTitle'
import BuyerSupplierCard from './BuyerSupplierCard'

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'space-between',
    padding: '30px',
    paddingBottom: '0',
    background: getColor('system-bg'),
  },
  search: {
    background: '#fff',
  },
  grid: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '20px',
    height: '82%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingBottom: '10px',
  },
  header: {
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '30px',
    position: 'sticky',
    top: '60px',
    zIndex: '1',
    background: getColor('system-bg'),
  },
  marginHead: {
    background: getColor('system-bg'),
  },
  paginationFooter: {
    position: 'sticky',
    bottom: '0px',
    height: '76px',
    zIndex: '1',
    borderTop: `1px solid ${getColor('grey-g95')}`,
    background: getColor('light-main'),
    marginLeft: '-31px',
    marginRight: '-27px',
    padding: '0 30px',
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
}))

export default function BuyerSupplierList() {
  const classes = useStyles()
  const { supplierList, search, onSearch, page, setPage, isLoading } = useSupplierList()

  return (
    <Container className={classes.root} height={'92vh'}>
      <HtmlTitle title={'Suppliers'} />
      {isLoading && <LoadingOverlay />}
      <Container className={classes.header}>
        <Container flex justify={'flex-end'} className={classes.marginHead}>
          <Container flex gap={'20px'}>
            <SearchField
              id="search-field"
              placeholder={'Search...'}
              width={250}
              onSearch={(e) => {
                onSearch(e)
              }}
              value={search}
              className={classes.search}
            />
          </Container>
        </Container>
      </Container>
      <Container className={classes.grid}>
        <BuyerSupplierCard supplierList={supplierList} />
      </Container>
      <Container flex justify={'space-between'} className={classes.paginationFooter}>
        {/* <Container flex width="40%" justify="flex-start" gap="0 10px">
          <Select
            id={'product-page'}
            width={65}
            height={36}
            options={number}
            menuPlacement={'top'}
            placeholder={'100'}
          />
          <Typography variant="paragraph1" color={getColor('grey-g65')}>
            {' '}
            Product per page
          </Typography>
        </Container> */}
        {/* <Pagination count={20} variant="outlined" shape="rounded" /> */}
        <Button
          icon={<CaretLeftIcon />}
          variant={'transparent'}
          className={classes.prevBtn}
          onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
          disabled={page === 1}
        >
          Prev.
        </Button>
        <Button
          icon={<CaretRightIcon />}
          variant={'transparent'}
          className={classes.nextBtn}
          onClick={() => setPage((pre) => pre + 1)}
          disabled={supplierList?.length !== 42}
        >
          Next
        </Button>
      </Container>
    </Container>
  )
}
