import React from 'react'
import {
  ChatIcon,
  CaretLeftIcon,
  CloseIcon,
  EmailIcon,
  InfoIcon,
  SearchIcon,
  AddIcon,
  AlertInfoIcon,
  AlertErrorIcon,
  AlertSuccessIcon,
  AlertWarningIcon,
  DeleteIcon,
  EyeIcon,
  FacebookColorIcon,
  LinkedInColorIcon,
  CheckedIcon,
  ClearIcon,
  NoNotificationsIcon,
  HelpIcon,
  CloseEyeIcon,
  ProductEmptyState,
  GoogleIcon,
  ErrorRedIcon,
  QuoteIcon,
  SettingsIcon,
  OrdersIcon,
  ConnectionsIcon,
  CompanyIcon,
  ChangeAccountIcon,
  NotificationIcon,
  EditIcon,
  EllipsisVerticleIcon,
  ActionDeleteIcon,
  UserProfileFillIcon,
  UsFlag,
  BlackDot,
  InventoryIcon,
  ReportsIcon,
  AdvertisingIcon,
  ShippingTruck,
  PhoneIcon,
  UserIcon,
  WebsiteIcon,
  EllipsisHorizontalIcon,
  SucessIcon,
  CaretRightIcon,
  ErrorBlackIcon,
  CaretDownBlueIcon,
  CaretDownIcon,
  CaretUpIcon,
  ProfileInfoFillIcon,
  InfoSmallIcon,
  DropDownIcon,
  SidebarSettingsIcon,
  AddWhiteIcon,
} from '../assets/icons'

export default function Icons() {
  return (
    <div>
      <UserIcon />
      <PhoneIcon />
      <ChatIcon />
      <CloseIcon />
      <EmailIcon />
      <InfoIcon />
      <SearchIcon />
      <DropDownIcon />
      <AddIcon />
      <AlertInfoIcon />
      <AlertErrorIcon />
      <AlertSuccessIcon />
      <AlertWarningIcon />
      <DeleteIcon />
      <EyeIcon />
      <FacebookColorIcon />
      <LinkedInColorIcon />
      <WebsiteIcon />
      <CheckedIcon />
      <ClearIcon />
      <NoNotificationsIcon />
      <HelpIcon />
      <CloseEyeIcon />
      <InfoSmallIcon />
      <ProductEmptyState />
      <GoogleIcon />
      <ErrorRedIcon />
      <EllipsisHorizontalIcon />
      <QuoteIcon />
      <SettingsIcon />
      <OrdersIcon />
      <ConnectionsIcon />
      <CompanyIcon />
      <ChangeAccountIcon />
      <NotificationIcon />
      <SidebarSettingsIcon />
      <EllipsisVerticleIcon />
      <ActionDeleteIcon />
      <UserProfileFillIcon />
      <UsFlag />
      <EditIcon />
      <SucessIcon />
      <ErrorBlackIcon />
      <BlackDot />
      <InventoryIcon />
      <ReportsIcon />
      <AdvertisingIcon />
      <AddWhiteIcon />
      <ShippingTruck />
      <CaretDownIcon />
      <CaretUpIcon />
      <CaretLeftIcon />
      <CaretDownBlueIcon />
      <CaretRightIcon />
      <ProfileInfoFillIcon />
    </div>
  )
}
