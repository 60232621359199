import { useEffect } from 'react'
import PropTypes from 'prop-types'

export default function HtmlTitle({ title }) {
  useEffect(() => {
    document.title = `PartsClub - ${title}`
    window.scrollTo(0, 0)
  }, [title])
  return null
}

HtmlTitle.propTypes = {
  title: PropTypes.string,
}
