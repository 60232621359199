import { createContext } from 'react'

export const quotesData = [
  {
    titile: 'AAM Parts & Equipment',
    chip: 'Premium',
    img: 'src',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    equemntName: 'John Deere',
    partName: 'Loaders',
    partSerialNumber: 'Hydraulics',
    usericon: 'icon src',
    partNumber: 'LW10450034',
    price: 125,
    mainPrice: 80.5,
    stock: 'In stock',
    shiping: 'ships today',
  },
  {
    titile: 'AGA Parts',
    chip: 'Premium',
    img: 'src',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    equemntName: 'John Deere',
    partName: 'Loaders',
    partSerialNumber: 'Hydraulics',
    usericon: 'icon src',
    partNumber: 'LW10450034',
    price: 125,
    mainPrice: 80.5,
    stock: 'In stock',
    shiping: 'ships today',
  },
]
export const invitedData = [
  {
    titile: 'Lear Company',
    chip: 'Premium',
    img: 'src',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    equemntName: 'John Deere',
    partName: 'Loaders',
    partSerialNumber: 'Hydraulics',
    usericon: 'icon src',
    partNumber: 'LW10450034',
    progress: 'In-Progress',
    match: 'Matched by PartsClub Admin - request sent 2 mins ago',
  },
  {
    titile: 'Tenneco Parts',
    chip: 'Premium',
    img: 'src',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    equemntName: 'John Deere',
    partName: 'Loaders',
    partSerialNumber: 'Hydraulics',
    usericon: 'icon src',
    partNumber: 'LW10450034',
    progress: 'In-Progress',
    match: 'Auto-Matched - request sent 15 mins ago',
  },
]
export const cantParticipant = [
  {
    titile: 'Lear Company',
    img: 'src',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
  },
  {
    titile: 'Tenneco Parts',
    img: 'src',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
  },
  {
    titile: 'Tenneco Parts',
    img: 'src',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
  },
]

export const premiumSupplierData = [
  {
    img: 'src',
    brandname: 'John Deere',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    parts: ['Brakes', 'Engine'],
    supilerinfo: 'About supplier information.',
  },
  {
    img: 'src',
    brandname: 'Honda',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    parts: ['Brakes', 'Engine'],
    supilerinfo: 'About supplier information.',
  },
  {
    img: 'src',
    brandname: 'New Holland Agriculture',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    parts: ['Brakes', 'Engine'],
    supilerinfo: 'About supplier information.',
  },
  {
    img: 'src',
    brandname: 'New Holland Agriculture2',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    parts: ['Brakes', 'Engine'],
    supilerinfo: 'About supplier information.',
  },
  {
    img: 'src',
    brandname: 'New Holland Agriculture3',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    parts: ['Brakes', 'Engine'],
    supilerinfo: 'About supplier information.',
  },
]
export const matchedSupplierData = [
  {
    img: 'src',
    brandname: 'Valley Blades',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    supilerinfo: 'About supplier information.',
  },
  {
    img: 'src',
    brandname: 'Standard Motor Products',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    supilerinfo: 'About supplier information.',
  },
  {
    img: 'src',
    brandname: 'Dana Inc.',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    supilerinfo: 'About supplier information.',
  },
  {
    img: 'src',
    brandname: 'Dana Inc.2',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    supilerinfo: 'About supplier information.',
  },
  {
    img: 'src',
    brandname: 'Dana Inc.3',
    city: 'EI Paso',
    state: 'TX',
    flag: 'flag src',
    supilerinfo: 'About supplier information.',
  },
]

export const BuyerQuoteDataContext = createContext({
  saveBuyerQuoteData: () => {},
  buyerQuoteData: {},
})

export function parsingAddress(data) {
  // eslint-disable-next-line camelcase
  const company_billing_addr = {
    ...(data?.cmp_billing_addr_id && { id: data?.cmp_billing_addr_id }),
    ...(data?.cmp_billing_addr_addr_line_1 && { addr_line_1: data?.cmp_billing_addr_addr_line_1 }),
    ...(data?.cmp_billing_addr_addr_line_2 && { addr_line_2: data?.cmp_billing_addr_addr_line_2 }),
    ...(data?.cmp_billing_addr_city && { city: data?.cmp_billing_addr_city }),
    ...(data?.cmp_billing_addr_city_abbrv && { city_abbrv: data?.cmp_billing_addr_city_abbrv }),
    ...(data?.cmp_billing_addr_state && { state: data?.cmp_billing_addr_state }),
    ...(data?.cmp_billing_addr_state_abbrv && { state_abbrv: data?.cmp_billing_addr_state_abbrv }),
    ...(data?.cmp_billing_addr_country_abbrv && { country_abbrv: data?.cmp_billing_addr_country_abbrv }),
    ...(data?.cmp_billing_addr_zipcode && { zipcode: data?.cmp_billing_addr_zipcode }),
  }
  // eslint-disable-next-line camelcase
  const company_delivery_addr = {
    ...(data?.cmp_delivery_addr_id && { id: data?.cmp_delivery_addr_id }),
    ...(data?.cmp_delivery_addr_addr_line_1 && { addr_line_1: data?.cmp_delivery_addr_addr_line_1 }),
    ...(data?.cmp_delivery_addr_addr_line_2 && { addr_line_2: data?.cmp_delivery_addr_addr_line_2 }),
    ...(data?.cmp_delivery_addr_city && { city: data?.cmp_delivery_addr_city }),
    ...(data?.cmp_delivery_addr_city_abbrv && { city_abbrv: data?.cmp_delivery_addr_city_abbrv }),
    ...(data?.cmp_delivery_addr_state && { state: data?.cmp_delivery_addr_state }),
    ...(data?.cmp_delivery_addr_state_abbrv && { state_abbrv: data?.cmp_delivery_addr_state_abbrv }),
    ...(data?.cmp_delivery_addr_country_abbrv && { country_abbrv: data?.cmp_delivery_addr_country_abbrv }),
    ...(data?.cmp_delivery_addr_zipcode && { zipcode: data?.cmp_delivery_addr_zipcode }),
  }
  // eslint-disable-next-line camelcase
  return { company_billing_addr, company_delivery_addr }
}

export function parsingShippingAddress(data) {
  // eslint-disable-next-line camelcase
  const company_billing_addr = {
    // ...(data?.user_address?.id && { id: data?.user_address?.id }),
    ...(data?.user_address?.addr_line_1 && { addr_line_1: data?.user_address?.addr_line_1 }),
    ...(data?.user_address?.addr_line_2 && { addr_line_2: data?.user_address?.addr_line_2 }),
    ...(data?.user_address?.city && { city: data?.user_address?.city }),
    ...(data?.user_address?.city_abbrv && { city_abbrv: data?.user_address?.city_abbrv }),
    ...(data?.user_address?.state && { state: data?.user_address?.state }),
    ...(data?.user_address?.state_abbrv && { state_abbrv: data?.user_address?.state_abbrv }),
    ...(data?.user_address?.country_abbrv && { country_abbrv: data?.user_address?.country_abbrv }),
    ...(data?.user_address?.zipcode && { zipcode: data?.user_address?.zipcode }),
  }
  // eslint-disable-next-line camelcase
  const company_delivery_addr = {
    // ...(data?.user_address?.id && { id: data?.user_address?.id }),
    ...(data?.user_address?.addr_line_1 && { addr_line_1: data?.user_address?.addr_line_1 }),
    ...(data?.user_address?.addr_line_2 && { addr_line_2: data?.user_address?.addr_line_2 }),
    ...(data?.user_address?.city && { city: data?.user_address?.city }),
    ...(data?.user_address?.city_abbrv && { city_abbrv: data?.user_address?.city_abbrv }),
    ...(data?.user_address?.state && { state: data?.user_address?.state }),
    ...(data?.user_address?.state_abbrv && { state_abbrv: data?.user_address?.state_abbrv }),
    ...(data?.user_address?.country_abbrv && { country_abbrv: data?.user_address?.country_abbrv }),
    ...(data?.user_address?.zipcode && { zipcode: data?.user_address?.zipcode }),
  }
  // eslint-disable-next-line camelcase
  return { company_billing_addr, company_delivery_addr }
}

export const offer = [
  {
    offerNumber: '1',
  },
  {
    offerNumber: '2',
  },
  {
    offerNumber: '3',
  },
]

export function formatUserProfileAddress(data) {
  delete data?.forklift_available
  delete data?.dock_available
  delete data?.delivery_instructions
  delete data?.unit_number
  delete data?.full_name
  delete data?.email
  delete data?.company_name
  delete data?.phone
  delete data?.is_default
  return data
}
export function quoteUpdateData(modelNumber, serialNumber, partNumber, qty, shipingAddress, notes, removeAssets) {
  const address = {
    ...(shipingAddress?.id && { id: shipingAddress?.id }),
    ...(shipingAddress?.addr_line_1 && { addr_line_1: shipingAddress?.addr_line_1 }),
    ...(shipingAddress?.addr_line_2 && { addr_line_2: shipingAddress?.addr_line_2 }),
    ...(shipingAddress?.city && { city: shipingAddress.city }),
    ...(shipingAddress.city_abbrv && { city_abbrv: shipingAddress.city_abbrv }),
    ...(shipingAddress.state && { state: shipingAddress.state }),
    ...(shipingAddress.state_abbrv && { state_abbrv: shipingAddress.state_abbrv }),
    ...(shipingAddress.country_abbrv && { country_abbrv: shipingAddress.country_abbrv }),
    ...(shipingAddress.zipcode && { zipcode: shipingAddress.zipcode }),
    ...(shipingAddress.company_name && { company_name: shipingAddress.company_name }),
    ...(shipingAddress.type && { type: shipingAddress.type }),
    ...(shipingAddress.email && { email: shipingAddress.email }),
    ...(shipingAddress.fullname && { full_name: shipingAddress.fullname }),
    ...(shipingAddress.UnitNumber ? { unit_number: shipingAddress.UnitNumber } : { unit_number: '' }),
    ...(shipingAddress.deliveryinst
      ? { delivery_instructions: shipingAddress.deliveryinst }
      : { delivery_instructions: '' }),
    ...(shipingAddress.phone && { phone: shipingAddress.phone }),
    ...(shipingAddress.forklift_available
      ? { forklift_available: shipingAddress.forklift_available }
      : { forklift_available: false }),
    ...(shipingAddress.dock_available ? { dock_available: shipingAddress.dock_available } : { dock_available: false }),
  }

  return {
    user_type: 'customer',
    ...(qty && { quantity: qty }),
    note: notes || '',
    part_number: partNumber || '',
    brand_model_number: modelNumber || '',
    brand_serial_number: serialNumber || '',
    company_delivery_addr: address,
    ...(removeAssets?.length > 0 && { remove_assets: removeAssets }),
  }
}
