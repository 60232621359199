import PropTypes from 'prop-types'
import { Drawer as MuiDrawer } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  paper: {
    width: (props) => getWidth(props.width),
  },
})

export default function Drawer({ open, width, anchor = 'right', children, onClose }) {
  const classes = useStyles({ width })
  return (
    <MuiDrawer anchor={anchor} onClose={onClose} open={open} classes={{ paper: classes.paper }}>
      {children}
    </MuiDrawer>
  )
}

Drawer.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.oneOf(['md']),
  anchor: PropTypes.oneOf(['right']),
  children: PropTypes.node,
  onClose: PropTypes.func,
}

function getWidth(size) {
  switch (size) {
    case 'md':
      return 500
    default:
      return 360
  }
}
