import useSWR from 'swr'
import { getUserProfile } from 'common-api/user-profile/getUserProfile'

export default function useUserProfile() {
  const { data, mutate } = useSWR(`/user`, getUserProfile)
  return {
    profileData: data,
    mutate,
  }
}
