import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import Placeholder from 'assets/images/Placeholder.png'
import { getColor } from 'theme/colors'
import { Divider } from '@mui/material'
import { formatPrice, getWellKnownAddress } from 'utils/Utils'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import { useParams } from 'react-router-dom'
import useUserProfile from 'common-hooks/user-profile/useUserProfile'
import Image from 'components/common/Image'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { Controller, useForm, useWatch } from 'react-hook-form'
import RadioGroup from 'components/common/radio/RadioGroup'
import { useEffect } from 'react'

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'unset',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  previewHeader: {
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
    border: 'unset',
    '& div:first-child': {
      display: ' flex',
      justifyContent: ' end',
      marginRight: '20px',
    },

    '& button': {
      backgroundColor: '#fff',
      '& div': {
        marginRight: 'unset!important',
      },
    },
  },
  downloadButton: {
    backgroundColor: 'transparent!important',
    border: '0.5px solid #fff',
    color: getColor('light-main'),
    fontSize: '0.875rem',
  },
  mainContainer: {
    height: '680px',
    width: '612px',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    background: getColor('light-main'),
  },
  subContainer: {
    padding: '40px',
    width: '100%',
    height: '680px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: '500',
    color: '#13141B',
    fontSize: '10px',
    lineHeight: '14px',
  },
  otherText: {
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '14px',
    color: getColor('dark-main'),
    width: '140px',
  },
  header: {
    fontWeight: '700',
  },
  partsDetail: {
    padding: '30px 0px 7px 0px',
  },
  afterDivider: {
    padding: '10px 0px 30px 0px',
    alignItems: 'normal',
  },
  note: {
    margin: '56px 0px 40px 0px',
  },
  buyNowBtn: {
    width: '142px',
    height: '32px',
    background: getColor('func-positive'),
    borderRadius: '4px',
    '&:hover': {
      background: getColor('func-positive'),
      color: getColor('light-main'),
    },
  },
  totalContainer: {
    width: '37%',
    float: 'right',
  },
  counterBtn: {
    width: '100px',
    height: '36px',
    border: `1px solid ${getColor('grey-g65')}`,
    borderRadius: '4px',
  },
  counterBtnText: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    color: getColor('grey-g35'),
  },
  chckoutText: {
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '14px',
  },
  footerbtns: {
    alignItems: 'flex-Start',
    paddingTop: '86px ',
  },
  addresDetails: {
    lineHeight: '14px',
    fontSize: '10px',
    fontWeight: '300',
  },
  stockDetails: {
    lineHeight: '14px',
    fontSize: '10px',
    fontWeight: '400',
    color: getColor('grey-g65'),
  },

  quotesDayDetail: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'end',
  },
  partsDetailBox: {
    alignItems: 'flex-Start',
    justifyContent: 'normal',
  },
  list: {
    margin: '0px 5px',
    paddingLeft: '17px',
    fontSize: '10px',
    fontWeight: '300',
    lineHeight: '14px',
  },
  BbillText: {
    fontSize: '10px',
    fontWeight: '400',
  },
  crossPrize: {
    color: '#13141B',

    fontWeight: 500,
    fontSize: '10px',
  },
  radioBtn: {
    fontSize: '10px!important',
    '& span': {
      fontSize: '10px!important',
    },
  },
  title: {
    padding: '20px 0',
    textAlign: 'left',
    width: '612px',
  },
  imgPlaceHolder: {
    border: '1px solid #C3C5D5',
  },
  shipingOption: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '14px',
    color: '#13141B',
    marginBottom: '5px',
    display: 'inline-block',
  },
  shipingOptionRoot: {
    width: '100%',
    '& ul': {
      padding: '0',
      margin: '0',
      '& li': {
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '5px',
      },
    },
    '& .shipingRadio': {
      '& .MuiRadio-root': {
        padding: '0',
        paddingRight: '5px',
        paddingLeft: '7px',
      },
      '& .MuiFormControlLabel-label': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontStyle: 'normal',
          fontWeight: 300,
          fontSize: '10px',
          lineHeight: '14px',
          color: '#13141B',
        },
      },
    },
  },
  qntyTypography: {
    fontWeight: '500',
    fontSize: ' 10px',
    lineHeight: '14px',
    color: ' #13141B',
    // width: '300px',
  },
  radioValue: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '14px',
    color: '#13141B',
  },
  shipingrootFlex: {
    display: 'flex',
    gap: '0 67px',
    width: '37%',
  },
  acknowledge: {
    '& svg': {
      width: 11,
      height: 11,
    },
    '& .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiTypography-body1': {
      fontWeight: 300,
      fontSize: '10px',
      lineHeight: '14px',
      color: '#13141B',
    },
  },
  termFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notesArrive: {
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '14px',
    color: '#13141B',
    '& span': {
      fontWeight: 700,
    },
  },
  notesRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bandImg: {
    border: '1px solid',
    borderColor: getColor('grey-g65'),
  },
  qntyWidh: {
    fontWeight: '500',
    fontSize: ' 10px',
    lineHeight: '14px',
    color: ' #13141B',
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  partWidth: {
    fontWeight: '500',
    fontSize: ' 10px',
    lineHeight: '14px',
    color: ' #13141B',
    width: '316px',
  },
  shipingOptionRoot2: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
    width: '193px',
  },
  radioLable: {
    color: '#13141B',
    fontWeight: ' 300',
    fontSize: ' 10px',
    lineHeight: ' 14px',
  },
}))

export default function SupplierActiveQuotePreview() {
  const classes = useStyles()

  const params = useParams()
  const { profileData } = useUserProfile()

  const { supplierReqDetails, isLoading } = useReqSupplierDetails(params?.id, params?.reqId)
  const createDate = supplierReqDetails?.createdAt
  const { control, setValue } = useForm()
  const validDate = supplierReqDetails?.valid_until
  let freightFilter
  if (supplierReqDetails?.Information?.freight !== '') {
    freightFilter = supplierReqDetails?.Information?.freight.filter((element) => element !== null)
  }
  if (freightFilter !== '') {
    freightFilter = freightFilter?.filter((m) => m.frieghtstate.value !== false)
  }
  const [options] = useWatch({
    control,
    name: ['options'],
  })
  useEffect(() => {
    setValue('options', Object.values(supplierReqDetails?.quote_request_delivery_options)[0])
  }, [setValue, supplierReqDetails])

  // const cmpAddress = profileData?.user_address
  function freightTotal(freight) {
    let total = supplierReqDetails?.quote_request_amount
    if (freight) {
      total += parseInt(freight, 10)
    }
    return total
  }

  return (
    <Container className={classes.root}>
      {isLoading && <LoadingOverlay />}
      <Container className={classes.mainContainer} id={'divToPrint'}>
        <Container className={classes.subContainer}>
          <Container>
            <Container flex justify={'space-between'}>
              <Image
                src={profileData?.company_profile || Placeholder}
                alt="Quotes Preview"
                width={'43.88px'}
                height={'43.88px'}
                className={classes.bandImg}
              />
              <Typography variant="body1" className={classes.header}>
                Quote
              </Typography>
            </Container>
            <Container>
              <Container>
                <Typography className={classes.text}>{profileData?.company_name || ''}</Typography>
              </Container>
              <Container flex justify={'space - between'}>
                <Container className={classes.otherText}>
                  <Typography className={classes.addresDetails}>
                    {getWellKnownAddress(profileData?.company_addr_line_1, profileData?.company_addr_line_2)}
                  </Typography>
                  {/* <Typography className={classes.addresDetails}>
                    {getWellKnownAddress(
                      supplierReqDetails?.cmp_delivery_addr_city,
                      supplierReqDetails?.cmp_delivery_addr_state_abbrv,
                      supplierReqDetails?.cmp_delivery_addr_zipcode,
                      supplierReqDetails?.cmp_delivery_addr_country_abbrv ||
                        supplierReqDetails?.cmp_delivery_addr_country
                    )}
                  </Typography> */}
                </Container>

                <Container flex width={'80%'} justify={'flex-end'} gap={'30px'}>
                  <Container className={classes.quotesDayDetail}>
                    <Typography className={classes.addresDetails}>Quote:</Typography>
                    <Typography className={classes.addresDetails}>Date:</Typography>
                    <Typography className={classes.addresDetails}>Valid Until:</Typography>
                  </Container>
                  <Container className={classes.quotesDayDetail}>
                    <Typography className={classes.addresDetails}>{supplierReqDetails?.quote_number}</Typography>
                    <Typography className={classes.addresDetails}>
                      {formatDate(createDate, DATE_FORMAT_PRIMARY)}
                    </Typography>
                    <Typography className={classes.addresDetails}>
                      {formatDate(validDate, DATE_FORMAT_PRIMARY)}
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container>
              <Container>
                <Container>
                  <Typography className={classes.text}>Shipping To :</Typography>
                </Container>
                <Container className={classes.otherText}>
                  <Typography className={classes.addresDetails}>
                    {supplierReqDetails?.cmp_billing_addr_company_name}
                  </Typography>
                  <Typography className={classes.addresDetails}>
                    {getWellKnownAddress(
                      supplierReqDetails?.cmp_delivery_addr_addr_line_1,
                      supplierReqDetails?.cmp_delivery_addr_addr_line_2
                    )}
                  </Typography>
                </Container>
              </Container>
            </Container>
            {/* Image shipping detail starts */}
            <Container flex className={classes.partsDetail} gap={'0 10px'}>
              <Container width={'316px'}>
                <Typography className={classes.partWidth}>Part</Typography>
              </Container>
              <Typography className={classes.qntyWidh}>Quantity</Typography>
              <Typography className={classes.qntyWidh}> Total</Typography>
            </Container>
            <Divider />

            <Container flex justify={'flex-start'} gap={'5px'} className={classes.afterDivider}>
              <Container>
                <>
                  {
                    supplierReqDetails?.quote_assets?.map((file, index) => {
                      const url = {
                        url: file.file ? URL.createObjectURL(file.file) : file?.part_image,
                        index,
                        assetId: file.id,
                        indexImage: 0,
                      }
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <Image
                          src={url?.url}
                          alt="Quotes Preview"
                          width={'62px'}
                          height={'58px'}
                          className={classes.bandImg}
                        />
                      )
                    })[0]
                  }
                </>
              </Container>
              <Container width={'345px'} flex direction={'column'} className={classes.partsDetailBox}>
                <Typography className={classes.text}>
                  {supplierReqDetails?.brand_name} - {supplierReqDetails?.part_name}{' '}
                  {supplierReqDetails?.part_number !== null ? `- ${supplierReqDetails?.part_number}` : ''}
                </Typography>
                <Typography className={classes.stockDetails}>
                  {supplierReqDetails?.quote_request_condition === 'new_aftermarket' && 'New Aftermarket'}
                  {supplierReqDetails?.quote_request_condition === 'new_oem' && 'New OEM'}
                  {supplierReqDetails?.quote_request_condition === 'used' && 'Used'}
                  {supplierReqDetails?.quote_request_weight && ` • ${supplierReqDetails?.quote_request_weight}lbs`}
                </Typography>
                <Typography className={classes.stockDetails}>
                  {supplierReqDetails?.quote_request_ships_in &&
                    `Ships in ${supplierReqDetails?.quote_request_ships_in} business days`}
                </Typography>
                {supplierReqDetails?.quote_request_can_return === false ? (
                  <Container className={classes.acknowledge}>
                    <Typography className={classes.radioLable}>
                      I acknowledge that this item is non-returnable
                    </Typography>
                  </Container>
                ) : (
                  ''
                )}
              </Container>

              <Container flex gap={'0 10px'} className={classes.partsDetailBox}>
                <Container width={100} justify={'flex-end'}>
                  <Typography className={classes.qntyTypography}>{supplierReqDetails?.quantity || '-'}</Typography>
                </Container>
                <Container width={100} justify={'flex-end'}>
                  <Typography className={classes.crossPrize} width={100} justify={'flex-end'}>
                    {formatPrice(supplierReqDetails?.quote_request_amount) || formatPrice(0)}
                  </Typography>
                </Container>
              </Container>
            </Container>

            <Container className={classes.notesRoot}>
              <Container>
                {supplierReqDetails?.note && (
                  <Typography className={classes.notesArrive} component={'p'}>
                    <span>Notes:</span>
                    {`  ${supplierReqDetails?.note}`}
                  </Typography>
                )}
              </Container>
              {/* <Container className={classes.shipingrootFlex} justify="space-between">
                <Container className={classes.shipingOptionRoot}>
                  {supplierReqDetails?.Information?.freight?.length > 0 && (
                    <Typography className={classes.shipingOption}>Shipping Options</Typography>
                  )}

                  <Container>
                    <ul>
                      {supplierReqDetails.Information?.freight?.map((obj) =>
                        obj?.frieghtstate?.value === true ? (
                          <li>
                            <Radio label={obj?.frieghtstate?.label} className="shipingRadio" />
                            <Typography variant="paragraph1" className={classes.radioValue}>
                              ${obj.freightvalue}
                            </Typography>
                          </li>
                        ) : (
                          ''
                        )
                      )}
                    </ul>
                  </Container>
                </Container>
              </Container> */}
              <Container className={classes.shipingrootFlex} justify="space-between">
                <Container className={classes.shipingOptionRoot}>
                  <Typography className={classes.shipingOption}>Shipping Options</Typography>
                  <Container>
                    <Controller
                      name={'options'}
                      control={control}
                      defaultValue={supplierReqDetails?.quote_request_delivery_options?.freight_next_day}
                      render={({ field: { name, value } }) => (
                        <RadioGroup
                          defaultValue={supplierReqDetails?.quote_request_delivery_options?.freight_next_day}
                          value={value}
                          onChange={(e) => {
                            setValue(name, e.target.value)
                          }}
                        >
                          <ul>
                            {supplierReqDetails?.quote_request_delivery_options?.freight_next_day && (
                              <li className={classes.shipingOptionRoot2}>
                                <Typography className={classes.radioLable}>Next Day Delivery</Typography>
                                {/* <Radio
                                    label={'Next Day Delivery'}
                                    name={name}
                                    value={supplierReqDetails?.quote_request_delivery_options?.freight_next_day}
                                    className="shipingRadio"
                                  /> */}
                                <Typography variant="paragraph1" className={classes.radioValue}>
                                  {Number(supplierReqDetails?.quote_request_delivery_options?.freight_next_day) === 0
                                    ? 'free'
                                    : formatPrice(supplierReqDetails?.quote_request_delivery_options?.freight_next_day)}
                                </Typography>
                              </li>
                            )}

                            {supplierReqDetails?.quote_request_delivery_options?.freight_second_day && (
                              <li className={classes.shipingOptionRoot2}>
                                {/* <Radio
                                    label={'2nd Day'}
                                    name={name}
                                    value={supplierReqDetails?.quote_request_delivery_options?.freight_second_day}
                                    className="shipingRadio"
                                  /> */}
                                <Typography className={classes.radioLable}>2nd Day</Typography>
                                <Typography variant="paragraph1" className={classes.radioValue}>
                                  {Number(supplierReqDetails?.quote_request_delivery_options?.freight_second_day) === 0
                                    ? 'free'
                                    : formatPrice(
                                        supplierReqDetails?.quote_request_delivery_options?.freight_second_day
                                      )}
                                </Typography>
                              </li>
                            )}

                            {supplierReqDetails?.quote_request_delivery_options?.freight_ground && (
                              <li className={classes.shipingOptionRoot2}>
                                {/* <Radio
                                    label={'Ground'}
                                    name={name}
                                    value={supplierReqDetails?.quote_request_delivery_options?.freight_ground}
                                    className="shipingRadio"
                                  /> */}
                                <Typography className={classes.radioLable}>Ground</Typography>
                                <Typography variant="paragraph1" className={classes.radioValue}>
                                  {Number(supplierReqDetails?.quote_request_delivery_options?.freight_ground) === 0
                                    ? 'free'
                                    : formatPrice(supplierReqDetails?.quote_request_delivery_options?.freight_ground)}
                                </Typography>
                              </li>
                            )}

                            {supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up && (
                              <li className={classes.shipingOptionRoot2}>
                                {/* <Radio
                                    label={'Pick Up'}
                                    name={name}
                                    value={supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up}
                                    className="shipingRadio"
                                  /> */}
                                <Typography className={classes.radioLable}>Pick Up</Typography>
                                <Typography variant="paragraph1" className={classes.radioValue}>
                                  {Number(supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up) === 0
                                    ? 'free'
                                    : formatPrice(supplierReqDetails?.quote_request_delivery_options?.mdo_pick_up)}
                                </Typography>
                              </li>
                            )}

                            {supplierReqDetails?.quote_request_delivery_options?.mdo_ltl && (
                              <li className={classes.shipingOptionRoot2}>
                                {/* <Radio
                                    name={name}
                                    value={supplierReqDetails?.quote_request_delivery_options?.mdo_ltl}
                                    className="shipingRadio"
                                    label={'LTL'}
                                  /> */}
                                <Typography className={classes.radioLable}>LTL</Typography>
                                <Typography variant="paragraph1" className={classes.radioValue}>
                                  {Number(supplierReqDetails?.quote_request_delivery_options?.mdo_ltl) === 0
                                    ? 'free'
                                    : formatPrice(supplierReqDetails?.quote_request_delivery_options?.mdo_ltl)}
                                </Typography>
                              </li>
                            )}

                            {supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery && (
                              <li className={classes.shipingOptionRoot2}>
                                {/* <Radio
                                    label={'Local Delivery'}
                                    name={name}
                                    value={supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery}
                                    className="shipingRadio"
                                  /> */}
                                <Typography className={classes.radioLable}>Local Delivery</Typography>
                                <Typography variant="paragraph1" className={classes.radioValue}>
                                  {Number(supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery) !== 0
                                    ? formatPrice(
                                        supplierReqDetails?.quote_request_delivery_options?.mdo_local_delivery
                                      )
                                    : 'free'}
                                </Typography>
                              </li>
                            )}
                          </ul>
                        </RadioGroup>
                      )}
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>

          <Container>
            <Container className={classes.termFlex}>
              <Container>
                <Typography className={classes.text}>Terms & Conditions</Typography>

                <ul className={classes.list}>
                  <li>Returnable:{supplierReqDetails?.quote_request_can_return ? ' Yes ' : ' No '} </li>
                  {supplierReqDetails?.quote_request_warranty && <li> Warranty: 365 days </li>}
                  {supplierReqDetails?.quote_request_cancel_product && <li>Cancelable: Prior to shipment</li>}
                </ul>
              </Container>
              <Container className={classes.totalContainer}>
                <Container flex justify={'space-between'}>
                  <Typography className={classes.BbillText}>Subtotal</Typography>
                  <Typography className={classes.BbillText}>
                    {formatPrice(supplierReqDetails?.quote_request_amount)}
                  </Typography>
                </Container>
                <Container flex justify={'space-between'}>
                  <Typography className={classes.BbillText}>Freight</Typography>
                  <Typography className={classes.BbillText}>{formatPrice(options)}</Typography>
                </Container>
                <Divider color={'1px solid '} />
                <Container flex justify={'space-between'}>
                  <Typography className={classes.text}>Total</Typography>
                  <Typography className={classes.BbillText}>{formatPrice(freightTotal(options))}</Typography>
                </Container>
                <Divider color={'1px solid '} />
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
