import { makeStyles } from '@mui/styles'
import { useStripe } from '@stripe/react-stripe-js'
import { AddIcon } from 'assets/icons'
import { checkout, createSubscription, getSetupIntent } from 'common-api/settings/settings'
import usePaymentMethods from 'common-hooks/settings/payment/usePaymentMethods'
import useSubscriptionPlans from 'common-hooks/settings/subscription/useSubscriptionPlans'
import useToastify from 'common-hooks/useToastify'
import useToggle from 'common-hooks/useToggle'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Radio from 'components/common/radio/Radio'
import RadioGroup from 'components/common/radio/RadioGroup'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import PaymentMethodDialog from 'containers/common-containers/add-payment-method/PaymentMethodDialog'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getColor } from 'theme/colors'
import { constants } from 'utils/constants'
import { formatPrice, getFromLocalStorage, setToLocalStorage } from 'utils/Utils'
import PaymentMethodRadioCard from './PaymentMethodRadioCard'
import UpgradePlanRadioCard from './UpgradePlanRadioCard'

const useStyles = makeStyles({
  upgradeRoot: {
    backgroundColor: '#fff',
  },
  cancelButtton: {
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    color: '#4A4D68',
    border: '1px solid #9799B5',
    backgroundColor: 'transparent',
    width: '77px',
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 30px',
    borderBottom: ' 1px solid #F0F0F4',
  },
  bodyLeftInner: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  radioGrp: {
    display: 'flex',
    flexDirection: 'row',
    '& div': {
      display: 'flex',
      gap: '0 25px',
      flexDirection: 'row',
    },
  },
  Bodytitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: ' 9px',
    paddingTop: '38px',
    paddingBottom: '18px',
  },
  cardRadio: {
    width: '100%',
  },
  paymentMethodCard: {
    marginTop: '18px',
  },
  addPaymentMwthod: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '0 10px',
    height: '64px',
    border: '1px solid #F0F0F4',
    borderRadius: '4px',
    padding: '20px',
    cursor: 'pointer',
  },
  upgradeBody: {
    display: 'flex',
    height: '94vh',
  },
  bodyRight: {
    width: '22%',
    background: '#F8F9F9',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
    borderLeft: '1px solid #F0F0F4',
  },
  bodyLeft: {
    width: '78%',
  },
  p10: {
    padding: '10px 0',
    color: getColor('grey-g25'),
  },
  loader: {
    width: '100%',
    position: 'absolute',
    background: 'rgb(255 255 255 / 80%)',
    zIndex: '100000',
  },
})

const SubscriptionUpgrade = () => {
  const classes = useStyles()
  const stripe = useStripe()
  const navigate = useNavigate()
  const location = useLocation()
  const planId = location?.state?.id
  const [loading, setLoading] = useState(false)
  const [activePaymentMethod, setActivePaymentMethod] = useState()
  const [paymentDialog, setPaymentDialog] = useToggle(false)
  const { toastMessage } = useToastify()
  const upgradePlan = location?.state?.upgradePlan
  const bilingChange = location?.state?.chanegBilling
  const [planCheck, setPlanCheck] = useState('annually')
  const { paymentMethods } = usePaymentMethods()
  const userRole = getFromLocalStorage(constants.ROLE)
  const { paidPlans, isLoading } = useSubscriptionPlans(
    userRole === 'customer' || location?.state?.type === 'buyer_plans' ? 'buyer_plans' : 'seller_plans'
  )
  const [activeCard, setActiveCard] = useState()

  const handlePlan = (event) => {
    if (event.target.value === planCheck) {
      setPlanCheck('')
    } else {
      setPlanCheck(event.target.value)
    }
  }

  const addPaymentMethod = async () => {
    setLoading(true)
    const setupIntent = await getSetupIntent()
    setToLocalStorage(constants.CLIENT_SECRET, setupIntent.client_secret)
    setLoading(false)
    setPaymentDialog()
  }

  useEffect(() => {
    if (paidPlans?.length > 0 && !activeCard) {
      setActiveCard(
        planId
          ? { id: planId, stripe_plan_id: paidPlans?.[0]?.stripe_plan_id }
          : { id: paidPlans?.[0]?.plan_type, stripe_plan_id: paidPlans?.[0]?.stripe_plan_id }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paidPlans])

  const PlanSummaryArray = paidPlans.filter((d) => d.plan_type === activeCard?.id)[0]
  const PlanSummaryObject = { ...PlanSummaryArray, planCheck }

  async function confirmPayment() {
    try {
      // call the backend to create subscription
      const response = await createSubscription({
        stripe_plan_id:
          planCheck === 'monthly' ? activeCard.stripe_plan_id.monthly : activeCard.stripe_plan_id.annually,
        card_id: activePaymentMethod,
      })
      const confirmPayment = await stripe?.confirmCardPayment(response?.client_secret)

      if (confirmPayment?.error) {
        toastMessage('error', 'Something went wrong!')
      } else {
        const data = {
          payment_intent_id: confirmPayment?.paymentIntent?.id,
          client_secret: confirmPayment?.paymentIntent?.client_secret,
          subscription_intent_id: response?.subscription_intent_id,
          status: confirmPayment?.paymentIntent?.status,
          plan_type: activeCard?.id,
        }
        toastMessage('success', 'Payment successful!')
        await checkout(data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Container>
      <Container className={classes.upgradeRoot}>
        <Container className={classes.title}>
          <Button className={classes.cancelButtton} onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Container width="55%">
            <Typography variant="title" color={getColor('grey-g25')}>
              Upgrade Account
            </Typography>
          </Container>
        </Container>
        <Container className={classes.upgradeBody}>
          {loading && <LoadingOverlay className={classes.loader} />}
          <Container className={classes.bodyLeft}>
            <Container className={classes.bodyLeftInner}>
              <Container className={classes.Bodytitle}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  Your Plan
                </Typography>
                <RadioGroup className={classes.radioGrp} value={planCheck}>
                  <Radio value={'monthly'} label="Monthly" onChange={handlePlan} />
                  <Radio value={'annually'} label="Annually" onChange={handlePlan} />
                </RadioGroup>
              </Container>
              <Container className={classes.cardGrid}>
                {isLoading && <LoadingOverlay />}
                <RadioGroup className={classes.cardRadio}>
                  {paidPlans.map((plan, index) => (
                    <UpgradePlanRadioCard
                      key={index}
                      onClick={() => setActiveCard({ id: plan.plan_type, stripe_plan_id: plan.stripe_plan_id })}
                      value={activeCard?.id}
                      activeCard={activeCard?.id}
                      id={plan.plan_type}
                      name={plan.plan_type}
                      price={planCheck === 'monthly' ? plan.price.monthly : plan.price.annually}
                      planDescription={plan.plan_description}
                      plan={plan.plan_type}
                      offer={plan.benefit}
                      offerlist={plan.benefit_title}
                      upgradePlan={upgradePlan}
                      bilingChange={bilingChange}
                    />
                  ))}
                </RadioGroup>
              </Container>
              <Container>
                <Typography variant="title" color={getColor('grey-g25')}>
                  Payment Method
                </Typography>

                <Container className={classes.paymentMethodCard}>
                  {paymentMethods?.length > 0 && (
                    <RadioGroup className={classes.cardRadio}>
                      {paymentMethods?.map((d, index) => (
                        <PaymentMethodRadioCard
                          key={index}
                          id={d.id}
                          value={activePaymentMethod}
                          lastDigits={d.last_digits}
                          exp={`${d.exp_month}/${d.exp_year}`}
                          onChange={() => setActivePaymentMethod(d.id)}
                        />
                      ))}
                    </RadioGroup>
                  )}
                  <Container className={classes.addPaymentMwthod} onClick={() => addPaymentMethod()}>
                    <AddIcon />
                    <Typography variant="body1" color={getColor('grey-g25')}>
                      Add Card
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container className={classes.bodyRight}>
            <Container>
              <Typography variant="title" color={getColor('grey-g25')} className={classes.p10}>
                Summary
              </Typography>
              <Container>
                <Container flex justify="space-between">
                  <Typography variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                    Plan
                  </Typography>
                  <Typography variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                    {PlanSummaryObject.plan_type}
                  </Typography>
                </Container>
                <Container flex justify="space-between">
                  <Typography variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                    Billing Period
                  </Typography>
                  <Typography variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                    {PlanSummaryObject.planCheck === 'monthly' ? 'Monthly' : 'Annually'}
                  </Typography>
                </Container>
              </Container>
            </Container>
            <Seperator />
            <Container>
              <Container flex justify="space-between">
                <Typography variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                  Sub Total
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                  $
                  {PlanSummaryObject.planCheck === 'monthly'
                    ? PlanSummaryObject?.price?.monthly
                    : PlanSummaryObject?.price?.annually * 12}
                </Typography>
              </Container>
              {/* <Container flex justify="space-between">
                <Typography variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                  Taxes
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')} className={classes.p10}>
                  $10.00
                </Typography>
              </Container> */}
            </Container>
            <Seperator />
            <Container flex justify="space-between">
              <Typography variant="title" color={getColor('grey-g25')} className={classes.p10}>
                Total
              </Typography>
              <Typography variant="title" color={getColor('grey-g25')} className={classes.p10}>
                {''}
                {formatPrice(
                  Number(
                    PlanSummaryObject.planCheck === 'monthly'
                      ? PlanSummaryObject?.price?.monthly
                      : PlanSummaryObject?.price?.annually * 12
                  ) + 10
                ) || '-'}
              </Typography>
            </Container>
            <Button
              disabled={!activePaymentMethod || !activeCard}
              onClick={() => {
                setLoading(true)
                confirmPayment()
              }}
              // onClick={() =>
              //   navigate('/settings/subscription', {
              //     state: { status: 'active', plan_type: PlanSummaryObject.plan_type },
              //   })
              // }
            >
              Pay Now
            </Button>
          </Container>
        </Container>
      </Container>
      <PaymentMethodDialog open={paymentDialog} onClose={setPaymentDialog} successURL={'upgradeaccount'} />
    </Container>
  )
}

export default SubscriptionUpgrade
