import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import TextArea from 'components/common/text/TextArea'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import TextField from 'components/common/text/TextField'
import useToastify from 'common-hooks/useToastify'
import useGetHelp from 'common-hooks/useGetHelp'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px ',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  message: {
    border: 'unset',
    '& input': {
      border: 'unset',
      backgroundColor: 'unset',
    },
  },
  TextArea: {
    height: '200px',
    '& textarea': {
      border: 'unset',
      backgroundColor: 'unset',
      padding: '0',
    },
  },
  subject: {
    height: '46px',
    borderTop: '1px solid #F0F0F4',
    borderBottom: '1px solid #F0F0F4',
    '& input': {
      border: 'unset',
      backgroundColor: 'unset',
      padding: '0',
      fontWeight: ' 300',
    },
    '& input:placeholder': {
      color: '#9799B5',
    },
  },
})

export default function HelpDialogWithSubject({ open, onClose, section }) {
  const classes = useStyles()
  const { control, handleSubmit, reset } = useForm()
  const { toastMessage } = useToastify()

  //   const options = countryOption?.map((item) => createOption(item, item))
  const { helpDialog } = useGetHelp()
  const onSubmit = (data) => {
    if (!data?.subject || data?.subject === '') {
      toastMessage('error', 'Please enter subject')
      return
    }
    if (data?.message === '' || !data?.message) {
      toastMessage('error', 'Please enter message body')
      return
    }
    helpDialog('support@partsclub.us', data?.subject, data.message, section)
    reset({
      subject: '',
      message: '',
    })
    onClose()
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Help'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.selectField}>
              <Container>
                <Typography variant="paragraph1" color={getColor('grey-g65')}>
                  support@partsclub.us
                </Typography>
              </Container>
              <Container>
                <Controller
                  name={'subject'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      id={name}
                      name={name}
                      defaultValue={''}
                      value={value}
                      onChange={onChange}
                      className={classes.subject}
                      placeholder="Subject"
                    />
                  )}
                />
              </Container>
              <Container className={classes.TextArea}>
                <Controller
                  name={'message'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextArea
                      name={name}
                      value={value}
                      onChange={onChange}
                      // className={classes.TextArea}
                      // error={getFormError(errors, name)}
                      placeholder={'Write a message here...'}
                    />
                  )}
                />
              </Container>
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">Send</Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
HelpDialogWithSubject.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  section: PropTypes.string,
}
