/* eslint-disable camelcase */
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import GooglePlaces from 'components/custom/GooglePlaces'
import useUserProfile from 'common-hooks/user-profile/useUserProfile'
import { SupplierQuoteContext } from '../SupplierQuoteDetailsUtils'
import { getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: '690px',
    overflowY: 'scroll',
  },
  group: {
    display: 'flex',
    gap: '0 16px',
  },
  radiogrp: {
    display: 'flex',
    gap: '0 26px',
    height: '20px',
    // flexDirection: 'row',
  },
  pb10: {
    paddingBottom: '10px',
  },
  billing: {
    fontWeight: '700',
    color: getColor('grey-g25'),
    paddingTop: '5px',
  },
  bilingAddrres: {
    height: '20px',
  },
})

const ShippingAddressDialog = ({ open, onclose }) => {
  const { onSaveSupplierQuoteInformaton, supplierQueoteInformation } = useContext(SupplierQuoteContext)
  const { control, setValue, getValues, handleSubmit } = useForm()
  const { profileData } = useUserProfile()
  const handeleCloseDilaog = () => {
    onclose()
  }
  const onSubmit = (data) => {
    onSaveSupplierQuoteInformaton((pre) => {
      pre.company_billing = data?.company_billing || pre?.company_billing
      pre.addChange = true
      return pre
    })
    onclose()
  }
  const cmpAddress = supplierQueoteInformation?.addChange
    ? supplierQueoteInformation.company_billing
    : profileData?.user_address
  useEffect(() => {
    if (open) {
      setValue('company_billing', cmpAddress)
    }
    // Object.keys(parsedSupplierData).map((key) => setValue(key, parsedSupplierData[key]))
  }, [open])
  const classes = useStyles()
  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Shipping Address'} onClose={handeleCloseDilaog} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.dialogbody} direction={'column'} gap={'10px 0'}>
            <Controller
              name={'company_billing'}
              control={control}
              render={({ field: { name } }) => (
                <GooglePlaces
                  id={name}
                  name={name}
                  label="Location"
                  spacing={3}
                  placeholder={'e.g. 4517 Washington Ave'}
                  value={
                    getValues()?.company_billing?.addr_line_1
                      ? getWellKnownAddress(
                          getValues()?.company_billing?.addr_line_1,
                          getValues()?.company_billing?.addr_line_2
                        )
                      : ''
                  }
                  onSelect={(loc) => {
                    setValue(
                      'company_billing',
                      formatAddressOfGAPI(
                        loc.addr,
                        loc.addr_line_1,
                        loc.addr_line_2,
                        null,
                        getValues()?.company_billing?.id
                      )
                    )
                  }}
                />
              )}
            />
          </Container>

          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={handeleCloseDilaog}>
                Cancel
              </Button>
              <Button type="submit" width={70}>
                Apply
              </Button>
            </Container>
          </DialogFooter>
        </form>
      </Dialog>
    </Container>
  )
}
ShippingAddressDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
}

export default ShippingAddressDialog
