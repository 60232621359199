import { CardContent, CardMedia } from '@mui/material'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import propTypes from 'prop-types'
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import { EarlyBadge, EmailIcon, PhoneIcon, WebsiteIcon, Verfied } from 'assets/icons'
import HeaderPlaceHolder from 'assets/images/CardHeaderPlaceHolder.png'
import Chip from 'components/common/Chip'
import Link from 'components/common/Link'
import { useNavigate } from 'react-router-dom'
import Tooltip from 'components/common/Tooltip'
import { capitalizeFirstLetter, getFlag, getShortAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    height: '341px',
    width: '270px',
    borderRadius: '0px',
    transition: 'boxShadow 2s ease-out 100ms',
    border: `1px solid ${getColor('grey-g95')}`,
    '&:hover': {
      boxShadow: '0px 16px 14px rgba(0, 0, 0, 0.06),0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
    position: 'relative',
  },
  banner: {
    backgroundColor: getColor('grey-g95'),
    width: '100%',
    height: '80px',
    // position: 'relative',
  },
  logo: {
    backgroundColor: getColor('light-main'),
    width: '80px',
    height: '80px',
    top: '40px',
    left: '20px',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
    position: 'absolute',
  },
  info: {
    padding: '20px',
    paddingTop: '16px',
  },
  linkButton: {
    height: '40px',
    width: '40px',
  },
  chipContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '13px',
    right: '20px',
  },
  verifyMain: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '20px',
    paddingTop: '10px',
    height: '34px',
  },
  premimumChip: {
    height: '20px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    color: '#13141B',
    borderRadius: '100px',
    '& div': {
      color: '#13141B',
      fontSize: '11px',
    },
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tagLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
export default function SupplierCard({
  id,
  name,
  imgheader,
  status,
  isPremium,
  logo,
  isVerified,
  earlyAdopt,
  city,
  // stateAbbrv,
  state,
  tagline,
  email,
  phoneNumber,
  industries,
  websiteUrl,
  country,
  countryAbbrv,
}) {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <Container
      className={classes.root}
      elevation={0}
      key={id}
      // onClick={() => navigate(`/sa/supplierdetails/${data?.id}`)}
      onClick={() => navigate(`/sa/editsupplier/${name}/${id}`)}
    >
      <CardMedia
        id="banner"
        className={classes.banner}
        component="img"
        image={imgheader || HeaderPlaceHolder}
        onError={(e) => {
          e.target.src = 'https://via.placeholder.com/150'
        }}
      />
      <Container className={classes.chipContainer}>
        {status === 'inactive' && <Chip label={capitalizeFirstLetter(status)} variant="error" />}
        {status === 'active' && <Chip label={capitalizeFirstLetter(status)} variant="secondary" />}
        {isPremium && <Chip label={'Premium'} className={classes.premimumChip} />}
      </Container>
      <CardMedia
        id="logo"
        className={classes.logo}
        component="img"
        image={logo}
        onError={(e) => {
          e.target.src = 'https://via.placeholder.com/150'
        }}
      />

      <Container className={classes.verifyMain}>
        {isVerified ? <Verfied /> : ''}
        {earlyAdopt ? <EarlyBadge /> : ''}
      </Container>
      <CardContent className={classes.info}>
        <Container direction="column" gap="15px" justify="flex-start">
          <Container>
            <Typography variant="title" component="div" className={classes.title}>
              {name}
            </Typography>
            <Container display="flex" alignItems="center" gap="5px">
              <Typography variant="paragraph1" component="div">
                {getShortAddress(city, state, country, countryAbbrv)}
                {/* {getWellKnownAddress(country || countryAbbrv)} */}
              </Typography>
              {getFlag(country)}
            </Container>
          </Container>
          <Typography variant="paragraph1" component="div" className={classes.tagLine}>
            {tagline || '-'}
          </Typography>
          <Container flex direction="row" gap="10px" justify="flex-start">
            <Link to={`email:${email}`}>
              <Button className={classes.linkButton} variant="secondary" border icon={<EmailIcon />} />
            </Link>
            <Link to={`tel:${phoneNumber}`}>
              <Button className={classes.linkButton} variant="secondary" border icon={<PhoneIcon />} />
            </Link>
            <Link to={websiteUrl || ''}>
              <Button className={classes.linkButton} variant="secondary" border icon={<WebsiteIcon />} />
            </Link>
          </Container>
          {industries.length && (
            <Container flex direction="row" gap="10px" justify="flex-start" height="50px">
              {industries?.map((ind) => ind?.name && <Chip label={ind?.name} />)[0]}
              {industries?.length - 1 === 0 ? (
                ''
              ) : (
                <Tooltip placement={'right-start'} title={industries.map((ind) => `${ind?.name} `)} maxWidth={'200px'}>
                  <p>
                    {' '}
                    <Chip label={`+ ${industries?.length - 1}`} />
                  </p>
                </Tooltip>
              )}
            </Container>
          )}
        </Container>
      </CardContent>
    </Container>
  )
}
SupplierCard.propTypes = {
  id: propTypes.string,
  name: propTypes.string,
  imgheader: propTypes.string,
  status: propTypes.string,
  isPremium: propTypes.string,
  logo: propTypes.string,
  isVerified: propTypes.string,
  earlyAdopt: propTypes.string,
  country: propTypes.string,
  tagline: propTypes.string,
  email: propTypes.string,
  phoneNumber: propTypes.string,
  industries: propTypes.array,
  websiteUrl: propTypes.string,
  countryAbbrv: propTypes.string,
  city: propTypes.string,
  state: propTypes.string,
}
