/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useState, useEffect, useRef } from 'react'
import typography from 'theme/typography'

import IconButton from 'components/common/IconButton'
import { ClearIcon } from 'assets/icons'
import TextField from 'components/common/text/TextField'
import Label from '../common/Label'

const GOOGLE_PLACE_API_KEY = 'AIzaSyBUnzuK9Dp4dBQ6ijkIWStNyN8vnynli5k'
let autoComplete
let locationObj

const useStyles = makeStyles(() => ({
  root: {
    // marginBottom: (props) => theme.spacing(props.spacing),
    width: (props) => props.width,
    position: 'relative',
  },
  labelTypography: {
    fontSize: typography.paragraph2.fontSize,
    fontWeight: typography.paragraph2.fontWeight,
  },
  clearIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))
const loadScript = (url, callback) => {
  let script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function loadMap() {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}
const options = {
  // bounds: defaultBounds,
  componentRestrictions: { country: 'us' },
  fields: ['address_components', 'formatted_address', 'geometry', 'icon', 'name'],
  strictBounds: true,
  types: ['establishment'],
}
function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, options)
  autoComplete.setFields(['address_components', 'formatted_address'])
  autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery))
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace()
  locationObj = addressObject?.address_components
  const query = addressObject.formatted_address
  updateQuery(query)
}

function GooglePlace({
  id,
  name,
  size,
  spacing = 0,
  width,
  placeholder,
  label,
  error,
  labelSpacing = 0,
  className,
  setLocationArray,

  setValue,
  onChange,
  onBlur,
  readOnly,
  disabled,
  value,
}) {
  const [query, setQuery] = useState(null)
  const autoCompleteRef = useRef(null)

  // ${process.env.REACT_APP_GOOGLE_API_KEY}
  useEffect(() => {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACE_API_KEY}&libraries=places`, () =>
      handleScriptLoad(setQuery, autoCompleteRef)
    )
  }, [])
  useEffect(() => {
    if (autoCompleteRef?.current?.value) {
      onChange(autoCompleteRef?.current?.value)
    }
  }, [autoCompleteRef?.current?.value])

  useEffect(() => {
    setQuery(value)
  }, [])
  const InputOnChange = (event) => {
    setQuery(event.target.value)
  }
  const classes = useStyles({
    spacing,
    size,
    width,
    readOnly,
    disabled,
    error,
  })
  const onClear = () => {
    setQuery('')
    setLocationArray([])
    setValue('location', '')
    setValue('street', '')

    locationObj = ''
    // setValue('location', '')
  }
  setLocationArray(locationObj)

  return (
    <Grid container direction="column" className={classNames(classes.root)}>
      {label && (
        <Label className={classes.labelTypography} spacing={labelSpacing}>
          {label}
        </Label>
      )}
      <TextField
        id={id}
        ref={autoCompleteRef}
        name={name}
        onChange={InputOnChange}
        placeholder={placeholder}
        value={query}
        className={className}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        rightIcon={query ? <ClearButton onClick={onClear} /> : ''}
      />
    </Grid>
  )
}

export default GooglePlace

function ClearButton(props) {
  const classes = useStyles()
  return (
    <IconButton size="md" variant="transparent" className={classes.clearIcon} {...props}>
      <ClearIcon />
    </IconButton>
  )
}

GooglePlace.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  spacing: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  labelSpacing: PropTypes.number,
  width: PropTypes.number,
  // rightIcon: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  setLocationArray: PropTypes.func,

  setValue: PropTypes.func,
}
