import {
  AdvertiseIcon,
  Book,
  // AdvertisingIcon,
  // ChangeAccountIcon,
  CompanyIcon,
  // ConnectionsIcon,
  // DisableSearch,
  InventoryIcon,
  // OfferIcon,
  OrdersIcon,
  QuoteIcon,
  InventoryNewIcon,
  // ReportsIcon,
  SearchIcon,
  SettingsIcon,
  ReportIcon,
  UserConnectionIcon,
  SuperCompanyIcon,
  SupportIcon,
} from 'assets/icons'

export const TOP_NAV_BUYER = [
  createNav('Search', '/search', SearchIcon, 'search'),
  createNav('Quotes', '/quotes', QuoteIcon, 'quotes'),
  // createNav('Offers', '/offers', OfferIcon, 'offers'),
  createNav('Orders', '/orders', OrdersIcon, 'orders'),
  createNav('Suppliers', '/suppliers', CompanyIcon, 'suppliers'),
  createNav('Manuals (soon)', '', Book, 'soon'),
  // createNav('Communities', '', ConnectionsIcon, 'communities'),
  // createNav('Parts Manuals (Soon)', '', AdvertisingIcon, 'parts manuals'),
  // createNav('Settings', '/profile', SettingsIcon, 'profile'),
  // createNav('Upgrade Account', '', ChangeAccountIcon, 'upgradeaccount'),
]
export const TOP_NAV_SUPPLIER = [
  // createNav('Find Parts (soon)', '', DisableSearch, 'soon'),
  createNav('Requests', '/requestboard', InventoryIcon, 'requestboard'),
  createNav('Quotes', '/quotes', QuoteIcon, 'quotes'),
  // createNav('Offers', '/offers', OfferIcon, 'offers'),
  createNav('Orders', '/orders', OrdersIcon, 'orders'),
  createNav('Manuals (soon)', '', Book, 'soon'),
  // createNav('Communities', '', ConnectionsIcon, 'communities'),
  createNav('Inventory', '/inventory', InventoryNewIcon, 'inventory'),
  createNav('Advertising (soon)', '', AdvertiseIcon, 'soon'),

  // createNav('Reports', '', ReportsIcon, 'reports'),
  // createNav('Advertising (Soon)', '', AdvertisingIcon, 'soon'),
  // createNav('Company Profile', '', CompanyIcon, 'company profile'),
  // createNav('Settings', '/profile', SettingsIcon, 'profile'),
  // createNav('Payments', '', ChangeAccountIcon, 'payments'),
]

// export const TOP_NAV_SUPER_ADMIN = [createNav('Suppliers', '/sa/company', ConnectionsIcon, 'suppliers')]

export const TOP_NAV_SUPER_ADMIN = [
  createNav('Company', '/sa/company', SuperCompanyIcon, 'company'),
  createNav('Users', '/sa/users', UserConnectionIcon, 'users'),
  createNav('Report', '/sa/report', ReportIcon, 'report'),
  createNav('Orders', '/sa/orders', OrdersIcon, 'orders'),
  createNav('Support', '/sa/support', SupportIcon, 'support'),
]
export const BOTTOM_NAV = [createNav('Settings', '/settings/profile', SettingsIcon, 'settings')]

export const DRAWER_OPEN_WIDTH = 240
export const DRAWER_CLOSE_WIDTH = 70

function createNav(title, href, Icon, activeOccurrence) {
  return {
    title,
    href,
    Icon,
    activeOccurrence,
  }
}
