import Container from 'components/common/Container'
import './css/landingpage.css'
import './css/responsive.css'
import React, { useState } from 'react'
import buyinRightImg from 'assets/images/buyinRightImg.png'
import listingStar from 'assets/images/listingStar.svg'
import stillQuestion from 'assets/images/stillQuestion.svg'
import Button from 'components/common/Button'
import Seperator from 'components/common/Seperator'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Typography from 'components/common/Typography'
import HtmlTitle from 'utils/HtmlTitle'
import { CaretDownIcon } from 'assets/icons'
import { getColor } from 'theme/colors'
import LoginDialog from 'containers/authentication/LoginDialog'
import LandingPageFooter from './LandingPageFooter'
import LandingPageHeader from './LandingPageHeader'

const BuyingPage = () => {
  const [plan, setPlan] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const handlePlan = (e) => {
    setPlan(e)
  }

  return (
    <Container className="buying-main">
      <HtmlTitle title={'Buying'} />
      <Container className="container">
        <LandingPageHeader />
      </Container>
      <Container className="buying-body">
        <section className="section-hero p90">
          <div className="container">
            <div className="hero">
              <div className="hero-text-box">
                <h1 className="heading-primary">Search & Save like a pro</h1>
                <p className="hero-heading-description">
                  Get access to a world of verified parts suppliers to get quotes, buy parts and track orders in one
                  place and easier than ever before
                </p>
                <p className="hero-description">
                  Whether you have a part number or not, PartsClub connects you to vetted suppliers who can supply new,
                  rebuilt and used options. We work with OEM Dealers and Aftermarket suppliers to present you with as
                  many options as possible.
                </p>
              </div>
              <div className="hero-img-box">
                <img src={buyinRightImg} className="hero-img" alt={buyinRightImg} />
              </div>
            </div>
          </div>
        </section>

        <section className="section-prizing p90">
          <div className="container">
            <h2 className="heading-secondary">Level up your buying power</h2>
            <div className="heading-text">
              monthly
              <SwitchToggle defaultChecked={plan} onChange={handlePlan} /> Yearly (25% off)
            </div>
          </div>

          <div className="container">
            <div className="pricing">
              <div className="pricing-plan pricing-plan-free">
                <div className="plan-header">
                  <p className="plan-name">Free</p>
                  <p className="plan-price">
                    {plan ? (
                      <>
                        <span>$0.00</span>/month
                      </>
                    ) : (
                      <>
                        <span>$0.00</span>/month
                      </>
                    )}
                  </p>
                  <p className="list-item">5 quote requests per month</p>
                </div>
                <Seperator />
                <ul className="list">
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Buy parts on PartsClub
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Connect with suppliers
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Basic profile page
                  </li>
                </ul>
                <div className="plan-sign-up">
                  <Button className="start-free-btn" onClick={setOpenDialog}>
                    Start For Free
                  </Button>
                </div>
              </div>

              <div className="pricing-plan pricing-plan-super-saver">
                <div className="plan-header">
                  <p className="plan-name">Unlimited (For Parts Pros)</p>
                  <p className="plan-price">
                    {plan ? (
                      <>
                        <span>$9.99</span>/month
                      </>
                    ) : (
                      <>
                        <span>$12.99</span>/month
                      </>
                    )}
                  </p>
                  <p className="list-item">Unlimited quote requests</p>
                </div>
                <Seperator />
                <ul className="list">
                  <li className="list-item">
                    <span>Everything in the Saver, plus</span>
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    All free features plus:
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Get a 'Pro Buyer' badge
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Join exclusive buying groups
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Advanced profile page
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Get best pricing from suppliers
                  </li>
                </ul>
                <div className="plan-sign-up">
                  <Button className="commingsoon-button">Coming Soon</Button>
                </div>
              </div>

              <div className="pricing-plan pricing-plan-saver">
                <div className="plan-header">
                  <p className="plan-name">Extra</p>
                  <p className="plan-price">
                    {plan ? (
                      <>
                        <span>$8.99</span>/month
                      </>
                    ) : (
                      <>
                        <span>$11.99</span>/month
                      </>
                    )}
                  </p>
                  <p className="list-item">15 quote requests per month</p>
                </div>
                <Seperator />
                <ul className="list">
                  <li className="list-item">Everything in the Starter, plus</li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    All free features plus:
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    'Upgraded Buyer' badge
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Get better pricing from suppliers
                  </li>
                  <li className="list-item">
                    <img className="list-icon" src={listingStar} alt={listingStar} />
                    Receive offers by equipment
                  </li>
                </ul>
                <div className="plan-sign-up">
                  <Button className="commingsoon-button">Coming Soon</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Container className="frequently-ask-question-section">
          <Container className="container">
            <Container className="frequently-ask-question-inner p90">
              <Typography className="title " component="h1">
                Frequently Asked Questions (FAQ)
              </Typography>
              <Container className="frequently-ask-question-accordian">
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      Is PartsClub like eBay or Amazon
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub competes directly with both eBay and Amazon by providing unique features and
                      functionality to facilitate buyer-seller interactions and transactions specific to the equipment
                      parts industry.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      These features include seller fees that are up to up to 70% less than competitors,
                      account-specific pricing discounts between buyers and sellers, the ability to checkout without a
                      credit card, and more.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      How does PartsClub make money?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub has an advertising-supported free tier for both buyers and suppliers.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Sellers also pay the industry’s lowest transaction fees (3% + applicable credit card fees) and we
                      have upgraded account options for both buyers and sellers looking for greater functionality and
                      performance reporting.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      *PartsClub does not sell or share specific personal or activity data of individuals or businesses
                      using the platform.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      If I buy a part on PartsClub - what happens next?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub has an identical eCommerce process to eBay or Amazon - orders on PartsClub are sent to
                      the supplier for order and shipping confirmations. The supplier will upload a tracking number once
                      the product ships so you can track your order to your door.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      If you require any assistance with an order, PartsClub has dedicated Support Staff to assist you.
                      Our team can be reached during business hours at sellmoreparts@partsclub.us.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      What if there is something wrong with my order?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      We want all buyers and sellers to have a positive experience working together on PartsClub but
                      understand that issues do occur periodically. Let us help and make it right.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      If you require any assistance with an order, PartsClub has dedicated Support Staff to assist you.
                      Our team can be reached during business hours at sellmoreparts@partsclub.us.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      How do I return an order?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Returns can easily be started in PartsClub right from your order screen.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Just upload the tracking number when you send the order back to the supplier and the refund will
                      be sent back when the supplier receives the return.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      Who owns PartsClub?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub, Inc. is an independent corporation based in Austin, Texas and is not financially
                      affiliated with any organizations in the equipment or equipment parts industries.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Neutrality is one of our core values - PartsClub has intentionally avoided accepting funding or
                      financing from any current or future platform participant to maintain impartiality and the trust
                      of PartsClub’s buyers and sellers.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      Our founder Zac Jones created PartsClub to help agricultural, construction and industrial
                      equipment businesses grow parts sales by easily listing parts, quoting buyers and selling parts on
                      a safe and neutral platform.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<CaretDownIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="h3" component={'h3'} color={getColor('grey-g25')}>
                      Is PartsClub only in North America?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub is initially focused on serving North America - exclusively processing transactions
                      between buyers and sellers in the United States, Canada, and Mexico. PartsClub will be launching
                      in Europe in Spring 2024.
                    </Typography>
                    <Typography variant="body1" component={'p'} color={getColor('grey-g35')}>
                      PartsClub is also projected to launch in the Caribbean, Central America, and South America in Fall
                      2025 with further expansion to follow.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Container>
              <Container className="still-question">
                <Container className="still-question-inner">
                  <img src={stillQuestion} alt="still" />
                  <Container className="still-question-title">
                    <Typography component="h3">Still have questions?</Typography>
                    <Typography component="p">
                      Can’t find answer you’re looking for? Ask us, our team alway here to help you. Contact us at{' '}
                      <a href="mailto:sellmoreparts@partsclub.us"> sellmoreparts@partsclub.us</a>
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <LandingPageFooter />
      <LoginDialog open={openDialog} onClose={setOpenDialog} />
    </Container>
  )
}

export default BuyingPage
