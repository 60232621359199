import { addPart, deletePart, putQuoteDetails } from 'common-api/buyer-supplier/buyerSupplier'
import { createQuotePost } from 'common-api/create-quote/createQuote'
import useToastify from 'common-hooks/useToastify'
import { useParams } from 'react-router-dom'

export default function useCreateQuote() {
  const links = []
  const { toastMessage } = useToastify()
  const params = useParams()

  const createQuote = async (data, setloading) => {
    let companyAdd = {}
    let deliveryInstructions = ''
    if (data?.dialogDetails?.delivery_instructions) {
      deliveryInstructions = data?.dialogDetails.delivery_instructions
    }
    if (data?.address) {
      companyAdd = { company_delivery_addr: data?.address, company_billing_addr: data?.address }
    }
    if (data.company_delivery_addr && data?.profileAdd) {
      companyAdd = {
        company_delivery_addr: {
          ...data.company_delivery_addr,
          ...(data?.dialogDetails?.company_name && { company_name: data?.dialogDetails.company_name }),
          email: data?.dialogDetails.email,
          phone: data?.dialogDetails.phone,
          ...(data?.dialogDetails.full_name && { full_name: data?.dialogDetails.full_name }),
          ...(data?.dialogDetails.unit_number ? { unit_number: data?.dialogDetails.unit_number } : { unit_number: '' }),
          type: data?.dialogDetails?.type,
          delivery_instructions: deliveryInstructions,
        },
        company_billing_addr: data?.company_billing_addr,
      }
    } else if (data.company_delivery_addr && !data?.profileAdd) {
      companyAdd = {
        company_delivery_addr: {
          ...data.company_delivery_addr,
          company_name: data?.dialogDetails.company_name,
          email: data?.dialogDetails.email,
          phone: data?.dialogDetails.phone.toString(),
          ...(data?.dialogDetails.full_name && { full_name: data?.dialogDetails.full_name }),
          ...(data?.dialogDetails.unit_number ? { unit_number: data?.dialogDetails.unit_number } : { unit_number: '' }),
          delivery_instructions: deliveryInstructions,
          type: data?.dialogDetails?.type,
        },
        company_billing_addr: data?.company_billing_addr,
      }
    } else if (!data?.address) {
      const userAddress = { ...data?.profileAdd, delivery_instructions: deliveryInstructions, unit_number: '' }
      companyAdd = {
        company_delivery_addr: userAddress,
        company_billing_addr: data?.profileAdd,
      }
    } else if (!data.company_delivery_addr && data?.profileAdd) {
      companyAdd = {
        company_delivery_addr: data?.profileAdd,
        company_billing_addr: data?.profileAdd,
      }
    }
    try {
      await Promise.all(
        data?.visibleTextBoxData?.map(async (obj) => {
          const partNumberkey = `${obj.name}number`
          const partNotesKey = `${obj?.name}notes`
          const formData = new FormData()
          const parsingData = {
            ...(data.Equipments?.equipment.id === 81 ? '' : { equip_id: data.Equipments?.equipment.id }),
            ...(data.Brands.brand.id === 81 ? '' : { brand_id: data.Brands.brand.id }),
            // user createed part has temporary ID that's why we need to pass parentId as a partID
            ...(obj.parentId && { part_id: obj.parentId }),
            ...(obj?.[partNumberkey] && { part_number: obj?.[partNumberkey].replaceAll(' ', '') }),
            ...(obj?.value &&
              !obj?.[partNumberkey] && {
                part_number: obj?.value,
              }),
            ...(data.brand_model_number && { brand_model_number: data.brand_model_number }),
            ...(data.brand_serial_number && { brand_serial_number: data.brand_serial_number }),
            ...(data?.Equipments?.tabId && { industry_id: data?.Equipments?.tabId }),
            ...{ private: false },
            ...(data.Equipments.equipmentName && { equipment_name: data.Equipments.equipmentName }),
            ...(data.Brands.brandName && { brand_name: data.Brands.brandName }),
            ...(obj?.[partNotesKey] && { notes: obj?.[partNotesKey] }),
            ...(obj.quantity ? { quantity: obj.quantity } : { quantity: 1 }),
            ...(obj?.name && { user_part: obj?.name }),
            ...(companyAdd && { company_delivery_addr: companyAdd?.company_delivery_addr }),
            ...(companyAdd && { company_billing_addr: companyAdd?.company_billing_addr }),
          }
          const stringify = JSON.stringify(JSON.stringify(parsingData))
          formData.append('document', stringify)
          if (obj[`${obj.name}productImage`]?.length > 0) {
            obj[`${obj.name}productImage`].forEach((file) => {
              formData.append('assets', file.file)
            })
          }
          const response = await createQuotePost(formData)

          if (response) {
            setloading(false)
          }
          links.push({
            link: `/quotes/active/${response}`,
            brandName: data?.Brands?.brand?.name,
            partNumber: obj?.[partNumberkey] || obj?.[partNumberkey] === '' ? obj?.[partNumberkey] : obj?.value,
            partName: obj?.name,
          })
        })
      )
      return links
    } catch {
      return false
    }
  }

  const updateQuoteDetail = async (data, images, mutate) => {
    const formData = new FormData()
    const newString = JSON.stringify(JSON.stringify(data))

    if (images?.length > 0) {
      images.forEach((file) => {
        formData.append('assets', file.file)
      })
    }

    formData.append('document', newString)
    try {
      const response = await putQuoteDetails(params.id, formData)

      toastMessage('success', 'Quote details updated successfully')
      mutate()
      return response
    } catch {
      return false
    }
  }

  const createPartByCustomer = async (data) => {
    const partId = await addPart(data)
    toastMessage('success', 'Part added successfully')
    return partId
  }
  const deletePartByCustomer = async (id, mutate) => {
    const partId = await deletePart(id)
    toastMessage('success', 'Part deleted successfully')
    mutate()
    return partId
  }
  return { createQuote, updateQuoteDetail, createPartByCustomer, deletePartByCustomer }
}
