import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import { useLocation } from 'react-router-dom'
import Container from 'components/common/Container'
import { Typography } from '@mui/material'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
// import Logo from 'assets/icons/Logo40x40.svg'
import { DrawerCloseIcon, DrawerLogo, DrawerOpenIcon, HelpIconWhite } from 'assets/icons'
// import Typography from 'components/common/Typography'
import IconButton from 'components/common/IconButton'
import Seperator from 'components/common/Seperator'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import AppDrawerList from './AppDrawerList'
import {
  BOTTOM_NAV,
  DRAWER_CLOSE_WIDTH,
  DRAWER_OPEN_WIDTH,
  TOP_NAV_BUYER,
  TOP_NAV_SUPER_ADMIN,
  TOP_NAV_SUPPLIER,
} from './AppDrawerUtils'
import useToggle from 'common-hooks/useToggle'
import HelpDialogWithSubject from 'containers/HelpDialogWithSubject'
import Button from 'components/common/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: getColor('dark-d25'),
    borderRight: 0,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: ' column',
    justifyContent: 'start',
    height: '100%',
    overflow: 'hidden',

    // width: 50,
  },
  companyName: {
    color: getColor('light'),
    paddingLeft: '0.625rem',
    width: '91px',
    minWidth: '91px',
  },
  rootOpen: {
    width: DRAWER_OPEN_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rootClose: {
    width: DRAWER_CLOSE_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  closeIcon: {
    transform: 'translateX(-4%)',
  },
  openSide: {
    display: 'flex',
    // justifyContent: 'space-around',
    paddingLeft: '15px',
    alignItems: 'center',
    height: '60px',
    borderBottom: ' 1px solid #4A4D68',
    '& h3': {
      opacity: '1',
      visibility: 'visible',
      transition: 'opacity 3s linear',
      minWidth: '94px',
    },
  },
  closeSide: {
    borderBottom: ' 1px solid #4A4D68',
    textAlign: 'center',
    // paddingTop: ' 15px',
    '& h3': {
      opacity: '0',
      visibility: 'hidden',
    },
  },
  logo: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  setting: {
    // height: '61%',
    height: '50%',
    position: 'absolute',
    bottom: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    margin: '0 20px',
    marginBottom: ' 30px',
  },
  settingbg: {
    backgroundColor: getColor('grey-g35'),
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    borderRadius: '4px',
  },
  buttonpart: {
    padding: '0',
    marginTop: '8px',
  },
  avatarpb: {
    paddingBottom: '10px',
    alignItems: 'baseline',
  },
  typographypb: {
    // paddingBottom: '8px',
    color: getColor('light-main'),
  },
  iconbg: {
    backgroundColor: 'unset',
  },
  settingsmallbg: {
    backgroundColor: getColor('grey-g35'),
    borderRadius: '4px',
    padding: '8px',
    width: '46px',
  },
  settingsmall: {
    margin: '0 7px',
    marginBottom: ' 30px',
    // height: '61%',
    height: '50%',
    position: 'absolute',
    bottom: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  list: {
    paddingTop: '10px',
  },
  headarLogo: {
    height: '60px',
  },
  iconBtn: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  iconPosition: {
    position: 'fixed',
    left: '54px',
    top: '45px',
    zIndex: '999',
    transition: 'all 0.2s',
  },
  iconPosition2: {
    position: ' fixed',
    left: '224px',
    top: '45px',
    zIndex: '9',
    transition: 'all 0.3s',
  },
  settingTypo: {
    fontFamily: 'Lexend Deca',
    fontStyle: 'normal',
    fontWeight: ' 400',
    fontSize: ' 12px',
    lineHeight: ' 18px',
    color: '#9799B5',
  },
  linkFlex: {
    display: 'flex',
    gap: '0 10px',
    alignItems: 'center',
    margin: '0 10px',
    minWidth: 'auto',
    padding: '10px',
  },
  linkFlex1: {
    display: 'flex',
    minWidth: 'auto',
    gap: '0 10px',
    alignItems: 'center',
    margin: '0 10px',
    padding: '10px',
    justifyContent: 'center',
  },
  settings: {
    position: 'absolute',
    bottom: '0',
    '& ul': {
      paddingBottom: '0',
    },
    // borderTop: '1px solid red',
  },
  sprotor: {
    margin: '0 10px 0 10px',
    width: '221px',
  },
  helpLink: {
    background: 'none',
    color: '#9799B5',
    fontSize: '12px',
    fontWeight: '400px',
    '& span': {
      fontSize: '12px',
      fontWeight: '400px',
    },
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  helpDiv: {
    padding: '0 10px',
    margin: (props) => (props.open ? '0 10px 10px' : '0 14px 10px'),
    height: 44,
    borderRadius: '4px',
    width: (props) => (!props.open ? '45px' : '220px'),
    '&:hover': {
      backgroundColor: '#4A4D68',
      cursor: 'pointer',
    },
  },
}))
export default function AppDrawer({ open, toggle }) {
  const classes = useStyles({ open })
  const location = useLocation()
  const [helpDialog, setHelpDialog] = useToggle(false)
  // const { profileData } = useUserProfile()
  const userRole = getFromLocalStorage(constants.ROLE)

  // const lastName = capitalizeFirstLetter(profileData?.last_name)
  // const firstName = capitalizeFirstLetter(profileData?.first_name)
  // const fullName = capitalizeFirstLetter(`${firstName} ${lastName}`)
  // function capitalizeFirstLetter(string) {
  //   return string?.charAt(0).toUpperCase() + string?.slice(1)
  // }

  function renderDropdownNavItem({ title, href, Icon, activeOccurrence }, index) {
    let activeList = false
    let inactiveList = false
    if (location.pathname.includes(activeOccurrence)) {
      activeList = true
    }
    if (activeOccurrence?.includes('soon')) {
      inactiveList = true
    }
    return (
      <AppDrawerList
        className={classes.root}
        key={index}
        href={href}
        Icon={Icon}
        active={activeList}
        textVisible={open}
        inactiveList={inactiveList}
      >
        {title}
      </AppDrawerList>
    )
  }
  const list = () => (
    <Container className={classes.root}>
      <Container className={open ? classes.openSide : classes.closeSide}>
        <Container flex className={classes.headarLogo}>
          <IconButton className={classes.logo} variant="transparent" size="lg">
            <DrawerLogo />
          </IconButton>
          {open && (
            <Typography variant="h3" className={classes.companyName}>
              {'PartsClub'}
            </Typography>
          )}
        </Container>
        <Container className={!open ? classes.iconPosition : classes.iconPosition2}>
          <IconButton onClick={toggle} className={classes.iconBtn}>
            {!open ? <DrawerOpenIcon /> : <DrawerCloseIcon />}
          </IconButton>
        </Container>
      </Container>
      {userRole === 'customer' && (
        <>
          <List className={classes.list}>{TOP_NAV_BUYER.map(renderDropdownNavItem).slice(0, 8)}</List>
        </>
      )}
      {userRole === 'supplier' && (
        <>
          {/* {TOP_NAV_SUPPLIER} */}
          <List className={classes.list}>{TOP_NAV_SUPPLIER.map(renderDropdownNavItem).slice(0, 4)}</List>
          <Container flex>
            <Seperator borderColor={'#4A4D68'} width={'220px'} />
          </Container>

          <List className={classes.list}>{TOP_NAV_SUPPLIER.map(renderDropdownNavItem).slice(4, 12)}</List>
        </>
      )}
      {userRole === 'super_admin' && (
        <>
          <List
            className={classes.list}
            onClick={() => {
              sessionStorage.clear()
              window.location.reload()
            }}
          >
            {TOP_NAV_SUPER_ADMIN.map(renderDropdownNavItem).slice(0, 12)}
          </List>
        </>
      )}
      <Container className={classes.settings}>
        <Seperator width={open ? '220px' : '50px'} borderColor={'#4A4D68'} margin={'0 10px 0 10px'} />
        {userRole !== 'super_admin' && (
          <List className={classes.list}>{BOTTOM_NAV.map(renderDropdownNavItem).slice(0, 8)}</List>
        )}

        {/* <List> */}
        {/* <Container display="flex" alignItems="center" className={classes.helpDiv} onClick={() => setHelpDialog(true)}>
          <HelpIconWhite />
          {open && <Button className={classes.helpLink}>Get Help</Button>}
        </Container> */}
        {userRole !== 'super_admin' && (
          <Container display="flex" alignItems="center" className={classes.helpDiv} onClick={() => setHelpDialog(true)}>
            <HelpIconWhite />
            {open && <Button className={classes.helpLink}>Get Help</Button>}
          </Container>
        )}
        {/* </List> */}
      </Container>
    </Container>
  )

  return (
    <Container>
      <Drawer
        variant="permanent"
        anchor={'left'}
        open={open}
        onClose={toggle}
        classes={{
          paper: classNames(classes.root, {
            [classes.rootOpen]: open,
            [classes.rootClose]: !open,
          }),
        }}
      >
        {list()}
      </Drawer>
      <HelpDialogWithSubject open={helpDialog} onClose={setHelpDialog} section="General" />
    </Container>
  )
}
AppDrawer.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
}
