import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Radio from 'components/common/radio/Radio'
import visaCardLogo from 'assets/icons/visaCardLogo.svg'
import React from 'react'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import PropTypes from 'prop-types'
import Image from 'components/common/Image'

const useStyles = makeStyles({
  paymentcardRoot: {
    border: '1px solid ',
    borderColor: (props) => (props.active ? '#FF8C1A' : '#F0F0F4'),
    borderRadius: '4px',
    width: '100%',
    marginBottom: '20px',
    height: '64px',
    position: 'relative',
  },
  paymentcardinner: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 10px',
    padding: '0 20px',
    justifyContent: 'space-between',
    height: '100%',
  },
  cardNumber: {
    width: '65%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: ' 24px',
  },
  radioBtn: {
    paddingTop: '1px',
    position: 'absolute',
    left: '10px',
    top: '10px',
    width: ' 96%',
    height: '100%',
    display: 'unset',
    zIndex: '1',
  },
})

const PaymentMethodRadioCard = ({ id, lastDigits, value, exp, onChange }) => {
  const active = id === value
  const classes = useStyles({ active })
  return (
    <Container>
      <Container className={classes.paymentcardRoot}>
        <Container className={classes.paymentcardinner}>
          <Radio value={id} onChange={onChange} className={classes.radioBtn} />
          <Container className={classes.cardNumber}>
            <Image src={visaCardLogo} />
            <Typography variant="body1" color={getColor('grey-g25')}>
              {`**** **** **** ${lastDigits}`}
            </Typography>
          </Container>
          <Typography variant="paragraph1" color={getColor('grey-g35')}>
            {`Exp: ${exp}`}
          </Typography>
        </Container>
      </Container>
    </Container>
  )
}
export default PaymentMethodRadioCard
PaymentMethodRadioCard.propTypes = {
  id: PropTypes.string,
  lastDigits: PropTypes.string,
  value: PropTypes.string,
  exp: PropTypes.string,
  onChange: PropTypes.func,
}
