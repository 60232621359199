/* eslint-disable camelcase */
import { ButtonBase, Card, CardContent, CardMedia } from '@mui/material'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  root: (active) => ({
    width: '180px',
    height: '160px',
    borderRadius: '0px',
    transition: 'boxShadow 2s ease-out 100ms',
    border: `1px solid ${getColor('grey-g95')}`,
    '&:hover': {
      boxShadow: '0px 16px 14px rgba(0, 0, 0, 0.06),0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04)',
    },
    ...(active && {
      border: `1px solid ${getColor('orange-main')}`,
    }),
  }),
  buttonBase: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mediaCard: {
    width: '80px',
    height: '80px',
  },
  textColor: {
    color: '#13141B',
  },
}))
export default function LabeledImageCard(item) {
  const { id, name, cover_image, onChange, value, width, height, formStep } = item
  const active = name === value || id === value ? true : ''
  const classes = useStyles(active, width, height)
  return (
    <Card className={classes.root} elevation={0}>
      <ButtonBase
        className={classes.buttonBase}
        onClick={() => {
          onChange(name, id, cover_image)
        }}
        type="submit"
        form={`hook-form-${formStep}`}
      >
        <CardMedia
          className={classes.mediaCard}
          component="img"
          image={cover_image}
          onError={(e) => {
            e.target.src = 'https://via.placeholder.com/150'
          }}
        />
        <CardContent>
          <Typography variant="body1" component="div" className={classes.textColor}>
            {name}
          </Typography>
        </CardContent>
      </ButtonBase>
    </Card>
  )
}
