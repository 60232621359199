import { getColor } from './colors'

const typography = {
  fontFamily: ['Lexend Deca'],
  textTransform: 'none',
  color: getColor('dark-d25'),
  h1: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '30px',
  },
  h2: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '26px',
  },
  h3: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '24px',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '24px',
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '20px',
  },
  body2: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '18px',
  },
  paragraph1: {
    fontSize: '0.875rem',
    fontWeight: 300,
    lineHeight: '20px',
  },
  paragraph2: {
    fontSize: '0.75rem',
    fontWeight: 300,
    lineHeight: '16px',
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '20px',
    textTransform: 'none',
  },

  caption: {
    fontSize: '0.688rem',
    fontWeight: 400,
    lineHeight: '16px',
    color: getColor('grey-g35'),
  },
  overline: {
    fontSize: '0.625rem',
    fontWeight: 500,
    lineHeight: '16px',
    textTransform: 'none',
  },
}

export default typography
