export function parseShippingData(data) {
  console.log(data)

  //   const formData = new FormData()
  const phoneNumber = `+${data?.prefix}-${data?.phone}`.toString()
  const res = {}
  Object.keys(data).map((key) => {
    if (data[key] && !['prefix', 'phone_number'].includes(key)) {
      if (key === 'phone') {
        res.phone = phoneNumber
        return res
      }
      if (data[key]) {
        res[key] = data[key]
      }
    }
    return 0
  })
  if (data?.dock_available) {
    res.dock_available = true
  } else {
    res.dock_available = false
  }
  if (data?.forklift_available) {
    res.forklift_available = true
  } else {
    res.forklift_available = false
  }
  if (data?.delivery_instructions === '') {
    res.delivery_instructions = ''
  }
  if (data?.unit_number === '') {
    res.unit_number = ''
  }
  // if(data?.)
  return res
}

export const companyOptions = [
  { value: 'Broker', label: 'Broker' },
  { value: 'Dealer', label: 'Broker' },
  { value: 'Distributor', label: 'Distributor' },
  { value: 'Import/Export', label: 'Import/Export' },
  { value: 'Manufacturer', label: 'Manufacturer' },
  { value: 'Rental', label: 'Rental' },
  { value: 'Repair', label: 'Repair' },
  { value: 'Salvage', label: 'Salvage' },
]
