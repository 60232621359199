import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import classNames from 'classnames'
import usePopover from 'components/common/popover/usePopover'
import { cloneElement } from 'react'
import Popover from 'components/common/popover/Popover'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    backgroundColor: getColor('light'),
    borderRadius: 4,
    padding: '1rem',
    paddingBottom: '0',
    backdropFilter: 'blur(4px)',
    width: props.width || 'auto',
    border: '1px solid #F0F0F4',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  }),
  activeTrigger: {
    visibility: 'visible !important',
  },
}))

export default function ContextMenu({
  trigger,
  children,
  closeOnClick,
  parentWidth,
  keepMounted,
  width,
  className,
  anchorOriginVertical,
  anchorOriginHorizontal,
  transformOriginVertical,
  transformoriginhorizontal,
  ...props
}) {
  const classes = useStyles({ width })
  const [open, anchor, onOpen, onClose, anchorWidth] = usePopover()

  const handleClick = () => {
    if (closeOnClick) {
      onClose()
    }
  }

  return (
    <>
      {cloneElement(trigger, {
        onClick: (e) => {
          e.stopPropagation()
          onOpen(e)
        },
        className: classNames(trigger.props.className, {
          [classes.activeTrigger]: !!open,
        }),
      })}
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        paperClassName={classNames(classes.root, className)}
        onClick={handleClick}
        keepMounted={keepMounted}
        {...getProps(parentWidth, anchorWidth)}
        {...props}
        anchorOriginVertical={anchorOriginVertical}
        anchorOriginHorizontal={anchorOriginHorizontal}
        transformOriginVertical={transformOriginVertical}
        transformoriginhorizontal={transformoriginhorizontal}
      >
        {children}
      </Popover>
    </>
  )
}

ContextMenu.propTypes = {
  trigger: PropTypes.node,
  children: PropTypes.node,
  width: PropTypes.string,
  closeOnClick: PropTypes.bool,
  anchorOriginVertical: PropTypes.oneOf(['top', 'center', 'bottom']),
  anchorOriginHorizontal: PropTypes.oneOf(['left', 'center', 'right']),
  transformOriginVertical: PropTypes.oneOf(['top', 'center', 'bottom']),
  transformoriginhorizontal: PropTypes.oneOf(['left', 'center', 'right']),
  parentWidth: PropTypes.bool,
  keepMounted: PropTypes.bool,
  className: PropTypes.string,
}

function getProps(parentProps, anchorWidth) {
  return {
    ...(parentProps && {
      PaperProps: {
        style: {
          width: anchorWidth || 'auto',
          margin: '0.5rem 0',
        },
      },
      anchorOriginHorizontal: 'left',
      transformoriginhorizontal: 'left',
    }),
  }
}
