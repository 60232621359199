import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import React from 'react'
import SupplierQuoteSidebar from './sidebar/SupplierQuoteSidebar'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
}))
const SupplierQuoteDetails = () => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <SupplierQuoteSidebar />
    </Container>
  )
}

export default SupplierQuoteDetails
