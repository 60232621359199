import Container from 'components/common/Container'
import { React, useEffect, useState } from 'react'
import Button from 'components/common/Button'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import TextField from 'components/common/text/TextField'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm, useWatch } from 'react-hook-form'
import useUserPassword from 'common-hooks/user-profile/useUserPassword'
import useToastify from 'common-hooks/useToastify'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import List from 'components/custom/list/List'
import { useNavigate } from 'react-router-dom'
import useProfileSettingDetail from 'common-hooks/settings/profile/useProfileSettingDetail'

const useStyles = makeStyles({
  dialogbody: {
    // padding: '20px',
  },
  passwordContainer: {
    display: 'flex',
    // flexDirection: 'column',
    gap: '0 20px',
    maxWidth: '250px',
    // padding: '20px',
  },
  headerDiv: {
    padding: '30px',
    width: '830px',
    margin: '0 auto',
  },
  passwordTitle: {
    // paddingTop: '33px',
    paddingBottom: '20px',
  },
  profileroot: {
    width: '100%',
    display: 'flex',
    background: '#fff',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabs: {
    width: '260px',
    padding: '20px 10px 20px 10px',
    borderRight: `1px solid ${getColor('grey-g95')}`,
  },
  tabList: {
    padding: '10px 20px 10px 20px',
    color: '#36384c',
  },
  containers: {
    height: '94vh',
    backgroundColor: '#F8F9F9',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  active: {
    color: getColor('func-info'),
    padding: '10px 20px 10px 20px',
  },
  passwordInner: {
    background: '#fff',
    padding: '20px',
    marginTop: '30px',
  },
  passwordInnerFlex: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  noLink: {
    padding: '10px 20px',
  },
})

const SecuritySettings = () => {
  const { updatePassword } = useUserPassword()
  const { profileData } = useProfileSettingDetail()
  const classes = useStyles()
  const { toastMessage } = useToastify()
  const [updateDisabled, setUpdateDisabled] = useState(true)
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('old Password is required').min(6, 'Password must be at least 6 characters'),
    password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  })

  const {
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })
  const [currentPassword, password, confirmPassword] = useWatch({
    control,
    name: ['currentPassword', 'password', 'confirmPassword'],
  })

  const handlepassword = async () => {
    if (password === confirmPassword) {
      const data = {
        old_password: currentPassword,
        new_password: password,
      }
      const response = await updatePassword(data)
      if (response) {
        reset({ currentPassword: '', password: '', confirmPassword: '' })
      }
    } else {
      toastMessage('error', 'Both the new password and confirm password fields value must be matched')
    }

    return false
  }

  useEffect(() => {
    if (currentPassword && password && confirmPassword) {
      setUpdateDisabled(false)
    } else {
      setUpdateDisabled(true)
    }
  }, [currentPassword, password, confirmPassword])

  return (
    <>
      <Container className={classes.profileroot}>
        <Container className={classes.tabs}>
          <List
            title={'My Profile'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/profile')
            }}
          />
          {profileData?.user_company ? (
            <List
              title={'Company Profile'}
              className={classes.tabList}
              type={'link'}
              onClick={() => navigate('/settings/company')}
            />
          ) : (
            <Container className={classes.noLink}>
              <Typography color="#9799B5">Company Profile</Typography>
            </Container>
          )}

          <List
            title={'Payment'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/payment')
            }}
          />
          <List
            title={'Shipping'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/shipping')
            }}
          />
          <List
            title={'Membership'}
            className={classes.tabList}
            type={'link'}
            onClick={() => {
              navigate('/settings/membership')
            }}
          />
          <List
            title={'Password'}
            className={classes.active}
            type={'link'}
            onClick={() => {
              navigate('/settings/security')
            }}
          />
        </Container>
        <Container width="100%" className={classes.containers}>
          <Container flex justify="space-between" width="100%" className={classes.headerDiv}>
            <Container width="100%">
              <Typography variant="h1">Security</Typography>
              <Container className={classes.passwordInner}>
                <Container className={classes.passwordTitle}>
                  <Typography variant="title1" color={getColor('grey-g25')} component="p">
                    Password
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g25')}>
                    Update your password here
                  </Typography>
                </Container>
                <Container className={classes.passwordInnerFlex}>
                  <Container>
                    <TextField
                      id={'currentPassword'}
                      type="password"
                      control={control}
                      name={'currentPassword'}
                      placeholder={'Current Password'}
                      width="355px"
                      label={'Current Password'}
                      {...register('currentPassword', { required: true })}
                      error={errors.currentPassword ? 'This Field required' : ''}
                    />
                  </Container>
                  <Container className={classes.passwordContainer}>
                    <Container>
                      <TextField
                        id={'password'}
                        type="password"
                        width="355px"
                        name={'password'}
                        placeholder={'New Password'}
                        label={'New Password'}
                        control={control}
                        {...register('password', { required: true })}
                        error={errors.password ? <>{errors.password?.message}</> : ''}
                      />
                    </Container>
                    <Container>
                      <TextField
                        id={'confirmPassword'}
                        type="password"
                        control={control}
                        width="355px"
                        name={'confirmPassword'}
                        placeholder={'Confirm Password'}
                        label={'Confirm Password'}
                        {...register('confirmPassword', { required: true })}
                        error={errors.confirmPassword ? <>{errors.confirmPassword?.message}</> : ''}
                      />
                    </Container>
                  </Container>
                  <Button width={151} onClick={handlepassword} disabled={updateDisabled}>
                    Update Password
                  </Button>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </>
  )
}
SecuritySettings.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  setpassword: PropTypes.func,
}

export default SecuritySettings
