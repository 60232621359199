import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import Spacing from 'components/common/Spacing'
import { getColor } from 'theme/colors'
import { AlertErrorIcon, AlertInfoIcon, AlertSuccessIcon, AlertWarningIcon, CloseWhiteIcon } from 'assets/icons'
import Container from 'components/common/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    borderRadius: 4,
    minHeight: '40px',
    height: 'auto',
    padding: '10px 10px',
    color: getColor('light'),
    display: 'flex',
  },
  title: {
    padding: theme.spacing(0, 1.5),
  },
  actions: {
    width: 'auto',
    height: '30px',
  },
  closeButton: {
    '&:hover': {
      backgroundColor: getColor('dark'),
    },
    '& svg *': {
      fill: getColor('light'),
    },
  },
}))

export default function Toast({ variant, message, closeToast }) {
  const classes = useStyles()
  return (
    <Container flex justify="space-between" alignItems="center" className={classes.root} wrap="nowrap">
      <Spacing flex={1} container item alignItems="center" wrap="nowrap">
        {variant && getIcon(variant)}
        <Typography variant="body2" className={classes.title}>
          {message}
        </Typography>
      </Spacing>
      <Container container item alignItems="center" className={classes.actions}>
        <CloseWhiteIcon onClick={closeToast} />
      </Container>
    </Container>
  )
}

Toast.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  closeToast: PropTypes.func,
}

function getIcon(variant) {
  switch (variant) {
    case 'error':
      return <AlertErrorIcon />
    case 'info':
      return <AlertInfoIcon />
    case 'warning':
      return <AlertWarningIcon />
    case 'success':
      return <AlertSuccessIcon />
    default:
      return <AlertSuccessIcon />
  }
}
