import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import { Controller } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import Container from '../Container'
import TextField from './TextField'
import Select from '../select/Select'

function modifyStyles(styles) {
  return {
    ...styles,
    control: (provided, state) => ({
      ...styles.control(provided, state),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }),
  }
}

const useStyles = makeStyles((theme) => ({
  badge: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),
    backgroundColor: getColor('grey-g95'),
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    border: `0.5px solid ${getColor('grey-g85')}`,
    borderRight: 0,
  },
  code: {
    width: 62,
  },
  dropdown: {
    paddingRight: '2px',
  },
}))
export function createOption(value, label) {
  return {
    value,
    label,
  }
}
export function createOptionDialog(value, label, image) {
  return {
    value,
    label,
    image,
  }
}
const DropDownTextField = forwardRef(
  ({ onChange, CodeProps, codeName, label, control, options, disabled, textBoxDisabled, ...props }, ref) => {
    const classes = useStyles()
    const handleChange = (e) => {
      if (onChange) onChange(e)
    }

    const DISCOUNT_ON = options || ['%', '$']
    const DISCOUNT_ON_OPTIONS = DISCOUNT_ON.map((code) => createOption(code, code))
    return (
      <TextField
        id={'dropdowntextfiled'}
        label={label}
        disabled={textBoxDisabled}
        leftBadge={
          <Container container className={classes.code}>
            <Controller
              name="dropdownselect"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <Select
                  id={`${props.id}-code`}
                  disabled={disabled}
                  options={DISCOUNT_ON_OPTIONS}
                  modifyStyles={modifyStyles}
                  menuPosition="fixed"
                  name={name}
                  className={classes.dropdown}
                  onChange={onChange}
                  {...CodeProps}
                  value={value || DISCOUNT_ON_OPTIONS[0]}
                />
              )}
            />
          </Container>
        }
        ref={ref}
        onChange={handleChange}
        {...props}
      />
    )
  }
)

DropDownTextField.displayName = 'DropDownTextField'

DropDownTextField.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  CodeProps: PropTypes.object,
  size: PropTypes.string,
  codeName: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  textBoxDisabled: PropTypes.bool,
}

export default DropDownTextField
