// import { getQuoteDetailList } from 'common-api/buyer-supplier/buyerSupplier'
// import useSWR from 'swr'

// export default function useQuoteDetails(id) {
//   const { data, mutate } = useSWR(id ? `/quote/${id}` : null, getQuoteDetailList)

//   return {
//     quotesDetailDataList: data,
//     mutate,
//   }
// }
import { getQuoteDetails } from 'common-api/buyer-supplier/buyerSupplier'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

export default function useBuyerQuoteDetails() {
  const param = useParams()

  const { data, mutate } = useSWR(param?.id ? `/quote/${param?.id}` : null, getQuoteDetails)
  return {
    quoteDetails: data,
    mutate,
  }
}
