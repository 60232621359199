import { getSupplierDetails } from 'common-api/buyer-supplier/buyerSupplier'
import useSWR from 'swr'

export default function useReqSupplierDetails(id, reqId) {
  // const { data } = useSWR(`/quote/${id}`, getSupplierDetails)
  const { data, mutate } = useSWR(() => {
    if (reqId) {
      return `/quote/${id}?quote_request_id=${reqId}`
    }
    if (id) {
      return `/quote/${id}`
    }
    return null
  }, getSupplierDetails)
  return {
    supplierReqDetails: data,
    isLoading: !data,
    supplierdetailsMutate: mutate,
  }
}
