import { getInventoryProductList } from 'common-api/inventory/inventory'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import useToastify from 'common-hooks/useToastify'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { constants, inventoryProductLimit } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'

export default function useProductList(type, status, filterData) {
  const location = useLocation()
  const [search, onSearch] = useState(location?.state?.searchValue ? location?.state?.searchValue : '')
  const [page, setPage] = useState(1)
  const { toastMessage } = useToastify()
  const limit = location?.pathname === '/inventory' ? 35 : inventoryProductLimit

  const CompanyId = getFromLocalStorage(constants.COMPANY_ID)

  const params = {
    ...(filterData?.normalBrand?.value && { brand_id: filterData?.normalBrand?.value }),
    ...(filterData?.part_desp?.value && { part_desp: filterData?.part_desp?.value }),
    ...(filterData?.condition && { condition: filterData?.condition?.value }),
    ...(filterData?.enable_buy_now && { enable_buy_now: filterData?.enable_buy_now }),
    ...(filterData?.enable_make_offer && { enable_make_offer: filterData?.enable_make_offer }),
    ...(filterData?.fitsBrand?.value && { fits_with_brand: filterData?.fitsBrand?.value }),
    ...(filterData?.fits_with_model && { fits_with_model: filterData?.fits_with_model?.value }),
    ...(filterData?.has_warranty && { has_warranty: filterData?.has_warranty }),
    ...(filterData?.is_cancellable && { is_cancellable: filterData?.is_cancellable }),
    ...(filterData?.is_returnable && { is_returnable: filterData?.is_returnable }),
    ...(filterData?.ltl_freight_required && { ltl_freight_required: filterData?.ltl_freight_required }),
    ...(filterData?.pricerange?.length > 0 && { price_min: filterData?.pricerange[0] }),
    ...(filterData?.pricerange?.length > 0 && { price_max: filterData?.pricerange[1] }),
    ...(filterData?.quantityRange?.length > 0 && { qty_min: filterData?.quantityRange[0] }),
    ...(filterData?.quantityRange?.length > 0 && { qty_max: filterData?.quantityRange[1] }),
    ...(filterData?.status && { status: filterData?.status.value }),
    ...(search && { search }),
    ...(location?.state?.searchValue && { search: location?.state?.searchValue }),
    ...(status && { status }),
    ...(CompanyId && { company_id: CompanyId }),
    ...(type && { user_type: type }),
    limit,
    page,
  }

  const { data, mutate } = useSwrInfinite('/services/product', params, getInventoryProductList)
  if (data && data.length === 0 && page > 1) {
    toastMessage('error', 'There are no more products')
    setPage((pre) => pre - 1)
  }
  return {
    productData: data,
    mutate,
    isLoading: !data,
    onSearch,
    search,
    setPage,
    page,
  }
}
