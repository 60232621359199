import { useEffect, useReducer } from 'react'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { AuthContext, initialState, reducer } from 'utils/provider/AuthContext'
import CssBaseline from '@mui/material/CssBaseline'
import { hideGlobalConsoles } from 'utils/Utils'
import RouteIndex from './routes'
import createRootTheme from './theme'

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const theme = createRootTheme('#42a5f5')

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      hideGlobalConsoles()
    }
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <AuthContext.Provider
        value={{
          authState: state,
          dispatch,
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div id="app">
            <RouteIndex />
          </div>
          <ToastContainer
            position="bottom-left"
            className="toastify_container"
            toastClassName="toastify_root"
            bodyClassName="toastify_body"
            hideProgressBar
            closeButton={false}
            toastStyle={{ backgroundColor: 'transparent' }}
            closeOnClick={false}
            autoClose={2500}
          />
        </ThemeProvider>
      </AuthContext.Provider>
    </StyledEngineProvider>
  )
}

export default App
