export function signUpDataParsing(data) {
  const parsingData = {
    ...(data.data.firstName && { first_name: data.data.firstName }),
    ...(data.data.lastName && { last_name: data.data.lastName }),
    ...(data.companyData.isCompany === true ? { is_company: true } : { is_company: false }),
    ...(data.companyData.companyName?.id !== '' && {
      company_id: data.companyData.companyName.id,
    }),
    ...(data.data.emailId && { email: data.data.emailId }),
    ...(data.data.confirmPassword && { password: data.data.confirmPassword }),
    ...(data.companyData.jobTitle && { job_title: data.companyData.jobTitle }),
    ...(data.data.location && data.data.location),
    ...(data.industry && { industries: [data.industry] }),
    ...(data.type && { type: data.type }),
    ...(data?.companyData?.cmp_addr && { cmp_addr: data?.companyData?.cmp_addr }),
    ...(data.companyData.companyName?.id === '' && {
      company_name: data?.companyData?.companyName?.label,
    }),
  }
  return parsingData
}

function removeSpace(event) {
  return event?.replace(/ /g, '')
}
export function locationValue(locationArray) {
  const reversed = locationArray?.reverse()
  const obj = {}
  if (reversed && reversed.length > 0) {
    const locationArray = reversed.filter((obj) => {
      const type = obj.types
      return !type[0].includes('postal_code')
    })
    let addrLine1 = ''
    obj.country_abbrv = locationArray?.[0]?.short_name
    obj.state_abbrv = locationArray?.[1]?.short_name
    obj.state = locationArray?.[1]?.long_name
    obj.city_abbrv = removeSpace(locationArray?.[2]?.short_name)
    obj.city = locationArray?.[2]?.long_name
    obj.addr_line_2 = locationArray?.[3]?.short_name
    locationArray.map((item, index) => {
      if (index > 3) {
        addrLine1 += `${item.short_name} `
      }
      return 0
    })
    obj.addr_line_1 = addrLine1
  }
  return obj
}

export function locationProfileValue(locationArray) {
  const reversed = locationArray?.reverse()
  const obj = {}
  if (reversed && reversed.length > 0) {
    let addrLine1 = ''
    obj.country_abbrv = locationArray?.[0]?.short_name
    obj.country = locationArray?.[0]?.long_name
    obj.state_abbrv = locationArray?.[1]?.short_name
    obj.state = locationArray?.[1]?.long_name
    obj.city_abbrv = removeSpace(locationArray?.[2]?.short_name)
    obj.city = locationArray?.[2]?.long_name
    obj.addr_line_2 = locationArray?.[3]?.short_name
    obj.zipcode = locationArray?.[0]?.long_name
    locationArray.map((item, index) => {
      if (index > 3) {
        addrLine1 += `${item.short_name} `
      }
      return 0
    })
    obj.addr_line_1 = addrLine1
  }
  return obj
}
export function formatAddressOfGAPI(locationArray, addrLine1, addrLine2, latlong, id) {
  const obj = {
    // latitude: '',
    // longitude: '',
    addr_line_2: '',
    country_abbrv: '',
    country: '',
    state_abbrv: '',
    state: '',
    city_abbrv: '',
    city: '',
    zipcode: '',
  }

  if (locationArray && locationArray.length > 0) {
    if (id) obj.id = id

    if (latlong?.latitude) {
      obj.latitude = latlong?.latitude
    }
    if (latlong?.longitude) {
      obj.longitude = latlong?.longitude
    }

    obj.addr_line_2 = addrLine2 || ''
    obj.addr_line_1 = addrLine1

    locationArray?.map((e) => {
      if (e.types[0] === 'country') {
        obj.country = e?.long_name ? e?.long_name : ''
        obj.country_abbrv = e?.short_name ? e?.short_name : ''
      }
      if (e.types[0] === 'administrative_area_level_1') {
        obj.state_abbrv = e?.short_name ? e?.short_name : ''
        obj.state = e?.long_name ? e?.long_name : ''
      }
      if (e.types[0] === 'locality') {
        obj.city_abbrv = e?.short_name ? e?.short_name : ''
        obj.city = e?.long_name ? e?.long_name : ''
      }
      if (e.types[0] === 'postal_code') {
        obj.zipcode = e?.long_name ? e?.long_name : ''
      }
      return 0
    })
  }
  return obj
}
