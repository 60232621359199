// import { getQuoteDetailList } from 'common-api/buyer-supplier/buyerSupplier'
// import useSWR from 'swr'

// export default function useQuoteDetails(id) {
//   const { data, mutate } = useSWR(id ? `/quote/${id}` : null, getQuoteDetailList)

//   return {
//     quotesDetailDataList: data,
//     mutate,
//   }
// }
import { getQuoteDetailList } from 'common-api/buyer-supplier/buyerSupplier'
import useSWR from 'swr'

export default function useQuoteDetails(id, url) {
  const { data, mutate } = useSWR(() => {
    if (url) {
      if (id && url.includes('/quotes/active/')) {
        return `/quote/${id}`
      }
      return null
    }
    if (id) {
      return `/quote/${id}`
    }
    return null
  }, getQuoteDetailList)

  return {
    quotesDetailDataList: data,
    mutate,
  }
}
