import { Card, CardContent, CardMedia } from '@mui/material'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'
import PaymentCardLogo from 'assets/images/PaymentCardLogo.svg'
import { AlertSuccessIcon } from 'assets/icons'
import PropTypes from 'prop-types'
import usePaymentMethods from 'common-hooks/settings/payment/usePaymentMethods'
import Button from 'components/common/Button'

const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    width: '263px',
    borderRadius: '0px',
    // transition: 'boxShadow 2s ease-out 100ms',
    border: `1px solid ${getColor('grey-g95')}`,
    // '&:hover': {
    //   boxShadow: '0px 16px 14px rgba(0, 0, 0, 0.06),0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04)',
    //   cursor: 'pointer',
    // },
    position: 'relative',
  },
  info: {
    padding: '20px!important',
  },
  paragraph: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: getColor('grey-g65'),
  },
  cardLogo: {
    backgroundColor: getColor('light-main'),
    width: '44px',
    height: '44px',
    border: '1px solid #F0F0F4',
  },
  cardNumber: {
    alignItems: 'center',
  },
  editLink: {
    background: 'unset',
    height: 'unset',
    minHeight: 'unset',
    color: '#2680D9',
    textDecoration: 'underline',
    '&:hover': {
      background: 'unset',
    },
    padding: '0',
    '& span': {
      fontWeight: 300,
    },
  },
  deleteLink: {
    background: 'unset',
    color: '#AD1F36',
    height: 'unset',
    minHeight: 'unset',
    textDecoration: 'underline',
    '&:hover': {
      background: 'unset',
    },
    padding: '0',
    '& span': {
      fontWeight: 300,
    },
  },
}))
export default function PaymentMethodCard({ card }) {
  const classes = useStyles()
  // const isDefault = false
  const { deletePaymentCard, mutate, setDefalutCard } = usePaymentMethods()
  const handleDeleteCard = (id) => {
    deletePaymentCard(id, mutate)
  }
  const handleSetAsdefalut = (id) => {
    setDefalutCard({ card_id: id }, mutate)
  }
  return (
    <>
      <Card className={classes.root} elevation={0}>
        <CardContent className={classes.info}>
          <Container direction="column" gap="10px" justify="flex-start">
            <Container justify="flex-start" gap={'0px 5px'} className={classes.cardNumber}>
              <CardMedia
                id="cardLogo"
                className={classes.cardLogo}
                component="img"
                image={PaymentCardLogo}
                onError={(e) => {
                  e.target.src = 'https://via.placeholder.com/150'
                }}
              />
              <Typography variant="title" color={getColor('grey-g25')}>
                {`**** **** **** ${card?.last_digits}`}
              </Typography>
            </Container>
            <Container justify="flex-start" direction="column">
              {/* <Typography variant="paragraph1" color={getColor('grey-g35')}>
                Cameron Williamson
              </Typography> */}
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {`Exp: ${card?.exp_month}/${card?.exp_year}`}
              </Typography>
            </Container>
            <Container justify="space-between" width="100%">
              <Container>
                <Button className={classes.deleteLink} onClick={() => handleDeleteCard(card?.id)}>
                  Remove
                </Button>
              </Container>
              <Container flex gap="5px">
                {card?.is_default ? (
                  <>
                    <AlertSuccessIcon />
                    <Typography variant="paragraph1" color={'#0DA50D'}>
                      Default
                    </Typography>
                  </>
                ) : (
                  // <Link
                  //   to="#"
                  //   className={classes.editLink}
                  //   underline
                  //   onClick={() => handleSetAsdefalut(card?.id)}
                  // >
                  //   Set as default
                  //   </Link>
                  <Button className={classes.editLink} onClick={() => handleSetAsdefalut(card?.id)}>
                    Set as default
                  </Button>
                )}
              </Container>
            </Container>
          </Container>
        </CardContent>
      </Card>
    </>
  )
}
PaymentMethodCard.propTypes = {
  card: PropTypes.any,
}
