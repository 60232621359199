import {
  createShippoAccount,
  unlinkShippoAccount,
  createPolices,
  editCompanySettings,
  deleteProfileImage,
} from 'common-api/settings/settings'
import useToastify from 'common-hooks/useToastify'
import { addpoliciesParseData, updateCompanySettings } from 'containers/settings/company/CompanySettingsUtils'

export default function useCompanySettings() {
  const { toastMessage } = useToastify()
  const shippoAccount = async (data, setShipo) => {
    try {
      const response = await createShippoAccount(data)
      toastMessage('success', 'Shippo account linked successfully')
      setShipo(true)
      return response
    } catch {
      return false
    }
  }
  const unlinkShippo = async (mutate) => {
    try {
      const response = await unlinkShippoAccount()
      toastMessage('success', 'Shippo account unlinked successfully')
      mutate()
      return response
    } catch {
      return false
    }
  }
  const policiesAdd = async (data) => {
    const parseData = addpoliciesParseData(data)

    try {
      const response = await createPolices(parseData)
      toastMessage('success', 'Policies added successfully')

      return response
    } catch {
      return false
    }
  }

  const updateCompany = async (data, bannerImg, logoImg) => {
    const formData = new FormData()
    const parsedData = updateCompanySettings(data)

    if (bannerImg) {
      formData.append('img_header', bannerImg)
    }
    if (logoImg) {
      formData.append('logo', logoImg)
    }

    formData.append('document', parsedData)

    try {
      const response = await editCompanySettings(formData)
      toastMessage('success', 'Company Updated successfully')
      return response
    } catch {
      return false
    }
  }

  const companyProfileImagesDelete = async (data) => {
    try {
      const response = await deleteProfileImage(data)
      if (data.type === 'cover') {
        toastMessage('success', 'Cover image Delete Successfully')
      }
      if (data.type === 'logo') {
        toastMessage('success', 'Profile image Delete Successfully')
      }
      if (data.type === 'tax_exemption_certificate') {
        toastMessage('success', 'Tax exemption certificate deleted successfully')
      }
      if (data.type === 'wnine_tax_form') {
        toastMessage('success', 'W-9 tax form certificate deleted successfully')
      }
      return response
    } catch {
      return false
    }
  }

  return { shippoAccount, unlinkShippo, policiesAdd, updateCompany, companyProfileImagesDelete }
}
