import React from 'react'
import Container from 'components/common/Container'
import Placeholder from 'assets/images/Placeholder.png'
import indicatorarrow from 'assets/icons/indicatorarrow.svg'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import { PropTypes } from 'prop-types'
import Button from 'components/common/Button'
import { useNavigate } from 'react-router-dom'
import { Verfied } from 'assets/icons'
import { formatPrice } from 'utils/Utils'
// import Chip from 'components/common/Chip'

const useStyles = makeStyles(() => ({
  mainContainer: {
    marginBottom: '20px',
    width: '800px',
    height: '136px',
    background: getColor('light'),
    padding: '20px',
    gap: '10px',
    border: '1px solid #F0F0F4',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },

  subContainer: {
    justifyContent: 'space-between',
  },
  lastContainer: {
    marginTop: '25px',
    justifyContent: 'space-between',
  },
  img: {
    width: '90px',
    height: '90px',
    border: '1px solid #F0F0F4 ',
  },
  blueText: {
    color: getColor('func-info'),
  },
  greenText: {
    marginRight: '2px',
    color: getColor('func-positive'),
  },
  greenMsgDot: {
    paddingRight: '2px',
    color: getColor('func-positive'),
    fontSize: 'x-large',
  },
  otherText: {
    color: getColor('grey-g35'),
  },
  imgSupplierText: {
    color: getColor('grey-g25'),
  },
  blackDot: {
    padding: '0px 5px 3px 5px',
    color: getColor('grey-g35'),
  },
  msgBlackDot: {
    padding: '0px 5px',
  },
  btn: {
    marginTop: '5px',
    padding: '0px 0px',
    alignItems: 'center',
    minHeight: '0px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  quotCaption: {
    color: getColor('func-positive'),
    width: '27px',
    height: '20px',
    background: getColor('func-badge'),
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0px 0px 0px 2px',
  },
}))

const OfferCard = ({ quoteImg, imagesCount }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Container flex direction={'column'}>
      <Container
        flex
        className={classes.mainContainer}
        onClick={() => {
          navigate(`/offers/active`)
        }}
      >
        <Container>
          <img src={quoteImg || Placeholder} alt="LET'S SPARK UP LOGO" className={classes.img} />
        </Container>
        <Container width={'100%'}>
          <Container flex justifyContent={'space-between'} className={classes.subContainer}>
            <Typography variant="title">
              <span>brand - part_name - part_number</span>
            </Typography>
            <Container display="flex" direction="column">
              <Container flex gap="5px">
                <Typography variant="title" color={getColor('grey-g35')}>
                  {formatPrice(60)}
                </Typography>
                <Typography variant="title" color={getColor('grey-g65')} className={classes.strikeAmt}>
                  <s>{formatPrice(120)}</s>
                </Typography>
              </Container>
              <Container display="flex" justify="flex-end" gap="3px">
                {/* <Typography>•</Typography>
                <Chip label="New Offer" variant="secondary" />{' '} */}
              </Container>
            </Container>
          </Container>
          <Container flex className={classes.subContainer}>
            <Container flex gap="5px" alignItems="center">
              <Typography variant="paragraph1" color={getColor('func-positive')}>
                In Stock
              </Typography>
              <Typography>•</Typography>
              <Typography color={getColor('grey-g65')}>Qty.</Typography>
              <Typography>•</Typography>
              <Typography color={getColor('grey-g65')}>condition</Typography>
            </Container>
          </Container>

          <Container flex className={classes.lastContainer}>
            <Container flex>
              <Container display="flex" direction="column">
                <Container flex gap="2px">
                  <Typography variant="body1" color={getColor('grey-g65')}>
                    company/seller_name
                  </Typography>
                  <Verfied />
                </Container>

                <Typography variant="body1" color={getColor('grey-g65')}>
                  city, state
                </Typography>
              </Container>
            </Container>

            <Container flex>
              {/* <Typography variant="body1" className={classes.imgSupplierText}>
                {data?.message ? (
                  <Container flex>
                    <Container className={classes.greenMsgDot}>•</Container>
                    <span className={classes.otherText}>{data?.message}</span>
                    <span className={classes.msgBlackDot}>•</span>
                  </Container>
                ) : (
                  <>{''}</>
                )}
              </Typography> */}

              <Typography>
                {imagesCount > 0 ? (
                  <>
                    <span className={classes.otherText}>Images</span>{' '}
                    <span className={classes.otherText}>{imagesCount}</span>
                    <span className={classes.msgBlackDot}>•</span>
                  </>
                ) : (
                  <>{''}</>
                )}
              </Typography>
              <Container>
                <Button className={classes.btn} variant="transparent">
                  <img src={indicatorarrow} alt="Next" />
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
OfferCard.propTypes = {
  quoteImg: PropTypes.string,
  imagesCount: PropTypes.string,
}

export default OfferCard
