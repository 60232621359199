import { getSearchProductDetails } from 'common-api/search-product/searchProduct'
import useSWR from 'swr'

export default function useSearchProductDetails(id) {
  const { data, mutate } = useSWR(id ? `services/product/${id}` : null, getSearchProductDetails)

  return {
    searchDetails: data,
    isLoading: !data,
    mutate,
  }
}
