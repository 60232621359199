/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import Container from 'components/common/Container'
import { TabPanel, Tabs } from 'components/common/Tabs'
import SearchField from 'components/common/search/SearchField'
import Button from 'components/common/Button'
import { makeStyles } from '@mui/styles'
import {
  AddIcon,
  CaretLeftIcon,
  CaretRightIcon,
  DropdownBigIcon,
  EditIcon,
  RedDeleteIcon,
  SortArrowIcon,
} from 'assets/icons'
import { Table } from 'components/common/Table'
import orderEmptyState from 'assets/images/orderEmptyState.svg'
import orderCompletedEmpty from 'assets/images/orderCompletedEmpty.svg'
import { getColor } from 'theme/colors'
import IconButton from 'components/common/IconButton'
import correctionIcon from 'assets/images/correctionIcon.svg'
import Chip from 'components/common/Chip'
import ContextMenu from 'components/custom/drop-downs/ContextMenu'
import ContextMenuItem from 'components/custom/drop-downs/ContextMenuItem'
import Image from 'components/common/Image'
import useToggle from 'common-hooks/useToggle'
import NotesDialog from './NotesDialog'
import Typography from 'components/common/Typography'
import { tabsOrder } from './superAdminUtils'
import useSuperAdminOrderList from 'common-hooks/super-admin/useSuperAdminOrderList'
import { DATE_FORMAT_COMMA_SUPER, formatDate } from 'utils/dateUtils'
import { formatPrice } from 'utils/Utils'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    // padding: '30px',
  },
  marginHead: {
    position: 'sticky',
    top: '60px',
    // background: getColor('system-bg'),
    zIndex: '1',
  },
  mainContainer: {
    background: '#fff',
    padding: '30px',
    // '& svg': {
    //   '& path': {
    //     fill: '#4A4D68',
    //   },
    // },
  },
  SearchField: {
    '& input': {
      background: '#F8F9F9',
    },
  },
  list: {
    marginTop: '20px',
    maxHeight: '75vh',
    overflowY: 'scroll',

    whiteSpace: ' nowrap',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      paddingtop: '50px',
      backgroundColor: '#F0F0F4',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
    '& table': {
      overflowX: 'hidden',
      width: '1920px',
      // overflowX: 'scroll',
      '& tr': {
        cursor: 'pointer',
      },
    },
  },
  pagination: {
    borderTop: '1px solid #F0F0F4 ',
    padding: '30px',
    display: 'flex',
    background: '#fff',
    justifyContent: 'space-between',
  },
  paginationFooter: {
    position: 'sticky',
    bottom: '0px',
    height: 'unset',
    paddingTop: '30px',
    // marginLeft: '-20px',
    // marginRight: '-20px',
    padding: '0 0',
    zIndex: '1',
    borderTop: `1px solid ${getColor('grey-g95')}`,
    background: getColor('light-main'),
    '& svg path': {
      fill: 'unset',
    },
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  customTableContainer: {
    overflowX: 'initial',
  },
  placeholder: {
    // position: 'absolute',
    display: 'flex',
    height: '72vh',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px 0',
  },
  iconBtn: {
    background: 'unset',
    border: ' 1px solid #9799B5',
    borderRadius: ' 4px',
    width: ' 36px',
    height: '36px',
    '&:hover': {
      background: 'unset',
    },
  },
  btn: {
    width: '100%',

    justifyContent: ' space-between',
    padding: 'unset',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  addBtn: {
    background: 'unset',
    color: '#2680D9',
    padding: 'unset',
    height: 'unset',
    minHeight: 'unset',
    '& svg path': {
      fill: '#2680D9',
      stroke: '#2680D9',
    },
    '&:hover': {
      background: 'unset',
    },
  },
  noteRoot: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    '& svg path': {
      fill: 'unset',
    },
    '& .hoverIcon': {
      display: 'none',
      gap: '0 10px',
      position: 'absolute',
      background: '#F8F9F9',
      right: '5px',
      '& .btn': {
        background: 'unset',
        padding: 'unset',
        '&:hover': {
          background: 'unset',
        },
      },
    },
    '&:hover .hoverIcon': {
      display: 'flex',
    },
  },
  sortBtn: {
    padding: '0',
    width: 'unset',
    height: 'unset',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  rootBg: {
    background: '#fff',
  },
}))

export default function Orders() {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const [activeRows, setActiveRows] = useState([])
  const [notesDialog, setNotesDialog] = useToggle(false)
  const [orderBy, setOrderBy] = useState()

  const [orderStatus, setOrderStatus] = useState('awaiting_shipment')
  const { orderList, search, onSearch, isLoading, orderUpdate, page, setPage } = useSuperAdminOrderList(
    orderStatus,
    orderBy
  )

  const [editnoteData, setEditNoteData] = useState()
  const [noteId, setNoteId] = useState()

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    switch (newValue) {
      case 0:
        setOrderStatus('awaiting_shipment')
        break
      case 1:
        setOrderStatus('shipped')
        break
      case 2:
        setOrderStatus('cancelled')
        break
      default:
        setOrderStatus('awaiting_shipment')
    }
  }

  function createData(
    status,
    OrderID,
    Ordered,
    Product,
    Qty,
    Total,
    Shipping,
    Buyer,
    PONumber,
    Seller,
    soNumber,
    Notes
  ) {
    return { status, OrderID, Ordered, Product, Qty, Total, Shipping, Buyer, PONumber, Seller, soNumber, Notes }
  }
  function createShippedData(
    status,
    OrderID,
    Ordered,
    Product,
    Qty,
    Total,
    Shipping,
    Buyer,
    PONumber,
    Seller,
    soNumber,
    shipped,
    Notes
  ) {
    return {
      status,
      OrderID,
      Ordered,
      Product,
      Qty,
      Total,
      Shipping,
      Buyer,
      PONumber,
      Seller,
      soNumber,
      shipped,
      Notes,
    }
  }
  function createcancelledData(
    status,
    OrderID,
    Ordered,
    CancelledBy,
    Product,
    Qty,
    Total,
    Shipping,
    Buyer,
    PONumber,
    Cancelled,
    Seller,
    soNumber,
    Reason,
    Notes
  ) {
    return {
      status,
      OrderID,
      Ordered,
      CancelledBy,
      Product,
      Qty,
      Total,
      Shipping,
      Buyer,
      PONumber,
      Cancelled,
      Seller,
      soNumber,
      Reason,
      Notes,
    }
  }

  function statusProduct(status) {
    if (status === 'not set') return 'Not Set'
    if (status === 'In Progress' || status === 'Inprogress') return 'In Progress'
    if (status === 'Completed') return 'Completed'
    return 'Not Set'
  }

  function deliveryOption(option) {
    if (option?.freight_ground) {
      return 'Ground'
    }
    if (option?.freight_next_day) {
      return 'Next Day Delivery'
    }
    if (option?.freight_second_day) {
      return '2nd Day'
    }
    if (option?.mdo_ltl) {
      return 'LTL'
    }
    if (option?.mdo_local_delivery) {
      return 'Local Delivery'
    }
    if (option?.mdo_pick_up) {
      return 'Pick Up'
    }
    return 'Next Day Delivery'
  }

  useEffect(() => {
    if (orderList !== undefined && orderStatus === 'awaiting_shipment') {
      setActiveRows(
        orderList?.map((obj) =>
          createData(
            <ContextMenu
              width={'205px'}
              closeOnClick
              trigger={
                <IconButton className={classes.btn}>
                  <Chip
                    label={statusProduct(obj?.status)}
                    // eslint-disable-next-line no-nested-ternary
                    variant={
                      // eslint-disable-next-line no-nested-ternary
                      statusProduct(obj?.status) === 'In Progress'
                        ? 'secondary'
                        : statusProduct(obj?.status) === 'Completed'
                        ? 'success'
                        : ''
                    }
                  />
                  <DropdownBigIcon />
                </IconButton>
              }
            >
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'Not Set' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Not Set'} />
                  {statusProduct(obj?.status) === 'Not Set' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'In Progress' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'In Progress'} variant={'secondary'} />
                  {statusProduct(obj?.status) === 'In Progress' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'Completed' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Completed'} variant={'success'} />
                  {statusProduct(obj?.status) === 'Completed' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
            </ContextMenu>,

            obj?.order_id ? obj?.order_id : '-',
            obj?.ordered_date ? formatDate(obj?.ordered_date, DATE_FORMAT_COMMA_SUPER) : '-',
            `${obj?.brand_name}  ${obj?.part_name ? `- ${obj?.part_name}` : ''}  ${
              obj?.part_number ? `- ${obj?.part_number}` : ''
            }`,
            obj?.qty ? obj?.qty : '-',
            obj?.final_amount ? formatPrice(obj?.final_amount) : '-',
            deliveryOption(obj?.delivery_options),
            <div>
              {obj?.buyer_full_name ? obj?.buyer_full_name : '-'}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.buyer_email ? obj?.buyer_email : '-'}
              </Typography>
            </div>,
            obj?.po_number ? obj?.po_number : '-',
            <div>
              {obj?.seller_full_name ? obj?.seller_full_name : '-'}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.seller_email ? obj?.seller_email : '-'}
              </Typography>
            </div>,
            obj?.sales_order_number ? obj?.sales_order_number : '-',
            obj?.notes ? (
              <Container className={classes.noteRoot}>
                <div className={classes.resonRoot}>
                  {obj?.notes?.length > 40 ? <div>{obj?.notes?.slice(0, 40)}...</div> : obj?.notes}
                </div>
                <Container className={'hoverIcon'}>
                  <IconButton
                    className={'btn'}
                    onClick={() => {
                      setNotesDialog()
                      setEditNoteData(obj?.notes)
                      setNoteId(obj?.id)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton className={'btn'} onClick={() => orderUpdate({ id: obj?.id, notes: '' })}>
                    <RedDeleteIcon />
                  </IconButton>
                </Container>
              </Container>
            ) : (
              <Button
                onClick={() => {
                  setNotesDialog()
                  setEditNoteData('')
                  setNoteId(obj?.id)
                }}
                className={classes.addBtn}
                icon={<AddIcon />}
              >
                Add
              </Button>
            )
          )
        )
      )
    }
    if (orderList !== undefined && orderStatus === 'shipped') {
      setActiveRows(
        orderList?.map((obj) =>
          createShippedData(
            <ContextMenu
              width={'205px'}
              closeOnClick
              trigger={
                <IconButton className={classes.btn}>
                  <Chip
                    label={statusProduct(obj?.status)}
                    // eslint-disable-next-line no-nested-ternary
                    variant={
                      // eslint-disable-next-line no-nested-ternary
                      statusProduct(obj?.status) === 'In Progress'
                        ? 'secondary'
                        : statusProduct(obj?.status) === 'Completed'
                        ? 'success'
                        : ''
                    }
                  />
                  <DropdownBigIcon />
                </IconButton>
              }
            >
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'Not Set' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Not Set'} />
                  {statusProduct(obj?.status) === 'Not Set' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'In Progress' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'In Progress'} variant={'secondary'} />
                  {statusProduct(obj?.status) === 'In Progress' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'Completed' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Completed'} variant={'success'} />
                  {statusProduct(obj?.status) === 'Completed' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
            </ContextMenu>,

            obj?.order_id ? obj?.order_id : '-',
            obj?.ordered_date ? formatDate(obj?.ordered_date, DATE_FORMAT_COMMA_SUPER) : '-',
            `${obj?.brand_name}  ${obj?.part_name ? `- ${obj?.part_name}` : ''}  ${
              obj?.part_number ? `- ${obj?.part_number}` : ''
            }`,
            obj?.qty ? obj?.qty : '-',
            obj?.final_amount ? formatPrice(obj?.final_amount) : '-',
            obj?.delivery_options ? deliveryOption(obj?.delivery_options) : '-',

            <div>
              {obj?.buyer_full_name ? obj?.buyer_full_name : '-'}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.buyer_email ? obj?.buyer_email : '-'}
              </Typography>
            </div>,
            obj?.po_number ? obj?.po_number : '-',
            <div>
              {obj?.seller_full_name ? obj?.seller_full_name : '-'}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.seller_email ? obj?.seller_email : '-'}
              </Typography>
            </div>,
            obj?.sales_order_number ? obj?.sales_order_number : '-',
            obj?.ordered_shipped_date ? formatDate(obj?.ordered_shipped_date, DATE_FORMAT_COMMA_SUPER) : '-',
            obj?.notes ? (
              <Container className={classes.noteRoot}>
                <div className={classes.resonRoot}>
                  {obj?.notes?.length > 40 ? <div>{obj?.notes?.slice(0, 40)}...</div> : obj?.notes}
                </div>
                <Container className={'hoverIcon'}>
                  <IconButton
                    className={'btn'}
                    onClick={() => {
                      setNotesDialog()
                      setEditNoteData(obj?.notes)
                      setNoteId(obj?.id)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton className={'btn'} onClick={() => orderUpdate({ id: obj?.id, notes: '' })}>
                    <RedDeleteIcon />
                  </IconButton>
                </Container>
              </Container>
            ) : (
              <Button
                onClick={() => {
                  setNotesDialog()
                  setEditNoteData('')
                  setNoteId(obj?.id)
                }}
                className={classes.addBtn}
                icon={<AddIcon />}
              >
                Add
              </Button>
            )
          )
        )
      )
    }
    if (orderList !== undefined && orderStatus === 'cancelled') {
      setActiveRows(
        orderList?.map((obj) =>
          createcancelledData(
            <ContextMenu
              width={'205px'}
              closeOnClick
              trigger={
                <IconButton className={classes.btn}>
                  <Chip
                    label={statusProduct(obj?.status)}
                    // eslint-disable-next-line no-nested-ternary
                    variant={
                      // eslint-disable-next-line no-nested-ternary
                      statusProduct(obj?.status) === 'In Progress'
                        ? 'secondary'
                        : statusProduct(obj?.status) === 'Completed'
                        ? 'success'
                        : ''
                    }
                  />
                  <DropdownBigIcon />
                </IconButton>
              }
            >
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'Not Set' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Not Set'} />
                  {statusProduct(obj?.status) === 'Not Set' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'In Progress' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'In Progress'} variant={'secondary'} />
                  {statusProduct(obj?.status) === 'In Progress' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => orderUpdate({ id: obj?.id, status: 'Completed' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Completed'} variant={'success'} />
                  {statusProduct(obj?.status) === 'Completed' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
            </ContextMenu>,

            obj?.order_id ? obj?.order_id : '-',
            obj?.ordered_date ? formatDate(obj?.ordered_date, DATE_FORMAT_COMMA_SUPER) : '-',
            obj?.order_cancel_by ? obj?.order_cancel_by : '-',
            `${obj?.brand_name}  ${obj?.part_name ? `- ${obj?.part_name}` : ''}  ${
              obj?.part_number ? `- ${obj?.part_number}` : ''
            }`,
            obj?.qty ? obj?.qty : '-',
            obj?.final_amount ? formatPrice(obj?.final_amount) : '-',
            obj?.delivery_options ? deliveryOption(obj?.delivery_options) : '-',
            <div>
              {obj?.buyer_full_name ? obj?.buyer_full_name : '-'}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.buyer_email ? obj?.buyer_email : '-'}
              </Typography>
            </div>,
            obj?.po_number ? obj?.po_number : '-',
            obj?.ordered_cancelled_date ? formatDate(obj?.ordered_cancelled_date, DATE_FORMAT_COMMA_SUPER) : '-',
            <div>
              {obj?.seller_full_name ? obj?.seller_full_name : '-'}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.seller_email ? obj?.seller_email : '-'}
              </Typography>
            </div>,
            obj?.sales_order_number ? obj?.sales_order_number : '-',
            <div className={classes.resonRoot}>
              {obj?.order_cancle_reason?.length > 40 ? (
                <div>
                  {obj?.order_cancle_reason.slice(0, 40)}...
                  <Tooltip title={obj?.order_cancle_reason}>
                    <p> View reason</p>
                  </Tooltip>
                </div>
              ) : (
                obj?.order_cancle_reason
              )}
            </div>,
            obj?.notes ? (
              <Container className={classes.noteRoot}>
                <div className={classes.resonRoot}>
                  {obj?.notes?.length > 40 ? <div>{obj?.notes?.slice(0, 40)}...</div> : obj?.notes}
                </div>
                <Container className={'hoverIcon'}>
                  <IconButton
                    className={'btn'}
                    onClick={() => {
                      setNotesDialog()
                      setEditNoteData(obj?.notes)
                      setNoteId(obj?.id)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton className={'btn'} onClick={() => orderUpdate({ id: obj?.id, notes: '' })}>
                    <RedDeleteIcon />
                  </IconButton>
                </Container>
              </Container>
            ) : (
              <Button
                onClick={() => {
                  setNotesDialog()
                  setEditNoteData('')
                  setNoteId(obj?.id)
                }}
                className={classes.addBtn}
                icon={<AddIcon />}
              >
                Add
              </Button>
            )
          )
        )
      )
    }
  }, [orderList, orderStatus])

  const allProductcols = [
    <Container width={'100%'} flex justify="space-between">
      Status
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Order ID
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_id', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_id', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Ordered
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_date', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_date', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Product
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'brand_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'brand_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Qty.
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'qty', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'qty', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Total{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'final_amount', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'final_amount', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Shipping
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'delivery_options', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'delivery_options', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Buyer
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'buyer_full_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'buyer_full_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      P.O. Number
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'po_number', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'po_number', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Seller
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'seller_full_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'seller_full_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      S.O. Number{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'sales_order_number', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'sales_order_number', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Notes{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
  ]

  const allShippedcols = [
    <Container width={'100%'} flex justify="space-between">
      Status
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Order ID
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_id', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_id', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Ordered
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_date', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_date', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Product
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'brand_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'brand_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Qty.
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'qty', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'qty', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Total{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'final_amount', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'final_amount', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Shipping
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'delivery_options', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'delivery_options', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Buyer
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'buyer_full_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'buyer_full_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      P.O. Number
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'po_number', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'po_number', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Seller
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'seller_full_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'seller_full_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      S.O. Number{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'sales_order_number', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'sales_order_number', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      <Container width={'100%'} flex justify="space-between">
        Shipped
        <ContextMenu
          width={'205px'}
          closeOnClick
          trigger={
            <IconButton className={classes.sortBtn}>
              <SortArrowIcon />
            </IconButton>
          }
        >
          <ContextMenuItem onClick={() => setOrderBy(null)}>
            <Typography>Relevance</Typography>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_shipped_date', order: 'asc' })}>
            <Typography>A - Z</Typography>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_shipped_date', order: 'desc' })}>
            <Typography>Z - A</Typography>
          </ContextMenuItem>
        </ContextMenu>
      </Container>
    </Container>,

    <Container width={'100%'} flex justify="space-between">
      Notes{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
  ]
  const allCancelledcols = [
    <Container width={'100%'} flex justify="space-between">
      Status
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Order ID
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_id', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_id', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Ordered
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_date', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_date', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Cancelled By
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_cancel_by', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_cancel_by', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Product
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'brand_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'brand_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Qty.
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'qty', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'qty', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Total{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'final_amount', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'final_amount', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Shipping
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'delivery_options', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'delivery_options', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Buyer
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'buyer_full_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'buyer_full_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      P.O. Number
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'po_number', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'po_number', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Cancelled
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_cancelled_date', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'ordered_cancelled_date', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Seller
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'seller_full_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'seller_full_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      S.O. Number{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'sales_order_number', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'sales_order_number', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      <Container width={'100%'} flex justify="space-between">
        Reason
        <ContextMenu
          width={'205px'}
          closeOnClick
          trigger={
            <IconButton className={classes.sortBtn}>
              <SortArrowIcon />
            </IconButton>
          }
        >
          <ContextMenuItem onClick={() => setOrderBy(null)}>
            <Typography>Relevance</Typography>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_cancle_reason', order: 'asc' })}>
            <Typography>A - Z</Typography>
          </ContextMenuItem>
          <ContextMenuItem onClick={() => setOrderBy({ order_by: 'order_cancle_reason', order: 'desc' })}>
            <Typography>Z - A</Typography>
          </ContextMenuItem>
        </ContextMenu>
      </Container>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Notes{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
  ]

  return (
    <Container height={'93vh'} className={classes.rootBg}>
      <Container className={classes.mainContainer} width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            <Tabs value={tabValue} onchange={handleChange} array={tabsOrder} />
            <Container flex gap={'20px'}>
              <Container>
                <SearchField
                  id={'searchField'}
                  width={'334px'}
                  className={classes.SearchField}
                  onSearch={(e) => {
                    onSearch(e)
                  }}
                  value={search}
                />
              </Container>
            </Container>
          </Container>
        </Container>

        <Container className={classes.list}>
          <TabPanel value={tabValue} index={0}>
            <Container>
              {isLoading && <LoadingOverlay />}
              {orderList?.length > 0 ? (
                <Table stickyHeader cols={allProductcols} rows={activeRows} />
              ) : (
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={orderEmptyState} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    {search === '' ? (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No awaiting shipment
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          Looks like no one has placed an orders
                        </Typography>
                      </Container>
                    ) : (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No result found
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          Looks like there are no awaiting order with this value
                        </Typography>
                      </Container>
                    )}
                  </Container>
                </Container>
              )}
            </Container>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Container>
              {isLoading && <LoadingOverlay />}
              {orderList?.length > 0 ? (
                <Table stickyHeader cols={allShippedcols} rows={activeRows} />
              ) : (
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={orderCompletedEmpty} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    {search === '' ? (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No completed orders
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          When orders completed - it will appear here
                        </Typography>
                      </Container>
                    ) : (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No result found
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          Looks like there are no completed order with this value
                        </Typography>
                      </Container>
                    )}
                  </Container>
                </Container>
              )}
            </Container>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Container>
              {isLoading && <LoadingOverlay />}
              {orderList?.length > 0 ? (
                <Table stickyHeader cols={allCancelledcols} rows={activeRows} />
              ) : (
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={orderEmptyState} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    {search === '' ? (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No cancelled orders
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          Looks like no one has cancelled an orders
                        </Typography>
                      </Container>
                    ) : (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No result found
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          Looks like there are no cancelled order with this value
                        </Typography>
                      </Container>
                    )}
                  </Container>
                </Container>
              )}
            </Container>
          </TabPanel>
        </Container>
        <Container flex justify={'space-between'} className={classes.paginationFooter}>
          <Button
            icon={<CaretLeftIcon />}
            variant={'transparent'}
            className={classes.prevBtn}
            onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
            disabled={page === 1}
          >
            Prev.
          </Button>
          <Button
            icon={<CaretRightIcon />}
            variant={'transparent'}
            className={classes.nextBtn}
            onClick={() => setPage((pre) => pre + 1)}
            disabled={orderList?.length < 35}
          >
            Next
          </Button>
        </Container>
      </Container>
      <NotesDialog
        onClose={setNotesDialog}
        open={notesDialog}
        editnoteData={editnoteData}
        noteId={noteId}
        productUpdate={orderUpdate}
        setEditNoteData={setEditNoteData}
      />
    </Container>
  )
}
