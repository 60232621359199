import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import Chip from 'components/common/Chip'
import { getColor } from 'theme/colors'
import Button from 'components/common/Button'
import { useNavigate } from 'react-router-dom'
import { formatPrice, getFlag, getShortAddress } from 'utils/Utils'
import useSuppliersActiveList from 'common-hooks/buyer-supplier-list/useSuppliersActiveList'

const useStyles = makeStyles(() => ({
  cardRoot: {
    width: '800px',
    margin: '0 auto',
    border: '1px solid #F0F0F4',
    padding: '20px',
    marginBottom: '20px',
    display: 'flex',
    background: '#fff',
    gap: '0 10px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },
  img: {
    width: '70px',
    height: '50px',
    border: '1px solid #F0F0F4',
    objectFit: 'cover',
  },
  cardLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  counterOfferChip: {
    background: '#E0F0FF',
    width: '115px',
    borderRadius: '100px',
    '& span': {
      color: '#19528C',
    },
  },
  orderPlacedChip: {
    background: '#E0FFE0',
    width: '115px',
    borderRadius: '100px',
    '& span': {
      color: '#097309',
    },
  },
  waitingForResponseChip: {
    background: '#F0F0F4',
    borderRadius: '100px',
    '& span': {
      color: '#36384C',
    },
  },
  cardRight: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px 0',
  },
  cardRight1: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px 0',
  },
  viewQuote: {
    background: 'unset',
    color: '#2680D9',
    '&:hover': {
      background: 'unset',
    },
  },
}))

export default function ActiveQuoteOfferCard({
  partName,
  quoteId,
  brandModel,
  brandSerial,
  brandName,
  brandImg,
  equpImg,
  partNumber,
  equipName,
  city,
  state,
  country,
  amount,
  buyername,
  reqId,
  counterOfferPrice,
  companyName,
  isCompany,
}) {
  const classes = useStyles()
  const orderplace = false
  const navigate = useNavigate()
  const { nudgeMailSupplier } = useSuppliersActiveList()
  const handleClick = () => {
    nudgeMailSupplier({ quote_id: quoteId, quote_request_id: reqId })
  }
  return (
    <Container>
      <Container
        className={classes.cardRoot}
        onClick={(e) => {
          e.stopPropagation()
          navigate(`/quotes/active/${quoteId}/${reqId}`)
        }}
      >
        <Container className={classes.cardLeft}>
          <img src={brandImg} alt="BRAND LOGO" className={classes.img} />
          <img src={equpImg} alt="Machine parts" className={classes.img} />
        </Container>
        <Container className={classes.cardRight}>
          <Container className={classes.cardRight1}>
            <Container flex justify="space-between">
              <Typography component={'p'} variant="title" color={getColor('grey-g25')}>
                {brandName} - {partName} - {partNumber}
              </Typography>
              {counterOfferPrice && (
                <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                  New Offer Price: ${counterOfferPrice}
                </Typography>
              )}
              {!counterOfferPrice && (
                <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                  {formatPrice(amount)}
                </Typography>
              )}
            </Container>
            <Container flex justify="space-between">
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {equipName && `${equipName} `} {brandModel && `• ${brandModel} •`} {brandSerial}
              </Typography>
              <Container flex gap={'0 10px'}>
                {counterOfferPrice && (
                  <Typography variant="paragraph1" color="#AD1F36">
                    Original Price: ${amount}
                  </Typography>
                )}

                {counterOfferPrice && <Chip label="Counter Offer" className={classes.counterOfferChip} />}
                {orderplace && <Chip label="Order Placed" className={classes.orderPlacedChip} />}
                {!counterOfferPrice && <Chip label="Waiting For Response" className={classes.waitingForResponseChip} />}
              </Container>
            </Container>
          </Container>
          <Container flex justify="space-between">
            <Container>
              {isCompany ? (
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {buyername} at {companyName}
                </Typography>
              ) : (
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {buyername}
                </Typography>
              )}

              <Container flex gap="0 5px" justify="flex-start">
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  {getShortAddress(city, state, country)}
                </Typography>
                {getFlag(country)}
              </Container>
            </Container>
            {counterOfferPrice && (
              <Button
                variant="secondary"
                border
                onClick={(e) => {
                  navigate(`/quotes/active/${quoteId}/${reqId}`)
                  e.stopPropagation()
                }}
              >
                View Offer
              </Button>
            )}
            {orderplace && (
              <Button variant="secondary" border>
                Track Order
              </Button>
            )}

            {!counterOfferPrice && (
              <Container>
                <Button className={classes.viewQuote} onClick={() => navigate(`/quotes/active/${quoteId}/${reqId}`)}>
                  View Quote
                </Button>
                <Button
                  variant="secondary"
                  border
                  onClick={(e) => {
                    handleClick()
                    e.stopPropagation()
                  }}
                >
                  Nudge
                </Button>
              </Container>
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
ActiveQuoteOfferCard.propTypes = {
  partName: PropTypes.string,
  reqId: PropTypes.string,
  quoteId: PropTypes.string,
  brandName: PropTypes.string,
  partNumber: PropTypes.string,
  equipName: PropTypes.string,
  city: PropTypes.string,
  brandImg: PropTypes.string,
  equpImg: PropTypes.string,
  state: PropTypes.string,
  brandModel: PropTypes.string,
  brandSerial: PropTypes.string,
  buyername: PropTypes.string,
  amount: PropTypes.string,
  counterOfferPrice: PropTypes.string,
  country: PropTypes.string,
  companyName: PropTypes.string,
  isCompany: PropTypes.bool,
}
