import { getSupplierRequestedList } from 'common-api/buyer-supplier/buyerSupplier'
import { useState } from 'react'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'

export default function useSuppliersReqList(id) {
  const company = getFromLocalStorage(constants.COMPANY_ID)
  const limit = 14
  // const [pagimation, setPagination] = useState({ limit, page: 1 })
  const [fillter, setFillterData] = useState({})
  const [page, setPage] = useState(1)

  // const setPaginate = (isNext) => {
  //   if (isNext) {
  //     if (data && data.length > 0) setPagination({ limit, page: pagimation.page + 1 })
  //   } else if (pagimation.page > 1) setPagination({ limit, page: pagimation.page - 1 })
  // }
  const setPreferenceData = (data) => {
    setFillterData({
      ...(data?.industry && { industry: data?.industry }),
      ...(data?.equipment && { equipment: data?.equipment }),
      ...(data?.part && { part: data?.part }),
      ...(data?.brand && { brand: data?.brand }),
    })
  }

  const params = {
    limit,
    page,
    ...(![null, undefined, 'null', 'undefined'].includes(company) && { company_id: company }),
    ...(id && { industry: id }),
    ...(fillter?.brand !== '' && { brand: fillter?.brand }),
    ...(fillter?.industry !== '' && { industry: fillter?.industry }),
    // ...(fillter?.part !== '' && { part: fillter?.part }),
    ...(fillter?.equipment !== '' && { equipment: fillter?.equipment }),
  }

  const { data, mutate } = useSwrInfinite('/services/quote-invite/list', params, getSupplierRequestedList)
  if (data && data.length === 0 && page > 1) setPage((pre) => pre - 1)
  return {
    reqQuoteData: data,
    mutate,
    isLoadingReq: !data,
    setPage,
    page,
    setPreferenceData,
    fillter,
  }
}
