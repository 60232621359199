import React from 'react'
import { Switch as MuiSwitch } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'

const useStyles = makeStyles({
  root: (props) => ({
    width: props.width || 28,
    height: props.height || 16,
    padding: 0,
    display: 'flex',
    opacity: (props) => (props.disabled ? 0.75 : 1),
  }),
  switchBase: {
    padding: 2,
    color: getColor('light'),
    '&$checked': {
      transform: 'translateX(12px)',
      color: getColor('light'),
      '& + $track': {
        opacity: 1,
        backgroundColor: getColor('orange-main'),
      },
    },
    '&.Mui-disabled': {
      color: getColor('light'),
    },
  },
  thumb: (props) => ({
    width: props.thumbWidth || 12,
    height: props.thumbHeight || 12,
    zIndex: -1,
    boxShadow: ' 0px 3px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#ffffff',
  }),
  track: (props) => ({
    // border: `1px solid ${getColor('system-bg')}`,
    borderRadius: 29,
    opacity: 1,
    backgroundColor: props.trackBgColor || getColor('grey-g80'),
  }),
  checked: {},
})

export default function SwitchToggle({
  id,
  onChange,
  disabled,
  switchColor,
  width,
  height,
  thumbWidth,
  thumbHeight,
  trackBgColor,
  bgColor,
  defaultChecked,
}) {
  const classes = useStyles({ disabled, switchColor, width, height, thumbWidth, thumbHeight, trackBgColor, bgColor })
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.checked)
    }
  }
  return (
    <Container container direction="column">
      <MuiSwitch
        id={id}
        defaultChecked={defaultChecked}
        checked={defaultChecked}
        onChange={handleChange}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        disabled={disabled}
      />
    </Container>
  )
}
SwitchToggle.propTypes = {
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  thumbWidth: PropTypes.string,
  thumbHeight: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  trackBgColor: PropTypes.string,
  bgColor: PropTypes.string,
  switchColor: PropTypes.string,
}
