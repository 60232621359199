import { getBrandsData } from 'common-api/create-quote/createQuote'
import { useState } from 'react'
import useSWR from 'swr'

export default function useDistributorBrandList() {
  const [search, onSearch] = useState('')
  const { data } = useSWR(() => {
    if (search !== '') {
      return `/services/brands/part-manufacturer?search=${search}`
    }
    return `/services/brands/part-manufacturer`
  }, getBrandsData)
  return {
    brandList: data,
    onSearch,
    search,
    isLoading: !data,
  }
}
