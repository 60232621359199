import { productQuoteRequest, productReport } from 'common-api/search-product/searchProduct'
import useToastify from 'common-hooks/useToastify'
import { quoteParseData } from 'containers/product-search/SearchUtils'

export default function useRequestQuote() {
  const { toastMessage } = useToastify()
  const links = []
  const createProductQuote = async (data) => {
    const formData = new FormData()
    const parsedData = quoteParseData(data)
    if (data?.productImages?.length > 0) {
      data?.productImages.forEach((file) => {
        formData.append('assets', file?.file)
      })
    }

    formData.append('document', parsedData)

    try {
      const response = await productQuoteRequest(formData)
      links.push({
        link: `/quotes/active/${response}`,
        brandName: data?.brand_name?.label,
        partName: data?.part_desp,
        partNumber: data?.part_no,
      })
      toastMessage('success', 'Quote request created successfully')

      return links
    } catch {
      return false
    }
  }
  const reportProduct = async (data, id) => {
    try {
      await productReport({ product_id: id, ...data })
      toastMessage('success', 'Product Report Successfully')
    } catch {
      return false
    }
    return null
  }

  return { createProductQuote, reportProduct }
}
