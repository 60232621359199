import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import props from 'theme/props'
import { getColor } from 'theme/colors'

const useStyles = makeStyles({
  rangeText: {
    display: 'flex',
    width: props.width || '100%',
    justifyContent: 'space-between',
    padding: '10px 0px 10px 0px',
  },
  rangesliderroot: {
    color: 'rgb(255, 140, 26)',
    '& .MuiSlider-rail': {
      color: '#F0F0F4',
      height: '4px',
    },
    '& .MuiSlider-track': {
      height: '4px',
    },
    '& .MuiSlider-thumb': {
      width: '16px',
      height: '16px',
      border: '2px solid #FFFFFF',
      boxShadow: '0px 0px 5px rgba(16, 16, 16, 0.2)',
      '&:after': {
        width: '16px',
        height: '16px',
      },
    },
  },
})

export default function RangeSlider({ min, max, label, prefix, onChange, defaultValue }) {
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue || [min, max])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <Container>
      <Container className={classes.rangeText}>
        <Typography variant="title" color={getColor('grey-g25')}>
          {label}
        </Typography>
        <Typography variant="body1" color={getColor('grey-g35')}>
          {` ${!prefix ? '' : prefix} ${value[0]}`} - {`${!prefix ? '' : prefix} ${value[1]}`}
        </Typography>
      </Container>

      <Box sx={{ width: 300 }}>
        <Slider
          className={classes.rangesliderroot}
          min={min}
          max={max}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="off"
        />
      </Box>
    </Container>
  )
}
RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  defaultValue: PropTypes.array,
}
