import { forwardRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button as MuiButton, CircularProgress } from '@mui/material'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { lighten } from '@mui/material/styles'
import { getColor } from 'theme/colors'
import Link from './Link'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'relative',
    margin: props.margin || '0',
    width: props.width || 'auto',
    minWidth: 'auto',
    minHeight: getHeight(props.size),
    textTransform: 'none',
    lineHeight: 1.2,
    fontSize: '2rem',
    fontWeight: 700,
    borderWidth: '1px',
    backgroundColor: getBgColor(props.variant),
    color: getTextColor(props.variant),
    boxShadow: 'none',
    borderRadius: 4,
    ...getRootStyle(props, theme),

    '&:hover': {
      backgroundColor: getHoverBgColor(props.variant),
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    '&:disabled': {
      color: (props) => getTextColor(props.variant),
      opacity: 0.5,
    },
  }),
  loading: {
    '& $content': {
      visibility: 'hidden',
    },
  },
  progress: {
    position: 'absolute',
    color: (props) => getTextColor(props.variant),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const Button = forwardRef(
  (
    {
      children,
      size = 'md',
      variant = 'primary',
      className,
      labelClassName,
      margin,
      width,
      border,
      onlyIcon,
      loading,
      disabled,
      iconSameColor,
      textSize = 'md',
      spacing,
      to,
      icon,
      textColor,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      size,
      variant,
      margin,
      width,
      border,
      onlyIcon,
      iconSameColor,
      textSize,
      spacing,
    })

    const button = (
      <>
        <MuiButton
          classes={{
            root: classNames(classes.root, className, {
              [classes.loading]: loading,
            }),
            label: labelClassName,
          }}
          disabled={disabled || loading}
          ref={ref}
          {...props}
        >
          {loading && <CircularProgress size={20} className={classes.progress} />}
          <div className={classes.content}>
            {icon}
            <Typography variant="button" color={textColor}>
              {children}
            </Typography>
          </div>
        </MuiButton>
      </>
    )

    if (to) {
      return (
        <Link to={to} asWrapper>
          {button}
        </Link>
      )
    }

    return button
  }
)

Button.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'transparent', 'error', 'disabled', 'positive']),
  children: PropTypes.node,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  margin: PropTypes.any,
  width: PropTypes.any,
  border: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  iconSameColor: PropTypes.bool,
  textSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  spacing: PropTypes.number,
  to: PropTypes.string,
  icon: PropTypes.node,
  textColor: PropTypes.string,
}

Button.displayName = 'Button'

export default Button

function getRootStyle(props, theme) {
  return {
    padding: props.onlyIcon ? theme.spacing(0, 2) : theme.spacing(0, 3),
    fontSize: getFontSize(props.textSize),
    color: getTextColor(props.variant),
    marginBottom: theme.spacing(props.spacing),
    ...(props.border && {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: getBorderColor(props.variant),
    }),
    ...(props.iconSameColor && {
      '& svg *': {
        fill: 'currentColor',
      },
    }),
  }
}

function getHeight(size) {
  switch (size) {
    case 'sm':
      return 30
    case 'md':
      return 36
    case 'lg':
      return 42
    default:
      return 36
  }
}

function getFontSize(size) {
  switch (size) {
    case 'sm':
      return '0.625rem'
    case 'md':
      return '0.75rem'
    case 'lg':
      return '1rem'
    default:
      return '0.75rem'
  }
}

function getBgColor(variant) {
  switch (variant) {
    case 'primary':
      return getColor('orange-main')
    case 'secondary':
      return getColor('light')
    case 'error':
      return '#AD1F36'
    case 'transparent':
      return 'transparent'
    case 'positive':
      return getColor('func-positive')
    case 'disabled':
      return getColor('grey-g95')
    default:
      return getColor('orange-main')
  }
}

function getBorderColor(variant) {
  switch (variant) {
    case 'primary':
      return getColor('dark')
    case 'secondary':
      return getColor('grey-g65')
    case 'transparent':
      return 'transparent'
    default:
      return getColor('dark')
  }
}

function getTextColor(variant) {
  switch (variant) {
    case 'primary':
      return getColor('light')
    case 'secondary':
      return getColor('grey-g35')
    case 'transparent':
      return getColor('grey-g50')
    case 'disabled':
      return getColor('grey-g65')
    case 'positive':
      return getColor('light-main')
    default:
      return getColor('light')
  }
}

function getHoverBgColor(variant) {
  switch (variant) {
    case 'transparent':
      return lighten(getColor('grey-g15'), 0.25)
    case 'secondary':
      return getColor('grey-g95')
    case 'positive':
      return getColor('green-g500')
    default:
      return lighten(getBgColor(variant), 0.25)
  }
}
