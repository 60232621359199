import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import { createOption } from 'components/common/text/DropDownTextField'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import Select from 'components/common/select/Select'
import MonthYearPicker from 'components/common/MonthYearPicker'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import { timeOption } from 'containers/orders/OrdersUtils'
import useOrders from 'common-hooks/orders/useOrders'
import { useEffect } from 'react'
import useToastify from 'common-hooks/useToastify'
// import e from 'express'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px ',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  message: {
    border: 'unset',
    '& input': {
      border: 'unset',
      backgroundColor: 'unset',
    },
  },
  TextArea: {
    '& textarea': {
      border: 'unset',
      backgroundColor: 'unset',
      padding: '0',
    },
  },
  subject: {
    height: '46px',
    borderTop: '1px solid #F0F0F4',
    borderBottom: '1px solid #F0F0F4',
    '& input': {
      border: 'unset',
      backgroundColor: 'unset',
      padding: '0',
      fontWeight: ' 300',
    },
    '& input:placeholder': {
      color: '#9799B5',
    },
  },
})

export default function LocalDeliveryDialog({ open, onClose, editLocalDelivery, mutate, activeId }) {
  const classes = useStyles()
  const { toastMessage } = useToastify()
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      diliver_on: new Date(),
    },
  })
  //   const options = countryOption?.map((item) => createOption(item, item))

  const { orderLocalDeliveryShip, updateDeliveryTime } = useOrders()
  const onSubmit = (data) => {
    if (editLocalDelivery) {
      updateDeliveryTime(data)
      onClose()
      mutate()
    } else if (!data.start || !data.end) {
      toastMessage('error', 'Please select start or end time')
    } else {
      orderLocalDeliveryShip(data, activeId)
      onClose()
      if (!activeId) {
        mutate()
      }
    }
  }

  useEffect(() => {
    if (editLocalDelivery) {
      Object.keys(editLocalDelivery).map((key) => {
        if (['start', 'end'].includes(key)) {
          setValue(key, createOption(editLocalDelivery[key], editLocalDelivery[key]))
        } else {
          setValue(key, editLocalDelivery[key])
        }
        return 0
      })
    }
  }, [editLocalDelivery, setValue])

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Ship Order'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.selectField}>
              <Container>
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  Let buyer know when item will be deliver at their place. select time duration e.g. Delivery window is:
                  between 10:00 AM and 1:00 PM
                </Typography>
              </Container>

              <Controller
                name="diliver_on"
                control={control}
                render={({ field: { name, value } }) => (
                  <MonthYearPicker
                    views={['year', 'month', 'day']}
                    label="Delivery Date"
                    disablePast
                    minDate={new Date()}
                    value={value}
                    onChange={(newValue) => {
                      setValue(name, newValue)
                    }}
                  />
                )}
              />

              <Container flex gap={'0 20px'}>
                <Controller
                  name={'start'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      options={timeOption}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      menuPosition={'fixed'}
                    />
                  )}
                />

                <Controller
                  name={'end'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      options={timeOption}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      menuPosition={'fixed'}
                    />
                  )}
                />
              </Container>
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">{editLocalDelivery ? 'Update' : 'Ship Now'}</Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
LocalDeliveryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editLocalDelivery: PropTypes.string,
  mutate: PropTypes.func,
  activeId: PropTypes.string,
}
