import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import BuyerOrderCancelCard from './BuyerOrderCancelCard'
import { parseOrderData } from '../OrdersUtils'

const useStyles = makeStyles(() => ({
  awainglistBody: {
    maxWidth: '800px',
    // height: '80vh',
    // overflowY: 'scroll',
    margin: '0 auto',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  awaingcardList: {
    marginTop: '17px',
  },
  dateClass: {
    paddingBottom: '17px',
  },
}))

export default function BuyerOrderCancelledList({ orderData }) {
  const classes = useStyles()
  const rows = parseOrderData(orderData, 'ordered_cancelled_date')
  const keys = Object.keys(rows)
  return (
    <Container>
      <Container className={classes.awainglistBody}>
        <Container className={classes.awaingcardList}>
          {keys?.map((key, index) => (
            <Fragment key={index}>
              <Container className={classes.dateClass}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  {key.replace(',', '').toUpperCase()}
                </Typography>
              </Container>

              {rows[key].map((obj, index) => (
                <BuyerOrderCancelCard
                  key={index}
                  id={obj.id}
                  partName={obj.part_name}
                  brandName={obj.brand_name}
                  brandImg={obj.brand_cover_image}
                  equpImg={obj.equip_cover_image}
                  partNumber={obj.part_number}
                  orderId={obj.order_id}
                  poNumber={obj.po_number}
                  buyername={obj.seller_full_name}
                  city={obj.city}
                  state={obj.state}
                  amount={obj.amount}
                  delivery={obj.delivery_options}
                  quantity={obj.qty}
                  deliverOn={obj.deliver_on}
                  trackingNumber={obj.tracking_no}
                  shipvia={obj.shipped_via}
                  dilverTime={obj.delivered_time}
                  country={obj?.country}
                />
              ))}
            </Fragment>
          ))}
        </Container>
      </Container>
    </Container>
  )
}
BuyerOrderCancelledList.propTypes = {
  orderData: PropTypes.array,
}
