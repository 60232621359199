import { makeStyles } from '@mui/styles'
import { CaretLeftIcon, DownloadIcon, RedDeleteIcon, RestartIcon } from 'assets/icons'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import Dropzone from 'components/custom/dropzone/Dropzone'
import { Table } from 'components/common/Table'
import { useState, useEffect } from 'react'
import { cols, importRecordData } from './InventoryUtils'
import Chip from 'components/common/Chip'
import useToggle from 'common-hooks/useToggle'
import InstructionDialog from './InstructionDialog'
import { useNavigate } from 'react-router-dom'
import { CSV_MINE, filesAddKeys } from 'utils/Files'
import IconButton from 'components/common/IconButton'

const useStyles = makeStyles(() => ({
  rootMain: {
    height: '93vh',
  },
  instBtn: {
    background: 'unset',
    color: '#4A4D68',
    '&:hover': {
      background: 'unset',
    },
  },
  titleRoot: {
    background: '#fff',
  },
  titleFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px 30px',
  },
  pdfDesign: {
    height: '130px!important',
    border: '1px dashed #C3C5D5',
    borderRadius: '4px',
    background: '#FFFFFF',
  },
  uploadRoot: {
    padding: '30px',
  },
  csvTitle: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  contactText: {
    fontWeight: ' 300',
    fontSize: ' 12px',
    lineHeight: ' 18px',
    color: '#626583',
    display: 'block',
    marginTop: '20px',
    '& a': {
      textDecoration: 'underline',
      color: '#626583',
    },
  },
  iconBtn: {
    background: 'unset',
    padding: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
}))

export default function Import() {
  const [rowData, setRowData] = useState([])
  const [instructionDialog, setInstructionDialog] = useToggle(false)
  const [, setCsvFile] = useState()
  const navigate = useNavigate()

  const handleFile = (e) => {
    const filesArr = filesAddKeys(e)
    setCsvFile(filesArr)
  }

  function createData(Title, Status, UploadDate, UploadedBy, Action) {
    return { Title, Status, UploadDate, UploadedBy, Action }
  }

  function variantColor(status) {
    if (status === 'In Progress') {
      return 'secondary'
    }
    if (status === 'Failed') {
      return 'error'
    }
    if (status === 'Completed') {
      return 'success'
    }
    return ''
  }

  function ActionIcon(status) {
    if (status === 'Pending') {
      return (
        <IconButton className={classes.iconBtn}>
          <RedDeleteIcon />
        </IconButton>
      )
    }
    if (status === 'Failed') {
      return (
        <IconButton className={classes.iconBtn}>
          <RestartIcon />
        </IconButton>
      )
    }
    if (status === 'Completed') {
      return (
        <IconButton className={classes.iconBtn}>
          <DownloadIcon />
        </IconButton>
      )
    }
    return ''
  }

  useEffect(() => {
    if (importRecordData !== undefined) {
      setRowData(
        importRecordData?.map((obj) =>
          createData(
            obj?.title,
            <Chip label={obj?.status} variant={variantColor(obj?.status)} />,
            obj?.date,
            obj?.uploadDate,
            ActionIcon(obj?.status)
          )
        )
      )
    }
  }, [importRecordData])

  const classes = useStyles()
  return (
    <Container className={classes.rootMain}>
      <Container className={classes.titleRoot}>
        <Container className={classes.titleFlex}>
          <Container flex gap={'0 20px'}>
            <Button
              variant={'secondary'}
              width={'36px'}
              border
              icon={<CaretLeftIcon />}
              onClick={() => navigate('/inventory')}
            />
            <Typography variant="h1" color={getColor('grey-g25')}>
              Import
            </Typography>
          </Container>
          <Container flex gap={'0 20px'}>
            <Button className={classes.instBtn} onClick={setInstructionDialog}>
              Instructions
            </Button>
            <Button variant={'secondary'} border icon={<DownloadIcon />}>
              Template
            </Button>
          </Container>
        </Container>
      </Container>
      <Container className={classes.uploadRoot}>
        <Container className={classes.uploapcsv}>
          <Dropzone
            id={'drop-zone'}
            dropzoneClassName={classes.pdfDesign}
            multiple={false}
            accept={CSV_MINE}
            onChange={(e) => handleFile(e)}
            csvUpload
          />
        </Container>
        <Container className={classes.csvRecord}>
          <Container className={classes.csvTitle}>
            <Typography variant="title" color={getColor('grey-g25')}>
              Imported Files
            </Typography>
            <Typography variant="paragraph1" color={getColor('grey-g65')}>
              Last Import: 21 days ago
            </Typography>
          </Container>
          <Container className={classes.csvTable}>
            <Table cols={cols} rows={rowData} />
          </Container>
          <Typography className={classes.contactText}>
            Feel free to contact <a href="mailto:sellmoreparts@partsclub.us"> sellmoreparts@partsclub.us</a> for
            assistance with uploading inventory
          </Typography>
        </Container>
      </Container>
      <InstructionDialog open={instructionDialog} onClose={setInstructionDialog} />
    </Container>
  )
}
