import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Seperator from 'components/common/Seperator'
import { useContext, useEffect } from 'react'
import Typography from 'components/common/Typography'
import classNames from 'classnames'
import Button from 'components/common/Button'
import useToggle from 'common-hooks/useToggle'
import Select from 'components/common/select/Select'
import { Controller, useForm, useWatch } from 'react-hook-form'
import Checkbox from 'components/common/Checkbox'
import LabelTextField from 'components/common/text/LabelTextField'
import TitleList from 'components/custom/list/TitleList'
import List from 'components/custom/list/List'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import { CaretDownIcon, EditIcon, ShippingTruck } from 'assets/icons'
import { createOption } from 'components/common/text/DropDownTextField'
import useSupplierCreateQuote from 'common-hooks/supplier/create-quote/useSupplierCreateQuote'
import SuccessDialog from 'containers/buyer-quotes/SuccessDialog'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import useUserProfile from 'common-hooks/user-profile/useUserProfile'
import { exceptThisSymbols, getSupplierData, getTimes, SupplierQuoteContext } from '../SupplierQuoteDetailsUtils'
import ShippingAddressDialog from './ShippingAddressDialog'
import { getWellKnownAddress } from 'utils/Utils'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  title: {},
  root: { paddingBottom: '30px' },
  mainFlex: {
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  eqpimage: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',

    '& div': {
      width: '32.2%',
      height: '120px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',

      '& img': {
        width: '120px',
        height: '120px',
      },
    },
  },
  eqpImageBorder: {
    '& div': {
      border: 'unset',
      '& img': {
        border: '1px solid #F0F0F4',
      },
    },
    '& div:first-child': {
      borderRight: 'unset',
    },
  },
  partsimages: {
    width: '32.2%',
    cursor: 'pointer',
    height: '120px',
    display: 'flex',
    justifyContent: ' center',
    alignItems: 'center',
    '& img': {
      width: '120px',
      height: '120px',
      border: '1px solid #F0F0F4',
    },
  },
  orderCutOff: {
    // paddingTop: '10px',
  },
  timeSelect: {
    width: '120px',
  },
  p8: {
    padding: '8px 0',
  },
  p10: {
    padding: '10px 0',
  },
  p2: {
    padding: '2px 0',
  },
  partImageGrid: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  iconbtn: {
    padding: '0',
    '&:hover': {
      background: 'unset',
    },
  },
  shippingClass: {
    alignItems: 'baseline',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  moreDel: {
    alignItems: 'baseline',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  labelTextField: {
    '& input': {
      textAlign: 'right',
    },
  },
  moreTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  termList: {
    padding: '0',
  },
  exapanBtn: (props) => ({
    '& svg': {
      rotate: props.delivery ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  exapanTermsBtn: (props) => ({
    height: 'unset',
    minHeight: 'unset',
    '& svg': {
      rotate: props.conditions ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  delDiv: {
    marginTop: '20px',
  },
  addresDetails: {
    color: '#36384C',
  },
  termsRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '15px',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  termsRootInner: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0 50px',
    // marginBottom: '15px',
    justifyContent: 'space-between',
  },
  companySetting: {
    fontWeight: ' 300',
    fontSize: ' 14px',
    lineHeight: ' 20px',
    cursor: 'pointer',
    borderBottom: '1px solid #4A4D68',
    display: 'inline-block',
  },
  conditionsRoot: {
    fontSize: ' 14px',
    fontWeight: ' 300',
    lineHeight: ' 20px',
    color: '#36384c',
    display: 'flex',
    flexFlow: 'wrap',
    gap: '0 2px',
  },
}))
const SupplierShippingForm = ({ setBttonDisable }) => {
  const [successDialog, toggleSuccessDialog] = useToggle(false)
  const [toggleDialog, setToggleDialog] = useToggle(false)
  const [delivery, toggleDelivery] = useToggle(false)
  const [conditions, toggleConditions] = useToggle(false)
  const { profileData } = useUserProfile()
  const params = useParams()
  const { supplierReqDetails } = useReqSupplierDetails(params?.id, params?.reqId)
  const parsedSupplierData = getSupplierData(supplierReqDetails)
  const { createSupplierQuote } = useSupplierCreateQuote()
  const { onSaveSupplierQuoteInformaton, supplierQueoteInformation } = useContext(SupplierQuoteContext)
  const navigate = useNavigate()

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      buyerCanBuy: true,
      negotiateOffer: true,
      returnProduct: true,
      cancel_product: true,
      Warranty: true,
      Ground: '',
      secondDayState: '',
      nextDayState: '',
    },
  })
  const cmpAddress = supplierQueoteInformation?.addChange
    ? supplierQueoteInformation.company_billing
    : profileData?.user_address

  const [
    negotiateOffer,
    buyerCanBuy,
    returnProduct,
    Warranty,
    groundRate,
    ground,
    secondDay,
    secondDayState,
    nextDayState,
    nextDay,
    orderTime,
    ltlState,
    ltl,
    localDeliveryState,
    localdelivery,
    pickUpState,
    pickup,
    cancelProduct,
  ] = useWatch({
    control,
    name: [
      'negotiateOffer',
      'buyerCanBuy',
      'returnProduct',
      'Warranty',
      'groundRate',
      'ground',
      'secondDay',
      'secondDayState',
      'nextDayState',
      'nextDay',
      'orderTime',
      'ltlState',
      'ltl',
      'localDeliveryState',
      'localdelivery',
      'pickUpState',
      'pickup',
      'cancel_product',
    ],
  })
  const onSaveContextValue = () => {
    const Information = {
      negotiateOffer,
      buyerCanBuy,
      returnProduct,
      cancelProduct,
      Warranty,
      freight: [
        groundRate === undefined ? null : { frieghtstate: ground, freightvalue: groundRate },
        secondDay === undefined ? null : { frieghtstate: secondDayState, freightvalue: secondDay },
        nextDay === undefined ? null : { frieghtstate: nextDayState, freightvalue: nextDay },
        ltl === undefined ? null : { frieghtstate: ltlState, freightvalue: ltl },
        localdelivery === undefined ? null : { frieghtstate: localDeliveryState, freightvalue: localdelivery },
        pickup === undefined ? null : { frieghtstate: pickUpState, freightvalue: pickup },
      ],

      orderTime,
    }
    onSaveSupplierQuoteInformaton({
      ...supplierQueoteInformation,
      Information,
      company_billing: supplierQueoteInformation?.company_billing || profileData?.user_address,
    })
  }
  useEffect(() => {
    if (parsedSupplierData) {
      Object.keys(parsedSupplierData).map((key) => setValue(key, parsedSupplierData[key]))
    }
    if (parsedSupplierData?.orderBy) {
      setValue('orderTime', createOption(parsedSupplierData?.orderBy, parsedSupplierData?.orderBy))
    }
  }, [setValue])

  useEffect(() => {
    onSaveContextValue()

    if (
      (ground?.value !== true || groundRate === undefined || groundRate === '') &&
      (secondDayState?.value !== true || secondDay === undefined || secondDay === '') &&
      (nextDayState?.value !== true || nextDay === undefined || nextDay === '') &&
      (ltlState?.value !== true || ltl === undefined || ltl === '') &&
      (localDeliveryState?.value !== true || localdelivery === undefined || localdelivery === '') &&
      (pickUpState?.value !== true || pickup === undefined || pickup === '')
    ) {
      setBttonDisable(true)
    } else {
      setBttonDisable(false)
    }
  }, [
    negotiateOffer,
    buyerCanBuy,
    returnProduct,
    Warranty,
    ground,
    groundRate,
    secondDayState,
    secondDay,
    nextDayState,
    nextDay,
    orderTime,
    ltlState,
    ltl,
    localDeliveryState,
    localdelivery,
    pickUpState,
    pickup,
    cancelProduct,
    supplierQueoteInformation?.company_billing || parsedSupplierData?.company_billing,
  ])
  const classes = useStyles({ delivery, conditions })

  const options = getTimes()

  const onSubmit = () => {
    createSupplierQuote(supplierQueoteInformation)
    toggleSuccessDialog(true)
  }

  if (ground?.value === true && groundRate === undefined) {
    setValue('groundRate', '0.00')
  }
  if (secondDayState?.value === true && secondDay === undefined) {
    setValue('secondDay', '0.00')
  }
  if (nextDayState?.value === true && nextDay === undefined) {
    setValue('nextDay', '0.00')
  }
  if (ltlState?.value === true && ltl === undefined) {
    setValue('ltl', '0.00')
  }
  if (localDeliveryState?.value === true && localdelivery === undefined) {
    setValue('localdelivery', '0.00')
  }
  if (pickUpState?.value === true && pickup === undefined) {
    setValue('pickup', '0.00')
  }

  return (
    <Container className={classNames(classes.mainFlex, classes.root)} direction={'column'} gap={'20px 0'}>
      <form id="supplierPartsForm" name="supplier-parts-form" onSubmit={handleSubmit(onSubmit)}>
        <Container flex direction="column" justify="flex-start" gap={'10px 0'} className={classes.shippingClass}>
          <Container flex justify="space-between" width="100%">
            <Typography variant="title" color={getColor('grey-g25')}>
              Shipping From
            </Typography>
            <Button
              variant="secondary"
              icon={<EditIcon />}
              className={classes.iconbtn}
              onClick={() => setToggleDialog(true)}
            />
          </Container>

          <Container flex gap="10px">
            <Container>
              <ShippingTruck />
            </Container>

            {/* <Typography variant="body1">{}</Typography> */}
            <Typography className={classes.addresDetails}>
              {getWellKnownAddress(cmpAddress?.addr_line_1, cmpAddress?.addr_line_2, cmpAddress?.city)}
            </Typography>
          </Container>
          <Container flex justify="space-between" width="100%" className={classes.orderCutOff}>
            <Container>
              <Typography variant="body1" color={getColor('grey-g25')}>
                {' '}
                Order By (Cut-Off Time)
              </Typography>
            </Container>
            <Container>
              <Controller
                name={'orderTime'}
                control={control}
                render={({ field: { name, value, ref, onChange } }) => (
                  <Select
                    id={'order-time'}
                    className={classes.container}
                    options={options}
                    placeholder="Select"
                    value={value}
                    ref={ref}
                    name={name}
                    closeMenuOnScroll={false}
                    searchable={false}
                    onChange={onChange}
                    width={120}
                  />
                )}
              />
            </Container>
          </Container>
        </Container>
        <Seperator />
        <Container flex direction="column" justify="flex-start" className={classes.shippingClass}>
          <TitleList
            title={'Freight*'}
            subtitle={'Integrate courier accounts to automate freight rates'}
            tooltip={'Rates are automatically fetched from courier accounts via Shippo.'}
            tooltipMaxWidth={'500px'}
            height={'64px'}
          />
          <Container flex justify="space-between" width="100%" height="40px">
            <Container>
              <Controller
                name={'ground'}
                control={control}
                value={'2'}
                render={({ field: { name } }) => (
                  <Checkbox
                    label={'Ground'}
                    name={name}
                    defaultChecked={parsedSupplierData?.ground}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setValue(name, { value: e.target.checked, label: 'Ground' })
                      } else {
                        setValue(name, { value: false, label: '' })
                      }
                    }}
                  />
                )}
              />
            </Container>
            <Container>
              <Controller
                name={'groundRate'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <LabelTextField
                    id={'ground-rate'}
                    name={name}
                    type={'number'}
                    onChange={onChange}
                    disabled={ground?.value === undefined ? true : !ground?.value}
                    labelText={'$'}
                    width={'120px'}
                    className={classes.labelTextField}
                    placeholder={'00.00'}
                    value={value === undefined ? '' : value}
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  />
                )}
              />
            </Container>
          </Container>
          <Container flex justify="space-between" width="100%" height="40px">
            <Container>
              <Controller
                name={'secondDayState'}
                control={control}
                render={({ field: { name } }) => (
                  <Checkbox
                    label={'2nd Day'}
                    defaultChecked={parsedSupplierData?.secondDayState}
                    name={name}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setValue(name, { value: e.target.checked, label: '2nd Day' })
                      } else {
                        setValue(name, { value: false, label: '' })
                      }
                    }}
                  />
                )}
              />
            </Container>
            <Container>
              <Controller
                name={'secondDay'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <LabelTextField
                    id={'second-day'}
                    name={name}
                    type={'number'}
                    disabled={secondDayState?.value === undefined ? true : !secondDayState?.value}
                    onChange={onChange}
                    labelText={'$'}
                    width={'120px'}
                    className={classes.labelTextField}
                    placeholder={'00.00'}
                    value={value}
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  />
                )}
              />
            </Container>
          </Container>

          <Container flex justify="space-between" width="100%" height="40px">
            <Container>
              <Controller
                name={'nextDayState'}
                control={control}
                render={({ field: { name } }) => (
                  <Checkbox
                    label={'Next Day'}
                    defaultChecked={parsedSupplierData?.nextDayState}
                    name={name}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setValue(name, { value: e.target.checked, label: 'Next Day' })
                      } else {
                        setValue(name, { value: false, label: '' })
                      }
                    }}
                  />
                )}
              />
            </Container>
            <Container>
              <Controller
                name={'nextDay'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <LabelTextField
                    id={'next-day'}
                    name={name}
                    type={'number'}
                    onChange={onChange}
                    disabled={nextDayState?.value === undefined ? true : !nextDayState?.value}
                    labelText={'$'}
                    width={'120px'}
                    className={classes.labelTextField}
                    placeholder={'00.00'}
                    value={value}
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  />
                )}
              />
            </Container>
          </Container>
        </Container>
        <Seperator />
        <Container width="100%" className={classes.moreDel}>
          <Container flex justify="space-between">
            <TitleList title={'More Delivery Options'} className={classes.moreTitle} />
            <Button
              variant="secondary"
              className={classes.exapanBtn}
              onClick={() => toggleDelivery(true)}
              icon={<CaretDownIcon />}
            />
          </Container>
          {delivery && (
            <Container flex direction={'column'}>
              <Container flex justify="space-between" width="100%" height="40px">
                <Container>
                  <Controller
                    name={'ltlState'}
                    control={control}
                    render={({ field: { name } }) => (
                      <Checkbox
                        label={'LTL'}
                        defaultChecked={parsedSupplierData?.ltlState}
                        name={name}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue(name, { value: e.target.checked, label: 'LTL' })
                          } else {
                            setValue(name, { value: false, label: '' })
                          }
                        }}
                      />
                    )}
                  />
                </Container>
                <Container>
                  <Controller
                    name={'ltl'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <LabelTextField
                        id={'ltl'}
                        name={name}
                        type={'number'}
                        disabled={ltlState?.value === undefined ? true : !ltlState?.value}
                        onChange={onChange}
                        labelText={'$'}
                        width={'120px'}
                        className={classes.labelTextField}
                        placeholder={'00.00'}
                        value={value}
                        onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      />
                    )}
                  />
                </Container>
              </Container>
              <Container flex justify="space-between" width="100%" height="40px">
                <Container>
                  <Controller
                    name={'localDeliveryState'}
                    control={control}
                    render={({ field: { name } }) => (
                      <Checkbox
                        label={'Local Delivery'}
                        name={name}
                        defaultChecked={parsedSupplierData?.localDeliveryState}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue(name, { value: e.target.checked, label: 'LocalDelivery' })
                          } else {
                            setValue(name, { value: false, label: '' })
                          }
                        }}
                      />
                    )}
                  />
                </Container>
                <Container>
                  <Controller
                    name={'localdelivery'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <LabelTextField
                        id={'local-delivery'}
                        name={name}
                        type={'number'}
                        onChange={onChange}
                        disabled={localDeliveryState?.value === undefined ? true : !localDeliveryState?.value}
                        labelText={'$'}
                        width={'120px'}
                        className={classes.labelTextField}
                        placeholder={'00.00'}
                        value={value}
                        onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      />
                    )}
                  />
                </Container>
              </Container>
              <Container flex justify="space-between" width="100%" height="40px">
                <Container>
                  <Controller
                    name={'pickUpState'}
                    control={control}
                    render={({ field: { name } }) => (
                      <Checkbox
                        label={'Pick Up'}
                        name={name}
                        defaultChecked={parsedSupplierData?.pickUpState}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setValue(name, { value: e.target.checked, label: 'Pick Up' })
                          } else {
                            setValue(name, { value: false, label: '' })
                          }
                        }}
                      />
                    )}
                  />
                </Container>
                <Container>
                  <Controller
                    name={'pickup'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <LabelTextField
                        id={'pick-up'}
                        name={name}
                        type={'number'}
                        disabled={pickUpState?.value === undefined ? true : !pickUpState?.value}
                        onChange={onChange}
                        labelText={'$'}
                        width={'120px'}
                        className={classes.labelTextField}
                        placeholder={'00.00'}
                        value={value}
                        onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      />
                    )}
                  />
                </Container>
              </Container>
            </Container>
          )}
        </Container>
        <Seperator />
        <Container width="100%" className={classes.delDiv}>
          <Container width="100%" className={classes.termsRoot}>
            <Container width="100%" className={classes.termsRootInner}>
              <Typography variant="title" color={getColor('grey-g25')} component="p">
                Terms & Conditions
              </Typography>
              <Button
                variant="secondary"
                icon={<CaretDownIcon />}
                className={classes.exapanTermsBtn}
                onClick={() => toggleConditions(true)}
              />
            </Container>
            <Container className={classes.conditionsRoot}>
              Default conditions set in
              <Container onClick={() => navigate('/settings/company')} border className={classes.companySetting}>
                Company Settings
              </Container>
            </Container>
          </Container>
          {conditions && (
            <Container flex direction={'column'} gap="15px 0">
              <Container flex justify="space-between" width="100%">
                <List title={'Buyer can negotiate this offer'} className={classes.termList} />
                <SwitchToggle
                  name={'negotiateOffer'}
                  onChange={(e) => {
                    setValue('negotiateOffer', e)
                  }}
                  id={'negotiateOffer'}
                  defaultChecked={negotiateOffer}
                />
              </Container>
              <Container flex justify="space-between" width="100%">
                <Container>
                  <Typography variant="body1" color={getColor('grey-g25')} component="p">
                    Buyer can return this product
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                    30-day
                  </Typography>
                </Container>
                <SwitchToggle
                  name={'returnProduct'}
                  onChange={(e) => {
                    setValue('returnProduct', e)
                  }}
                  id={'returnProduct'}
                  defaultChecked={returnProduct}
                />
              </Container>
              <Container flex justify="space-between" width="100%">
                <Container>
                  <Typography variant="body1" color={getColor('grey-g25')} component="p">
                    Warranty
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                    365 days
                  </Typography>
                </Container>
                <SwitchToggle
                  name={'Warranty'}
                  onChange={(e) => {
                    setValue('Warranty', e)
                  }}
                  id={'Warranty'}
                  // value={Warranty}
                  defaultChecked={Warranty}
                />
              </Container>
              <Container flex justify="space-between" width="100%">
                <Container>
                  <Typography variant="body1" color={getColor('grey-g25')} component="p">
                    Buyer can cancel this product
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                    Prior to shipment
                  </Typography>
                </Container>
                <SwitchToggle
                  name={'cancel_product '}
                  onChange={(e) => {
                    setValue('cancel_product', e)
                  }}
                  id={'cancel_product'}
                  defaultChecked={cancelProduct}
                />
              </Container>
            </Container>
          )}
        </Container>
      </form>
      <ShippingAddressDialog open={toggleDialog} onclose={setToggleDialog} />
      <SuccessDialog open={successDialog} onClose={toggleSuccessDialog} />
    </Container>
  )
}
SupplierShippingForm.propTypes = {
  setBttonDisable: PropTypes.func,
}
export default SupplierShippingForm
