export const colors = {
  purple: {
    main: '#EDE9FF',
    light: '#EAE5FF',
    dark: '#2D12B2',
  },
  orange: {
    main: '#FF8C1A',
    hover: '#FFBE85',
    press: '#F4892A',
    o25: '#FFF6E5',
    o900: '#805707',
  },
  blue: {
    main: '#4D97FF',
    link: '#196EE5',
    b25: '#E0F0FF',
    b900: '#19528C',
    b500: '#4093E5',
  },
  red: {
    r25: '#FFEBEE',
    r900: '#7A1626',
    r500: '#C7364E',
  },
  green: {
    g25: '#E0FFE0',
    g900: '#097309',
    g500: '#39CC39',
  },
  light: {
    main: '#FFFFFF',
  },
  dark: {
    main: '#000000',
    d25: '#36384C',
  },
  system: {
    bg: '#F8F9F9',
  },
  grey: {
    g15: '#F2F3F5',
    g25: '#36384C',
    g35: '#4A4D68',
    g50: '#707580',
    g65: '#9799B5',
    g80: '#C3C5D5',
    g85: '#CED2D9',
    g90: '#E4E6EB',
    g95: '#F0F0F4',
    g45: '#626583',
  },
  func: {
    positive: '#0DA50D',
    negative: '#DE2B2B',
    badge: '#D2F7D2',
    info: '#2680D9',
    alert: '#AD1F36',
    hint: '#F2A60D',
  },
}

export const BADGE_COLORS = [
  '#93BDF5',
  '#97DEFC',
  '#82D8D4',
  '#F995DE',
  '#F392AA',
  '#FBB397',
  '#FAD896',
  '#77C679',
  '#B697FB',
]

export const TAG_COLORS = [
  { primary: getColor('grey-g35'), secondary: getColor('grey-g15') },
  { primary: getColor('orange-main'), secondary: getColor('orange-hover') },
  { primary: getColor('red-r900'), secondary: getColor('red-r500') },
  { primary: getColor('blue-b900'), secondary: getColor('blue-b500') },
  { primary: getColor('green-g900'), secondary: getColor('green-g500') },
  { primary: getColor('purple-dark'), secondary: getColor('purple-light') },
]

export function getBadgeColor(random = true) {
  if (random) {
    return BADGE_COLORS[Math.floor(Math.random() * BADGE_COLORS.length)]
  }
  return BADGE_COLORS[0]
}

export function getTagColor(random = true, color) {
  if (random) {
    return TAG_COLORS[Math.floor(Math.random() * TAG_COLORS.length)]
  }
  switch (color) {
    case 'grey':
      return TAG_COLORS[0]
    case 'green':
      return TAG_COLORS[4]
    case 'purple':
      return TAG_COLORS[5]
    case 'red':
      return TAG_COLORS[2]
    case 'orange':
      return TAG_COLORS[1]
    case 'blue':
      return TAG_COLORS[3]
    default:
      return TAG_COLORS[0]
  }
}

export function getColor(colorName = 'light') {
  let color = colors.light.main

  const path = colorName.split('-').filter(Boolean)
  const pathLength = path.length
  const mainPath = path[0]
  const colorNode = colors[mainPath]

  if (!colorNode) {
    console.warn(`Color node "${mainPath}" is missing in the palette`)
    return color
  }

  if (pathLength === 1) {
    if (!colorNode.main) {
      console.warn(`Color node "${mainPath}" has no "main" color`)
      return color
    }
    color = colorNode.main
  }

  if (pathLength > 1) {
    const colorVariantPath = path[1]
    const colorVariant = colorNode[colorVariantPath]
    if (!colorVariant) {
      console.warn(`Color node "${mainPath}" has no "${colorVariantPath}" color`)
      return color
    }
    color = colorVariant
  }
  return color
}
