import { getOrdersList } from 'common-api/orders/orders'
import { useState } from 'react'
import useSWR from 'swr'

export default function useOrdersList(type, status) {
  const [search, onSearch] = useState('')

  // const { data, mutate } = useSWR(`/order/${type}/${status}`, getOrdersList)
  const { data, mutate } = useSWR(() => {
    if (search === '') {
      return `/order/${type}/${status}`
    }
    if (search !== '') {
      return `/order/${type}/${status}?search=${search}`
    }
    return null
  }, getOrdersList)

  return {
    orderData: data,
    mutate,
    isLoading: !data,
    onSearch,
    search,
  }
}
