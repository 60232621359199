import { makeStyles } from '@mui/styles'
import useToggle from 'common-hooks/useToggle'
import Container from 'components/common/Container'
import Link from 'components/common/Link'
import Radio from 'components/common/radio/Radio'
import Typography from 'components/common/Typography'
import React from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import { Done } from 'assets/icons'
import PlanDetailDialog from './subscriptionDialog/PlanDetailDialog'

const useStyles = makeStyles({
  cardRoot: {
    width: '100%',
    height: '114px',
    borderRadius: '4px',
    border: '1px solid ',
    borderColor: (props) => (props.active ? '#FF8C1A' : '#F0F0F4'),
    padding: '20px',
    position: 'relative',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  extraTypoFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  seeplan: {
    color: '#2680D9',
    zIndex: '99999',
    '& a': {
      color: '#2680D9',
    },
  },
  cardInner: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0 8px',
  },
  radioBtn: {
    paddingTop: '1px',
    position: 'absolute',
    left: '10px',
    top: '10px',
    width: ' 27%',
    height: '55%',
    display: 'unset',
    zIndex: '1',
    // '& input': {
    //   width: '8%',
    //   height: '10%',
    // },
  },
  seeplanRoot: {
    paddingTop: '10px',
    zIndex: 9999999,
    display: 'flex',
    justifyContent: 'space-between',
  },
  radioBtnRight: {
    marginLeft: '24px',
  },
  default: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 2px ',
    color: '#0DA50D',
  },
  priceTextSmall: {
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#9799B5',
  },
})

const UpgradePlanRadioCard = ({
  id,
  name,
  price,
  planDescription,
  value,
  plan,
  offer,
  offerlist,
  onClick,
  upgradePlan,
}) => {
  const active = id === value
  const classes = useStyles({ active })

  const [planDetailDialog, setplanDetailDialog] = useToggle(false)
  return (
    <Container>
      <Container className={classes.cardRoot} onClick={onClick}>
        <Container className={classes.cardInner}>
          <Radio value={name} checked={active} className={classes.radioBtn} />

          <Container width="94%" className={classes.radioBtnRight}>
            <Container className={classes.extraTypoFlex}>
              <Typography variant="body1" color={getColor('grey-g25')}>
                {name}
              </Typography>
              <Typography variant="title" color={getColor('grey-g25')}>
                ${price}
                <span className={classes.priceTextSmall}>/month</span>
              </Typography>
            </Container>
            <Typography variant="paragraph1" color={getColor('grey-g25')}>
              {planDescription}
            </Typography>
            <Container className={classes.seeplanRoot}>
              <Link
                to=""
                underline
                className={classes.seeplan}
                onClick={(e) => {
                  setplanDetailDialog(true)
                  e.stopPropagation()
                }}
              >
                See plan details
              </Link>
              {upgradePlan === true && id === value ? (
                <Typography variant="paragraph1" className={classes.default}>
                  <Done /> Current Plan
                </Typography>
              ) : (
                ''
              )}
            </Container>
          </Container>
        </Container>
      </Container>
      <PlanDetailDialog
        open={planDetailDialog}
        onClose={setplanDetailDialog}
        id={id}
        name={name}
        price={price}
        plan={plan}
        request={planDescription}
        offer={offer}
        offerlist={offerlist}
      />
    </Container>
  )
}

export default UpgradePlanRadioCard
UpgradePlanRadioCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  price: PropTypes.string,
  onClick: PropTypes.func,
  planDescription: PropTypes.string,
  plan: PropTypes.string,
  offer: PropTypes.string,
  offerlist: PropTypes.object,
  upgradePlan: PropTypes.bool,
}
