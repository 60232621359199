import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { ClearIcon } from 'assets/icons'
import useUncontrolledSearch from 'components/common/search/useUncontrolledSearch'
import { IconButton } from '@mui/material'
import TextField from 'components/common/text/TextField'
import Container from 'components/common/Container'
import { Controller, useForm } from 'react-hook-form'

const useStyles = makeStyles(() => ({
  rightIcon: {
    padding: '0.125rem',
  },
  searchRoot: {
    position: 'relative',
    '& input': {
      padding: '0 15px',
    },
    width: '100%',
  },
  searchBtn: {
    position: 'absolute',
    top: '9px',
    right: '15px',
    zIndex: '999',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  iconBtn: {
    padding: '0',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
}))

export default function CompanySearch({
  id,
  onSearchClick,
  onSearch = () => {},
  companyName,
  setCompanyName,
  setCompanyNewName,
  companySetValue,
  ...props
}) {
  const classes = useStyles()
  const { onType, onClear, ref, hasText } = useUncontrolledSearch(onSearch)
  const { setValue, control } = useForm()
  const clearIcon = (props?.value && props?.value !== '') || hasText
  const clearAll = () => {
    onClear()
    setValue('searchCompany', '')
    companySetValue('cmp_addr', '')
    setCompanyName({ label: '', id: '' })
    setCompanyNewName(false)
    if (props.clearPreserveState) props.clearPreserveState()
  }
  if (companyName?.label !== '') {
    setValue('searchCompany', companyName?.label)
  }
  return (
    <>
      <Container className={classes.searchRoot}>
        <Controller
          name={'searchCompany'}
          control={control}
          render={({ field: { name, value } }) => (
            <TextField
              id={id}
              ref={ref}
              inputClassName={classes.root}
              name={name}
              value={value}
              onChange={(e) => {
                onType(e)
              }}
              {...props}
            />
          )}
        />
        <Container className={classes.searchBtn}>
          <IconButton className={classes.iconBtn}>
            {clearIcon && <ClearButton onClick={clearAll} className={classes.rightIcon} />}
          </IconButton>
        </Container>
      </Container>
    </>
  )
}

CompanySearch.propTypes = {
  id: PropTypes.string,
  onSearch: PropTypes.func,
  value: PropTypes.string,
  clearPreserveState: PropTypes.func,
  onSearchClick: PropTypes.func,
  companyName: PropTypes.func,
  setCompanyName: PropTypes.string,
  setCompanyNewName: PropTypes.func,
  companySetValue: PropTypes.func,
}

function ClearButton(props) {
  return (
    <IconButton size="sm" variant="transparent" {...props}>
      <ClearIcon />
    </IconButton>
  )
}
