import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { EditIcon } from 'assets/icons'
import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'
import Image from 'components/common/Image'
import Seperator from 'components/common/Seperator'
import visaCardLogo from 'assets/icons/visaCardLogo.svg'
import React from 'react'
import { getColor } from 'theme/colors'
import useToggle from 'common-hooks/useToggle'
import { useLocation, useNavigate } from 'react-router-dom'
import PaymentMethodDialog from 'containers/common-containers/add-payment-method/PaymentMethodDialog'
import PlanHistoryTable from './PlanHistoryTable'
import ConfirmationDialog from './subscriptionDialog/ConfirmationDialog'
import PropTypes from 'prop-types'
import RecipientDialog from './subscriptionDialog/RecipientDialog'
import { plans } from './SubscriptionUtils'
import { formatPrice } from 'utils/Utils'

const useStyles = makeStyles({
  plansTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '33px',
    paddingBottom: '25px',
  },
  planCard: {
    width: '540px',
    padding: '20px',
    background: ' #FFFFFF',
    border: '1px solid #F0F0F4',
    borderRadius: ' 4px',
  },
  price: {
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#36384C',
  },
  priceSmall: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
  },
  linkColor: {
    '& a': {
      color: '#2680D9',
    },
  },
  planCardInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  mailEditBtn: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  linkDiseabled: {
    color: '#9799B5',
    '& a': {
      color: '#9799B5',
    },
  },
  planGridRight: {
    width: '385px',
    background: '#fff',
    padding: '20px',
    borderRadius: ' 4px',
    border: '1px solid #F0F0F4',
  },
  planCardRightInner: {
    display: 'flex',
    gap: '20px 0',
    flexDirection: 'column',
  },
  planGrid: {
    display: 'flex',
    gap: '0 20px',
  },
  cardNumber: {
    marginBottom: '12px',
  },
  tableWidth: {},
  canelSub: {
    color: '#9799B5',
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  blueLink: {
    color: '#2680D9',
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
})

const SubscriptionPlanHistory = ({ paymentHistory }) => {
  const classes = useStyles()
  const [confimationDialog, setConfirmationDialog] = useToggle(false)
  const [recipentDialog, setRecipentDialog] = useToggle(false)
  const [paymentDialog, setPaymentDialog] = useToggle(false)
  const location = useLocation()
  const navigate = useNavigate()
  const planId = location?.state?.id

  const PlanSummaryArray = plans.filter((d) => d.id === planId)[0]

  const currentPlan = paymentHistory && paymentHistory?.active_plan?.length !== '' && paymentHistory?.active_plan?.[0]
  const PlanSummaryObject = { ...PlanSummaryArray }

  return (
    <Container className={classes.Root}>
      <Container className={classes.plansTitle}>
        <Typography variant="title" color={getColor('grey-g25')}>
          Plan
        </Typography>
      </Container>
      <Container className={classes.planGrid}>
        <Container className={classes.planGridLeft}>
          <Container className={classes.planCard}>
            <Container className={classes.planCardInner}>
              <Container>
                <Container flex justify="space-between">
                  <Typography variant="title" color={getColor('grey-g25')}>
                    {currentPlan?.plan_name}
                  </Typography>
                  <Typography className={classes.price}>
                    {formatPrice(currentPlan?.plan_price)}

                    <span className={classes.priceSmall}>/month</span>
                  </Typography>
                </Container>
                <Container flex justify="space-between">
                  {PlanSummaryObject?.name?.includes('Unlimited') ? (
                    '-'
                  ) : (
                    <Container
                      className={classes.blueLink}
                      onClick={() => navigate('/upgradeaccount', { state: { id: planId, upgradePlan: true } })}
                    >
                      Upgrade to Unlimited
                    </Container>
                  )}
                  <Container
                    className={classes.blueLink}
                    onClick={() => navigate('/upgradeaccount', { state: { id: planId, chanegBilling: true } })}
                  >
                    Change billing period
                  </Container>
                </Container>
              </Container>
              <Seperator />
              <Container>
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  Next Invoice On: mm/dd/yyyy
                </Typography>
                <Container>
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    Invoice Sent To:: maverick.dubuque@gaylord.com
                  </Typography>
                  <IconButton className={classes.mailEditBtn} onClick={() => setRecipentDialog(true)}>
                    <EditIcon />
                  </IconButton>
                </Container>
                <Container onClick={() => setConfirmationDialog(true)} className={classes.canelSub}>
                  Cancel Subscription
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
        <Container className={classes.planGridRight}>
          <Container className={classes.planCardRightInner}>
            <Container flex justify="space-between">
              <Typography variant="title" color={getColor('grey-g25')}>
                Payment Method
              </Typography>
              <Container className={classes.blueLink} onClick={() => setPaymentDialog(true)}>
                Change
              </Container>
            </Container>
            <Seperator />
            <Container>
              <Container>
                <Container flex gap={'0 10px'} justify="flex-start" className={classes.cardNumber}>
                  <Image src={visaCardLogo} />
                  <Typography variant="title" color={getColor('grey-g25')}>
                    **** **** **** 5680
                  </Typography>
                </Container>
                <Typography variant="paragraph1" color={getColor('grey-g25')} component="p">
                  Cameron Williamson
                </Typography>
                <Typography variant="paragraph1" color={getColor('grey-g25')}>
                  Exp: 09/2024
                </Typography>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={classes.plansTitle}>
        <Typography variant="title" color={getColor('grey-g25')}>
          Billing
        </Typography>
      </Container>
      <Container className={classes.tableWidth}>
        <PlanHistoryTable paymentHistory={paymentHistory} />
      </Container>
      <ConfirmationDialog open={confimationDialog} onClose={setConfirmationDialog} />
      <RecipientDialog open={recipentDialog} onClose={setRecipentDialog} />
      <PaymentMethodDialog open={paymentDialog} onClose={setPaymentDialog} successURL={''} />
    </Container>
  )
}

export default SubscriptionPlanHistory

SubscriptionPlanHistory.propTypes = {
  paymentHistory: PropTypes.object,
}
