import { getAllShippingAddresss } from 'common-api/settings/settings'
import useSWR from 'swr'

export default function useShippingAddressList() {
  const { data, mutate } = useSWR(`/shipping/address`, getAllShippingAddresss)

  return {
    addressList: data || [],
    isLoading: !data,
    mutate,
  }
}
