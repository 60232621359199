import PropTypes, { node } from 'prop-types'
import classNames from 'classnames'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import { forwardRef } from 'react'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: 'auto',
    ...getRootStyle(props, theme),
  }),
  label: {
    color: getColor('grey-g35'),
  },
  checkbox: {
    padding: '5px',
    color: getColor('grey-g65'),
    '&.Mui-checked': {
      color: getColor('orange-main'),
    },
    '& svg': {
      fontSize: 'inherit',
      width: 25,
      height: 25,
      border: '1.5px',
      borderRadius: '2px',
    },
  },
}))

const Checkbox = forwardRef(
  (
    {
      name,
      label,
      value,
      onChange,
      className,
      labelPlacement = 'end',
      spaceBetween,
      checkboxClassName,
      labelClassName,
      icon,
      checkedIcon,
      onClick,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({ spaceBetween, labelPlacement })
    return (
      <FormControlLabel
        classes={{
          root: classNames(classes.root, className),
          label: classNames(classes.label, labelClassName),
        }}
        control={
          <MuiCheckbox
            name={name}
            color="default"
            checked={value}
            value={value}
            className={classNames(classes.checkbox, checkboxClassName)}
            onChange={onChange}
            onClick={onClick}
            icon={icon}
            checkedIcon={checkedIcon}
            {...props}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        ref={ref}
      />
    )
  }
)

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['end', 'start']),
  spaceBetween: PropTypes.bool,
  checkboxClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.node,
  checkedIcon: node,
  onClick: PropTypes.func,
}

Checkbox.displayName = 'Checkbox'

export default Checkbox

function getRootStyle(props, theme) {
  return {
    ...(props.spaceBetween && {
      justifyContent: 'space-between',
    }),
    ...(props.labelPlacement === 'start' && {
      margin: theme.spacing(0, -2, 0, 0),
    }),
    ...(props.labelPlacement === 'end' && {
      margin: theme.spacing(0, 0, 0, -2),
    }),
  }
}
