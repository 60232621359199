import Chip from 'components/common/Chip'
import Image from 'components/common/Image'

export const tabs = [
  {
    id: '1',
    tabLabel: 'All Products',
    tabContent: 'Content 1',
  },
  {
    id: '2',
    tabLabel: 'Active',
    tabContent: 'Content 2',
  },
  {
    id: '3',
    tabLabel: 'Inactive',
    tabContent: 'Content 3',
  },
]

function createData(image, title, Status, Stock, brand, partNumber, partDescription, price, quantity) {
  return { image, title, Status, Stock, brand, partNumber, partDescription, price, quantity }
}

export const allProductrows = [
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...,',
    <Chip label="Active" variant="success" />,
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="Inactive" variant="error" />,
    <Chip label="In Stock" variant="secondary" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Inactive" variant="error" />,
    <Chip label="Out of Stock" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '120'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Inactive" variant="error" />,
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="Inactive" variant="error" />,
    <Chip label="In Stock" variant="secondary" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$102.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="Inactive" variant="error" />,
    <Chip label="In Stock" variant="secondary" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '20'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="Expired" variant="error" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="In Stock" variant="secondary" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$26.00',
    '26'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="Out of Stock" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="Out of Stock" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '122'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="Expired" variant="error" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$85.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="Out of Stock" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '122'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="Expired" variant="error" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$85.00',
    '10'
  ),
  createData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Active" variant="success" />,
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
]

export const allProductcols = [
  'Image',
  'Title',
  'Status',
  'Availability',
  'Brand',
  'Part Number',
  'Part Description',
  'Price',
  'Qty.',
]

function createActiveData(image, title, Stock, brand, partNumber, partDescription, price, quantity) {
  return { image, title, Stock, brand, partNumber, partDescription, price, quantity }
}

export const allActivectrows = [
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...,',
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Out of Stock" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '120'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$102.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '20'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Expired" variant="error" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$26.00',
    '26'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Out of Stock" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Out of Stock" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '122'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="Expired" variant="error" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$85.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="Out of Stock" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '122'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'John Deere - Steering Valve - 59...',
    <Chip label="Expired" variant="error" />,
    'John Deere',
    'LW254856358',
    'Steering Value',
    '$85.00',
    '10'
  ),
  createActiveData(
    <Image height={20} width={24} />,
    'JCB - Steering Valve - 59...',
    <Chip label="In Stock" variant="secondary" />,
    'JCB',
    'LW254856358',
    'Steering Value',
    '$23.00',
    '10'
  ),
]

export const allActivectcols = [
  'Image',
  'Title',
  'Availability',
  'Brand',
  'Part Number',
  'Part Description',
  'Price',
  'Qty.',
]
export const cols = ['Title', 'Status', 'Upload Date', 'Upload Date', '']

export const conditions = [
  { label: 'New Aftermarket', value: 'new_aftermarket' },
  { label: 'New OEM', value: 'new_oem' },
  { label: 'Used', value: 'used' },
]
export const status = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
]

export const parseCrossRefNumbers = (data) => {
  const composed = []
  const uniqueIds = []
  Object.keys(data).map((key) => {
    if (key === 'cross_refs' && data?.cross_refs !== undefined) {
      data?.cross_refs?.forEach((item) => {
        const newItem = { ...item, partnumber: [] }
        data?.cross_refs?.forEach((innerItem) => {
          if (innerItem.brand_id === item.brand_id) {
            newItem.partnumber = newItem.partnumber.concat({
              value: innerItem.part_no,
              label: innerItem.part_no,
              id: innerItem?.id,
            })
          }
        })
        composed.push(newItem)
      })
    }
    return composed
  })
  const fillterBrands = composed.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.brand_id)
    if (!isDuplicate) {
      uniqueIds.push(element.brand_id)
      return true
    }
    return false
  })
  return fillterBrands
}

export const parseFitsWith = (data) => {
  const composed = []
  const uniqueIds = []
  Object.keys(data).map((key) => {
    if (key === 'fits_with' && data?.fits_with !== undefined) {
      data?.fits_with?.forEach((item) => {
        const newItem = { ...item, modelNumbers: [] }
        data?.fits_with?.forEach((innerItem) => {
          if (innerItem.brand_id === item.brand_id) {
            newItem.modelNumbers = newItem.modelNumbers.concat({
              model_no: innerItem?.model_no,
              serial_rang_from: innerItem?.serial_rang_from,
              serial_rang_to: innerItem?.serial_rang_to,
              id: innerItem?.id,
            })
          }
        })
        composed.push(newItem)
      })
    }
    return composed
  })
  const fillterBrands = composed.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.brand_id)
    if (!isDuplicate) {
      uniqueIds.push(element.brand_id)
      return true
    }
    return false
  })
  return fillterBrands
}

export const parseProductData = (data, unitPrice, crossRefNumbers, FitsWithData, relatedProducts) => {
  const relatedprods = (relatedProducts) => {
    const ans = []
    if (relatedProducts && relatedProducts.length > 0) {
      for (let i = 0; i < relatedProducts.length; i += 1) {
        const data = relatedProducts[i]
        if (data?.value) {
          ans.push(data?.value)
        }
      }
    }
    return ans
  }

  const relatedProductData = relatedprods(relatedProducts)

  const formatData = (allData) => {
    const ans = []
    if (allData && allData.length > 0) {
      for (let i = 0; i < allData.length; i += 1) {
        const data = allData[i]
        const partialData = {
          brand_id: data.brand_id ? data.brand_id : data.brand_name?.value,
          // brand_cover: data.brand_cover || 'brand_cover',
          // created_at: data.created_at || new Date().toISOString(),
        }
        if (data.partnumber && data.partnumber.length > 0) {
          for (let j = 0; j < data.partnumber.length; j += 1) {
            if (data.partnumber[j]?.value)
              ans.push({
                ...partialData,
                part_no: data.partnumber[j]?.value,
                ...(data?.partnumber[j]?.id && { id: data?.partnumber[j]?.id }),
              })
          }
        }
      }
    }

    return ans
  }

  const newData = formatData(crossRefNumbers)

  const formatFitsData = (allData) => {
    const ans = []
    if (allData && allData.length > 0) {
      for (let i = 0; i < allData.length; i += 1) {
        const data = allData[i]
        const partialData = {
          brand_id: data.brand_id ? data.brand_id : data.brand_name?.value,
        }
        if (data.modelNumbers && data.modelNumbers.length > 0) {
          for (let j = 0; j < data.modelNumbers.length; j += 1) {
            if (data?.modelNumbers[j])
              ans.push({
                ...partialData,
                model_no: data?.modelNumbers[j]?.model_no,
                ...(data?.modelNumbers[j]?.serial_rang_from && {
                  serial_rang_from: data?.modelNumbers[j]?.serial_rang_from,
                }),
                ...(data?.modelNumbers[j]?.serial_rang_to && { serial_rang_to: data?.modelNumbers[j]?.serial_rang_to }),
                ...(data?.modelNumbers[j]?.id && { id: data?.modelNumbers[j]?.id }),
              })
          }
        }
      }
    }

    return ans
  }
  const newFormateData = formatFitsData(FitsWithData)
  const availableIn = (obj, qty) => {
    if (obj) {
      return parseInt(obj, 10)
    }
    if (!obj && qty === 0) {
      return 0
    }
    return ''
  }
  const parsedData = {
    ...(data?.id && { id: data?.id }),
    ...(data?.brand_name?.id && { brand_id: data?.brand_name?.id }),
    ...(data?.part_desp && { part_desp: data?.part_desp }),
    ...(data?.title && { title: data?.title }),
    ...(data?.desp && { desp: data?.desp }),
    ...(availableIn(data?.available_in, data?.qty) !== '' && {
      available_in: availableIn(data?.available_in, data?.qty),
    }),
    ...(data?.part_no && { part_no: data?.part_no }),
    ...(data?.condition.value && { condition: data?.condition.value }),
    ...(data?.weight && { weight: data?.weight }),
    ...(unitPrice && { unit_price: unitPrice }),
    ...(data?.msrp && { msrp: data?.msrp }),
    ...(data?.qty && { qty: data?.qty }),
    // ...(data?.available_in && { available_in: data?.available_in }),
    ...(data?.status.value && { status: data?.status.value }),
    ...(data?.enable_buy_now === true ? { enable_buy_now: data?.enable_buy_now } : { enable_buy_now: false }),
    ...(data?.enable_make_offer === true
      ? { enable_make_offer: data?.enable_make_offer }
      : { enable_make_offer: false }),
    ...(data?.is_returnable === true ? { is_returnable: data?.is_returnable } : { is_returnable: false }),
    ...(data?.has_warranty === true ? { has_warranty: data?.has_warranty } : { has_warranty: false }),
    ...(data?.is_cancellable === true ? { is_cancellable: data?.is_cancellable } : { is_cancellable: false }),
    ...(data?.ltl_freight_required === true
      ? { ltl_freight_required: data?.ltl_freight_required }
      : { ltl_freight_required: false }),

    // ...(data?.enable_buy_now && { enable_buy_now: data?.enable_buy_now }),
    // ...(data?.enable_make_offer && { enable_make_offer: data?.enable_make_offer }),
    // ...(data?.is_returnable && { is_returnable: data?.is_returnable }),
    // ...(data?.has_warranty && { has_warranty: data?.has_warranty }),
    // ...(data?.is_cancellable && { is_cancellable: data?.is_cancellable }),
    // ...(data?.ltl_freight_required && { ltl_freight_required: data?.ltl_freight_required }),
  }
  return JSON.stringify(
    JSON.stringify({
      product: parsedData,
      ...(newData && { cross_refs: newData }),
      ...(newFormateData && { fits_with: newFormateData }),
      ...(relatedProductData.length > 0 && { related_prods: relatedProductData }),
    })
  )
}

export const importRecordData = [
  {
    title: 'Inventory Feb 2023.csv',
    status: 'Pending',
    date: 'Feb. 01, 2023',
    uploadDate: 'Cameron Williamson',
  },
  {
    title: 'Inventory Feb 2023.csv',
    status: 'In Progress',
    date: 'Feb. 01, 2023',
    uploadDate: 'Cameron Williamson',
  },
  {
    title: 'Inventory Feb 2023.csv',
    status: 'Failed',
    date: 'Feb. 01, 2023',
    uploadDate: 'Cameron Williamson',
  },
  {
    title: 'Inventory Feb 2023.csv',
    status: 'Completed',
    date: 'Feb. 01, 2023',
    uploadDate: 'Cameron Williamson',
  },
]
