import {
  createOrderBuyer,
  DeliveryTimeUpdate,
  orderCancel,
  PoNumberUpadate,
  shipOrder,
  shipOrderLocalDelivery,
  shipOrderPickup,
  SoNUmberUpdate,
  TrackingNumberUpdate,
} from 'common-api/orders/orders'
import { getHelp } from 'common-api/user-profile/getUserProfile'
import useToastify from 'common-hooks/useToastify'
import { useParams } from 'react-router-dom'

export default function useOrders() {
  const { toastMessage } = useToastify()

  const param = useParams()

  const cancelOrder = async (data, id) => {
    try {
      await orderCancel({ id, ...data })
      toastMessage('success', 'Order cancelled successfully')
    } catch {
      return false
    }
    return null
  }

  const orderShip = async (trackingNO, courierNme, id) => {
    try {
      await shipOrder({ id: id || param?.id, tracking_no: trackingNO, courier_name: courierNme })
      toastMessage('success', 'Order shipped successfully')
    } catch {
      return false
    }
    return null
  }
  const createOrder = async (quoteId, quoteReqID, options, mutate, listMutate, supplierdetailsMutate) => {
    try {
      const orderId = await createOrderBuyer({
        quote_id: quoteId,
        quote_request_id: quoteReqID,
        delivery_options: options,
      })
      toastMessage('success', 'Order created successfully')
      mutate()
      supplierdetailsMutate()
      listMutate()
      return orderId
    } catch {
      return false
    }
  }
  const orderLocalDeliveryShip = async (data, id) => {
    try {
      await shipOrderLocalDelivery({
        id: id || param?.id,
        diliver_on: data.diliver_on,
        delivered_time: {
          start: data.start.value,
          end: data.end.value,
        },
      })
      toastMessage('success', 'Order shipped successfully')
    } catch {
      return false
    }
    return null
  }
  const orderPickupShip = async (id) => {
    try {
      await shipOrderPickup({ id: id || param?.id })
      toastMessage('success', 'Order shipped successfully')
    } catch {
      return false
    }
    return null
  }
  const updateSoNUmber = async (data) => {
    try {
      await SoNUmberUpdate({ id: param?.id, sales_order_number: data })
      toastMessage('success', 'Sales order number updated')
    } catch {
      return false
    }
    return null
  }

  const updateTrackingNumber = async (trackingNO, courierNme) => {
    try {
      await TrackingNumberUpdate({ id: param?.id, tracking_no: trackingNO, courier_name: courierNme })
      toastMessage('success', 'Tracking number updated')
    } catch {
      return false
    }
    return null
  }
  const updateDeliveryTime = async (data) => {
    try {
      await DeliveryTimeUpdate({
        id: param?.id,
        diliver_on: data.diliver_on,
        delivered_time: {
          start: data.start.value,
          end: data.end.value,
        },
      })
      toastMessage('success', 'Delivery time updated')
    } catch {
      return false
    }
    return null
  }
  const orderHelp = async (email, subject, body, section, sellerName) => {
    try {
      await getHelp({
        // id: param?.id,
        email,
        ...(subject && { subject }),
        body,
        ...(section && { section }),
      })
      toastMessage(
        'success',
        email === 'support@partsclub.us'
          ? 'Message sent successfully to PartsClub'
          : `Message sent successfully to ${sellerName || null}`
      )
    } catch {
      return false
    }
    return null
  }

  const updatePoNumber = async (data) => {
    try {
      await PoNumberUpadate({
        id: param?.id,
        po_number: data,
      })
      toastMessage('success', 'Purchase order number updated')
    } catch {
      return false
    }
    return null
  }

  return {
    cancelOrder,
    orderShip,
    orderLocalDeliveryShip,
    orderPickupShip,
    updateSoNUmber,
    updateTrackingNumber,
    updateDeliveryTime,
    orderHelp,
    updatePoNumber,
    createOrder,
  }
}
