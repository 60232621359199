import React, { useState, useEffect } from 'react'
import Container from 'components/common/Container'
import { TabPanel, Tabs } from 'components/common/Tabs'
import SearchField from 'components/common/search/SearchField'
import Button from 'components/common/Button'
import { makeStyles } from '@mui/styles'
import { CaretLeftIcon, CaretRightIcon, InventoryFilterIcon } from 'assets/icons'
import inventoryEmptyState from 'assets/images/inventoryEmptyState.svg'
import { useNavigate } from 'react-router-dom'
import useToggle from 'common-hooks/useToggle'
import { Table } from 'components/common/Table'
import { getColor } from 'theme/colors'
import { allActivectcols, allProductcols, tabs } from './InventoryUtils'
import FilterDialog from './FilterDialog'
import { inventoryProductLimit } from 'utils/constants'
import useProductList from 'common-hooks/inventory/useProductList'
import Image from 'components/common/Image'
import Chip from 'components/common/Chip'
import LoadingOverlay from 'components/common/LoadingOverlay'

import Typography from 'components/common/Typography'
import IconButton from 'components/common/IconButton'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    // padding: '30px',
  },
  marginHead: {
    position: 'sticky',
    top: '60px',
    // background: getColor('system-bg'),
    zIndex: '1',
  },
  mainContainer: {
    background: '#fff',
    padding: '30px',
  },
  SearchField: {
    '& input': {
      background: 'transparent',
    },
  },
  list: {
    marginTop: '20px',
    height: '82.5vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
      paddingtop: '50px',
      backgroundColor: '#F0F0F4',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
    '& table': {
      '& tr': {
        cursor: 'pointer',
      },
    },
  },
  pagination: {
    borderTop: '1px solid #F0F0F4 ',
    padding: '30px',
    display: 'flex',
    background: '#fff',
    justifyContent: 'space-between',
  },
  paginationFooter: {
    // position: 'sticky',
    bottom: '0px',
    height: 'unset',

    paddingTop: '30px',
    // marginLeft: '-20px',
    // marginRight: '-20px',
    padding: '0 0',
    zIndex: '1',
    // borderTop: `1px solid ${getColor('grey-g95')}`,
    background: getColor('light-main'),
    '& svg path': {
      fill: 'unset',
    },
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  customTableContainer: {
    overflowX: 'initial',
  },
  placeholder: {
    // position: 'absolute',
    display: 'flex',
    top: '0',
    height: '72vh',
    right: '0',
    left: '0',
    bottom: '0',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px 0',
  },
  iconBtn: {
    background: 'unset',
    border: ' 1px solid #9799B5',
    borderRadius: ' 4px',
    width: ' 36px',
    height: '36px',
    '&:hover': {
      background: 'unset',
    },
  },
}))

const Inventory = () => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const [activeRows, setActiveRows] = useState([])
  const [productStatus, setProductStatus] = useState('')
  const [filterData, setFilterData] = useState()

  const navigate = useNavigate()
  const [filterDialog, setFilterDialog] = useToggle()

  const { productData, search, onSearch, setPage, page, isLoading } = useProductList(
    'supplier',
    productStatus,
    filterData
  )

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    switch (newValue) {
      case 0:
        setProductStatus('')
        break
      case 1:
        setProductStatus('active')
        break
      case 2:
        setProductStatus('inactive')
        break
      default:
        setProductStatus('')
    }
  }

  function createData(image, title, Status, Stock, brand, partNumber, partDescription, price, quantity, id) {
    if (productStatus === '') {
      return { image, title, Status, Stock, brand, partNumber, partDescription, price, quantity, id }
    }
    return { image, title, Stock, brand, partNumber, partDescription, price, quantity, id }
  }

  useEffect(() => {
    if (productData?.length > 0) {
      const rows = productData?.map((obj) =>
        createData(
          <Image src={obj?.cover_image || obj?.brand_cover} height={20} width={24} />,
          `${obj?.brand_name} - ${obj?.part_desp} - ${obj?.part_no}`,
          <Chip
            label={obj?.status === 'active' ? 'Active' : 'Inactive'}
            variant={obj?.status === 'active' ? 'success' : 'error'}
          />,
          <Chip
            label={obj?.stock === true ? 'In Stock' : 'Out of Stock'}
            variant={obj?.stock === true ? 'secondary' : ''}
          />,
          obj?.brand_name ? obj?.brand_name : '-',
          obj?.part_no ? obj?.part_no : '-',
          obj?.part_desp ? obj?.part_desp : '-',
          obj?.unit_price ? `$ ${obj?.unit_price}` : '-',
          obj?.qty ? obj?.qty : '-',
          obj?.id
        )
      )
      setActiveRows(rows)
    }
  }, [productData])

  const handleOnProduct = (id) => {
    navigate(`/inventory/editproduct/${id}`)
  }

  return (
    <Container height={'92.9vh'}>
      <Container className={classes.mainContainer} width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            <Tabs value={tabValue} onchange={handleChange} array={tabs} />
            <Container flex gap={'20px'}>
              <Container>
                <SearchField
                  id={'searchField'}
                  width={'250px'}
                  className={classes.SearchField}
                  onSearch={(e) => {
                    onSearch(e)
                  }}
                  value={search}
                />
              </Container>
              <Container flex gap={'10px'}>
                {/* version 1.1 */}

                {/* <Button variant={'secondary'} border onClick={() => navigate('/inventory/import')}>
                  Import
                </Button> */}

                <IconButton className={classes.iconBtn} onClick={setFilterDialog}>
                  <InventoryFilterIcon />
                </IconButton>
                <Button onClick={() => navigate('/inventory/addproduct')}>&nbsp;Add</Button>
              </Container>
            </Container>
          </Container>
        </Container>

        <Container className={classes.list}>
          <TabPanel value={tabValue} index={0}>
            <Container>
              {isLoading && <LoadingOverlay />}
              {productData?.length > 0 ? (
                <Table stickyHeader cols={allProductcols} rows={activeRows} handleOnClick={handleOnProduct} />
              ) : (
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={inventoryEmptyState} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    <Container flex direction="column">
                      <Typography variant="title" color={getColor('grey-g25')} component="p">
                        Upload products for sale
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                        Add products that you would like to sell individually
                      </Typography>
                    </Container>
                    <Container flex gap={'0 15px'}>
                      <Button onClick={() => navigate('/inventory/addproduct')}>Add Product</Button>

                      {/* version 1.1 */}

                      {/* <Button variant="secondary" border onClick={() => navigate('/inventory/import')}>
                     Import CSV
                   </Button> */}
                    </Container>
                  </Container>
                </Container>
              )}
            </Container>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Container>
              {isLoading && <LoadingOverlay />}
              {productData?.length > 0 ? (
                <Table stickyHeader cols={allActivectcols} rows={activeRows} handleOnClick={handleOnProduct} />
              ) : (
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={inventoryEmptyState} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    <Container flex direction="column">
                      <Typography variant="title" color={getColor('grey-g25')} component="p">
                        No active products
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                        You don’t have any active products yet.
                      </Typography>
                    </Container>
                    <Container flex gap={'0 15px'}>
                      <Button onClick={() => navigate('/inventory/addproduct')}>&nbsp;Add Product</Button>

                      {/* version 1.1 */}

                      {/* <Button variant="secondary" border onClick={() => navigate('/inventory/import')}>
                    Import CSV
                  </Button> */}
                    </Container>
                  </Container>
                </Container>
              )}
            </Container>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Container>
              {isLoading && <LoadingOverlay />}
              {productData?.length > 0 ? (
                <Table stickyHeader cols={allActivectcols} rows={activeRows} handleOnClick={handleOnProduct} />
              ) : (
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={inventoryEmptyState} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    <Container flex direction="column">
                      <Typography variant="title" color={getColor('grey-g25')} component="p">
                        No inactive products
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                        Awesome! You currently have no inactive products.
                      </Typography>
                    </Container>
                  </Container>
                </Container>
              )}
            </Container>
          </TabPanel>
          <Container flex justify={'space-between'} className={classes.paginationFooter}>
            <Button
              icon={<CaretLeftIcon />}
              variant={'transparent'}
              className={classes.prevBtn}
              onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
              disabled={page === 1}
            >
              Prev.
            </Button>
            <Button
              icon={<CaretRightIcon />}
              variant={'transparent'}
              className={classes.nextBtn}
              onClick={() => setPage((pre) => pre + 1)}
              disabled={productData?.length < inventoryProductLimit}
            >
              Next
            </Button>
          </Container>
        </Container>
      </Container>

      <FilterDialog open={filterDialog} onClose={setFilterDialog} setFilterData={setFilterData} />
    </Container>
  )
}

export default Inventory
