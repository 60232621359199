import { Suspense } from 'react'
import AppRoutes from './AppRoutes'

export default function RouteIndex() {
  return (
    <Suspense fallback={'Loding...'}>
      <AppRoutes />
    </Suspense>
  )
}
