import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import Container from './Container'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  circularBackTrack: (props) => ({
    color: getColor('grey-g95'),
    width: '36px!important' || props.width,
    height: '36px!important' || props.height,
  }),
  activeBackTrack: (props) => ({
    color: getColor('func-alert'),
    position: 'absolute',
    left: 0,
    width: '36px!important' || props.width,
    height: '36px!important' || props.height,
  }),
  progressLabel: {
    color: getColor('grey-g35'),
    marginBottom: '4px',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: ' center',
    alignItems: ' center',
  },
}))

export default function ProgressBar({ variant, value, className, thickness, valueLabel, width, height }) {
  const classes = useStyles({ width, height })
  return (
    <Container className={classNames(className || classes.root)}>
      <CircularProgress
        variant={variant || 'determinate'}
        thickness={thickness}
        className={classes.circularBackTrack}
        value={100}
      />
      <CircularProgress
        variant={variant || 'determinate'}
        thickness={thickness}
        className={classes.activeBackTrack}
        value={value}
      />
      <Typography variant="caption" className={classes.progressLabel}>
        {valueLabel}/3
      </Typography>
    </Container>
  )
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  thickness: PropTypes.number,
  value: PropTypes.number,
  valueLabel: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  variant: PropTypes.string,
}
