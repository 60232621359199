/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Container from 'components/common/Container'
import SearchField from 'components/common/search/SearchField'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import Chip from 'components/common/Chip'
import { useContext, useEffect, useState } from 'react'
import Placeholder from 'assets/images/Placeholder.png'
import usePartsDeep from 'common-hooks/usePartsDeep'
import { CircularProgress } from '@mui/material'
import { BuyerQuoteContext } from 'containers/buyer-quotes/BuyerQuotesUtils'
import LoadingOverlay from 'components/common/LoadingOverlay'
import PartsSelectionTreeView from 'containers/common-containers/PartsSelectionTreeView'
import { useFormData } from './FormProvider'
import useQuotePartsTree from 'common-hooks/useQuotePartsTree'

const useStyles = makeStyles(() => ({
  form: {
    overflow: 'auto',
    width: 'auto',
  },
  root: {
    padding: (props) => getPadding(props.toggle, props?.visibleTextBoxData.length !== 0),
    height: '600px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  partsDataChip: {
    zIndex: '111111',
    marginTop: '10px',
    display: 'flex',
    flexFlow: 'wrap',
    gap: '10px',
    order: '3',
    backgroundColor: '#fff',
    padding: '0 0 10px 0',
    position: 'relative',
    top: '80px',
  },
  productInfo: {
    width: '100%',
    padding: '20px 0',
    zIndex: 999,
    maxWidth: '780px',
    position: 'absolute',
    top: '0px',
    backgroundColor: '#FFFF',
    '& input': {
      width: 'auto ',
    },
  },
  img: {
    width: '50px',
    height: '50px',
    padding: '4px',
    '& img': {
      width: '80px',
      ObjectFit: 'cover',
      height: '100%',
      border: '1px solid #F0F0F4 ',
    },
  },
  divFlex: {
    display: 'flex',
  },
  imgDiv: {
    height: '50px',
    width: '50px',
    background: '#fff',
    border: '1px solid #F0F0F4',
  },
  chips: {
    display: 'flex',
    gap: '4px',
  },
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '47%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
}))

export default function PartsForm({ setnextBtn, nextFormStep, formStep }) {
  const { data, setFormValues } = useFormData()
  const [loading, setLoading] = useState(false)
  const { BuyerQueoteInformation, visibleTextBoxData, setTextBoxVisibilityData, onSaveBuyerQuoteInformaton } =
    useContext(BuyerQuoteContext)
  const [visibleTextBox, setTextBoxVisibility] = useState(visibleTextBoxData)

  // const { partsTree, isLoading, setSelectedNode } = usePartsDetails(
  //   BuyerQueoteInformation?.Equipments?.equipment?.id,
  //   null,
  //   true
  // )
  const { partsTree, isLoading, mutate } = useQuotePartsTree(BuyerQueoteInformation?.Equipments?.equipment?.id)

  const { allNodeIds, query, setSearchQuery, searchedTree } = usePartsDeep(
    // BuyerQueoteInformation?.Brands.brand?.id,
    BuyerQueoteInformation?.Equipments?.equipment?.id
  )

  const classes = useStyles({ visibleTextBoxData })
  const [parts, setParts] = useState()

  const { handleSubmit } = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      email: data?.email || '',
    },
  })
  setnextBtn(true)

  visibleTextBoxData?.map((item) => {
    if (item?.checked === true) {
      return setnextBtn(false)
    }
    return setnextBtn(true)
  })
  const handleSearch = (node) => {
    if (node?.length === 0) {
      setSearchQuery(node)
      setParts(partsTree)
    } else if (query !== node) {
      setSearchQuery(node)
      setLoading(true)
    }
  }

  useEffect(() => {
    if (query?.length > 0) {
      setParts(searchedTree)
      setLoading(false)
    }
  }, [searchedTree?.length])

  useEffect(() => {
    if (partsTree && partsTree?.length > 0 && !parts) {
      setParts(partsTree)
    }
  }, [parts, partsTree])
  const onSaveContextValue = () => {
    onSaveBuyerQuoteInformaton({ ...BuyerQueoteInformation, visibleTextBoxData })
  }

  const onSubmit = async (values) => {
    setFormValues(values)
    onSaveContextValue()
    nextFormStep(formStep + 1)
    // const response = await createQuote({ ...BuyerQueoteInformation, visibleTextBoxData })
    // const links = []
    // setLoading(true)
    // setTimeout(() => {
    //   links.push(...response)
    //   setLoading(false)
    //   setTextBoxVisibilityData([])
    //   setSuccesDialog(true)
    //   setSuccesLink(links)
    //   onClose()
    // }, 2000)
  }
  function equipmentName(val) {
    if (val?.equipment?.name === 'Other') {
      return val?.equipmentName
    }
    return val?.equipment?.name
  }
  function brandName(val) {
    if (val?.brand?.name === 'Other') {
      return val?.brandName
    }
    return val?.brand?.name
  }
  function coverImage(val) {
    if (val === '/static/media/UnionIcon.e8ec7324b81cc0c0dd56b9cfff1a8858.svg') {
      return Placeholder
    }
    return val
  }
  function handleRemoveChip(id) {
    setTextBoxVisibilityData(visibleTextBoxData?.filter((obj) => obj?.id !== id))
    setTextBoxVisibility(visibleTextBoxData?.filter((obj) => obj?.id !== id))
  }
  return (
    <Container>
      {loading && <CircularProgress color="secondary" size={40} className={classes.progress} />}
      {/* <Container className={classes.info}>
        <InfoPanel setToggle={setToggle} toggle={toggle} />
      </Container> */}
      <Container className={classes.form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              document.activeElement.blur()
            }
          }}
          id={`hook-form-${formStep}`}
        >
          <Container>
            <Container flex justify="space-between" className={classes.productInfo}>
              <Container className={classes.partsDataDiv}>
                <Container flex width="100%" justify="flex-start" gap={'10px'}>
                  <Container className={classes.imgDiv}>
                    <img
                      src={coverImage(BuyerQueoteInformation?.Brands?.brand?.coverImg)}
                      alt="placehodler"
                      className={classes.img}
                    />
                  </Container>
                  <Container className={classes.divFlex} direction="column" gap="4px">
                    <Typography variant="body1">{brandName(BuyerQueoteInformation?.Brands)}</Typography>
                    <Container className={classes.chips}>
                      <Chip label={equipmentName(BuyerQueoteInformation?.Equipments)} />
                    </Container>
                  </Container>
                </Container>
              </Container>

              <Container justify="center" flex>
                <SearchField
                  id="search-feild"
                  placeholder={'Search part description'}
                  clearable
                  clearPreserveState={() => handleSearch('')}
                  onBlur={(e) => {
                    handleSearch(e.target.value)
                  }}
                />
              </Container>
            </Container>
          </Container>
          {visibleTextBoxData[0]?.name && (
            <Container className={classes.partsDataChip} width={'100%'}>
              {visibleTextBoxData?.map(
                (obj, index) =>
                  obj?.name && (
                    <Chip
                      label={`${obj?.name ? obj?.name : null}: ${obj?.value !== '' ? obj?.value : ''}`}
                      key={index}
                      removable
                      onRemove={() => handleRemoveChip(obj?.id)}
                    />
                  )
              )}
            </Container>
          )}
          <Container className={classes.root}>
            {isLoading && <LoadingOverlay />}
            <PartsSelectionTreeView
              partsList={parts}
              visibleTextBox={visibleTextBox}
              setTextBoxVisibility={setTextBoxVisibility}
              setTextBoxVisibilityData={setTextBoxVisibilityData}
              idsToExpand={allNodeIds}
              equpId={BuyerQueoteInformation?.Equipments?.equipment?.id}
              query={query}
              mutate={mutate}
              setParts={setParts}
            />
          </Container>
        </form>
      </Container>
    </Container>
  )
}

PartsForm.propTypes = {
  setnextBtn: PropTypes.func,
  formStep: PropTypes.number,
  nextFormStep: PropTypes.number,
}
function getPadding(infoVisbility, chipVisibility) {
  let padding = ''
  if (chipVisibility) {
    padding = `${'90px 0px 0 0px'}`
  } else {
    padding = `${'95px 0px 0 0px'}`
  }
  return padding
}
