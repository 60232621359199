import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px 0',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  bodyInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
})

export default function HowDoOfferWorkDialog({ open, onClose }) {
  const classes = useStyles()
  const { handleSubmit } = useForm()
  //   const options = countryOption?.map((item) => createOption(item, item))

  const onSubmit = () => {
    onClose()
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'How do offers work?'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.dialogbody}>
              <Container>
                <Typography variant="body2" color={getColor('grey-g65')} component="p">
                  Offers allow buyers and sellers to easily and securely work together on PartsClub to close more deals
                  quickly.
                </Typography>
              </Container>
              <Container className={classes.bodyInner}>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  • Buyers can send up to (3) offers to a supplier per product or quote
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  • In sending an offer, a buyer is agreeing to purchase the product(s) at the price in their submitted
                  offer
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  • If a supplier accepts an offer, an order is automatically created and the buyer must pay for that
                  order
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  • All offers expire after 48 hours
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  • If a buyer fails to respond in 48 hours, they will lose one of their (3) offers in the negotiation
                  and the price will revert back to the original asking price. Seller response times are tracked and
                  logged against their PartsClub Seller Score
                </Typography>
              </Container>
            </Container>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
HowDoOfferWorkDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
