import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    padding: theme.spacing(10, 0),
    minHeight: '100%',
  },
  container: {
    margin: theme.spacing(2.5, 0),
  },
  headerTitle: {
    top: 0,
  },
}))

export default function EmptyContainer({
  title = 'No data found',
  alt = 'Empty placeholder',
  content,
  img,
  imgComponent,
  cardTitle,
  disableTitle,
  width = 75,
  ...props
}) {
  const classes = useStyles()
  return (
    <Container className={classes.root} container alignItems="center" justify="center" {...props}>
      {!!img && !imgComponent && <img src={img} width={width} alt={alt} />}
      {!!imgComponent && imgComponent}
      {!disableTitle && (
        <Typography className={classes.container} variant="h3">
          {title}
        </Typography>
      )}
      {content}
    </Container>
  )
}

EmptyContainer.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string,
  img: PropTypes.string,
  content: PropTypes.node,
  imgComponent: PropTypes.node,
  cardTitle: PropTypes.node,
  disableTitle: PropTypes.bool,
  width: PropTypes.number,
}
