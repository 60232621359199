import { createTheme } from '@mui/material'
import typography from './typography'
import createPalette from './palette'
import props from './props'
import overrides from './overrides'

export default function createRootTheme(clientColor) {
  return createTheme({
    typography,
    props,
    overrides,
    palette: createPalette(clientColor),
    spacing: (factor) => `${0.25 * factor}rem`,
  })
}
