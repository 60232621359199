import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Sidebar from 'components/custom/Sidebar'
import React, { useEffect, useState } from 'react'
import Steps from 'components/common/Steps'
import Button from 'components/common/Button'
import { getColor } from 'theme/colors'
import { useNavigate } from 'react-router-dom'
import SupplierRequestForm from './SupplierRequestForm'
import SupplierPartsForm from './SupplierPartsForm'
import SupplierShippingForm from './SupplierShippingForm'
import { SupplierQuoteContext } from '../SupplierQuoteDetailsUtils'
import RequestPreview from '../RequestPreview'
import PartsPreview from '../PartsPreview'
import ShippingPreview from '../ShippingPreview'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  preview: {
    width: '79%',
  },
  supplieRoot: {
    paddingTop: '0',
    height: '100%',
    position: 'relative',
    width: '400px',
    // width: '21%',
    '& .supplierForm': {
      paddingTop: '10px',
      maxHeight: '85vh',
      paddingBottom: '50px',

      width: '100%',
      '&::-webkit-scrollbar': {
        width: '6px',
        backgroundColor: '#fff',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        borderRadius: ' 100px',
        background: '#C3C5D5',
      },
    },
    '& .stepRoot': {
      padding: '18px 20px',
      borderBottom: '1px solid #F0F0F4',
      borderRight: '1px solid #F0F0F4',
      '& button': {
        padding: '0 6px',
        height: 'unset',
        minHeight: 'unset',
      },
      '& div': {
        '& div:first-child': {
          '& button': {
            paddingLeft: '0',
          },
        },
      },
    },
  },
  cancleBtn: {
    border: '1px solid ',
    borderColor: getColor('grey-g65'),
    color: getColor('grey-g35'),
  },
  previousBtn: {
    marginRight: '10px',
  },
  supplierFooter: {
    display: 'flex',
    padding: '17px 20px',
    borderTop: '1px solid #F0F0F4',
    borderRight: '1px solid #F0F0F4',
    marginTop: '-5px',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    backgroundColor: '#fff',
  },
  disabled: {
    border: 0,
  },
}))
const SupplierQuoteSidebar = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [buttonDisable, setBttonDisable] = useState(false)
  const TabsList = [
    { tabIndex: 0, name: 'Details' },
    { tabIndex: 1, name: 'Parts' },
    { tabIndex: 2, name: 'Shipping' },
  ]
  const [formStep, setFormStep] = useState(0)
  const handleTabChange = (newValue) => {
    setFormStep(newValue)
  }
  const nextFormStep = () => {
    setFormStep((currentStep) => currentStep + 1)
    if (formStep === 2) {
      setFormStep(0)
    }
  }

  const prevFormStep = () => {
    setFormStep((currentStep) => currentStep - 1)
  }
  const [partsQueoteInfo, setPartsQueoteInfo] = useState({})

  const constextValue = {
    supplierQueoteInformation: partsQueoteInfo,
    onSaveSupplierQuoteInformaton: setPartsQueoteInfo,
  }

  useEffect(() => {
    if (formStep === 0) {
      setBttonDisable(false)
    }
  }, [formStep])
  return (
    <SupplierQuoteContext.Provider value={constextValue}>
      <Container className={classes.root}>
        <Container className={classes.supplieRoot}>
          <Container className={'stepRoot'}>
            <Steps TabsList={TabsList} currentStep={formStep} handleTabChange={handleTabChange} />
          </Container>

          <Sidebar className="supplierForm" display="visible">
            {formStep === 0 && <SupplierRequestForm />}
            {formStep === 1 && <SupplierPartsForm setBttonDisable={setBttonDisable} />}
            {formStep === 2 && <SupplierShippingForm setBttonDisable={setBttonDisable} />}
          </Sidebar>

          <Container className={classes.supplierFooter} justify={'space-between'}>
            <Button
              variant="transparent"
              className={classes.cancleBtn}
              width={'77px'}
              onClick={() => navigate('/quotes')}
            >
              Cancel
            </Button>
            <Container>
              <Button
                className={formStep <= 0 ? classes.disabled : classes.previousBtn}
                variant="secondary"
                width={'90px'}
                onClick={prevFormStep}
                border
                disabled={formStep <= 0}
              >
                Previous
              </Button>
              {formStep === 0 && (
                <Button width={'auto'} onClick={nextFormStep} disabled={buttonDisable}>
                  Next
                </Button>
              )}
              {formStep === 1 && (
                <Button width={'auto'} onClick={nextFormStep} disabled={buttonDisable}>
                  Next
                </Button>
              )}
              {formStep === 2 && (
                <Button type="submit" form={'supplierPartsForm'} disabled={buttonDisable}>
                  Send Quote
                </Button>
              )}
            </Container>
          </Container>
        </Container>
        <Container className={classes.preview}>
          {formStep === 0 && <RequestPreview />}
          {formStep === 1 && <PartsPreview />}
          {formStep === 2 && <ShippingPreview />}
        </Container>
      </Container>
    </SupplierQuoteContext.Provider>
  )
}
export default SupplierQuoteSidebar
