import PropTypes from 'prop-types'
import TreeView from '@mui/lab/TreeView'
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import {
  BlackDot,
  BluePlus,
  CaretDownBlueIcon,
  CaretRightIcon,
  DeleteIcon,
  DisableClose,
  RedDeleteIcon,
  SimpleClose,
  Tick,
} from 'assets/icons'
import { Typography } from '@mui/material'
import { getColor } from 'theme/colors'
import TextField from 'components/common/text/TextField'
import Container from 'components/common/Container'
import typography from 'theme/typography'
import Seperator from 'components/common/Seperator'
import { allowOnlyAlphaNumric, generateUUID } from 'utils/Utils'
import { useContext, useEffect, useState } from 'react'
import Button from 'components/common/Button'
import { BuyerQuoteContext } from 'containers/buyer-quotes/BuyerQuotesUtils'
import useToastify from 'common-hooks/useToastify'

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF;',
    border: `1px solid ${getColor('grey-g95')}`,
    borderTop: 'unset',
    overflowX: 'hidden',
  },
  treeViewTextfiled: {
    // '& input': {
    border: '1px solid #F0F0F4',
    // borderLeft: `1px solid ${getColor('grey-g95')}`,
    // borderRight: `1px solid ${getColor('grey-g95')}`,
    backgroundColor: '#FFFFFF',
    margin: '2px 0',
    borderRadius: 0,
    // '&:hover': {
    //   borderColor: getColor('grey-g95'),
    // },
    // '&:focus': {
    //   borderColor: getColor('grey-g95'),
    // },
    fontWeight: typography.paragraph1.fontWeight,
    fontSize: typography.paragraph1.fontSize,
    // },
  },
  reportthiLink: {
    fontSize: ' 12px',
    lineHeight: '10px',
    backgroundColor: 'unset',
    color: (props) => (props.disableBtn ? `${getColor('grey-g65')} !important` : '#2680D9'),
    height: 'unset',
    width: 'unset',
    minHeight: 'unset',
    borderRadius: 'unset',
    padding: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& span': {
      fontSize: ' 12px',
      lineHeight: '12px',
    },
  },
  newFiled: {
    '& div input': {
      border: 'none',
      background: 'none',
      borderBottom: '1px solid #2680D9',
      position: 'relative',
      top: '3px',
    },
  },
  deleteIcon: {
    height: '30px',
    width: '30px',
  },
  deleteBtnDiv: {
    borderLeft: '1px solid #F0F0F4',
    padding: '0 15px',
  },
}))
export default function PartsSelectionTreeView({
  partsList,
  visibleTextBox,
  setTextBoxVisibility,
  setTextBoxVisibilityData,
  idsToExpand,
  equpId,
  setParts,
}) {
  const classes = useStyle({ partsList })
  const [expandedIds, setExpanded] = useState([])
  const { visibleTextBoxData } = useContext(BuyerQuoteContext)
  const [disableBtn, setDisableBtn] = useState()
  const [addNewPartName, setNewPartName] = useState()
  const [flag, refresh] = useState()
  const { toastMessage } = useToastify()
  useEffect(() => {
    setExpanded(idsToExpand)
  }, [idsToExpand, partsList])
  // For Create New Custom Part by Customer==================================================================================

  const onCreateNewPartClick = (parentId) => {
    const partData = { name: 'new-user-part', parent_id: parentId }
    setParts((pre) => {
      pre?.map((obj) => {
        if (obj?.id === parentId) {
          obj?.children?.push(partData)
          setDisableBtn(true)
        }
        return 0
      })
      return pre
    })
    refresh(!flag)
  }
  const handleCreateNewPart = async (parentId) => {
    if (addNewPartName === '' || !addNewPartName) {
      toastMessage('error', 'Please enter part name')
      return
    }
    const partData = {
      id: generateUUID(),
      equips: equpId,
      name: addNewPartName,
      ...(parentId && { parent_id: parentId }),
      isDelete: true,
    }
    handleCheck(true, partData.id, '', addNewPartName, parentId)
    setParts((pre) => {
      pre?.map((obj) => {
        if (obj?.id === parentId) {
          obj?.children?.push(partData)
          setDisableBtn(false)
        }
        return 0
      })
      return pre
    })
    handleRemoveNewPart(null)
    setNewPartName('')
    // refresh(!flag)
  }
  const handleRemoveNewPart = () => {
    partsList?.map((obj) => {
      obj?.children?.map((objChild, i) => {
        if (obj?.children[i]?.name === 'new-user-part') {
          delete obj?.children[i]
          setDisableBtn(false)
        }
        return 0
      })
      return 0
    })
    refresh(!flag)
  }
  const handleDeletePart = (name) => {
    partsList?.map((obj) => {
      obj?.children?.map((i, index) => {
        if (obj?.children[index]?.name === name) {
          delete obj?.children[index]
        }
        return 0
      })
      return 0
    })
    setTextBoxVisibilityData(visibleTextBoxData?.filter((obj) => obj?.name !== name))
    setTextBoxVisibility(visibleTextBoxData?.filter((obj) => obj?.name !== name))
    refresh(!flag)
  }

  // =====================================================================================================================================================

  // handle and collect data -- checkbox and textbox visibility and textbox data -- in array=================================================
  const handleCheck = (checked, id, value, name, parentId) => {
    setTextBoxVisibility((prevState) => {
      let flag = false
      let res = prevState?.map((obj) => {
        if (id === obj.id) {
          flag = true
          return { id, parentId, checked, value: checked ? value : '', name }
        }
        return obj
      })
      if (!flag) {
        if (!res) res = []
        if (checked) {
          res.push({ id, parentId, checked, value: checked ? value : '', name })
        }
      }
      if (!checked) {
        res = res.filter((idx) => idx.id !== id)
      }
      setTextBoxVisibilityData(res)
      return res
    })
  }
  // =====================================================================================================================================================

  const getTreeItemsFromData = (treeItems, handleCheck, visibleTextBox) => {
    const getTextboxValue = (id) => {
      let val
      visibleTextBox?.forEach((obj) => {
        if (obj.id === id) {
          val = obj.value
        }
      })
      return val
    }
    return treeItems?.map((treeItemData) => {
      let children
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children, handleCheck, visibleTextBox)
      }
      return (
        <>
          <StyledTreeItem
            disabled={!treeItemData?.children}
            {...(treeItemData.name === 'Add Part' && {
              icon: <DeleteIcon className={'noneIcon'} />,
            })}
            sx={{ border: '1px solide' }}
            key={treeItemData.id}
            nodeId={treeItemData.id}
            labelText={treeItemData.name}
            disableBtn={disableBtn}
            onCreateNewPartClick={() => onCreateNewPartClick(treeItemData?.parent_id)}
            labelInfo={
              Array.isArray(children) ? (
                ''
              ) : (
                <>
                  <Container flex gap="15px">
                    <TextField
                      type={'text'}
                      onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                      id={treeItemData.id}
                      inputClassName={classes.treeViewTextfiled}
                      placeholder={'Part Number'}
                      autocomplete={'off'}
                      onClick={(e) => e.target.focus()}
                      onChange={(e) =>
                        handleCheck(true, treeItemData.id, e.target.value, treeItemData?.name, treeItemData?.parent_id)
                      }
                      clearable
                      value={getTextboxValue(treeItemData.id) ? getTextboxValue(treeItemData.id) : ''}
                      defaultValue=""
                      onClear={() =>
                        handleCheck(true, treeItemData.id, '', treeItemData?.name, treeItemData?.parent_id)
                      }
                    />
                    {treeItemData?.user_created === undefined && (
                      <>
                        <Container className={classes.deleteBtnDiv}>
                          <RedDeleteIcon
                            onClick={() => handleDeletePart(treeItemData?.name)}
                            className={classes.deleteIcon}
                          />
                        </Container>
                      </>
                    )}
                    {/* <Checkbox
                      value={isVisible(treeItemData.id)}
                      // checked={treeItemData?.user_created || treeItemData?.isDelete}
                      onClick={(e) => {
                        handleCheck(e.target.checked, treeItemData.id, '', treeItemData?.name)
                      }}
                    /> */}
                  </Container>
                </>
              )
            }
            newPartLabelInfo={
              Array.isArray(children) ? (
                ''
              ) : (
                <>
                  <Container width="100%" display="flex" className="newDiv">
                    <TextField
                      className={classes.newFiled}
                      onChange={(e) => setNewPartName(e?.target?.value)}
                      onClick={(e) => e.target.focus()}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleCreateNewPart(treeItemData?.parent_id)
                        }
                      }}
                    />
                    <Container flex>
                      <Button
                        icon={<Tick />}
                        onClick={() => handleCreateNewPart(treeItemData?.parent_id)}
                        className={classes.reportthiLink}
                      />
                      <Button
                        icon={<SimpleClose />}
                        onClick={() => handleRemoveNewPart(treeItemData?.parent_id)}
                        // onClick={() => treeItems.filter((dlr) => dlr.newPartId !== treeItemData?.newPartId)}
                        // onClick={() => treeItems.filter((dlr) => dlr.newPartId !== treeItemData?.newPartId)}
                        className={classes.reportthiLink}
                      />
                    </Container>
                  </Container>
                </>
              )
            }
            children={children}
          />
        </>
      )
    })
  }

  return (
    <TreeView
      {...(expandedIds && expandedIds?.length > 0 && { expanded: expandedIds })}
      className={classes.root}
      defaultCollapseIcon={
        <span style={{ width: 30, marginTop: 6 }}>
          <CaretDownBlueIcon />
        </span>
      }
      defaultExpandIcon={
        <span style={{ width: 30, marginTop: 6 }}>
          <CaretRightIcon />
        </span>
      }
      defaultEndIcon={
        <span style={{ width: 30, marginTop: 6 }} className={classes.blackDot}>
          <BlackDot />
        </span>
      }
      // sx={{ flexGrow: 1, overflowY: 'auto' }}
      searchMode={'Poppet'}
      onNodeSelect={handleRemoveNewPart}
      // onNodeSelect={(e, nodeId) => {
      //   if (!expandedIds || expandedIds?.length === 0) setSelectedNode({ nodeId, isSelected: false })
      // }}
    >
      {getTreeItemsFromData(partsList || [], handleCheck, visibleTextBox)}
    </TreeView>
  )
}
PartsSelectionTreeView.propTypes = {
  partsList: PropTypes.array,
  visibleTextBox: PropTypes.array,
  setTextBoxVisibility: PropTypes.func,
  setTextBoxVisibilityData: PropTypes.func,
  setSelectedNode: PropTypes.func,
  idsToExpand: PropTypes.array || undefined,
  onCreateNewPartClick: PropTypes.func,
  equpId: PropTypes.string,
  setParts: PropTypes.func,
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: getColor('dark-d25'),
    backgroundColor: getColor('light-main'),
    padding: '0 20px',
    fontWeight: theme.typography.fontWeightMedium,
    height: '42px',
    border: `0px`,
    // borderBottom: 'unset',
    '&:hover': {
      backgroundColor: '#F0F0F4',
    },
  },
  [`& .${treeItemClasses.expanded}`]: {
    color: getColor('func-info'),
    backgroundColor: getColor('light-main'),
    fontWeight: typography.body1.fontWeight,
    fontSize: typography.body1.fontSize,
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
  },
  [`& .${treeItemClasses.disabled}`]: {
    opacity: '1 !important',
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: '50px',
    },
  },
}))

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    onCreateNewPartClick,
    newPartLabelInfo,
    disableBtn,
    ...other
  } = props
  const classes = useStyle({ labelText, disableBtn })
  return (
    <>
      <Seperator key={1} width="200%" margin={'0px 0px 0px -200px'} />
      <StyledTreeItemRoot
        label={
          <>
            {labelText === 'Add Part' ? (
              <>
                <Container>
                  <Button
                    icon={disableBtn ? <DisableClose /> : <BluePlus />}
                    onClick={onCreateNewPartClick}
                    className={classes.reportthiLink}
                    disabled={disableBtn}
                  >
                    Add Part
                  </Button>
                </Container>
              </>
            ) : (
              <Container flex>
                <Typography variant="body1" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                  {labelText === 'new-user-part' ? '' : labelText}
                </Typography>
                {labelText === 'new-user-part' ? (
                  newPartLabelInfo
                ) : (
                  <Typography variant="caption" color="inherit">
                    {labelInfo}
                  </Typography>
                )}
              </Container>
            )}
          </>
        }
        {...other}
      />
    </>
  )
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.func,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  partsList: PropTypes.array,
  labelInfo: PropTypes.any,
  newPartLabelInfo: PropTypes.node,
  labelText: PropTypes.string.isRequired,
  onCreateNewPartClick: PropTypes.func,
  disableBtn: PropTypes.bool,
}
