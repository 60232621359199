import JohndearBrandImage from 'assets/images/JohndearBrandImage.svg'

export const productList = [
  {
    id: '0',
    brandName: 'JCB',
    partName: 'Seal Kit',
    partNumber: '8034690',
    condition: 'New OEM',
    companyName: 'J.R. Smith Construction Company',
    city: 'Lynden',
    state: 'NJ',
    amout: '45.99',
    qty: '2',
    stock: 'In Stock',
    brandImage: JohndearBrandImage,
  },
  {
    id: '1',
    brandName: 'JCB',
    partName: 'Seal Kit',
    partNumber: '8034690',
    condition: 'New OEM',
    companyName: 'J.R. Smith Construction Company',
    city: 'Lynden',
    state: 'NJ',
    amout: '45.99',
    qty: '2',
    stock: 'In Stock',
    brandImage: JohndearBrandImage,
  },
  {
    id: '2',
    brandName: 'JCB',
    partName: 'Seal Kit',
    partNumber: '8034690',
    condition: 'New OEM',
    companyName: 'J.R. Smith Construction Company',
    city: 'Lynden',
    state: 'NJ',
    amout: '45.99',
    qty: '2',
    stock: 'Out Of Stock',
    brandImage: JohndearBrandImage,
  },
]

export const filterOption = [
  {
    label: 'Relevance',
    value: 'Relevance',
  },
  {
    label: 'A - Z',
    value: 'az',
  },
  {
    label: 'Z - A',
    value: 'za',
  },
  {
    label: 'Price: Low to High ',
    value: 'lowhigh',
  },
  {
    label: 'Price: High to Low ',
    value: 'highlow',
  },
]

export function quoteParseData(data) {
  delete data?.shipingAddressData?.company_billing_addr?.is_default
  delete data?.shipingAddressData?.company_billing_addr?.forklift_available
  delete data?.shipingAddressData?.company_billing_addr?.dock_available
  delete data?.shipingAddressData?.company_billing_addr?.delivery_instructions
  delete data?.shipingAddressData?.company_billing_addr?.unit_number
  delete data?.shipingAddressData?.company_billing_addr?.full_name
  delete data?.shipingAddressData?.company_billing_addr?.email
  delete data?.shipingAddressData?.company_billing_addr?.company_name
  delete data?.shipingAddressData?.company_billing_addr?.phone
  let companyAdd = {}
  if (data?.shipingAddressData?.company_billing_addr && data?.shipingAddressData?.company_delivery_addr) {
    companyAdd = {
      company_delivery_addr: {
        ...data.shipingAddressData?.company_delivery_addr,
        company_name: data?.shipingAddressData?.dialogDetails?.company_name,
        email: data?.shipingAddressData?.dialogDetails?.email,
        phone: data?.shipingAddressData?.dialogDetails?.phone,
        ...(data?.shipingAddressData?.dialogDetails?.full_name && {
          full_name: data?.shipingAddressData?.dialogDetails?.full_name,
        }),

        unit_number: data?.shipingAddressData?.dialogDetails?.unit_number
          ? data?.shipingAddressData?.dialogDetails?.unit_number
          : '',

        delivery_instructions: data?.shipingAddressData?.dialogDetails?.delivery_instructions
          ? data?.shipingAddressData?.dialogDetails?.delivery_instructions
          : '',

        type: data?.shipingAddressData?.dialogDetails?.type,
      },
      company_billing_addr: data?.company_billing_addr,
    }
  } else {
    companyAdd = {
      company_delivery_addr: data?.shipingAddressData?.company_billing_addr,
      company_billing_addr: data?.shipingAddressData?.company_billing_addr,
    }
  }
  const parsedData = {
    ...(data?.brand_id && { brand_id: data?.brand_id }),
    ...(data?.id && { product_id: data?.id }),
    ...(data?.brand_name?.label && { brand_name: data?.brand_name?.label }),
    ...(data?.brand_model_number && { brand_model_number: data.brand_model_number }),
    ...(data?.brand_serial_number && { brand_serial_number: data?.brand_serial_number }),
    ...(data?.brand_serial_number && { brand_serial_number: data?.brand_serial_number }),
    ...(data?.notes && { notes: data?.notes }),
    ...(data?.quantity && { quantity: data?.quantity }),
    ...(companyAdd && { company_delivery_addr: companyAdd?.company_delivery_addr }),
    ...(companyAdd && { company_billing_addr: companyAdd?.company_billing_addr }),
    private: true,
  }
  return JSON.stringify(JSON.stringify(parsedData))
  // return 0
}
