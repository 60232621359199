import React, { useEffect, useState } from 'react'
import Container from 'components/common/Container'
import { Tabs } from 'components/common/Tabs'
import supplierplaceholder from 'assets/images/supplierplaceholder.png'
import { makeStyles } from '@mui/styles'
import Link from 'components/common/Link'
import useToggle from 'common-hooks/useToggle'
import { useLocation } from 'react-router'
import { getColor } from 'theme/colors'
import useSuppliersActiveList from 'common-hooks/buyer-supplier-list/useSuppliersActiveList'
import useSuppliersReqList from 'common-hooks/buyer-supplier-list/useSuppliersReqList'
import LoadingOverlay from 'components/common/LoadingOverlay'
import QuoteRequestCard from './QuoteRequestCard'
import PreferenceDrawer from './PreferenceDrawer'
import ActiveQuoteOfferCard from './ActiveQuoteOfferCard'
import { CaretLeftIcon, CaretRightIcon } from 'assets/icons'
import Button from 'components/common/Button'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    padding: '30px',
  },
  mainContainer: {
    // background: getColor('light-main'),
    height: '94vh',
  },
  marginHead: {
    position: 'sticky',
    top: '60px',
    // background: getColor('light-main'),
    zIndex: '1',
  },

  list: {
    // height: '83%',
    // overflowY: 'scroll',

    // display: 'flex',
    // justifyContent: 'center',
    // flexDirection: 'column',
    height: '82vh',
    // padding: '0 5px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '0 5px',
    '&::-webkit-scrollbar': {
      width: '6px',
      paddingtop: '50px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
  },
  links: {
    '& a': {
      fontWeight: '400',
      color: '#2680D9',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  progress: {
    color: getColor('orange-main'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderPosition: {
    display: 'flex',
    justifyContent: 'center',
    height: '91%',
    alignItems: 'center',
  },
  iconWithLabel: {
    display: 'flex',
    gap: '0 5px',
    alignItems: 'center',
    '& p': {
      margin: '0',
    },
  },
  number: {
    width: '20px',
    height: '16px',
    borderRadius: '100px',
    background: '#2680D9',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
  },
  btnDiv: {
    maxWidth: '1920px',
    width: '800px',
    paddingTop: '20px',
    paddingBottom: '20px',
    margin: '0 auto',
    position: 'sticky',
    bottom: '0',
    left: '0',
    rigth: '0',
    background: '#F8F9F9',
    zIndex: '9',
    // padding: '0 0px 30px 0px',
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  loadingOverlay: {
    height: '74vh',
  },
}))

const SupplierQuotesList = () => {
  const classes = useStyles()
  const { activeQuotesData, pageActive, setPageActive, isLoading } = useSuppliersActiveList()
  const { reqQuoteData, setPreferenceData, isLoadingReq, setPage, page } = useSuppliersReqList()
  const supplierTabObject = [
    {
      id: '0',
      tabLabel: 'Requests',
      tabContent: 'Content 0',
    },
    {
      id: '1',
      tabContent: 'Content1',
      tabLabel: (
        <div className={classes.iconWithLabel}>
          <p>Active</p>
          <p className={classes.number}>{activeQuotesData?.length ? activeQuotesData?.length : '0'}</p>
        </div>
      ),
    },
    {
      id: '2',
      tabLabel: 'Closed',
      tabContent: 'Content 2',
    },
  ]

  const [value, setValue] = useState(0)
  const location = useLocation()

  useEffect(() => {
    if (location?.state?.active === 'active') {
      setValue(1)
    }
  }, [location])

  const [Preference, setPreference] = useToggle(false)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  console.log(value, 'value')
  console.log(location, 'location')
  return (
    <Container className={classes.mainContainer}>
      <Container width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            <Tabs value={value} onchange={handleChange} array={supplierTabObject} />
            {value === 0 && (
              <Container flex gap={'20px'}>
                <Container className={classes.links}>
                  <Link
                    to="#"
                    underline
                    onClick={() => {
                      setPreference(true)
                    }}
                  >
                    Set Preferences
                  </Link>
                </Container>
              </Container>
            )}
          </Container>
        </Container>
      </Container>
      <Container className={classes.list}>
        {value === 0 && (
          <>
            {reqQuoteData?.length === 0 && (
              <Container className={classes.placeholderPosition}>
                <img
                  src={supplierplaceholder}
                  alt="LET'S TURBOCHARGE YOUR PARTS SALES"
                  width={'314px'}
                  height={'275px'}
                />
              </Container>
            )}

            <Container>
              {isLoadingReq && (
                <Container className={classes.loadingOverlay}>
                  <LoadingOverlay />
                </Container>
              )}
              {reqQuoteData?.map((obj, index) => (
                <QuoteRequestCard
                  key={index}
                  partName={obj?.part_name}
                  createdAt={obj?.created_at}
                  quoteId={obj?.quote_id}
                  companyName={obj?.company_name}
                  brandName={obj?.brand_name}
                  partNumber={obj?.quote_part_number}
                  equipName={obj?.equip_name}
                  city={obj?.cust_addr_city}
                  country={obj?.cust_addr_country}
                  state={obj?.cust_addr_state}
                  supplierName={obj?.buyer_full_name}
                  brandImg={obj?.brand_cover_image}
                  equpImg={obj?.equip_cover_image}
                  brandModel={obj?.quote_brand_model_number}
                  brandSerial={obj?.quote_brand_serial_number}
                  inviteId={obj?.id}
                  type={obj?.type}
                  isCompany={obj?.company_is_company}
                />
              ))}
            </Container>
          </>
        )}
        {value === 2 && (
          <>
            <Container className={classes.placeholderPosition}>
              <img
                src={supplierplaceholder}
                alt="LET'S TURBOCHARGE YOUR PARTS SALES"
                width={'314px'}
                height={'275px'}
              />
            </Container>
          </>
        )}
        {value === 1 && (
          <>
            {isLoading && (
              <Container className={classes.loadingOverlay}>
                <LoadingOverlay />
              </Container>
            )}
            <Container>
              {activeQuotesData?.map((obj, index) => (
                <ActiveQuoteOfferCard
                  key={index}
                  brandImg={obj?.brand_cover_image}
                  logo={obj?.company_logo}
                  companyName={obj?.company_name}
                  equpImg={obj?.equip_cover_image}
                  partName={obj?.part_name}
                  createdAt={obj?.created_at}
                  quoteId={obj?.quote_id}
                  brandName={obj?.brand_name}
                  partNumber={obj?.quote_part_number}
                  equipName={obj?.equip_name}
                  city={obj?.buyer_addr_billing_city || obj?.buyer_addr_city}
                  state={obj?.buyer_addr_billing_state_abbrv || obj?.buyer_addr_country_abbrv}
                  country={obj?.buyer_addr_billing_country || obj?.buyer_addr_country}
                  buyername={obj?.buyer_full_name}
                  amount={obj?.amount}
                  quantity={obj?.quote_quantity}
                  reqId={obj?.id}
                  brandModel={obj?.quote_brand_model_number}
                  brandSerial={obj?.quote_brand_serial_number}
                  counterIsApproved={obj?.counter_is_approved}
                  counterOfferPrice={obj?.counter_offer_price}
                  isCompany={obj?.company_is_company}
                />
              ))}
            </Container>

            {activeQuotesData?.length === 0 && (
              <Container className={classes.placeholderPosition}>
                <img
                  src={supplierplaceholder}
                  alt="LET'S TURBOCHARGE YOUR PARTS SALES"
                  width={'314px'}
                  height={'275px'}
                />
              </Container>
            )}
          </>
        )}
        <Container display="flex" justify="space-between" width={'100%'} className={classes.btnDiv}>
          {value === 0 && (
            <>
              {' '}
              <Button
                icon={<CaretLeftIcon />}
                variant={'transparent'}
                className={classes.prevBtn}
                onClick={() => {
                  setPage((pre) => (pre > 1 ? pre - 1 : 1))
                }}
                disabled={page === 1}
              >
                Prev.
              </Button>
              <Button
                icon={<CaretRightIcon />}
                variant={'transparent'}
                className={classes.nextBtn}
                onClick={() => {
                  setPage((pre) => pre + 1)
                }}
                disabled={reqQuoteData?.length !== 14}
              >
                Next
              </Button>
            </>
          )}
          {value === 1 && (
            <>
              <Button
                icon={<CaretLeftIcon />}
                variant={'transparent'}
                className={classes.prevBtn}
                onClick={() => {
                  setPageActive((pre) => (pre > 1 ? pre - 1 : 1))
                }}
                disabled={pageActive === 1}
              >
                Prev.
              </Button>
              <Button
                icon={<CaretRightIcon />}
                variant={'transparent'}
                className={classes.nextBtn}
                onClick={() => {
                  setPageActive((pre) => pre + 1)
                }}
                disabled={activeQuotesData?.length !== 14}
              >
                Next
              </Button>
            </>
          )}
          {value === 2 && (
            <>
              <Button icon={<CaretLeftIcon />} variant={'transparent'} className={classes.prevBtn} disabled>
                Prev.
              </Button>
              <Button icon={<CaretRightIcon />} variant={'transparent'} className={classes.nextBtn} disabled>
                Next
              </Button>
            </>
          )}
        </Container>
      </Container>
      <PreferenceDrawer open={Preference} onclose={setPreference} setPreferenceData={setPreferenceData} />
    </Container>
  )
}

export default SupplierQuotesList
