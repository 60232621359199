import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Dropzone from 'components/custom/dropzone/Dropzone'
import Select from 'components/common/select/Select'
import useBrandList from 'common-hooks/create-quote/useBrandList'
import { Controller, useForm } from 'react-hook-form'
import { createOption, createOptionDialog } from 'components/common/text/DropDownTextField'
import Typography from 'components/common/Typography'
import TwoWayController from 'components/custom/TwoWayController'
import { useState, useEffect } from 'react'
import { filesAddKeys, IMAGE_MIME } from 'utils/Files'
import FileUpload from 'components/custom/dropzone/FileUpload'
import { AddIcon, CaretDownIcon, InfoSmallIcon, PencilIcon } from 'assets/icons'
import { getColor } from 'theme/colors'
import Seperator from 'components/common/Seperator'
import TextField from 'components/common/text/TextField'
import IconButton from 'components/common/IconButton'
import TextArea from 'components/common/text/TextArea'
import ShipingAddressDialog from './ShipingAddressDialog'
import useToggle from 'common-hooks/useToggle'
import useUserProfile from 'common-hooks/user-profile/useUserProfile'
import { getFlag, getWellKnownAddress } from 'utils/Utils'
import useRequestQuote from 'common-hooks/search-product/useRequestQuote'
import SuccessDialog from 'containers/supplier-quote-details/SuccessDialog'
import Tooltip from 'components/common/Tooltip'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
    maxHeight: '500px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    overflow: 'hidden',
  },
  imageContainer: {
    display: 'flex',
    gap: '0px 10px',
    flexFlow: 'wrap',

    '& img': {
      height: '70px',
      width: '70px',
      border: ' 1px solid #F0F0F4',
    },
  },
  dropZoneLogo: {
    width: '70px',
    display: 'flex',
    alignItems: 'center',
    height: '70px',
    paddingTop: '10px',
    borderColor: '#C3C5D5',
  },
  titleDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: '13px 0',
  },
  titleSubDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  confirmDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  iconBtn: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  shipingDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
    '& textarea': {
      height: '72px!important',
    },
    position: 'relative',
  },
  imagRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  exapanTermsBtn: (props) => ({
    '& svg': {
      rotate: props.product ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  textarea: {
    '& textarea': {
      height: '72px!important',
    },
  },
  flagSpan: {
    // '& img': {
    padding: '3px 5px',
    position: 'absolute',
    // },
  },
})

export default function RequestQuoteDialog({ open, onClose, searchDetails, setQuantity, quantity }) {
  const [productImages, setProductImages] = useState([])
  const [shipingAddessDialog, setShipingAddresDialog] = useToggle(false)
  const [shipingAddressData, setShipingAddressData] = useState()
  const [openDialog, setOpenDialog] = useToggle(false)
  const [successLinks, setSuccessLinks] = useState([])
  const [product, toggleProduct] = useToggle(true)
  const { brandList } = useBrandList()
  const option = brandList?.map((obj) => createOptionDialog(obj.id, obj.name, obj?.cover_image))
  const { profileData } = useUserProfile()
  const classes = useStyles({ product })
  const { control, handleSubmit, setValue } = useForm()

  const handleProductImages = (name, newFiles) => {
    const filesArr = filesAddKeys(newFiles)
    setProductImages([...productImages, ...filesArr])
  }
  const removeImage = (assetId) => {
    const filesArr = productImages?.filter((i) => i.id !== assetId)
    setProductImages(filesArr)
  }
  const handleQty = (e) => {
    setQuantity(e)
  }

  const { createProductQuote } = useRequestQuote()

  const onSubmit = async (data) => {
    const response = await createProductQuote({ ...data, quantity, shipingAddressData, productImages })
    setTimeout(() => {
      setSuccessLinks([...response])
      setOpenDialog(true)
      onClose()
    }, 1000)
  }

  useEffect(() => {
    if (searchDetails) {
      Object.keys(searchDetails).map((key) => {
        if (key === 'brand_name') {
          setValue(key, createOption(searchDetails?.brand_id, searchDetails?.brand_name))
        } else {
          setValue(key, searchDetails[key])
        }
        return 0
      })
    }
  }, [searchDetails])

  useEffect(() => {
    if (profileData) {
      setShipingAddressData({ company_billing_addr: profileData?.user_address })
    }
  }, [profileData])
  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Request Quote'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.dialogbody}>
              <Container className={classes.titleDiv}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  Part
                </Typography>
                <Container className={classes.titleSubDiv}>
                  <Container flex justify="space-between">
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      {searchDetails?.brand_name} - {searchDetails?.part_desp} - {searchDetails?.part_no}
                    </Typography>
                    {/* <TwoWayController value={quantity} onChange={() => setQuantity(quantity + 1)} /> */}
                    <TwoWayController defalutValue={quantity} onChange={(e) => handleQty(e)} />
                  </Container>
                </Container>
              </Container>
              <Seperator />
              <Container className={classes.shipingDiv}>
                <Container flex justify="space-between">
                  <Container flex justify="flex-start" gap="0 5px">
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Shipping
                    </Typography>
                    {/* <Tooltip title="'Once you create a quote you will not able to change the address, Provide proper shipping address.'">
                      <InfoSmallIcon />
                    </Tooltip> */}
                  </Container>
                  <IconButton className={classes.iconBtn} onClick={setShipingAddresDialog}>
                    <PencilIcon />
                  </IconButton>
                </Container>
                <Typography variant="paragraph1" color={getColor('grey-g35')}>
                  {/* {shipingAddressData?.company_delivery_addr?.addr_line_1}
                  {shipingAddressData?.company_delivery_addr?.addr_line_2}
                  {shipingAddressData?.company_delivery_addr?.city}
                  {shipingAddressData?.company_delivery_addr?.state}
                  {shipingAddressData?.company_delivery_addr?.zipcode} */}

                  {shipingAddressData?.company_billing_addr ? (
                    <>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        {getWellKnownAddress(
                          shipingAddressData?.company_billing_addr?.addr_line_1,
                          shipingAddressData?.company_billing_addr?.addr_line_2
                        )}
                        <span className={classes.flagSpan}>
                          {getFlag(
                            shipingAddressData?.company_billing_addr?.country || profileData?.user_address?.country
                          )}
                        </span>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                        {getWellKnownAddress(
                          profileData?.user_address?.addr_line_1,
                          profileData?.user_address?.addr_line_2
                        )}
                        <span className={classes.flagSpan}>
                          {getFlag(
                            profileData?.user_address?.country || shipingAddressData?.company_billing_addr?.country
                          )}
                        </span>
                      </Typography>
                    </>
                  )}
                </Typography>
              </Container>
              <Seperator />
              <Container className={classes.confirmDiv}>
                <Container flex justify="space-between">
                  <Typography variant="title" color={getColor('grey-g25')} component="p">
                    More Details
                  </Typography>
                  <Button
                    variant="secondary"
                    className={classes.exapanTermsBtn}
                    onClick={() => toggleProduct(true)}
                    icon={<CaretDownIcon />}
                  />
                </Container>
                {product && (
                  <>
                    <Container className={classes.imagRoot}>
                      <Container flex justify="flex-start">
                        <Typography variant="body1" color={getColor('grey-g35')}>
                          Images
                        </Typography>
                        <Tooltip
                          title={'Upload up to 3 images. Supported file formate are .jpg and .png, max size 2mb. '}
                        >
                          <InfoSmallIcon />
                        </Tooltip>
                      </Container>
                      <Container className={classes.imageContainer}>
                        {productImages?.map((file, index) => {
                          const props = {
                            url: file.file ? URL.createObjectURL(file.file) : file?.path,
                            index,
                            assetId: file.id,
                            indexImage: 0,
                            disableDropdown: true,
                            deleteAddImage: true,
                          }
                          return (
                            <Container key={index}>
                              <FileUpload
                                key={index}
                                height={'70px'}
                                width={'70px'}
                                imgHeight={'70px'}
                                imgwidth={'70px'}
                                removeImage={removeImage}
                                {...props}
                              />
                            </Container>
                          )
                        })}

                        <Dropzone
                          id={'drop-zone-logo'}
                          dropzoneClassName={classes.dropZoneLogo}
                          multiple
                          accept={IMAGE_MIME}
                          onChange={(e) => handleProductImages('productImage', e)}
                          actionComponent={<AddIcon />}
                        />
                      </Container>
                    </Container>
                    <Container width={'100%'}>
                      <Controller
                        name={'brand_name'}
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                          <Select
                            options={option}
                            id={name}
                            value={value}
                            name={name}
                            onChange={onChange}
                            label={'Brand'}
                            menuPosition={'fixed'}
                          />
                        )}
                      />
                    </Container>
                    <Container flex justify="space-between" gap={'0 15px'}>
                      <Controller
                        name={'brand_model_number'}
                        control={control}
                        render={({ field: { name, onChange } }) => (
                          <TextField
                            id={name}
                            name={name}
                            onChange={onChange}
                            label={'Model Number'}
                            placeholder="ex. 644K"
                          />
                        )}
                      />
                      <Controller
                        name={'brand_serial_number'}
                        control={control}
                        render={({ field: { name, onChange } }) => (
                          <TextField
                            id={name}
                            name={name}
                            onChange={onChange}
                            label={'Serial Number'}
                            placeholder="ex. 5883729272"
                          />
                        )}
                      />
                    </Container>
                    <Container className={classes.textarea}>
                      <Typography variant="paragraph2" color={getColor('grey-g25')}>
                        Notes
                      </Typography>
                      <Controller
                        name={'notes'}
                        control={control}
                        render={({ field: { name, onChange } }) => (
                          <TextArea
                            id={name}
                            name={name}
                            onChange={onChange}
                            placeholder="i.e., sizes, materials, engravings, ID numbers, pressure ratings, voltage, etc."
                          />
                        )}
                      />
                    </Container>
                  </>
                )}
              </Container>
            </Container>

            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">Send Request</Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
        <ShipingAddressDialog
          open={shipingAddessDialog}
          onClose={setShipingAddresDialog}
          profileData={profileData}
          setShipingAddressData={setShipingAddressData}
        />
        <SuccessDialog open={openDialog} onClose={setOpenDialog} succesLink={successLinks} />
      </Container>
    </>
  )
}
RequestQuoteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  searchDetails: PropTypes.object,
  setQuantity: PropTypes.func,
  quantity: PropTypes.func,
}
