// import { getSupplierActiveList } from 'common-api/buyer-supplier/buyerSupplier'
// import useSWR from 'swr'

// export default function useSuppliersActiveList() {
//   const status = 'active'
//   const { data, mutate } = useSWR(() => `/quote-request/list?status=${status}`, getSupplierActiveList)
//   return {
//     activeQuotesData: data,
//     mutate,
//     isLoading: !data,
//   }
// }

import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import { useState } from 'react'
import { getSupplierActiveList, postSupplier } from 'common-api/buyer-supplier/buyerSupplier'
import useToastify from 'common-hooks/useToastify'

export default function useSuppliersActiveList() {
  const { toastMessage } = useToastify()
  const status = 'active'

  const PAGE_SIZE = 14
  const [pageActive, setPageActive] = useState(1)
  const params = {
    ...(status && { status }),
    limit: PAGE_SIZE,
    page: pageActive,
  }

  const { data, mutate } = useSwrInfinite('/quote-request/list', params, getSupplierActiveList)
  if (data && data.length === 0 && pageActive > 1) setPageActive((pre) => pre - 1)

  const nudgeMailSupplier = async (data) => {
    try {
      const response = await postSupplier(data)
      toastMessage('success', 'Customer reminded of your quote response')
      return response
    } catch {
      return false
    }
  }
  return {
    activeQuotesData: data,
    mutate,
    nudgeMailSupplier,
    isLoading: !data,
    pageActive,
    setPageActive,
  }
}
