import Container from 'components/common/Container'
import React from 'react'
import Typography from 'components/common/Typography'
import SearchField from 'components/common/search/SearchField'
import { makeStyles } from '@mui/styles'
import { CircularProgress } from '@mui/material'
import { CaretLeftIcon, CaretRightIcon } from 'assets/icons'
import Button from 'components/common/Button'
// import { supplierListLimit } from 'utils/constants'
import LandingPageFooter from './LandingPageFooter'
import LandingPageHeader from './LandingPageHeader'
import './css/landingpage.css'
import './css/responsive.css'
import SupplierCard from './SupplierCard'

import HtmlTitle from 'utils/HtmlTitle'
import useLandingPageSupplierList from 'common-hooks/super-admin/useLandingPageSupplierList'

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '20%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
}))

export default function SupplierList() {
  const classes = useStyles()

  const { supplierList, search, onSearch, isLoading, page, setPage } = useLandingPageSupplierList()

  return (
    <>
      <Container className="supplier-main">
        <HtmlTitle title={'Suppliers'} />
        <Container className="header-root">
          <Container className="container">
            <LandingPageHeader />
          </Container>
        </Container>
        <Container className="supplier-body">
          <Container className="supplier-search">
            <Container className="container">
              <Container className="supplier-search-inner">
                <Typography component="h3">Suppliers</Typography>
                <Container className="search-filed">
                  <SearchField
                    id={'searchField'}
                    placeholder={'Search suppliers'}
                    onSearch={(e) => {
                      onSearch(e)
                    }}
                    value={search}
                  />
                </Container>
              </Container>
            </Container>
          </Container>
          <Container className="supplier-grid-sec">
            <Container className="container">
              <Container className="supplier-grid">
                {isLoading && <CircularProgress color="secondary" size={40} className={classes.progress} />}
                <SupplierCard supplierList={supplierList} />
                {supplierList?.length === 0 && search !== '' && <Typography>No Search Data Found</Typography>}
                {supplierList?.length === 0 && search === '' && <Typography>No Data Available</Typography>}
              </Container>
              <Container className="pagination-bottom pagination-btn">
                {/* <Pagination count={20} variant="outlined" shape="rounded" /> */}
                <Button
                  icon={<CaretLeftIcon />}
                  variant={'transparent'}
                  className={classes.prevBtn}
                  onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
                  disabled={page === 1}
                >
                  Prev.
                </Button>
                <Button
                  icon={<CaretRightIcon />}
                  variant={'transparent'}
                  className={classes.nextBtn}
                  onClick={() => setPage((pre) => pre + 1)}
                  // disabled={supplierList?.length < supplierListLimit}
                >
                  Next
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
        <LandingPageFooter />
      </Container>
    </>
  )
}
