import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import Checkbox from 'components/common/Checkbox'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import Button from 'components/common/Button'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px 0',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  bodyInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
  },
  subTextLine: {
    color: getColor('grey-g65'),
  },
})

export default function AddShipOptionDialog({ open, onClose }) {
  const classes = useStyles()
  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Shipping Options'} onClose={onClose} />
          <Container className={classes.dialogbody}>
            <Checkbox label="Ground" />
            <Checkbox label="2nd Day" />
            <Checkbox label="Next Day" />
            <Checkbox label="Local Delivery" />
            <Checkbox label="LTL" />
            <Checkbox label="Pickup (Free)" />
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button width={72}>Add</Button>
            </Container>
          </DialogFooter>
        </Dialog>
      </Container>
    </>
  )
}
AddShipOptionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
