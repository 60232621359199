import { getIndustryData } from 'common-api/authantication/auth'
import useSWR from 'swr'

export default function useIndustryList() {
  const { data } = useSWR('/services/industry', getIndustryData)
  return {
    industryData: data,
    isLoading: !data,
  }
}
