import { makeStyles } from '@mui/styles'
import {
  CaretDownIcon,
  ReturnsIcon,
  ShareIcon,
  ShippingIcon,
  UntilShipped,
  Verfied,
  VerifiedIcon,
  Warranty,
} from 'assets/icons'
import SampleImage1 from 'assets/images/PartsSampleImage-1.png'
import SampleImage2 from 'assets/images/PartsSampleImage-2.png'
import SampleImage3 from 'assets/images/PartsSampleImage-3.png'
import JohndearBrandImage from 'assets/images/JohndearBrandImage.svg'
import useToggle from 'common-hooks/useToggle'
import TwoWayController from 'components/custom/TwoWayController'
import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Link from 'components/common/Link'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import TitleList from 'components/custom/list/TitleList'
import { getColor } from 'theme/colors'
import { formatPrice, getFlag, getWellKnownAddress, setToLocalStorage } from 'utils/Utils'
import ShareProductDialog from 'containers/product-search/ShareProductDialog'
import LandingPageHeader from './LandingPageHeader'
import HtmlTitle from 'utils/HtmlTitle'
import LandingPageFooter from './LandingPageFooter'

import { useState } from 'react'
import useProductDetails from 'common-hooks/inventory/useProductDetails'
import LoginDialog from 'containers/authentication/LoginDialog'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { parseCrossRefNumbers, parseFitsWith } from 'containers/inventory/InventoryUtils'
import ProductSlider from './ProductSlider'
import { constants } from 'utils/constants'
import { useNavigate } from 'react-router-dom'

const useStyle = makeStyles({
  root: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    // padding: '30px 30px 20px 30px',
    // padding: '0 30px',
    gap: '0 30px',
    // borderTop: '1px solid #F0F0F4',
  },
  coverImag: {
    height: '300px',
    width: '100%',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
  },
  imagesDiv: {
    // margin: '0 30px 30px 30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '300px',
  },
  otherImg: {
    width: '78px',
    height: '78px',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
  },
  stockChip: {
    background: '#0DA50D',
    width: 'auto',
    height: '28px',
    borderRadius: '100px',

    alignItems: 'center',
    '& span': {
      color: '#fff',
      fontSize: '12px',
      fontWeight: 400,
    },
  },
  shareBtn: {
    background: 'transparent',
    color: getColor('grey-g25'),
    '& svg': {
      marginRight: '5px',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  titleDiv: {
    marginBottom: '20px',
  },
  detailsDiv: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    gap: '20px',
  },
  aboutDiv: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  optionsDiv: {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    justifyContent: 'flex-start',
    gap: '15px',
    // justifyContent: 'space-between',
  },
  fitsDiv: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  exapanBtn: (props) => ({
    '& svg': {
      rotate: props.delivery ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  fitsDetailDiv: {
    marginBottom: '15px',
    display: 'flex',
    gap: '5px ',
    flexDirection: 'column',
    '& h4': {
      width: '122px',
    },
  },
  fitsDetailDiv2: {
    marginBottom: '5px',
    display: 'flex',
    gap: '20px',
    '& h4': {
      minWidth: '122px',
    },
  },
  fitsUl: {
    margin: '0',
    padding: '0',
    paddingLeft: '25px',
  },
  crossBtn: (props) => ({
    '& svg': {
      rotate: props.crossRef ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  titleList: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  priceBox: {
    background: getColor('system-bg'),
    padding: '20px',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
    height: '335px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  msrpTxt: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    textDecorationLine: 'line-through',
    color: '#9799B5',
  },
  mrpTxt: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '38px',
    color: '#13141B',
  },
  link: {
    fontSize: '12px',
    fontWeight: 400,
    color: `${getColor('func-info')} !important`,
    textDecoration: 'underLine',
  },
  sendBtn: {
    width: '228px',
    height: '36px',
    // border: `1px solid ${getColor('grey-g65')}`,
    borderRadius: '4px',
    // background: 'transparent',
    // color: getColor('grey-g65'),
    // '&:hover': {
    // background: 'transparent',
    // },
  },
  soldDiv: {
    position: 'relative',
  },
  soldLink: {
    '&:link': {
      lineHeight: '5px',
      textDecoration: 'underLine',
    },
    '& svg': {
      height: '18px',
      width: '18px',
      position: 'absolute',
      bottom: '22px',
    },
  },
  reletedProdDiv: {
    background: '#fff',
    padding: '30px',
  },
  reletedProdDetails: {
    padding: '10px',
  },
  reletedProd: {
    border: `1px solid ${getColor('grey-g95')}`,
    width: '238px',
    marginTop: '20px',
  },
  reletedProdImage: {
    borderBottom: `1px solid ${getColor('grey-g95')}`,
  },
  rootDetail: {
    '& p': {
      marginBottom: '20px',
    },
  },
  modelRoot: {
    display: 'flex',
    gap: '0 20px',
  },
  partRoot: {
    display: 'flex',
  },
  outStockChip: {
    background: '#AD1F36',
    height: '28px',
    borderRadius: '100px',

    alignItems: 'center',
    '& span': {
      color: '#fff',
      fontSize: '12px',
      fontWeight: 400,
    },
  },
})

export default function PartDetailsPage() {
  const [share, toggleShare] = useToggle(false)
  const [delivery, toggleDelivery] = useToggle(true)
  const [crossRef, toggleCrossRef] = useToggle(true)
  // const [requestQuoteDialog, setRequestQuoteDialog] = useToggle(false)
  // const [offerDialog, setOfferDialog] = useToggle(false)
  const [openDialog, setOpenDialog] = useToggle(false)
  // const [buyNowBtnDisabled, setBuyNowBtnDisabled] = useState(true)
  const classes = useStyle({ delivery, crossRef })
  const navigate = useNavigate()

  const { productDetails, isLoading } = useProductDetails()

  // const sampleImages = [SampleImage1, SampleImage2, SampleImage3, SampleImage1, SampleImage2, SampleImage3]
  const tempProductArray = [productDetails?.brand_cover || JohndearBrandImage, SampleImage1, SampleImage2, SampleImage3]

  if (productDetails?.prod_assets?.length < 4) {
    productDetails?.prod_assets?.map((obj) => tempProductArray?.splice(0, 0, obj.path))
  }

  const imgArray = [productDetails?.brand_cover || JohndearBrandImage]

  // const sampleImages =
  //   searchDetails && searchDetails?.prod_assets?.length > 3
  //     ? searchDetails?.prod_assets?.map((obj) => obj?.path)
  //     : tempProductArray

  if (productDetails && productDetails?.prod_assets?.length > 3) {
    productDetails?.prod_assets?.map((obj) => imgArray.push(obj?.path))
  }
  const sampleImages = productDetails && productDetails?.prod_assets?.length > 3 ? imgArray : tempProductArray

  const [cuurentIndex, setCurrentIndex] = useState(0)

  const url = sampleImages && sampleImages?.filter((d, index) => index === cuurentIndex)

  const fitsWithData = productDetails && parseFitsWith(productDetails)
  const crossRefData = productDetails && parseCrossRefNumbers(productDetails)
  const productName = `${productDetails?.brand_name} - ${productDetails?.part_desp} - ${productDetails?.part_no}`
    .replace('null', '')
    .replace('undefined', '')

  // useEffect(() => {
  //   if (
  //     Number(productDetails?.weight) > 0 &&
  //     Number(productDetails?.unit_price) > 0 &&
  //     Number(productDetails?.qty) > 0
  //   ) {
  //     setBuyNowBtnDisabled(false)
  //   } else {
  //     setBuyNowBtnDisabled(true)
  //   }
  // }, [productDetails])

  return (
    <Container className={classes.rootDetail}>
      <HtmlTitle title={'Parts-Details'} />
      <Container className="container">
        <LandingPageHeader />
      </Container>

      <Container className="p-detail-page">
        <Container className="container">
          <Container className="p90 ">
            {isLoading && <LoadingOverlay />}
            <Container className="breadCrubms-partdetail">
              <Typography variant="caption" color={getColor('grey-g35')} component="p">
                <Container onClick={() => navigate('/parts')}>Parts </Container>
                {'>'} {productDetails?.brand_name} - {productDetails?.part_desp}{' '}
                {productDetails?.part_no ? `- ${productDetails?.part_no}` : ''}{' '}
              </Typography>
            </Container>
            <Container className={classes.root}>
              <Container className={classes.imagesDiv}>
                <Container>
                  <Image src={url[0]} className={classes.coverImag} />
                </Container>
                <Container className="product-slider">
                  <ProductSlider sliderData={sampleImages} setCurrentIndex={setCurrentIndex} />
                  <Typography variant="overline" color={getColor('grey-g80')} component="p">
                    *Logos are used for reference only. All trademarks, logos and brand names are the property of their
                    respective owners.
                  </Typography>
                </Container>
              </Container>

              <Container width={'610px'}>
                <Container className="detailpage-title">
                  <Typography variant="h1" color="#13141B">
                    {productDetails?.brand_name} - {productDetails?.part_desp}{' '}
                    {productDetails?.part_no ? `- ${productDetails?.part_no}` : ''}
                  </Typography>
                  <Container display="flex" justify="space-between" className={classes.titleDiv}>
                    {productDetails?.stock ? (
                      <Chip label="In Stock" className={classes.stockChip} />
                    ) : (
                      <Chip label="Out Of Stock" variant="error" className={classes.outStockChip} />
                    )}

                    <Button icon={<ShareIcon />} className={classes.shareBtn} onClick={toggleShare}>
                      Share
                    </Button>
                  </Container>
                </Container>
                <Seperator />
                <Container className={classes.detailsDiv}>
                  <Container width={'220px'} display={'flex'} direction={'column'} gap={'5px'}>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Brand:{' '}
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Part Description:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Part Number:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Condition:
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g35')}>
                      Weight:
                    </Typography>
                  </Container>

                  <Container width={'100%'} display={'flex'} direction={'column'} gap={'5px'}>
                    <Typography variant="body1" color={getColor('grey-g25')}>
                      {productDetails?.brand_name ? productDetails?.brand_name : '-'}{' '}
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g25')}>
                      {productDetails?.part_desp ? productDetails?.part_desp : '-'}
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g25')}>
                      {productDetails?.part_no ? `${productDetails?.part_no}` : '-'}
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g25')}>
                      {productDetails?.condition === 'new_oem' && 'New OEM'}{' '}
                      {productDetails?.condition === 'new_aftermarket' && 'New Aftermarket'}{' '}
                      {productDetails?.condition === 'used' && 'Used'}
                      {productDetails?.condition === null && '-'}
                    </Typography>
                    <Typography variant="body1" color={getColor('grey-g25')}>
                      {productDetails?.weight ? `${productDetails?.weight} lbs` : '-'}{' '}
                    </Typography>
                  </Container>
                </Container>
                <Seperator />
                <Container className={classes.aboutDiv}>
                  <Typography variant="title" color="#13141B">
                    {' '}
                    About this product
                  </Typography>
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    {productDetails?.desp || '-'}
                  </Typography>
                </Container>
                <Seperator />
                <Container className={classes.optionsDiv}>
                  {productDetails?.company_is_verified && (
                    <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                      <VerifiedIcon />
                      <Typography color={getColor('grey-g35')} variant="caption">
                        Verified suppliers
                      </Typography>
                    </Container>
                  )}

                  <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                    <ShippingIcon />
                    <Typography color={getColor('grey-g35')} variant="caption">
                      Fast shipping
                    </Typography>
                  </Container>

                  {productDetails?.is_returnable && (
                    <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                      <ReturnsIcon />
                      <Typography color={getColor('grey-g35')} variant="caption">
                        30-days return
                      </Typography>
                    </Container>
                  )}
                  {productDetails?.has_warranty && (
                    <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                      <Warranty />
                      <Typography color={getColor('grey-g35')} variant="caption">
                        365-days warranty
                      </Typography>
                    </Container>
                  )}
                  {productDetails?.is_cancellable && (
                    <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                      <UntilShipped />
                      <Typography color={getColor('grey-g35')} variant="caption">
                        Cancellable until shipped
                      </Typography>
                    </Container>
                  )}
                </Container>
                {fitsWithData?.length > 0 && (
                  <>
                    <Seperator />
                    <Container className={classes.fitsDiv}>
                      <Container flex justify="space-between">
                        <TitleList title={'Fits with'} className={classes.titleList} />
                        <Button
                          variant="secondary"
                          className={classes.exapanBtn}
                          onClick={() => toggleDelivery(true)}
                          icon={<CaretDownIcon />}
                        />
                      </Container>
                      {delivery && (
                        <Container>
                          <ul className={classes.fitsUl}>
                            {fitsWithData?.map((obj, index) => (
                              <li key={index}>
                                <Typography variant="title1" color={getColor('grey-g25')}>
                                  {obj?.brand_name}
                                </Typography>
                                <Container className={classes.fitsDetailDiv}>
                                  {obj?.modelNumbers?.map((md, index) => (
                                    <Container className={classes.modelRoot} key={index}>
                                      <Typography variant="body1" color={getColor('grey-g35')} component="h4">
                                        {md?.model_no}
                                      </Typography>

                                      <Typography variant="body1" color={getColor('grey-g35')} component="p">
                                        {md?.serial_rang_from} - {md?.serial_rang_to}
                                      </Typography>
                                    </Container>
                                  ))}
                                </Container>
                              </li>
                            ))}
                          </ul>
                        </Container>
                      )}
                    </Container>
                  </>
                )}

                {crossRefData?.length > 0 && (
                  <>
                    <Seperator />
                    <Container className={classes.fitsDiv}>
                      <Container flex justify="space-between">
                        <TitleList title={'Cross-References'} className={classes.titleList} />
                        <Button
                          variant="secondary"
                          className={classes.crossBtn}
                          onClick={() => toggleCrossRef(true)}
                          icon={<CaretDownIcon />}
                        />
                      </Container>
                      {crossRef && (
                        <Container>
                          {crossRefData?.map((obj, index) => (
                            <Container className={classes.fitsDetailDiv2} key={index}>
                              <Typography variant="body1" color={getColor('grey-g35')} component="h4">
                                {obj?.brand_name}
                              </Typography>

                              <Container className={classes.partRoot}>
                                {obj?.partnumber?.map((d, idx) => (
                                  <>
                                    <Typography key={index} variant="body1" color={getColor('grey-g35')}>
                                      {d?.value}
                                    </Typography>
                                    {idx !== obj?.partnumber?.length - 1 && <>,&nbsp;</>}
                                  </>
                                ))}
                              </Container>
                            </Container>
                          ))}
                        </Container>
                      )}
                    </Container>
                  </>
                )}
              </Container>

              <Container width={'268px'} className={classes.priceBox}>
                <Container display="flex" direction="column">
                  {Number(productDetails?.unit_price) !== 0 ? (
                    <>
                      <Typography className={classes.msrpTxt}>MSRP: {formatPrice(productDetails?.msrp)}</Typography>
                      <Typography className={classes.mrpTxt}> {formatPrice(productDetails?.unit_price)}</Typography>
                    </>
                  ) : (
                    <Typography variant="paragraph1" color="#9799B5" component="p">
                      <Typography variant="paragraph1" color="#2680D9">
                        Get pricing
                      </Typography>{' '}
                      for this product
                    </Typography>
                  )}
                </Container>

                {Number(productDetails?.unit_price) !== 0 ? (
                  <Typography variant="body2" color={getColor('grey-g65')}>
                    Save {formatPrice(productDetails?.unit_price * Number(1 + 0.05) - productDetails?.unit_price)}{' '}
                    instantly by upgrading to{' '}
                    <Link linkClassName={classes.link} to={'#'}>
                      PartsClub+
                    </Link>
                  </Typography>
                ) : (
                  ''
                )}
                {productDetails?.qty > 0 && (
                  <Typography variant="body1" color={'#2D9613'}>
                    Only {productDetails?.qty} left in stock
                  </Typography>
                )}
                {productDetails?.available_in !== null &&
                  productDetails?.available_in !== '0' &&
                  !productDetails?.qty > 0 && (
                    <Typography variant="body1" color={'#AD1F36'}>
                      Available in {productDetails?.available_in} days
                    </Typography>
                  )}
                <Container flex justify="space-between">
                  <Typography variant="body2" color={getColor('grey-g35')}>
                    Quantity:
                  </Typography>
                  <TwoWayController defalutValue={1} />
                </Container>
                <Button
                  onClick={() => {
                    setToLocalStorage(constants.UNAUTHORIZEDPARTID, JSON.stringify(productDetails?.id))
                    setToLocalStorage(constants.UNAUTHORIZEDPARTNAME, JSON.stringify(productName))
                    setOpenDialog()
                  }}
                  // disabled={buyNowBtnDisabled}
                >
                  Get My Price
                </Button>
                {/* <Button className={classes.sendBtn} variant="secondary" border onClick={setOpenDialog}>
                  Make Offer
                </Button> */}
                <Container display="flex" direction="column" width={'228px'} className={classes.soldDiv}>
                  <Typography variant="body2" color={getColor('grey-g35')}>
                    Sold by:
                  </Typography>
                  <Container className={classes.soldLink}>
                    <Link linkClassName={classes.link} to={'#'}>
                      {productDetails?.company_name}
                    </Link>
                    {productDetails?.company_is_verified && <Verfied />}
                    <Container display="flex" alignItems="center" gap="3px">
                      <Typography variant="paragraph1" color={getColor('grey-g35')}>
                        {getWellKnownAddress(productDetails?.company_city, productDetails?.company_state)}
                        {/* {productDetails?.company_city}, {productDetails?.company_country_abbrv} */}
                      </Typography>
                      {getFlag(productDetails?.company_country)}
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
            {productDetails?.related_prods?.length > 0 && (
              <>
                <Seperator />
                <Container className={classes.reletedProdDiv}>
                  <TitleList title={'Related Products'} />
                  <Container display="flex" gap="30px">
                    {productDetails?.related_prods?.map((obj, index) => (
                      <Container display="flex" direction="column" className={classes.reletedProd} key={index}>
                        <Container>
                          <Image
                            src={obj?.cover_image || SampleImage2}
                            width={'238px'}
                            height={'150px'}
                            className={classes.reletedProdImage}
                          />
                        </Container>
                        <Container display="flex" direction="column" className={classes.reletedProdDetails}>
                          <Typography variant="body1" color={getColor('grey-g25')}>
                            {obj?.product_title}
                          </Typography>
                          <Typography variant="paragraph1" color={getColor('grey-g35')}>
                            {obj?.product_condition === 'new_oem' && 'New OEM'}{' '}
                            {obj?.product_condition === 'new_aftermarket' && 'New Aftermarket'}{' '}
                            {obj?.product_condition === 'used' && 'Used'}
                          </Typography>
                        </Container>
                        <Container className={classes.reletedProdDetails}>
                          <Typography variant="title1" color={getColor('grey-g25')}>
                            {formatPrice(obj?.product_unit_price)}
                          </Typography>
                        </Container>
                      </Container>
                    ))}
                  </Container>
                </Container>
              </>
            )}
          </Container>
        </Container>
      </Container>

      <LandingPageFooter />
      <ShareProductDialog open={share} onClose={toggleShare} />
      <LoginDialog open={openDialog} onClose={setOpenDialog} />
    </Container>
  )
}
