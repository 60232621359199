import { ActionDeleteIcon, DeletePlainWhite } from 'assets/icons'
import classNames from 'classnames'
import { getColor } from 'theme/colors'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'
import File from 'components/custom/dropzone/File'

const useStyle = makeStyles((theme) => ({
  root: {
    marginRight: '0',
  },
  hoverIcon: {
    position: 'absolute',
    bottom: '-3px',
    right: '-3px',
    visibility: 'hidden',
  },
  deleteIcon: {
    position: 'absolute',
    background: '#13141B',
    opacity: '0.6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'hidden',
    '&:hover button': {
      backgroundColor: 'unset',
    },
    '&:svg': {
      height: '30px',
      width: '30px',
    },
  },
  image: {
    cursor: 'pointer',
    width: (props) => (props.imgWidth ? props.imgWidth : ''),
    height: (props) => (props.imgHeight ? props.height : ''),
  },
  delIcon: {
    '&:svg': {
      height: '30px',
      width: '30px',
    },
  },
  textCover: {
    width: (props) => props.width || 86,
    height: (props) => (props.height ? props.height : 86),
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: theme.spacing(0),
    border: `1px solid ${getColor('grey-g95')}`,
    verticalAlign: 'bottom',
    display: 'block',
    '&:hover $hoverIcon': {
      visibility: 'visible',
      backgroundColor: 'unset',
      boxShadow: 'none',
    },
    '&:hover $deleteIcon': {
      visibility: 'visible',
      // backgroundColor: 'unset',
      boxShadow: 'none',
      '&:svg': {
        height: '30px',
        width: '30px',
      },
    },
  },
  coverImage: (props) => ({
    [`&:nth-child(${props.indexImage + 1})`]: {
      bottom: 0,
      '&::after': {
        content: '"Cover"',
        textAlign: 'center',
        height: 24,
        position: 'absolute',
        background: 'rgba(0,0,0,0.6)',
        width: '100%',
        bottom: 0,
        left: 0,
        color: getColor('light'),
        opacity: 1,
        fontSize: 12,
        fontWeight: 700,
      },
    },
  }),
}))

export default function FileUpload({
  index,
  url,
  assetId,
  indexImage = 0,
  onClick,
  deleteAddImage,
  removeImage,
  mainIndex,
  width,
  height,
  coverActiveImage,
  quoteImage,
  className,
  imgHeight,
  imgWidth,
  name,
}) {
  const classes = useStyle({ indexImage, width, height, imgHeight, imgWidth })

  // Not remove this commit for now
  //   const { onUploadCoverImage } = useAddProduct()
  //   const { deleteAssets } = useProductAssetDelete()

  //   const handleCover = (id) => {
  //     onUploadCoverImage(id)
  //   }

  //   const handleApiDelete = (id) => {
  //     const assetsId = [id]
  //     deleteAssets(assetsId)
  //   }

  return (
    <Container className={className || classes.root}>
      <Container className={classNames(url === coverActiveImage ? classes.coverImage : classes.textCover)} key={index}>
        <File
          key={index}
          src={url}
          alt={`Attachment ${index}`}
          aspectWidth={4}
          aspectHeight={3}
          className={classes.image}
          onClick={onClick}
        />
        {deleteAddImage && (
          <IconButton
            className={classes.hoverIcon}
            onClick={() => removeImage(assetId, name, index, mainIndex)}
            variant="transparent"
          >
            <ActionDeleteIcon />
          </IconButton>
        )}
        {quoteImage && (
          <Container className={classes.deleteIcon}>
            <IconButton
              onClick={() => removeImage(assetId, name, index, mainIndex)}
              className={classes.delIcon}
              variant="transparent"
            >
              <DeletePlainWhite />
            </IconButton>
          </Container>
        )}
      </Container>
    </Container>
  )
}

FileUpload.propTypes = {
  assetId: PropTypes.string,
  index: PropTypes.number,
  url: PropTypes.string,
  indexImage: PropTypes.number,
  onClick: PropTypes.func,
  deleteAddImage: PropTypes.bool,
  removeImage: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  coverActiveImage: PropTypes.number,
  name: PropTypes.string,
  mainIndex: PropTypes.number,
  quoteImage: PropTypes.bool,
  className: PropTypes.string,
  imgWidth: PropTypes.string,
  imgHeight: PropTypes.string,
}
