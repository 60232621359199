import { makeStyles } from '@mui/styles'
import DownloadIcon from 'assets/icons/downloadIcon.svg'

import useSubscriptionPaymentHistory from 'common-hooks/settings/subscription/useSubscriptionPaymentHistory'
import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'
import Image from 'components/common/Image'
import { Table } from 'components/common/Table'
import { useEffect, useState, React } from 'react'
import { getColor } from 'theme/colors'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import { formatPrice } from 'utils/Utils'
import { cols } from './SubscriptionUtils'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  list: {
    // marginTop: '20px',
    maxHeight: '360px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
      paddingtop: '50px',
      backgroundColor: '#F0F0F4',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
    '& table': {
      '& th': {
        background: '#fff',
      },
      '& tr': {
        cursor: 'pointer',
      },
    },
  },
})

const PlanHistoryTable = ({ paymentHistory }) => {
  const classes = useStyles()
  const [activeRows, setActiveRows] = useState([])

  function createData(Date, Invoice, Amount, Status, Image) {
    return { Date, Invoice, Amount, Status, Image }
  }

  const { downloadInvoice, invoiceUrl } = useSubscriptionPaymentHistory()
  useEffect(() => {
    if (paymentHistory !== undefined && paymentHistory?.invoice_history?.length > 0) {
      setActiveRows(
        paymentHistory?.invoice_history?.map((obj) =>
          createData(
            formatDate(obj?.date, DATE_FORMAT_PRIMARY),
            obj?.description,
            formatPrice(obj?.amount),
            obj?.status === 'succeeded' ? 'Paid' : 'Cancelled',
            <IconButton style={{ backgroundColor: 'unset' }} onClick={() => downloadInvoice(obj?.invoice_id)}>
              <a href={invoiceUrl} target="_blank" rel="noopener noreferrer" download>
                <Image src={DownloadIcon} />
              </a>
            </IconButton>
          )
        )
      )
    }
  }, [paymentHistory, invoiceUrl])

  return (
    <Container>
      <Container className={classes.list}>
        <Table stickyHeader cols={cols} rows={activeRows} color={getColor('grey-g35')} />
      </Container>
    </Container>
  )
}

export default PlanHistoryTable

PlanHistoryTable.propTypes = {
  paymentHistory: PropTypes.object,
}
