/* eslint-disable camelcase */
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import { emailPattern } from 'utils/form/form'
import TextField from 'components/common/text/TextField'
import { Typography } from '@mui/material'
import useToggle from 'common-hooks/useToggle'
import { useEffect, useState } from 'react'
import useToastify from 'common-hooks/useToastify'
import EmailVerificationDialog from './EmailVerificationDialog'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
    display: 'flex',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    maxHeight: '690px',
    overflowY: 'scroll',
  },
  group: {
    display: 'flex',
    gap: '0 16px',
  },
  radiogrp: {
    display: 'flex',
    gap: '0 26px',
    height: '20px',
    // flexDirection: 'row',
  },
  pb10: {
    paddingBottom: '10px',
  },
  billing: {
    fontWeight: '700',
    color: getColor('grey-g25'),
    paddingTop: '5px',
  },
  bilingAddrres: {
    height: '20px',
  },
})

export default function EmailDialog({ open, onclose, emailID, verifyEmail, updateEmail }) {
  const classes = useStyles()
  const { toastMessage } = useToastify()
  const [openDialog, toggleDialog] = useToggle(false)
  const [email, setEmail] = useState()
  const [error, setError] = useState(false)
  useEffect(() => {
    if (!open) {
      setError(false)
    }
  }, [open])
  const onContinue = async () => {
    const response = await verifyEmail(email)
    if (response === 'Email exists') {
      setError(true)
    }
    if (emailPattern.test(email) && response === 'OK') {
      toggleDialog(true)
      onclose()
    } else if (!emailPattern.test(email)) {
      toastMessage('error', 'Please enter valid email.')
    }
  }
  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Email'} onClose={onclose} />
        <Container className={classes.dialogbody} direction={'column'} gap={'20px 0'}>
          <Container>
            <Typography variant="body1">Current email: {emailID}</Typography>
            <Typography variant="body1">
              Enter new email you would like to associate with your account below. We will send a verification code to
              on entered email.
            </Typography>
          </Container>
          <TextField
            id="login-email"
            type={'email'}
            name={'email'}
            placeholder="john.d@partsclub.com"
            label={'Email'}
            onChange={(e) => setEmail(e.target.value)}
            error={error && 'Email already exists'}
          />
        </Container>

        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={onclose}>
              Cancel
            </Button>
            <Button type="button" onClick={onContinue} width={70}>
              Continue
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
      <EmailVerificationDialog
        open={openDialog}
        onclose={toggleDialog}
        updateEmail={updateEmail}
        oldEmail={emailID}
        newEmail={email}
      />
    </Container>
  )
}
EmailDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  emailID: PropTypes.string,
  verifyEmail: PropTypes.func,
  updateEmail: PropTypes.func,
}
