import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Select from 'components/common/select/Select'

import { Controller, useForm, useWatch } from 'react-hook-form'
import { createOption } from 'components/common/MonthYearPicker'
import { countryOption } from './CompanySettingsUtils'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    overflow: 'hidden',
  },
})

const CompanyPolicesCountryDialog = ({ open, onclose, setCountryData, countryData }) => {
  const classes = useStyles()
  const { control } = useForm()
  const options = countryOption?.map((item) => createOption(item, item))
  const [country] = useWatch({
    control,
    name: ['country'],
  })
  const handleAdd = () => {
    setCountryData([...countryData, country.value])
    onclose()
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Country'} onClose={onclose} />
          <Container className={classes.selectField}>
            <Controller
              name={'country'}
              control={control}
              render={({ field: { name, onChange } }) => (
                <Select
                  options={options}
                  id={name}
                  name={name}
                  onChange={onChange}
                  label={'Country*'}
                  menuPosition={'fixed'}
                />
              )}
            />
            {/* <Select options={option} id={'Status'} name={'Status'} label={'Brand'} /> */}
          </Container>
          <DialogFooter justify={'flex-end'}>
            <Container flex gap={'0 10px'}>
              <Button variant="secondary" border width={77} onClick={onclose}>
                Cancel
              </Button>
              <Button width={80} onClick={() => handleAdd()}>
                Add
              </Button>
            </Container>
          </DialogFooter>
        </Dialog>
      </Container>
    </>
  )
}
CompanyPolicesCountryDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  setCountryData: PropTypes.func,
  countryData: PropTypes.func,
}

export default CompanyPolicesCountryDialog
