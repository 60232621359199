import { useState } from 'react'

export default function useSearch() {
  const [search, setSearch] = useState('')

  const onSearch = (e) => {
    setSearch(e)
  }

  return {
    search,
    onSearch,
  }
}
