import Drawer from 'components/custom/drawer/Drawer'
import DrawerHeader from 'components/custom/drawer/DrawerHeader'
import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import Select from 'components/common/select/Select'
import { makeStyles } from '@mui/styles'
import Typography from 'components/common/Typography'
import { useState } from 'react'
import Seperator from 'components/common/Seperator'
import { getColor } from 'theme/colors'
import Checkbox from 'components/common/Checkbox'
import RangeSlider from 'components/custom/RangeSlider'
import { conditions, status } from './InventoryUtils'
import { createOption } from 'components/common/text/DropDownTextField'
import useProductFitsModelNumerList from 'common-hooks/inventory/useProductFitsModelNumerList'
import { Controller, useForm } from 'react-hook-form'
import Button from 'components/common/Button'
import useProductPartDescList from 'common-hooks/inventory/useProductPartDescList'
import { ResetIcon } from 'assets/icons'
import IconButton from 'components/common/IconButton'
import useOEMbrandList from 'common-hooks/super-admin/useOEMbrandList'

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  subContainer: {
    padding: '20px',
    height: '90vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  container: {
    '& label': {
      color: 'red',
    },
  },
  innerContainer: {
    alignItems: 'unset',
  },
  conditionFlex: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  footer: {
    padding: '20px',
    borderTop: '1px solid #F0F0F4',
  },
  iconBtn: {
    width: '84%',
    justifyContent: 'end',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
})

export default function FilterDialog({ open, onClose, setFilterData }) {
  const classes = useStyles()
  // const { brandList } = useBrandList()
  const { brandList } = useOEMbrandList()
  const [fitsBrand, setFitsBrand] = useState()
  const [normalBrand, setNormalBrand] = useState()
  const { modelList, onSearchModel } = useProductFitsModelNumerList(fitsBrand?.value)
  const { partDescList, onSearch } = useProductPartDescList(normalBrand?.value)

  const brandOption = brandList?.map((obj) => createOption(obj.id, obj.name))

  const { control, setValue, handleSubmit, reset } = useForm()

  function getOptionsForModel(data) {
    return data?.map((values) => createOption(values, values))
  }
  const modelOption = getOptionsForModel(modelList)

  const partDespOption = partDescList?.map((values) => createOption(values, values))

  const [pricerange, setPriceRange] = useState()
  const [quantityRange, setQuantityRange] = useState()

  const onSubmit = (data) => {
    setFilterData({ ...data, pricerange, quantityRange, fitsBrand, normalBrand })
    onClose()
  }

  const handleOnreset = () => {
    reset({
      brand_id: '',
      part_desp: '',
      status: '',
      condition: '',
      fits_with_brand: '',
      fits_with_model: '',
      is_returnable: false,
      has_warranty: false,
      is_cancellable: false,
      ltl_freight_required: false,
      enable_make_offer: false,
      enable_buy_now: false,
    })
    setNormalBrand('')
    setFitsBrand('')
    setPriceRange([0, 10000])
    setQuantityRange([0, 100])
  }

  return (
    <>
      <Drawer open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.mainContainer}>
            <DrawerHeader title="Filter" onClose={onClose}>
              <IconButton className={classes.iconBtn} onClick={handleOnreset}>
                <ResetIcon />
              </IconButton>
            </DrawerHeader>

            <Container className={classes.subContainer}>
              <Container flex direction={'column'} gap={'20px'} className={classes.innerContainer}>
                <Controller
                  name={'status'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select options={status} id={name} name={name} value={value} onChange={onChange} label={'Status'} />
                  )}
                />
                <Controller
                  name={'brand_id'}
                  control={control}
                  render={({ field: { name } }) => (
                    <Select
                      options={brandOption}
                      id={name}
                      name={name}
                      value={normalBrand}
                      onChange={(e) => {
                        setNormalBrand(e)
                      }}
                      label={'Brand'}
                    />
                  )}
                />
                <Controller
                  name={'part_desp'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      options={partDespOption}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={'Part Description'}
                      onInputChange={(e) => {
                        onSearch(e)
                      }}
                    />
                  )}
                />

                <Controller
                  name={'condition'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      options={conditions}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={'Condition'}
                    />
                  )}
                />

                <Seperator />
                <RangeSlider
                  defaultValue={pricerange}
                  min={0}
                  max={10000}
                  prefix={'$'}
                  label={'Price'}
                  onChange={setPriceRange}
                />
                <Seperator />
                <RangeSlider
                  defaultValue={quantityRange}
                  min={0}
                  max={100}
                  label={'Quantity'}
                  onChange={setQuantityRange}
                />
                <Seperator />
                <Typography variant="title" color={getColor('grey-g25')}>
                  Fits With
                </Typography>

                <Select
                  options={brandOption}
                  value={fitsBrand}
                  onChange={(e) => {
                    setFitsBrand(e)
                  }}
                  id={'Brand'}
                  name={'fits_with_brand'}
                  label={'Brand'}
                />

                <Controller
                  name={'fits_with_model'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      options={modelOption}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={'Model'}
                      onInputChange={(e) => {
                        onSearchModel(e)
                      }}
                    />
                  )}
                />

                {/* <Select
                  options={modelOption}
                  id={'Model '}
                  name={'Model '}
                  onInputChange={(e) => {
                    onSearchModel(e)
                  }}
                  label={'Model'}
                /> */}
                <Seperator />
                <Typography variant="title" color={getColor('grey-g25')}>
                  Special Conditions
                </Typography>
                <Container className={classes.conditionFlex}>
                  <Controller
                    name={'is_returnable'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        onChange={(e) => {
                          setValue('is_returnable', e.target.checked)
                        }}
                        label={'Returnable'}
                        name={name}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'has_warranty'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        onChange={(e) => {
                          setValue('has_warranty', e.target.checked)
                        }}
                        label={'Warranty'}
                        name={name}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'is_cancellable'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        onChange={(e) => {
                          setValue('is_cancellable', e.target.checked)
                        }}
                        label={'Cancellable'}
                        name={name}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'ltl_freight_required'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        onChange={(e) => {
                          setValue('ltl_freight_required', e.target.checked)
                        }}
                        label={'LTL Freight Required'}
                        name={name}
                        value={value}
                      />
                    )}
                  />
                </Container>
                <Seperator />
                <Typography variant="title" color={getColor('grey-g25')}>
                  Marketplace
                </Typography>
                <Container className={classes.conditionFlex}>
                  <Controller
                    name={'enable_make_offer'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        onChange={(e) => {
                          setValue('enable_make_offer', e.target.checked)
                        }}
                        label={'Make an Offer'}
                        name={name}
                        value={value}
                      />
                    )}
                  />
                  <Controller
                    name={'enable_buy_now'}
                    control={control}
                    render={({ field: { name, value } }) => (
                      <Checkbox
                        onChange={(e) => {
                          setValue('enable_buy_now', e.target.checked)
                        }}
                        label={'Buy Now'}
                        name={name}
                        value={value}
                      />
                    )}
                  />
                </Container>
              </Container>
            </Container>

            <Container className={classes.footer}>
              <Button type="submit" width="100%">
                Apply
              </Button>
            </Container>
          </Container>
        </form>
      </Drawer>
    </>
  )
}
FilterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setFilterData: PropTypes.func,
}
