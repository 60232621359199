export const phoneRegExp =
  /^$|^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const emailRegExp = /^[^A-Z|'|`]*$/
export const colorCodeRegExp = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/

export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export function getFormError(errors, name) {
  return (errors && errors[name] && errors[name].message) || ''
}

export function getFormSelectError(errors, name) {
  return (errors && errors[name] && errors[name]?.value.message) || ''
}
