import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import SearchField from 'components/common/search/SearchField'
import { Typography } from '@mui/material'
import Select from 'components/common/select/Select'
import { getColor } from 'theme/colors'
import Button from 'components/common/Button'
import SearchEmpty from 'assets/images/SearchEmpty.png'
import { CaretLeftIcon, CaretRightIcon } from 'assets/icons'
import { filterOption } from 'containers/product-search/SearchUtils'
import HtmlTitle from 'utils/HtmlTitle'
import LandingPageHeader from './LandingPageHeader'
import LandingPageFooter from './LandingPageFooter'
import PartListCard from './PartListCard'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Image from 'components/common/Image'
import { useState } from 'react'
import useProductSearchList from 'common-hooks/search-product/useProductSearchList'

const useStyles = makeStyles({
  //   SearchField: {
  //     width: '908px',
  //     margin: '0 auto',
  //     padding: '25px 0',
  //     '& input': {
  //       height: '50px',
  //     },
  //   },
  root: {
    borderTop: '1px solid #F0F0F4',
    background: '#F8F9F9',
    '& input': {
      background: '#fff',
    },
  },
  searchResult: {
    maxWidth: '908px',
    width: '100%',
    margin: '0 auto',
    marginTop: '20px',
    '& p': {
      width: '70px',
    },
    marginBottom: '25px',
  },
  productLisitinGrid: {
    height: '77vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    marginTop: '30px',
  },
  placeholder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center ',
    height: '100%',
    position: 'absolute',
    top: '120px',
    bottom: '0',
    left: '0',
    right: '0',
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  btnDiv: {
    // padding: '0 0px 30px 0px',
  },
  dropdown: {
    background: '#fff',
    '& .css-1flow6g-control': {
      background: '#fff',
    },
  },
})

export default function PartsList() {
  const [filterValue, setFilterValue] = useState('')
  const { searchList, search, onSearch, page, setPage, isLoading } = useProductSearchList('customer', filterValue)
  // const { productData, isLoading, setPage, page, search, onSearch } = useProductList('customer',filterValue)
  // const [requestQuoteDialog, setRequestQuoteDialog] = useToggle(false)
  // const [offerDialog, setOfferDialog] = useToggle(false)

  const handleChange = (e) => {
    setFilterValue(e?.value)
  }

  const activeProduct = searchList && searchList?.filter((obj) => obj?.status === 'active')
  const classes = useStyles()
  return (
    <Container>
      <HtmlTitle title={'Parts'} />
      <Container className="container">
        <LandingPageHeader />
      </Container>

      <Container flex className={classes.root}>
        <Container width={'908px'} direction="column" display="flex" className="p90">
          <Container className={'p-SearchField'}>
            <Typography className="part-title">Parts</Typography>
            <Container className="div">
              <SearchField
                placeholder="Search by part number, brand, description"
                onSearch={(e) => {
                  onSearch(e)
                }}
                value={search}
              />
            </Container>
          </Container>
          {search && (
            <Container className={classes.searchResult}>
              <Container flex justify="space-between">
                <Typography variant="title" color={getColor('grey-g35')}>
                  Search Results for “{search}”
                </Typography>
                <Container flex gap={'0 10px'} width="241px">
                  <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                    Sort by:
                  </Typography>
                  <Select
                    className={classes.dropdown}
                    options={filterOption}
                    defaultValue={{ label: 'Relevance', value: 'Relevance' }}
                    id={'Options'}
                    name={'Options'}
                    onChange={handleChange}
                  />
                </Container>
              </Container>
            </Container>
          )}

          <Container className={classes.productLisitinGrid}>
            {isLoading && <LoadingOverlay />}
            {activeProduct?.map((obj, index) => (
              <PartListCard
                key={index}
                id={obj.id}
                brandName={obj.brand_name}
                partName={obj.part_desp}
                partNumber={obj.part_no}
                condition={obj.condition}
                companyName={obj.company_name}
                city={obj.company_city}
                state={obj.company_state}
                amout={obj.unit_price}
                qty={obj.qty}
                stock={obj.stock}
                brandImage={obj.cover_image}
                country={obj?.company_country}
                weight={obj?.weight}
                // setRequestQuoteDialog={setRequestQuoteDialog}
                // setOfferDialog={setOfferDialog}
              />
            ))}
            {activeProduct?.length === 0 && (
              <>
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={SearchEmpty} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    <Container flex direction="column">
                      <Typography variant="title" color={getColor('grey-g25')} component="p">
                        No products found
                      </Typography>
                      <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                        Start a quote request to get quotes from suppliers
                      </Typography>
                    </Container>
                    <Button>Request Quote</Button>
                  </Container>
                </Container>
              </>
            )}
          </Container>
          <Container display="flex" justify="space-between" width={'100%'} className={classes.btnDiv}>
            <Button
              icon={<CaretLeftIcon />}
              variant={'transparent'}
              className={classes.prevBtn}
              onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
              disabled={page === 1}
            >
              Prev.
            </Button>
            <Button
              icon={<CaretRightIcon />}
              variant={'transparent'}
              className={classes.nextBtn}
              onClick={() => setPage((pre) => pre + 1)}
            >
              Next
            </Button>
          </Container>
        </Container>
      </Container>
      {/* <RequestQuoteDialog open={requestQuoteDialog} onClose={setRequestQuoteDialog} />
      <OfferDialog open={offerDialog} onClose={setOfferDialog} /> */}
      <LandingPageFooter />
    </Container>
  )
}
