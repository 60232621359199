import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import useToggle from 'common-hooks/useToggle'
import AppBar from 'containers/app-bar/AppBar'
import { getColor } from 'theme/colors'
import { makeStyles } from '@mui/styles'
import AppDrawer from './app-drawer/AppDrawer'
import { DRAWER_CLOSE_WIDTH, DRAWER_OPEN_WIDTH } from './app-drawer/AppDrawerUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: (props) => (props.appBar ? theme.spacing(15) : 0), // 5rem
    paddingLeft: (props) => (props.appDrawer ? getPaddingLeft(props.drawer, theme) : 0),
  },
  mainPage: {
    background: getColor('system-bg'),
  },
}))

export default function AppPage({ children, title, appBar, appDrawer }) {
  const [drawer, toggleDrawer] = useToggle(true)
  const classes = useStyles({ drawer, appBar, appDrawer })

  return (
    <Container title={title} className={classes.root}>
      {appBar && <AppBar drawerOpen={drawer} />}
      {appDrawer && <AppDrawer open={drawer} toggle={toggleDrawer} />}

      <Container className={classes.mainPage}>{children}</Container>
    </Container>
  )
}

AppPage.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  appBar: PropTypes.bool,
  appDrawer: PropTypes.bool,
}

// function getPaddingLeft(drawer, theme) {
//   return `calc(${drawer ? DRAWER_OPEN_WIDTH : DRAWER_CLOSE_WIDTH}px + ${theme.spacing(0.5)})`
// }
function getPaddingLeft(drawer) {
  return `${drawer ? DRAWER_OPEN_WIDTH : DRAWER_CLOSE_WIDTH}px`
}
