import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import useCompanySettings from 'common-hooks/settings/company/useCompanySettings'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  unlinkBtn: {
    background: '#AD1F36',
    '&:hover': {
      background: '#AD1F36',
    },
  },
})

export default function CourierConfirmationDialog({ open, onClose, setShipo, mutate }) {
  const classes = useStyles()
  const { handleSubmit } = useForm()
  //   const options = countryOption?.map((item) => createOption(item, item))
  const { unlinkShippo } = useCompanySettings()
  const onSubmit = () => {
    setShipo(false)
    unlinkShippo(mutate)
    onClose()
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Confirmation'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.selectField}>
              <Typography variant="body1" color={getColor('grey-g35')}>
                Are you sure you want to unlink your Shippo account? Once you unlink it you will not be able to see
                courier account.
              </Typography>
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button className={classes.unlinkBtn} type="submit">
                  Unlink Account
                </Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
CourierConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setShipo: PropTypes.func,
  mutate: PropTypes.func,
}
