import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { AddIcon, MinusIcon } from 'assets/icons'
import Button from 'components/common/Button'
import TextField from 'components/common/text/TextField'
import Container from 'components/common/Container'
import typography from 'theme/typography'
import Label from 'components/common/Label'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    width: props.width || 105,
    height: props.height || 30,
    display: 'flex',
    borderRadius: '2px',
    border: '0.5px solid #C3C5D5',
    background: '#ffffff',
  }),
  labelTypography: {
    fontSize: typography.paragraph2.fontSize,
    fontWeight: typography.paragraph2.fontWeight,
  },
  button: {
    borderRadius: 'unset',
    minHeight: '100%',
  },
  txtfield: {
    height: '100%',
    '& div': {
      height: '100%',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input': {
      height: '100%',
      borderTop: 'unset',
      borderBottom: 'unset',
      textAlign: 'center',
      background: '#fff',
      margin: '0',
      '&:hover': {
        border: '0.5px solid #C3C5D5',
        borderTop: 'unset',
        borderBottom: 'unset',
      },
    },
  },
}))

const TwoWayController = forwardRef(
  (
    {
      width,
      height,
      btnWidth,
      txtWidth,
      onChange,
      defalutValue,
      label,
      labelSpacing = 0,
      labelVariant = 'paragraph2',
      labelTooltip,
      LabelProps = {
        textTransform: 'none',
      },
      // ...props
    },
    ref
  ) => {
    const classes = useStyles({ btnWidth, width, height })
    const [count, setCount] = useState()

    const handleIncNum = () => {
      setCount(count + 1)
      onChange(count + 1)
    }
    const handleDecNum = () => {
      if (count > 0) {
        setCount(count - 1)
        onChange(count - 1)
      } else {
        setCount(1)
      }
    }

    useEffect(() => {
      if (defalutValue) {
        setCount(defalutValue)
      } else {
        setCount(1)
      }
    }, [defalutValue])

    const handleOnNumber = (e) => {
      setCount(e.target.value)
    }

    return (
      <>
        <Container display="flex" direction="column">
          {label && (
            <Label
              className={classes.labelTypography}
              spacing={labelSpacing}
              variant={labelVariant}
              tooltip={labelTooltip}
              {...LabelProps}
            >
              {label}
            </Label>
          )}
          <Container className={classes.root} ref={ref}>
            <Button
              icon={<MinusIcon />}
              className={classes.button}
              width={btnWidth || '30px'}
              variant="secondary"
              onClick={handleDecNum}
            />
            <TextField
              id={'counter'}
              className={classes.txtfield}
              width={txtWidth || 45}
              value={count}
              type={'number'}
              onChange={handleOnNumber}
              placeholder={'0'}
              // {...props}
            />
            <Button
              icon={<AddIcon />}
              className={classes.button}
              width={btnWidth || '30px'}
              variant="secondary"
              onClick={handleIncNum}
            />
          </Container>
        </Container>
      </>
    )
  }
)

export default TwoWayController

TwoWayController.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  btnWidth: PropTypes.string,
  txtWidth: PropTypes.number,
  setCount: PropTypes.func,
  count: PropTypes.number,
  onChange: PropTypes.func,
  LabelProps: PropTypes.object,
  defalutValue: PropTypes.number,
  label: PropTypes.string,
  labelSpacing: PropTypes.number,
  labelVariant: PropTypes.string,
  labelTooltip: PropTypes.string,
}
