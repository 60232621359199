import Container from 'components/common/Container'
import { React, useEffect, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import TextField from 'components/common/text/TextField'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
})

export default function SerialNumberDialog({
  open,
  onClose,
  setSerialNumber,
  editSerialNumber,
  setFlag,
  setEditSerialNumber,
}) {
  const classes = useStyles()
  const [serialNumberData, setSerialNumberData] = useState()

  const { toastMessage } = useToastify()

  const handleOnClose = () => {
    setSerialNumberData('')
    setEditSerialNumber(null)
    onClose()
  }

  // eslint-disable-next-line consistent-return
  const saveSerialNumber = () => {
    if (editSerialNumber) {
      setSerialNumber(serialNumberData)
      setFlag(false)
      handleOnClose()
      return 0
    }

    if ((!editSerialNumber && serialNumberData === '') || serialNumberData === undefined) {
      toastMessage('error', 'Serial Number is required')
      return 0
    }
    setSerialNumber(serialNumberData)
    setFlag(false)
    handleOnClose()
  }

  useEffect(() => {
    if (editSerialNumber) {
      setSerialNumberData(editSerialNumber)
    }
  }, [editSerialNumber])

  return (
    <Container>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Serial Number'} onClose={handleOnClose} />
        <Container className={classes.dialogbody}>
          <TextField
            id={'Serial Number'}
            placeholder="Type"
            value={serialNumberData}
            onChange={(e) => setSerialNumberData(e.target.value)}
          />
        </Container>
        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button onClick={() => saveSerialNumber()}>{editSerialNumber ? 'Update' : 'Add'}</Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </Container>
  )
}
SerialNumberDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setSerialNumber: PropTypes.func,
  editSerialNumber: PropTypes.object,
  setFlag: PropTypes.func,
  setEditSerialNumber: PropTypes.func,
}
