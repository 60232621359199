/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react'
import { useForm, useWatch, Controller, FormProvider } from 'react-hook-form'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Label from 'components/common/Label'
// import Select from 'components/common/select/Select'
import RadioGroup from 'components/common/radio/RadioGroup'
import Radio from 'components/common/radio/Radio'
import useCompanyList from 'common-hooks/useCompanyList'
import TextField from 'components/common/text/TextField'
import { SignupDataContext } from 'utils/provider/AuthContext'
import GooglePlaces from 'components/custom/GooglePlaces'
import { formatAddressOfGAPI } from './SignUpUtils'
import CompanySearch from './CompanySearch'
import { Typography } from '@mui/material'
import { getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  radiogrp: {
    width: '100%',
    '& .MuiFormGroup-root': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
  borderBoxYes: {
    width: '150px',
    height: '44px',
    justifyContent: 'flex-start',
    paddingLeft: '15px',
    border: ({ working }) => (working ? `1px solid ${getColor('orange-main')}` : `1px solid ${getColor('grey-g80')}`),
    borderRadius: '4px',
  },
  radioLabel: {
    width: '136px',
  },
  borderBoxNo: {
    width: '150px',
    height: '44px',
    justifyContent: 'flex-start',
    paddingLeft: '15px',
    borderRadius: '4px',
    border: ({ working }) => {
      if (working && typeof working === 'boolean') {
        return `1px solid ${getColor('grey-g80')}`
      }

      if (!working && typeof working === 'boolean') {
        return `1px solid ${getColor('orange-main')}`
      }
      return `1px solid ${getColor('grey-g80')}`
    },
  },
  searchList: {
    maxHeight: '200px',
    overflowY: 'scroll',
    position: 'absolute',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    width: '100%',
    background: '#fff',
    zIndex: '11111',
    border: '1px solid #C3C5D5',
    borderTop: 'none',
  },
  searchemptyList: {
    position: 'absolute',
    width: '100%',
    background: '#fff',
    zIndex: '11111',
    border: '1px solid #C3C5D5',
    borderTop: 'none',
  },
  searchMainDiv: {
    position: 'relative',
    width: '100%',
  },
  searchtxt: {
    padding: '0.25rem 0.5rem',
    borderRadius: 4,
    fontSize: '0.75rem',
    cursor: 'pointer',
    '&:hover': {
      color: getColor('func-info'),
      backgroundColor: '#F8F9F9',
    },
  },
}))

export default function SignUpCompanyInfo({ setBtnDisable, onStepChange, step }) {
  const { companyList, onSearch, search } = useCompanyList()
  const [disableFileds, setDisableFileds] = useState(true)
  const { onSaveSignupData, signupData } = useContext(SignupDataContext)

  const [companyNewName, setCompanyNewName] = useState(signupData?.companyData?.companyNewName || false)

  const [companyName, setCompanyName] = useState(signupData?.companyData?.companyName || { label: '', id: '' })
  const methods = useForm({
    defaultValues: {
      isCompany: signupData?.companyData?.isCompany,
      jobTitle: signupData?.companyData?.jobTitle,
      cmp_addr: signupData?.companyData?.locationString,
    },
  })
  const { handleSubmit, control, setValue, resetField } = methods
  const [isCompany, noBtn, cmp_addr, jobTitle] = useWatch({
    control,
    name: ['isCompany', 'noBtn', 'cmp_addr', 'jobTitle'],
  })
  if (isCompany === false) {
    setBtnDisable(false)
  } else if (isCompany && companyName?.label !== '' && companyName?.id !== '' && jobTitle && !cmp_addr) {
    setBtnDisable(false)
  } else if (isCompany && companyName?.label !== '' && companyName?.id === '' && jobTitle && cmp_addr) {
    setBtnDisable(false)
  } else {
    setBtnDisable(true)
  }
  if (isCompany === false && isCompany && (companyName?.label !== '' || !jobTitle)) {
    setBtnDisable(true)
  }
  const onSaveSignupContextValue = () => {
    let companyData
    if (isCompany) {
      companyData = {
        isCompany: true,
        companyName,
        jobTitle,
        cmp_addr,
        locationString:
          getWellKnownAddress(cmp_addr?.addr_line_1, cmp_addr?.addr_line_2) === '-'
            ? signupData?.companyData?.locationString
            : getWellKnownAddress(cmp_addr?.addr_line_1, cmp_addr?.addr_line_2),
        companyNewName,
      }
    } else {
      companyData = {
        isCompany: false,
      }
    }
    onSaveSignupData({ ...signupData, companyData })
  }
  useEffect(() => {
    if (isCompany) {
      setDisableFileds(false)
    } else {
      setDisableFileds(true)
    }
    // if (search.length > 0) setCompanyOptions(companyList)
  }, [companyList.length > 0, noBtn, isCompany])
  const classes = useStyles()
  const onSubmit = () => {
    onSaveSignupContextValue()
    onStepChange(step + 1)
  }
  // function handleCompanyChange(index, event, name) {
  //   const values = [...sectionFields]
  // }
  const getCompanyValue = (id, label) => {
    setCompanyName({ label, id: id || '' })
    onSearch('')
    if (id === '') {
      setCompanyNewName(true)
    } else {
      setCompanyNewName(false)
    }
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id={'companyInfo-form'}>
        <Container width={'100%'} direction={'column'} flex justify="flex-start" gap="0px" height={'380px'}>
          <Container flex justify="flex-start" direction={'column'} width={'320px'} gap={'10px'}>
            <Container width="100%">
              <Label variant="body1">Are you working for company?</Label>
            </Container>

            <Controller
              control={control}
              render={({ field: { name, value } }) => (
                <RadioGroup
                  className={classes.radiogrp}
                  row
                  spacing={2.5}
                  name={name}
                  value={value}
                  onChange={(e) => {
                    setValue(name, e.target.value === 'true')
                  }}
                >
                  <Container flex className={classes.borderBoxYes}>
                    <Radio value name={name} label={'Yes'} labelWidth />
                  </Container>
                  <Container flex className={classes.borderBoxNo}>
                    <Radio value={false} name={name} label={'No'} labelWidth />
                  </Container>
                </RadioGroup>
              )}
              name="isCompany"
            />
            {/* <SelectCreateable
              options={companyOptions}
              id={'companyName'}
              placeholder={'Select'}
              defaultValue=""
              value={companyNewName}
              name={'companyName'}
              onChange={(event) => setCompanyNewName({ label: event?.label, id: event?.value || '' })}
              label={'Company Name'}
              onInputChange={(e) => {
                onSearch(e)
              }}
              disabled={disableFileds}
              isCompay
              menuPosition={'fixed'}
            /> */}
            <Container className={classes.searchMainDiv}>
              <CompanySearch
                placeholder="Type"
                label={'Company Name'}
                onSearch={(e) => {
                  onSearch(e)
                }}
                companyName={companyName}
                companySetValue={setValue}
                setCompanyName={setCompanyName}
                setCompanyNewName={setCompanyNewName}
                disabled={disableFileds}
                reset={resetField}
              />
              {search !== '' && companyList?.length > 0 && (
                <Container className={classes.searchList}>
                  {companyList?.map((obj, index) => (
                    <Container
                      key={index}
                      className={classes.searchtxt}
                      onClick={() => getCompanyValue(obj?.value, obj?.label)}
                    >
                      <Typography>{obj?.label}</Typography>
                    </Container>
                  ))}
                </Container>
              )}
              {search && companyList?.length === 0 && (
                <Container className={classes.searchemptyList}>
                  <Container className={classes.searchtxt} onClick={() => getCompanyValue('', search)}>
                    <Typography>Add New</Typography>
                  </Container>
                </Container>
              )}
            </Container>

            {companyNewName && (
              <Controller
                name={'cmp_addr'}
                control={control}
                render={({ field: { name, value } }) => (
                  <GooglePlaces
                    id={name}
                    name={name}
                    label="Company Address"
                    spacing={3}
                    placeholder={'Enter Location'}
                    disabled={disableFileds}
                    value={value}
                    onClearInput={() => setValue(name, '')}
                    onSelect={(loc) => {
                      setValue(name, formatAddressOfGAPI(loc.addr, loc.addr_line_1, loc.addr_line_2))
                    }}
                  />
                )}
              />
            )}
            <Controller
              name={'jobTitle'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  value={value}
                  label={'Job Title'}
                  placeholder={'Job Title'}
                  disabled={disableFileds}
                />
              )}
            />
          </Container>
        </Container>
      </form>
    </FormProvider>
  )
}

SignUpCompanyInfo.propTypes = {
  setBtnDisable: PropTypes.func,
  onStepChange: PropTypes.func,
  step: PropTypes.number,
}
