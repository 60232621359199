import { AuthContext } from 'utils/provider/AuthContext'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import AppPage from 'containers/AppPage'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'

export default function PrivateRoute({ children }) {
  const { authState } = useContext(AuthContext)
  const isAuthorized = authState?.isAuthenticated || false
  const location = useLocation()
  const userRole = getFromLocalStorage(constants.ROLE)
  if (!isAuthorized) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/home" />
  }
  if (userRole === 'super_admin') {
    return <Navigate to="/accessDenied" />
  }
  if (
    location?.pathname.includes('quotes/supplierdetail') ||
    location?.pathname.includes('upgradeaccount') ||
    location?.pathname.includes('/inventory/addproduct') ||
    location?.pathname.includes('/inventory/editproduct')
  ) {
    return children
  }
  // authorized so return child components
  if (location?.pathname.includes('quotes/active') && userRole === 'supplier') {
    return <AppPage appBar>{children}</AppPage>
  }
  if (location?.pathname.includes('offers/active')) {
    return <AppPage appBar>{children}</AppPage>
  }
  return (
    <AppPage appBar appDrawer>
      {children}
    </AppPage>
  )
}
PrivateRoute.propTypes = {
  children: PropTypes.node,
}
