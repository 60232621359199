import { toast } from 'react-toastify'
import Toast from 'components/common/Toast'

export default function useToastify() {
  const toastMessage = (type, message) => {
    toast(<Toast variant={type} message={message} />)
  }

  return {
    toastMessage,
  }
}
