import React from 'react'
import Container from 'components/common/Container'
import Placeholder from 'assets/images/Placeholder.png'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import publicmode from 'assets/icons/publicmode.svg'
import lockmode from 'assets/icons/lockmode.svg'
import { PropTypes } from 'prop-types'
import Button from 'components/common/Button'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  mainContainer: {
    marginBottom: '20px',
    width: '800px',
    height: '130px',
    background: getColor('light'),
    padding: '20px',
    gap: '10px',
    border: '1px solid #F0F0F4',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },

  subContainer: {
    justifyContent: 'space-between',
  },
  lastContainer: {
    marginTop: '25px',
    justifyContent: 'space-between',
  },
  img: {
    width: '90px',
    height: '90px',
    border: '1px solid #F0F0F4 ',
  },
  blueText: {
    color: getColor('func-info'),
  },
  greenText: {
    marginRight: '2px',
    color: getColor('func-positive'),
  },
  greenMsgDot: {
    paddingRight: '2px',
    color: getColor('func-positive'),
    fontSize: 'x-large',
  },
  otherText: {
    color: getColor('grey-g35'),
  },
  imgSupplierText: {
    color: getColor('grey-g25'),
  },
  blackDot: {
    padding: '0px 5px 3px 5px',
    color: getColor('grey-g35'),
  },
  msgBlackDot: {
    padding: '0px 5px',
  },
  btn: {
    marginTop: '5px',
    padding: '0px 0px',
    alignItems: 'center',
    minHeight: '0px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  quotCaption: {
    color: getColor('func-positive'),
    width: '27px',
    height: '20px',
    background: getColor('func-badge'),
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0px 0px 0px 2px',
  },
}))

const QuoteActiveCard = ({
  quoteImg,
  brandName,
  quoteId,
  brandModelNum,
  partNo,
  brandSerialNum,
  partName,
  quantity,
  equipName,
  publicPrivate,
  brandNameRef,
  // imagesCount,
  quoteCount,
  supplierCount,
  createdAt,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const date = createdAt
  const finalBrandName = brandNameRef || brandName ? brandName || brandNameRef : ''
  // const quoteName = `${finalBrandName} ${partNo} ${partName}`.replace('null', '').replace('undefined', '')
  return (
    <Container flex direction={'column'}>
      <Container
        flex
        className={classes.mainContainer}
        onClick={() => {
          navigate(`/quotes/active/${quoteId}`)
        }}
      >
        <Container>
          <img src={quoteImg || Placeholder} alt="LET'S SPARK UP LOGO" className={classes.img} />
        </Container>
        <Container width={'100%'}>
          <Container flex justifyContent={'space-between'} className={classes.subContainer}>
            <Typography variant="title" color={getColor('grey-g25')}>
              {finalBrandName} {partName} {partNo ? <> {partNo}</> : <>{''}</>}
            </Typography>
            <Container>
              <Typography variant="title" color={getColor('grey-g25')}>
                Qty: {quantity}
              </Typography>
            </Container>
          </Container>
          <Container flex className={classes.subContainer}>
            <Container flex>
              <Typography variant="paragraph1" className={classes.otherText}>
                {equipName}
              </Typography>

              <Container>
                <Typography variant="paragraph1" className={classes.otherText}>
                  {brandModelNum ? (
                    <>
                      <span className={classes.blackDot}>•</span>
                      {brandModelNum}
                    </>
                  ) : (
                    <>{''}</>
                  )}
                </Typography>
              </Container>

              <Container>
                <Typography variant="paragraph1" className={classes.otherText}>
                  {brandSerialNum && (
                    <>
                      <span className={classes.blackDot}>•</span>
                      {brandSerialNum}
                    </>
                  )}
                </Typography>
              </Container>
            </Container>

            <Container flex>
              <Container>
                {supplierCount > 0 && (
                  <Typography variant="body1" className={classes.blueText}>
                    {Number(supplierCount) === 1 ? `${supplierCount} Supplier` : `${supplierCount} Suppliers`}
                  </Typography>
                )}
              </Container>
              <Container>
                <Typography variant="body1" className={classes.greenText}>
                  {quoteCount > 0 && (
                    <>
                      <span className={classes.blackDot}>•</span>
                      {quoteCount} Quotes
                    </>
                  )}
                </Typography>
              </Container>
              {/* <Container>
                {data?.quotesCap && <Container className={classes.quotCaption}>{data?.quotesCap}</Container>}
              </Container> */}
            </Container>
          </Container>

          <Container flex className={classes.lastContainer}>
            <Container flex>
              <Container>
                <Typography variant="paragraph1" className={classes.otherText}>
                  {formatDate(date, DATE_FORMAT_PRIMARY)}
                  <span className={classes.blackDot}>•</span>
                </Typography>
              </Container>

              <Container>
                <Typography variant="paragraph1" className={classes.otherText}>
                  {!publicPrivate ? (
                    <Container flex gap={'5px'}>
                      <img src={publicmode} alt=" Logo" />
                      Public
                    </Container>
                  ) : (
                    <Container flex>
                      {' '}
                      <img src={lockmode} alt="LockImg" />
                      Private
                    </Container>
                  )}
                </Typography>
              </Container>
            </Container>

            <Container flex>
              {/* <Typography variant="body1" className={classes.imgSupplierText}>
                {data?.message ? (
                  <Container flex>
                    <Container className={classes.greenMsgDot}>•</Container>
                    <span className={classes.otherText}>{data?.message}</span>
                    <span className={classes.msgBlackDot}>•</span>
                  </Container>
                ) : (
                  <>{''}</>
                )}
              </Typography> */}

              {/* <Typography>
                {imagesCount > 0 ? (
                  <>
                    <span className={classes.otherText}>Images</span>{' '}
                    <span className={classes.otherText}>{imagesCount}</span>
                    <span className={classes.msgBlackDot}>•</span>
                  </>
                ) : (
                  <>{''}</>
                )}
              </Typography> */}
              <Container>
                <Button
                  variant="secondary"
                  border
                  onClick={() => {
                    navigate(`/quotes/active/${quoteId}`)
                  }}
                >
                  {/* <img src={indicatorarrow} alt="Next" /> */}
                  View Quote
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
QuoteActiveCard.propTypes = {
  quoteImg: PropTypes.string,
  brandName: PropTypes.string,
  quoteId: PropTypes.string,
  partNo: PropTypes.string,
  brandSerialNum: PropTypes.string,
  partName: PropTypes.string,
  quantity: PropTypes.string,
  equipName: PropTypes.string,
  // imagesCount: PropTypes.string,
  quoteCount: PropTypes.string,
  supplierCount: PropTypes.string,
  publicPrivate: PropTypes.string,
  brandNameRef: PropTypes.string,
  createdAt: PropTypes.string,
  brandModelNum: PropTypes.string,
}

export default QuoteActiveCard
