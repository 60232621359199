import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import Container from './Container'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: (props) => (props.noRound ? 0 : 6),
    zIndex: 5,
    backgroundColor: (props) => (props.bgColor ? props.bgColor : 'rgba(255, 255, 255, 0.5)'),
  },
  progress: {
    color: getColor('orange-main'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function LoadingOverlay({ noRound, bgColor, className }) {
  const classes = useStyles({ noRound, bgColor })
  return (
    <Container height={'100%'} className={classNames(classes.root, className)} flex>
      <CircularProgress size={40} className={classes.progress} />
    </Container>
  )
}

LoadingOverlay.propTypes = {
  noRound: PropTypes.bool,
  bgColor: PropTypes.string,
  className: PropTypes.string || undefined,
}
