import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import { makeStyles } from '@mui/styles'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import Seperator from 'components/common/Seperator'

const useStyles = makeStyles(() => ({
  root: {
    padding: '0.938rem 1.25rem',
    alignItems: 'flex-end',
  },
  userName: {
    color: getColor('dark-d25'),
  },
  message: {
    color: getColor('grey-g65'),
  },
  productName: {
    color: getColor('func-info'),
  },
  date: {
    color: getColor('grey-g65'),
    paddingTop: '10px',
  },
}))

export default function NotificationCard({ message, date, userName, productName, partSerialNumber, partName }) {
  const classes = useStyles()
  const content = (
    <Container flex direction="column" className={classes.root}>
      <Container flex>
        <Typography variant="body1" className={classes.userName}>
          <span className={classes.userName}>{userName}</span> <span className={classes.message}>{message}</span>
          <span className={classes.productName}>
            {productName}
            {partName}
            {partSerialNumber}
          </span>
        </Typography>
      </Container>
      <Typography variant="body2" className={classes.date}>
        {date}
      </Typography>
      <Seperator margin={'0.938rem 0 0 0'} />
    </Container>
  )

  return content
}

NotificationCard.propTypes = {
  message: PropTypes.string,
  userName: PropTypes.string,
  productName: PropTypes.string,
  partSerialNumber: PropTypes.string,
  partName: PropTypes.string,
  date: PropTypes.string,
}
