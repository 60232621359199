import propTypes from 'prop-types'
import { useState, createContext, useContext } from 'react'

export const FormContext = createContext()

export default function FormProvider({ children }) {
  const [data, setData] = useState({})

  const setFormValues = (values) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }))
  }

  return <FormContext.Provider value={{ data, setFormValues }}>{children}</FormContext.Provider>
}
FormProvider.propTypes = {
  children: propTypes.node,
}

export const useFormData = () => useContext(FormContext)
