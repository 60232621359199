import Container from 'components/common/Container'
import { React, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { Controller, useForm } from 'react-hook-form'
import RadioGroup from 'components/common/radio/RadioGroup'
import Radio from 'components/common/radio/Radio'
import TextArea from 'components/common/text/TextArea'
import Link from 'components/common/Link'
import useSupplierCreateQuote from 'common-hooks/supplier/create-quote/useSupplierCreateQuote'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  feedback: {
    padding: '10px 0px 20px 0px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    paddingBottom: '10px',
  },
})

const ReasonDailog = ({ open, onclose, inviteId }) => {
  const { cantParticipate } = useSupplierCreateQuote()
  const { toastMessage } = useToastify()
  const [radioValue, setRadioValue] = useState('Other')

  const { handleSubmit, register, setValue, control, reset } = useForm({
    defaultValues: {
      noteValue: '',
    },
  })

  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    if (radioValue === 'Other' && data?.noteValue === '') {
      toastMessage('error', 'Notes is required')
      return 0
    }
    cantParticipate(
      radioValue === 'Other' && data?.noteValue !== '' ? { reason: data?.noteValue } : { reason: radioValue },
      inviteId
    )
    reset({
      missingKey: '',
      noStock: '',
      partOffered: '',
      poorMatch: '',
      noteValue: '',
    })
    setRadioValue('Other')
    onclose()
  }
  const handleOnradio = (e) => {
    setRadioValue(e.target.value)
  }
  const classes = useStyles()

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <form id={'resonForm'} onSubmit={handleSubmit(onSubmit)}>
            <DialogHead title={'Reason for Quote Rejection'} onClose={onclose} />
            <Container className={classes.dialogbody}>
              <Container className={classes.feedback}>
                <Typography variant="paragraph1">Your feedback helps create better matches for you and </Typography>
                <Link to="#" underline>
                  are not visible to the buyer.
                </Link>
              </Container>
              <Container>
                <RadioGroup defaultValue={'Other'} onChange={handleOnradio}>
                  <Container className={classes.radioContainer}>
                    <Controller
                      name={'noStock'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'No stock available'}
                          name={name}
                          value={'No stock available'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'No stock available')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'partOffered'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          label={'Part not offered'}
                          name={name}
                          value={'Part not offered'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Part not offered')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'missingKey'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          name={'Missing key information'}
                          label={'Missing key information'}
                          value={'Missing key information'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Missing key information')
                            }
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={'poorMatch'}
                      control={control}
                      render={({ field: { name } }) => (
                        <Radio
                          name={'Poor match'}
                          label={'Poor match'}
                          value={'Poor match'}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setValue(name, 'Poor match')
                            }
                          }}
                        />
                      )}
                    />
                    <Radio name={'Other'} label={'Other'} value={'Other'} />
                  </Container>
                </RadioGroup>
              </Container>
              {radioValue === 'Other' && (
                <Container>
                  <TextArea
                    id={'noteArea'}
                    placeholder={'Paragraph'}
                    name={'noteValue'}
                    rows={4}
                    {...register('noteValue')}
                  />
                </Container>
              )}
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onclose}>
                  Cancel
                </Button>
                <Button width={80} type="submit">
                  Submit
                </Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
ReasonDailog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  inviteId: PropTypes.string,
}

export default ReasonDailog
