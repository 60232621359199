import * as moments from 'moment'

export const DATE_TIME_FORMAT = 'MM/DD/YYYY HH:MM:SS'
export const DATE_FORMAT_PRIMARY = 'MM/DD/YYYY'
export const DATE_FORMAT_COMMA = 'MMM. DD, YYYY'
export const DATE_YEAR = 'YYYY'
export const DATE_FORMAT_COMMA_SUPER = 'MMM DD, YYYY'
export const DATE_MONTH_YEAR = 'MMMM YYYY'
export function formatDate(date, format) {
  try {
    return moments(date).format(format)
  } catch {
    return date
  }
}
