import React, { useState } from 'react'
import Dialog from 'components/custom/dialog/Dialog'
import PropTypes from 'prop-types'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import Button from 'components/common/Button'
import { getColor } from 'theme/colors'
import { saveAs } from 'file-saver'
import ImageSlider from './ImageSlider'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    display: 'unset',
    maxWidth: '1920px',
    height: '100vh',
    boxShadow: 'unset',
  },
  previewHeader: {
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
    border: 'unset',
    '& div:first-child': {
      display: ' flex',
      justifyContent: ' end',
      marginRight: '20px',
    },

    '& button': {
      backgroundColor: '#fff',
      '& div': {
        marginRight: 'unset!important',
      },
    },
  },
  downloadButton: {
    backgroundColor: 'transparent!important',
    border: '0.5px solid #fff',
    color: getColor('light-main'),
    fontSize: '0.875rem',
  },
}))

const ImageSliderContainer = ({ open, onclose, imageData, index }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const url = imageData?.map((m) => (m.file ? URL.createObjectURL(m.file) : m.part_image))
  const downloadUrl = url?.filter((m, index) => index === currentIndex)

  const downloadImage = () => {
    saveAs(downloadUrl[0], 'image.jpg')
  }

  const classes = useStyles()
  return (
    <Dialog className={classes.root} open={open} backDrop={'#000000de'}>
      <DialogHead onClose={onclose} className={classes.previewHeader}>
        <Button className={classes.downloadButton} variant="transparent" onClick={downloadImage}>
          Download
        </Button>
      </DialogHead>
      <ImageSlider sliderData={imageData} slidesToShow={1} slickGoto={index} setCurrentIndex={setCurrentIndex} />
    </Dialog>
  )
}
ImageSliderContainer.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  imageData: PropTypes.array,
  index: PropTypes.number,
}
export default ImageSliderContainer
