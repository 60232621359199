import Api from 'services/Api'

export async function getOrdersList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getOrderDetails(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function orderCancel(data) {
  const response = await Api.post('/order/cancel', data)
  return response.data.data
}

export async function shipOrder(data) {
  const response = await Api.post('/order/ship', data)
  return response.data.data
}
export async function shipOrderLocalDelivery(data) {
  const response = await Api.post('/order/ship-localdelivery', data)
  return response.data.data
}
export async function shipOrderPickup(data) {
  const response = await Api.post('/order/pickup', data)
  return response.data.data
}
export async function SoNUmberUpdate(data) {
  const response = await Api.patch('/order/sales-order-no', data)
  return response.data.data
}
export async function TrackingNumberUpdate(data) {
  const response = await Api.put('/order/tracking-no', data)
  return response.data.data
}

export async function DeliveryTimeUpdate(data) {
  const response = await Api.put('/order/dilivery-date', data)
  return response.data.data
}
export async function helpOrder(data) {
  const response = await Api.post('/order/contact-us', data)
  return response.data.data
}

export async function PoNumberUpadate(data) {
  const response = await Api.patch('/order/purchase-order-no', data)
  return response.data.data
}
export async function createOrderBuyer(data) {
  const response = await Api.post('/quote/order', data)
  return response.data.data
}
