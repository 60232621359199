/* eslint-disable camelcase */

import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import TextField from 'components/common/text/TextField'
import Dropzone from 'components/custom/dropzone/Dropzone'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Typography from 'components/common/Typography'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { getColor } from 'theme/colors'
import { filesAddKeys, PDF_MIME } from 'utils/Files'
import PdfCard from 'components/custom/dropzone/PdfCard'
import { getFromLocalStorage } from 'utils/Utils'
import { constants } from 'utils/constants'
import useSupplierDetails from 'common-hooks/super-admin/useSupplierDetails'
import useUpdateTaxes from 'common-hooks/settings/company-taxes/useUpdateTaxes'
import { yupResolver } from '@hookform/resolvers/yup'
// import { getFormError } from 'utils/form/form'
import { updateTaxesParsing } from './CompanySettingsUtils'
import useCompanySettings from 'common-hooks/settings/company/useCompanySettings'

const useStyles = makeStyles(() => ({
  taxesTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    // marginTop: '30px',
    marginBottom: '20px',
  },
  taxesRoot: {
    background: '#fff',
    padding: '20px',
    borderRadius: '4px',
    border: '1px solid #F0F0F4',
    // marginTop: '30px',
  },
  TaxesForm: {
    display: 'flex',
    gap: '0 20px',
  },
  pdfDesign: {
    color: '#C3C5D5',
    border: '1px dashed #C3C5D5',
    borderRadius: '4px',
    width: '385px!important',
    position: 'relative',
    height: ' 80px!important',
  },
  dropZoneRoot: {
    position: 'relative',
    width: '30%',
    // display: 'flex',
    gap: '0 20px',
  },
  browseLink: {
    color: '#2680D9',
    textDecoration: 'underline',
    '& a': {
      color: '#2680D9',
    },
  },
  dropzoneTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    width: '380px',
    marginTop: '30px',
  },
}))
const schema = yup.object({
  // federal_tax_id: yup.string().matches(/^\d{9}$/, 'Please enter the valid tax id'),
})
const CompanyTaxes = ({ setTaxBtnDisable }) => {
  const classes = useStyles()
  const company = getFromLocalStorage(constants.COMPANY_ID)
  const { editTax } = useUpdateTaxes()
  let id
  if (![null, undefined, 'null', 'undefined'].includes(company)) {
    id = company.replaceAll('"', '')
  }
  const { supplierDetail } = useSupplierDetails(id)

  const {
    control,
    handleSubmit,
    setValue,
    // formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })
  const { companyProfileImagesDelete } = useCompanySettings()
  const [taxForm, setTaxForm] = useState([])
  const [taxExeForm, setTaxExeForm] = useState([])

  const [federal_tax_id, te_country, te_state] = useWatch({
    control,
    name: ['federal_tax_id', 'te_country', 'te_state'],
  })
  const handleWTaxForm = (name, newFiles) => {
    const filesArr = filesAddKeys(newFiles)
    setTaxForm(filesArr)
  }
  const handleTaxExemption = (name, newFiles) => {
    const filesArr = filesAddKeys(newFiles)
    setTaxExeForm(filesArr)
  }
  useEffect(() => {
    if (supplierDetail) {
      Object.keys(supplierDetail).map((key) => setValue(key, supplierDetail[key]))
      setTaxExeForm(supplierDetail?.tax_exemption_certificate ? supplierDetail?.tax_exemption_certificate : [])
      setTaxForm(supplierDetail?.wnine_tax_form ? supplierDetail?.wnine_tax_form : [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierDetail])
  useEffect(() => {
    if (federal_tax_id === '' || te_country === '' || te_state === '') {
      setTaxBtnDisable(true)
    } else {
      setTaxBtnDisable(false)
    }
  }, [federal_tax_id, setTaxBtnDisable, te_country, te_state])
  const onSubmit = (data) => {
    editTax(updateTaxesParsing(data, taxForm, taxExeForm))
  }
  const taxUpdatedDate = (supplierDetail) => {
    const dateObj = {}
    if (supplierDetail?.[0]?.type === 'tax_exemption_certificate') {
      dateObj.tax_exemption_certificate = supplierDetail[0]?.updated_at
    }
    if (supplierDetail?.[1]?.type === 'tax_exemption_certificate') {
      dateObj.tax_exemption_certificate = supplierDetail[1]?.updated_at
    }
    if (supplierDetail?.[0]?.type === 'wnine_tax_form') {
      dateObj.wnine_tax_form = supplierDetail[0]?.updated_at
    }
    if (supplierDetail?.[0]?.type === 'wnine_tax_form') {
      dateObj.wnine_tax_form = supplierDetail[0]?.updated_at
    }
    return dateObj
  }
  return (
    <Container>
      <form id={'taxes-form'} onSubmit={handleSubmit(onSubmit)}>
        <Container className={classes.taxesRoot}>
          <Container className={classes.taxesRootInner}>
            <Container className={classes.taxesTitle}>
              <Container>
                <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                  Tax-Exemption
                </Typography>
                <Typography variant="paragraph1" color={getColor('grey-g25')} component={'p'}>
                  Add tax-exemption details
                </Typography>
              </Container>
            </Container>
            <Container className={classes.TaxesForm}>
              <Controller
                name={'te_country'}
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label="Country"
                    width={'250px'}
                    placeholder={'Country'}
                    onChange={onChange}
                    //   error={getFormError(errors, name)}
                    className={classes.nameFiled}
                  />
                  // <Select
                  //   options={option}
                  //   id={name}
                  //   name={name}
                  //   width={'250px'}
                  //   onChange={onChange}
                  //   label={'Country'}
                  //   menuPosition={'fixed'}
                  // />
                )}
              />
              <Controller
                name={'te_state'}
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    value={value}
                    label="State"
                    width={'250px'}
                    placeholder={'State'}
                    onChange={onChange}
                    //   error={getFormError(errors, name)}
                    className={classes.nameFiled}
                  />
                )}
              />
              <Controller
                name={'federal_tax_id'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    name={name}
                    value={value}
                    // type={'number'}
                    label="Federal Tax ID Number"
                    width={'250px'}
                    placeholder={'**-*******'}
                    onChange={onChange}
                    // error={getFormError(errors, name)}
                    className={classes.nameFiled}
                  />
                )}
              />
            </Container>
            <Container className={classes.dropZoneRoot}>
              <Container>
                <Container className={classes.dropzoneTitle}>
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    {' '}
                    W-9 Tax Form
                  </Typography>
                  <a
                    href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                    // underline
                    className={classes.browseLink}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Download Template
                  </a>
                </Container>
                {taxForm?.length > 0 && (
                  <PdfCard
                    assetId={taxForm[0]?.id}
                    fileName={'wnine_tax_form'}
                    date={formatDate(
                      taxUpdatedDate(supplierDetail?.cmp_taxes_assets)?.wnine_tax_form
                        ? taxUpdatedDate(supplierDetail?.cmp_taxes_assets)?.wnine_tax_form
                        : new Date(),
                      DATE_FORMAT_PRIMARY
                    )}
                    url={supplierDetail?.wnine_tax_form || (taxForm[0]?.file && URL.createObjectURL(taxForm[0]?.file))}
                    // removeFile={() => setTaxForm([])}
                    removeFile={() => {
                      setTaxForm([])
                      companyProfileImagesDelete({ type: 'wnine_tax_form' })
                    }}
                  />
                )}
                {taxForm?.length <= 0 && (
                  <Dropzone
                    id={'drop-zone'}
                    dropzoneClassName={classes.pdfDesign}
                    multiple={false}
                    accept={PDF_MIME}
                    onChange={(e) => handleWTaxForm('w9Tax-form', e)}
                    PdfUpload
                  />
                )}
              </Container>
              <Container>
                <Container className={classes.dropzoneTitle}>
                  <Typography variant="body1" color={getColor('grey-g25')}>
                    Tax Exemption Certificate
                  </Typography>
                </Container>
                {taxExeForm?.length > 0 && (
                  <PdfCard
                    assetId={taxExeForm[0]?.id}
                    fileName={'tax_exemption_certificate'}
                    date={formatDate(
                      taxUpdatedDate(supplierDetail?.cmp_taxes_assets)?.tax_exemption_certificate
                        ? taxUpdatedDate(supplierDetail?.cmp_taxes_assets)?.tax_exemption_certificate
                        : new Date(),
                      DATE_FORMAT_PRIMARY
                    )}
                    url={
                      supplierDetail?.tax_exemption_certificate ||
                      (taxExeForm[0]?.file && URL.createObjectURL(taxExeForm[0]?.file))
                    }
                    removeFile={() => {
                      setTaxExeForm([])
                      companyProfileImagesDelete({ type: 'tax_exemption_certificate' })
                    }}
                  />
                )}
                {taxExeForm?.length <= 0 && (
                  <Dropzone
                    id={'drop-zone'}
                    dropzoneClassName={classes.pdfDesign}
                    multiple={false}
                    accept={PDF_MIME}
                    onChange={(e) => handleTaxExemption('taxExe-form', e)}
                    PdfUpload
                  />
                )}
              </Container>
            </Container>
          </Container>
        </Container>
      </form>
    </Container>
  )
}
CompanyTaxes.propTypes = {
  setTaxBtnDisable: PropTypes.func,
}

export default CompanyTaxes
