import Button from 'components/common/Button'
import Link from 'components/common/Link'
import React, { useEffect, useState } from 'react'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import { Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import TextField from 'components/common/text/TextField'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import useAuth from 'common-hooks/useAuth'
import SignInHeaderLogo from '../sign-in/SignInHeaderLogo'

const useStyles = makeStyles(() => ({
  divider: {
    margin: '30px 0px',
    width: '300px',

    backgroundColor: getColor('grey-g95'),
  },
  suggestionText: {
    margin: '0px 0px 30px 0px',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '300px',
    margin: '0 auto',
  },
  placeholderPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  backLink: {
    padding: '10px 0px 30px 0px',
  },
  btn: {
    margin: '10px 0px 0px 0px',
  },
  bottomLine: {
    color: getColor('grey-g65'),
    textAlign: 'center',
    maxWidth: '372px',
  },
  bottomLineLink: {
    color: getColor('grey-g65'),
    textDecoration: 'underline',
  },
}))

export default function ForgotResetPassword() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [btnDisable, setBtnDisable] = useState(true)
  const { resetPassword } = useAuth()
  const methods = useForm()
  const params = useParams()
  const { handleSubmit, control } = methods
  const [password, confirmPassword] = useWatch({
    control,
    name: ['password', 'confirmPassword'],
  })
  useEffect(() => {
    if (password && password === confirmPassword) {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }, [password, confirmPassword])
  function onSubmit(data) {
    resetPassword({ code: params.id, password: data.password })
    navigate('/login')
  }
  return (
    <Container className={classes.placeholderPosition}>
      <form onSubmit={handleSubmit(onSubmit)} id={'signup-account-from'}>
        <Container className={classes.mainContainer}>
          <Container className={classes.headerLogo} gap={'8px'}>
            <SignInHeaderLogo />
          </Container>
          <Divider light className={classes.divider} />
          <Container flex alignItems="center" direction={'column'} width={'100%'}>
            <Container flex direction="column" className={classes.suggestionText}>
              <Typography variant="title" color={getColor('grey-g25')}>
                Set Your Password
              </Typography>
              <Typography variant="body1" color={getColor('grey-g35')}>
                Your new password must be different to previously used password.
              </Typography>
            </Container>
            <Container flex direction={'column'} gap={'10px'}>
              <Controller
                name={'password'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={name}
                    name={name}
                    type={'password'}
                    placeholder={'Password'}
                    onChange={onChange}
                    value={value}
                    label={'New Password'}
                  />
                )}
              />
              <Controller
                name={'confirmPassword'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    id={name}
                    name={name}
                    onChange={onChange}
                    type={'password'}
                    placeholder={'Confirm Password'}
                    value={value}
                    label={'Confirm Password'}
                  />
                )}
              />

              <Button
                variant="secondary"
                border
                type="submit"
                width={'300px'}
                className={classes.btn}
                disabled={btnDisable}
              >
                Update Password
              </Button>
            </Container>

            <Container className={classes.backLink}>
              <Link variant="body2" underline to="/login" className={classes.link}>
                Back to Login
              </Link>
            </Container>
          </Container>
        </Container>
      </form>
      <Container className={classes.bottomLine}>
        <Typography variant="overline" component="div">
          By clicking above, you acknowledge that you are agree to PartsClub's{' '}
          <Link to="#">
            <Typography variant="overline" className={classes.bottomLineLink}>
              Terms & Conditions
            </Typography>
          </Link>{' '}
          and{' '}
          <Link to={'#'}>
            <Typography variant="overline" className={classes.bottomLineLink}>
              Privacy Policy.
            </Typography>
          </Link>
        </Typography>
      </Container>
    </Container>
  )
}
