import ConstructionBrandImage from 'assets/images/ConstructionBrandImage.svg'
import JcbImage from 'assets/images/JcbImage.svg'
import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'

export const TabsList = [
  { tabIndex: 0, name: 'Awaiting Shipment' },
  { tabIndex: 1, name: 'Completed' },
  { tabIndex: 2, name: 'Cancelled' },
]

export const awaitingCardData = [
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Next Day Delivery',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Local Delivery',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Pickup',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Next Day Delivery',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Local Delivery',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Pickup',
    quantity: '12',
  },
]
export const completedCardData = [
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Next Day Delivery',
    deliverOn: 'Dec. 22, 2022 between 10:00 AM to 01:15 PM',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Local Delivery',
    deliverOn: 'Dec. 22, 2022 between 10:00 AM to 01:15 PM',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Pickup',
    quantity: '12',
    deliverOn: 'Dec. 22, 2022 between 10:00 AM to 01:15 PM',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Next Day Delivery',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Local Delivery',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Pickup',
    quantity: '12',
  },
]

export const buyercompletedCardData = [
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    poNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Next Day Delivery',
    deliverOn: 'Dec. 22, 2022 between 10:00 AM to 01:15 PM',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Local Delivery',
    deliverOn: 'Dec. 22, 2022 between 10:00 AM to 01:15 PM',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Pickup',
    quantity: '12',
    deliverOn: 'Dec. 22, 2022 between 10:00 AM to 01:15 PM',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Next Day Delivery',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Local Delivery',
    quantity: '12',
  },
  {
    partName: 'Bucket Cylinder',
    brandName: 'John Deere',
    brandImg: ConstructionBrandImage,
    equpImg: JcbImage,
    partNumber: 'LW8924656',
    trackingNumber: '123456',
    orderId: '260922045559',
    soNumber: 'ORD80998066',
    buyername: 'abc',
    city: 'Ahd',
    state: 'Gujarat',
    amount: '966.00',
    delivery: 'Pickup',
    quantity: '12',
  },
]

export const timeOption = [
  { label: '12:00 AM', value: '12:00 AM' },
  { label: '01:00 AM', value: '01:00 AM' },
  { label: '02:00 AM', value: '02:00 AM' },
  { label: '03:00 AM', value: '03:00 AM' },
  { label: '04:00 AM', value: '04:00 AM' },
  { label: '05:00 AM', value: '05:00 AM' },
  { label: '06:00 AM', value: '06:00 AM' },
  { label: '07:00 AM', value: '07:00 AM' },
  { label: '08:00 AM', value: '08:00 AM' },
  { label: '09:00 AM', value: '09:00 AM' },
  { label: '10:00 AM', value: '10:00 AM' },
  { label: '11:00 AM', value: '11:00 AM' },
  { label: '12:00 PM', value: '12:00 PM' },
  { label: '01:00 PM', value: '01:00 PM' },
  { label: '02:00 PM', value: '02:00 PM' },
  { label: '03:00 PM', value: '03:00 PM' },
  { label: '04:00 PM', value: '04:00 PM' },
  { label: '05:00 PM', value: '05:00 PM' },
  { label: '06:00 PM', value: '06:00 PM' },
  { label: '07:00 PM', value: '07:00 PM' },
  { label: '08:00 PM', value: '08:00 PM' },
  { label: '09:00 PM', value: '09:00 AM' },
  { label: '10:00 PM', value: '10:00 PM' },
  { label: '11:00 PM', value: '11:00 PM' },
]

export function dataToProductCard(data) {
  if (!data) {
    return []
  }
  try {
    return data.map((share) => {
      const productDetail = share.product_details
      return {
        product_id: share.product_id,
        customer_full_name: share.user_full_name,
        name: share.name,
        inventoryFor: productDetail.inventory_for,
        view: share.view,
        img: share.assets,
        created_at: share.created_at,
        likes: share.liked,
        via: share.via,
      }
    })
  } catch (e) {
    return []
  }
}
export function parseOrderData(data, type) {
  try {
    const obj = {}
    data?.map((row) => {
      const date = formatDate(row[type], DATE_FORMAT_PRIMARY)

      if (obj[date]) {
        obj[date].push(row)
      } else {
        obj[date] = [row]
      }
      return null
    })
    return obj
  } catch (e) {
    console.error(e)
    return []
  }
}
