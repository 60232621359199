import { getProductDetails } from 'common-api/inventory/inventory'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

export default function useProductDetails() {
  const param = useParams()

  const { data, mutate } = useSWR(param?.id ? `/services/product/${param?.id}` : null, getProductDetails)

  return {
    productDetails: data,
    isLoading: !data,
    mutate,
  }
}
