import { Link as RouterLink, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'

import { forwardRef } from 'react'
import { getColor } from 'theme/colors'

const useStyles = makeStyles((theme) => ({
  routerLink: (props) => ({
    color: getColor('grey-g35'),
    ...getRouterLinkStyle(props, theme),
  }),
  activeStrong: {
    fontWeight: 700,
    color: getColor('blue-link'),
  },
}))

const Link = forwardRef(
  (
    {
      to,
      children,
      hoverUnderline,
      underline,
      className,
      linkClassName,
      activeStrong,
      asWrapper,
      disabled,
      native,
      domainColor,
      concurrency,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      hoverUnderline,
      underline,
      disabled,
      domainColor,
    })
    const location = useNavigate()

    const isActive = concurrency ? location.pathname.includes(concurrency) : location.pathname === to

    const baseClassName = classNames(classes.routerLink, linkClassName)

    const rootProps = {
      className: classNames(className, {
        [classes.activeStrong]: activeStrong && isActive,
      }),
      ...props,
    }

    const { onClick } = props

    const baseProps = {
      to,
      ref,
      className: baseClassName,
      onClick,
    }

    if (asWrapper) {
      return <RouterLink {...baseProps}>{children}</RouterLink>
    }

    if (native) {
      return (
        <a href={to} className={classNames(baseClassName, linkClassName)} {...props}>
          {children}
        </a>
      )
    }

    return (
      <Typography {...rootProps}>
        <RouterLink {...baseProps}>{children}</RouterLink>
      </Typography>
    )
  }
)

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  underline: PropTypes.bool,
  hoverUnderline: PropTypes.bool,
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  activeStrong: PropTypes.bool,
  asWrapper: PropTypes.bool,
  disabled: PropTypes.bool,
  native: PropTypes.bool,
  domainColor: PropTypes.bool,
  concurrency: PropTypes.string,
  onClick: PropTypes.func,
}

Link.displayName = 'Link'

export default Link

function getRouterLinkStyle(props, theme) {
  return {
    textDecoration: 'none',
    ...(props.hoverUnderline && {
      '&:hover': {
        textDecoration: 'underline',
      },
    }),
    ...(props.underline && {
      textDecoration: 'underline',
    }),
    ...(props.disabled && {
      pointerEvents: 'none',
      cursor: 'default',
    }),
    ...(props.domainColor && {
      color: theme.palette.domain.main,
    }),
  }
}
