/* eslint-disable no-nested-ternary */
import React from 'react'
import Container from 'components/common/Container'
import Placeholder from 'assets/images/Placeholder.png'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Button from 'components/common/Button'

// import Chip from 'components/common/Chip'
import Image from 'components/common/Image'
import { formatPrice, getFlag, getShortAddress } from 'utils/Utils'
import { getColor } from 'theme/colors'
import Chip from 'components/common/Chip'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  mainContainer: {
    marginBottom: '20px',
    width: '800px',
    // height: '160px',
    background: '#fff',
    padding: '20px',
    gap: '10px',
    border: '1px solid #F0F0F4',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: ' 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
    },
  },
  name: {
    // width: '280px',
  },
  subContainer: {
    // gap: '3px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // margin: '6px 0px',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },

  img: {
    width: '70px',
    height: '60px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),
  },
  img2: {
    width: '70px',
    height: '60px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),
    borderTop: '0',
  },
  dot: {
    color: '#4A4D68',
    padding: '0px 5px',
  },
  flag: {
    paddingLeft: '5.36px',
  },
  chipContainer: {
    paddingLeft: '4px',
  },
  premimumChip: {
    height: '20px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    color: '#13141B',
    borderRadius: '100px',
  },
  btnRoot: {
    marginTop: '10px',
  },
  deliverychip: {
    color: '#19528C',
    background: '#E0F0FF',
    borderRadius: '100px',
    '& span': {
      color: '#19528C',
    },
  },
}))

export default function BuyerOrderCancelCard({
  partName,
  brandName,
  brandImg,
  equpImg,
  partNumber,
  orderId,
  poNumber,
  trackingNumber,
  id,
  buyername,
  city,
  state,
  amount,
  delivery,
  quantity,
  shipvia,
  country,
}) {
  const classes = useStyles()
  //   const date = createdAt
  const navigate = useNavigate()

  return (
    <Container flex direction={'column'}>
      <Container
        flex
        className={classes.mainContainer}
        onClick={() => {
          navigate(`/Orders/Cancelled/${orderId}/${id}`)
        }}
      >
        <Container flex direction={'column'}>
          <Image src={brandImg || Placeholder} alt="LET'S SPARK UP LOGO" className={classes.img} />
          <Image src={equpImg || Placeholder} alt="Machine parts" className={classes.img2} />
        </Container>
        <Container width={'100%'} flex gap={'5px 0'} direction={'column'}>
          <Container flex justifyContent={'space-between'} className={classes.subContainer}>
            <Typography variant="title" color={getColor('grey-g25')}>
              {brandName && `${brandName} -`} {partName} {partNumber !== null ? `- ${partNumber}` : ''}
            </Typography>
            <Typography variant="title" color={getColor('grey-g25')}>{`Qty: ${quantity}  • ${formatPrice(
              amount
            )}`}</Typography>
          </Container>
          <Container flex className={classes.subContainer} direction={'column'}>
            <Container flex justify="space-between" width="100%">
              <Container>
                {Object.keys(delivery).length > 1 ? (
                  <>
                    {delivery.freight_next_day && (
                      <Typography variant="body1" color={getColor('grey-g65')}>
                        Tracking Number: - {trackingNumber}
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    {!delivery?.mdo_local_delivery && !delivery?.mdo_pick_up && (
                      <Typography variant="body1" color={getColor('grey-g35')}>
                        Tracking Number: - {trackingNumber}
                      </Typography>
                    )}
                    {delivery?.mdo_local_delivery && (
                      <Typography variant="body1" color={getColor('grey-g35')}>
                        {/* Deliver on Dec. 22, 2022 between 10:00 AM to 01:15 PM {deliverOn} */}
                        Order Deliver on
                      </Typography>
                    )}
                    {delivery?.mdo_pick_up && (
                      <Typography variant="body1" color={getColor('grey-g35')}>
                        {''}
                      </Typography>
                    )}
                  </>
                )}
              </Container>

              <Container>
                {Object.keys(delivery).length > 1 ? (
                  <>
                    {delivery?.freight_next_day && (
                      <Chip
                        label={delivery?.freight_next_day && 'Next Day Delivery'}
                        className={classes.deliverychip}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {shipvia && <Chip label={shipvia} />}
                    {delivery?.freight_next_day && (
                      <Chip
                        label={delivery?.freight_next_day && 'Next Day Delivery'}
                        className={classes.deliverychip}
                      />
                    )}
                    {delivery?.mdo_pick_up && (
                      <Chip label={delivery?.mdo_pick_up && 'Pickup'} className={classes.deliverychip} />
                    )}
                    {delivery?.mdo_local_delivery && (
                      <Chip
                        label={delivery?.mdo_local_delivery && 'Local Delivery '}
                        className={classes.deliverychip}
                      />
                    )}
                    {delivery?.freight_ground && (
                      <Chip label={delivery?.freight_ground && 'Ground'} className={classes.deliverychip} />
                    )}
                    {delivery?.mdo_ltl && <Chip label={delivery?.mdo_ltl && 'LTL'} className={classes.deliverychip} />}
                    {delivery?.freight_second_day && (
                      <Chip label={delivery?.freight_second_day && 'Second Day'} className={classes.deliverychip} />
                    )}
                  </>
                )}
              </Container>
            </Container>
            <Container>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                Order ID: {orderId} {''}
              </Typography>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                • PO Number: {poNumber}
              </Typography>
            </Container>

            <Container flex width={'100%'} justify={'space-between'} className={classes.btnRoot}>
              <Container>
                <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                  {buyername}
                </Typography>
                <Container display="flex" alignItems="center" gap="5px">
                  <Typography variant="paragraph1" color={getColor('grey-g35')}>
                    {getShortAddress(city, state, country)}
                  </Typography>
                  {getFlag(country)}
                </Container>
              </Container>
              <Container className={classes.btn}>
                <Button
                  variant="secondary"
                  border
                  onClick={() => {
                    navigate(`/Orders/Cancelled/${orderId}/${id}`)
                  }}
                >
                  View Details
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

BuyerOrderCancelCard.propTypes = {
  partName: PropTypes.string,
  brandName: PropTypes.string,
  brandImg: PropTypes.node,
  equpImg: PropTypes.node,
  partNumber: PropTypes.string,
  orderId: PropTypes.string,
  poNumber: PropTypes.string,
  buyername: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  amount: PropTypes.string,
  delivery: PropTypes.string,
  quantity: PropTypes.string,
  trackingNumber: PropTypes.string,
  id: PropTypes.string,
  shipvia: PropTypes.string,
  country: PropTypes.string,
  // deliverOn: PropTypes.string,
  // dilverTime: PropTypes.string,
}
