import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'
import TextField from 'components/common/text/TextField'
import Container from 'components/common/Container'
import useToggle from 'common-hooks/useToggle'
import EmailDialog from 'components/custom/dialog/EmailDialog'
import { Done } from 'assets/icons'

const useStyles = makeStyles(() => ({
  textField: {
    position: 'relative',
    '& input': {
      color: '#36384C!important',
    },
  },
  linkText: {
    position: 'absolute',
    right: '0',
    zIndex: 111,
    textDecoration: 'underline',
    color: getColor('func-info'),
    fontSize: '0.75rem',
    fontWeight: 300,
    lineHeight: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  doneIcon: {
    position: 'absolute',
    top: '55%',
    right: '10px',
  },
}))

const EmailField = forwardRef(({ onChange, className, value, id, verifyEmail, label, updateEmail, ...props }, ref) => {
  const classes = useStyles()
  const [openDialog, toggleDialog] = useToggle(false)
  const handleChange = (e) => {
    if (onChange) onChange(e.target.value)
  }

  return (
    <Container className={classNames(className, classes.textField)}>
      <Container className={classes.linkText} onClick={toggleDialog}>
        Update
      </Container>
      <TextField ref={ref} onChange={handleChange} label={label} value={value} {...props} id={id} />
      <Container className={classes.doneIcon}>
        <Done />
      </Container>
      <EmailDialog
        onclose={toggleDialog}
        open={openDialog}
        emailID={value || ''}
        verifyEmail={verifyEmail}
        updateEmail={updateEmail}
      />
    </Container>
  )
})

EmailField.displayName = 'EmailField'

EmailField.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  verifyEmail: PropTypes.func,
  updateEmail: PropTypes.func,
}

export default EmailField
