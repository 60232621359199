import axios from 'axios'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import handleError from './handleError'
import { stringifyQuery } from './url'

class Api {
  constructor() {
    this.instance = axios.create({
      baseURL: `${
        process.env.REACT_APP_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV
      }`,
    })
    this.instance.interceptors.request.use((req) => {
      if (req?.url !== 'services/login') {
        const _token = getFromLocalStorage(constants.TOKEN) || ''
        if (_token) {
          req.headers.Authorization = `Bearer ${_token}`
        }
      }
      return req
    })
  }

  get(endpoint, params = {}, config = {}) {
    return this.instance
      .get(endpoint, {
        ...config,
        params,
      })
      .catch((error) => handleError(error, config))
  }

  post(endpoint, data = {}, config) {
    return this.instance.post(endpoint, data, config).catch((error) => handleError(error, config))
  }

  put(endpoint, data = {}, config) {
    return this.instance.put(endpoint, data, config).catch((error) => handleError(error, config))
  }

  patch(endpoint, data = {}, config) {
    return this.instance.patch(endpoint, data, config).catch((error) => handleError(error, config))
  }

  delete(endpoint, config) {
    return this.instance.delete(endpoint, config).catch((error) => handleError(error, config))
  }

  deleteWithObj(endpoint, data, config) {
    return this.instance.delete(endpoint, { data, config }).catch((error) => handleError(error, config))
  }

  request(config) {
    return this.instance.request(config).catch((error) => handleError(error, config))
  }

  xWWWFormURLEncoded(endpoint, method, data) {
    return this.instance({
      method,
      url: endpoint,
      data: stringifyQuery(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).catch((error) => handleError(error))
  }

  formData(endpoint, method, data) {
    return this.instance({
      method,
      url: endpoint,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).catch((error) => handleError(error))
  }
}

export default new Api()
