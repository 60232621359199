import Api from 'services/Api'

export async function addShippingAddress(data) {
  const response = await Api.post('/shipping/address', data)
  return response.data.data
}

export async function editShippingAddress(data) {
  const response = await Api.put('/shipping/address', data)
  return response.data.data
}

export async function deleteShippingAddress(data) {
  const response = await Api.delete('/shipping/address', data)
  return response.data.data
}

export async function getAllShippingAddresss() {
  const response = await Api.get('/shipping/address')
  return response.data.data
}

export async function setDefaultShippingAddress(data) {
  const response = await Api.put('/mark-default', data)
  return response.data.data
}

// profile

export async function editSettingProfile(data) {
  const response = await Api.put('/user-detail', data)
  return response.data.data
}

export async function getProfileSettingData(data) {
  const response = await Api.get('/user-detail', data)
  return response.data.data
}

// company

export async function createShippoAccount(data) {
  const response = await Api.post('/company/shippo/api-key', data)
  return response.data.data
}
export async function getCompanyCarriers(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getCompanyPolicies(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function createPolices(data) {
  const response = await Api.post('/company/policy', data)
  return response.data.data
}
export async function deleteProfileImages(data) {
  const response = await Api.delete('/company/asset', { data })
  return response.data.data
}

export async function unlinkShippoAccount() {
  const response = await Api.put('/company/shippo/unlink')
  return response.data.data
}

// subscription

export async function getSubscriptionPlans(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getSubscriptionPaymentHistory(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function subscriptionCancel(id) {
  const response = await Api.delete(`/subscription/cancel/${id}`)
  return response.data.data
}
export async function subscriptionInvoiceDownload(data) {
  const response = await Api.post(`/subscription/invoice`, data)
  return response.data.data
}

// verifyEmail
export async function verifyProfileEmail(email) {
  const response = await Api.put('/verify-email', { email })
  return response.data.data
}

// updateEmail
export async function updateProfileEmail(email, code, password) {
  const response = await Api.put('/update-email', { email, code, password })
  return response
}

// update company settings
export async function editCompanySettings(data) {
  const response = await Api.put('/company', data)
  return response
}

export async function deleteProfileImage(data) {
  const response = await Api.delete(`/company-asset/${data?.type}`)
  return response.data.data
}

// payment

export async function getPaymentMethods(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getSetupIntent() {
  const response = await Api.get('/subscription/setupIntents')
  return response.data.data
}

export async function createSubscription(data) {
  const response = await Api.post('/subscription/create', data)
  return response.data.data
}

export async function checkout(data) {
  const response = await Api.post('/subscription/checkout', data)
  return response.data.data
}

// update taxes
export async function updateTaxes(data) {
  const response = await Api.put('/company/tax-exempt', data)
  return response.data.data
}

// Delete Card
export async function deleteCard(id) {
  const response = await Api.delete(`/subscription/payment-method/remove/${id}`)
  return response.data.data
}
// Defalut Card
export async function setAsDefalutCard(id) {
  const response = await Api.put(`/subscription/payment-method/default`, id)
  return response.data.data
}
export async function deleteUserProfileImage(data) {
  const response = await Api.deleteWithObj(`/remove/assets`, data)
  return response.data.data
}
