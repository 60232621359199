import Container from 'components/common/Container'
import { React } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getFromLocalStorage } from 'utils/Utils'
import { constants } from 'utils/constants'
import PaymentMethodForm from './PaymentMethodForm'

const useStyles = makeStyles({
  root: {
    padding: '20px',
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY)

export default function PaymentMethodDialog({ open, onClose, successURL }) {
  const classes = useStyles()
  const appearance = {
    theme: 'flat',
    variables: {
      // colorPrimary: '#C3C5D5',
      // colorBackground: '#C3C5D5',
      colorText: '#36384C',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      borderRadius: '2px',
    },
    rules: {
      '.Input:focus': {
        boxShadow: 'none',
      },
      '.Input': {
        colorBackground: '#C3C5D5',
      },
    },
  }
  const options = {
    clientSecret: getFromLocalStorage(constants.CLIENT_SECRET),
    appearance,
  }

  return (
    <Dialog open={open} size={'sm'}>
      <DialogHead title={'Credit Card'} onClose={onClose} />
      <Container className={classes.root}>
        <Elements stripe={stripePromise} options={options}>
          <PaymentMethodForm successURL={successURL} />
        </Elements>
      </Container>
      <DialogFooter justify={'flex-end'}>
        <Container flex gap={'0 10px'}>
          <Button variant="secondary" border onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" form="add-payment-method-from">
            Add/Update
          </Button>
        </Container>
      </DialogFooter>
    </Dialog>
  )
}
PaymentMethodDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  successURL: PropTypes.string,
}
