import { getSearchList } from 'common-api/search-product/searchProduct'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'

import { useState } from 'react'
import { useLocation } from 'react-router-dom'

export default function useProductSearchList(type, filterValue) {
  const location = useLocation()

  const [search, onSearch] = useState(location?.state?.searchValue ? location?.state?.searchValue : '')
  const PAGE_SIZE = 14
  const [page, setPage] = useState(1)
  const params = {
    ...(type && { user_type: type }),
    ...(search && { search }),
    ...(filterValue === 'az' && { order: 'asc' }),
    ...(filterValue === 'az' && { order_by: 'title' }),
    ...(filterValue === 'za' && { order: 'desc' }),
    ...(filterValue === 'za' && { order_by: 'title' }),
    ...(filterValue === 'lowhigh' && { order: 'asc' }),
    ...(filterValue === 'lowhigh' && { order_by: 'price' }),
    ...(filterValue === 'highlow' && { order: 'desc' }),
    ...(filterValue === 'highlow' && { order_by: 'price' }),
    limit: PAGE_SIZE,
    page,
  }

  const { data } = useSwrInfinite('/services/product', params, getSearchList)
  if (data && data.length === 0 && page > 1) {
    setPage((pre) => pre - 1)
  }
  return {
    searchList: data,
    search,
    isLoading: !data,
    onSearch,
    setPage,
    page,
  }
}
