import PropTypes from 'prop-types'
import { useForm, useWatch, FormProvider } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import SearchField from 'components/common/search/SearchField'
import { useContext, useEffect, useState } from 'react'
import { getColor } from 'theme/colors'
import { TabPanel, Tabs } from 'components/common/Tabs'
// import useSearch from 'common-hooks/useSearch'
import useEquipmentsList from 'common-hooks/create-quote/useEquipmentsList'
import useIndustryList from 'common-hooks/useIndustryList'

import EllipsisVerticleIcon from 'assets/icons/UnionIcon.svg'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { useFormData } from './FormProvider'
import { BuyerQuoteContext } from '../BuyerQuotesUtils'
import LabeledImageCard from '../LabeledImageCard'
import FormFooter from './FormFooter'
// import { Typography } from '@mui/material'

const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    width: '780px',
  },
  gridContainer: {
    gridTemplateColumns: 'auto auto auto',
    padding: '10px',
    '& div': {
      display: 'block',
    },
  },
  gridItem: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'lightblue',
    border: '1px solid black',
  },
  mainBox: {
    padding: '10px',
  },
  gridMediaContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '579px',
    overflowY: 'scroll',
    flexDirection: 'column',
    backgroundColor: getColor('system-bg'),
    padding: (props) => getPadding(props.filedVisibility),

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  body: {
    backgroundColor: getColor('system-bg'),
    padding: '75px 30px 0 30px',
  },
  popular: {
    alignItems: 'unset',
    gap: '10px 0',
    marginBottom: '10px',
  },
  populargrid: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '20px',
    // width: '780px',
  },
  bodyheaderbg: {
    backgroundColor: getColor('system-bg'),
    padding: '0 30px',
    paddingTop: '21px',
    paddingBottom: '21px',
    position: 'absolute',
    width: '100%',
    zIndex: '999',
  },
  search: {
    '& input': {
      borderRadius: 'unset',
    },
  },
  infotooltip: {
    padding: '0 30px',
    backgroundColor: getColor('system-bg'),
    '& div': {
      padding: '8px 0',
    },
  },
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '47%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
  searchFiled: {
    padding: '0px 0px 20px 0px',
  },
  searchBar: {
    background: '#fff',
  },
}))
export default function EquipmentsForm({ formStep, nextFormStep, setnextBtn, nextBtn, quoteTab }) {
  const { setFormValues } = useFormData()
  const { industryData } = useIndustryList()
  if (industryData && industryData.length > 3) {
    industryData.length = 3
  }
  const other = {
    id: 81,
    name: 'Other',
    cover_image: EllipsisVerticleIcon,
  }
  const { onSaveBuyerQuoteInformaton, BuyerQueoteInformation } = useContext(BuyerQuoteContext)
  const [activeCard, setActiveCard] = useState(BuyerQueoteInformation?.Equipments?.equipment?.name || '')
  const [tabId, setTabId] = useState('')
  const [tab, setTab] = useState(BuyerQueoteInformation?.Equipments?.tab || 0)
  const [filedVisibility, setFiledVisibility] = useState(false)
  const classes = useStyles({ filedVisibility })
  useEffect(() => {
    if (industryData) {
      setTabId(industryData[0].id)
    }
    if (quoteTab === 'Industrial & Aerial') {
      setTab(2)
      setTabId(industryData?.[2].id)
    }
    if (quoteTab === 'Construction & Mining') {
      setTab(1)
      setTabId(industryData?.[1].id)
    }
    if (quoteTab === 'Agricultural & Turf') {
      setTab(0)
      setTabId(industryData?.[0].id)
    }
  }, [industryData])
  const { equipmentsList, search, onSearch, isLoading } = useEquipmentsList(tabId)
  const methods = useForm({
    defaultValues: {
      ...BuyerQueoteInformation.equipment,
      ...BuyerQueoteInformation.brand,
      equipment: BuyerQueoteInformation?.Equipments?.equipment,
    },
  })
  const { handleSubmit, setValue, watch, control } = methods

  const fields = watch()
  useEffect(() => {
    if (fields.equipment === 'Other') {
      setFiledVisibility(true)
    } else {
      setFiledVisibility(false)
    }
  }, [fields.equipment])

  const handleChange = (event, newValue) => {
    setValue('equipmentType', event.target.name)
    setTab(newValue)
    switch (newValue) {
      case 0:
        setTabId(industryData[0].id)
        break
      case 1:
        setTabId(industryData[1].id)
        break
      case 2:
        setTabId(industryData[2].id)
        break
      default:
        setTabId(industryData[0].id)
        break
    }
  }

  const [equipmentName, setEquipmentName] = useState(BuyerQueoteInformation?.Equipments?.equipmentName || '')
  const onSaveContextValue = () => {
    const Equipments = {
      equipment,
      equipmentName,
      tabId,
      tab,
    }
    onSaveBuyerQuoteInformaton({ ...BuyerQueoteInformation, Equipments })
  }
  const selectEquipment = (name, id, coverImg) => {
    setValue('equipment', { name, id, coverImg })
    setActiveCard(name)
    // setnextBtn(false)
  }

  const [equipment] = useWatch({
    control,
    name: ['equipment'],
  })

  // const popular = []
  // const all = []
  // equipmentsList?.map((obj) => {
  //   if (obj?.is_popular) {
  //     popular.push(obj)
  //   }
  //   if (!obj?.is_popular) {
  //     all.push(obj)
  //   }
  //   return 0
  // })
  const onSubmit = (values) => {
    setFormValues(values)
    onSaveContextValue()
    nextFormStep(formStep + 1)
  }
  useEffect(() => {
    if (BuyerQueoteInformation?.Equipments?.tab) {
      setTab(BuyerQueoteInformation?.Equipments?.tab)
      switch (BuyerQueoteInformation?.Equipments?.tab) {
        case 0:
          setTabId(industryData[0].id)
          break
        case 1:
          setTabId(industryData[1].id)
          break
        case 2:
          setTabId(industryData[2].id)
          break
        default:
          setTabId(industryData[0].id)
          break
      }
    }
  }, [BuyerQueoteInformation?.Equipments?.tab])
  useEffect(() => {
    if (activeCard !== '' && activeCard !== 'Other') {
      setnextBtn(false)
    } else if (activeCard === 'Other' && equipmentName !== '') {
      setnextBtn(false)
    } else {
      setnextBtn(true)
    }
  })
  return (
    <FormProvider {...methods}>
      <Container>
        {/* <form id={`hook-form-${formStep}`} onSubmit={handleSubmit(onSubmit)}> */}
        <form id={`hook-form-${formStep}`} onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.bodyheaderbg}>
            <Container className={classes.searchFiled}>
              <SearchField
                id="search-feild"
                border={'unset'}
                placeholder={'Search equipment'}
                onSearch={(e) => {
                  onSearch(e)
                }}
                value={search}
                className={classes.searchBar}
              />
            </Container>
            <Container flex direction="row" justify="flex-start" gap={'15px'}>
              <Container>
                <Tabs value={tab} onchange={handleChange} array={industryData} />
              </Container>
            </Container>
          </Container>

          <Container className={classes.body}>
            <TabPanel value={tab} index={0}>
              <div className={classes.gridMediaContainer}>
                {isLoading && <LoadingOverlay />}
                <Container flex direction={'column'} className={classes.popular}>
                  {/* If need to change popular quip this code will help */}

                  {/* {!isLoading && <Typography variant="title1">Popular</Typography>} */}
                  <Container className={classes.populargrid}>
                    {equipmentsList?.map((eItem, index) => (
                      <LabeledImageCard
                        key={index}
                        {...eItem}
                        value={activeCard}
                        onChange={selectEquipment}
                        formStep={formStep}
                      />
                    ))}
                    {!isLoading && <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />}
                  </Container>
                </Container>
                {/* If need to change popular quip this code will help */}
                {/* <Container flex direction={'column'} className={classes.popular}>
                  {!isLoading && <Typography variant="title1">All</Typography>}
                  <Container className={classes.populargrid}>
                    {all?.map((eItem, index) => (
                      <LabeledImageCard
                        key={index}
                        {...eItem}
                        value={activeCard}
                        onChange={selectEquipment}
                        formStep={formStep}
                      />
                    ))}
                    {!isLoading && <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />}
                  </Container>
                </Container> */}
              </div>
            </TabPanel>

            <TabPanel value={tab} index={1}>
              <div className={classes.gridMediaContainer}>
                {isLoading && <LoadingOverlay />}
                <Container flex direction={'column'} className={classes.popular}>
                  {/* If need to change popular quip this code will help */}
                  {/* {!isLoading && <Typography variant="title1">Popular</Typography>} */}
                  <Container className={classes.populargrid}>
                    {equipmentsList?.map((eItem, index) => (
                      <LabeledImageCard
                        key={index}
                        {...eItem}
                        value={activeCard}
                        onChange={selectEquipment}
                        formStep={formStep}
                      />
                    ))}
                    {!isLoading && <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />}
                  </Container>
                </Container>
                {/* If need to change popular quip this code will help */}
                {/* <Container flex direction={'column'} className={classes.popular}>
                  {!isLoading && <Typography variant="title1">All</Typography>}
                  <Container className={classes.populargrid}>
                    {all?.map((eItem, index) => (
                      <LabeledImageCard
                        key={index}
                        {...eItem}
                        value={activeCard}
                        onChange={selectEquipment}
                        formStep={formStep}
                      />
                    ))}
                    {!isLoading && <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />}
                  </Container>
                </Container> */}
              </div>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <div className={classes.gridMediaContainer}>
                {isLoading && <LoadingOverlay />}
                <Container flex direction={'column'} className={classes.popular}>
                  {/* If need to change popular quip this code will help */}
                  {/* {!isLoading && <Typography variant="title1">Popular</Typography>} */}
                  <Container className={classes.populargrid}>
                    {equipmentsList?.map((eItem, index) => (
                      <LabeledImageCard
                        key={index}
                        {...eItem}
                        value={activeCard}
                        onChange={selectEquipment}
                        formStep={formStep}
                      />
                    ))}
                    {!isLoading && <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />}
                  </Container>
                </Container>
                {/* If need to change popular quip this code will help */}
                {/* <Container flex direction={'column'} className={classes.popular}>
                  {!isLoading && <Typography variant="title1">All</Typography>}
                  <Container className={classes.populargrid}>
                    {all?.map((eItem, index) => (
                      <LabeledImageCard
                        key={index}
                        {...eItem}
                        value={activeCard}
                        onChange={selectEquipment}
                        formStep={formStep}
                      />
                    ))}
                    {!isLoading && <LabeledImageCard {...other} value={activeCard} onChange={selectEquipment} />}
                  </Container>
                </Container> */}
              </div>
            </TabPanel>
          </Container>

          <FormFooter
            equipmentTypes={fields.equipment}
            currentStep={formStep}
            setEquipmentName={setEquipmentName}
            equipmentName={equipmentName}
            nextBtn={nextBtn}
          />
        </form>
      </Container>
    </FormProvider>
  )
}
EquipmentsForm.propTypes = {
  formStep: PropTypes.number,
  nextFormStep: PropTypes.func,
  setnextBtn: PropTypes.func,
  nextBtn: PropTypes.number,
  quoteTab: PropTypes.string,
}
function getPadding(textFiledVisibility) {
  return `${!textFiledVisibility ? '52px 0 30px 0' : '52px 0 100px 0'}`
}
