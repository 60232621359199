import Drawer from 'components/custom/drawer/Drawer'
import DrawerHeader from 'components/custom/drawer/DrawerHeader'
import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import Select from 'components/common/select/Select'
// import useIndustryList from 'common-hooks/useIndustryList'
import { makeStyles } from '@mui/styles'
import Seperator from 'components/common/Seperator'
import { Controller, useForm, useWatch } from 'react-hook-form'
import Button from 'components/common/Button'
import { ResetIcon } from 'assets/icons'
import IconButton from 'components/common/IconButton'
// import { getOptionsList } from 'utils/Utils'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import { countryOption } from 'containers/settings/company/CompanySettingsUtils'
import MonthYearPicker from 'components/common/MonthYearPicker'
import { createOption } from 'components/common/text/DropDownTextField'
import { plans } from '../superAdminUtils'
import moment from 'moment'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  subContainer: {
    padding: '20px',
    height: '90vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  container: {
    '& label': {
      color: 'red',
    },
  },
  innerContainer: {
    alignItems: 'unset',
  },
  conditionFlex: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  footer: {
    padding: '20px',
    borderTop: '1px solid #F0F0F4',
  },
  iconBtn: {
    width: '84%',
    justifyContent: 'end',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  joinDate: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
    '& .MuiInputBase-colorPrimary': {
      background: '#F8F9F9',
    },
    // '& input': {
    //   '&::placeholder': {
    //     color: '#9799B5',
    //     fontFamily: 'Lexend Deca',
    //     fontWeight: '300',
    //   },
    // },
  },
})

export default function FilterDialog({ open, onClose, setFilterData }) {
  const classes = useStyles()
  // const { industryData } = useIndustryList()
  // const industryOptions = getOptionsList(industryData)
  // const [industries, setIndustries] = useState([])

  const country = countryOption?.map((values) => createOption(values, values))

  const { control, handleSubmit, getValues, setValue, reset } = useForm()
  const { toastMessage } = useToastify()
  const [startDate, endDate] = useWatch({
    control,
    name: ['from_date', 'to_date'],
  })

  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    console.log(data, 'data')
    if ((data?.from_date && data?.to_date === undefined) || (data?.from_date && data?.to_date === null)) {
      toastMessage('error', 'Please select To date')
      return 0
    }
    if ((data?.from_date === undefined && data?.to_date) || (data?.from_date === null && data?.to_date)) {
      toastMessage('error', 'Please select From date')
      return 0
    }

    const startOfDay = moment(startDate).startOf('day').toISOString()
    const endOfDay = moment(endDate).endOf('day').toISOString()
    setFilterData({
      // ...(data?.industry && { industry: data?.industry?.map((obj) => obj?.value)?.join() }),
      ...(data?.country && { country: data?.country?.map((obj) => obj?.value)?.join() }),
      ...(data?.subscription && { plan_name: data?.subscription?.map((obj) => obj?.value)?.join() }),
      ...(data?.from_date && { from_date: startOfDay }),
      ...(data?.to_date && { to_date: endOfDay }),
      // ...(data?.from_date && { from_date: JSON.stringify(data?.from_date) }),
      // ...(data?.to_date && { to_date: JSON.stringify(data?.to_date) }),
    })
    onClose()
  }

  const handleOnreset = () => {
    reset({
      industry: '',
      country: '',
      subscription: '',
      from_date: null,
      to_date: null,
    })
  }

  return (
    <>
      <Drawer open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className={classes.mainContainer}>
            <DrawerHeader title="Filter" onClose={onClose}>
              <IconButton className={classes.iconBtn} onClick={handleOnreset}>
                <ResetIcon />
              </IconButton>
            </DrawerHeader>

            <Container className={classes.subContainer}>
              <Container flex direction={'column'} gap={'20px'} className={classes.innerContainer}>
                {/* <Controller
                  name={'industry'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      multi
                      options={industryOptions}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={'Industry'}
                    />
                  )}
                /> */}
                <Controller
                  name={'subscription'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      multi
                      options={plans}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={'Subscription'}
                    />
                  )}
                />
                <Controller
                  name={'country'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Select
                      multi
                      options={country}
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      label={'Country'}
                    />
                  )}
                />
                <Seperator />
                <Typography variant="title" color={getColor('grey-g25')}>
                  Joined
                </Typography>
                <Container className={classes.joinDate}>
                  <Controller
                    name="from_date"
                    control={control}
                    render={({ field: { name, value } }) => (
                      <MonthYearPicker
                        // views={['year', 'month']}
                        views={['year', 'month', 'day']}
                        label="From"
                        maxDate={new Date()}
                        value={value}
                        dateFormate={'MMM. DD, YYYY'}
                        onChange={(newValue) => {
                          setValue(name, newValue)
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="to_date"
                    control={control}
                    render={({ field: { name, value } }) => (
                      <MonthYearPicker
                        // views={['year', 'month']}
                        views={['year', 'month', 'day']}
                        label="To"
                        minDate={getValues().start_date || startDate}
                        maxDate={new Date()}
                        value={value}
                        dateFormate={'MMM. DD, YYYY'}
                        onChange={(newValue) => {
                          setValue(name, newValue)
                        }}
                      />
                    )}
                  />
                </Container>
              </Container>
            </Container>
            <Container className={classes.footer}>
              <Button type="submit" width="100%">
                Apply
              </Button>
            </Container>
          </Container>
        </form>
      </Drawer>
    </>
  )
}
FilterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setFilterData: PropTypes.func,
}
