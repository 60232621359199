import { AddIcon, InfoSmallIcon, RedDeleteIcon } from 'assets/icons'
import Dropzone from 'components/custom/dropzone/Dropzone'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import PropTypes from 'prop-types'
import FileUpload from 'components/custom/dropzone/FileUpload'
import TwoWayController from 'components/custom/TwoWayController'
import React, { useContext, useState } from 'react'
import { filesCreateIds, IMAGE_MIME } from 'utils/Files'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import IconButton from 'components/common/IconButton'
import TextField from 'components/common/text/TextField'
import TextArea from 'components/common/text/TextArea'
import Tooltip from 'components/common/Tooltip'
import { BuyerQuoteContext } from '../BuyerQuotesUtils'
import { allowOnlyAlphaNumric } from 'utils/Utils'
import { getColor } from 'theme/colors'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '10px',
  },
  textArea: {
    height: '56px !important',
  },
  eqpimage: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      width: '36px',
      height: '36px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      '& img': {
        width: '36px',
        height: '36px',
        border: '1px solid #C3C5D5',
      },
    },
  },
  textCover: {
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: ' center',
    background: '#fff',
    alignItems: 'center',
    border: '1px dashed #C3C5D5',
    '& div': {
      margin: '0',
      marginTop: '4px',
    },
  },
  imageClass: {
    marginRight: '10px',
  },
  txtfiledClass: {
    background: '#fff',
  },
  partNameInput: {
    background: '#fff',
  },
}))

export default function MultipleParts({ partsData }) {
  const classes = useStyles()
  const { toastMessage } = useToastify()
  const methods = useForm()
  const { setValue, register, control } = methods
  const { visibleTextBoxData, setTextBoxVisibilityData, onSaveBuyerQuoteInformaton } = useContext(BuyerQuoteContext)
  const arr2 = [...visibleTextBoxData]
  const [, setImagesArray] = useState()
  const [partsArray, setPartsArray] = useState(partsData)
  const handleFileChange = (name, prevState, newFiles, index) => {
    const MAX_FILE_SIZE = 2048 // 2MB
    const fileSizeKiloBytes = newFiles?.map((obj) => obj?.size / 1024)
    if (fileSizeKiloBytes?.[0] > MAX_FILE_SIZE) {
      toastMessage('error', 'Upload image max size 2mb')
      return 0
    }
    const filesArr = filesCreateIds(prevState[name] ? [...prevState[name], ...newFiles] : newFiles)

    setValue(name, filesArr)
    if (filesArr.length > 3) {
      filesArr.length = 3
    }
    const arr = [...partsArray]
    arr[index][name] = filesArr
    setImagesArray(filesArr)
    setPartsArray(arr)
    // eslint-disable-next-line no-plusplus
    return 0
  }
  const removeSecondParsImage = (assetId, name, i, index) => {
    const arr = [...partsArray]
    const filesArr = arr[index][name].filter((i) => i.id !== assetId)

    arr[index][name] = filesArr
    setPartsArray(arr)
    setImagesArray(filesArr)
  }

  function handleChange(name, value, index) {
    const arr = [...partsArray]
    arr[index][name] = value
    arr[index].charCount = value?.length
    setPartsArray(arr)
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < arr2?.length; x++) {
      arr2[x].quntity = partsArray[x].quntity
    }
    setTextBoxVisibilityData(arr2)
  }
  function handleChangeNotes(name, value, index) {
    const arr = [...partsArray]
    arr[index][name] = value
    arr[index].charCount = value?.length
    setPartsArray(arr)
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < arr2?.length; x++) {
      arr2[x].notes = partsArray[x][name]
    }
    setTextBoxVisibilityData(arr2)
  }
  function handleChangeNumber(name, value, index) {
    const arr = [...partsArray]
    arr[index][name] = value
    setPartsArray(arr)
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < arr2?.length; x++) {
      arr2[x].number = partsArray[x][name]
    }
    setTextBoxVisibilityData(arr2)
  }
  function removeParts(id) {
    setTextBoxVisibilityData(visibleTextBoxData?.filter((obj) => obj?.id !== id))
    onSaveBuyerQuoteInformaton((pre) => {
      pre.visibleTextBoxData = visibleTextBoxData?.filter((obj) => obj?.id !== id)
      return pre
    })
    setPartsArray(partsArray.filter((dlr) => dlr.id !== id))
  }
  return (
    <Container>
      {partsArray?.map((obj, index) => (
        <Container display="flex" direction="column" gap="10px" key={index} className={classes.root}>
          <Container display="flex" justify="space-between">
            {/* <Typography variant="body1" color={getColor('grey-g25')}>{`${index + 1}. ${obj?.name}`}</Typography> */}
            <Typography variant="body1" color={getColor('grey-g25')}>{`${obj?.name}`}</Typography>

            {partsArray?.length !== 1 && (
              <IconButton variant="transparent" onClick={() => removeParts(obj?.id)}>
                <RedDeleteIcon />
              </IconButton>
            )}
          </Container>
          <Container display="flex" gap="15px" alignItems="center" className={classes.partTitle}>
            <Container display="flex" justify="space-between" alignItems="center" gap="15px">
              <Controller
                name={`${obj.name}number`}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <TextField
                    onChange={(e) => {
                      onChange(e)
                      handleChangeNumber(`${obj.name}number`, e.target.value, index)
                    }}
                    inputClassName={classes.partNameInput}
                    value={value === undefined ? obj?.value : value}
                    name={name}
                    placeholder={'e.g. LW8924656'}
                    label="Part Number"
                    onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                  />
                )}
              />
              <TwoWayController
                onChange={(e) => {
                  handleChange(`quantity`, e, index)
                }}
                defalutValue={1}
                label="Quantity"
              />
              <Container display="flex" direction="column" className={classes.partTitle}>
                <Container width="150px" display="flex" gap="0">
                  <Typography variant="paragraph1" color={getColor('grey-g25')}>
                    Images
                  </Typography>
                  <Tooltip
                    placement="right"
                    maxWidth={'400px'}
                    title={'Upload up to 3 images. Supported file formate are .jpg and .png, max size 2mb. '}
                  >
                    <InfoSmallIcon className={classes.icon} />
                  </Tooltip>
                </Container>
                <Container display="flex">
                  <Container className={classNames(classes.eqpimage, classes.partsimages)}>
                    {obj[`${obj.name}productImage`]?.map((file, i) => {
                      const props = {
                        url: URL.createObjectURL(file.file),
                        i,
                        assetId: file.id,
                        indexImage: 0,
                        disableDropdown: true,
                        quoteImage: true,
                      }
                      return (
                        <FileUpload
                          key={index}
                          className={classes.imageClass}
                          removeImage={removeSecondParsImage}
                          name={`${obj.name}productImage`}
                          index={i}
                          mainIndex={index}
                          {...props}
                        />
                      )
                    })}
                  </Container>
                  {obj[`${obj.name}productImage`]?.length >= 3 ? (
                    ''
                  ) : (
                    <Dropzone
                      multiple
                      accept={IMAGE_MIME}
                      onChange={(e) => handleFileChange(`${obj.name}productImage`, obj, e, index)}
                      dropzoneClassName={classes.textCover}
                      actionComponent={<AddIcon />}
                    />
                  )}
                </Container>
              </Container>
            </Container>
          </Container>
          <Container display="flex" direction="column" className={classes.partTitle}>
            <Container display="flex" justify="space-between" width="100%">
              <Typography variant="body1" color={getColor('grey-g25')}>
                Notes
              </Typography>
              <Typography variant="body1" color="#9799B5">
                {`${obj?.charCount || 0}/200`}
              </Typography>
            </Container>
            <Container width="100%">
              <TextArea
                id={'notes'}
                placeholder={'Write notes here...'}
                rows={3}
                {...register(`${obj.name}notes`)}
                onChange={(e) => handleChangeNotes(`${obj.name}notes`, e.target.value, index)}
                height={'36px'}
                maxLength="200"
                textareaClassName={classes.txtfiledClass}
              />
            </Container>
          </Container>
        </Container>
      ))}
    </Container>
  )
}

MultipleParts.propTypes = {
  partsData: PropTypes.array,
}
