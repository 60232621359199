/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Container from 'components/common/Container'
// import SearchField from 'components/common/search/SearchField'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import Chip from 'components/common/Chip'
import { useContext, useEffect, useState } from 'react'
import { getColor } from 'theme/colors'
// import usePartsDetails from 'common-hooks/usePartsDetails'
import LoadingOverlay from 'components/common/LoadingOverlay'
import usePartsDeep from 'common-hooks/usePartsDeep'
import { useFormData } from './FormProvider'

import { BuyerQuoteContext } from '../BuyerQuotesUtils'
import PartsSelectionTreeView from 'containers/common-containers/PartsSelectionTreeView'
import useQuotePartsTree from 'common-hooks/useQuotePartsTree'

const useStyles = makeStyles(() => ({
  form: {
    overflow: 'auto',
    width: 'auto',
  },
  root: {
    padding: (props) => getPadding(props.toggle, props?.visibleTextBoxData.length !== 0),
    maxHeight: '655px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  productInfo: {
    width: '100%',
    padding: '10px 30px',
    height: '70px',
    flexFlow: 'wrap',
    zIndex: 999,
    position: 'absolute',
    top: '60px',
    backgroundColor: getColor('system-bg'),
    '& input': {
      background: '#fff',
    },
  },
  img: {
    width: '50px',
    height: '50px',
    '& img': {
      width: '80px',
      ObjectFit: 'cover',
      height: '100%',
      border: '1px solid #F0F0F4 ',
    },
  },
  divFlex: {
    display: 'flex',
  },
  imgDiv: {
    height: '50px',
    width: '50px',
    background: '#fff',
    border: '1px solid #F0F0F4',
  },
  chips: {
    display: 'flex',
    gap: '4px',
    marginTop: '10px',
  },
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '47%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
  partsDataChip: {
    zIndex: '111111',
    // marginTop: '10px',
    display: 'flex',
    flexFlow: 'wrap',
    gap: '10px',
    order: '3',
    backgroundColor: getColor('system-bg'),
    padding: '0 0 10px 0',
  },
  partsDataDiv: {
    display: 'inline-block',
  },
}))

export default function PartsForm({ setnextBtn, nextFormStep, formStep, prevFormStep, setFormStep }) {
  const { data, setFormValues } = useFormData()
  const [loading, setLoading] = useState(false)
  const {
    BuyerQueoteInformation,
    visibleTextBoxData,
    setTextBoxVisibilityData,
    onSaveBuyerQuoteInformaton,
    setPartsTreeViewData,
    partsTreeViewData,
  } = useContext(BuyerQuoteContext)
  const [visibleTextBox, setTextBoxVisibility] = useState(visibleTextBoxData)

  const { partsTree, isLoading, mutate } = useQuotePartsTree(BuyerQueoteInformation?.Equipments?.equipment?.id)
  // const { allNodeIds, query, setSearchQuery, searchedTree } = usePartsDeep(
  //   BuyerQueoteInformation?.Equipments?.equipment?.id
  // )
  const { allNodeIds, query, searchedTree } = usePartsDeep(BuyerQueoteInformation?.Equipments?.equipment?.id)

  const classes = useStyles({ visibleTextBoxData })
  // const { createQuote } = useCreateQuote()
  const [parts, setParts] = useState()

  const { handleSubmit } = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      email: data?.email || '',
    },
  })
  setnextBtn(true)

  visibleTextBoxData?.map((item) => {
    if (item?.checked === true) {
      return setnextBtn(false)
    }
    return setnextBtn(true)
  })
  // const handleSearch = (node) => {
  //   if (node?.length === 0) {
  //     setSearchQuery(node)
  //     setParts(partsTree)
  //   } else if (query !== node) {
  //     setSearchQuery(node)
  //     setLoading(true)
  //   }
  // }

  useEffect(() => {
    if (query?.length > 0) {
      setParts(searchedTree)
      // setPartsTreeViewData(partsTree)
      setLoading(false)
    }
  }, [searchedTree?.length])
  useEffect(() => {
    if (partsTree && partsTree.length > 0 && !parts && !partsTreeViewData) {
      setParts(partsTree)
      setPartsTreeViewData(partsTree)
    } else if (partsTreeViewData && !query?.length > 0) {
      setParts(partsTreeViewData)
    }
  }, [parts, partsTree])

  const onSaveContextValue = () => {
    onSaveBuyerQuoteInformaton({ ...BuyerQueoteInformation, visibleTextBoxData })
  }

  const onSubmit = (values) => {
    setFormValues(values)
    onSaveContextValue()
    nextFormStep(formStep + 1)
  }
  function equipmentName(val) {
    if (val?.equipment?.name === 'Other') {
      return val?.equipmentName
    }
    return val?.equipment?.name
  }
  function brandName(val) {
    if (val?.brand?.name === 'Other') {
      return val?.brandName
    }
    return val?.brand?.name
  }
  function handleRemoveChip(id, name) {
    setTextBoxVisibilityData(visibleTextBoxData?.filter((obj) => obj?.id !== id))
    setTextBoxVisibility(visibleTextBoxData?.filter((obj) => obj?.id !== id))
    setParts(
      partsTreeViewData?.map((obj) => {
        obj?.children?.map((i, index) => {
          if (obj?.children[index]?.name === name) {
            delete obj?.children[index]
          }
          return 0
        })
        return 0
      })
    )
  }
  function handleRemoveBrandName(id) {
    onSaveBuyerQuoteInformaton((pre) => {
      if (pre?.Brands?.brand?.id === id) {
        delete pre?.Brands?.brand
      }
      return pre
    })
    prevFormStep()
  }
  function handleRemoveEquipName(id) {
    onSaveBuyerQuoteInformaton((pre) => {
      if (pre?.Equipments?.equipment?.id === id) {
        delete pre?.Equipments?.equipment
      }
      return pre
    })
    setFormStep(0)
  }
  return (
    <Container>
      {loading && <LoadingOverlay />}
      <Container className={classes.form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              document.activeElement.blur()
            }
          }}
          // id={'parts-form'}
          id={`hook-form-${formStep}`}
        >
          <Container>
            <Container flex justify="space-between" className={classes.productInfo}>
              {/* <Container width={'100%'}>
                <SearchField
                  id="search-feild"
                  placeholder={'Search part description'}
                  clearable
                  clearPreserveState={() => handleSearch('')}
                  onBlur={(e) => {
                    handleSearch(e.target.value)
                  }}
                />
              </Container> */}
              <Container className={classes.chips}>
                <Chip
                  label={equipmentName(BuyerQueoteInformation?.Equipments)}
                  removable
                  onRemove={() => handleRemoveEquipName(BuyerQueoteInformation?.Equipments?.equipment?.id)}
                />
                <Chip
                  label={brandName(BuyerQueoteInformation?.Brands)}
                  removable
                  onRemove={() => handleRemoveBrandName(BuyerQueoteInformation?.Brands?.brand?.id)}
                />
                {visibleTextBoxData[0]?.name && (
                  <Container className={classes.partsDataChip} width={'100%'}>
                    {visibleTextBoxData?.map(
                      (obj, index) =>
                        obj?.name && (
                          <Chip
                            label={`${obj?.name ? obj?.name : null}: ${obj?.value !== '' ? obj?.value : ''}`}
                            key={index}
                            removable
                            onRemove={() => handleRemoveChip(obj?.id, obj.name)}
                          />
                        )
                    )}
                  </Container>
                )}
              </Container>
            </Container>
          </Container>

          <Container className={classes.root} id={'partsTreeView'}>
            {isLoading && <LoadingOverlay />}
            <PartsSelectionTreeView
              partsList={parts}
              visibleTextBox={visibleTextBox}
              setTextBoxVisibility={setTextBoxVisibility}
              setTextBoxVisibilityData={setTextBoxVisibilityData}
              idsToExpand={allNodeIds}
              equpId={BuyerQueoteInformation?.Equipments?.equipment?.id}
              query={query}
              mutate={mutate}
              setParts={setParts}
            />
          </Container>
        </form>
      </Container>
    </Container>
  )
}

PartsForm.propTypes = {
  setnextBtn: PropTypes.func,
  nextFormStep: PropTypes.number,
  formStep: PropTypes.number,
  prevFormStep: PropTypes.func,
  setFormStep: PropTypes.func,
}
function getPadding(infoVisbility, chipVisibility) {
  let padding = ''
  if (chipVisibility) {
    padding = `${'58px 30px 0 30px'}`
  } else {
    padding = `${'58px 30px 0 30px'}`
  }
  return padding
}
