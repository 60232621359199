import { getInvitedDataList } from 'common-api/buyer-supplier/buyerSupplier'
import useSWR from 'swr'

export default function useInvitedSuppliers(id) {
  const { data, mutate } = useSWR(() => {
    if (id) {
      return `/quote-invite/quote/list?quote_id=${id}`
    }
    return null
  }, getInvitedDataList)
  return {
    invitedDataList: data,
    mutate,
  }
}
