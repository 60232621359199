import { getCompanyList } from 'common-api/authantication/auth'
import { createOption } from 'components/common/text/DropDownTextField'
import { useState } from 'react'
import useSWR from 'swr'

export default function useCompanyList() {
  const [search, onSearch] = useState()
  const { data } = useSWR(() => {
    if (search.length > 0) {
      return `/services/select-company?companyName=${search}`
    }
    return null
  }, getCompanyList)

  let list = data?.map((obj) => createOption(obj.id, obj.name))
  // let list = data?.map((obj) => createOptionDialog(obj.id, obj.name))

  if (list === undefined || search.length === 0) {
    list = []
  }
  return {
    companyList: list,
    onSearch,
    search,
  }
}
