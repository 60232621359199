import DownloadIcon from 'assets/icons/downloadIcon.svg'
import listingStar from 'assets/images/listingStar.svg'
import IconButton from 'components/common/IconButton'
import Image from 'components/common/Image'

export const plans = [
  {
    id: '0',
    name: 'Free',
    price: '0.00',
    pricemonthly: '0.00',
    plan: 'Month',
    planannually: 'Annually',
    request: '5 quote requests per month',
    offer: 'Everything in the Saver, plus',
    offerlist: [
      {
        img: listingStar,
        name: 'Buy parts on PartsClub',
      },
      {
        img: listingStar,
        name: 'Connect with suppliers ',
      },
      {
        img: listingStar,
        name: 'Basic profile page',
      },
    ],
  },
  {
    id: '1',
    name: 'Extra',
    price: '8.99',
    pricemonthly: '12.99',
    plan: 'Month',
    planannually: 'Annually',
    request: '15 quote requests per month',
    offer: 'Everything in the Starter, plus',
    offerlist: [
      {
        img: listingStar,
        name: 'All free features plus:',
      },
      {
        img: listingStar,
        name: 'More quote requests',
      },
      {
        img: listingStar,
        name: 'Get better offers from suppliers',
      },
      {
        img: listingStar,
        name: 'Receive offers by equipment',
      },
    ],
  },
  {
    id: '2',
    name: 'Unlimited (For Parts Pros)',
    price: '9.99',
    pricemonthly: '11.99',
    plan: 'Month',
    planannually: 'Annually',
    request: 'Unlimited quote requests',
    offer: 'Everything in the Saver, plus',
    offerlist: [
      {
        img: listingStar,
        name: 'All free features plus:',
      },
      {
        img: listingStar,
        name: 'Get a ‘Pro Buyer’ badge',
      },
      {
        img: listingStar,
        name: 'Join exclusive buying groups',
      },
      {
        img: listingStar,
        name: 'Advanced profile page',
      },
      {
        img: listingStar,
        name: 'Get best offers from suppliers',
      },
    ],
  },
]

export const supplierplans = [
  {
    id: '0',
    name: 'Extra',
    price: '0.00',
    pricemonthly: '0.00',
    plan: 'Month',
    planannually: 'Annually',
    request: 'For small parts businesses and brokers',
    offer: 'Everything in the Saver, plus',
    offerlist: [
      {
        img: listingStar,
        name: 'Buy parts on PartsClub',
      },
      {
        img: listingStar,
        name: 'Connect with suppliers ',
      },
      {
        img: listingStar,
        name: 'Basic profile page',
      },
    ],
  },
  {
    id: '1',
    name: 'Plus+',
    price: '499.99',
    pricemonthly: '624.99',
    plan: 'Month',
    planannually: 'Annually',
    request: 'For parts businesses looking for exposure',
    offer: 'Everything in the Starter, plus',
    offerlist: [
      {
        img: listingStar,
        name: 'Automated inventory uploads',
      },
      {
        img: listingStar,
        name: 'Send preemptive offers to buyers',
      },
      {
        img: listingStar,
        name: 'Upgraded company profile',
      },
      {
        img: listingStar,
        name: 'Better search result placement',
      },
      {
        img: listingStar,
        name: 'Basic reporting',
      },
    ],
  },
  {
    id: '2',
    name: 'Pro (Popular)',
    price: '399.99',
    pricemonthly: '499.99',
    plan: 'Month',
    planannually: 'Annually',
    request: 'Unlimited quote requests',
    offer: 'Everything in the Plus, plus',
    offerlist: [
      {
        img: listingStar,
        name: 'Top search result placement',
      },
      {
        img: listingStar,
        name: 'Exclusive ‘Premium Seller’ badge',
      },
      {
        img: listingStar,
        name: 'Exclusive promotional opportunities',
      },
      {
        img: listingStar,
        name: 'Exclusive seller support resources',
      },
      {
        img: listingStar,
        name: 'Sell products through your distributors',
      },
      {
        img: listingStar,
        name: 'Advanced reporting',
      },
    ],
  },
]

export const paymentDetail = [
  {
    id: '0',
    number: '**** **** **** 5680',
    exp: 'Exp: 09/2024',
  },
  {
    id: '1',
    number: '**** **** **** 4799',
    exp: 'Exp: 08/2027',
  },
]

function createData(Date, Invoice, Amount, Status, Image) {
  return { Date, Invoice, Amount, Status, Image }
}

export const rows = [
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
  createData(
    'mm/dd/yyyy',
    'Saver (annually)',
    '$119.88',
    'Paid ',
    <IconButton style={{ backgroundColor: 'unset' }}>
      <Image src={DownloadIcon} />
    </IconButton>
  ),
]

export const cols = ['Date', 'Invoice/Description', 'Amount', 'Status', '']
