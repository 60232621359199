import { getRequestDetails } from 'common-api/buyer-supplier/buyerSupplier'
import useSWR from 'swr'

export default function useDummyQuoteDetails(id) {
  const { data } = useSWR(`/services/quote/dummy/${id}`, getRequestDetails)

  return {
    quoteRequestDetail: data,
    isLoading: !data,
  }
}
