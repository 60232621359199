import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import RadioGroup from 'components/common/radio/RadioGroup'
import React, { useContext } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { SignupDataContext } from 'utils/provider/AuthContext'
import PropTypes from 'prop-types'
import Buyer from 'assets/images/Buyer.svg'
import Seller from 'assets/images/Seller.svg'
import UserTypeRadioCard from './UserTypeRadioCard'

const useStyles = makeStyles(() => ({
  radioGroup: {
    width: '100%',
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px ',
      justifyContent: 'center',
    },
  },
  root: {
    display: 'flex',
    width: '100%',
    height: '380px',
    '& #singup-account-type': {
      width: '100%',
    },
  },
}))

const SingUpAccoutType = ({ setBtnDisable, onStepChange, step }) => {
  const { onSaveSignupData, signupData } = useContext(SignupDataContext)
  const classes = useStyles()

  const useType = [
    {
      img: Buyer,
      type: 'Buyer',
      value: 'customer',
    },
    {
      img: Seller,
      type: 'Seller',
      value: 'supplier',
    },
  ]

  const methods = useForm({ defaultValues: { ...signupData, type: signupData?.type } })
  const { handleSubmit, control, setValue, getValues } = methods

  const [type] = useWatch({
    control,
    name: ['type'],
  })

  const onSaveSignupContextValue = () => {
    onSaveSignupData({ ...signupData, type })
  }

  const onSubmit = () => {
    onSaveSignupContextValue()
    onStepChange(step + 1)
  }

  if (type || signupData?.type) {
    setBtnDisable(false)
  } else {
    setBtnDisable(true)
  }

  return (
    <FormProvider {...methods}>
      <Container className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)} id={'singup-account-type'}>
          <Container>
            <Container flex justify="space-between" gap="20px">
              <Controller
                control={control}
                name="type"
                render={({ field: { name } }) => (
                  <RadioGroup name={name} className={classes.radioGroup}>
                    {useType?.map((eItem, index) => (
                      <UserTypeRadioCard
                        name={eItem.type}
                        img={eItem.img}
                        id={eItem.value}
                        checked={eItem.value === signupData?.type || eItem.value === getValues()?.type}
                        key={index}
                        onChange={() => {
                          setValue(name, eItem.value)
                          onSaveSignupData({ ...signupData, type: eItem?.value })
                        }}
                        onClick={() => {
                          setValue(name, eItem.value)
                          onSaveSignupData({ ...signupData, type: eItem?.value })
                        }}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Container>
          </Container>
        </form>
      </Container>
    </FormProvider>
  )
}

export default SingUpAccoutType

SingUpAccoutType.propTypes = {
  setBtnDisable: PropTypes.func,
  onStepChange: PropTypes.func,
  step: PropTypes.number,
}
