import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { forwardRef } from 'react'
import Container from 'components/common/Container'
import { getColor } from 'theme/colors'
import classNames from 'classnames'

const useStyles = makeStyles(() => ({
  root: {
    width: '400px',
    display: 'inline-block',
    borderRight: '1px solid #F0F0F4',
    padding: '20px 20px',
    height: '92.9vh',
    backgroundColor: getColor('light-main'),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: (props) => (props.display ? props.display : 'none'),
    },
  },
}))

const Sidebar = forwardRef(({ children, className, display }, ref) => {
  const classes = useStyles({ display })

  return (
    <Container className={classNames(classes.root, className)} ref={ref}>
      {children}
    </Container>
  )
})

Sidebar.displayName = 'Sidebar'

Sidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  display: PropTypes.string,
}

export default Sidebar
