import {
  createNewSupplier,
  deleteCompanyById,
  deleteProfileImages,
  UpdateSupplier,
} from 'common-api/super-admin/superAdmin'
import useToastify from 'common-hooks/useToastify'
import { addSupplierParseData } from 'containers/super-admin/supplier-mgt/SupplierUtils'
import { useNavigate } from 'react-router-dom'

export default function useCreateSupplier() {
  const { toastMessage } = useToastify()
  const navigate = useNavigate()

  const createSupplier = async (data, bannerImg, logoImg, mutate, resetEquips) => {
    const formData = new FormData()
    const parsedData = addSupplierParseData(data)
    if (bannerImg?.length > 0) {
      bannerImg.forEach((file) => {
        formData.append('img_header', file)
      })
    }
    if (logoImg?.length > 0) {
      logoImg.forEach((file) => {
        formData.append('logo', file)
      })
    }

    formData.append('document', parsedData)

    if (data?.id) {
      try {
        const response = await UpdateSupplier(formData)
        resetEquips({ del_equips: [], equips: [] })
        mutate()
        toastMessage('success', 'Supplier updated successfully')

        return response
      } catch {
        return false
      }
    } else {
      try {
        const response = await createNewSupplier(formData)
        toastMessage('success', 'Supplier created successfully')
        navigate('/sa/company')
        return response
      } catch {
        return false
      }
    }
  }
  const companyProfileImagesDelete = async (data) => {
    try {
      const response = await deleteProfileImages(data)
      if (data.type === 'cover') {
        toastMessage('success', 'Header image delete successfully')
      }
      return response
    } catch {
      return false
    }
  }
  const companyDelete = async (id, listMutate) => {
    try {
      const response = await deleteCompanyById(id)
      toastMessage('succes', 'Company deleted successfully')
      listMutate()
      return response
    } catch {
      return false
    }
  }
  return { createSupplier, companyProfileImagesDelete, companyDelete }
}
