import PropTypes from 'prop-types'
import { RadioGroup as MuiRadioGroup, FormControl } from '@mui/material'
import Typography from 'components/common/Typography'
import Label from 'components/common/Label'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: (props) => theme.spacing(props.spacing),
  },
  radiobold: {
    fontWeight: 700,
    fontSize: '10px',
  },
  group: (props) => ({
    ...(props.row && {
      flexDirection: 'row',
    }),
  }),
}))

export default function RadioGroup({
  children,
  label,
  name,
  labelSpacing = 1,
  className,
  spacing,
  row,
  value,
  onChange,
  LabelProps,
  ...props
}) {
  const classes = useStyles({ spacing, row })
  return (
    <FormControl component="fieldset" className={classNames(classes.root, className)}>
      {label && (
        <Label spacing={labelSpacing} {...LabelProps}>
          <Typography className={classes.radiobold}>{label}</Typography>
        </Label>
      )}
      <MuiRadioGroup name={name} className={classes.group} value={value} onChange={onChange} {...props}>
        {children}
      </MuiRadioGroup>
    </FormControl>
  )
}

RadioGroup.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  labelSpacing: PropTypes.number,
  className: PropTypes.string,
  spacing: PropTypes.number,
  row: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  LabelProps: PropTypes.object,
}
