import { makeStyles } from '@mui/styles'

import useSearch from 'common-hooks/useSearch'
import Button from 'components/common/Button'
import Typography from 'components/common/Typography'
import Checkbox from 'components/common/Checkbox'
import Container from 'components/common/Container'
import Radio from 'components/common/radio/Radio'
import RadioGroup from 'components/common/radio/RadioGroup'
import SearchField from 'components/common/search/SearchField'
import Select from 'components/common/select/Select'
import Spacing from 'components/common/Spacing'
import SwitchToggle from 'components/common/switch/SwitchToggle'
import SwitchTogglePrefix from 'components/common/switch/SwitchTogglePrefix'
import TextField from 'components/common/text/TextField'
import React, { useContext } from 'react'
import { AuthContext } from 'utils/provider/AuthContext'
import LabelTextField from 'components/common/text/LabelTextField'
import DropDownTextField from 'components/common/text/DropDownTextField'
import { useForm } from 'react-hook-form'
import BreadCrumbs from 'components/common/BreadCrumbs'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 5),
    border: '1px solid',
  },
}))
export default function Home() {
  const { authState, dispatch } = useContext(AuthContext)
  const classes = useStyles()
  const { control } = useForm()
  const { search, onSearch } = useSearch()

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ]
  return (
    <>
      <Container>
        <Container flex height={'94vh'}>
          <Container width={'434px'} height={'auto'} className={classes.root}>
            <Typography>
              Welcome {authState?.user} Authantication {authState?.isAuthenticated}
            </Typography>

            <Spacing y={2}>
              <BreadCrumbs list={['quote', 'active', 'details']} />
            </Spacing>

            <Spacing y={2}>
              <Select options={options} id={'singleSlect'} label={'Single Select'} />
            </Spacing>
            <Spacing y={2}>
              <Select options={options} multi id={'multiselect'} label={'Multi Select'} />
            </Spacing>
            <Spacing y={2}>
              <TextField placeholder={'Name'} id={'nameFlied'} label={'Text Filed'} />
            </Spacing>
            <Spacing y={2}>
              <SearchField
                id="search"
                size="sm"
                placeholder="Search"
                onSearch={(e) => {
                  onSearch(e)
                }}
                value={search}
                label={'Search Filed'}
              />
            </Spacing>
            <Spacing y={2}>
              <Typography>DropDown TextFiled</Typography>
              <DropDownTextField id={'dropdowntextfiled'} control={control} />
            </Spacing>

            <Container justify={'space-between'}>
              <Spacing x={1}>
                <Typography>Switch</Typography>
                <Spacing y={1}>
                  <SwitchToggle />
                </Spacing>
              </Spacing>
              <Spacing x={1}>
                <Typography>Switch Prefix</Typography>
                <SwitchTogglePrefix />
              </Spacing>
            </Container>
            <Spacing y={2}>
              <Typography>Label TextFiled</Typography>
              <LabelTextField id={'labeltextfiled'} labelText={'Label'} />
            </Spacing>
            <Container justify={'space-between'}>
              <Spacing x={1} y={2}>
                <Typography>CheckBox</Typography>
                <Checkbox label={'Checkbox'} />
              </Spacing>
            </Container>
            <Spacing x={1} y={2}>
              <Typography>Radio Button</Typography>
              <RadioGroup>
                <Radio label={'male'} value={'male'} />
                <Radio label={'female'} value={'female'} />
              </RadioGroup>
            </Spacing>

            <Container flex>
              <Spacing y={2}>
                <Button
                  onClick={() => {
                    dispatch({
                      type: 'LOGOUT',
                    })
                  }}
                >
                  Logout
                </Button>
              </Spacing>
            </Container>
          </Container>
        </Container>
      </Container>
    </>
  )
}
