import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import SupplierOrderAwaitingCard from './SupplierOrderAwaitingCard'
import { parseOrderData } from '../OrdersUtils'

const useStyles = makeStyles(() => ({
  awainglistBody: {
    maxWidth: '800px',
    // height: '80vh',
    // overflowY: 'scroll',
    margin: '0 auto',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  awaingcardList: {
    marginTop: '17px',
  },
  dateClass: {
    paddingBottom: '17px',
  },
}))

export default function SupplierOrderAwaitingList({ orderData }) {
  const classes = useStyles()
  const rows = parseOrderData(orderData, 'ordered_date')
  const keys = Object.keys(rows)
  return (
    <Container>
      <Container className={classes.awainglistBody}>
        <Container className={classes.awaingcardList}>
          {keys?.map((key, index) => (
            <Fragment key={index}>
              <Container className={classes.dateClass}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  {key.replace(',', '').toUpperCase()}
                </Typography>
              </Container>

              {rows[key].map((obj, index) => (
                <SupplierOrderAwaitingCard
                  id={obj.id}
                  key={index}
                  partName={obj.part_name}
                  brandName={obj.brand_name}
                  brandImg={obj.brand_cover_image}
                  equpImg={obj.equip_cover_image}
                  partNumber={obj.part_number}
                  orderId={obj.order_id}
                  soNumber={obj.sales_order_number}
                  buyername={obj.buyer_full_name}
                  city={obj.city}
                  state={obj.state}
                  amount={obj.amount}
                  delivery={obj.delivery_options}
                  quantity={obj.qty}
                  country={obj?.country}
                />
              ))}
            </Fragment>
          ))}
        </Container>
      </Container>
    </Container>
  )
}
SupplierOrderAwaitingList.propTypes = {
  orderData: PropTypes.array,
}
