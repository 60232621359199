import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'
import TextField from 'components/common/text/TextField'
import Container from 'components/common/Container'

const useStyles = makeStyles(() => ({
  textField: (props) => ({
    width: props.width || 'auto',
    backgroundColor: getColor('system-bg'),
    borderRadius: '2px',
    position: 'relative',
    '&:before': {
      content: `"${props.labelText}"`,
      position: 'absolute',
      fontSize: '0.875rem',
      color: getColor('dark-d25'),
      display: 'flex',
      alignItems: ' center',
      margin: '0 10px',
      top: '0',
      bottom: ' 0',
      zIndex: '1',
      justifyContent: ' center',
      fontFamily: 'Lexend Deca',
      fontStyle: 'normal',
      fontWeight: ' 300',
      ...getRootStyle(props),
    },
    '& div': {
      display: 'flex',
    },
    '& input': {
      paddingLeft: props.paddingLeft || '10px',
      paddingRight: props.paddingRight || '10px',
      position: 'relative',
      textAlign: props?.textAlign || 'left',
    },
  }),
}))

const LabelTextField = forwardRef(
  ({ width, labelText, onChange, className, lablePosition = 'left', type, id, textAlign, ...props }, ref) => {
    const padding = getPadding(labelText.length, lablePosition)
    const classes = useStyles({ width, labelText, lablePosition, textAlign, ...padding })
    const handleChange = (e) => {
      if (onChange) onChange(e.target.value)
    }
    return (
      <Container className={classNames(className, classes.textField)}>
        <TextField ref={ref} type={type} onChange={handleChange} {...props} id={id} />
      </Container>
    )
  }
)

LabelTextField.displayName = 'LabelTextField'

LabelTextField.propTypes = {
  width: PropTypes.string,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  lablePosition: PropTypes.string,
  id: PropTypes.string.isRequired,
  CodeProps: PropTypes.object,
  size: PropTypes.string,
  codeName: PropTypes.string,
  control: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  textAlign: PropTypes.string,
}

export default LabelTextField

function getRootStyle(props) {
  return {
    ...(props.lablePosition === 'left' && {
      left: '0',
    }),
    ...(props.lablePosition === 'right' && {
      right: '0',
    }),
  }
}

function getPadding(charLength, lablePosition) {
  let paddingLeft
  let paddingRight

  if (lablePosition === 'left') {
    paddingLeft = `${20 + charLength * 8}px`
  } else {
    paddingRight = `${20 + charLength * 8}px`
  }
  return { paddingLeft, paddingRight }
}
