import Container from 'components/common/Container'
import { React, useEffect, useState } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import TextArea from 'components/common/text/TextArea'
import useToastify from 'common-hooks/useToastify'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
})

const NotesDialog = ({ open, onClose, setNotes, editNotes, setFlag, setEditNots }) => {
  const [note, setNote] = useState()

  const { toastMessage } = useToastify()

  const handleOnClose = () => {
    setNote('')
    setEditNots(null)
    onClose()
  }

  // eslint-disable-next-line consistent-return
  const saveNote = () => {
    if (editNotes) {
      setNotes(note)
      setFlag(false)
      handleOnClose()
      return 0
    }
    if ((!editNotes && note === '') || note === undefined) {
      toastMessage('error', 'Notes  is required')
      return 0
    }
    setNotes(note)
    setFlag(false)
    handleOnClose()
  }

  useEffect(() => {
    if (editNotes) {
      setNote(editNotes)
    }
  }, [editNotes])

  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Dialog open={open} size={'sm'}>
        <DialogHead title={'Notes'} onClose={handleOnClose} />
        <Container className={classes.dialogbody}>
          <TextArea
            id={'noteArea'}
            placeholder={'Paragraph'}
            value={note}
            rows={4}
            onChange={(e) => setNote(e.target.value)}
          />
        </Container>
        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button onClick={() => saveNote()}>{editNotes ? 'Update' : 'Add'}</Button>
          </Container>
        </DialogFooter>
      </Dialog>
    </Container>
  )
}
NotesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setNotes: PropTypes.func,
  editNotes: PropTypes.string,
  setFlag: PropTypes.func,
  setEditNots: PropTypes.func,
}

export default NotesDialog
