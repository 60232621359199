import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: props.width || 'auto',
    alignItems: props.alignItems || 'normal',
    height: props.height || 'auto',
    display: props.flex || props.display,
    flexDirection: props.direction,
    ...(props.gap && { gap: props.gap }),
    padding: theme.spacing(props.padding),
    gap: props.gap || 'unset',
    textAlign: props.textAlign,
    ...getRootStyle(props),
  }),
}))
function Container({
  children,
  flex,
  display,
  direction,
  padding,
  alignItems,
  width,
  height,
  className,
  justify,
  textAlign = 'unset',
  gap,
  containerRef,
  onClick,
  id,
}) {
  const classes = useStyles({ flex, direction, padding, width, height, justify, gap, textAlign, alignItems, display })
  return (
    <Grid className={classNames(classes.root, className)} ref={containerRef} onClick={onClick} id={id}>
      {children}
    </Grid>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  flex: PropTypes.bool,
  direction: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.string,
  justify: PropTypes.string,
  gap: PropTypes.string,
  textAlign: PropTypes.string,
  className: PropTypes.string,
  containerRef: PropTypes.any,
  onClick: PropTypes.func,
  id: PropTypes.string,
  display: PropTypes.string,
  alignItems: PropTypes.string,
}

export default Container

function getRootStyle(props) {
  return {
    ...(props.flex && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    ...(props.justify === 'space-between' && {
      justifyContent: 'space-between',
      display: 'flex',
    }),
    ...(props.justify === 'flex-end' && {
      justifyContent: 'flex-end',
      display: 'flex',
    }),
    ...(props.justify === 'flex-start' && {
      justifyContent: 'flex-start',
      display: 'flex',
    }),
    ...(props.justify === 'space-around' && {
      justifyContent: 'space-around',
      display: 'flex',
    }),
    ...(props.justify === 'space-evenly' && {
      justifyContent: 'space-evenly',
      display: 'flex',
    }),
  }
}
