import { counterExpire, counterNudge, getCounterListBuyer } from 'common-api/counter/getCounterListBuyer'
import useToastify from 'common-hooks/useToastify'
import useSWR from 'swr'

export default function useGetCounterListBuyer(id) {
  const { toastMessage } = useToastify()
  const { data, mutate } = useSWR(() => {
    if (id) {
      return `counter/detail/${id}/asc`
    }
    return null
  }, getCounterListBuyer)

  const setCounterOfferExpire = async (data) => {
    try {
      const response = await counterExpire(data)
      return response
    } catch {
      return false
    }
  }
  const counterNudgeBuyer = async (data) => {
    try {
      const response = await counterNudge(data)
      toastMessage('success', 'Supplier reminded of your quote request')
      return response
    } catch {
      return false
    }
  }
  return {
    counterData: data,
    mutate,
    setCounterOfferExpire,
    counterNudgeBuyer,
  }
}
