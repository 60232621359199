/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import Container from 'components/common/Container'
import { TabPanel, Tabs } from 'components/common/Tabs'
import SearchField from 'components/common/search/SearchField'
import Button from 'components/common/Button'
import inventoryEmptyState from 'assets/images/inventoryEmptyState.svg'
import { makeStyles } from '@mui/styles'
import {
  AddIcon,
  CaretLeftIcon,
  CaretRightIcon,
  DropdownBigIcon,
  EditIcon,
  RedDeleteIcon,
  SortArrowIcon,
} from 'assets/icons'
import { Table } from 'components/common/Table'
import { getColor } from 'theme/colors'
import IconButton from 'components/common/IconButton'
import correctionIcon from 'assets/images/correctionIcon.svg'
import Chip from 'components/common/Chip'
import ContextMenu from 'components/custom/drop-downs/ContextMenu'
import ContextMenuItem from 'components/custom/drop-downs/ContextMenuItem'
import Image from 'components/common/Image'
import useToggle from 'common-hooks/useToggle'
import NotesDialog from './NotesDialog'
import Typography from 'components/common/Typography'
import Tooltip from 'components/common/Tooltip'
import { tabsReport } from './superAdminUtils'
import useSuperAdminReportList from 'common-hooks/super-admin/useSuperAdminReportList'
import { DATE_FORMAT_COMMA_SUPER, formatDate } from 'utils/dateUtils'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { formatPrice } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    // padding: '30px',
  },
  marginHead: {
    position: 'sticky',
    top: '60px',
    // background: getColor('system-bg'),
    zIndex: '1',
  },
  mainContainer: {
    background: '#fff',
    padding: '30px',
    // '& svg': {
    //   '& path': {
    //     fill: '#4A4D68',
    //   },
    // },
  },
  SearchField: {
    '& input': {
      background: '#F8F9F9',
    },
  },
  list: {
    marginTop: '20px',
    maxHeight: '75vh',
    overflowY: 'scroll',

    whiteSpace: ' nowrap',

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      paddingtop: '50px',
      backgroundColor: '#F0F0F4',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
    '& table': {
      overflowX: 'hidden',
      width: '1920px',
      // overflowX: 'scroll',
      '& tr': {
        cursor: 'pointer',
      },
    },
  },
  pagination: {
    borderTop: '1px solid #F0F0F4 ',
    padding: '30px',
    display: 'flex',
    background: '#fff',
    justifyContent: 'space-between',
  },
  paginationFooter: {
    position: 'sticky',
    bottom: '0px',
    height: 'unset',
    paddingTop: '30px',
    // marginLeft: '-20px',
    // marginRight: '-20px',
    padding: '0 0',
    zIndex: '1',
    borderTop: `1px solid ${getColor('grey-g95')}`,
    background: getColor('light-main'),
    '& svg path': {
      fill: 'unset',
    },
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  customTableContainer: {
    overflowX: 'initial',
  },
  // placeholder: {
  //   position: 'absolute',
  //   display: 'flex',
  //   top: '0',
  //   right: '0',
  //   left: '0',
  //   bottom: '0',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   gap: '20px 0',
  // },
  iconBtn: {
    background: 'unset',
    border: ' 1px solid #9799B5',
    borderRadius: ' 4px',
    width: ' 36px',
    height: '36px',
    '&:hover': {
      background: 'unset',
    },
  },
  btn: {
    width: '100%',

    justifyContent: ' space-between',
    padding: 'unset',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  addBtn: {
    background: 'unset',
    color: '#2680D9',
    padding: 'unset',
    height: 'unset',
    minHeight: 'unset',
    '& svg path': {
      fill: '#2680D9',
      stroke: '#2680D9',
    },
    '&:hover': {
      background: 'unset',
    },
  },
  noteRoot: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    '& svg path': {
      fill: 'unset',
    },
    '& .hoverIcon': {
      display: 'none',
      gap: '0 10px',
      position: 'absolute',
      background: '#F8F9F9',
      right: '5px',
      '& .btn': {
        background: 'unset',
        padding: 'unset',
        '&:hover': {
          background: 'unset',
        },
      },
    },
    '&:hover .hoverIcon': {
      display: 'flex',
    },
  },
  resonRoot: {
    '& div': {
      display: 'flex',
    },
    '& p': {
      margin: '0',
      display: 'inline-block',
    },
  },
  sortBtn: {
    padding: '0',
    width: 'unset',
    height: 'unset',
    background: 'unset',
    '&:hover': {
      background: 'unset',
    },
  },
  placeholder: {
    // position: 'absolute',
    display: 'flex',
    top: '0',
    height: '72vh',
    right: '0',
    left: '0',
    bottom: '0',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px 0',
  },
  rootBg: {
    background: '#fff',
  },
}))

export default function Report() {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(2)
  const [activeRows, setActiveRows] = useState([])
  const [notesDialog, setNotesDialog] = useToggle(false)
  const [editnoteData, setEditNoteData] = useState()
  const [noteId, setNoteId] = useState()
  const [orderBy, setOrderBy] = useState()

  const { producList, search, onSearch, isLoading, productUpdate, setPage, page } = useSuperAdminReportList(orderBy)

  const [productStatus] = useState('product')

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    // switch (newValue) {
    //   case 0:
    //     setProductStatus('selleroffer')
    //     break
    //   case 1:
    //     setProductStatus('buyeroffer')
    //     break
    //   case 2:
    //     setProductStatus('product')
    //     break
    //   default:
    //     setProductStatus('selleroffer')
    // }
  }

  function createData(
    status,
    OfferID,
    Date,
    Reporter,
    seller,
    Reason,
    product,
    brand,
    ProductPrice,
    Notes,
    Buyer,
    OfferName,
    CounterPrice,
    Counter
  ) {
    if (productStatus === 'product') {
      return { status, Date, Reporter, seller, Reason, product, brand, ProductPrice, Notes }
    }

    return { status, OfferID, Date, Reporter, Buyer, Reason, OfferName, ProductPrice, CounterPrice, Counter, Notes }
  }

  function statusProduct(status) {
    if (status === 'not set') return 'Not Set'
    if (status === 'In Progress') return 'In Progress'
    if (status === 'Completed') return 'Completed'
    return 'Not Set'
  }

  useEffect(() => {
    if (producList !== undefined) {
      setActiveRows(
        producList?.map((obj) =>
          createData(
            <ContextMenu
              width={'205px'}
              closeOnClick
              trigger={
                <IconButton className={classes.btn}>
                  <Chip
                    label={statusProduct(obj?.status)}
                    // eslint-disable-next-line no-nested-ternary
                    variant={obj?.status === 'In Progress' ? 'secondary' : obj?.status === 'Completed' ? 'success' : ''}
                  />
                  <DropdownBigIcon />
                </IconButton>
              }
            >
              <ContextMenuItem onClick={() => productUpdate({ id: obj?.id, status: 'Not Set' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Not Set'} />
                  {statusProduct(obj?.status) === 'Not Set' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => productUpdate({ id: obj?.id, status: 'In Progress' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'In Progress'} variant={'secondary'} />
                  {statusProduct(obj?.status) === 'In Progress' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
              <ContextMenuItem onClick={() => productUpdate({ id: obj?.id, status: 'Completed' })}>
                <Container flex justify="space-between" width={'100%'}>
                  <Chip label={'Completed'} variant={'success'} />
                  {statusProduct(obj?.status) === 'Completed' && <Image src={correctionIcon} />}
                </Container>
              </ContextMenuItem>
            </ContextMenu>,

            obj?.OfferID,
            formatDate(obj?.created_at, DATE_FORMAT_COMMA_SUPER),
            <div>
              {obj?.reporter_name ? obj?.reporter_name : -''}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.reporter_email ? obj?.reporter_email : '-'}
              </Typography>
            </div>,
            <div>
              {obj?.seller_name ? obj?.seller_name : '-'}
              <Typography variant="paragraph2" color={getColor('grey-g35')} component="p">
                {obj?.seller_email ? obj?.seller_email : '-'}
              </Typography>
            </div>,
            <div className={classes.resonRoot}>
              {obj?.reason?.length > 40 ? (
                <div>
                  {obj?.reason.slice(0, 40)}...
                  <Tooltip title={obj?.reason}>
                    <p> View reason</p>
                  </Tooltip>
                </div>
              ) : (
                obj?.reason
              )}
            </div>,
            obj?.product_title ? obj?.product_title : '-',
            obj?.brand_name ? obj?.brand_name : '-',
            obj?.product_unit_price ? formatPrice(obj?.product_unit_price) : '-',
            obj?.notes ? (
              <Container className={classes.noteRoot}>
                <div className={classes.resonRoot}>
                  {obj?.notes?.length > 40 ? <div>{obj?.notes?.slice(0, 40)}...</div> : obj?.notes}
                </div>
                <Container className={'hoverIcon'}>
                  <IconButton
                    className={'btn'}
                    onClick={() => {
                      setNotesDialog()
                      setEditNoteData(obj?.notes)
                      setNoteId(obj?.id)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton className={'btn'} onClick={() => productUpdate({ id: obj?.id, notes: '' })}>
                    <RedDeleteIcon />
                  </IconButton>
                </Container>
              </Container>
            ) : (
              <Button
                onClick={() => {
                  setNotesDialog()
                  setEditNoteData('')
                  setNoteId(obj?.id)
                }}
                className={classes.addBtn}
                icon={<AddIcon />}
              >
                Add
              </Button>
            ),
            obj?.OfferName,
            obj?.CounterPrice,
            obj?.Counter
          )
        )
      )
    }
  }, [producList])

  const Productcols = [
    <Container width={'100%'} flex justify="space-between">
      Status{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'status', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,

    <Container width={'100%'} flex justify="space-between">
      Date{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'created_at', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'created_at', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Reporter{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'reporter_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'reporter_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Seller{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'seller_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'seller_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Reason
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'reason', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'reason', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Product
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'product_title', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'product_title', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Brand
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'brand_name', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'brand_name', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Product Price
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'product_unit_price', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'product_unit_price', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
    <Container width={'100%'} flex justify="space-between">
      Notes{' '}
      <ContextMenu
        width={'205px'}
        closeOnClick
        trigger={
          <IconButton className={classes.sortBtn}>
            <SortArrowIcon />
          </IconButton>
        }
      >
        <ContextMenuItem onClick={() => setOrderBy(null)}>
          <Typography>Relevance</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'asc' })}>
          <Typography>A - Z</Typography>
        </ContextMenuItem>
        <ContextMenuItem onClick={() => setOrderBy({ order_by: 'notes', order: 'desc' })}>
          <Typography>Z - A</Typography>
        </ContextMenuItem>
      </ContextMenu>
    </Container>,
  ]

  return (
    <Container height={'93vh'} className={classes.rootBg}>
      <Container className={classes.mainContainer} width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            <Tabs value={tabValue} onchange={handleChange} array={tabsReport} />
            <Container flex gap={'20px'}>
              <Container>
                <SearchField
                  id={'searchField'}
                  width={334}
                  className={classes.SearchField}
                  onSearch={(e) => {
                    onSearch(e)
                  }}
                  value={search}
                />
              </Container>
            </Container>
          </Container>
        </Container>

        <Container className={classes.list}>
          <TabPanel value={tabValue} index={0}>
            <Container>
              <Table stickyHeader cols={Productcols} rows={activeRows} />
            </Container>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Container>
              <Table stickyHeader cols={Productcols} rows={activeRows} />
            </Container>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Container>
              {isLoading && <LoadingOverlay />}
              {producList?.length > 0 ? (
                <Table stickyHeader cols={Productcols} rows={activeRows} />
              ) : (
                <Container className={classes.placeholder}>
                  <Container>
                    <Image src={inventoryEmptyState} />
                  </Container>
                  <Container flex direction="column" gap="20px 0">
                    {search === '' ? (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No product reported
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          Looks like all the products are looks fine
                        </Typography>
                      </Container>
                    ) : (
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No result found
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          Looks like there are no reported products with this value
                        </Typography>
                      </Container>
                    )}
                  </Container>
                </Container>
              )}

              {/* {producList?.length <= 0 && (
                <>
                  {' '}
                  <Container className={classes.placeholder}>
                    <Container>
                      <Image src={inventoryEmptyState} />
                    </Container>
                    <Container flex direction="column" gap="20px 0">
                      <Container flex direction="column">
                        <Typography variant="title" color={getColor('grey-g25')} component="p">
                          No active product
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')} component="p">
                          You don’t have any active product yet.
                        </Typography>
                      </Container>
                      {/* <Button onClick={() => navigate('/inventory/addproduct')}>&nbsp;Add Product</Button> */}
              {/* </Container>
                  </Container>
                </> */}
            </Container>
          </TabPanel>
        </Container>
        <Container flex justify={'space-between'} className={classes.paginationFooter}>
          <Button
            icon={<CaretLeftIcon />}
            variant={'transparent'}
            className={classes.prevBtn}
            onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
            disabled={page === 1}
          >
            Prev.
          </Button>
          <Button
            icon={<CaretRightIcon />}
            variant={'transparent'}
            className={classes.nextBtn}
            onClick={() => setPage((pre) => pre + 1)}
            disabled={producList?.length < 35}
          >
            Next
          </Button>
        </Container>
      </Container>
      <NotesDialog
        onClose={setNotesDialog}
        open={notesDialog}
        editnoteData={editnoteData}
        noteId={noteId}
        productUpdate={productUpdate}
        setEditNoteData={setEditNoteData}
      />
    </Container>
  )
}
