import Api from 'services/Api'

export async function getUserProfile(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function setUpdateProfile(data) {
  const response = await Api.put(`/user`, data)
  return response.data.data
}

export async function setUserPassword(data) {
  const response = await Api.put(`/user/update-password`, data)
  return response.data.data
}

export async function getHelp(data) {
  const response = await Api.post(`/company/contact-us`, data)
  return response.data.data
}
