import { Paper, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import { PropTypes } from 'prop-types'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'

const useStyles = makeStyles({
  root: {
    // padding: '0 25px',
    '& .MuiPaper-root': {
      // border: `0.5px solid ${getColor('grey-g95')}`,
      borderRadius: '4px',
      boxShadow: 'none',
    },
    '& .MuiTableHead-root': {
      backgroundColor: getColor('system-bg'),
      border: `0.5px solid ${getColor('grey-g95')}`,
    },
    '& .MuiTableCell-root': {
      border: `0.5px solid ${getColor('grey-g95')}`,
      padding: '10px',
    },
  },
  tableContainer: {
    backgroundColor: getColor('system-bg'),
    // height: '80vh',
  },
  customTableContainer: {
    overflowX: 'initial',
    '& th': {
      backgroundColor: '#F8F9F9',
    },
    '& tr:hover': {
      backgroundColor: '#F8F9F9',
    },
  },
  cellId: {
    display: 'none',
  },
})

export const Table = ({ cols, rows, color, height, handleOnClick, stickyHeader }) => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <TableContainer
        sx={{ height }}
        classes={stickyHeader ? { root: classes.customTableContainer } : ''}
        component={Paper}
      >
        <MuiTable stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow>
              {cols?.map((col, index) => (
                <TableCell key={index}>
                  <Typography variant="body1" color={getColor('grey-g35')}>
                    {col}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                onClick={() => {
                  if (row?.id) handleOnClick(row?.id)
                }}
              >
                {Object.keys(row).map((key) => (
                  <TableCell key={index} className={key === 'id' ? classes.cellId : ''}>
                    <Typography variant="body1" color={color}>
                      {row[key]}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Container>
  )
}

Table.propTypes = {
  cols: PropTypes.array,
  rows: PropTypes.array,
  color: PropTypes.string,
  height: PropTypes.string,
  handleOnClick: PropTypes.func,
  stickyHeader: PropTypes.bool,
}
