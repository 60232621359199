import { makeStyles } from '@mui/styles'
import { forwardRef } from 'react'
import { PropTypes } from 'prop-types'
import props from 'theme/props'
import { Divider as MuiDivider } from '@mui/material'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  customSeperator: (props) => ({
    alignItems: 'center',
    borderColor: props.borderColor || getColor('grey-g95'),
    margin: props.margin,
    width: props.width || '100%',
    '&:before': {
      borderColor: props.borderColor || getColor('grey-g95'),
    },
    '&:after': {
      borderColor: props.borderColor || getColor('grey-g95'),
    },
    ...getRootStyle(props),
  }),
}))
const Seperator = forwardRef(({ children, margin, width, borderColor, orientation }, ref) => {
  const classes = useStyles({
    children,
    margin,
    width,
    borderColor,
    orientation,
    ...props,
  })
  return (
    <>
      <MuiDivider className={classes.customSeperator} ref={ref}>
        {children}
      </MuiDivider>
    </>
  )
})
Seperator.propTypes = {
  children: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  borderColor: PropTypes.string,
  orientation: PropTypes.string,
}
export default Seperator
function getRootStyle(props) {
  return {
    ...(props.orientation === 'vertical' && {
      transform: 'rotate(90deg)',
    }),
  }
}
