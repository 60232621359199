import { setApprovedCounter, setCounterData } from 'common-api/counter/getCounterListBuyer'
import useToastify from 'common-hooks/useToastify'

export default function useSupplierCounter() {
  const { toastMessage } = useToastify()
  const setSupplierCounter = async (data, mutate) => {
    try {
      const response = await setCounterData(data)
      toastMessage('success', 'Counter Data Submitted')
      mutate()
      return response
    } catch {
      return false
    }
  }
  const setSupplierApprove = async (data, mutate) => {
    try {
      const response = await setApprovedCounter(data)
      toastMessage('success', 'You locked the offer')
      mutate()
      return response
    } catch {
      return false
    }
  }

  return { setSupplierCounter, setSupplierApprove }
}
