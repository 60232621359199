import Api from 'services/Api'

// Buyer-Request-Quotes (Active)
export async function getActiveQuoteList(url) {
  const response = await Api.get(url)
  return response.data.data
}
// Buyer-Request-Quotes-Details
export async function getQuoteDetailList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getInvitedDataList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getCantParticipateList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getDetailQuoteList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function putQuoteDetails(id, param) {
  const response = await Api.put(`/quote/${id}`, param)
  return response.data.data
}
export async function counterFormData(data) {
  const response = await Api.post(`/counter/create-buyer`, data)
  return response.data.data
}
// supplier-quotes
export async function getSupplierRequestedList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getQuoteDetails(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function getSupplierActiveList(url) {
  const response = await Api.get(url)
  return response.data.data
}
// Buyer-Supplier-List
export async function getSupplierList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getSupplierDetails(url) {
  const response = await Api.get(url)
  return response.data.data
}

// Buyer-Supplier Request Detail
export async function getRequestDetails(url) {
  const response = await Api.get(url)
  return response.data.data
}
// Nudge
export async function putNudge(id) {
  const response = await Api.put(`quote-invite/nudge?id=${id}`)
  return response.data.data
}

// Add Part
export async function addPart(data) {
  const response = await Api.post(`/part/user-created/`, data)
  return response
}
export async function deletePart(id) {
  const response = await Api.delete(`/part/remove/${id}`)
  return response
}
// Nudge Supplier
export async function postSupplier(data) {
  const response = await Api.post(`/quote-request/nudge`, data)
  return response.data.data
}
