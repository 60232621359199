import { createSupplierQuotePost, rejectQuote } from 'common-api/create-quote/create-supplier-quote'
import useToastify from 'common-hooks/useToastify'
import { supplierParsing } from 'containers/supplier-quote-details/SupplierQuoteDetailsUtils'
import { useParams } from 'react-router-dom'

export default function useSupplierCreateQuote() {
  const { toastMessage } = useToastify()
  const params = useParams()
  const createSupplierQuote = async (data) => {
    try {
      const parsedData = supplierParsing(data, params?.id, params?.reqId)
      await createSupplierQuotePost(parsedData)
      toastMessage('success', 'Quote sent to buyer successfully')
    } catch {
      return false
    }
    return null
  }
  const cantParticipate = async (data, id) => {
    try {
      await rejectQuote({ id, ...data })
      toastMessage('success', 'Quote rejected successfully')
    } catch {
      return false
    }
    return null
  }
  return { createSupplierQuote, cantParticipate }
}
