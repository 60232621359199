import PropTypes from 'prop-types'
import { Popover as MuiPopover, Fade } from '@mui/material'
import { getColor } from 'theme/colors'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: getColor('grey-g90'),
  },
})

export default function Popover({
  open,
  onClose,
  anchorEl,
  children,
  paperClassName,
  anchorOriginVertical = 'bottom',
  anchorOriginHorizontal = 'center',
  transformOriginVertical = 'top',
  transformoriginhorizontal = 'left',
  ...props
}) {
  const classes = useStyles()
  return (
    <MuiPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: transformOriginVertical,
        horizontal: transformoriginhorizontal,
      }}
      classes={{
        paper: classNames(classes.paper, paperClassName),
      }}
      TransitionComponent={Fade}
      {...props}
    >
      {children}
    </MuiPopover>
  )
}
Popover.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
  children: PropTypes.node,
  paperClassName: PropTypes.string,
  anchorOriginVertical: PropTypes.oneOf(['top', 'center', 'bottom']),
  anchorOriginHorizontal: PropTypes.oneOf(['left', 'center', 'right']),
  transformOriginVertical: PropTypes.oneOf(['top', 'center', 'bottom']),
  transformoriginhorizontal: PropTypes.oneOf(['left', 'center', 'right']),
}
