import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Seperator from 'components/common/Seperator'
import listingStar from 'assets/images/listingStar.svg'
import Typography from 'components/common/Typography'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'

const useStyles = makeStyles({
  cardGrid: {
    display: 'flex',
    gap: '20px',
    flexFlow: 'wrap',
  },
  card: {
    width: '100%',
    height: ' auto',
    background: '#FFFFFF',
    // border: '1px solid #F0F0F4',
    borderRadius: ' 4px',
    padding: '20px',
  },
  cardInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  priceText: {
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '42px',
    color: '#13141B',
  },
  priceTextSmall: {
    fontFamily: "'Lexend Deca'",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#9799B5',
  },
  pricingFlex: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px 0',
    // padding: '20px 0',
    paddingTop: '20px',
  },
  currrentPlanBtn: {
    background: '#F0F0F4!important',
    color: '#9799B5!important',
    width: '100%',
  },
  upgradeBtn: {
    background: '#fff!important',
    color: '#4A4D68!important',
    width: '100%',
    border: '1px solid #9799B5',
  },
  upgradeGreenBtn: {
    background: '#0DA50D!important',
    color: '#fff!important',
    width: '100%',
  },
  listFlex: {
    display: 'flex',
    gap: '0 5px',
    alignItems: 'center',
    color: getColor('grey-g35'),
  },
  listFlexRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0',
    marginTop: '15px',
  },
  DialogWidth: {
    width: '400px',
  },
})

const PlanDetailDialog = ({ open, onClose, price, plan, request, offer, offerlist }) => {
  const classes = useStyles()

  const [benefitTitlesArray, setBanefitTitleArray] = useState([])
  const numbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']

  useEffect(() => {
    setBanefitTitleArray(() => {
      const arr = []
      for (let i = 0; i < Object.keys(offerlist).length; i += 1) {
        arr.push(
          <Typography key={i} variant="body1" className={classes.listFlex}>
            <Image src={listingStar} />
            {offerlist[numbers[i]]}
          </Typography>
        )
      }
      return arr
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerlist])

  return (
    <Container>
      <Dialog open={open} className={classes.DialogWidth}>
        <DialogHead onClose={onClose} title="Plan Details" />
        <Container className={classes.card}>
          <Container className={classes.cardInner}>
            <Container className={classes.cardgrp}>
              <Typography variant="title" color={getColor('grey-g25')}>
                {plan}
              </Typography>
              <Container className={classes.pricingFlex}>
                <Typography className={classes.priceText}>
                  ${price} <span className={classes.priceTextSmall}>/month</span>
                </Typography>
                <Typography variant="body1" component="p" color={getColor('grey-g35')}>
                  {request}
                </Typography>
              </Container>
            </Container>
            <Seperator />
            <Container className={classes.cardgrp}>
              <Typography variant="body1" color={getColor('grey-g35')}>
                {offer}
              </Typography>
              <Container className={classes.listFlexRoot}>{benefitTitlesArray.map((obj) => obj)}</Container>
            </Container>
          </Container>
        </Container>
      </Dialog>
    </Container>
  )
}
export default PlanDetailDialog
PlanDetailDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  price: PropTypes.string,
  request: PropTypes.string,
  plan: PropTypes.string,
  offer: PropTypes.string,
  offerlist: PropTypes.object,
}
