import {
  createProduct,
  crossRefNumberDelete,
  fitsWithDataDelete,
  productCoverImage,
  productImageDelete,
  relatedProductDelete,
  shareViaEmailProduct,
  updateProduct,
} from 'common-api/inventory/inventory'
import useToastify from 'common-hooks/useToastify'
import { parseProductData } from 'containers/inventory/InventoryUtils'
import { useNavigate } from 'react-router-dom'

export default function useAddProduct() {
  const { toastMessage } = useToastify()
  const navigate = useNavigate()

  const addProduct = async (data, unitPrice, crossRefNumbers, FitsWithData, relatedProducts, productImages, mutate) => {
    const formData = new FormData()
    const parsedData = parseProductData(data, unitPrice, crossRefNumbers, FitsWithData, relatedProducts)

    if (productImages?.length > 0) {
      productImages.forEach((file) => {
        formData.append('images', file?.file)
      })
    }

    formData.append('document', parsedData)

    if (data?.id) {
      try {
        const response = await updateProduct(formData)
        toastMessage('success', 'Product Updated successfully')
        // navigate('/inventory')
        mutate()
        return response
      } catch {
        return false
      }
    } else {
      try {
        const response = await createProduct(formData)
        toastMessage('success', 'Product Created successfully')
        navigate('/inventory')
        return response
      } catch {
        return false
      }
    }
  }

  const deleteCrossRefNumbers = async (productid, brandid, id, mutate) => {
    try {
      const response = await crossRefNumberDelete({ product_id: productid, brand_id: brandid, id })
      toastMessage('success', 'Cross-Reference Deleted')
      mutate()
      // navigate('/inventory')
      return response
    } catch {
      return false
    }
  }
  const deleteFitsWithData = async (productid, brandid, id, mutate) => {
    try {
      const response = await fitsWithDataDelete({ product_id: productid, brand_id: brandid, id })
      toastMessage('success', 'Fitment Data Deleted')
      mutate()
      // navigate('/inventory')
      return response
    } catch {
      return false
    }
  }
  const deleteRelatedProduct = async (id, mutate) => {
    try {
      const response = await relatedProductDelete(id)
      toastMessage('success', 'Related Product Deleted')
      // navigate('/inventory')
      mutate()
      return response
    } catch {
      return false
    }
  }
  const deleteProductImage = async (id, mutate) => {
    try {
      const response = await productImageDelete(id)
      toastMessage('success', 'Product Image Deleted')
      // navigate('/inventory')
      mutate()
      return response
    } catch {
      return false
    }
  }
  const coverProductImage = async (id, mutate) => {
    try {
      const response = await productCoverImage(id)
      toastMessage('success', 'Product Image Cover Successfully')
      // navigate('/inventory')
      mutate()
      return response
    } catch {
      return false
    }
  }

  const productShareViaEmail = async (id, data, mutate) => {
    try {
      const response = await shareViaEmailProduct(id, data)
      toastMessage('success', 'Mail sent successfully')
      // navigate('/inventory')
      mutate()
      return response
    } catch {
      return false
    }
  }

  return {
    addProduct,
    deleteCrossRefNumbers,
    deleteFitsWithData,
    deleteRelatedProduct,
    deleteProductImage,
    coverProductImage,
    productShareViaEmail,
  }
}
