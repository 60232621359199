import Api from 'services/Api'

export async function getSupplierList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function getSupplierDetails(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function createNewSupplier(data) {
  const response = await Api.post(`/company`, data)
  return response.data.data
}
export async function UpdateSupplier(data) {
  const response = await Api.put('/company', data)
  return response.data.data
}
export async function deleteProfileImages(data) {
  const response = await Api.delete(`/company-asset/${data?.type}/${data?.companyId}`, data)
  return response.data.data
}
export async function deleteCompanyById(id) {
  const response = await Api.delete(`/company/supplier-remove/${id}`)
  return response.data.data
}

// report

export async function getReportProductList(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function updateProduct(data) {
  const response = await Api.put('/super-admin/product', data)
  return response.data.data
}

// order

export async function getOrderList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function updateOrder(data) {
  const response = await Api.put('/super-admin/orders', data)
  return response.data.data
}

// support

export async function getSupportList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function updateSupport(data) {
  const response = await Api.put('/super-admin/contact-us', data)
  return response.data.data
}

// user

export async function getUserList(url) {
  const response = await Api.get(url)
  return response.data.data
}

export async function updateUser(data) {
  const response = await Api.put('/super-admin/users', data)
  return response.data.data
}
