import Api from 'services/Api'

export async function getCounterListBuyer(url) {
  const response = await Api.get(url)
  return response.data.data
}
export async function setCounterData(data) {
  const response = await Api.post(`/counter/create-supplier`, data)
  return response.data.data
}
export async function setApprovedCounter(data) {
  const response = await Api.post(`/counter/accept`, data)
  return response.data.data
}
export async function counterExpire(data) {
  const response = await Api.post(`/counter/expire`, data)
  return response.data.data
}
export async function counterNudge(data) {
  const response = await Api.post(`/counter/nudge`, data)
  return response.data.data
}
