import Container from 'components/common/Container'
import { React, useState, useEffect } from 'react'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { Divider } from '@mui/material'
import Typography from 'components/common/Typography'
import Checkbox from 'components/common/Checkbox'
import { getColor } from 'theme/colors'
import RadioGroup from 'components/common/radio/RadioGroup'
import Radio from 'components/common/radio/Radio'
import classNames from 'classnames'
import useToggle from 'common-hooks/useToggle'
import { CaretDownIcon } from 'assets/icons'
import { formatPrice } from 'utils/Utils'
import useOrders from 'common-hooks/orders/useOrders'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import QuoteSuccessDialog from './QuoteSuccessDialog'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  priceflex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  colorbody: {
    color: getColor('grey-g35'),
  },
  colortitle: {
    color: getColor('grey-g25'),
  },
  divflex: {
    display: 'flex',
  },
  width: {
    width: '100%',
  },
  radioGrp: {
    height: '27px',
  },
  specialCondtion: {
    marginBottom: '2px',
    display: 'inline-block',
  },
  shppingBtn: (props) => ({
    '& svg': {
      rotate: props.deliveryOptionToggle ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  deliveryOption: {
    width: '100%',
  },
})

const ConfirmationDialog = ({
  open,
  onclose,
  shippingOptions,
  brandName,
  quoteReqId,
  partNumber,
  partName,
  orderDetailsId,

  amount,
  companyname,
  returnAble,
  mutate,
  listMutate,
  supplierdetailsMutate,
  offerPrice,
}) => {
  const handeleCloseDilaog = () => {
    onclose()
    setAcknowledge(false)
  }
  const [acknowledge, setAcknowledge] = useState(false)
  const { createOrder } = useOrders()
  const handleOnAcknowledge = (e) => {
    setAcknowledge(e.target.checked)
  }
  const [successDialog, toggleSuccessDialog] = useToggle(false)
  const [deliveryOptionToggle, setDeliveryOptionToggle] = useToggle(true)
  const [deliveryOption, setDeliveryOption] = useState()
  const { control } = useForm()
  const [selectedOption, setSelectedOptions] = useState({})
  const [disableBtn, setDisableBtn] = useState(true)
  const params = useParams()
  const [orderId, setOrderId] = useState('')
  const classes = useStyles({ deliveryOptionToggle })
  async function onClickBtn() {
    const response = await createOrder(params.id, quoteReqId, selectedOption, mutate, listMutate, supplierdetailsMutate)
    setTimeout(() => {
      if (response) {
        setOrderId(response)
      }
      toggleSuccessDialog(true)
    }, 1000)

    onclose()
  }
  useEffect(() => {
    if (acknowledge && deliveryOption && Object.keys(deliveryOption)?.length > 0) {
      setDisableBtn(false)
    } else if (returnAble === false && !acknowledge) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }, [acknowledge, deliveryOption])
  return (
    <Container>
      <Dialog open={open}>
        <DialogHead title={'Confirmation'} onClose={handeleCloseDilaog} />
        <Container className={classNames(classes.dialogbody, classes.divflex)} direction={'column'} gap={'20px 0'}>
          <Container className={classes.divflex} direction={'column'} gap={'8px 0'}>
            <Typography className={classes.colortitle} variant="title" color={getColor('grey-g25')}>
              Summary
            </Typography>
            <Container className={classes.priceflex}>
              <Typography className={classes.colorbody} variant="body1" color={getColor('grey-g35')}>
                {brandName} - {partName} {partNumber || ''}
              </Typography>
              <Typography className={classes.colorbody} variant="body1" color={getColor('grey-g35')}>
                {formatPrice(offerPrice || amount)}
              </Typography>
            </Container>
          </Container>
          <Divider />
          <Container>
            <Container flex justify="space-between">
              <Container flex gap={'0 5px'}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  {/* Shipping Options to {zipCode || ''} */}
                  Shipping
                </Typography>
              </Container>
              <Button
                variant="secondary"
                className={classes.shppingBtn}
                onClick={() => setDeliveryOptionToggle(true)}
                icon={<CaretDownIcon />}
              />
            </Container>
            {deliveryOptionToggle && (
              <Container width={'100%'}>
                <Controller
                  name={'options'}
                  control={control}
                  render={({ field: { value } }) => (
                    <RadioGroup
                      className={classes.deliveryOption}
                      value={value}
                      onChange={(e) => setDeliveryOption(e.target.value)}
                    >
                      {shippingOptions?.freight_ground && (
                        <>
                          <Container flex justify="space-between" width={'100%'}>
                            <Radio
                              label={'Ground'}
                              value={shippingOptions?.freight_ground}
                              name={'freight_ground'}
                              className="shipingRadio"
                              onChange={(e) => setSelectedOptions({ freight_ground: e.target.value })}
                            />
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {Number(shippingOptions?.freight_ground) === 0
                                ? 'free'
                                : formatPrice(shippingOptions?.freight_ground)}
                            </Typography>
                          </Container>
                        </>
                      )}
                      {shippingOptions?.freight_next_day && (
                        <>
                          <Container flex justify="space-between" width={'100%'}>
                            <Radio
                              label={'Next Day Delivery'}
                              value={shippingOptions?.freight_next_day}
                              className="shipingRadio"
                              onChange={(e) => setSelectedOptions({ freight_next_day: e.target.value })}
                            />
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {Number(shippingOptions?.freight_next_day) === 0
                                ? 'free'
                                : formatPrice(shippingOptions?.freight_next_day)}
                            </Typography>
                          </Container>
                        </>
                      )}
                      {shippingOptions?.freight_second_day && (
                        <>
                          <Container flex justify="space-between" width={'100%'}>
                            <Radio
                              label={'2nd Day'}
                              value={shippingOptions?.freight_second_day}
                              className="shipingRadio"
                              onChange={(e) => setSelectedOptions({ freight_second_day: e.target.value })}
                            />
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {Number(shippingOptions?.freight_second_day) === 0
                                ? 'free'
                                : formatPrice(shippingOptions?.freight_second_day)}
                            </Typography>
                          </Container>
                        </>
                      )}
                      {shippingOptions?.mdo_ltl && (
                        <>
                          <Container flex justify="space-between" width={'100%'}>
                            <Radio
                              label={'LTL'}
                              value={shippingOptions?.mdo_ltl}
                              className="shipingRadio"
                              onChange={(e) => setSelectedOptions({ mdo_ltl: e.target.value })}
                            />
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {Number(shippingOptions?.mdo_ltl) === 0 ? 'free' : formatPrice(shippingOptions?.mdo_ltl)}
                            </Typography>
                          </Container>
                        </>
                      )}
                      {shippingOptions?.mdo_local_delivery && (
                        <>
                          <Container flex justify="space-between" width={'100%'}>
                            <Radio
                              label={'Local Delivery'}
                              value={shippingOptions?.mdo_local_delivery}
                              className="shipingRadio"
                              onChange={(e) => setSelectedOptions({ mdo_local_delivery: e.target.value })}
                            />
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {Number(shippingOptions?.mdo_local_delivery) === 0
                                ? 'free'
                                : formatPrice(shippingOptions?.mdo_local_delivery)}
                            </Typography>
                          </Container>
                        </>
                      )}
                      {shippingOptions?.mdo_pick_up && (
                        <>
                          <Container flex justify="space-between" width={'100%'}>
                            <Radio
                              label={'Pick Up'}
                              value={shippingOptions?.mdo_pick_up}
                              className="shipingRadio"
                              onChange={(e) => setSelectedOptions({ mdo_pick_up: e.target.value })}
                            />
                            <Typography variant="body1" color={getColor('grey-g35')}>
                              {Number(shippingOptions?.mdo_pick_up) === 0
                                ? 'free'
                                : formatPrice(shippingOptions?.mdo_pick_up)}
                            </Typography>
                          </Container>
                        </>
                      )}
                    </RadioGroup>
                  )}
                />
              </Container>
            )}
          </Container>
          <Divider />
          <Container className={classes.divflex} direction={'column'} gap={'10px 0'}>
            <Container className={classes.priceflex}>
              <Typography className={classes.colorbody} variant="body1" color={getColor('grey-g35')}>
                Subtotal
              </Typography>
              <Typography className={classes.colorbody} variant="body1" color={getColor('grey-g35')}>
                {formatPrice(offerPrice || amount)}
              </Typography>
            </Container>
            <Container className={classes.priceflex}>
              <Typography className={classes.colorbody} variant="body1" color={getColor('grey-g35')}>
                Shipping total
              </Typography>
              <Typography className={classes.colorbody} variant="body1" color={getColor('grey-g35')}>
                {formatPrice(deliveryOption)}
              </Typography>
            </Container>
            <Container className={classes.priceflex}>
              <Typography className={classes.colortitle} variant="title" color={getColor('grey-g35')}>
                Total
              </Typography>
              <Typography className={classes.colortitle} variant="title" color={getColor('grey-g35')}>
                {formatPrice(parseInt(offerPrice || amount, 10) + parseInt(deliveryOption, 10))}
              </Typography>
            </Container>
          </Container>

          {returnAble && (
            <>
              <Divider />
              <Container>
                <Typography className={classNames(classes.colortitle, classes.specialCondtion)} variant="title">
                  *Special Condition from {companyname}
                </Typography>
                <Checkbox label={'I acknowledge that this item is non-returnable'} onChange={handleOnAcknowledge} />
              </Container>
            </>
          )}
        </Container>
        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={handeleCloseDilaog}>
              Cancel
            </Button>
            <Button
              // variant={disableBtn === t : 'positive'}
              variant="positive"
              disabled={disableBtn}
              onClick={() => onClickBtn()}
            >
              Send Order
            </Button>
          </Container>
        </DialogFooter>
      </Dialog>
      <QuoteSuccessDialog
        open={successDialog}
        onClose={toggleSuccessDialog}
        orderDetailsId={orderDetailsId}
        orderId={orderId}
      />
    </Container>
  )
}
ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  shippingOptions: PropTypes.object,
  brandName: PropTypes.string,
  partNumber: PropTypes.string,
  partName: PropTypes.string,
  quoteReqId: PropTypes.string,
  amount: PropTypes.string,
  orderDetailsId: PropTypes.string,
  companyname: PropTypes.string,
  returnAble: PropTypes.bool,
  mutate: PropTypes.bool,
  listMutate: PropTypes.func,
  supplierdetailsMutate: PropTypes.func,
  offerPrice: PropTypes.string,
}

export default ConfirmationDialog
