/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { getPartsData } from 'common-api/create-quote/createQuote'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import { useState } from 'react'

export default function usePartsDetails(equpId, part, isCreateable) {
  const [searchedParts, onPartsSearch] = useState('')
  const [partsTree, setPartsTree] = useState(part ? [part] : [])
  const [selectedNode, setSelectedNode] = useState(part ? { nodeId: part?.id, isSelected: true } : null)
  const params = {
    ...(equpId && equpId !== 81 && { equipment_id: equpId }),
    // ...(brandId && brandId !== 81 && { brand_id: brandId }),
    ...(selectedNode?.nodeId && { parent_id: selectedNode?.nodeId }),
  }
  const { data, mutate } = useSwrInfinite('/services/part', params, getPartsData)
  const createNew = { id: '', name: 'Create' }
  const partsList = data
    ? data.map((obj) => ({
        id: obj.id,
        parent_id: obj.parent_id,
        name: obj.name,
        user_created: obj?.user_created,
        ...(obj.childs !== '0' && { children: [] }),
      }))
    : []
  if (partsTree?.length === 0 && data && data.length > 0) {
    if (isCreateable) {
      setPartsTree([createNew, ...partsList])
    } else {
      setPartsTree(partsList)
    }
  } else if (data) {
    mergeChildNodes(partsList, partsTree, selectedNode, isCreateable)
  }

  return {
    partsTree,
    onPartsSearch,
    searchedParts,
    isLoading: !data,
    setSelectedNode,
    selectedNode,
    childs: partsList,
    setPartsTree,
    mutate,
  }
}

const mergeChildNodes = (data, partsTree, selectedNode, isCreateable) => {
  mergeMe(partsTree)
  function mergeMe(list) {
    // eslint-disable-next-line guard-for-in
    for (const item in list) {
      if (Array.isArray(list[item].children) && list[item].children?.length > 0) {
        mergeMe(list[item].children)
      } else if (list[item].id === selectedNode?.nodeId) {
        const createNew = { id: '', name: 'Create', parent_id: selectedNode?.nodeId }
        if (data?.length === 0) delete list[item].children
        else if (isCreateable) {
          list[item].children = [createNew, ...data]
        } else {
          list[item].children = data
        }
      }
    }
  }
}
