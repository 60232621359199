import Container from 'components/common/Container'
import SearchField from 'components/common/search/SearchField'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import QuoteImage from 'assets/images/QuoteImage.png'
import { makeStyles } from '@mui/styles'
import Chip from 'components/common/Chip'
import useSearch from 'common-hooks/useSearch'
import { getColor } from 'theme/colors'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import Button from 'components/common/Button'
import { useContext } from 'react'
import { CreateSupplierContext } from 'containers/super-admin/supplier-mgt/SupplierUtils'
import AddPartsTreeView from './AddPartsTreeView'

const useStyles = makeStyles(() => ({
  form: {
    overflow: 'auto',
    width: 'auto',
  },
  root: {
    padding: '20px 30px 20px 30px',
    height: '471px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  productInfo: {
    width: '100%',
    padding: '20px 30px',
    zIndex: 999,
    position: 'absolute',
    display: 'none',
    bottom: (props) => getPosition(props.toggle),
    backgroundColor: getColor('system-bg'),
    '& input': {
      width: 'auto ',
    },
  },
  img: {
    width: '50px',
    height: '50px',
    '& img': {
      width: '80px',
      ObjectFit: 'cover',
      height: '100%',
      border: '1px solid #F0F0F4 ',
    },
  },
  divFlex: {
    display: 'flex',
  },
  cancelBtn: {
    border: '  1px solid #9799B5',
  },
  dailogFooter: {
    padding: '20px 30px',
    maxheight: '100px',
    position: 'absolute',
    bottom: 70,
    width: '100%',
    backgroundColor: getColor('light'),
    display: 'flex',
    gap: '10px',
    justify: 'flex-start',
    flexFlow: 'wrap',
    borderBottom: '1px solid #F0F0F4 ',
  },
}))

export default function AddPartsDialog({ open, onClose, equipmentId, partId }) {
  const classes = useStyles()
  const { search, onSearch } = useSearch()
  const { onSelectNode, partsData, setPartsData } = useContext(CreateSupplierContext)

  const handleAddParts = () => {
    onSelectNode((pre) => {
      pre?.map((obj) => {
        if (obj.id === equipmentId) {
          obj.children = [{}]
          partsData?.map((el) => {
            if (!el.parent_id && el.equip_id === equipmentId) {
              if (JSON.stringify(obj.children[0]) === '{}') {
                obj.children.splice(0, 1)
              }
              if (el.id) obj.children.push(el)
            }
            return 0
          })
        }
        return 0
      })
      return pre
    })
    onClose()
  }

  return (
    <Dialog open={open} size={'5.5xl'} className={classes.main}>
      <DialogHead onClose={onClose}>Parts</DialogHead>
      <Container>
        <Container className={classes.form}>
          <form>
            <Container flex justify="space-between" className={classes.productInfo}>
              <Container flex width="100%" justify="flex-start" gap={'10px'}>
                <img src={QuoteImage} alt="placehodler" className={classes.img} />
                <Container className={classes.divFlex} direction="column" gap="4px">
                  <Typography variant="body1">John Deere</Typography>
                  <Container flex gap="10px">
                    <Chip label="Loader" />
                    <Chip label="244K" />
                    <Chip label="1LU244KXLZB040616" />
                  </Container>
                </Container>
              </Container>
              <Container justify="center" flex>
                <SearchField
                  id="search-feild"
                  placeholder={'Search part description'}
                  onSearch={(e) => {
                    onSearch(e)
                  }}
                  value={search}
                />
              </Container>
            </Container>

            <Container className={classes.root}>
              <AddPartsTreeView
                equipmentId={equipmentId}
                partId={partId}
                partsData={partsData}
                setPartsData={setPartsData}
              />
            </Container>
          </form>
        </Container>

        <DialogFooter>
          <Container flex justify="flex-end" gap={'0 10px'}>
            <Button variant="transparent" className={classes.cancelBtn} width={77} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" width={58} onClick={handleAddParts}>
              Add
            </Button>
          </Container>
        </DialogFooter>
      </Container>
    </Dialog>
  )
}

AddPartsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  equipmentId: PropTypes.string,
  partId: PropTypes.string,
}

function getPosition(infoVisbility) {
  return `${infoVisbility ? '126px' : '67px'}`
}
