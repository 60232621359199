import { AuthContext } from 'utils/provider/AuthContext'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import AppPage from 'containers/AppPage'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'

export default function AdminPermissionRoute({ children }) {
  const { authState } = useContext(AuthContext)
  const isAuthorized = authState?.isAuthenticated || false
  const location = useLocation()
  const userRole = getFromLocalStorage(constants.ROLE)
  if (!isAuthorized) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/home" />
  }
  if (userRole !== 'super_admin') {
    return <Navigate to="/accessDenied" />
  }
  if (location?.pathname.includes('sa/addsupplier') || location?.pathname.includes('sa/editsupplier')) {
    return children
  }

  // authorized so return child components
  return (
    <AppPage appBar appDrawer>
      {children}
    </AppPage>
  )
}
AdminPermissionRoute.propTypes = {
  children: PropTypes.node,
}
