import PropTypes from 'prop-types'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material'
import { ReactComponent as Calendar } from 'assets/icons/Calendar.svg'
import typography from 'theme/typography'
import { makeStyles } from '@mui/styles'
import Container from './Container'
import Label from './Label'
import { DATE_MONTH_YEAR, formatDate } from 'utils/dateUtils'
import { getColor } from 'theme/colors'

export function createOption(value, label) {
  return {
    value,
    label,
  }
}

const useStyles = makeStyles({
  labelTypography: {
    fontSize: typography.paragraph2.fontSize,
    fontWeight: typography.paragraph2.fontWeight,
  },
})

export default function MonthYearPicker({ onChange, views, minDate, maxDate, value, label, dateFormate }) {
  const classes = useStyles()
  const popperSx = {
    '& .MuiPaper-root': {
      boxShadow: '0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04)',
      border: '1px solid #F0F0F4',
      width: '360px',
      height: '331px',
    },
    '& .MuiCalendarOrClockPicker-root': {
      '& div': {
        // width: '100%',
        padding: '0',
        // margin: '0 auto',
        '& .Mui-selected:focus': {
          background: '#FF8C1A',
        },
      },
    },
    '& .MuiYearPicker-root': {
      maxHeight: '271px',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#F0F0F4',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#C3C5D5',
        borderRadius: '100px',
      },
    },
    '& .PrivatePickersYear-root': {
      '& .Mui-selected': {
        background: '#FF8C1A',
        color: '#fff',
      },
      '& button': {
        background: 'transperent',
        color: '#36384C',
        '&:hover': {
          backgroundColor: '#FFF7E6',
          color: '#000',
        },
      },
    },
    '& .MuiMonthPicker-root': {
      paddingRight: '10px',
      '& .Mui-selected': {
        background: '#FF8C1A',
        color: '#fff',
      },
    },
    placeholder: {
      '&::placeholder': {
        color: getColor('grey-g65'),
        fontFamily: 'Lexend Deca',
      },
    },
  }

  return (
    <Container justify="flex-start" direction="column">
      <Label className={classes.labelTypography}>{label}</Label>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          views={views}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={onChange}
          PopperProps={{ sx: popperSx }}
          components={{
            OpenPickerIcon: Calendar,
          }}
          renderInput={(params) => {
            const prop = { ...params }
            prop.inputProps.value = value ? formatDate(value, dateFormate || DATE_MONTH_YEAR) : ''
            // prop.inputProps.value = value ? formatDate(value, dateFormate || DATE_MONTH_YEAR) : value
            prop.inputProps.placeholder = 'Select'

            return <TextField {...prop} helperText={null} />
          }}
        />
      </LocalizationProvider>
    </Container>
  )
}

MonthYearPicker.propTypes = {
  views: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string,
  control: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  dateFormate: PropTypes.string,
}
