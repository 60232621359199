import { getSupplierRequestedList } from 'common-api/buyer-supplier/buyerSupplier'
import { useState } from 'react'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'

export default function useDummyQuotes(id) {
  const limit = 10
  const [pagimation, setPagination] = useState({ limit, page: 1 })
  const setPaginate = (isNext) => {
    if (isNext) {
      if (data && data.length > 0) setPagination({ limit, page: pagimation.page + 1 })
    } else if (pagimation.page > 1) setPagination({ limit, page: pagimation.page - 1 })
  }
  const params = {
    limit: pagimation.limit,
    page: pagimation.page,
    ...(id && { industry: id }),
  }

  const { data, mutate } = useSwrInfinite('/services/quote/dummy', params, getSupplierRequestedList)
  return {
    reqQuoteData: data,
    mutate,
    isLoadingReq: !data,
    pagimation,
    setPaginate,
  }
}
