/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { getPartsData } from 'common-api/create-quote/createQuote'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import { useState } from 'react'
// import { partsDataParse } from 'containers/buyer-quotes/BuyerQuotesUtils'

export default function usePartsDeep(equpId) {
  const [query, setSearchQuery] = useState('')
  const params = {
    ...(equpId && equpId !== 81 && { equipment_id: equpId }),
    // ...(brandId && brandId !== 81 && { brand_id: brandId }),
    ...(query?.length > 2 && { search: query }),
  }
  const { data } = useSwrInfinite(query?.length > 0 ? '/services/part/deep' : null, params, getPartsData)
  // Old deep search
  // const list = data ? partsDataParse(data) : []
  const list =
    data?.length > 0
      ? [
          {
            id: data?.[0]?.id,
            name: data?.[0]?.name,
            parent_id: data?.[0]?.parent_id,
            children: [{ parent_id: data?.[0]?.id, name: 'Add Part' }],
          },
        ]
      : []
  // Old deep search
  // return {
  //   searchedTree: list?.partsList,
  //   setSearchQuery,
  //   query,
  //   allNodeIds: list?.allNodeIds,
  // }
  return {
    searchedTree: list,
    setSearchQuery,
    query,
    allNodeIds: data?.[0]?.id || [],
  }
}
