import Button from 'components/common/Button'
import Link from 'components/common/Link'
import TextField from 'components/common/text/TextField'
import useAuth from 'common-hooks/useAuth'
import { getFormError } from 'utils/form/form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import Container from 'components/common/Container'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'

const schema = yup
  .object({
    email: yup.string().email('Invalid email format').required('Email is required'),
  })
  .required()

const useStyles = makeStyles(() => ({
  bottomLine: {
    color: getColor('grey-g65'),
    textAlign: 'center',
  },
  bottomLineLink: {
    color: getColor('grey-g65'),
    textDecoration: 'underline',
  },
  loginForm: {
    ' & .continueBtn': {
      marginBottom: '10px',
    },
  },
  dividder: {
    padding: '0 10px',
    color: getColor('grey-g65'),
  },
}))
export default function VerifyEmail({ onStepChange, setUserId, setEmail }) {
  const classes = useStyles()
  const { isLoading, verifyEmail } = useAuth()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const email = useWatch({
    control,
    name: 'email',
  })
  const onSubmit = async (data) => {
    const userId = await verifyEmail(data.email)
    setEmail(data.email)
    if (userId?.type !== false) {
      setUserId(userId)
      onStepChange(2)
    }
    if (userId.error.code === 400) {
      return false
    }
    if (userId?.type === false) {
      onStepChange(3)
    }
    return true
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container
        flex
        alignItems="center"
        direction={'column'}
        width={'100%'}
        className={classes.loginForm}
        gap={'20px 0'}
      >
        <TextField
          id="login-email"
          type={'email'}
          name={'email'}
          placeholder="john.d@partsclub.com"
          label={'Email'}
          {...register('email', { required: true })}
          error={getFormError(errors, 'email')}
        />

        <Container width="100%" flex direction={'column'}>
          <Button loading={isLoading} variant="secondary" border type="submit" width={'100%'} className={'continueBtn'}>
            Continue With Email
          </Button>

          <Link variant="body2" underline to={email ? `/forgot-password/${email}` : '/forgot-password'}>
            Forgot Password?
          </Link>
        </Container>
      </Container>
    </form>
  )
}
VerifyEmail.propTypes = {
  onStepChange: PropTypes.func,
  setUserId: PropTypes.func,
  setEmail: PropTypes.func,
}
