import { Route, Routes, Navigate } from 'react-router-dom'
import Home from 'containers/home/Home'
import Login from 'containers/authentication/Login'
import BuyerQuotesList from 'containers/buyer-quotes/BuyerQuotesList'
import BuyerQuoteDetails from 'containers/buyer-quote-details/BuyerQuoteDetails'
import SupplierQuotesList from 'containers/supplier-quotes/SupplierQuotesList'
import SupplierQuoteDetails from 'containers/supplier-quote-details/SupplierQuoteDetails'
import Inventory from 'containers/inventory/Inventory'
import SuperAdminRoutes from 'containers/super-admin/SuperAdminRoutes'
import ForgotPassword from 'containers/authentication/forgot-password/ForgotPassword'
import ForgotLink from 'containers/authentication/forgot-password/ForgotLink'
import BuyerQuotesRequestList from 'containers/supplier-request-board/BuyerQuotesRequestList'
import BuyerSupplierList from 'containers/suppliers-list/BuyerSupplierList'
import BuyerSupplierDetails from 'containers/suppliers-list/BuyerSupplierDetails'
import SupplierDetails from 'containers/landing-page/SupplierDetails'
import ForgotResetPassword from 'containers/authentication/forgot-password/ForgotResetPassword'
import LandingPage from 'containers/landing-page/LandingPage'
import Icons from 'containers/Icons'
import QuoteRequest from 'containers/landing-page/QuoteRequest'
import CreateQuote from 'containers/landing-page/create-quote/CreateQuote'
import QuoteRequestDetail from 'containers/landing-page/QuoteRequestDetail'
import SupplierList from 'containers/landing-page/SupplierList'
import UserProfile from 'containers/user-profile/UserProfile'
import BuyingPage from 'containers/landing-page/BuyingPage'
import SellingPage from 'containers/landing-page/SellingPage'
import AboutUsPage from 'containers/landing-page/AboutUsPage'
// import SubscriptionUpgrade from 'containers/settings/subscription/SubscriptionUpgrade'
import ProfileSettings from 'containers/settings/profile/ProfileSettings'
import CompanySettings from 'containers/settings/company/CompanySettings'
// import PaymentSettings from 'containers/settings/payment/PaymentSettings'
import SecuritySettings from 'containers/settings/security/SecuritySettings'
// import SubscriptionSettings from 'containers/settings/subscription/SubscriptionSettings'
import ShippingSettings from 'containers/settings/shipping/ShippingSettings'
import NotFound from 'containers/NotFound'
import { constants } from 'utils/constants'
import { getFromLocalStorage } from 'utils/Utils'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PrivateRoute from './PrivateRoute'
import SubscriptionUpgrade from 'containers/settings/subscription/SubscriptionUpgrade'
import PaymentSettings from 'containers/settings/payment/PaymentSettings'
import SubscriptionSettings from 'containers/settings/subscription/SubscriptionSettings'
import SupplierOrders from 'containers/orders/supplier-orders/SupplierOrders'
import BuyerOrders from 'containers/orders/buyer-orders/BuyerOrders'
import SupplierActiveQuoteDetails from 'containers/supplier-quotes/SupplierActiveQuoteDetails'
import AddProduct from 'containers/inventory/AddProduct'
import BuyerOrderShipDetails from 'containers/orders/buyer-orders/BuyerOrderShipDetails'
import SupplierOrderShipDetails from 'containers/orders/supplier-orders/SupplierOrderShipDetails'
import SearchPartDetails from 'containers/product-search/SearchPartDetails'
import AdminPermissionRoute from './AdminPermissionRoute'
import CommonAuthRoute from './CommonAuthRoute'
import SearchListing from 'containers/product-search/SearchListing'
import PartsList from 'containers/landing-page/PartsList'
import PartDetailsPage from 'containers/landing-page/PartDetailsPage'
import OffersList from 'containers/offers/OffersList'
import OfferDetails from 'containers/offers/OfferDetails'
import Import from 'containers/inventory/Import'

export default function AppRoutes() {
  const userRole = getFromLocalStorage(constants.ROLE)
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY)
  // const superAdminProps = {
  //   ...(userRole !== 'super_admin' && { element: <Navigate to={userRole ? '/quotes' : '/home'} /> }),
  // }
  return (
    <Routes>
      {/* <Route path="/sa" {...superAdminProps}>
        {userRole === 'super_admin' && SuperAdminRoutes()}
      </Route> */}
      <Route path="/sa">{SuperAdminRoutes()}</Route>
      <Route
        path="/components"
        element={
          <CommonAuthRoute>
            <Home />
          </CommonAuthRoute>
        }
      />
      <Route
        path="/quotes"
        element={<PrivateRoute>{userRole === 'supplier' ? <SupplierQuotesList /> : <BuyerQuotesList />}</PrivateRoute>}
      />
      <Route
        path="/quotes/:type/:id"
        element={
          <PrivateRoute>
            <BuyerQuoteDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/quotes/supplierdetail/request/:id"
        element={
          <PrivateRoute>
            <SupplierQuoteDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/quotes/supplierdetail/:id/active/:reqId"
        element={
          <PrivateRoute>
            <SupplierQuoteDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/quotes/active/:id/:reqId"
        element={
          <PrivateRoute>
            <SupplierActiveQuoteDetails />
          </PrivateRoute>
        }
      />

      <Route
        path="/suppliers"
        element={
          <PrivateRoute>
            <BuyerSupplierList />
          </PrivateRoute>
        }
      />
      <Route
        path="/suppliers/:suppliername/:id"
        element={
          <PrivateRoute>
            <BuyerSupplierDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory"
        element={
          <PrivateRoute>
            <Inventory />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/import"
        element={
          <PrivateRoute>
            <Import />
          </PrivateRoute>
        }
      />
      <Route
        path="/search"
        element={
          <PrivateRoute>
            <SearchListing />
          </PrivateRoute>
        }
      />
      <Route
        path="/search/:suppliername/:id"
        element={
          <PrivateRoute>
            <SearchPartDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/addproduct"
        element={
          <PrivateRoute>
            <AddProduct />
          </PrivateRoute>
        }
      />
      <Route
        path="/inventory/editproduct/:id"
        element={
          <PrivateRoute>
            <AddProduct />
          </PrivateRoute>
        }
      />

      <Route
        path="/requestboard"
        element={
          <PrivateRoute>
            <BuyerQuotesRequestList />
          </PrivateRoute>
        }
      />
      <Route
        path="/icons"
        element={
          <PrivateRoute>
            <Icons />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <AdminPermissionRoute>
            <UserProfile />
          </AdminPermissionRoute>
        }
      />
      <Route
        path="/settings/profile"
        element={
          <PrivateRoute>
            <ProfileSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/company"
        element={
          <PrivateRoute>
            <CompanySettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/payment"
        element={
          <PrivateRoute>
            <PaymentSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/shipping"
        element={
          <PrivateRoute>
            <ShippingSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/membership"
        element={
          <PrivateRoute>
            <SubscriptionSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/security"
        element={
          <PrivateRoute>
            <SecuritySettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/upgradeaccount"
        element={
          <PrivateRoute>
            <Elements stripe={stripePromise}>
              <SubscriptionUpgrade />
            </Elements>
          </PrivateRoute>
        }
      />
      <Route
        path="/orders"
        element={<PrivateRoute>{userRole === 'supplier' ? <SupplierOrders /> : <BuyerOrders />}</PrivateRoute>}
      />

      <Route
        path="/orders/:ordertype/:orderid/:id"
        element={
          <PrivateRoute>
            {userRole === 'supplier' ? <SupplierOrderShipDetails /> : <BuyerOrderShipDetails />}
          </PrivateRoute>
        }
      />
      <Route
        path="/offers"
        element={
          <PrivateRoute>
            <OffersList />
          </PrivateRoute>
        }
      />
      <Route
        path="/offers/active"
        element={
          <PrivateRoute>
            <OfferDetails />
          </PrivateRoute>
        }
      />
      <Route path="/accessDenied" element={<NotFound />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Navigate to={`${userRole === 'super_admin' ? '/sa' : '/quotes'}`} />} />
      <Route path="/forgotlink" element={<ForgotLink />} />
      <Route path="/forgot-password/:email" element={<ForgotPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:id" element={<ForgotResetPassword />} />
      <Route path="/home" element={<LandingPage />} />
      <Route path="/quoterequest" element={<QuoteRequest />} />
      <Route path="/quoterequest/detail/:id" element={<QuoteRequestDetail />} />
      <Route path="/supplier" element={<SupplierList />} />
      <Route path="/supplier/:suppliername/:id" element={<SupplierDetails />} />
      <Route path="/createquote" element={<CreateQuote />} />
      <Route path="/buying" element={<BuyingPage />} />
      <Route path="/selling" element={<SellingPage />} />
      <Route path="/about" element={<AboutUsPage />} />
      <Route path="/parts" element={<PartsList />} />
      <Route path="/parts/:suppliername/:id" element={<PartDetailsPage />} />
      <Route path="/parts/:id" element={<PartDetailsPage />} />
    </Routes>
  )
}
