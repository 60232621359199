import { makeStyles } from '@mui/styles'
import {
  CaretDownIcon,
  ReturnsIcon,
  ShareIcon,
  ShippingIcon,
  UntilShipped,
  Verfied,
  VerifiedIcon,
  Warranty,
} from 'assets/icons'
import SampleImage1 from 'assets/images/PartsSampleImage-1.png'
import reportIcon from 'assets/images/reportIcon.svg'
import PropTypes from 'prop-types'
import SampleImage2 from 'assets/images/PartsSampleImage-2.png'
import SampleImage3 from 'assets/images/PartsSampleImage-3.png'
import JohndearBrandImage from 'assets/images/JohndearBrandImage.svg'
import useToggle from 'common-hooks/useToggle'
import TwoWayController from 'components/custom/TwoWayController'
import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Link from 'components/common/Link'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
import TitleList from 'components/custom/list/TitleList'
import { getColor } from 'theme/colors'
import { formatPrice } from 'utils/Utils'
import ProductSlider from 'components/custom/ProductSlider'
import { useEffect, useState } from 'react'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import { parseCrossRefNumbers, parseFitsWith } from './InventoryUtils'

const useStyle = makeStyles({
  root: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px ',
  },
  coverImag: {
    height: '350px',
    width: '372px',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
  },
  imagesDiv: {
    // margin: '0 30px 30px 30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '372px',
  },
  otherImg: {
    width: '78px',
    height: '78px',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
  },
  stockChip: {
    background: '#0DA50D',
    width: 'auto',
    height: '28px',
    borderRadius: '100px',

    alignItems: 'center',
    '& span': {
      color: '#fff',
      fontSize: '12px',
      fontWeight: 400,
    },
  },
  shareBtn: {
    background: 'transparent',
    color: getColor('grey-g25'),
    '& svg': {
      marginRight: '5px',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  titleDiv: {
    marginBottom: '20px',
  },
  detailsDiv: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    gap: '20px',
  },
  aboutDiv: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  optionsDiv: {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    '& div': {
      padding: '0 10px',
    },
    // justifyContent: 'space-between',
  },
  fitsDiv: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  exapanBtn: (props) => ({
    '& svg': {
      rotate: props.delivery ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  fitsDetailDiv: {
    marginBottom: '15px',
    display: 'flex',
    gap: '5px ',
    flexDirection: 'column',
    '& h4': {
      width: '122px',
    },
  },
  fitsDetailDiv2: {
    marginBottom: '5px',
    display: 'flex',
    gap: '20px',
    '& h4': {
      minWidth: '122px',
    },
  },
  fitsUl: {
    margin: '0',
    paddingLeft: '25px',
    padding: '0',
  },
  crossBtn: (props) => ({
    '& svg': {
      rotate: props.crossRef ? '180deg' : 'none',
      transition: 'all 0.2s',
    },
    padding: 0,
    '&:hover': {
      background: 'unset',
    },
  }),
  titleList: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  priceBox: {
    background: getColor('system-bg'),
    padding: '20px',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  msrpTxt: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    textDecorationLine: 'line-through',
    color: '#9799B5',
  },
  mrpTxt: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '38px',
    color: '#13141B',
  },
  link: {
    fontSize: '12px',
    fontWeight: 400,
    color: `${getColor('func-info')} !important`,
    textDecoration: 'underLine',
  },
  sendBtn: {
    width: '228px',
    height: '36px',
    border: `1px solid ${getColor('grey-g65')}`,
    borderRadius: '4px',
    background: 'transparent',
    color: getColor('grey-g65'),
    '&:hover': {
      background: 'transparent',
    },
  },
  soldDiv: {
    position: 'relative',
  },
  soldLink: {
    '&:link': {
      lineHeight: '5px',
      textDecoration: 'underLine',
    },
    '& svg': {
      height: '18px',
      width: '18px',
      position: 'absolute',
      bottom: '22px',
    },
  },
  reletedProdDiv: {
    background: '#fff',
    padding: '30px',
  },
  reletedProdDetails: {
    padding: '10px',
  },
  reletedProd: {
    border: `1px solid ${getColor('grey-g95')}`,
    width: '238px',
    marginTop: '20px',
  },
  reletedProdImage: {
    borderBottom: `1px solid ${getColor('grey-g95')}`,
  },
  imageRoot: {
    '& p': {
      paddingTop: '15px',
    },
  },
  reportthiLink: {
    fontSize: ' 12px',
    lineHeight: 'unset',
    backgroundColor: 'unset',
    color: '#9799B5',
    borderBottom: '1px solid #9799B5',
    height: 'unset',
    width: 'unset',
    minHeight: 'unset',
    borderRadius: 'unset',
    padding: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& span': {
      fontSize: ' 12px',
    },
  },
  reportRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px 0',
  },
  productDialog: {
    maxWidth: '1440px',
    width: '100%',
  },
  modelRoot: {
    display: 'flex',
    gap: '0 20px',
  },
  partRoot: {
    display: 'flex',
  },
  titileGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px 0',
  },
  withourSliderImage: {
    '& img': {
      width: '78px',
      height: '78px',
    },
  },
  withourImageRoot: {
    display: 'flex',
    gap: '0 10px',
  },
  smallDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export default function PreviewListingDialog({ open, onClose, searchDetails }) {
  // const { searchDetails, isLoading } = useSearchProductDetails(param.id)

  const [delivery, toggleDelivery] = useToggle(true)
  const [crossRef, toggleCrossRef] = useToggle(true)
  const [buyNowBtnDisabled, setBuyNowBtnDisabled] = useState(true)

  const classes = useStyle({ delivery, crossRef })
  const [quantity, setQuantity] = useState(1)

  const tempProductArray = [searchDetails?.brand_cover || JohndearBrandImage, SampleImage1, SampleImage2, SampleImage3]

  if (searchDetails?.prod_assets?.length < 4) {
    searchDetails?.prod_assets?.map((obj) => tempProductArray?.splice(0, 0, obj.path))
    // searchDetails?.prod_assets?.map((obj) => tempProductArray?.push(obj.path))
  }

  const imgArray = [searchDetails?.brand_cover || JohndearBrandImage]

  // const sampleImages =
  //   searchDetails && searchDetails?.prod_assets?.length > 3
  //     ? searchDetails?.prod_assets?.map((obj) => obj?.path)
  //     : tempProductArray

  if (searchDetails && searchDetails?.prod_assets?.length > 3) {
    searchDetails?.prod_assets?.map((obj) => imgArray.push(obj?.path))
  }
  const sampleImages = searchDetails && searchDetails?.prod_assets?.length > 3 ? imgArray : tempProductArray

  const [cuurentIndex, setCurrentIndex] = useState(0)

  const url = sampleImages?.filter((d, index) => index === cuurentIndex)

  const fitsWithData = searchDetails && parseFitsWith(searchDetails)
  const crossRefData = searchDetails && parseCrossRefNumbers(searchDetails)

  useEffect(() => {
    if (Number(searchDetails?.weight) > 0 && Number(searchDetails?.unit_price) > 0 && Number(searchDetails?.qty) > 0) {
      setBuyNowBtnDisabled(false)
    } else {
      setBuyNowBtnDisabled(true)
    }
  }, [searchDetails])

  return (
    <Dialog open={open} className={classes.productDialog}>
      <DialogHead onClose={onClose} />
      <Container>
        {/* {isLoading && <LoadingOverlay />} */}
        <Container className={classes.root}>
          <Container className={classes.imagesDiv}>
            <Container>
              <Image src={url[0]} className={classes.coverImag} />
            </Container>
            <Container className={classes.imageRoot}>
              {/* {searchDetails?.prod_assets?.length < 4 && searchDetails?.prod_assets?.length !== 0 ? (
                <Container className={classes.withourImageRoot}>
                  {searchDetails?.prod_assets.map((obj, index) => (
                    <Container key={index} className={classes.withourSliderImage}>
                      <Image src={obj?.path} />
                    </Container>
                  ))}
                </Container>
              ) : ( */}
              <ProductSlider sliderData={sampleImages} setCurrentIndex={setCurrentIndex} />
              {/* )} */}
              <Typography variant="overline" color={getColor('grey-g80')} component="p">
                *Logos are used for reference only. All trademarks, logos and brand names are the property of their
                respective owners.
              </Typography>
            </Container>
          </Container>

          <Container width={'610px'}>
            <Container className={classes.titileGrid}>
              <Typography variant="h1">
                {searchDetails?.brand_name} - {searchDetails?.part_desp}{' '}
                {searchDetails?.part_no ? `- ${searchDetails?.part_no}` : ''}
              </Typography>
              <Container display="flex" justify="space-between" className={classes.titleDiv}>
                {searchDetails?.stock ? (
                  <Chip label="In Stock" className={classes.stockChip} />
                ) : (
                  <Chip label="Out Of Stock" />
                )}

                <Button icon={<ShareIcon />} className={classes.shareBtn}>
                  Share
                </Button>
              </Container>
            </Container>
            <Seperator />
            <Container className={classes.detailsDiv}>
              <Container width={'146px'} display={'flex'} direction={'column'} gap={'5px'}>
                <Typography variant="body1" color={getColor('grey-g35')}>
                  Brand:{' '}
                </Typography>
                <Typography variant="body1" color={getColor('grey-g35')}>
                  Part Description:
                </Typography>
                <Typography variant="body1" color={getColor('grey-g35')}>
                  Part Number:
                </Typography>
                <Typography variant="body1" color={getColor('grey-g35')}>
                  Condition:
                </Typography>
                <Typography variant="body1" color={getColor('grey-g35')}>
                  Weight:
                </Typography>
              </Container>
              <Container width={'100%'} display={'flex'} direction={'column'} gap={'5px'}>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {searchDetails?.brand_name}{' '}
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {searchDetails?.part_desp}
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {searchDetails?.part_no ? `${searchDetails?.part_no}` : '-'}
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {searchDetails?.condition === 'new_oem' && 'New OEM'}{' '}
                  {searchDetails?.condition === 'new_aftermarket' && 'New Aftermarket'}{' '}
                  {searchDetails?.condition === 'used' && 'Used'}
                </Typography>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {searchDetails?.weight} lbs
                </Typography>
              </Container>
            </Container>
            <Seperator />
            <Container className={classes.aboutDiv}>
              <Typography variant="title" color="#13141B">
                About this product
              </Typography>
              <Typography variant="paragraph1" color={getColor('grey-g35')}>
                {searchDetails?.desp || '-'}
              </Typography>
            </Container>
            <Seperator />
            <Container className={classes.optionsDiv}>
              {searchDetails?.company_is_verified && (
                <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                  <VerifiedIcon />
                  <Typography variant="caption" color={getColor('grey-g35')}>
                    Verified suppliers
                  </Typography>
                </Container>
              )}

              <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                <ShippingIcon />
                <Typography variant="caption" color={getColor('grey-g35')}>
                  Fast shipping
                </Typography>
              </Container>

              {searchDetails?.is_returnable && (
                <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                  <ReturnsIcon />
                  <Typography variant="caption" color={getColor('grey-g35')}>
                    30-days return
                  </Typography>
                </Container>
              )}
              {searchDetails?.has_warranty && (
                <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                  <Warranty />
                  <Typography variant="caption" color={getColor('grey-g35')}>
                    365-days warranty
                  </Typography>
                </Container>
              )}
              {searchDetails?.is_cancellable && (
                <Container display="flex" direction="column" justify="center" alignItems="center" gap="10px">
                  <UntilShipped />
                  <Typography variant="caption" color={getColor('grey-g35')}>
                    Cancellable until shipped
                  </Typography>
                </Container>
              )}
            </Container>
            {fitsWithData?.length > 0 && (
              <>
                <Seperator />
                <Container className={classes.fitsDiv}>
                  <Container flex justify="space-between">
                    <TitleList title={'Fits with'} className={classes.titleList} />
                    <Button
                      variant="secondary"
                      className={classes.exapanBtn}
                      onClick={() => toggleDelivery(true)}
                      icon={<CaretDownIcon />}
                    />
                  </Container>
                  {delivery && (
                    <Container>
                      <ul className={classes.fitsUl}>
                        {fitsWithData?.map((obj, index) => (
                          <li key={index}>
                            <Typography variant="title1" color={getColor('grey-g25')}>
                              {obj?.brand_name}
                            </Typography>
                            <Container className={classes.fitsDetailDiv}>
                              {obj?.modelNumbers?.map((md, index) => (
                                <Container className={classes.modelRoot} key={index}>
                                  <Typography variant="body1" color={getColor('grey-g35')} component="h4">
                                    {md?.model_no}
                                  </Typography>

                                  <Typography variant="body1" color={getColor('grey-g35')} component="p">
                                    {md?.serial_rang_from && (
                                      <>
                                        {md?.serial_rang_from} - {md?.serial_rang_to}
                                      </>
                                    )}
                                  </Typography>
                                </Container>
                              ))}
                            </Container>
                          </li>
                        ))}
                      </ul>
                    </Container>
                  )}
                </Container>
              </>
            )}

            {crossRefData?.length > 0 && (
              <>
                <Seperator />
                <Container className={classes.fitsDiv}>
                  <Container flex justify="space-between">
                    <TitleList title={'Cross-References'} className={classes.titleList} />
                    <Button
                      variant="secondary"
                      className={classes.crossBtn}
                      onClick={() => toggleCrossRef(true)}
                      icon={<CaretDownIcon />}
                    />
                  </Container>
                  {crossRef && (
                    <Container>
                      {crossRefData?.map((obj, index) => (
                        <Container className={classes.fitsDetailDiv2} key={index}>
                          <Typography variant="body1" color={getColor('grey-g35')} component="h4">
                            {obj?.brand_name}
                          </Typography>

                          <Container className={classes.partRoot}>
                            {obj?.partnumber?.map((d, idx) => (
                              <>
                                <Typography key={index} variant="body1" color={getColor('grey-g35')}>
                                  {d?.value}
                                </Typography>
                                {idx !== obj?.partnumber?.length - 1 && <>,&nbsp;</>}
                              </>
                            ))}
                          </Container>
                        </Container>
                      ))}
                    </Container>
                  )}
                </Container>
              </>
            )}
          </Container>
          <Container className={classes.reportRoot}>
            <Container width={'268px'} className={classes.priceBox}>
              <Container display="flex" direction="column">
                {Number(searchDetails?.unit_price) !== 0 ? (
                  <>
                    <Typography className={classes.msrpTxt}>MSRP: {formatPrice(searchDetails?.msrp)}</Typography>
                    <Typography className={classes.mrpTxt}> {formatPrice(searchDetails?.unit_price)}</Typography>
                  </>
                ) : (
                  <Typography variant="paragraph1" color="#9799B5" component="p">
                    <Typography variant="paragraph1" color="#2680D9">
                      Get pricing
                    </Typography>{' '}
                    for this product
                  </Typography>
                )}
              </Container>
              {Number(searchDetails?.unit_price) !== 0 ? (
                <Typography variant="body2" color={getColor('grey-g65')}>
                  Save {formatPrice(searchDetails?.unit_price * Number(1 + 0.05) - searchDetails?.unit_price)} instantly
                  by upgrading to{' '}
                  <Link linkClassName={classes.link} to={'#'}>
                    PartsClub+
                  </Link>
                </Typography>
              ) : (
                ''
              )}

              {/* <Typography variant="body1" color={'#2D9613'}>
                Only {searchDetails?.qty} left in stock
              </Typography> */}
              {searchDetails?.qty > 0 && (
                <Typography variant="body1" color={'#2D9613'}>
                  Only {searchDetails?.qty} left in stock
                </Typography>
              )}
              {searchDetails?.available_in !== null && searchDetails?.available_in !== '0' && !searchDetails?.qty > 0 && (
                <Typography variant="body1" color={'#AD1F36'}>
                  Available in {searchDetails?.available_in} days
                </Typography>
              )}
              <Container flex justify="space-between">
                <Typography variant="body2" color={getColor('grey-g35')}>
                  Quantity:
                </Typography>
                <TwoWayController value={quantity} onChange={() => setQuantity(quantity + 1)} />
              </Container>
              {searchDetails?.enable_buy_now && <Button disabled={buyNowBtnDisabled}>Request Quote</Button>}
              {searchDetails?.enable_make_offer && (
                <Button variant="secondary" border>
                  Send Offer
                </Button>
              )}
              <Container display="flex" direction="column" width={'228px'} className={classes.soldDiv}>
                <Typography variant="body2" color={getColor('grey-g35')}>
                  Sold by:
                </Typography>
                <Container className={classes.soldLink}>
                  <Link linkClassName={classes.link} to={'#'}>
                    {searchDetails?.company_name}
                  </Link>
                  {searchDetails?.company_is_verified && <Verfied />}
                  <Verfied />
                  <Container>
                    <Typography variant="paragraph1" color={getColor('grey-g35')}>
                      {searchDetails?.company_city},{searchDetails?.company_country_abbrv}
                    </Typography>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container flex gap="0 2px" justify="flex-end">
              <Image src={reportIcon} />
              <Button className={classes.reportthiLink}>Report this listing</Button>
            </Container>
          </Container>
        </Container>
        {searchDetails?.related_prods?.length > 0 && (
          <>
            <Seperator />
            <Container className={classes.reletedProdDiv}>
              <TitleList title={'Related Products'} />
              <Container display="flex" gap="30px">
                {searchDetails?.related_prods?.map((obj, index) => (
                  <Container display="flex" direction="column" className={classes.reletedProd} key={index}>
                    <Container>
                      <Image
                        src={obj?.cover_image || SampleImage2}
                        width={'238px'}
                        height={'150px'}
                        className={classes.reletedProdImage}
                      />
                    </Container>
                    <Container display="flex" gap={'10px'} direction="column" className={classes.reletedProdDetails}>
                      <Container className={classes.smallDiv}>
                        <Typography variant="body1" color={getColor('grey-g25')}>
                          {obj?.product_title}
                        </Typography>
                        <Typography variant="paragraph1" color={getColor('grey-g35')}>
                          {obj?.product_condition === 'new_oem' && 'New OEM'}{' '}
                          {obj?.product_condition === 'new_aftermarket' && 'New Aftermarket'}{' '}
                          {obj?.product_condition === 'used' && 'Used'}
                        </Typography>
                      </Container>

                      <Typography variant="title1" color={getColor('grey-g25')}>
                        {formatPrice(obj?.product_unit_price)}
                      </Typography>
                    </Container>
                  </Container>
                ))}
              </Container>
            </Container>
          </>
        )}
      </Container>
    </Dialog>
  )
}
PreviewListingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  searchDetails: PropTypes.object,
}
