import Container from 'components/common/Container'
import Dialog from 'components/custom/dialog/Dialog'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import useToggle from 'common-hooks/useToggle'
import { FacebookColorIcon, LinkCopyIcon, LinkedInColorIcon, MailIcon } from 'assets/icons'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import Seperator from 'components/common/Seperator'
import IconButton from 'components/common/IconButton'
import EmailDiaglog from 'containers/inventory/EmailDiaglog'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  emailField: {
    border: ' 1px solid #F0F0F4',
    borderRadius: ' 4px',
    padding: '15px',
    display: 'flex',
    gap: '0 10px',
    cursor: 'pointer',
  },
  socialMediaBox: {
    width: '197px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    border: ' 1px solid #F0F0F4',
    borderRadius: ' 4px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  IconButton: {
    background: 'unset',
    width: 'unset',
    height: 'unset',
    padding: '0',
    '&:hover': {
      background: 'unset',
    },
  },
  linkCopy: {
    border: ' 1px solid #C3C5D5',
    borderRadius: '2px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default function ShareProductDialog({ open, onClose }) {
  const classes = useStyles()

  const [emailDialog, setEmailDialog] = useToggle(false)

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Share Product'} onClose={onClose} />
          <Container className={classes.selectField}>
            <Container
              className={classes.emailField}
              onClick={() => {
                setEmailDialog()
                onClose()
              }}
            >
              <MailIcon />
              <Container>
                <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                  Email
                </Typography>
                <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                  Add subject line and message.
                </Typography>
              </Container>
            </Container>

            <Container flex gap={'0 10px'} width={'100%'}>
              <Seperator />
              <Typography variant="overline" color={getColor('grey-g65')}>
                Or
              </Typography>
              <Seperator />
            </Container>
            <Container flex gap={'0 15px'}>
              <Container className={classes.socialMediaBox}>
                <LinkedInColorIcon />
                <Typography>LinkedIn</Typography>
              </Container>
              <Container className={classes.socialMediaBox}>
                <FacebookColorIcon />
                <Typography>Facebook</Typography>
              </Container>
            </Container>
            <Container className={classes.linkCopy}>
              <Typography variant="paragraph1" color={'grey-g25'}>
                www.partsclub.us/product_id
              </Typography>
              <IconButton className={classes.IconButton}>
                <LinkCopyIcon />
              </IconButton>
            </Container>
          </Container>
        </Dialog>
      </Container>
      <EmailDiaglog open={emailDialog} onClose={setEmailDialog} />
    </>
  )
}
ShareProductDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
