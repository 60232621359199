import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Dialog from 'components/custom/dialog/Dialog'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import DialogHead from 'components/custom/dialog/DialogHead'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Typography from 'components/common/Typography'
import { getColor } from 'theme/colors'
import useOrders from 'common-hooks/orders/useOrders'

const useStyles = makeStyles({
  dialogbody: {
    padding: '20px',
  },
  selectField: {
    padding: '20px 30px 30px 30px',
    display: 'flex',
    gap: '18px 0',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
})

export default function ConfirmationDialog({ open, onClose, mutate, activeId }) {
  const classes = useStyles()
  const { handleSubmit } = useForm()
  //   const options = countryOption?.map((item) => createOption(item, item))

  const { orderPickupShip } = useOrders()

  const onSubmit = () => {
    if (activeId) {
      orderPickupShip(activeId)
    } else {
      orderPickupShip()
      mutate()
    }

    onClose()
  }

  return (
    <>
      <Container>
        <Dialog open={open} width={'450px'}>
          <DialogHead title={'Confirmation'} onClose={onClose} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.selectField}>
              <Typography variant="body1" color={getColor('grey-g35')}>
                Are you sure order is ready for pickup? Once you change order status you won’t be able to change it
                again.
              </Typography>
            </Container>
            <DialogFooter justify={'flex-end'}>
              <Container flex gap={'0 10px'}>
                <Button variant="secondary" border width={77} onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">Ready For Pickup</Button>
              </Container>
            </DialogFooter>
          </form>
        </Dialog>
      </Container>
    </>
  )
}
ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  activeId: PropTypes.string,
  mutate: PropTypes.func,
}
