import React from 'react'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import classNames from 'classnames'
import { InfoSmallIcon } from 'assets/icons'
import Container from 'components/common/Container'
import Tooltip from 'components/common/Tooltip'
import Image from 'components/common/Image'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  root: (props) => ({
    height: props.height || '40px',
    padding: props.padding || '10px 20px',
    width: props.width,
  }),
  linkType: {
    cursor: 'pointer',
    // color: '#36384c',
  },
  img: {
    height: '30px',
    width: '30px',
    border: `1px solid ${getColor('grey-g95')}`,
  },
}))

export default function List({
  className,
  title,
  tooltip,
  icon,
  subtitle,
  tooltipMaxWidth,
  height,
  type,
  onClick,
  padding,
  img,
  txtColor,
  width,
}) {
  const classes = useStyles({ height, padding, width })
  return (
    <Container flex direction="column" className={classNames(classes.root, className)} onClick={onClick}>
      <Container flex justify="flex-start" width="100%" gap={'10px'}>
        {icon && icon}
        {img && <Image src={img} className={classes.img} />}
        {type === 'link' && (
          <Typography variant="body1" className={classes.linkType}>
            {title}
          </Typography>
        )}
        {!type && (
          <Typography variant="body1" color={txtColor}>
            {title}
          </Typography>
        )}
        {tooltip && (
          <Tooltip placement="right" maxWidth={tooltipMaxWidth} title={tooltip}>
            <InfoSmallIcon className={classes.icon} />
          </Tooltip>
        )}
      </Container>
      {subtitle && <Typography variant="paragraph1">{subtitle}</Typography>}
    </Container>
  )
}

List.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipMaxWidth: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  img: PropTypes.string,
  padding: PropTypes.string,
  txtColor: PropTypes.string,
  width: PropTypes.string,
}
