import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import ImagePlaceholder from 'assets/images/no-image-placeholder.svg'
import { useEffect, useState } from 'react'
import classNames from 'classnames'

const useStyles = makeStyles({
  cover: {
    objectFit: (props) => (props.contain ? 'contain' : 'cover'),
  },
  chip: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
  },
})

export default function Image({
  src,
  alt,
  width,
  height,
  className,
  onClick,
  spacing,
  loading,
  contain,
  imageClassName,
  imageBadge,
  ...props
}) {
  const classes = useStyles({ contain })
  const [source, setSource] = useState(src || ImagePlaceholder)

  useEffect(() => {
    if (src) {
      setSource(src)
    }
  }, [src])

  return (
    <>
      <img
        src={source}
        alt={alt}
        width={width}
        height={height}
        className={classNames(classes.cover, className)}
        onClick={onClick}
        aria-hidden="true"
        {...props}
      />
    </>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  spacing: PropTypes.number,
  loading: PropTypes.bool,
  contain: PropTypes.bool,
  imageBadge: PropTypes.object,
  imageClassName: PropTypes.string,
}
