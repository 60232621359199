import { getCompanyCarriers } from 'common-api/settings/settings'
import useSWR from 'swr'

export default function useGetCompanyCarriers(shipo) {
  const limit = 100
  const page = 1
  const { data, mutate } = useSWR(shipo ? `/company/carriers/${page}/${limit}` : null, getCompanyCarriers)

  return {
    companyCarriersList: data,
    isLoading: !data,
    mutate,
  }
}
