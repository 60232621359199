import PropTypes from 'prop-types'
import DialogHead from 'components/custom/dialog/DialogHead'
import Dialog from 'components/custom/dialog/Dialog'
import Container from 'components/common/Container'
import Button from 'components/common/Button'
// import Select from 'components/common/select/Select'
import useBrandList from 'common-hooks/create-quote/useBrandList'
import DialogFooter from 'components/custom/dialog/DialogFooter'
import { makeStyles } from '@mui/styles'
import SelectCreateable from 'components/common/select/SelectCreateable'
import Seperator from 'components/common/Seperator'
import { useState, React, useEffect } from 'react'
import IconButton from 'components/common/IconButton'
import { DeleteIcon } from 'assets/icons'
import { createOption } from 'components/common/text/DropDownTextField'
import useAddProduct from 'common-hooks/inventory/useAddProduct'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles({
  body: {
    padding: '20px',
  },
  brandMain: {
    // '& .brandRoot:first-child': {
    //   paddingTop: '20px',
    // },
  },
  brandRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    gap: '20px 0',

    position: 'relative',
  },
  IconButton: {
    backgroundColor: 'unset',
    position: 'absolute',
    right: '0',
    marginTop: '23px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  brdBtn: {
    // paddingTop: '20px',
  },
  brandBtn: {
    backgroundColor: 'unset',
    color: '#2680D9!important',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& span': {
      color: '#2680D9!important',
    },
  },
})

export function CrossRefNumberDialog({
  open,
  onClose,
  setCrossRefNumbers,
  editCrossRefNumbers,
  setEditCrossRefNumber,
  mutate,
  setflag,
}) {
  const classes = useStyles()
  const param = useParams()
  const { brandList } = useBrandList()
  const { deleteCrossRefNumbers } = useAddProduct()
  const brandOption = brandList?.map((obj) => ({ value: obj.id, label: obj.name }))

  const [brandData, setBrandData] = useState([
    {
      brand_name: '',
      partnumber: '',
    },
  ])
  const [disableBtn, setDisableBtn] = useState(true)

  const partnumberOption = [
    {
      label: '',
      value: '',
    },
  ]

  const handleOnBrand = () => {
    setBrandData([
      ...brandData,
      {
        brand_name: '',
        partnumber: '',
      },
    ])
  }

  const handleOnChange = (e, i, name) => {
    const changedata = [...brandData]
    changedata[i][name] = e
    setBrandData(changedata)
  }

  const handleOnRemove = (i) => {
    const removedata = [...brandData]
    removedata.splice(i, 1)
    setBrandData(removedata)
    if (!editCrossRefNumbers?.id && i === 0 && brandData?.length === 1) {
      handleOnClose()
    }
    if (editCrossRefNumbers?.id) {
      deleteCrossRefNumbers(param.id, editCrossRefNumbers?.brand_id, editCrossRefNumbers?.id, mutate)
      handleOnClose()
    }
  }
  const handleOnClose = () => {
    setEditCrossRefNumber(null)
    setBrandData([
      {
        brand_name: '',
        partnumber: '',
      },
    ])
    onClose()
  }

  useEffect(() => {
    if (editCrossRefNumbers) {
      Object.keys(editCrossRefNumbers).map((key) => {
        if (['brand_name', 'partnumber'].includes(key)) {
          setBrandData([
            {
              brand_name:
                typeof editCrossRefNumbers?.brand_name === 'object'
                  ? editCrossRefNumbers?.brand_name
                  : createOption(editCrossRefNumbers?.brand_name, editCrossRefNumbers?.brand_name),
              partnumber: editCrossRefNumbers?.partnumber,
              ...(editCrossRefNumbers?.brand_id && { brand_id: editCrossRefNumbers?.brand_id }),
            },
          ])
        }
        return 0
      })
    }
  }, [editCrossRefNumbers])

  useEffect(() => {
    brandData.forEach((obj) => {
      if (obj.brand_name !== '' && obj.partnumber !== '' && obj.partnumber?.length > 0) {
        setDisableBtn(false)
      } else {
        setDisableBtn(true)
      }
    })
  }, [brandData])

  return (
    <Container>
      <Dialog open={open} width={'450px'}>
        <DialogHead title={'Cross-References'} onClose={handleOnClose} />
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Container className={classes.body}>
          <Container className={classes.brandMain}>
            {brandData?.map((data, index) => (
              <Container className={classes.brandRoot} key={index}>
                <SelectCreateable
                  options={brandOption}
                  id={'brand'}
                  placeholder={'Select'}
                  width={'360px'}
                  value={data?.brand_name}
                  disabled={editCrossRefNumbers}
                  name={'brand'}
                  onChange={(e) => handleOnChange(e, index, 'brand_name')}
                  label={'Brand*'}
                  menuPosition={'fixed'}
                />

                <SelectCreateable
                  isMulti
                  id={'partnumber'}
                  name={'partnumber'}
                  value={data?.partnumber}
                  options={partnumberOption}
                  onChange={(e) => handleOnChange(e, index, 'partnumber')}
                  menuPosition={'fixed'}
                  label={'Part Numbers*'}
                  placeholder={'e.g. AT7970125, 29/803790'}
                  setRemoveCrossReffNumb={() => {
                    if (editCrossRefNumbers?.id) {
                      deleteCrossRefNumbers(param.id, null, editCrossRefNumbers?.id, mutate)
                    }
                  }}
                />
                {brandData && brandData?.length >= 2 && (
                  <IconButton className={classes.IconButton} onClick={() => handleOnRemove(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
                {editCrossRefNumbers && (
                  <IconButton className={classes.IconButton} onClick={() => handleOnRemove(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
                <Seperator />
              </Container>
            ))}
            {editCrossRefNumbers ? (
              ''
            ) : (
              <Container className={classes.brdBtn}>
                <Button
                  disabled={disableBtn}
                  className={classes.brandBtn}
                  width={'85px'}
                  onClick={() => handleOnBrand()}
                >
                  + Brand
                </Button>
              </Container>
            )}
          </Container>
        </Container>
        <DialogFooter justify={'flex-end'}>
          <Container flex gap={'0 10px'}>
            <Button variant="secondary" border width={77} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={disableBtn}
              onClick={() => {
                setCrossRefNumbers((pre) => {
                  if (editCrossRefNumbers?.editIndex >= 0 && editCrossRefNumbers) {
                    const newArray = pre.filter((obj, index) => index !== editCrossRefNumbers?.editIndex)
                    return [...newArray, ...brandData]
                  }

                  return [...pre, ...brandData]
                })
                setBrandData([
                  {
                    brand_name: '',
                    partnumber: '',
                  },
                ])
                setflag(false)
                handleOnClose()
              }}
            >
              {editCrossRefNumbers ? 'Update' : 'Add'}
            </Button>
          </Container>
        </DialogFooter>
        {/* </form> */}
      </Dialog>
    </Container>
  )
}

CrossRefNumberDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setCrossRefNumbers: PropTypes.func,
  editCrossRefNumbers: PropTypes.object,
  setEditCrossRefNumber: PropTypes.func,
  mutate: PropTypes.func,
  setflag: PropTypes.func,
}
