import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Typography from 'components/common/Typography'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { getColor } from 'theme/colors'
import BuyerOrderAwaitingCard from './BuyerOrderAwaitingCard'
import { parseOrderData } from '../OrdersUtils'

const useStyles = makeStyles(() => ({
  awainglistBody: {
    maxWidth: '800px',
    margin: '0 auto',
    // position: 'relative',
    // height: '80vh',
    // overflowY: 'scroll',
    // overflowX: 'hidden',
    // margin: '0 auto',
    // '&::-webkit-scrollbar': {
    //   width: '6px',
    //   paddingtop: '50px',
    //   backgroundColor: '#F0F0F4',
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   width: '4px',
    //   borderRadius: ' 100px',
    //   background: '#C3C5D5',
    // },
  },
  dateClass: {
    marginBottom: '17px',
  },
}))

export default function BuyerOrderAwaitingList({ orderData }) {
  const rows = parseOrderData(orderData, 'ordered_date')
  const keys = Object.keys(rows)
  const classes = useStyles()
  return (
    <Container>
      <Container className={classes.awainglistBody}>
        {/* <Typography variant="title" color={getColor('grey-g25')}>
          mm/dd/yyyy
        </Typography> */}
        <Container className={classes.awaingcardList}>
          {keys?.map((key, index) => (
            <Fragment key={index}>
              <Container className={classes.dateClass}>
                <Typography variant="title" color={getColor('grey-g25')}>
                  {key.replace(',', '').toUpperCase()}
                </Typography>
              </Container>

              {rows[key].map((obj, index) => (
                <BuyerOrderAwaitingCard
                  key={index}
                  id={obj.id}
                  partName={obj.part_name}
                  brandName={obj.brand_name}
                  brandImg={obj.brand_cover_image}
                  equpImg={obj.equip_cover_image}
                  partNumber={obj.part_number}
                  poNumber={obj.po_number}
                  orderId={obj.order_id}
                  suppliername={obj.seller_full_name}
                  city={obj.city}
                  state={obj.state}
                  amount={obj.amount}
                  delivery={obj.delivery_options}
                  quantity={obj.qty}
                  country={obj?.country}
                />
              ))}
            </Fragment>
          ))}
          {/* {orderData?.map((data, index) => (
            <BuyerOrderAwaitingCard
              key={index}
              id={data.id}
              partName={data.part_name}
              brandName={data.brand_name}
              brandImg={data.brand_cover_image}
              equpImg={data.equip_cover_image}
              partNumber={data.part_number}
              orderId={data.order_id}
              suppliername={data.seller_full_name}
              city={data.city}
              state={data.state}
              amount={data.amount}
              delivery={data.delivery_options}
              quantity={data.qty}
            />
          ))} */}
        </Container>
      </Container>
    </Container>
  )
}
BuyerOrderAwaitingList.propTypes = {
  orderData: PropTypes.array,
}
