import { getProfileSettingData } from 'common-api/settings/settings'
import useSWR from 'swr'

export default function useProfileSettingDetail() {
  const { data, mutate } = useSWR(`/user-detail`, getProfileSettingData)

  return {
    profileData: data,
    isLoading: !data,
    mutate,
  }
}
