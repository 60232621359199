import { makeStyles } from '@mui/styles'
import useCompanySettings from 'common-hooks/settings/company/useCompanySettings'
import PropTypes from 'prop-types'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import TextField from 'components/common/text/TextField'
import Typography from 'components/common/Typography'
import React, { useState } from 'react'
import { getColor } from 'theme/colors'

const useStyles = makeStyles(() => ({
  Step: {
    width: '40px',
    height: '40px',
    background: '#FFEABF',
    border: ' 1px solid #FFEABF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '180px',
    '& p': {
      fontFamily: "'Lexend Deca'",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: ' #F4892A',
    },
  },
  gotoshippoBtn: {
    width: '121.42px',
    height: '36px',
    border: '1px solid #9799B5',
    color: '#4A4D68',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  courierCard: {
    border: '1px solid #F0F0F4',
    padding: '20px',
    // borderRadius: '4px',
  },
  courierCardInner: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0 10px',
  },
  courierCardright: {
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      marginTop: '20px',
    },
  },
  courierGridRoot: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  typoGap: {
    display: 'flex',
    gap: '5px 0',
    flexDirection: 'column',
  },
  cnctBtn: {
    width: '146px',
    height: '36px',
    border: '1px solid #9799B5',
    color: '#4A4D68',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textfieldRoot: {
    display: 'flex',
    gap: '0 20px',
    width: '100%',
    alignItems: 'flex-end',
  },
  linkcolor: {
    color: '#7D809B',
    textDecoration: 'underline',
  },
}))

export default function CourierStep({ setShipo }) {
  const classes = useStyles()
  const [shippoKey, setShippoKey] = useState()
  const [error, setError] = useState(false)

  const { shippoAccount } = useCompanySettings()

  const handleShippo = () => {
    if (shippoKey) {
      // if (shippoKey?.length !== 52) {
      //   return toastMessage('error', 'Please enter valid shippo key')
      // }
      shippoAccount({ shippo_api_key: shippoKey }, setShipo)
      setError(false)
    } else {
      setError(true)
    }
    setShippoKey('')
    return null
  }

  return (
    <Container>
      <Container className={classes.courierRoot}>
        <Typography variant="paragraph1" color={getColor('grey-g65')}>
          Follow this below steps to connect your Shippo account.
        </Typography>
        <Container className={classes.courierGridRoot}>
          <Container className={classes.courierCard}>
            <Container className={classes.courierCardInner}>
              <Container className={classes.Step}>
                <Typography component="p">1</Typography>
              </Container>
              <Container className={classes.courierCardright}>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {/* Open/Create Free Shippo Account */}
                  Open your Shippo account
                </Typography>
                <Typography variant="body2" color={getColor('grey-g65')}>
                  {/* Login to your Shippo account in order to get API key token, Or If you don’t have any shippo account
                  create an account first and create an API key token */}
                  {/* Login to your Shippo account to get started */}
                  Login/create your Shippo account to get API key token.
                </Typography>
                <a
                  href="https://goshippo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.gotoshippoBtn}
                >
                  Go To Shippo
                </a>
              </Container>
            </Container>
          </Container>
          <Container className={classes.courierCard}>
            <Container className={classes.courierCardInner}>
              <Container className={classes.Step}>
                <Typography component="p">2</Typography>
              </Container>
              <Container className={classes.courierCardright}>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {/* Open/Create Free Shippo Account */}
                  Connect your courier account
                </Typography>
                <Container className={classes.typoGap}>
                  <Typography variant="body2" color={getColor('grey-g65')}>
                    Follow below steps to connect your courier accounts into Shippo. Skip this step if you already did
                    it.
                  </Typography>
                  <Typography variant="body2" color={getColor('grey-g65')}>
                    {'Steps: Settings > Integrations > Carriers > Connect Carrier Account'}
                  </Typography>
                </Container>
              </Container>
            </Container>
          </Container>

          <Container className={classes.courierCard}>
            <Container className={classes.courierCardInner}>
              <Container className={classes.Step}>
                <Typography component="p">3</Typography>
              </Container>
              <Container className={classes.courierCardright}>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  Get/create API key token
                </Typography>
                <Container className={classes.typoGap}>
                  <Typography variant="body2" color={getColor('grey-g65')}>
                    {/* Once you login into your shippo account, Follow below path to get/create an API key token. If you’ve
                    already created just simply past it below. */}
                    Follow below steps to get/create an API key token. If you’ve already created past it in step 3.
                  </Typography>

                  <Typography variant="body2" color={getColor('grey-g65')}>
                    {'Steps: Settings > Integrations > API > Token > Live Token > Generate Token > Copy API'}
                  </Typography>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container className={classes.courierCard}>
            <Container className={classes.courierCardInner}>
              <Container className={classes.Step}>
                <Typography component="p">4</Typography>
              </Container>
              <Container className={classes.courierCardright}>
                <Typography variant="body1" color={getColor('grey-g25')}>
                  {/* Paste API key token */}
                  Insert API key token
                </Typography>
                <Container className={classes.typoGap} gap="10px 0">
                  <Typography variant="body2" color={getColor('grey-g65')}>
                    {/* Once you generated your API key token past it below to connect courier service from your shippo
                    account. */}
                    Paste your API Key token below to connect your Shippo accounts.
                  </Typography>

                  <Container className={classes.textfieldRoot}>
                    <TextField
                      label="API Token Key"
                      placeholder="Type"
                      width={'443px'}
                      value={shippoKey}
                      onChange={(e) => {
                        // setError(false)
                        setShippoKey(e.target.value)
                      }}
                      error={error ? 'Token Key Needed' : ''}
                    />
                    <Button className={classes.cnctBtn} onClick={handleShippo}>
                      {' '}
                      Connect Account
                    </Button>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          {/* <Typography variant="paragraph1" color={'#7D809B'}>
            Require any assistance with Shippo? We’re happy to help. Contact us at{' '}
            <a className={classes.linkcolor} href="mailto:support@partsclub.us">
              support@partsclub.us
            </a>{' '}
          </Typography> */}
        </Container>
      </Container>
    </Container>
  )
}

CourierStep.propTypes = {
  setShipo: PropTypes.func,
}

// export default CourierStep
