import heic2any from 'heic2any'

export const FILE_SIZE_PRIMARY = 15000000

// export const IMAGE_MIME = ['image/*', 'image/heic', 'image/heif']
export const IMAGE_MIME = {
  'image/jpeg': ['.jpeg', '.jpg', '.png'],
  'image/png': ['.jpeg', '.jpg', '.png'],
}
export const VIDEO_MIME = ['video/*']
export const PDF_MIME = { 'application/pdf': ['.pdf'] }
export const CSV_MINE = {
  'application/csv': ['.csv'],
  // 'application/xlsx': ['.xlsx'],
}

export function filesAddKeys(files) {
  return files?.map((file, index) => {
    // if need to include files from Api
    if (file.details) {
      return {
        details: file.details,
        id: `${file.details.url}-${index}`,
      }
    }
    return {
      file,
      id: `${file.lastModified}-${index}`,
    }
  })
}

export function filesCreateIds(files) {
  return files?.map((file, index) => {
    if (!file.id) {
      return {
        file,
        id: `temp-${file.lastModified}-${index}`,
      }
    }
    return file
  })
}

export function filesRemoveKeys(files) {
  return files?.map((file) => {
    // if need to handle fines from Api
    if (file.details) {
      return { details: file.details }
    }
    return file.file
  })
}

export function rejectMaxHeight(file) {
  return {
    file,
    errors: [{ code: 'max-height' }],
  }
}

export function checkImageDimensions(file) {
  return new Promise((resolve) => {
    const i = new Image()

    i.onload = () => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve({
          width: i.width,
          height: i.height,
        })
      }
    }
    i.src = URL.createObjectURL(file)
  })
}

export function convertHeic(file) {
  return new Promise((resolve) => {
    heic2any({ blob: file, toType: 'image/jpeg', quality: 0.5 })
      .then((converted) => {
        resolve(converted)
      })
      .catch((e) => {
        const fi = new File([file], file.name.replace('.heic', '.jpg'), {
          type: 'image/jpeg',
        })
        fi.path = fi.name
        resolve(fi)
        console.log('error', e)
      })
  })
}

export function isVideo(file) {
  if (file.file_type) {
    return file.file_type.includes('video')
  }
  return file?.type?.includes('video')
}

export function isPdf(file) {
  return file.type.includes('pdf')
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

// Below function check for null, undefined, and empty array as well as empty object.
export function isEmpty(data) {
  if (
    !data ||
    Object.keys(data).length === 0 ||
    data.length === 0 ||
    typeof data === 'undefined' ||
    data === undefined ||
    data === ''
  )
    return true
  return false
}
export function removeBrackets(data) {
  return data?.toString()?.replace(/[[\]'"]+/g, '')
}
