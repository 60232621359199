import { getPartDescList } from 'common-api/inventory/inventory'
import useSwrInfinite from 'common-hooks/swr/useSwrInfinite'
import { useState } from 'react'

export default function useProductPartDescList(id) {
  const [search, onSearch] = useState('')

  const params = {
    ...(id && { brand_id: id }),
    ...(search && { search }),
  }

  const { data } = useSwrInfinite('/product/list/part-desc', params, getPartDescList)

  return {
    partDescList: data,
    onSearch,
    search,
    isLoading: !data,
  }
}
