import { makeStyles } from '@mui/styles'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import IconButton from 'components/common/IconButton'
// import Logo from 'assets/icons/Logo50x50.svg'
// import updateLogo from 'assets/images/updateLogo.png'
import LandingSmallLogo from 'assets/images/LandingSmallLogo.svg'
import './css/landingpage.css'
import { getColor } from 'theme/colors'
import Link from 'components/common/Link'
import Typography from 'components/common/Typography'
import LoginDialog from 'containers/authentication/LoginDialog'
import useToggle from 'common-hooks/useToggle'
import { CloseIcon, HamburgerIcon, LandingPageLogoBig } from 'assets/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  separator: {
    margin: theme.spacing(0, 3),
  },
  root: {
    alignItems: 'center',
    padding: 0,
    height: '80px',
  },
  button: {
    padding: 'unset',
    '&:hover': { backgroundColor: 'unset' },
  },
  logo: {
    height: '65px',
    width: '65px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  badgeicon: {
    '&>:nth-child(2)': {
      position: 'absolute',
      fontSize: '12px',
      top: '10px',
      left: '7px',
      minWidth: 'unset',
      width: '18px',
      height: '18px',
      color: getColor('light-main'),
      backgroundColor: getColor('orange-main'),
      border: '1.08333px solid #FFFFFF;',
    },
  },
}))

export default function LandingPageHeader() {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const [menu, setMenu] = useToggle(false)
  const navigate = useNavigate()
  // const handleOnMenu = () => {
  //   setMenu(true)
  // }
  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split('/')
  return (
    <>
      <Container className={'header-root'} justify={'space-between'} width={'100%'}>
        <Container className="page-logo">
          <IconButton className={classes.logo} variant="transparent" size="lg" onClick={() => navigate('/home')}>
            {/* <img src={updateLogo} alt="drawer logo" className="desktop-img" width={'55px'} /> */}
            <LandingPageLogoBig className="desktop-img" />
            <img src={LandingSmallLogo} alt="drawer logo" className="mobile-img" />
          </IconButton>
        </Container>
        <Container flex gap={'0 20px'} className="nav-menu-main">
          <Container flex justify="flex-start" gap="20px">
            <Container flex justify="flex-start" gap="20px" className={menu ? 'nav-menu-open' : 'nav-menu'}>
              <Link to={'/home'}>
                <Typography
                  color={splitLocation[1] === 'home' ? getColor('orange-main') : getColor('grey-g25')}
                  variant="body1"
                >
                  Home
                </Typography>
              </Link>
              <Link to={'/parts'}>
                <Typography
                  color={splitLocation[1] === 'parts' ? getColor('orange-main') : getColor('grey-g25')}
                  variant="body1"
                >
                  Parts
                </Typography>
              </Link>
              <Link to={'/quoterequest'}>
                <Typography
                  color={splitLocation[1] === 'quoterequest' ? getColor('orange-main') : getColor('grey-g25')}
                  variant="body1"
                >
                  Requests
                </Typography>
              </Link>

              <Link to={'/supplier'}>
                <Typography
                  color={splitLocation[1] === 'supplier' ? getColor('orange-main') : getColor('grey-g25')}
                  variant="body1"
                >
                  Suppliers
                </Typography>
              </Link>
              <Link to={'/buying'}>
                <Typography
                  color={splitLocation[1] === 'buying' ? getColor('orange-main') : getColor('grey-g25')}
                  variant="body1"
                >
                  Buying
                </Typography>
              </Link>

              <Link to={'/selling'}>
                <Typography
                  color={splitLocation[1] === 'selling' ? getColor('orange-main') : getColor('grey-g25')}
                  variant="body1"
                >
                  Selling
                </Typography>
              </Link>
              <Link to={'/about'}>
                <Typography
                  color={splitLocation[1] === 'about' ? getColor('orange-main') : getColor('grey-g25')}
                  variant="body1"
                >
                  About Us
                </Typography>
              </Link>
              <Container flex justify="flex-start" gap="20px">
                <Button onClick={setOpenDialog}>Login/Sign Up</Button>
              </Container>
            </Container>
          </Container>
        </Container>
        {/* <Button className={'burger'} onClick={setMenu}>
          Menu
        </Button> */}
        <IconButton className={'burger'} onClick={setMenu}>
          {menu ? <CloseIcon /> : <HamburgerIcon />}
        </IconButton>
      </Container>
      <LoginDialog open={openDialog} onClose={setOpenDialog} />
    </>
  )
}
