import PropTypes from 'prop-types'
import { forwardRef, useState } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'
import { Grid, IconButton } from '@mui/material'
import { getColor } from 'theme/colors'
import { ClearIcon, CloseEyeIcon, ErrorBlackIcon, ErrorRedIcon, EyeIcon } from 'assets/icons'
import { getHeight } from 'utils/styles/control'
import typography from 'theme/typography'
import Label from '../Label'
import Tooltip from '../Tooltip'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: (props) => theme.spacing(props.spacing),
    width: (props) => props.width,
    position: 'relative',
    display: (props) => (props.type === 'hidden' ? 'none' : 'block'),
  },
  container: {
    position: 'relative',
    display: 'flex',
  },
  input: {
    width: '100%',
    height: (props) => getHeight(props.size),
    padding: (props) => (props.rightIcon ? theme.spacing(0, 8, 0, 2.5) : theme.spacing(0, 2.5)),
    backgroundColor: getColor('system-bg'),
    borderRadius: (props) => (props.leftBadge ? '0 4px 4px 0' : 2),
    fontFamily: 'Lexend Deca',
    borderWidth: 1,
    color: getColor('grey-g25'),
    border: 1,
    borderStyle: 'solid',
    borderColor: (props) => (props.error ? getColor('func-negative') : getColor('grey-g80')),
    fontSize: '0.875rem',
    fontWeight: 300,
    boxSizing: 'border-box',
    outline: 'none',

    '&:disabled': {
      color: getColor('grey-g80'),
      '&:hover': {
        borderColor: getColor('grey-g80'),
      },
    },
    '&::placeholder': {
      color: (props) => (props.disabled ? getColor('grey-g80') : getColor('grey-g65')),
      fontFamily: 'Lexend Deca',
    },

    '&:active ': {
      borderColor: (props) => {
        if (props.readOnly) {
          return getColor('grey-g85')
        }
        if (props.error) {
          return getColor('func-negative')
        }
        return getColor('func-info')
      },
    },
    '&:focus': {
      borderColor: (props) => {
        if (props.readOnly) {
          return getColor('grey-g85')
        }
        if (props.error) {
          return getColor('func-negative')
        }
        return getColor('func-info')
      },
    },
    '&:hover': {
      borderColor: getColor('grey-g35'),
    },
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    height: '100%',
    right: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    opacity: (props) => (props.disabled ? 0.25 : 1),
  },
  badgeContainer: {
    height: (props) => getHeight(props.size),
  },
  errorIcon: {
    position: 'absolute',
    right: '0',
    zIndex: '111',
  },
  labelTypography: {
    fontSize: typography.paragraph2.fontSize,
    fontWeight: typography.paragraph2.fontWeight,
  },
  errorIcon2: {
    position: 'absolute',
    right: 0,
    top: '-17px ',
  },
  errorIcon3: {
    position: 'absolute',
    right: 0,
    top: '0 ',
  },
}))

// TODO remove label split props, use only LabelProps
const TextField = forwardRef(
  (
    {
      id,
      placeholder,
      type = 'text',
      size = 'sm',
      spacing = 0,
      label,
      labelSpacing = 0,
      width,
      rightIcon,
      onClear,
      value,
      defaultValue,
      labelVariant = 'paragraph2',
      labelTooltip,
      toolTipinfo,
      error,
      onChange,
      onBlur,
      name,
      readOnly,
      clearable,
      LabelProps = {
        textTransform: 'none',
      },
      inputClassName,
      className,
      rootRef,
      leftBadge,
      disabled,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      spacing,
      size,
      width,
      readOnly,
      error,
      disabled,
      rightIcon: !!rightIcon,
      leftBadge: !!leftBadge,
      type,
    })
    const [showPassword, toggleShowPassword] = useState(false)
    return (
      <Grid container direction="column" className={classNames(classes.root, className)} ref={rootRef}>
        {label && (
          <Label
            className={classes.labelTypography}
            spacing={labelSpacing}
            variant={labelVariant}
            tooltip={labelTooltip}
            {...LabelProps}
          >
            {label}
          </Label>
        )}
        {error && (
          <div className={label ? classes.errorIcon : classes.errorIcon2}>
            <Tooltip title={error} placement={'right-start'}>
              <ErrorRedIcon />
            </Tooltip>
          </div>
        )}
        {toolTipinfo && (
          <div className={label ? classes.errorIcon3 : classes.errorIcon3}>
            <Tooltip title={toolTipinfo} placement={'right-start'}>
              <ErrorBlackIcon />
            </Tooltip>
          </div>
        )}
        <div className={classes.container}>
          {!!leftBadge && <div className={classes.badgeContainer}>{leftBadge}</div>}
          <input
            id={id}
            name={name}
            placeholder={placeholder}
            type={showPassword ? 'text' : type}
            className={classNames(classes.input, inputClassName)}
            value={value || defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readOnly}
            disabled={disabled}
            ref={ref}
            onWheel={(e) => e.target.blur()}
            {...props}
          />
          {type === 'password' && (
            <div className={classes.iconContainer}>
              <IconButton variant="transparent" onClick={() => toggleShowPassword((prev) => !prev)}>
                {showPassword !== true ? <CloseEyeIcon /> : <EyeIcon />}
              </IconButton>
            </div>
          )}
          {clearable && value && <ClearIcon className={classes.iconContainer} onClick={onClear} />}
          {rightIcon && <div className={classes.iconContainer}>{rightIcon}</div>}
        </div>
      </Grid>
    )
  }
)

TextField.displayName = 'TextField'

TextField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'hidden']),
  placeholder: PropTypes.string,
  spacing: PropTypes.number,
  size: PropTypes.oneOf(['sm', 'md']),
  label: PropTypes.string,
  labelSpacing: PropTypes.number,
  width: PropTypes.number,
  rightIcon: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  labelVariant: PropTypes.oneOf(['paragraph1', 'paragraph2']),
  labelTooltip: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  LabelProps: PropTypes.object,
  clearable: PropTypes.bool,
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  rootRef: PropTypes.object,
  leftBadge: PropTypes.node,
  disabled: PropTypes.bool,
  toolTipinfo: PropTypes.any,
  defaultValue: PropTypes.string,
}

export default TextField
