import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import ImagePlaceholder from 'assets/images/ImagePlaceholder.png'
import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import LoadingOverlay from 'components/common/LoadingOverlay'

const useStyles = makeStyles({
  cover: {
    objectFit: (props) => (props.contain ? 'contain' : 'cover'),
  },
  chip: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
  },
})

// TODO Add progress loading
export default function File({
  src,
  alt,
  width,
  height,
  aspectWidth,
  aspectHeight,
  className,
  onClick,
  spacing,
  loading,
  contain,
  imageClassName,
  imageBadge,
  ...props
}) {
  const classes = useStyles({ contain })
  const [source, setSource] = useState(src || ImagePlaceholder)
  useEffect(() => {
    if (src) {
      setSource(src)
    }
  }, [src])

  if (aspectWidth && aspectHeight) {
    return (
      <Container
        aspectWidth={aspectWidth}
        aspectHeight={aspectHeight}
        className={className}
        onClick={onClick}
        bottom={spacing}
      >
        {loading && <LoadingOverlay noRound />}
        <img
          src={source}
          alt={alt}
          width="100%"
          height="100%"
          className={classNames(classes.cover, imageClassName)}
          onError={() => setSource(ImagePlaceholder)}
        />
      </Container>
    )
  }

  return (
    <>
      <img
        src={source}
        alt={alt}
        width={width}
        height={height}
        className={className}
        onClick={onClick}
        aria-hidden="true"
        {...props}
      />
    </>
  )
}

File.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  aspectWidth: PropTypes.number,
  aspectHeight: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  spacing: PropTypes.number,
  loading: PropTypes.bool,
  contain: PropTypes.bool,
  imageBadge: PropTypes.object,
  imageClassName: PropTypes.string,
}
