import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Seperator from 'components/common/Seperator'
import Typography from 'components/common/Typography'
// import johndearBrand from 'assets/images/JohndearBrandImage.svg'
// import johndearImage from 'assets/images/JohndearImage.svg'
import Placeholder from 'assets/images/Placeholder.png'
import React, { useState } from 'react'
import useToggle from 'common-hooks/useToggle'
import ImageSliderContainer from 'components/custom/image-slider/ImageSliderContainer'
import Image from 'components/common/Image'
import classNames from 'classnames'
import { getColor } from 'theme/colors'
import { useParams } from 'react-router-dom'
import useReqSupplierDetails from 'common-hooks/buyer-supplier-list/useReqSupplierDetails'
import { getWellKnownAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  title: {},
  root: {},
  mainFlex: {
    display: 'flex',
  },
  eqpimage: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
    width: '120px',
    height: '120px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),

    '& div': {
      width: '32.2%',
      height: '120px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',
    },
  },
  eqpimage2: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
    width: '120px',
    height: '120px',
    border: '1px solid',
    borderColor: getColor('grey-g95'),
    borderLeft: '0',

    '& div': {
      width: '32.2%',
      height: '120px',
      display: 'flex',
      justifyContent: ' center',
      alignItems: 'center',
      border: '1px solid #F0F0F4',
    },
  },
  partsimages: {
    width: '32.2%',
    cursor: 'pointer',
    height: '120px',
    display: 'flex',
    justifyContent: ' center',
    alignItems: 'center',
    '& img': {
      width: '120px',
      height: '120px',
      border: '1px solid #F0F0F4',
    },
  },
  p8: {
    padding: '8px 0',
    color: '#36384C',
  },
  p10: {
    padding: '10px 0',
    color: '#36384C',
  },
  p2: {
    padding: '2px 0',
  },
  partImageGrid: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  imageOverlay: (props) => ({
    position: 'relative',
    cursor: 'pointer',
    '&:before': {
      position: 'absolute',
      content: `"+${props?.partsImage?.length - 3}"`,
      top: '0',
      bottom: '0',
      fontWeight: ' 400',
      fontFamily: 'Lexend Deca',
      fontSize: ' 14px',
      left: '0',
      right: '0',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#13141bb3',
    },
  }),
}))
const SupplierRequestForm = () => {
  const [openImageDialog, toggleImageDialog] = useToggle(false)
  const [imageIndex, setImageIndex] = useState(0)
  const params = useParams()
  const { supplierReqDetails } = useReqSupplierDetails(params?.id, params?.reqId)
  function handleImage(index) {
    setImageIndex(index)
    if (imageIndex >= 0) {
      toggleImageDialog(true)
    }
  }
  const partsImage = supplierReqDetails?.quote_assets
  const classes = useStyles({ partsImage })

  return (
    <Container className={classNames(classes.mainFlex, classes.root)} direction={'column'} gap={'10px 0'}>
      <Container className={classNames(classes.mainFlex, classes.title)} direction={'column'}>
        <Container>
          <Typography component="h1" variant="title" color={getColor('grey-g25')} className={classes.p8}>
            Buyer
          </Typography>
        </Container>
        <Container>
          <Typography component="p" variant="body1" color={getColor('grey-g25')} className={classes.p10}>
            {supplierReqDetails?.buyer_full_name}
          </Typography>
        </Container>
      </Container>
      <Seperator />
      <Container className={classes.mainFlex} direction={'column'}>
        <Container>
          <Typography className={classes.p8} component={'h1'} color={getColor('grey-g25')} variant={'title'}>
            Equipment Details
          </Typography>
        </Container>
        <Container flex justify="flex-start">
          <Image
            src={supplierReqDetails?.brand_cover_image || Placeholder}
            alt={'brand Image'}
            className={classes.eqpimage}
          />
          <Image
            src={supplierReqDetails?.equip_cover_image || Placeholder}
            alt={'brand Image'}
            className={classes.eqpimage2}
          />
        </Container>
        <Container>
          <Typography className={classNames(classes.p10)} component={'p'} variant="body1" color={getColor('grey-g25')}>
            <span>{supplierReqDetails?.brand_name}</span>
            &nbsp;
            <span>{supplierReqDetails?.equip_name}</span>
            {supplierReqDetails?.brand_model_number ? `> ${supplierReqDetails?.brand_model_number}` : ''}
            {supplierReqDetails?.brand_serial_number ? `> ${supplierReqDetails?.brand_serial_number}` : ''}
          </Typography>
        </Container>
      </Container>
      <Seperator />
      <Container className={classes.mainFlex} direction={'column'}>
        <Container>
          <Typography color={getColor('grey-g25')} className={classes.p8} component="h1" variant="title">
            Part Details
          </Typography>
        </Container>

        <Container className={classes.partImageGrid}>
          {supplierReqDetails?.quote_assets?.length !== 0 &&
            supplierReqDetails?.quote_assets
              ?.map((obj, index) => (
                <Container
                  key={index}
                  className={classNames(
                    classes.partsimages,
                    index === 2 && supplierReqDetails?.quote_assets.length > 3 ? classes.imageOverlay : ''
                  )}
                  onClick={() => handleImage(index)}
                >
                  <Image key={index} src={obj?.part_image} alt={'partImage'} />
                </Container>
              ))
              .splice(0, 3)}
        </Container>
        <Container className={classes.mainFlex} direction={'column'}>
          <Typography className={classes.p10} component={'p'} variant="body1" color={getColor('grey-g25')}>
            {supplierReqDetails?.part_details?.name}
            {supplierReqDetails?.part_name && ` > ${supplierReqDetails?.part_name}`}
            {supplierReqDetails?.part_number && ` > ${supplierReqDetails?.part_number}`}
          </Typography>

          {/* <Container className={classes.mainFlex} justify={'space-between'}>
            <Typography className={classes.p10} component={'p'} variant="body1" color={getColor('grey-g25')}>
              Category
            </Typography>
            <Typography className={classes.p10} component={'p'} variant="body1" color={getColor('grey-g35')}>
              {supplierReqDetails?.part_details?.name}
            </Typography>
          </Container> */}
          {/* <Container className={classes.mainFlex} justify={'space-between'}>
            <Typography variant="body1" className={classes.p10} component={'p'} color={getColor('grey-g25')}>
              Sub Category
            </Typography>
            <Typography variant="body1" className={classes.p10} component={'p'} color={getColor('grey-g35')}>
              {supplierReqDetails?.part_name}
              {supplierReqDetails?.part_number && ` : ${supplierReqDetails?.part_number}`}
            </Typography>
          </Container> */}
          <Container className={classes.mainFlex} justify={'space-between'}>
            <Typography variant="body1" className={classes.p10} component={'p'} color={getColor('grey-g25')}>
              Quantity
            </Typography>
            <Typography variant="body1" className={classes.p10} component={'p'} color={getColor('grey-g35')}>
              {supplierReqDetails?.quantity}
            </Typography>
          </Container>
        </Container>
      </Container>
      <Seperator />

      <Container className={classes.mainFlex} direction={'column'}>
        <Container>
          <Typography className={classes.p8} component="h1" variant="title" color={getColor('grey-g25')}>
            Notes
          </Typography>
        </Container>
        <Container>
          <Typography variant="body1" color={getColor('grey-g35')} component={'p'} className={classes.p2}>
            {supplierReqDetails?.note}
          </Typography>
        </Container>
      </Container>
      <Seperator />
      <Container className={classes.mainFlex} direction={'column'}>
        <Container>
          <Typography className={classes.p8} component="h1" variant="title" color={getColor('grey-g25')}>
            Shipping Address
          </Typography>
        </Container>
        <Container>
          <Typography variant="body1" color={getColor('grey-g25')} component="p">
            {supplierReqDetails?.buyer_full_name} • {supplierReqDetails?.cmp_delivery_addr_company_name}
          </Typography>

          <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'} className={classes.p2}>
            {getWellKnownAddress(
              supplierReqDetails?.cmp_delivery_addr_addr_line_1,
              supplierReqDetails?.cmp_delivery_addr_addr_line_2
            )}
          </Typography>
          {supplierReqDetails?.cmp_delivery_addr_unit_number && (
            <Typography variant="paragraph1" color={getColor('grey-g35')}>
              UnitNumber:{supplierReqDetails?.cmp_delivery_addr_unit_number}
            </Typography>
          )}
          {supplierReqDetails?.cmp_delivery_addr_email && (
            <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
              E:{supplierReqDetails?.cmp_delivery_addr_email}
            </Typography>
          )}
          {supplierReqDetails?.cmp_delivery_addr_phone && (
            <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
              P:{supplierReqDetails?.cmp_delivery_addr_phone}
            </Typography>
          )}
        </Container>
      </Container>
      <ImageSliderContainer
        open={openImageDialog}
        onclose={toggleImageDialog}
        imageData={supplierReqDetails?.quote_assets}
        index={imageIndex}
      />
    </Container>
  )
}

export default SupplierRequestForm
