import Drawer from 'components/custom/drawer/Drawer'
import DrawerHeader from 'components/custom/drawer/DrawerHeader'
import PropTypes from 'prop-types'
import Container from 'components/common/Container'
import Select from 'components/common/select/Select'
import { makeStyles } from '@mui/styles'
import Typography from 'components/common/Typography'
import DrawerFooter from 'components/custom/drawer/DrawerFooter'
import useIndustryList from 'common-hooks/useIndustryList'
import { useState } from 'react'
import useEquipmentsList from 'common-hooks/create-quote/useEquipmentsList'
// import usePartsDetails from 'common-hooks/create-quote/usePartsDetails'
import { createOption } from 'components/common/MonthYearPicker'
import { getOptionsList } from 'utils/Utils'
import useOEMbrandList from 'common-hooks/super-admin/useOEMbrandList'

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  subContainer: {
    padding: '20px',
  },
  container: {
    '& label': {
      color: 'red',
    },
  },
  filterRoot: {
    alignItems: 'unset',
  },
})

const PreferenceDrawer = ({ open, onclose, setPreferenceData }) => {
  const classes = useStyles()
  const { industryData } = useIndustryList()
  const [selectedIndus, setSelectedIndus] = useState([])
  const [selectedEqup, setSelectedEqup] = useState([])
  // const [selectedParts, setSelectedParts] = useState([])
  const [selectedBrand, setSelectedBrand] = useState([])
  const { equipmentsList } = useEquipmentsList(selectedIndus?.map((obj) => obj?.value)?.join())
  const industryOptions = getOptionsList(industryData)
  const { brandList, onSearch, search } = useOEMbrandList()

  // const { brandList, onBrandSearch, searchedBrand } = useBrandList(selectedEqup?.map((obj) => obj?.value)?.join())
  // const { partsList, onPartsSearch, searchedParts } = usePartsDetails(selectedBrand?.join(), selectedEqup?.join())
  // eslint-disable-next-line consistent-return
  function getOptionsForFillter(data) {
    if (data) {
      return data?.map((values) => createOption(values.id, values.name))
    }
    if (search.length > 0) {
      return data?.map((values) => createOption(values.id, values.name))
    }
    // if (searchedParts.length > 0) {
    //   return data?.map((values) => createOption(values.id, values.name))
    // }
  }
  const equpOptions = getOptionsForFillter(equipmentsList)
  const brnadOptions = getOptionsForFillter(brandList)
  // const partsOptions = getOptionsForFillter(partsList)
  const handleSave = () => {
    setPreferenceData({
      industry: selectedIndus?.map((obj) => obj?.value)?.join(),
      equipment: selectedEqup?.map((obj) => obj?.value)?.join(),
      brand: selectedBrand?.map((obj) => obj?.value)?.join(),
      // part: selectedParts?.join(),
    })
    onclose()
  }

  return (
    <>
      <Drawer open={open} onClose={onclose}>
        <Container className={classes.mainContainer}>
          <Container>
            <Container>
              <DrawerHeader title="Preference" onClose={onclose} />
            </Container>
            <Container className={classes.subContainer}>
              <Container flex direction={'column'} gap={'20px'} className={classes.filterRoot}>
                <Typography variant="body1">Customize your request feed </Typography>
                <Select
                  multi
                  id={'Industry'}
                  name={'Industry'}
                  label={'Industry'}
                  placeholder={'All'}
                  options={industryOptions}
                  value={selectedIndus}
                  onChange={(selectedOptions) => {
                    setSelectedIndus(selectedOptions?.map((item) => item))
                  }}
                />
                <Select
                  options={equpOptions}
                  multi
                  id={'Equipment'}
                  name={'Equipment'}
                  label={'Equipment'}
                  placeholder={'All'}
                  value={selectedEqup}
                  onChange={(selectedOptions) => {
                    setSelectedEqup(selectedOptions?.map((item) => item))
                  }}
                />
                <Select
                  options={brnadOptions}
                  multi
                  id={'Brand'}
                  name={'Brand'}
                  label={'Brand'}
                  placeholder={'All'}
                  value={selectedBrand}
                  onInputChange={(e) => {
                    onSearch(e)
                  }}
                  onChange={(selectedOptions) => {
                    setSelectedBrand(selectedOptions?.map((item) => item))
                  }}
                />
                {/* <Select
                  options={partsOptions}
                  multi
                  id={'Part '}
                  name={'Part '}
                  label={'Part'}
                  onInputChange={(e) => {
                    onPartsSearch(e)
                  }}
                  onChange={(selectedOptions) => {
                    setSelectedParts(selectedOptions?.map((item) => item?.value))
                  }}
                /> */}
              </Container>
            </Container>
          </Container>

          <Container>
            <DrawerFooter width={'100%'} variant={'primary'} onClick={handleSave}>
              Save
            </DrawerFooter>
          </Container>
        </Container>
      </Drawer>
    </>
  )
}
PreferenceDrawer.propTypes = {
  open: PropTypes.bool,
  onclose: PropTypes.func,
  setPreferenceData: PropTypes.func,
}
export default PreferenceDrawer
