import { getHelp } from 'common-api/user-profile/getUserProfile'
import useToastify from 'common-hooks/useToastify'

export default function useGetHelp() {
  const { toastMessage } = useToastify()

  const helpDialog = async (email, subject, body, section) => {
    try {
      const response = await getHelp({
        email,
        ...(subject && { subject }),
        body,
        section,
      })
      toastMessage('success', 'Message sent successfully to PartsClub')
      return response
    } catch {
      return false
    }
  }
  return { helpDialog }
}
