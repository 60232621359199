import React, { useState } from 'react'
import { BuyerQuoteContext } from 'containers/buyer-quotes/BuyerQuotesUtils'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'

import LoginDialog from 'containers/authentication/LoginDialog'
import CreateQuoteFooter from './CreateQuoteFooter'
import FormProvider from './forms/FormProvider'
import EquipmentsForm from './forms/EquipmentsForm'
import BrandsForm from './forms/BrandsForm'
import PartsForm from './forms/PartsForm'

import CreateQuoteSteps from './CreateQuoteSteps'
import SummaryForm from './forms/SummaryForm'

const useStyle = makeStyles(() => ({
  root: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  dailogFooter: {
    width: '100%',
    position: 'relative',
  },
  main: {
    maxWidth: '780px',
    height: '100vh',
    paddingTop: '60px',
    display: 'flex',
    flexDirection: ' column',
    justifyContent: ' center',
    margin: '0 auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabLabel: {
    padding: '0px 0px',
    height: '24px',
    fontFamily: 'Lexend Deca',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: getColor('grey-g35'),
    '& .MuiTabs-indicator': {
      backgroundColor: 'orange',
      height: 3,
    },
    '& .MuiTab-root.Mui-selected': {
      color: 'red',
    },
  },
  dailogBody: {
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  dailogHeader: {
    width: '320px',
    margin: '0 auto',
    marginBottom: '30px',
  },
}))

// function FormCompleted() {
//   const { data } = useFormData()

//   return (
//     <>
//       <h2>Thank you for Create Quote! 🎉</h2>

//       <pre>{JSON.stringify(data)}</pre>
//     </>
//   )
// }

export default function CreateQuote() {
  const [formStep, setFormStep] = useState(0)
  const [nextBtn, setnextBtn] = useState(false)
  const [selectedParts, setSelectedParts] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1)

  const prevFormStep = () => {
    setFormStep((currentStep) => currentStep - 1)
  }

  const [quoteData, setQuoteData] = useState([])

  const contextValue = {
    onSaveBuyerQuoteInformaton: setQuoteData,
    BuyerQueoteInformation: quoteData,
    visibleTextBoxData: selectedParts,
    setTextBoxVisibilityData: setSelectedParts,
  }

  const classes = useStyle()

  return (
    <>
      <Container className={classes.main}>
        {/* <Container className={classes.mainDailog}> */}
        <Container className={classes.dailogHeader}>
          <CreateQuoteSteps formStep={formStep} />
        </Container>

        <Container className={classes.dailogBody}>
          <Container padding="0 30px">
            <BuyerQuoteContext.Provider value={contextValue}>
              <FormProvider>
                {formStep === 0 && (
                  <EquipmentsForm
                    formStep={formStep}
                    nextFormStep={nextFormStep}
                    setnextBtn={setnextBtn}
                    nextBtn={nextBtn}
                  />
                )}
                {formStep === 1 && (
                  <BrandsForm
                    formStep={formStep}
                    nextFormStep={nextFormStep}
                    prevFormStep={prevFormStep}
                    setnextBtn={setnextBtn}
                    nextBtn={nextBtn}
                  />
                )}
                {formStep === 2 && (
                  <PartsForm
                    // setSuccesDialog={setSuccesDialog}
                    // setSuccesLink={setSuccesLink}
                    setnextBtn={setnextBtn}
                    formStep={formStep}
                    nextFormStep={nextFormStep}
                    prevFormStep={prevFormStep}
                    nextBtn={nextBtn}
                  />
                )}
                {formStep === 3 && (
                  <SummaryForm
                    // setSuccesDialog={setSuccesDialog}
                    // setSuccesLink={setSuccesLink}
                    setnextBtn={setnextBtn}
                    // onClose={onClose}
                  />
                )}
              </FormProvider>
            </BuyerQuoteContext.Provider>
          </Container>
        </Container>

        <Container className={classes.dailogFooter}>
          <CreateQuoteFooter
            currentStep={formStep}
            nextFormStep={nextFormStep}
            prevFormStep={prevFormStep}
            nextBtn={nextBtn}
            setnextBtn={setnextBtn}
            setOpenLoginDialog={setOpenDialog}
          />
        </Container>
        <LoginDialog open={openDialog} onClose={setOpenDialog} />
        {/* </Container> */}
      </Container>
    </>
  )
}
