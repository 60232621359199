import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@mui/styles'

import * as yup from 'yup'

import useGetCompanyPolicies from 'common-hooks/settings/company/useGetCompanyPolicies'
import useToggle from 'common-hooks/useToggle'
import Checkbox from 'components/common/Checkbox'
import Chip from 'components/common/Chip'
import Container from 'components/common/Container'

import SwitchToggle from 'components/common/switch/SwitchToggle'
import LabelTextField from 'components/common/text/LabelTextField'
import TextArea from 'components/common/text/TextArea'
import Typography from 'components/common/Typography'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { getColor } from 'theme/colors'
import { getFormError } from 'utils/form/form'
import useCompanySettings from 'common-hooks/settings/company/useCompanySettings'
import TitleList from 'components/custom/list/TitleList'
import CompanyPolicesCountryDialog from './CompanyPolicesCountryDialog'
import Button from 'components/common/Button'

const useStyles = makeStyles(() => ({
  returnPeriodFlex: {
    display: 'flex ',
    flexDirection: 'column',
  },
  policeReturn: {
    display: 'flex',
    gap: '20px 0',
    padding: '20px',
    background: '#fff',
    border: '1px solid #F0F0F4',
    borderRadius: '4px',
    flexDirection: 'column',
  },
  policesInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },
  policesParagraph: {
    paddingTop: '20px',
    color: getColor('grey-g35'),
  },
  addchipText: {
    fontFamily: 'Lexend Deca',
    fontStyle: ' normal',
    fontWeight: '400',
    fontSize: ' 14px',
    lineHeight: '20px',
    color: '#36384C',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  addIcon: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  taxesTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    // marginTop: '30px',
  },
  margin: {
    // marginTop: '30px',
  },
  AddBtn: {
    color: '#36384C!important',
    backgroundColor: '#fff!important',
    width: '69px',
    height: '28px',
    minHeight: 'unset',
    border: '1px solid #C3C5D5',
    borderRadius: '100px',
    '& span': {
      color: '#36384C!important',
    },
  },
  canclationRoot: {
    background: '#fff',
    padding: '20px',
    borderRadius: '4px',
  },
}))

const CompanyPolicies = () => {
  const classes = useStyles()

  const [countryDialog, setCountryDialog] = useToggle(false)
  const [countryData, setCountryData] = useState([])
  const [disableState, setDisableState] = useState(true)
  const schema = yup.object({
    return_period: yup.string().required('return period is required.'),
    restocking_fee: yup.string().required('restocking fee is required.'),
    warranty_period: yup.string().required('warranty period is required.'),
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const { policiesAdd } = useCompanySettings()

  const onSubmit = (data) => {
    policiesAdd({ ...data, free_shipping_eligible_countries: countryData })
  }
  const { companyPolicy } = useGetCompanyPolicies()

  useEffect(() => {
    if (companyPolicy) {
      Object.keys(companyPolicy).map((key) => {
        if (key === 'free_shipping_eligible_countries' && companyPolicy?.free_shipping_eligible_countries.length > 0) {
          setCountryData(companyPolicy?.free_shipping_eligible_countries)
        } else {
          setValue(key, companyPolicy[key])
        }
        return 0
      })
    }
  }, [companyPolicy])
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)} id="policy-form">
        <Container className={classes.policesMain}>
          <Container className={classes.policesInner}>
            <Container className={classes.margin}>
              <Container className={classes.policeReturn}>
                <Container className={classes.taxesTitle}>
                  <TitleList title={'Returns'} subtitle={'Policies for returning orders'} />
                </Container>
                <Container flex gap={'0 20px'} justify={'flex-start'}>
                  <Container className={classes.returnPeriodFlex}>
                    <Typography variant="paragraph2" component={'label'} color={getColor('grey-g25')}>
                      Return Period*
                    </Typography>
                    <Controller
                      name={'return_period'}
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <LabelTextField
                          name={name}
                          value={value}
                          type={'number'}
                          labelText={'Days'}
                          lablePosition={'right'}
                          width={'250px'}
                          placeholder={'30'}
                          onChange={onChange}
                          error={getFormError(errors, name)}
                          className={classes.nameFiled}
                        />
                      )}
                    />
                  </Container>
                  <Container className={classes.returnPeriodFlex}>
                    <Typography variant="paragraph2" component={'label'} color={getColor('grey-g25')}>
                      Restocking Fee*
                    </Typography>
                    <Controller
                      name={'restocking_fee'}
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <LabelTextField
                          name={name}
                          value={value}
                          type={'number'}
                          labelText={'%'}
                          lablePosition={'right'}
                          width={'95px'}
                          placeholder={'00.00'}
                          onChange={onChange}
                          error={getFormError(errors, name)}
                          className={classes.nameFiled}
                        />
                      )}
                    />
                  </Container>
                </Container>
                <Container>
                  <Typography variant="paragraph2" component={'label'} color={getColor('grey-g25')}>
                    Return Policy Details
                  </Typography>
                  <Controller
                    name={'return_policy_details'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <TextArea
                        name={name}
                        value={value}
                        onChange={onChange}
                        // error={getFormError(errors, name)}
                        placeholder={'Write description about return policy here...'}
                      />
                    )}
                  />
                </Container>
                <Controller
                  name={'rtn_freight_for_rtnp'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Checkbox
                      name={name}
                      value={value !== undefined ? value : false}
                      onChange={onChange}
                      label={'Buyer pays return freight'}
                      // error={getFormError(errors, name)}
                    />
                  )}
                />

                <Typography variant="paragraph2" color={getColor('grey-g25')} component={'p'}>
                  *Buyers must be able to return eligible products for at least 30-days after receiving their order.
                  Sellers can use the quote or product settings to mark items as ineligible for return (i.e.,
                  non-returnable). Buyers will not have a return button for these products.
                </Typography>
              </Container>
            </Container>

            <Container className={classes.canclationRoot}>
              <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                Cancellations
              </Typography>
              <Typography variant="paragraph2" color={getColor('grey-g35')} component={'p'}>
                Policies for cancelling orders
              </Typography>
              <Container className={classes.policesParagraph}>
                <Typography variant="paragraph1" color={getColor('grey-g35')} component={'p'}>
                  Buyers can cancel eligible PartsClub orders for free until a shipment tracking number has been
                  inputted by the seller. Once a tracking number has been added, the order is considered shipped and a
                  return must be initiated by the buyer.
                </Typography>
                <Typography
                  variant="paragraph1"
                  color={getColor('grey-g35')}
                  component={'p'}
                  className={classes.policesParagraph}
                >
                  Sellers can use the quote or product settings to mark items as ineligible for cancellation (i.e.,
                  non-cancellable). Buyers will not have a cancel button for these products.
                </Typography>
              </Container>
            </Container>

            <Container className={classes.policeReturn}>
              <Container>
                <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                  Warranty
                </Typography>
                <Typography variant="paragraph1" color={getColor('grey-g25')} component={'p'}>
                  Policies for claiming warranty
                </Typography>
              </Container>
              <Container flex gap={'0 20px'} justify={'flex-start'}>
                <Container className={classes.returnPeriodFlex}>
                  <Typography variant="paragraph2" component={'label'} color={getColor('grey-g25')}>
                    Warranty Period*
                  </Typography>
                  <Controller
                    name={'warranty_period'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <LabelTextField
                        name={name}
                        value={value}
                        type={'number'}
                        labelText={'Days'}
                        lablePosition={'right'}
                        width={'250px'}
                        placeholder={'365'}
                        onChange={onChange}
                        error={getFormError(errors, name)}
                        className={classes.nameFiled}
                      />
                    )}
                  />
                </Container>
              </Container>
              <Container>
                <Typography variant="paragraph2" component={'label'} color={getColor('grey-g25')}>
                  Warranty Policy Details
                </Typography>
                <Controller
                  name={'warranty_policy_details'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextArea
                      name={name}
                      value={value}
                      onChange={onChange}
                      // error={getFormError(errors, name)}
                      placeholder={'Write description about warranty policy here...'}
                    />
                  )}
                />
              </Container>
              <Controller
                name={'rtn_freight_for_wty'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <Checkbox
                    name={name}
                    value={value !== undefined ? value : false}
                    onChange={onChange}
                    label={'Buyer pays return freight'}
                    // error={getFormError(errors, name)}
                  />
                )}
              />
            </Container>

            <Container>
              <Container className={classes.policeReturn}>
                <Container flex justify="space-between">
                  <Container>
                    <Typography variant="title" color={getColor('grey-g25')} component={'p'}>
                      Free Shipping
                    </Typography>
                    <Typography variant="paragraph1" color={getColor('grey-g25')} component={'p'}>
                      Policies for free shipping at checkout
                    </Typography>
                  </Container>
                  <Controller
                    name={'free_shipping_enabled'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => {
                      if (value) {
                        setDisableState(false)
                      } else {
                        setDisableState(true)
                      }
                      return (
                        <SwitchToggle
                          name={name}
                          value={value}
                          onChange={onChange}
                          defaultChecked={value}
                          // error={getFormError(errors, name)}
                        />
                      )
                    }}
                  />
                </Container>
                <Container className={classes.returnPeriodFlex}>
                  <Typography variant="paragraph2" component={'label'} color={getColor('grey-g25')}>
                    Minimum Spend
                  </Typography>
                  <Controller
                    name={'free_shipping_min_spend'}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                      <LabelTextField
                        name={name}
                        value={value}
                        onChange={onChange}
                        type={'number'}
                        labelText={'$'}
                        lablePosition={'left'}
                        width={'250px'}
                        placeholder={'00.00'}
                        disabled={disableState}
                        // error={getFormError(errors, name)}
                      />
                    )}
                  />
                </Container>
                {!disableState && (
                  <Container>
                    <Container flex justify="space-between">
                      <Typography className={classes.addchipText}>Add Eligible Countries for Free Shipping</Typography>
                      {/* <IconButton onClick={setCountryDialog} className={classes.addIcon}>
                        <AddIcon />
                      </IconButton> */}
                    </Container>
                    <Container flex justify="flex-start" gap={'0 10px'}>
                      <Button onClick={setCountryDialog} className={classes.AddBtn}>
                        + Add
                      </Button>
                      {countryData?.map((data, index) => (
                        <Chip
                          key={index}
                          label={data}
                          removable
                          onRemove={() => setCountryData(countryData.filter((dlr, id) => id !== index))}
                        />
                      ))}
                    </Container>
                  </Container>
                )}
              </Container>
            </Container>
          </Container>
        </Container>
      </form>
      <CompanyPolicesCountryDialog
        open={countryDialog}
        onclose={setCountryDialog}
        setCountryData={setCountryData}
        countryData={countryData}
      />
    </Container>
  )
}

export default CompanyPolicies
