import UserProfile from 'containers/user-profile/UserProfile'
import { Navigate, Route } from 'react-router-dom'
import AdminPermissionRoute from 'routes/AdminPermissionRoute'
import Orders from './Orders'
import Report from './Report'
import CreateSupplier from './supplier-mgt/create-supplier/CreateSupplier'
import SuppliersList from './supplier-mgt/SuppliersList'
import Support from './Support'
import Users from './user/Users'

export default function SuperAdminRoutes() {
  return (
    <>
      <Route index path="/sa" element={<Navigate to="company" />} />
      <Route
        path="/sa/company"
        element={
          <AdminPermissionRoute>
            <SuppliersList />
          </AdminPermissionRoute>
        }
      />
      <Route
        path="/sa/addsupplier"
        element={
          <AdminPermissionRoute>
            <CreateSupplier />
          </AdminPermissionRoute>
        }
      />
      <Route
        path="/sa/editsupplier/:suppliername/:id"
        element={
          <AdminPermissionRoute>
            <CreateSupplier />
          </AdminPermissionRoute>
        }
      />
      <Route
        path="/sa/Profile"
        element={
          <AdminPermissionRoute>
            <UserProfile />
          </AdminPermissionRoute>
        }
      />
      <Route
        path="/sa/users"
        element={
          <AdminPermissionRoute>
            <Users />
          </AdminPermissionRoute>
        }
      />
      <Route
        path="/sa/report"
        element={
          <AdminPermissionRoute>
            <Report />
          </AdminPermissionRoute>
        }
      />
      <Route
        path="/sa/orders"
        element={
          <AdminPermissionRoute>
            <Orders />
          </AdminPermissionRoute>
        }
      />
      <Route
        path="/sa/support"
        element={
          <AdminPermissionRoute>
            <Support />
          </AdminPermissionRoute>
        }
      />
    </>
  )
}
