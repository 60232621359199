/* eslint-disable import/order */
import React, { useState } from 'react'
import Container from 'components/common/Container'
import { CaretRightIcon, CaretLeftIcon } from 'assets/icons'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import Button from 'components/common/Button'
import Select from 'components/common/select/Select'
import { getColor } from 'theme/colors'
import { useNavigate } from 'react-router-dom'
// import useSuppliersReqList from 'common-hooks/buyer-supplier-list/useSuppliersReqList'
// import { DATE_FORMAT_PRIMARY, formatDate } from 'utils/dateUtils'
import LandingPageHeader from './LandingPageHeader'
import './css/landingpage.css'
import './css/responsive.css'
import LandingPageFooter from './LandingPageFooter'
import LoginDialog from 'containers/authentication/LoginDialog'
import useIndustryList from 'common-hooks/useIndustryList'
import LoadingOverlay from 'components/common/LoadingOverlay'
import { getFlag, getFromLocalStorage, getShortAddress, timeFromNow } from 'utils/Utils'
import HtmlTitle from 'utils/HtmlTitle'
import useDummyQuotes from 'common-hooks/landing-page/useDummyQuotes'
import { constants } from 'utils/constants'

const useStyles = makeStyles(() => ({
  root: {
    height: '80vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  mainContainer: {
    marginBottom: '20px',
    width: '800px',
    height: '175px',
    background: getColor('light-main'),
    padding: '20px',
    gap: '10px',
    borderRadius: '4px',
    border: '1px solid #F0F0F4',
  },
  subContainer: {
    gap: '3px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '6px 0px',
  },
  subHeader: {},
  btn: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  img: {
    width: '90px',
    height: '67.5px',
  },
  dot: {
    color: getColor('grey-g35'),
    padding: '0px 5px',
  },
  flag: {
    paddingLeft: '5.36px',
  },
  listData: {
    marginTop: '62px',
  },
  blueLinks: {
    background: 'transparent',
    height: 'unset',
    width: 'unset',
    padding: 'unset',
    minHeight: 'unset',
    color: getColor('func-info'),
    borderBottom: '1px solid',
    borderRadius: 'unset',
    lineHeight: '1',
    zIndex: 99999,
    '& div': {
      '& span': {
        lineHeight: 'unset',
      },
    },
    borderColor: getColor('func-info'),
    '&:hover': {
      background: 'transparent',
    },
  },

  btnFilter: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  progress: {
    position: 'absolute',
    color: '#2680D9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '15%',
    left: '47%',
    right: '0',
    bottom: '0',
  },
  overlayRoot: {
    width: '700px',
    height: '600px',
    margin: '0 auto',
    position: 'relative',
  },
}))

export default function QuoteRequest() {
  const [selectedOpt, setSelectedOpt] = useState()
  const handleChange = (selectedOptions) => {
    setSelectedOpt(selectedOptions?.id)
  }

  const { reqQuoteData, setPaginate, pagimation, isLoadingReq } = useDummyQuotes(selectedOpt)
  const { industryData } = useIndustryList()

  if (industryData?.length === 4) {
    industryData.push({ id: '', name: 'All industries' })
    industryData.reverse()
  }
  const industryOptions = industryData?.map((obj) => ({ id: obj.id, label: obj.name }))
  const [openDialog, setOpenDialog] = useState(false)
  const filtered = reqQuoteData ? reqQuoteData.filter((item) => Object.values(item).join('').toLowerCase()) : []
  const classes = useStyles()
  const navigate = useNavigate()
  const isAuthorised = !!getFromLocalStorage(constants.TOKEN)

  const getSerialNumber = () => (isAuthorised ? filtered?.quote_brand_serial_number || '-' : 'Unlock Serial Number')

  const getBuyerDetails = () => (isAuthorised ? filtered?.buyer_full_name || '-' : 'Unlock Buyer Details')

  const handleClick = (e) => {
    setOpenDialog(true)
    e.stopPropagation()
  }
  return (
    <>
      <Container className="quoterequest-main">
        <HtmlTitle title={'Requests'} />
        <Container className="container">
          <LandingPageHeader />
        </Container>
        <Container className={'quoterequest-inner'}>
          <Container className="container">
            <Container className={'quoterequest-title'}>
              <Typography component="h3">Requests</Typography>
              <Container flex gap={'10px'}>
                <Container className="select-drop-down">
                  <Select
                    options={industryOptions}
                    defaultValue={{ id: '', label: 'All Industries' }}
                    id={'Options'}
                    name={'Options'}
                    placeholder={'All industries'}
                    width={'100%'}
                    onChange={handleChange}
                  />
                </Container>
              </Container>
            </Container>
          </Container>
          <Container className="quoterequest-list-main">
            <Container className="create-quote-request-list">
              {isLoadingReq && (
                // <Container className={classes.overlayRoot}>
                //   <LoadingOverlay color="secondary" size={40} className={classes.progress} />
                // </Container>
                <LoadingOverlay />
              )}

              {filtered?.length === 0 && !isLoadingReq && <Typography>No Data Found</Typography>}
              {filtered?.map((filteredData, id) => (
                <Container
                  key={id}
                  flex
                  className="create-quote-request-list-card"
                  gap="0 10px"
                  onClick={() => navigate(`/quoterequest/detail/${filteredData.id}`)}
                >
                  <Container flex direction={'column'} className="card-left-img">
                    <img src={filteredData.brand_cover_image} alt="BRAND LOGO" />
                    <img src={filteredData.equip_cover_image} alt="Machine parts" />
                  </Container>
                  <Container width={'100%'} className={'card-right'}>
                    <Container className="card-title">
                      <Typography variant="title" component="h3" color={getColor('grey-g25')}>
                        {filteredData?.brand_name} {filteredData?.part_name} {filteredData?.quote_part_number}
                      </Typography>
                      <Typography variant="paragraph1" component={'p'} color={getColor('grey-g35')}>
                        {filteredData?.equip_name ? <>{filteredData?.equip_name}</> : <>{''}</>}
                        {filteredData?.quote_brand_model_number ? (
                          <>
                            <span className={classes.dot}> • </span>
                            {filteredData?.quote_brand_model_number}
                          </>
                        ) : (
                          <>{''}</>
                        )}
                        {filteredData?.quote_brand_serial_number ? (
                          <>
                            <span className={classes.dot}> • </span>
                            <Button onClick={handleClick} className={classes.blueLinks}>
                              {/* Unlock serial number */}
                              {getSerialNumber()}
                              {/* {filteredData?.quote_brand_serial_number} */}
                            </Button>
                          </>
                        ) : (
                          <>{''}</>
                        )}
                      </Typography>
                    </Container>
                    <Container className="buyerDetailRoot">
                      {/* <Typography variant="body1" color={getColor('grey-g25')} component={'p'}>
                                123 Equipment Rental Company
                              </Typography> */}
                      <Container>
                        <Button onClick={handleClick} className={classes.blueLinks}>
                          {/* {filteredData?.buyer_full_name} */}
                          {getBuyerDetails()}
                          {/* Unlock Buyer Details */}
                        </Button>
                      </Container>
                      <Container display="flex" alignItems="center" gap="5px">
                        <Typography
                          variant="paragraph1"
                          component="p"
                          color={getColor('grey-g35')}
                          className="flag-content"
                        >
                          {getShortAddress(
                            filteredData?.cust_addr_city,
                            filteredData?.cust_addr_state,
                            filteredData?.cust_addr_country
                          )}
                        </Typography>
                        {getFlag(filteredData?.cust_addr_country)}
                      </Container>
                    </Container>
                    <Container flex width={'100%'} justify={'space-between'}>
                      <Container>
                        <Typography
                          variant="paragraph1"
                          color={getColor('grey-g25')}
                          component="p"
                          className="brand-paragraph"
                        >
                          {filteredData?.brand_name}
                          {filteredData?.equip_name ? (
                            <>
                              <span className={classes.dot}> • </span>
                              {filteredData?.equip_name}
                            </>
                          ) : (
                            <>{''}</>
                          )}
                          {filteredData?.part_name || filteredData?.quote_brand_name_ref ? (
                            <>
                              <span className={classes.dot}> • </span>
                              {filteredData?.part_name || filteredData?.quote_brand_name_ref}
                            </>
                          ) : (
                            <>{''}</>
                          )}

                          {/* {filteredData?.quote_quantity ? (
                            <>
                              <span className={classes.dot}> • </span>
                              {filteredData?.quote_quantity}
                            </>
                          ) : (
                            <>{''}</>
                          )} */}
                        </Typography>
                      </Container>
                      <Container className={classes.btn}>
                        <Typography variant="paragraph1" className="time-upload">
                          {/* {formatDate(filteredData?.created_at, DATE_FORMAT_PRIMARY)} */}
                          {timeFromNow(filteredData?.created_at)}
                        </Typography>
                        <span className="time-upload">•</span>
                        <Button variant="positive" className={classes.startbtn}>
                          Start Quote
                        </Button>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              ))}
            </Container>
          </Container>
          {/* <Container className="pagination-bottom">
            <Pagination count={40} variant="outlined" shape="rounded" />
          </Container> */}
          <Container className="next-prev-bottoms">
            {pagimation.page === 1 ? (
              <Button variant={'secondary'} border icon={<CaretLeftIcon />} disabled>
                Prev.
              </Button>
            ) : (
              <Button variant={'secondary'} border onClick={() => setPaginate(false)} icon={<CaretLeftIcon />}>
                Prev.
              </Button>
            )}

            {pagimation.limit <= filtered.length ? (
              <Button variant={'secondary'} border onClick={() => setPaginate(true)} icon={<CaretRightIcon />}>
                Next
              </Button>
            ) : (
              <Button variant={'secondary'} border disabled icon={<CaretRightIcon />}>
                Next
              </Button>
            )}
          </Container>
        </Container>

        <LandingPageFooter />
        <LoginDialog open={openDialog} onClose={setOpenDialog} />
      </Container>
    </>
  )
}
