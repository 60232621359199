import { Card, CardContent, CardMedia } from '@mui/material'
import Typography from 'components/common/Typography'
import { makeStyles } from '@mui/styles'
import { getColor } from 'theme/colors'
import Container from 'components/common/Container'
import propTypes from 'prop-types'
import HeaderPlaceHolder from 'assets/images/CardHeaderPlaceHolder.png'
import Chip from 'components/common/Chip'
import Image from 'components/common/Image'
import { useNavigate } from 'react-router-dom'
import Verfied from 'assets/icons/Verfied.svg'
// import earlyBadge from 'assets/icons/earlyBadge.svg'
import Tooltip from 'components/common/Tooltip'
import { getFlag, getShortAddress } from 'utils/Utils'

const useStyles = makeStyles(() => ({
  root: {
    height: '276px',
    width: '280px',
    borderRadius: '0px',
    transition: 'boxShadow 2s ease-out 100ms',
    border: `1px solid ${getColor('grey-g95')}`,
    '&:hover': {
      boxShadow: '0px 16px 14px rgba(0, 0, 0, 0.06),0px 2px 6px rgba(0, 0, 0, 0.04),0px 0px 1px rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
    position: 'relative',
  },
  banner: {
    backgroundColor: getColor('grey-g95'),
    width: '100%',
    height: '80px',
    // position: 'relative',
  },
  logo: {
    backgroundColor: getColor('light-main'),
    width: '80px',
    height: '80px',
    top: '40px',
    left: '20px',
    border: `1px solid ${getColor('grey-g95')}`,
    borderRadius: '4px',
    position: 'absolute',
  },
  info: {
    padding: '20px',
    paddingTop: '16px',
  },
  linkButton: {
    height: '40px',
    width: '40px',
  },
  chipContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '13px',
    right: '10px',
  },
  premimumChip: {
    height: '20px',
    backgroundColor: '#FFC700',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: ' 16px',
    color: '#13141B',
    borderRadius: '100px',
    '& div': {
      color: '#13141B',
      fontSize: '11px',
    },
  },
  specializeChip: {
    color: getColor('grey-g25'),
    background: '#F0F0F4',
    borderRadius: ' 100px',
    fontWeight: ' 400',
    fontSize: ' 11px',
    display: 'flex',
    alignItems: 'center',
  },
  chipMain: {
    paddingTop: '4px',
  },
  verifyMain: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '20px',
    paddingTop: '10px',
    height: '35px',
  },
  paragraph: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: getColor('grey-g65'),
    height: '35px',
  },
  headingName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
export default function SupplierCard({ supplierList }) {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <>
      {supplierList?.map((data, index) => {
        const supplierName = `${data.name}`
        return (
          <Card
            key={index}
            className={'supplier-card-root'}
            elevation={0}
            // onClick={() => navigate('/supplier/suppliersdetails')}
            onClick={() => navigate(`/supplier/${supplierName}/${data?.id}`)}
          >
            <CardMedia
              id="banner"
              className={classes.banner}
              component="img"
              image={data?.imgheader || HeaderPlaceHolder}
              onError={(e) => {
                e.target.src = 'https://via.placeholder.com/150'
              }}
            />
            <Container className={classes.chipContainer}>
              {data?.is_premium && <Chip label={'Premium'} className={classes.premimumChip} />}
            </Container>
            <CardMedia
              id="logo"
              className={classes.logo}
              component="img"
              image={data?.logo}
              onError={(e) => {
                e.target.src = 'https://via.placeholder.com/150'
              }}
            />
            <Container className={classes.verifyMain}>
              {data?.is_verified && <Image src={Verfied} />}
              {/* <Image src={earlyBadge} /> */}
            </Container>
            <CardContent className={classes.info}>
              <Container direction="column" gap="10px" justify="flex-start">
                <Container>
                  <Typography
                    variant="title"
                    color={getColor('grey-g25')}
                    component="h5"
                    className={classes.headingName}
                  >
                    {data?.name}
                  </Typography>
                  <Container flex justify="flex-start" gap={'0px 5px'}>
                    <Typography
                      variant="paragraph1"
                      color={getColor('grey-g35')}
                      component="p"
                      className={classes.headingName}
                    >
                      {getShortAddress(data?.city, data?.state, data?.country, data?.country_abbrv)}
                      {/* {getWellKnownAddress(data?.city, data?.state)} */}
                    </Typography>
                    {getFlag(data?.country)}
                  </Container>
                </Container>
                <Typography className={classes.paragraph} variant="body2" component="p">
                  {data?.tagline}
                </Typography>

                <Container flex direction="row" gap="10px" justify="flex-start" className={classes.chipMain}>
                  {/* <Chip label={data.specialization} className={classes.specializeChip} />
                <Chip label="+1" className={classes.specializeChip} /> */}
                  {data?.industries?.length && (
                    <Container flex direction="row" gap="10px" justify="flex-start">
                      {data?.industries.map((ind) => ind?.name && <Chip label={ind?.name} />)[0]}
                      {/* <Chip label={`+ ${data?.industries.length - 1}`} /> */}
                      {data?.industries?.length - 1 === 0 ? (
                        ''
                      ) : (
                        <Tooltip placement={'right-start'} title={data?.industries?.map((ind) => ind?.name)}>
                          <span>
                            {' '}
                            <Chip label={`+ ${data?.industries?.length - 1}`} />
                          </span>
                        </Tooltip>
                      )}
                    </Container>
                  )}
                </Container>
              </Container>
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}
SupplierCard.propTypes = {
  supplierList: propTypes.array,
}
