import { makeStyles } from '@mui/styles'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import PropTypes from 'prop-types'
import Typography from 'components/common/Typography'
import React, { useContext, useState } from 'react'
import Placeholder from 'assets/images/Placeholder.png'
import ShipingAddressDialog from '../ShipingAddressDialog'
import { getColor } from 'theme/colors'
import Seperator from 'components/common/Seperator'
import { CloseIcon, InfoSmallIcon } from 'assets/icons'
import { Controller, useForm } from 'react-hook-form'
import TextField from 'components/common/text/TextField'
import useCreateQuote from 'common-hooks/create-quote/useCreateQuote'
import { BuyerQuoteContext } from '../BuyerQuotesUtils'
import MultipleParts from './MultipleParts'
import { getWellKnownAddress } from 'utils/Utils'
import LoadingOverlay from 'components/common/LoadingOverlay'
import TitleList from 'components/custom/list/TitleList'
import { formatUserProfileAddress } from 'containers/buyer-quote-details/BuyerQuoteUtils'
import QuoteShipping from './QuoteShipping'
import { useLocation } from 'react-router-dom'
import useToastify from 'common-hooks/useToastify'
import LoginDialog from 'containers/authentication/LoginDialog'
import Tooltip from 'components/common/Tooltip'
import GooglePlaces from 'components/custom/GooglePlaces'
import { formatAddressOfGAPI } from 'containers/authentication/sign-up/SignUpUtils'
import useToggle from 'common-hooks/useToggle'

const useStyles = makeStyles(() => ({
  summaryBody: {
    backgroundColor: getColor('system-bg'),
    padding: '30px',
    minHeight: '670px',
  },
  oemBrands: {
    border: `1px solid ${getColor('grey-g95')}`,
    height: '36px',
    padding: '10px 20px',
    borderRadius: '100000px',
    background: '#fff',
  },
  imagesRoot: {
    alignItems: 'center',
    display: 'flex',
    width: '165px',
  },
  titile: {
    display: 'flex',
    gap: '0 10px',
  },
  img: {
    height: '30px',
    width: '30px',
  },

  partRoot1: {
    paddingTop: '8px',
    paddingBottom: '25px',
  },
  pb10: {
    marginTop: '10px',
  },
  summaryInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px 0',
  },

  brandDetail: {
    alignItems: 'unset',
  },
  // addressDiv: {
  //   width: '480px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  //   gap: '15px',
  //   position: 'relative',
  // },

  txtfiledClass: {
    background: '#fff',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  addressDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '15px',
    '& input': {
      background: '#fff',
    },
  },
}))

const SummaryForm = ({ onClose, setFormStep, setSuccesLink, setSuccesDialog }) => {
  const classes = useStyles()

  const { BuyerQueoteInformation, setTextBoxVisibilityData, onSaveBuyerQuoteInformaton } = useContext(BuyerQuoteContext)
  const location = useLocation()
  const { createQuote } = useCreateQuote()
  function coverImage(val) {
    if (val === '/static/media/UnionIcon.e8ec7324b81cc0c0dd56b9cfff1a8858.svg') {
      return Placeholder
    }
    return val
  }
  function handleRemoveBrandName(id) {
    onSaveBuyerQuoteInformaton((pre) => {
      if (pre?.Brands?.brand?.id === id) {
        delete pre?.Brands?.brand
        setTextBoxVisibilityData([])
      }
      return pre
    })
    setFormStep(1)
  }
  function handleRemoveEquipName(id) {
    onSaveBuyerQuoteInformaton((pre) => {
      if (pre?.Equipments?.equipment?.id === id) {
        delete pre?.Equipments?.equipment
        setTextBoxVisibilityData([])
      }
      return pre
    })
    setFormStep(0)
  }

  const methods = useForm()
  const { handleSubmit, control, setValue, getValues } = methods
  const [shippinAddressDialog, setShippinAddressDialog] = useToggle(false)
  const [profileData, setProfileData] = useState()
  const [loading, setloading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [userAddress, setUserAdress] = useState()
  const { toastMessage } = useToastify()
  const onSubmit = async (data) => {
    let newData
    if (location.pathname === '/quotes') {
      newData = {
        ...BuyerQueoteInformation,
        profileAdd: formatUserProfileAddress(userAddress?.user_address),
        brand_model_number: data?.modalNumber,
        brand_serial_number: data?.serialNumber,
      }
      setloading(true)
      const response = await createQuote(newData, setloading)
      if (response) {
        setTextBoxVisibilityData([])
        setSuccesLink(response)
        setSuccesDialog(true)
        onClose()
      } else {
        setloading(false)
      }
    } else if (!data?.company_delivery_addr) {
      toastMessage('error', 'Shipping address is required')
      return 0
    } else {
      newData = {
        ...BuyerQueoteInformation,
        brand_model_number: data?.modalNumber,
        brand_serial_number: data?.serialNumber,
        address: data?.company_delivery_addr,
      }
      setOpenDialog(true)
      localStorage.setItem('unAuthQuoteRequest', JSON.stringify(newData))
    }
    return 0
  }
  return (
    <Container>
      <form id={`summary-form`} onSubmit={handleSubmit(onSubmit)}>
        {loading && <LoadingOverlay />}
        <Container className={classes.summaryBody}>
          <TitleList title="Equipment" />
          <Container className={classes.summaryInner}>
            <Container className={classes.titile}>
              <Container flex gap={'15px'} justify={'flex-start'} className={classes.brandDetail}>
                <Container display="flex" gap={'5px'} alignItems="center" className={classes.oemBrands}>
                  <Image
                    src={coverImage(BuyerQueoteInformation?.Equipments?.equipment?.coverImg)}
                    alt="brandImg"
                    className={classes.img}
                  />
                  <Container>
                    <Typography variant="body">{BuyerQueoteInformation?.Equipments?.equipment?.name}</Typography>
                  </Container>
                  <CloseIcon
                    onClick={() => handleRemoveEquipName(BuyerQueoteInformation?.Equipments?.equipment?.id)}
                    className={classes.closeIcon}
                  />
                </Container>
                <Container display="flex" gap={'12px'} alignItems="center" className={classes.oemBrands}>
                  <Image
                    src={coverImage(BuyerQueoteInformation?.Brands?.brand?.coverImg)}
                    alt="brandImg"
                    className={classes.img}
                  />
                  <Container>
                    <Typography variant="body" color={getColor('grey-g25')}>
                      {BuyerQueoteInformation?.Brands?.brand?.name}
                    </Typography>
                  </Container>
                  <CloseIcon
                    onClick={() => handleRemoveBrandName(BuyerQueoteInformation?.Brands?.brand?.id)}
                    className={classes.closeIcon}
                  />
                </Container>
              </Container>
            </Container>
            <Container className={classes.titile}>
              <Container flex gap={'15px'} justify={'flex-start'} className={classes.brandDetail}>
                <Controller
                  name={'modalNumber'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TextField
                      placeholder={'e.g. 244R'}
                      onChange={onChange}
                      // onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                      value={value}
                      name={name}
                      id={name}
                      inputClassName={classes.txtfiledClass}
                      label="Model Number"
                    />
                  )}
                />
                <Controller
                  name={'serialNumber'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TextField
                      placeholder={'e.g. AT1234BD'}
                      onChange={onChange}
                      // onKeyDown={(e) => allowOnlyAlphaNumric(e)}
                      value={value}
                      name={name}
                      id={name}
                      label="Serial Number"
                      inputClassName={classes.txtfiledClass}
                    />
                  )}
                />
              </Container>
            </Container>
            <Seperator />
            <Container className={classes.partsDiv}>
              <Typography variant="title" color={getColor('grey-g25')} className={classes.partsTitle}>
                Parts
              </Typography>
              <MultipleParts partsData={BuyerQueoteInformation?.visibleTextBoxData} />
            </Container>
            <Seperator />
            {location.pathname === '/quotes' ? (
              <QuoteShipping
                setUserAdress={setUserAdress}
                setProfileData={setProfileData}
                setShippinAddressDialog={setShippinAddressDialog}
                // opneSuccesDialog={opneSuccesDialog}
                setSuccesDialog={setSuccesDialog}
                // succesLink={succesLink}
              />
            ) : (
              <Container>
                <Container display="flex" gap="90px">
                  <Container flex>
                    <Typography variant="title" color={getColor('grey-g25')}>
                      Shipping
                    </Typography>
                    <Tooltip
                      placement="right"
                      maxWidth={'565px'}
                      title={
                        'Once you create a quote you will not able to change the address, Provide proper shipping address.'
                      }
                    >
                      <InfoSmallIcon className={classes.icon} />
                    </Tooltip>
                  </Container>
                  <Container className={classes.addressDiv}>
                    <Controller
                      name={'company_delivery_addr'}
                      control={control}
                      render={({ field: { name } }) => (
                        <GooglePlaces
                          id={name}
                          name={name}
                          onClearInput={() => setValue(name, { id: getValues()?.company_billing_addr?.id })}
                          value={
                            getValues()?.company_delivery_addr?.addr_line_1
                              ? getWellKnownAddress(
                                  getValues()?.company_delivery_addr?.addr_line_1,
                                  getValues()?.company_delivery_addr?.addr_line_2
                                )
                              : ''
                          }
                          spacing={3}
                          placeholder={'S 5th Ave, Merrll St, Castlewood, SD 57078, US'}
                          onSelect={(loc, i) => {
                            if (i === 0)
                              setValue(
                                'company_delivery_addr',
                                formatAddressOfGAPI(
                                  loc.addr,
                                  loc.addr_line_1,
                                  loc.addr_line_2,
                                  null,
                                  getValues()?.company_delivery_addr?.id
                                )
                              )
                            else
                              setValue(
                                'company_billing_addr',
                                formatAddressOfGAPI(
                                  loc.addr,
                                  loc.addr_line_1,
                                  loc.addr_line_2,
                                  null,
                                  getValues()?.company_billing_addr?.id
                                )
                              )
                          }}
                        />
                      )}
                    />
                  </Container>
                </Container>
              </Container>
            )}
            <Seperator />
          </Container>
        </Container>
      </form>
      <LoginDialog open={openDialog} onClose={setOpenDialog} />
      <ShipingAddressDialog open={shippinAddressDialog} onclose={setShippinAddressDialog} profileData={profileData} />
    </Container>
  )
}
SummaryForm.propTypes = {
  onClose: PropTypes.func,
  setFormStep: PropTypes.func,
  setSuccesLink: PropTypes.func,
  setSuccesDialog: PropTypes.func,
}
export default SummaryForm
