import React, { Fragment, useState } from 'react'
import Container from 'components/common/Container'
import { TabPanel, Tabs } from 'components/common/Tabs'
import placeholderCenter from 'assets/images/placeholderCenter.png'
import QuoteActiveCard from 'containers/buyer-quotes/QuoteActiveCard'
import SearchField from 'components/common/search/SearchField'
import Button from 'components/common/Button'
import { makeStyles } from '@mui/styles'
import useToggle from 'common-hooks/useToggle'
// import useSearch from 'common-hooks/useSearch'
import useActiveQuotes from 'common-hooks/buyer/quotes/useActiveQuotes'
import { getColor } from 'theme/colors'
import LoadingOverlay from 'components/common/LoadingOverlay'
import Typography from 'components/common/Typography'
import HtmlTitle from 'utils/HtmlTitle'
import { BuyerQuoteContext, parseOrderList, tabObject } from './BuyerQuotesUtils'
import CreateQuoteDialog from './CreateQuoteDialog'
import { CaretLeftIcon, CaretRightIcon } from 'assets/icons'
import useCreateQuote from 'common-hooks/create-quote/useCreateQuote'

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'space-between',
    padding: '30px',
  },
  marginHead: {
    position: 'sticky',
    top: '60px',
    background: getColor('system-bg'),
    zIndex: '1',
    '& input': {
      background: '#fff',
    },
  },
  img: {
    margin: '151px auto',
  },
  list: {
    // height: '83%',
    maxHeight: '83vh',
    // padding: '0 5px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '0 5px',
    '&::-webkit-scrollbar': {
      width: '6px',
      paddingtop: '50px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: ' 100px',
      background: '#C3C5D5',
    },
  },
  progress: {
    color: getColor('orange-main'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateClass: {
    maxWidth: '800px',
    margin: '0 auto',
    paddingBottom: '20px',
  },
  nextBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
    '& div': {
      flexFlow: 'row-reverse',
    },
  },
  prevBtn: {
    border: '1px solid #9799B5',
    borderRadius: '4px',
  },
  btnDiv: {
    maxWidth: '1920px',
    width: '800px',
    paddingTop: '20px',
    paddingBottom: '20px',
    margin: '0 auto',
    position: 'sticky',
    bottom: '0',
    left: '0',
    rigth: '0',
    background: '#F8F9F9',
    zIndex: '9',
    // padding: '0 0px 30px 0px',
  },
  overLoading: {
    height: '600px',
  },
  quoteMain: {
    height: '93.3vh',
    '@media (max-width: 1600px)': {
      height: '96vh',
    },
  },
}))

const BuyerQuotesList = () => {
  const [openDialog, setOpenDialog] = useToggle(false)
  const classes = useStyles()
  // const { search, onSearch } = useSearch()
  const [value, setValue] = useState(0)
  const [formStep, setFormStep] = useState(0)
  const [status, setStatus] = useState('active')
  const { createQuote } = useCreateQuote()
  const handleChange = (event, newValue) => {
    setValue(newValue)
    switch (newValue) {
      case 0:
        setStatus('active')
        break
      case 1:
        setStatus('closed')
        break

      default:
        setStatus('active')
        break
    }
  }

  const [selectedParts, setSelectedParts] = useState([])
  const [quoteData, setQuoteData] = useState([])
  const [partsSelectionTreeView, setPartsTreeview] = useState()

  const contextValue = {
    onSaveBuyerQuoteInformaton: setQuoteData,
    BuyerQueoteInformation: quoteData,
    visibleTextBoxData: selectedParts,
    setTextBoxVisibilityData: setSelectedParts,
    setPartsTreeViewData: setPartsTreeview,
    partsTreeViewData: partsSelectionTreeView,
  }

  const handleClick = () => {
    setFormStep(0)
    setQuoteData([])
    setOpenDialog(true)
  }
  const unAuthQuoteRequest = localStorage.getItem('unAuthQuoteRequest')
  if (unAuthQuoteRequest) {
    createQuote(JSON.parse(unAuthQuoteRequest))
    localStorage.removeItem('unAuthQuoteRequest')
  }
  const { activeQuotesData, isLoading, onSearch, search, setPage, page } = useActiveQuotes(status)

  const filtered = !search
    ? activeQuotesData
    : activeQuotesData.filter((item) => Object.values(item).join('').toLowerCase().includes(search.toLowerCase()))

  const rows = parseOrderList(filtered, 'created_at')
  const keys = Object.keys(rows)

  return (
    <Container className={classes.quoteMain}>
      <HtmlTitle title={'Quotes'} />
      <Container className={classes.mainContainer} width={'100%'}>
        <Container className={classes.header}>
          <Container flex justify={'space-between'} className={classes.marginHead}>
            <Tabs value={value} onchange={handleChange} array={tabObject} />
            <Container flex gap={'20px'}>
              <Container>
                <SearchField
                  id={'searchField'}
                  onSearch={(e) => {
                    onSearch(e)
                  }}
                  value={search}
                />
              </Container>
              <Container>
                <Button onClick={handleClick}>Request Quote</Button>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={classes.list}>
        <TabPanel value={value} index={0}>
          {filtered?.length === 0 && search === '' && (
            <>
              <Container height={'77vh'} flex direction={'column'}>
                <Container flex direction={'column'}>
                  <Container>
                    <img src={placeholderCenter} alt="LET'S SPARK UP LOGO" width={'305px'} height={'290px'} />
                  </Container>
                  <Container>
                    <Button onClick={setOpenDialog} variant={'secondary'} border>
                      Request Quote
                    </Button>
                  </Container>
                </Container>
              </Container>
            </>
          )}

          {filtered?.length !== 0 && (
            <Container height={'100%'}>
              {isLoading && (
                <Container className={classes.overLoading}>
                  <LoadingOverlay />{' '}
                </Container>
              )}

              {keys?.map((key, index) => (
                <Fragment key={index}>
                  <Container className={classes.dateClass}>
                    <Typography variant="title" color={getColor('grey-g25')}>
                      {/* {key.replace(',', '')} */}
                      {key}
                    </Typography>
                  </Container>

                  {rows[key].map((obj, index) => (
                    <QuoteActiveCard
                      key={index}
                      quoteImg={obj?.brand_cover_image}
                      quoteId={obj?.id}
                      brandName={obj?.brand_name}
                      brandSerialNum={obj?.brand_serial_number}
                      brandModelNum={obj?.brand_model_number}
                      partName={obj?.user_part}
                      quantity={obj?.quantity}
                      equipName={obj?.equip_name}
                      partNo={obj?.part_number}
                      supplier={obj?.supplier}
                      publicPrivate={obj?.private}
                      brandNameRef={obj?.brand_name_ref}
                      imagesCount={obj?.quote_assets_count}
                      createdAt={obj?.created_at}
                      quoteCount={obj?.requests_count}
                      supplierCount={obj?.invites_count}
                    />
                  ))}
                </Fragment>
              ))}
            </Container>
          )}

          {filtered?.length === 0 && search !== '' && (
            <Container flex>
              <Typography>No Data Found</Typography>
            </Container>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Container flex direction={'column'}>
            {filtered?.length !== 0 && (
              <Container height={'100%'}>
                {isLoading && (
                  <Container className={classes.overLoading}>
                    {' '}
                    <LoadingOverlay />
                  </Container>
                )}

                {keys?.map((key, index) => (
                  <Fragment key={index}>
                    <Container className={classes.dateClass}>
                      <Typography variant="title" color={getColor('grey-g25')}>
                        {key}
                      </Typography>
                    </Container>

                    {rows[key].map((obj, index) => (
                      <QuoteActiveCard
                        key={index}
                        quoteImg={obj?.quote_assets_url}
                        quoteId={obj?.id}
                        brandName={obj?.brand_name}
                        brandSerialNum={obj?.brand_serial_number}
                        brandModelNum={obj?.brand_model_number}
                        partName={obj?.part_name}
                        quantity={obj?.quantity}
                        equipName={obj?.equip_name}
                        partNo={obj?.part_number}
                        supplier={obj?.supplier}
                        publicPrivate={obj?.private}
                        brandNameRef={obj?.brand_name_ref}
                        imagesCount={obj?.quote_assets_count}
                        createdAt={obj?.created_at}
                        quoteCount={obj?.requests_count}
                        supplierCount={obj?.invites_count}
                      />
                    ))}
                  </Fragment>
                ))}
              </Container>
            )}
            {filtered?.length === 0 && search !== '' && (
              <Container flex direction={'column'}>
                <Container>
                  <img src={placeholderCenter} alt="LET'S SPARK UP LOGO" width={'305px'} height={'290px'} />
                </Container>
              </Container>
            )}
          </Container>
          {filtered?.length === 0 && search === '' && (
            <>
              <Container height={'77vh'} flex direction={'column'}>
                <Container flex direction={'column'}>
                  <Container>
                    <img src={placeholderCenter} alt="LET'S SPARK UP LOGO" width={'305px'} height={'290px'} />
                  </Container>
                </Container>
              </Container>
            </>
          )}
        </TabPanel>
        <Container display="flex" justify="space-between" width={'100%'} className={classes.btnDiv}>
          <Button
            icon={<CaretLeftIcon />}
            variant={'transparent'}
            className={classes.prevBtn}
            onClick={() => setPage((pre) => (pre > 1 ? pre - 1 : 1))}
            disabled={page === 1}
          >
            Prev.
          </Button>
          <Button
            icon={<CaretRightIcon />}
            variant={'transparent'}
            className={classes.nextBtn}
            onClick={() => setPage((pre) => pre + 1)}
            disabled={filtered?.length !== 20}
          >
            Next
          </Button>
        </Container>
      </Container>

      <BuyerQuoteContext.Provider value={contextValue}>
        <CreateQuoteDialog open={openDialog} onClose={setOpenDialog} setFormStep={setFormStep} formStep={formStep} />
      </BuyerQuoteContext.Provider>
    </Container>
  )
}

export default BuyerQuotesList
