import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'

export default function PaymentMethodForm({ successURL }) {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!stripe || !elements) {
      return
    }
    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `http://localhost:3000/${successURL}`,
      },
    })

    if (result.error) {
      // console.log(result.error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form onSubmit={handleSubmit} id="add-payment-method-from">
      <PaymentElement />
    </form>
  )
}

PaymentMethodForm.propTypes = {
  successURL: PropTypes.string,
}
